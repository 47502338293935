import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  setError,
  setFileToLivePostfillersStatus,
  setFileToLivePrefillersStatus,
} from '../../features/uiSlice';
import { GetFileToLiveFiller } from '../../types/API';
import { FILE_TO_LIVE_GETMOVIE_FLOW_ERR_MSG } from '../../utils/Message';
import { setEQFileToLiveFiller } from '../../features/fileToLivesSlice';
import { UI_STATUS } from '../../utils/Const';

/** 疑似ライブフィラー利用動画取得API実行 */
const GetFillerList =
  (type: 'post' | 'pre', mid: string, searchFillerMid: string): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    // 処理中をセット
    try {
      if (type === 'pre') {
        dispatch(setFileToLivePrefillersStatus(UI_STATUS.PROCESSING));
      }
      if (type === 'post') {
        dispatch(setFileToLivePostfillersStatus(UI_STATUS.PROCESSING));
      }

      // 疑似ライブフィラー利用取得API実行
      const response: AxiosResponse<GetFileToLiveFiller> = await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getFillerList`,
        {
          cid: user.contract.cid,
          userApiParams: user.user,
          pageSize: 100,
          pageNumber: 1,
          mid,
          search_filler_mid: searchFillerMid || null,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      const { movies } = response.data;
      dispatch(setEQFileToLiveFiller({ type, fillers: movies || [] }));

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: FILE_TO_LIVE_GETMOVIE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      if (type === 'pre') {
        dispatch(setFileToLivePrefillersStatus(UI_STATUS.COMPLETED));
      }
      if (type === 'post') {
        dispatch(setFileToLivePostfillersStatus(UI_STATUS.COMPLETED));
      }
    }
  };

export default GetFillerList;
