import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SCRIPT1_NAME = 'gtag';
const SCRIPT2_NAME = 'gtagScript';
const SCRIPT3_NAME = 'gtm';
const NOSCRIPT_NAME = 'gtmNoscript';

export const initializeGA = (): void => {
  // 既存は削除
  document.getElementById(SCRIPT2_NAME)?.remove();
  document.getElementById(SCRIPT1_NAME)?.remove();
  document.getElementById(SCRIPT3_NAME)?.remove();
  document.getElementById(NOSCRIPT_NAME)?.remove();

  if (!process.env.REACT_APP_GA4_ID || process.env.REACT_APP_GA4_ID === '')
    return;

  // タグ埋め込み
  const script1 = document.createElement('script');
  script1.id = SCRIPT1_NAME;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA4_ID}`;
  script1.async = true;
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.id = SCRIPT2_NAME;
  script2.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GA4_ID}');`;
  document.head.appendChild(script2);

  const script3 = document.createElement('script');
  script3.id = SCRIPT3_NAME;
  script3.text = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');`;
  document.head.appendChild(script3);

  const noscript = document.createElement('noscript');
  noscript.id = NOSCRIPT_NAME;
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  document.getElementById('root')?.before(noscript);
};

declare global {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  interface Window {
    gtag?: Gtag.Gtag;
  }
}

export const useTracking = (googleAnalyticsId: string | undefined): void => {
  if (!googleAnalyticsId) return;
  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (googleAnalyticsId === '') return;
      window.gtag('config', String(googleAnalyticsId), {
        page_path: location.pathname,
      });
    });

    return unlisten;
  }, [googleAnalyticsId, listen]);
};
