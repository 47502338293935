import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { InfoOutlined, Help } from '@material-ui/icons';
import Tooltip from '../parts/Tooltip/Tooltip';
import { LiveShareProps } from '../../props/LiveShare';
import {
  STREAMS,
  STREMAS_NUM,
  LIVESAHRE_TABS,
  SUPPORTPAGE_URL_COOKIE,
} from '../../utils/Const';
import { ToStreamsJap, ToStreamsNum } from '../../utils/ChangeStatus';

/** スタイル */
const useStyle = makeStyles({
  input: {
    background: '#FFFFFF',
    marginRight: 4,
    '& .MuiOutlinedInput-input': {
      padding: '6px 10px',
      lineHeight: '28px',
      width: '100%',
    },
  },
  root: {
    padding: 0,
    color: '#333333',
    fontWeight: 500,
    '& .MuiFormControlLabel-label': {
      lineHeight: '40px',
      fontSize: 14,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
    },
  },
  defaultQuality: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0 0',
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '16px 22px 10px',
    color: '#333333',
    '& h5': {
      fontSize: 15,
      fontWeight: 500,
      margin: 0,
      color: '#333333',
    },
    '& .MuiFormControlLabel-label': {
      lineHeight: '32px',
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
      marginRight: 14,
    },
  },
  discription: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 26,
    opacity: 0.6,
    fontSize: 12,
  },
  icon: {
    color: '#333333',
    marginRight: 4,
  },
  cookieControl: {
    borderLeft: '2px solid #61B227',
    paddingLeft: 20,
    margin: '15px 0 15px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'center',
    },
  },
  helpContainer: {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
      bottom: '-15px',
      left: 140,
      lineHeight: 1.2,
    },
    '&:hover .tooltip a': {
      color: '#FFF',
    },
    '&:hover .tooltip > .balloon:before': {
      transform: 'rotate(90deg)',
      top: '-26px',
      left: '-130px',
      zIndex: '-1',
    },
  },
  help: {
    color: '#B6BEC9',
    marginLeft: 5,
    marginBottom: '-2px',
    width: 16,
    height: 16,
  },
});

/** その他設定（任意） */
const OtherSetting = ({ props }: { props: LiveShareProps }): JSX.Element => {
  const classes = useStyle();
  const {
    selectTab,
    referrerPolicy,
    choiceReferrerPolicy,
    streamQuality,
    fileToLiveMovie,
    defaultQualityPC,
    defaultQualitySP,
    choiceDefaultQualityPC,
    choiceDefaultQualitySP,
    videoanalyzerCookie,
    resumeCookie,
    setCookieHandle,
    pid,
  } = props;
  // ライブ画質設定のリスト
  const [streamQualityListPC, setStreamQualityListPC] = useState<
    {
      value: STREMAS_NUM;
      title: STREAMS;
    }[]
  >([]);
  const [streamQualityListSP, setStreamQualityListSP] = useState<
    {
      value: STREMAS_NUM;
      title: STREAMS;
    }[]
  >([]);

  // ライブ画質設定のリスト作成
  useEffect(() => {
    if (streamQuality) {
      const activeQuality = Object.entries(streamQuality).filter(
        (quality) => quality[1].activateFlg
      );
      const list = activeQuality.map(([key]) => {
        return {
          value: ToStreamsNum(key),
          title: ToStreamsJap(key),
        };
      });
      // オリジナルのみではない場合、自動画質を先頭に追加
      if (
        activeQuality.length > 1 ||
        streamQuality.original.activateFlg === 0
      ) {
        list.unshift({ value: STREMAS_NUM.AUTO, title: STREAMS.AUTO });
      }
      setStreamQualityListPC(list);
      setStreamQualityListSP(list);
    } else if (fileToLiveMovie) {
      if (fileToLiveMovie.pcQuality) {
        const list = Object.entries(fileToLiveMovie.pcQuality)
          .filter((quality) => quality[1].activeFlg)
          .map(([key]) => {
            return {
              value: ToStreamsNum(key),
              title: ToStreamsJap(key),
            };
          });
        setStreamQualityListPC([
          // 自動画質を先頭に追加
          { value: STREMAS_NUM.AUTO, title: STREAMS.AUTO },
          ...list,
        ]);
      }
      if (fileToLiveMovie.spQuality) {
        const list = Object.entries(fileToLiveMovie.spQuality)
          .filter((quality) => quality[1].activeFlg)
          .map(([key]) => {
            return {
              value: ToStreamsNum(key),
              title: ToStreamsJap(key),
            };
          });
        setStreamQualityListSP([
          // 自動画質を先頭に追加
          { value: STREMAS_NUM.AUTO, title: STREAMS.AUTO },
          ...list,
        ]);
      }
    }
  }, [streamQuality]);

  return (
    <>
      <h4>その他設定（任意）</h4>
      <Box className={classes.root}>
        {(selectTab === LIVESAHRE_TABS.IFRAME ||
          selectTab === LIVESAHRE_TABS.CHAT_TAG) && (
          <Box>
            リファラーポリシー：
            <Select
              value={referrerPolicy}
              onChange={choiceReferrerPolicy}
              variant="outlined"
              className={classes.input}
              style={{ minWidth: 300 }}
            >
              <MenuItem value={1}>あり</MenuItem>
              <MenuItem value={0}>なし</MenuItem>
            </Select>
          </Box>
        )}
        <Box className={classes.defaultQuality}>
          デフォルト画質：
          <Box style={{ marginLeft: 10 }}>
            PC
            <Select
              value={
                defaultQualityPC !== STREMAS_NUM.UNSELECTED
                  ? defaultQualityPC
                  : ''
              }
              onChange={choiceDefaultQualityPC}
              variant="outlined"
              className={classes.input}
              style={{ minWidth: 150, margin: '0 10px' }}
            >
              <MenuItem value={undefined} style={{ minHeight: 36 }} />
              {streamQualityListPC.map((quality) => (
                <MenuItem value={quality.value}>{quality.title}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box style={{ marginLeft: 10 }}>
            スマホ
            <Select
              value={
                defaultQualitySP !== STREMAS_NUM.UNSELECTED
                  ? defaultQualitySP
                  : ''
              }
              onChange={choiceDefaultQualitySP}
              variant="outlined"
              className={classes.input}
              style={{ minWidth: 150, margin: '0 10px' }}
            >
              <MenuItem value={undefined} style={{ minHeight: 36 }} />
              {streamQualityListSP.map((quality) => (
                <MenuItem value={quality.value}>{quality.title}</MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      {selectTab !== LIVESAHRE_TABS.CHAT_TAG && pid >= 0 && (
        <>
          <h4>高度な設定</h4>
          <Box className={classes.greenBack}>
            <h5>
              Cookieの制御
              <span
                className={classes.helpContainer}
                style={{ display: 'inline-block' }}
              >
                <Help fontSize="small" className={classes.help} />
                <Tooltip
                  message={
                    <>
                      EQプレイヤーで使用しているCookieについて、
                      <a
                        href={SUPPORTPAGE_URL_COOKIE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        詳しくはこちら
                      </a>
                      をご確認ください。
                    </>
                  }
                />
              </span>
            </h5>
            <Box className={classes.cookieControl}>
              <FormControlLabel
                name="videoanalyzerCookie"
                control={
                  <Checkbox color="primary" checked={videoanalyzerCookie} />
                }
                label={<>動画視聴解析系Cookieを拒否する</>}
                onChange={() =>
                  setCookieHandle(!videoanalyzerCookie, resumeCookie)
                }
              />
              <Box className={classes.discription}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                Cookieを拒否する場合、プレイヤーを再生するブラウザでは視聴ログを取得しません。
              </Box>
              <FormControlLabel
                name="resumeCookie"
                control={<Checkbox color="primary" checked={resumeCookie} />}
                label={<>プレイヤーレジューム機能系Cookieを拒否する</>}
                onChange={() =>
                  setCookieHandle(videoanalyzerCookie, !resumeCookie)
                }
              />
              <Box className={classes.discription}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                Cookieを拒否する場合、プレイヤーを再生するブラウザではプレイヤーレジュームが正常動作しなくなります。
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default OtherSetting;
