import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { LIVECONTROLLER_CHECK_LIVE_ERR_MSG } from '../../utils/Message';
import { setError, setLiveControllerStatus } from '../../features/uiSlice';
import { CountUpCntLive } from '../../types/API';
import { UI_STATUS } from '../../utils/Const';

type CheckLiveCountParams = {
  cid: number;
  userApiParams: string;
  setSimultaneousLiveCountDialog: React.Dispatch<React.SetStateAction<boolean>>;
  afterCheckedLiveCount: () => void;
};

/** ライブ同時開催数を確認し、上限超過しないかチェック */
const CheckLiveCount =
  (arg: CheckLiveCountParams): AppThunk =>
  async (dispatch) => {
    try {
      // ライブ契約数カウントアップAPI実行
      const responseCountUpCntLive: AxiosResponse<CountUpCntLive> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}countUpCntLive`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            countup: 0, // NOTE: カウントアップせずにチェックのみ
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      // ライブ最大契約数と現在配信中の数が一致しない場合
      if (
        responseCountUpCntLive.data.maxLive !==
        responseCountUpCntLive.data.onLiveCnt
      ) {
        // 同時ライブ開催数超過について確認済みとして後続処理を実行する
        arg.afterCheckedLiveCount();
        return;
      }
      // 同時ライブ開催数についての警告orエラーダイアログを表示するフラグをONにする
      arg.setSimultaneousLiveCountDialog(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: LIVECONTROLLER_CHECK_LIVE_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default CheckLiveCount;
