import moment from 'moment';

/** ライブ自動画質設定取得API Mockレスポンス */
const AutoQuality = {
  /** ライブ自動画質設定取得APIの結果 */
  autoQuolity: {
    cid: 1,
    errorCode: 400,
    message: 'OK',
    profiles: [...Array(10)].map((x, i) => {
      const startDate = moment().add(-1, 'hours').add(i, 'minutes');
      return {
        lqpid: i + 1,
        profileName: `ライブ自動画質設定名-${i + 1}`,
        defaultFlg: Number(i === 5),
        framerate: 60,
        quality: {
          original: {
            activeFlg: 1,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 0,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 1,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 1,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 1,
            videoBitrate: 282,
          },
        },
        updateDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
      };
    }),
  },
};

// ライブ自動画質設定取得APIの結果にデスクトップ配信用のMockデータを追加してexport
export default {
  ...AutoQuality,
  autoQuolity: {
    ...AutoQuality.autoQuolity,
    profiles: [
      ...AutoQuality.autoQuolity.profiles,
      {
        lqpid: AutoQuality.autoQuolity.profiles.length + 1,
        profileName: `パススルーしない配信でフルHD画質が有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 0,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 1,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 0,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 1,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 1,
            videoBitrate: 282,
          },
        },
      },
      {
        lqpid: AutoQuality.autoQuolity.profiles.length + 2,
        profileName: `パススルーしない配信でHD画質が有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 0,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 0,
          },
          hd: {
            activeFlg: 1,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 1,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 1,
            videoBitrate: 282,
          },
        },
      },
      {
        lqpid: AutoQuality.autoQuolity.profiles.length + 3,
        profileName: `パススルー配信でHD画質が有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 1,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 1,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 0,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 0,
            videoBitrate: 282,
          },
        },
      },
      {
        lqpid: AutoQuality.autoQuolity.profiles.length + 4,
        profileName: `パススルー配信でオリジナル画質のみ有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 1,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 0,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 0,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 0,
            videoBitrate: 282,
          },
        },
      },
    ],
  },
};
