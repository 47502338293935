import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import jaLocale from 'date-fns/locale/ja';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Box, Button } from '@material-ui/core';
import { Close, QueryBuilder, KeyboardArrowRight } from '@material-ui/icons';
import { HistoryConnectionGraphProps } from '../../props/HistoryConnectionGraph';
import ChartView from '../LiveController/ChartView';
import ToggleInterval from '../LiveController/ToggleInterval';
import { DATE_ERR_MSG } from '../../utils/Message';
import { MIN_WIDTH } from '../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {
    width: MIN_WIDTH,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: 'calc(100vh - 230px)',
    width: 1035,
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px #0000001F',
    borderRadius: 8,
    lineHeight: 1,
    overflow: 'hidden',
    color: '#333333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    '& h3': {
      fontSize: 20,
      fontWeight: 500,
    },
  },
  body: {
    padding: '10px 30px 0',
    fontSize: 14,
    width: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    '& h4': {
      fontSize: 15,
      fontWeight: 500,
      borderLeft: '1px solid #61B227',
      paddingLeft: 20,
      margin: ' 20px 0',
    },
  },
  icon: {
    color: '#C32733',
    marginRight: 4,
  },
  inputDate: {
    background: '#FFFFFF',
    marginRight: 5,
    '& .MuiOutlinedInput-root': {
      height: 40,
      width: 160,
      fontSize: 15,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  inputTime: {
    background: '#FFFFFF',
    margin: 0,
    width: 103,
    '& .MuiOutlinedInput-root': {
      height: 40,
      width: 100,
      fontSize: 15,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
    marginBottom: 25,
    justifyContent: 'space-between',
  },
  submitButon: {
    width: 80,
    height: 40,
    background: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    borderRadius: 4,
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 15,
    marginLeft: 8,
    transition: 'all 400ms',
    '&:hover': {
      background: '#015CFA',
    },
  },
  csvButton: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 14,
    color: '#333333',
    width: 94,
  },
});

type propsType = {
  open: boolean;
  props: HistoryConnectionGraphProps;
  onClose: () => void;
};

/** グラフ表示(UI) */
const HistoryConnectionGraphComponent = ({
  open,
  props,
  onClose,
}: propsType): JSX.Element => {
  const classes = useStyle();
  const { fromDate, toDate, updateSpan, downloadClientsLogCSV } = props;
  const [fromDateObj, setFromDateObj] = useState<MaterialUiPickersDate>(
    new Date()
  );
  const [toDateObj, setToDateObj] = useState<MaterialUiPickersDate>(new Date());

  const handleDate = (name: string, value: MaterialUiPickersDate) => {
    if (name === 'from') {
      if (
        value?.getTime() &&
        toDateObj &&
        value.getTime() < toDateObj.getTime()
      ) {
        setFromDateObj(value);
        return;
      }
    }
    if (name === 'to') {
      if (
        value?.getTime() &&
        fromDateObj &&
        value.getTime() > fromDateObj.getTime()
      ) {
        setToDateObj(value);
        return;
      }
    }
    if (!value?.getTime()) return;
    alert(DATE_ERR_MSG);
  };

  const checkDate = (name: string, date: Date) => {
    if (!date.getTime()) {
      alert(DATE_ERR_MSG);
      return;
    }
    if (name === 'from') {
      if (date && toDateObj && date.getTime() < toDateObj.getTime()) {
        setFromDateObj(date);
        return;
      }
    }
    if (name === 'to') {
      if (date && fromDateObj && date.getTime() > fromDateObj.getTime()) {
        setToDateObj(date);
        return;
      }
    }
    alert(DATE_ERR_MSG);
  };

  const blurDate = (name: string, value: string) => {
    let formatVaue = value;
    if (value.match(/_/)) {
      // 一桁で入力された場合の対応
      formatVaue = value
        .split('/')
        .map((x, index) => {
          if (!x.match(/_/)) return x;
          const str = x.replace('_', '');
          if (index <= 0) {
            return `0000${str}`.slice(-4);
          }
          return `00${str}`.slice(-2);
        })
        .join('/');
    }
    const date = new Date(
      `${formatVaue} ${moment(fromDateObj).format('HH:mm')}`
    );
    checkDate(name, date);
  };
  const blurTime = (name: string, value: string) => {
    let formatVaue = value;
    if (value.match(/_/)) {
      // 一桁で入力された場合の対応
      formatVaue = value
        .split(':')
        .map((x) => {
          if (!x.match(/_/)) return x;
          const str = x.replace('_', '');
          return `00${str}`.slice(-2);
        })
        .join(':');
    }
    const date = new Date(
      `${moment(fromDateObj).format('YYYY/MM/DD')} ${formatVaue}`
    );
    checkDate(name, date);
  };

  useEffect(() => {
    setFromDateObj(new Date(`${fromDate.date} ${fromDate.time}`));
    setToDateObj(new Date(`${toDate.date} ${toDate.time}`));
  }, [fromDate, toDate]);

  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      <Box className={classes.container}>
        <>
          <Box className={classes.header}>
            <h3>ライブイベント履歴（同時接続数履歴）</h3>
            <Close onClick={onClose} style={{ cursor: 'pointer' }} />
          </Box>
          <Box className={classes.body}>
            <Box className={classes.datePicker}>
              <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                  <>
                    <span style={{ marginRight: 15 }}>期間</span>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="yyyy/MM/dd"
                      value={fromDateObj}
                      InputAdornmentProps={{ position: 'end' }}
                      onChange={(date) => handleDate('from', date)}
                      onBlur={(e) => blurDate('from', e.target.value)}
                      className={classes.inputDate}
                      disableToolbar
                    />
                    <KeyboardTimePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      value={fromDateObj}
                      InputAdornmentProps={{ position: 'end' }}
                      onChange={(date) => handleDate('from', date)}
                      onBlur={(e) => blurTime('from', e.target.value)}
                      className={classes.inputTime}
                      keyboardIcon={<QueryBuilder />}
                      ampm={false}
                      disableToolbar
                    />
                    <span style={{ margin: '0 11px' }}>〜</span>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="yyyy/MM/dd"
                      value={toDateObj}
                      InputAdornmentProps={{ position: 'end' }}
                      onChange={(date) => handleDate('to', date)}
                      onBlur={(e) => blurDate('to', e.target.value)}
                      className={classes.inputDate}
                      disableToolbar
                    />
                    <KeyboardTimePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      value={toDateObj}
                      InputAdornmentProps={{ position: 'end' }}
                      onChange={(date) => handleDate('to', date)}
                      onBlur={(e) => blurTime('to', e.target.value)}
                      className={classes.inputTime}
                      keyboardIcon={<QueryBuilder />}
                      ampm={false}
                      disableToolbar
                    />
                    <Button
                      onClick={() => updateSpan(fromDateObj, toDateObj)}
                      className={classes.submitButon}
                    >
                      適用
                    </Button>
                  </>
                </MuiPickersUtilsProvider>
              </Box>
              <Button
                onClick={downloadClientsLogCSV}
                className={classes.csvButton}
              >
                CSV出力
                <KeyboardArrowRight
                  fontSize="small"
                  style={{ marginLeft: 4, width: 16, height: 16 }}
                />
              </Button>
            </Box>
            <ToggleInterval historyProps={props} />
            <Box style={{ height: 'calc(100vh - 452px)', overflowY: 'auto' }}>
              <ChartView historyProps={props} width={900} />
            </Box>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default HistoryConnectionGraphComponent;
