import axios from 'axios';
import { AppThunk } from '../../app/store';
import { setError } from '../../features/uiSlice';
import { ARCHIVE_SPLIT_API_ERR_MSG } from '../../utils/Message';

type Arg = {
  wlivesId: string;
};

/** 録画ファイル分割フロー */
const archiveSplit =
  (arg: Arg): AppThunk =>
  async (dispatch) => {
    try {
      // ライブ分割API実行
      await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}splitRecording`,
        arg,
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: ARCHIVE_SPLIT_API_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    }
  };

export default archiveSplit;
