import React from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import FormTextBox from '../parts/FormTextBox/FormTextBox';
import { FileToLiveContentProps } from '../../props/FileToLiveContent';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& .MuiGrid-container': {
      marginTop: 0,
      marginBottom: '-16px',
    },
    '& .MuiGrid-spacing-xs-8 > .MuiGrid-item': {
      padding: '16px 30px',
      paddingRight: 2,
    },
    '& .MuiGrid-grid-xs-3': {
      lineHeight: '40px',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 30,
    marginBottom: 14,
    fontSize: 15,
  },
});

/** ライブイベント情報 */
const LiveEvent = ({
  register,
  ContentProps,
  open = false,
}: {
  register: UseFormRegister<FieldValues>;
  ContentProps?: FileToLiveContentProps;
  open?: boolean;
}): JSX.Element => {
  const classes = useStyle();
  const disableFlg = ContentProps?.disableFlg || false;
  const contentSetting = ContentProps?.liveEventInfo;

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        EQポータルをご利用される場合、ポータルサイトに表示する下記項目の設定を行えます。
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          ライブイベント名
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="LiveEventInfoName"
            width="fullWidth"
            register={register}
            rows={3}
            maxLength={300}
            defaultValue={contentSetting?.eventName}
            disabled={disableFlg}
          />
        </Grid>
        <Grid item xs={3}>
          ライブイベント説明文
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="LiveEventInfoDescription"
            width="fullWidth"
            register={register}
            rows={3}
            maxLength={5000}
            defaultValue={contentSetting?.description}
            disabled={disableFlg}
          />
        </Grid>
        <Grid item xs={3}>
          出演者情報
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="LiveEventInfoPerformer"
            width="fullWidth"
            register={register}
            rows={3}
            maxLength={5000}
            defaultValue={contentSetting?.performer}
            disabled={disableFlg}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LiveEvent;
