import { JCLOUD_SERVER_STATUS } from '../Const';

/** プレイヤーテスト用m3u8 */
const PLAYER_TEST_URL =
  'http://eqa922iwao.eq.webcdn.stream.ne.jp/www50/eqa922iwao/jmc_pub/jmc_pd/00001/23f31a5106d2457ea2de708ac27e1521.m3u8';

/** JCloudサーバー設定情報取得API Mockレスポンス(type=wlives) */
export const WlivesServer = {
  wlives: {
    id: 'ServerId',
    status: JCLOUD_SERVER_STATUS.CLOSED,
    appName: 'appname',
    ssfo: 'disabled',
    overseasStream: 'disabled',
    streams: [
      {
        name: 'live',
        feedUrl: {
          main: 'rtmp://MainFeedUrl/live',
          backup: 'rtmp://BackupFeedUrl/live',
        },
        monitorUrl: {
          main: PLAYER_TEST_URL,
          backup: PLAYER_TEST_URL,
        },
        playerUrl: {
          abr: 'MasterABR',
          abrDvr: 'MasterABRDVR',
          master: 'MasterPlayList',
          masterDvr: 'MasterPlayListDVR',
          main: 'MainPlayList',
          mainDvr: 'MainPlayListDVR',
          backup: 'BackupPlayList',
          backupDvr: 'BackupPlayListDVR',
          ssfo: 'SSFOPlayList',
          ssfoDvr: 'SSFOPlayListDVR',
        },
      },
    ],
    dvr: {
      status: 'enable',
      windowDuration: 240,
      lifetime: 90,
    },
    recording: {
      status: 'enable',
      archiveSplitSize: 5555,
    },
    adaptiveBitrate: {
      status: 'enable',
      templateName: 'cid.lpid',
    },
    encryptStream: {
      mode: 'auto',
      encryptKeyUrl: 'EncryptKeyUrl',
      encryptKeyValue: 'EncryptKeyValue',
    },
  },
  abr: {
    name: 'cid.lpid',
    streamParameters: [
      {
        bitrate: 532,
        framerate: 60,
        resolutionWidth: 640,
        resolutionHeight: 360,
      },
      {
        bitrate: 2000,
        framerate: 60,
        resolutionWidth: 1280,
        resolutionHeight: 720,
      },
      {
        bitrate: 4000,
        framerate: 60,
        resolutionWidth: 1920,
        resolutionHeight: 1080,
      },
    ],
  },
};

/** JCloudサーバー設定情報取得API Mockレスポンス(type=hls-auth) */
export const HlsAuthSever = {
  streamId: 'StreamId',
  authenticatedUrl: 'AuthenticatedUrl',
  sourceUrl: 'SourceUrl.m3u8',
  streamingType: 'live',
  allowUserDuplicates: true,
};

/** JCloudサーバー作成API Mockレスポンス */
export const CreateSever = {
  wlivesId: 'WlivesId',
};
