import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  InfoOutlined,
  Message,
  PauseCircleFilled,
  SpeakerNotesOff,
} from '@material-ui/icons';
import Sensors from '../../images/Sensors.svg';
import {
  FILE_TO_LIVE_CHAT_STATUS,
  EDITIONS,
  FILE_TO_LIVE_STATUS,
  UI_STATUS,
  FILETOLIVE_CONTROLL_PLAYERSIZE,
} from '../../utils/Const';
import Badge from '../parts/Badge/Badge';
import { FileToLiveControllerProps } from '../../props/FileToLiveController';

/** スタイル */
const useStyle = makeStyles({
  root: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 12px #1C385E0C',
    borderRadius: 8,
    width: 530,
    height: 'fit-content',
    marginRight: 30,
  },
  header: {
    display: 'flex',
    padding: '17px 30px',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    '& h3': {
      fontSize: 16,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
    },
  },
  body: {
    padding: '15px 20px 0',
  },
  status: {
    background: '#61B2270D',
    border: '1px solid #61B227',
    borderRadius: 4,
    fontSize: 12,
    padding: '2px 10px',
  },
  badge: {
    padding: '6px 12px',
  },
  player: {
    height: FILETOLIVE_CONTROLL_PLAYERSIZE.HEIGHT,
    width: FILETOLIVE_CONTROLL_PLAYERSIZE.WIDTH,
    position: 'relative',
    background: '#FAFAFA',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    '& > iframe': {
      margin: '0 auto',
      height: FILETOLIVE_CONTROLL_PLAYERSIZE.HEIGHT,
      width: FILETOLIVE_CONTROLL_PLAYERSIZE.WIDTH,
      display: 'flex',
      boxSizing: 'border-box',
    },
  },
  playerLid: {
    height: FILETOLIVE_CONTROLL_PLAYERSIZE.HEIGHT,
    width: FILETOLIVE_CONTROLL_PLAYERSIZE.WIDTH,
    position: 'relative',
    background: '#000000',
    border: '1px solid #F0F0F0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      background: '#f7f7f7',
      transition: 'all 400ms',
      '&:hover': {
        background: '#cccccc',
        border: 'none',
      },
    },
  },
  info: {
    margin: 0,
    padding: '10px 0 20px',
    fontSize: 13,
  },
  row: {
    display: 'flex',
    lineHeight: '28px',
  },
  title: {
    color: 'rgba(51, 51, 51, 0.5)',
    width: 145,
  },
  content: {
    width: 335,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  attention: {
    background: '#C3273305',
    border: ' 1px dashed #C32733',
    borderRadius: 4,
    padding: '10px 16px',
    margin: '0 30px',
    color: '#C32733',
    '& h3': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      marginBottom: 6,
    },
    '& ul': {
      paddingInlineStart: 20,
      margin: 0,
    },
    '& li': {
      listStyleType: 'none',
      textIndent: '-1em',
      fontSize: 12,
    },
  },
  buttons: {
    display: 'flex',
    borderTop: '1px solid #F0F0F0',
    marginTop: 24,
    padding: 19,
    '& button': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      paddingTop: 12,
      paddingBottom: 14,
      fontSize: 14,
      fontWeight: 700,
      color: '#FFFFFF',
      '&.Mui-disabled': {
        color: '#00000038',
        background: '#D8D8D8',
        borderBottom: '1px solid #D8D8D8',
      },
    },
  },
  spaceLine: {
    border: '1px dashed #898989',
    margin: '0 12px',
    opacity: 0.3,
  },
  liveStop: {
    width: 301,
    background: '#C32733',
    '&:hover': {
      background: '#A21B25',
    },
  },
  chatStop: {
    width: 162,
    background: '#C32733',
    '&:hover': {
      background: '#A21B25',
    },
    '&.Mui-disabled': {
      background: '#D8D8D8',
      borderBottom: '1px solid #AAAAAA',
    },
  },
  chatStart: {
    width: 162,
    background: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    '&:hover': {
      background: '#1F68DD',
    },
    '&.Mui-disabled': {
      background: '#D8D8D8',
      borderBottom: '1px solid #AAAAAA',
    },
  },
});

/** コントロールパネル 左(UI) */
const ControllerLeft = ({
  props,
}: {
  props: FileToLiveControllerProps;
}): JSX.Element => {
  const classes = useStyle();
  const {
    uiStatus,
    edition,
    chatStatus,
    finishStream,
    previewLid,
    openPreviewDialog,
    openChatDialog,
    flpid,
    liveEventInfoName,
    liveName,
    chatStartBtnDisabled,
    chatStopBtnDisabled,
    liveStatus,
    playerRef,
  } = props;
  const isLive = Boolean(liveStatus === FILE_TO_LIVE_STATUS.STREAMING);
  const processingChat = uiStatus === UI_STATUS.PROCESSING_CHAT;
  const isChatActive = Boolean(
    chatStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING
  );
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <h3 style={{ marginRight: 6 }}>
          <img src={Sensors} alt="Sensors" style={{ marginRight: 6 }} />
          ライブコントロール
        </h3>
        <Badge title={liveStatus} className={classes.badge} />
      </Box>
      <Box className={classes.body}>
        <Box className={classes.player}>
          {previewLid && (
            <Box className={classes.playerLid}>
              <Button
                onClick={openPreviewDialog.handleOpen}
                disabled={liveStatus === FILE_TO_LIVE_STATUS.NONE}
              >
                ライブ映像を表示
              </Button>
            </Box>
          )}
          {!previewLid && (
            <iframe
              referrerPolicy="no-referrer-when-downgrade"
              title="playerIframe-moniter"
              id="playerIframe-moniter"
              allowFullScreen
              frameBorder="0"
              ref={playerRef}
            />
          )}
        </Box>
        <Box className={classes.info}>
          <Box className={classes.row}>
            <Box className={classes.title}>疑似ライブID：</Box>
            <Box className={classes.content}>{flpid}</Box>
          </Box>
          {edition !== EDITIONS.U_LIVE && (
            <Box className={classes.row}>
              <Box className={classes.title}>疑似ライブイベント情報名：</Box>
              <Box className={classes.content}>{liveEventInfoName}</Box>
            </Box>
          )}
          <Box className={classes.row}>
            <Box className={classes.title}>疑似ライブ設定名：</Box>
            <Box className={classes.content}>{liveName}</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.attention}>
        <h3 style={{ fontSize: 14, paddingTop: 2 }}>
          <InfoOutlined
            fontSize="small"
            style={{ marginRight: 4, width: 15, height: 15 }}
          />
          ご注意事項
        </h3>
        <ul>
          <li>
            ※&ensp;疑似ライブ配信開始前に、必ず設定内容が正しいかご確認ください。
          </li>
          <li>※&ensp;視聴者数は30秒間隔で更新されます。</li>
        </ul>
      </Box>
      <Box className={classes.buttons}>
        <Button
          className={classes.liveStop}
          onClick={finishStream}
          disabled={!isLive}
        >
          <PauseCircleFilled />
          ライブ配信停止
        </Button>
        <span className={classes.spaceLine} />
        {processingChat ? (
          <Button
            className={isChatActive ? classes.chatStop : classes.chatStart}
            disabled
          >
            <CircularProgress size={25} />
          </Button>
        ) : (
          <Button
            className={isChatActive ? classes.chatStop : classes.chatStart}
            onClick={openChatDialog}
            disabled={isChatActive ? chatStopBtnDisabled : chatStartBtnDisabled}
          >
            {isChatActive ? <SpeakerNotesOff /> : <Message />}
            {`チャット${isChatActive ? '停止' : '開始'}`}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ControllerLeft;
