import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { LIVECONTROLLER_FLOW_ERR_MSG } from '../../utils/Message';
import {
  UI_STATUS,
  JCLOUD_SERVER_STATUS,
  LIVE_STATUS_ENG,
  EVENT_STATUS,
} from '../../utils/Const';
import { setContract } from '../../features/userSlice';
import { setError, setLiveControllerStatus } from '../../features/uiSlice';
import {
  addWlivesABRProfile,
  addEQLiveProfile,
  setLiveEventProfile,
  setWowzaFeed,
} from '../../features/livesSlice';
import {
  GetLiveProfile,
  GetJCloudServer,
  GetContract,
  GetLiveEvent,
  GetWowzaFeed,
} from '../../types/API';
import { FeedStateType } from '../../types/Utils';

type GetLiveControllerFlowParams = {
  cid: number;
  lpid: number;
  userApiParams: string;
  autoMode: boolean;
  setNoFeedNotification: React.Dispatch<React.SetStateAction<FeedStateType>>;
  setFeedinCnt: React.Dispatch<React.SetStateAction<FeedStateType>>;
};

/** コントロールパネル表示フロー */
const getLiveControllerFlow =
  (arg: GetLiveControllerFlowParams): AppThunk =>
  async (dispatch) => {
    try {
      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setContract({ ...responseGetContract.data }));

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
        {
          lpid: arg.lpid,
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          pageSize: 1,
          portal: responseGetContract.data.serviceFlg39 ? 1 : 0,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

      const EQlive = responseProfile.data.profile.find(
        (x) => x.lpid === arg.lpid
      );

      // ライブイベント情報取得API実行
      const responseEvent: AxiosResponse<GetLiveEvent> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
        { cid: arg.cid, userApiParams: arg.userApiParams, lpid: arg.lpid },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setLiveEventProfile({ ...responseEvent.data }));

      // JCloudの状態チェック
      if (EQlive?.jcloudWlives?.liveId) {
        // JCloudサーバー設定情報取得API実行
        const responseWlive: AxiosResponse<GetJCloudServer> = await axios.get(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}getJcloudServer/${EQlive.jcloudWlives.liveId}`,
          {
            params: {
              type: 'wlives',
              cid: arg.cid,
              lpid: arg.lpid,
            },
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(
          addWlivesABRProfile({ lpid: arg.lpid, ...responseWlive.data })
        );
        const wlive = responseWlive.data;

        // フィード状態チェック
        // Wowzaフィード確認API実行
        const responseWowza: AxiosResponse<GetWowzaFeed[]> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getWowzaFeed`,
          {
            wlivesIds: [wlive.wlives.id],
            lids: [
              {
                cid: arg.cid,
                userApiParams: arg.userApiParams,
                lpid: arg.lpid,
                customerDir: responseGetContract.data.directory,
              },
            ],
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(setWowzaFeed({ ...responseWowza.data[0] }));

        // 配信中にフィードが不安定の場合通知する
        if (EQlive?.status === LIVE_STATUS_ENG.OPEN) {
          // ライブ中のライブイベントを抽出
          const currentEvent = responseEvent.data.events.find(
            (x) => x.eventStatus === EVENT_STATUS.RECORDING
          );
          const feedinCnt = {
            mainFeed: Boolean(currentEvent?.mainFeedinCnt),
            backFeed: Boolean(currentEvent?.subFeedinCnt),
          };
          // フィードインの状態をuseStateに格納
          arg.setFeedinCnt(feedinCnt);
          // フィードインがあって現在フィードがない方にフィード不安定を通知する
          const mainfeedNotification =
            feedinCnt.mainFeed && !responseWowza.data[0].mainFeed;
          const backfeedNotification =
            feedinCnt.backFeed && !responseWowza.data[0].backFeed;

          arg.setNoFeedNotification({
            mainFeed: mainfeedNotification,
            backFeed: backfeedNotification,
          });

          // フィードインがなしで現在フィードがあるフィードを登録する
          const mainFeedinCnt =
            !feedinCnt.mainFeed && responseWowza.data[0].mainFeed;
          const backFeedinCnt =
            !feedinCnt.backFeed && responseWowza.data[0].backFeed;
          if (mainFeedinCnt || backFeedinCnt) {
            // ライブイベント情報更新API実行
            await axios.post(
              `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}setEvent`,
              {
                cid: arg.cid,
                userApiParams: arg.userApiParams,
                lpid: arg.lpid,
                leid: currentEvent?.leid,
                main_feedin_cnt: Number(
                  responseWowza.data[0].mainFeed || feedinCnt.mainFeed
                ),
                sub_feedin_cnt: Number(
                  responseWowza.data[0].backFeed || feedinCnt.backFeed
                ),
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
            // フィードインの状態をuseStateに格納
            arg.setFeedinCnt({
              mainFeed: responseWowza.data[0].mainFeed || feedinCnt.mainFeed,
              backFeed: responseWowza.data[0].backFeed || feedinCnt.backFeed,
            });
          }
        }

        // ここまで終わったら画面は表示する
        dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));

        // ACTIVATE状態チェック＆更新
        if (
          wlive?.wlives.status !== JCLOUD_SERVER_STATUS.CLOSED &&
          wlive?.wlives.status !== JCLOUD_SERVER_STATUS.CLOSING &&
          wlive?.wlives.status !== JCLOUD_SERVER_STATUS.OPENED &&
          wlive?.wlives.status !== JCLOUD_SERVER_STATUS.OPENING
        ) {
          // Activate中をセット
          dispatch(setLiveControllerStatus(UI_STATUS.ACTIVATING));
          // Activate状態にする
          await axios.post(
            `${String(
              process.env.REACT_APP_BACKEND_API_ENDPOINT
            )}updateLiveStatus`,
            {
              cid: arg.cid,
              userApiParams: arg.userApiParams,
              wlivesId: wlive?.wlives.id,
              lpid: arg.lpid,
              type: 'activate',
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
          // オートモード以外の場合、1時間後にDeactivateするジョブを発行しておく
          if (!arg.autoMode) {
            await axios.post(
              `${String(
                process.env.REACT_APP_BACKEND_API_ENDPOINT
              )}jobDispatch`,
              {
                type: 'deactivate',
                wlivesId: wlive.wlives.id,
                delayTime: 60,
                cid: arg.cid,
                userApiParams: arg.userApiParams,
                lpid: String(arg.lpid),
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
          }
        }
      }
      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVECONTROLLER_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default getLiveControllerFlow;
