import moment from 'moment';
import { CHAT_STATUS, LIVE_STATUS_ENG, PORTAL_STATUS } from '../Const';
import LiveEndImage from './images/live_end.png';
import LivePrepareImage from './images/live_preparing.png';
import { GetLiveProfile } from '../../types/API';

/** ライブプロファイル取得API Mockレスポンス */
export const LiveProfile = (
  pageNumber: number,
  portal: number
): GetLiveProfile => {
  if (pageNumber === 1) {
    return {
      livesum: 200,
      /** ライブプロファイルリスト */
      profile: [...Array(100)].map((x, i) => {
        let liveStatus: LIVE_STATUS_ENG = LIVE_STATUS_ENG.NONE;
        let chatStatus: CHAT_STATUS = CHAT_STATUS.NONE;
        let portalStatus: PORTAL_STATUS | undefined;
        const startDate = moment().add(-1, 'hours').add(i, 'minutes');

        if (i <= 50) {
          liveStatus = LIVE_STATUS_ENG.STOP;
          portalStatus = portal ? PORTAL_STATUS.NONE : undefined;
          if (i <= 2) {
            liveStatus = LIVE_STATUS_ENG.CREATING;
          }
          if (i >= 3 && i < 5) {
            liveStatus = LIVE_STATUS_ENG.ERROR;
          }
          if (i < 30) {
            chatStatus = CHAT_STATUS.STOP;
          }
          if (i >= 30 && i < 40) {
            chatStatus = CHAT_STATUS.STREAMING;
          }
        }
        if (i > 50) {
          liveStatus = LIVE_STATUS_ENG.STOP;
          portalStatus = portal ? PORTAL_STATUS.PRIVATE : undefined;
          if (i < 80) {
            chatStatus = CHAT_STATUS.STOP;
          }
          if (i >= 80 && i < 90) {
            chatStatus = CHAT_STATUS.STREAMING;
          }
        }
        return {
          lpid: i + 1,
          status: liveStatus,
          liveName: `ライブ配信設定名-${i}`,
          liveDescription: `ライブ配信設定説明-${i},ライブ配信設定説明-${i},`,
          groupId: 1,
          mpid: 2,
          maxClient: 20,
          liveappFlg: i % 3 === 2 ? 1 : 0,
          bitrateFhd: 0,
          bitrateHd: 0,
          bitrateHigh: 0,
          bitrateStandard: 0,
          bitrateLow: 0,
          vodFlg: 1,
          exlink: {
            enabled: 1,
            settings: [
              {
                index: 1,
                text: `関連ページリンクテキスト-${i}-1`,
                url: `https://${i}-1`,
              },
              {
                index: 2,
                text: `関連ページリンクテキスト-${i}-2`,
                url: `http://${i}-2`,
              },
              {
                index: 3,
                text: `関連ページリンクテキスト-${i}-3`,
                url: `https://${i}-3`,
              },
            ],
          },
          vlpid: 3,
          serverNumber: 2,
          chat: {
            enabled: i % 2 ? 1 : 0,
            status: chatStatus,
            autoResizeFlg: 0,
            width: 640,
            height: 360,
            goodBtn: 1,
            troubleBtn: 0,
            enterMsg: 0,
            adminDisplayName: '事務局',
            blockType: 0,
            blockWords: ['blockeord1', 'blockword2'],
            openTiming: -1,
            closeTiming: 40,
            iconTexts: [
              {
                id: 1,
                text: 'よくわかりました',
                activeFlg: 1,
              },
              {
                id: 2,
                text: 'メモします',
                activeFlg: 0,
              },
            ],
          },
          portalStatus,
          portalMeta: {
            eventName: `ライブイベント情報名-${i}`,
            description: `ライブイベント情報説明文-${i},ライブイベント情報説明文-${i}`,
            performer: `出演者-${i}`,
          },
          desktopEncoder: i % 3 === 1 ? 1 : 0,
          livePermalink: 'hogehoge',
          thumbFeedout: LiveEndImage.toString(),
          thumbFeedin: LivePrepareImage.toString(),
          thumbLiveopen: LiveEndImage.toString(),
          thumbFeedoutDatetime: 'yyyy-MM-dd HH:mm:ss',
          thumbFeedinDatetime: 'yyyy-MM-dd HH:mm:ss',
          thumbLiveopenDatetime: 'yyyy-MM-dd HH:mm:ss',
          updateDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
          insertDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
          encrypt: 1,
          dvr: 0,
          dvrCloseMinutes: 30,
          dvrCloseDate: null,
          lqpid: 4,
          jcloudWlives: {
            liveId: i > 25 ? `wlivesServer-${i}` : ``,
            hlsauthStreamId: {
              master: `hlsStream-master-${i}`,
              original: `hlsStream-original-${i}`,
              fhd: `hlsStream-fhd-${i}`,
              hd: `hlsStream-hd-${i}`,
              high: '',
              standard: `hlsStream-standard-${i}`,
              low: '',
            },
            hlsauthStreamIdMain: {
              master: `hlsStream-master-${i}`,
              original: `hlsStream-original-${i}`,
              fhd: `hlsStream-fhd-${i}`,
              hd: `hlsStream-hd-${i}`,
              high: '',
              standard: `hlsStream-standard-${i}`,
              low: '',
            },
            hlsauthStreamIdBack: {
              master: `hlsStream-master-${i}`,
              original: `hlsStream-original-${i}`,
              fhd: `hlsStream-fhd-${i}`,
              hd: `hlsStream-hd-${i}`,
              high: '',
              standard: `hlsStream-standard-${i}`,
              low: '',
            },
          },
        };
      }),
    };
  }
  return {
    livesum: 200,

    /** ライブプロファイルリスト */
    profile: [...Array(100)].map((x, i) => {
      let chatStatus: CHAT_STATUS = CHAT_STATUS.NONE;
      let dvrCloseDate: string | null = null;
      const startDate = moment().add(-1, 'hours').add(i, 'minutes');
      const closeDate = startDate.clone().add(20, 'minutes');
      const portalStatus: PORTAL_STATUS | undefined = portal
        ? PORTAL_STATUS.PUBLIC
        : undefined;

      if (i < 30) {
        chatStatus = CHAT_STATUS.STOP;
      }
      if (i >= 30 && i < 40) {
        chatStatus = CHAT_STATUS.STREAMING;
      }
      if (i > 50) {
        dvrCloseDate = closeDate.format('YYYY-MM-DDTHH:mm:ss.SS');
        if (i < 80) {
          chatStatus = CHAT_STATUS.STOP;
        }
        if (i >= 80 && i < 90) {
          chatStatus = CHAT_STATUS.STREAMING;
        }
      }
      return {
        lpid: i + 101,
        status: i > 50 ? LIVE_STATUS_ENG.STOP : LIVE_STATUS_ENG.OPEN,
        liveName: `ライブ配信設定名-${i + 100}`,
        liveDescription: `ライブ配信設定説明-${i + 100},ライブ配信設定説明-${
          i + 100
        },`,
        groupId: 1,
        mpid: 2,
        maxClient: 20,
        liveappFlg: i % 3 === 2 ? 1 : 0,
        bitrateFhd: 0,
        bitrateHd: 0,
        bitrateHigh: 0,
        bitrateStandard: 0,
        bitrateLow: 0,
        vodFlg: 1,
        exlink: {
          enabled: 1,
          settings: [
            {
              index: 1,
              text: `関連ページリンクテキスト-${i + 100}-1`,
              url: `https://${i + 100}-1`,
            },
            {
              index: 2,
              text: `関連ページリンクテキスト-${i + 100}-2`,
              url: `http://${i + 100}-2`,
            },
            {
              index: 3,
              text: `関連ページリンクテキスト-${i + 100}-3`,
              url: `https://${i + 100}-3`,
            },
          ],
        },
        vlpid: 3,
        serverNumber: 2,
        chat: {
          enabled: i % 2 ? 1 : 0,
          status: chatStatus,
          autoResizeFlg: 1,
          width: 640,
          height: 360,
          goodBtn: 1,
          troubleBtn: 0,
          enterMsg: 0,
          adminDisplayName: '事務局',
          blockType: 0,
          blockWords: ['blockeord1', 'blockword2'],
          openTiming: -1,
          closeTiming: 40,
          iconTexts: [
            {
              id: 1,
              text: 'よくわかりました',
              activeFlg: 1,
            },
            {
              id: 2,
              text: 'メモします',
              activeFlg: 0,
            },
          ],
        },
        portalStatus,
        portalMeta: {
          eventName: `ライブイベント情報名-${i + 100}`,
          description: `ライブイベント情報説明文-${
            i + 100
          },ライブイベント情報説明文-${i + 100}`,
          performer: `出演者-${i + 100}`,
        },
        desktopEncoder: i % 3 === 1 ? 1 : 0,
        livePermalink: 'hogehoge',
        thumbFeedout: LiveEndImage.toString(),
        thumbFeedin: LivePrepareImage.toString(),
        thumbLiveopen: LiveEndImage.toString(),
        thumbFeedoutDatetime: 'yyyy-MM-dd HH:mm:ss',
        thumbFeedinDatetime: 'yyyy-MM-dd HH:mm:ss',
        thumbLiveopenDatetime: 'yyyy-MM-dd HH:mm:ss',
        updateDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        insertDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        encrypt: 1,
        dvr: 0,
        dvrCloseMinutes: 30,
        dvrCloseDate,
        lqpid: 4,
        jcloudWlives: {
          liveId: `wlivesServer-${i + 100}`,
          hlsauthStreamId: {
            master: `hlsStream-master-${i + 100}`,
            original: `hlsStream-original-${i + 100}`,
            fhd: `hlsStream-fhd-${i + 100}`,
            hd: `hlsStream-hd-${i + 100}`,
            high: '',
            standard: `hlsStream-standard-${i + 100}`,
            low: '',
          },
          hlsauthStreamIdMain: {
            master: `hlsStream-master-${i + 100}`,
            original: `hlsStream-original-${i + 100}`,
            fhd: `hlsStream-fhd-${i + 100}`,
            hd: `hlsStream-hd-${i + 100}`,
            high: '',
            standard: `hlsStream-standard-${i + 100}`,
            low: '',
          },
          hlsauthStreamIdBack: {
            master: `hlsStream-master-${i + 100}`,
            original: `hlsStream-original-${i + 100}`,
            fhd: `hlsStream-fhd-${i + 100}`,
            hd: `hlsStream-hd-${i + 100}`,
            high: '',
            standard: `hlsStream-standard-${i + 100}`,
            low: '',
          },
        },
      };
    }),
  };
};

/** 1件だけライブプロファイル取得API Mockレスポンス */
export const LiveProfileOne = (
  lpid: number,
  portal: number
): GetLiveProfile => {
  let liveStatus: LIVE_STATUS_ENG = LIVE_STATUS_ENG.NONE;
  let chatStatus: CHAT_STATUS = CHAT_STATUS.NONE;
  let portalStatus: PORTAL_STATUS | undefined;
  let dvrCloseDate: string | null = null;

  const startDate = moment().add(-1, 'hours').add(lpid, 'minutes');
  const closeDate = startDate.clone().add(20, 'minutes');

  if (lpid <= 50) {
    liveStatus = LIVE_STATUS_ENG.STOP;
    portalStatus = portal ? PORTAL_STATUS.NONE : undefined;
    if (lpid <= 2) {
      liveStatus = LIVE_STATUS_ENG.CREATING;
    }
    if (lpid >= 3 && lpid < 5) {
      liveStatus = LIVE_STATUS_ENG.ERROR;
    }
    if (lpid < 30) {
      chatStatus = CHAT_STATUS.STOP;
    }
    if (lpid >= 30 && lpid < 40) {
      chatStatus = CHAT_STATUS.STREAMING;
    }
  }
  if (lpid > 50 && lpid <= 100) {
    liveStatus = LIVE_STATUS_ENG.STOP;
    portalStatus = portal ? PORTAL_STATUS.PRIVATE : undefined;
    if (lpid < 80) {
      chatStatus = CHAT_STATUS.STOP;
    }
    if (lpid >= 80 && lpid < 90) {
      chatStatus = CHAT_STATUS.STREAMING;
    }
  }
  if (lpid > 100) {
    liveStatus = LIVE_STATUS_ENG.OPEN;
    portalStatus = portal ? PORTAL_STATUS.PUBLIC : undefined;
    if (lpid > 150) dvrCloseDate = closeDate.format('YYYY-MM-DDTHH:mm:ss.SS');
    if (lpid < 130 || (lpid > 150 && lpid < 180)) {
      chatStatus = CHAT_STATUS.STOP;
    }
    if ((lpid >= 130 && lpid < 140) || (lpid >= 180 && lpid < 190)) {
      chatStatus = CHAT_STATUS.STREAMING;
    }
  }
  return {
    livesum: 150,

    /** ライブプロファイルリスト */
    profile: [
      {
        lpid,
        status: liveStatus,
        liveName: `ライブ配信設定名-${lpid}`,
        liveDescription: `ライブ配信設定説明-${lpid},ライブ配信設定説明-${lpid},`,
        groupId: 1,
        mpid: 2,
        maxClient: 20,
        liveappFlg: 1,
        bitrateFhd: 0,
        bitrateHd: 1,
        bitrateHigh: 0,
        bitrateStandard: 0,
        bitrateLow: 0,
        vodFlg: 1,
        exlink: {
          enabled: 1,
          settings: [
            {
              index: 1,
              text: `関連ページリンクテキスト-${lpid}-1`,
              url: `https://${lpid}-1`,
            },
            {
              index: 2,
              text: `関連ページリンクテキスト-${lpid}-2`,
              url: `http://${lpid}-2`,
            },
            {
              index: 3,
              text: `関連ページリンクテキスト-${lpid}-3`,
              url: `https://${lpid}-3`,
            },
          ],
        },
        vlpid: 3,
        chat: {
          enabled: 1,
          status: chatStatus,
          autoResizeFlg: 0,
          width: 640,
          height: 360,
          goodBtn: 1,
          troubleBtn: 0,
          enterMsg: 0,
          adminDisplayName: '事務局',
          blockType: 0,
          blockWords: ['blockeord1', 'blockword2'],
          openTiming: -1,
          closeTiming: 40,
          iconTexts: [
            {
              id: 1,
              text: 'よくわかりました',
              activeFlg: 1,
            },
            {
              id: 2,
              text: 'メモします',
              activeFlg: 0,
            },
          ],
        },
        portalStatus,
        portalMeta: {
          eventName: `ライブイベント情報名-${lpid}`,
          description: `ライブイベント情報説明文-${lpid},ライブイベント情報説明文-${lpid}`,
          performer: `出演者-${lpid}`,
        },
        desktopEncoder: 0,
        thumbFeedout: LiveEndImage.toString(),
        thumbFeedin: LivePrepareImage.toString(),
        thumbLiveopen: LiveEndImage.toString(),
        thumbFeedoutDatetime: '2021-11-22 18:56:24.443',
        thumbFeedinDatetime: '2021-11-22 18:56:24.443',
        thumbLiveopenDatetime: '2021-11-22 18:56:24.443',
        updateDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        encrypt: 1,
        dvr: 1,
        dvrCloseMinutes: 30,
        dvrCloseDate,
        lqpid: 4,
        jcloudWlives: {
          liveId: lpid > 25 ? `wlivesServer-${lpid}` : '',
          hlsauthStreamId: {
            master: `hlsStream-master-${lpid}`,
            original: `hlsStream-original-${lpid}`,
            fhd: `hlsStream-fhd-${lpid}`,
            hd: `hlsStream-hd-${lpid}`,
            high: '',
            standard: `hlsStream-standard-${lpid}`,
            low: '',
          },
          hlsauthStreamIdMain: {
            master: `hlsStream-master-${lpid}`,
            original: `hlsStream-original-${lpid}`,
            fhd: `hlsStream-fhd-${lpid}`,
            hd: `hlsStream-hd-${lpid}`,
            high: '',
            standard: `hlsStream-standard-${lpid}`,
            low: '',
          },
          hlsauthStreamIdBack: {
            master: `hlsStream-master-${lpid}`,
            original: `hlsStream-original-${lpid}`,
            fhd: `hlsStream-fhd-${lpid}`,
            hd: `hlsStream-hd-${lpid}`,
            high: '',
            standard: `hlsStream-standard-${lpid}`,
            low: '',
          },
        },
      },
    ],
  };
};

/** 任意件だけライブプロファイル取得API Mockレスポンス */
// export const LiveProfileSome = (lpid: number[]): GetLiveProfile => {
//   return {
//     livesum: 150,

//     /** ライブプロファイルリスト */
//     profile: [...Array(lpid.length)].map((x, i) => {
//       let liveStatus: LIVE_STATUS_ENG = LIVE_STATUS_ENG.NONE;
//       let chatStatus: CHAT_STATUS = CHAT_STATUS.NONE;
//       let dvrCloseDate: string | null = null;

//       const startDate = moment().add(-1, 'hours').add(lpid[i], 'minutes');
//       const closeDate = startDate.clone().add(20, 'minutes');

//       if (lpid[i] <= 50) {
//         liveStatus = LIVE_STATUS_ENG.STOP;
//         if (lpid[i] <= 2) {
//           liveStatus = LIVE_STATUS_ENG.CREATING;
//         }
//         if (lpid[i] >= 3 && lpid[i] < 5) {
//           liveStatus = LIVE_STATUS_ENG.ERROR;
//         }
//         if (lpid[i] < 30) {
//           chatStatus = CHAT_STATUS.STOP;
//         }
//         if (lpid[i] >= 30 && lpid[i] < 40) {
//           chatStatus = CHAT_STATUS.STREAMING;
//         }
//       }
//       if (lpid[i] > 50 && lpid[i] <= 100) {
//         liveStatus = LIVE_STATUS_ENG.PRE;
//         if (lpid[i] < 80) {
//           chatStatus = CHAT_STATUS.STOP;
//         }
//         if (lpid[i] >= 80 && lpid[i] < 90) {
//           chatStatus = CHAT_STATUS.STREAMING;
//         }
//       }
//       if (lpid[i] > 100) {
//         liveStatus = LIVE_STATUS_ENG.OPEN;
//         if (lpid[i] > 150)
//           dvrCloseDate = closeDate.format('YYYY-MM-DDTHH:mm:ss.SS');
//         if (lpid[i] < 130 || (lpid[i] > 150 && lpid[i] < 180)) {
//           chatStatus = CHAT_STATUS.STOP;
//         }
//         if (
//           (lpid[i] >= 130 && lpid[i] < 140) ||
//           (lpid[i] >= 180 && lpid[i] < 190)
//         ) {
//           chatStatus = CHAT_STATUS.STREAMING;
//         }
//       }
//       return {
//         lpid: lpid[i],
//         status: liveStatus,
//         liveName: `ライブ配信設定名-${lpid[i]}`,
//         liveDescription: `ライブ配信設定説明-${lpid[i]},ライブ配信設定説明-${lpid[i]},`,
//         groupId: 1,
//         mpid: 2,
//         maxClient: 20,
//         liveappFlg: 0,
//         bitrateFhd: 0,
//         bitrateHd: 1,
//         bitrateHigh: 0,
//         bitrateStandard: 0,
//         bitrateLow: 0,
//         vodFlg: 1,
//         exlink: {
//           enabled: 1,
//           settings: [
//             {
//               index: 1,
//               text: `関連ページリンクテキスト-${lpid[i]}-1`,
//               url: `https://${lpid[i]}-1`,
//             },
//             {
//               index: 2,
//               text: `関連ページリンクテキスト-${lpid[i]}-2`,
//               url: `http://${lpid[i]}-2`,
//             },
//             {
//               index: 3,
//               text: `関連ページリンクテキスト-${lpid[i]}-3`,
//               url: `https://${lpid[i]}-3`,
//             },
//           ],
//         },
//         vlpid: 3,
//         serverNumber: 2,
//         chat: {
//           enabled: 1,
//           status: chatStatus,
//           autoResizeFlg: 0,
//           width: 640,
//           height: 360,
//           goodBtn: 1,
//           troubleBtn: 0,
//           enterMsg: 0,
//           adminDisplayName: '事務局',
//           blockType: 0,
//           blockWords: ['blockeord1', 'blockword2'],
//           openTiming: -1,
//           closeTiming: 40,
//           iconTexts: [
//             {
//               id: 1,
//               text: 'よくわかりました',
//               activeFlg: 1,
//             },
//             {
//               id: 2,
//               text: 'メモします',
//               activeFlg: 0,
//             },
//           ],
//         },
//         portalMeta: {
//           eventName: `ライブイベント情報名-${lpid[i]}`,
//           description: `ライブイベント情報説明文-${lpid[i]},ライブイベント情報説明文-${lpid[i]}`,
//           performer: `出演者-${lpid[i]}`,
//         },
//         desktopEncoder: 0,
//         livePermalink: 'hogehoge',
//         thumbFeedout: LiveEndImage.toString(),
//         thumbFeedin: LivePrepareImage.toString(),
//         thumbLiveopen: LiveEndImage.toString(),
//         thumbFeedoutDatetime: 'yyyy-MM-dd HH:mm:ss',
//         thumbFeedinDatetime: 'yyyy-MM-dd HH:mm:ss',
//         thumbLiveopenDatetime: 'yyyy-MM-dd HH:mm:ss',
//         updateDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
//         insertDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
//         encrypt: 1,
//         dvr: 1,
//         dvrCloseMinutes: 30,
//         dvrCloseDate,
//         lqpid: 4,
//         jcloudWlives: {
//           liveId: `wlivesServer-${lpid[i]}`,
//           hlsauthStreamId: {
//             master: `hlsStream-master-${lpid[i]}`,
//             original: `hlsStream-original-${lpid[i]}`,
//             fhd: `hlsStream-fhd-${lpid[i]}`,
//             hd: `hlsStream-hd-${lpid[i]}`,
//             high: '',
//             standard: `hlsStream-standard-${lpid[i]}`,
//             low: '',
//           },
//           hlsauthStreamIdMain: {
//             master: `hlsStream-master-${lpid[i]}`,
//             original: `hlsStream-original-${lpid[i]}`,
//             fhd: `hlsStream-fhd-${lpid[i]}`,
//             hd: `hlsStream-hd-${lpid[i]}`,
//             high: '',
//             standard: `hlsStream-standard-${lpid[i]}`,
//             low: '',
//           },
//           hlsauthStreamIdBack: {
//             master: `hlsStream-master-${lpid[i]}`,
//             original: `hlsStream-original-${lpid[i]}`,
//             fhd: `hlsStream-fhd-${lpid[i]}`,
//             hd: `hlsStream-hd-${lpid[i]}`,
//             high: '',
//             standard: `hlsStream-standard-${lpid[i]}`,
//             low: '',
//           },
//         },
//       };
//     }),
//   };
// };

/** ライブプロファイル作成・更新API Mockレスポンス */
export const SetLiveProfile = {
  lpid: 1,
  errorCode: 0,
  message: '',
};
