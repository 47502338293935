// import { encryptSecurityContent } from '../DecryptSecurityContent';
import moment from 'moment';

/** ライブ新規登録プルダウンリスト取得API Mockレスポンス */
const LiveSetUpDataList = {
  /** ライブ自動画質設定取得APIの結果 */
  autoQuolity: {
    cid: 1,
    errorCode: 400,
    message: 'OK',
    profiles: [...Array(10)].map((x, i) => {
      const startDate = moment().add(10, 'hours').add(i, 'minutes');
      return {
        lqpid: i + 1,
        profileName: `ライブ自動画質設定名-${i + 1}`,
        defaultFlg: Number(i === 5),
        framerate: 60,
        quality: {
          original: {
            activeFlg: 1,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 0,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 1,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 1,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 1,
            videoBitrate: 282,
          },
        },
        updateDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
      };
    }),
  },
  /** マスタープロファイル取得APIの結果 */
  masters: {
    errorCode: 400,
    message: 'OK',
    profile: [...Array(10)].map((x, i) => {
      return {
        mpid: i + 1,
        profileName: `トランスコード設定名-${i + 1}`,
        defaultFlg: i === 0 ? '1' : '0',
        speedType: 0,
        bitratecheckFlg: true,
        thumbnailRatio: 1,
        trid: 2,
        rotateType: 0,
        rotateAngle: 90,
      };
    }),
  },
  /** 管理グループ取得APIの結果 */
  groups: {
    errorCode: 400,
    message: 'OK',
    group: [...Array(10)].map((x, i) => {
      return {
        grid: i + 1,
        groupName: `アーカイブ管理グループ設定名-${i + 1}`,
      };
    }),
  },

  /** 視聴制限プロファイル取得APIの結果 */
  viewLimits: {
    errorCode: 400,
    message: 'OK',
    profile: [...Array(10)].map((x, i) => {
      return {
        vlpid: i + 1,
        profileName: `視聴制限設定名-${i + 1}`,
        profileDetail: `視聴制限概要-${i + 1}`,
        details:
          'LPAQhYFhVOYX+LfV+8LHcvf8Jv5U7CylqC6dO2l+b+dLfQAWtaPmKg5nBD0OyiPSbeKb2/8dbZH8Wvv5Q+VdWdWoIlIpZmgA6sYj6PQE9XL7jmiQUUxYGg/+LvQPSRJ9eSNu89FBEQQN/bapFdJv6MOa/bKIB7VcYqgRY3AMnIbETSZykEHE3UgeCgdMlvQSRWwziTKcSnV36tOBSOL9CmbbZOYkcCLf6Ab5JLEKQ4DGDkr0nvqIk9E+Q0H4BxMY',
        // TODO: 暗号化のMockデータは一旦固定値を使用
        /*
        details: encryptSecurityContent({
          auth_mode: 'and',
          domainlimit_type: 1,
          domainlimit_list: [`stream.co.jp${i + 1}`, `co3.co.jp${i + 1}`],
          iplimit_type: 2,
          iplimit_list: [
            `${i + 1}.${i + 1}.${i + 1}.${i + 1}`,
            '202.79.12.3',
            '124.35.1.34/24',
          ],
          idlimit_type: 1,
          idlimit_list: [
            {
              id: `ID${i + 1}-1`,
              pw: `pwd${i + 1}-1`,
              hash_pw: '99024280cab824efca53a5d1341b9210',
            },
            {
              id: `ID${i + 1}-2`,
              pw: `pwd${i + 1}-2`,
              hash_pw: '36ddda5af915d91549d3ab5bff1bafec',
            },
          ],
          otlimit_type: 1,
          ogp_flg: 0,
          noindex_flg: 0,
          insert_date: '2020-01-01T00:00:00.000',
          update_date: '2020-01-01T00:00:00.000',
        }),
        */
      };
    }),
  },
};

// ライブ自動画質設定取得APIの結果にデスクトップ配信用のMockデータを追加してexport
export default {
  ...LiveSetUpDataList,
  autoQuolity: {
    ...LiveSetUpDataList.autoQuolity,
    profiles: [
      ...LiveSetUpDataList.autoQuolity.profiles,
      {
        lqpid: LiveSetUpDataList.autoQuolity.profiles.length + 1,
        profileName: `パススルーしない配信でフルHD画質が有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 0,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 1,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 0,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 1,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 1,
            videoBitrate: 282,
          },
        },
      },
      {
        lqpid: LiveSetUpDataList.autoQuolity.profiles.length + 2,
        profileName: `パススルーしない配信でHD画質が有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 0,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 0,
          },
          hd: {
            activeFlg: 1,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 1,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 1,
            videoBitrate: 282,
          },
        },
      },
      {
        lqpid: LiveSetUpDataList.autoQuolity.profiles.length + 3,
        profileName: `パススルー配信でHD画質が有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 1,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 1,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 0,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 0,
            videoBitrate: 282,
          },
        },
      },
      {
        lqpid: LiveSetUpDataList.autoQuolity.profiles.length + 4,
        profileName: `パススルー配信でオリジナル画質のみ有効`,
        defaultFlg: 0,
        framerate: 60,
        quality: {
          original: {
            activeFlg: 1,
            videoBitrate: 0,
          },
          fhd: {
            activeFlg: 0,
            videoBitrate: 4000,
          },
          hd: {
            activeFlg: 0,
            videoBitrate: 2000,
          },
          high: {
            activeFlg: 0,
            videoBitrate: 1056,
          },
          standard: {
            activeFlg: 0,
            videoBitrate: 532,
          },
          low: {
            activeFlg: 0,
            videoBitrate: 282,
          },
        },
      },
    ],
  },
};
