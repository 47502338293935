import moment from 'moment';
import LiveEndImage from './images/live_end.png';
import LivePrepareImage from './images/live_preparing.png';
import { CHAT_STATUS, FILE_TO_LIVE_STATUS_ENG } from '../Const';
import { GetFileToLiveProfile } from '../../types/API';

const liveStatus = [
  FILE_TO_LIVE_STATUS_ENG.WAITING,
  FILE_TO_LIVE_STATUS_ENG.READY,
  FILE_TO_LIVE_STATUS_ENG.STREAMING,
  FILE_TO_LIVE_STATUS_ENG.FINISHED,
  FILE_TO_LIVE_STATUS_ENG.ERROR,
];

const chatStatus = [CHAT_STATUS.STREAMING, CHAT_STATUS.STOP, CHAT_STATUS.NONE];
const chatRoomStatus = [0, 100, 200];

/** プレイヤーテスト用m3u8 */
const PLAYER_TEST_URL =
  'http://eqa922iwao.eq.webcdn.stream.ne.jp/www50/eqa922iwao/jmc_pub/jmc_pd/00001/23f31a5106d2457ea2de708ac27e1521.m3u8';

/** 疑似ライブプロファイル取得API Mockレスポンス */
export const FileToLiveProfile = (pageNumber: number): GetFileToLiveProfile => {
  const livesum = 250;
  const perPage = 100;
  let listNum = 100;
  const startNum = listNum * (pageNumber - 1) + 1;
  if (livesum - pageNumber * perPage < 0) {
    listNum = (livesum - pageNumber * perPage) * -1;
  }
  return {
    livesum,
    /** ライブプロファイルリスト */
    profile: [...Array(listNum)].map((x, i) => {
      const startDate = moment()
        .add(-1, 'hours')
        .add(i + startNum, 'minutes');
      const closeDate = startDate.clone().add(20, 'minutes');
      let status = liveStatus[i % liveStatus.length];
      if (status !== FILE_TO_LIVE_STATUS_ENG.ERROR) {
        if (startDate.diff(moment(), 'minutes') > 0) {
          status = FILE_TO_LIVE_STATUS_ENG.READY;
        }
        if (startDate.diff(moment(), 'minutes') > 30) {
          status = FILE_TO_LIVE_STATUS_ENG.WAITING;
        }
        if (startDate.diff(moment(), 'minutes') <= 0) {
          status = FILE_TO_LIVE_STATUS_ENG.STREAMING;
        }
        if (closeDate.diff(moment(), 'minutes') < 0) {
          status = FILE_TO_LIVE_STATUS_ENG.FINISHED;
        }
      }
      return {
        flpid: i + startNum,
        status,
        liveName: `疑似ライブ配信設定名-${i + startNum}`,
        liveDescription: `疑似ライブ配信設定説明-${
          i + startNum
        },疑似ライブ配信設定説明-${i + startNum},`,
        mid: 2,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        closeDate: closeDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        maxClient: 20,
        chatRoomId: 'ec72e567f8184a7e9b9b40003b9d5e2f',
        chatRoomAdminId: '61e35fd57155404b886b2855095cf0fd',
        chatRoomStatus: chatRoomStatus[i % chatRoomStatus.length],
        chat: {
          enabled: i % 2 ? 1 : 0,
          status: chatStatus[i % chatStatus.length],
          autoResizeFlg: 1,
          width: 640,
          height: 360,
          goodBtn: 1,
          troubleBtn: 0,
          enterMsg: 0,
          adminDisplayName: '事務局',
          blockType: 0,
          blockWords: ['blockeord1', 'blockword2'],
          openTiming: -1,
          closeTiming: 40,
          iconTexts: [
            {
              id: 1,
              text: 'よくわかりました',
              activeFlg: 1,
            },
            {
              id: 2,
              text: 'メモします',
              activeFlg: 0,
            },
          ],
        },
        portalMeta: {
          eventName: `疑似ライブイベント情報名-${i + startNum}`,
          description: `疑似ライブイベント情報説明文-${
            i + startNum
          },疑似ライブイベント情報説明文-${i + startNum}`,
          performer: `出演者-${i + startNum}`,
        },
        livePermalink: PLAYER_TEST_URL,
        thumbFeedout: LiveEndImage.toString(),
        thumbFeedin: LivePrepareImage.toString(),
        thumbLiveopen: LiveEndImage.toString(),
        thumbFeedoutDatetime: '2020-01-01T00:00:00.000',
        thumbFeedinDatetime: '2020-01-01T00:00:00.000',
        thumbLiveopenDatetime: '2020-01-01T00:00:00.000',
        prefillerMid: 20,
        postfillerMid: 0,
        updateDate: '2020-01-01T00:00:00.000',
        insertDate: '2020-01-01T00:00:00.000',
      };
    }),
  };
};

/** 任意件だけ疑似ライブプロファイル取得API Mockレスポンス */
export const FileToLiveProfileSome = (
  flpid: number[]
): GetFileToLiveProfile => {
  const livesum = 250;
  return {
    livesum,

    /** ライブプロファイルリスト */
    profile: [...Array(flpid.length)].map((x, i) => {
      const startDate = moment().add(-1, 'hours').add(flpid[i], 'minutes');
      const closeDate = startDate.clone().add(20, 'minutes');
      let status = liveStatus[flpid[i] % liveStatus.length];
      if (status !== FILE_TO_LIVE_STATUS_ENG.ERROR) {
        if (startDate.diff(moment(), 'minutes') > 0) {
          status = FILE_TO_LIVE_STATUS_ENG.READY;
        }
        if (startDate.diff(moment(), 'minutes') > 30) {
          status = FILE_TO_LIVE_STATUS_ENG.WAITING;
        }
        if (startDate.diff(moment(), 'minutes') <= 0) {
          status = FILE_TO_LIVE_STATUS_ENG.STREAMING;
        }
        if (closeDate.diff(moment(), 'minutes') < 0) {
          status = FILE_TO_LIVE_STATUS_ENG.FINISHED;
        }
      }
      return {
        flpid: flpid[i],
        status,
        liveName: `疑似ライブ配信設定名-${flpid[i]}`,
        liveDescription: `疑似ライブ配信設定説明-${flpid[i]},疑似ライブ配信設定説明-${flpid[i]},`,
        mid: 2,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        closeDate: closeDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        maxClient: 20,
        chatRoomId: 'ec72e567f8184a7e9b9b40003b9d5e2f',
        chatRoomAdminId: '61e35fd57155404b886b2855095cf0fd',
        chatRoomStatus: chatRoomStatus[flpid[i] % chatRoomStatus.length],
        chat: {
          enabled: 1,
          status: chatStatus[flpid[i] % chatStatus.length],
          autoResizeFlg: 0,
          width: 640,
          height: 360,
          goodBtn: 1,
          troubleBtn: 0,
          enterMsg: 0,
          adminDisplayName: '事務局',
          blockType: 0,
          blockWords: ['blockeord1', 'blockword2'],
          openTiming: -1,
          closeTiming: 40,
          iconTexts: [
            {
              id: 1,
              text: 'よくわかりました',
              activeFlg: 1,
            },
            {
              id: 2,
              text: 'メモします',
              activeFlg: 0,
            },
          ],
        },
        portalMeta: {
          eventName: `疑似ライブイベント情報名-${flpid[i]}`,
          description: `疑似ライブイベント情報説明文-${flpid[i]},疑似ライブイベント情報説明文-${flpid[i]}`,
          performer: `出演者-${flpid[i]}`,
        },
        livePermalink: PLAYER_TEST_URL,
        thumbFeedout: LiveEndImage.toString(),
        thumbFeedin: LivePrepareImage.toString(),
        thumbLiveopen: LiveEndImage.toString(),
        thumbFeedoutDatetime: '2020-01-01T00:00:00.000',
        thumbFeedinDatetime: '2020-01-01T00:00:00.000',
        thumbLiveopenDatetime: '2020-01-01T00:00:00.000',
        prefillerMid: 20,
        postfillerMid: 0,
        updateDate: '2020-01-01T00:00:00.000',
        insertDate: '2020-01-01T00:00:00.000',
      };
    }),
  };
};

/** 1件だけ疑似ライブプロファイル取得API Mockレスポンス */
export const FileToLiveProfileOne = (flpid: number): GetFileToLiveProfile => {
  const livesum = 250;

  const startDate = moment().add(-1, 'hours').add(flpid, 'minutes');
  const closeDate = startDate.clone().add(20, 'minutes');
  let status = liveStatus[(flpid - 1) % liveStatus.length];
  if (status !== FILE_TO_LIVE_STATUS_ENG.ERROR) {
    if (startDate.diff(moment(), 'minutes') > 0) {
      status = FILE_TO_LIVE_STATUS_ENG.READY;
    }
    if (startDate.diff(moment(), 'minutes') > 30) {
      status = FILE_TO_LIVE_STATUS_ENG.WAITING;
    }
    if (startDate.diff(moment(), 'minutes') <= 0) {
      status = FILE_TO_LIVE_STATUS_ENG.STREAMING;
    }
    if (closeDate.diff(moment(), 'minutes') < 0) {
      status = FILE_TO_LIVE_STATUS_ENG.FINISHED;
    }
  }
  return {
    livesum,
    /** 疑似ライブプロファイルリスト */
    profile: [
      {
        flpid,
        status,
        liveName: `疑似ライブ配信設定名-${flpid}`,
        liveDescription: `疑似ライブ配信設定説明-${flpid},疑似ライブ配信設定説明-${flpid},`,
        mid: 2,
        startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        closeDate: closeDate.format('YYYY-MM-DDTHH:mm:ss.SS'),
        maxClient: 20,
        chatRoomId: 'ec72e567f8184a7e9b9b40003b9d5e2f',
        chatRoomAdminId: '61e35fd57155404b886b2855095cf0fd',
        chatRoomStatus: chatRoomStatus[flpid % chatRoomStatus.length],
        chat: {
          enabled: 1,
          status: chatStatus[flpid % chatStatus.length],
          autoResizeFlg: 0,
          width: 640,
          height: 360,
          goodBtn: 1,
          troubleBtn: 0,
          enterMsg: 0,
          adminDisplayName: '事務局',
          blockType: 0,
          blockWords: ['blockeord1', 'blockword2'],
          openTiming: -1,
          closeTiming: -1,
          iconTexts: [
            {
              id: 1,
              text: 'よくわかりました',
              activeFlg: 1,
            },
            {
              id: 2,
              text: 'メモします',
              activeFlg: 0,
            },
          ],
        },
        portalMeta: {
          eventName: `疑似ライブイベント情報名-${flpid}`,
          description: `疑似ライブイベント情報説明文-${flpid},疑似ライブイベント情報説明文-${flpid}`,
          performer: `出演者-${flpid}`,
        },
        livePermalink: PLAYER_TEST_URL,
        thumbFeedout: LiveEndImage.toString(),
        thumbFeedin: LivePrepareImage.toString(),
        thumbLiveopen: LiveEndImage.toString(),
        thumbFeedoutDatetime: '2020-01-01T00:00:00.000',
        thumbFeedinDatetime: '2020-01-01T00:00:00.000',
        thumbLiveopenDatetime: '2020-01-01T00:00:00.000',
        prefillerMid: 20,
        postfillerMid: 0,
      },
    ],
  };
};

/** 疑似ライブプロファイル作成・更新API Mockレスポンス */
export const SetFileToLiveProfile = {
  flpid: 1,
  errorCode: 0,
  message: '',
};
