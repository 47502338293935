import { ONOFF, PLAYER_ASPECTFIX } from '../Const';

/** プレイヤー一覧取得API Mockレスポンス */
const PlayerProfile = {
  errorCode: 0,
  message: 'OK',
  cid: 1124,
  players: [...Array(10)].map((x, i) => {
    return {
      pid: i,
      name: `プレイヤー名-${i}`,
      defaultFlg: i === 1,
      width: 640,
      height: 360,
      lightwidth: 640,
      lightheight: 360,
      aspectratio: PLAYER_ASPECTFIX.XVIIVFIX,
      protocol: 'protocol',
      playertype: 11,
      sound: ONOFF.OFF,
      control: ONOFF.ON,
      volume: ONOFF.ON,
      playpause: ONOFF.ON,
      seekbar: i % 2 ? ONOFF.ON : ONOFF.OFF,
      hidescreen: ONOFF.ON,
      autostart: ONOFF.ON,
      autoresize: i % 2 ? ONOFF.ON : ONOFF.OFF,
      fullscreen: ONOFF.ON,
      useendimage: ONOFF.ON,
      footer: `フッターテキスト-${i}`,
      footeralign: 'center',
      title: ONOFF.ON,
      multibitrate: ONOFF.ON,
      exlink: ONOFF.ON,
      tag: ONOFF.ON,
      sns: ONOFF.ON,
      facebook: ONOFF.OFF,
      twitter: ONOFF.ON,
      mixi: ONOFF.ON,
      google: ONOFF.ON,
      line: ONOFF.ON,
      replay: ONOFF.ON,
      inboundlink: ONOFF.ON,
      resume: ONOFF.ON,
      watermark: ONOFF.ON,
      watermarkDesign: '2',
      playbackRate1: 0.5,
      playbackRate2: 0.7,
      playbackRate3: 1.0,
      playbackRate4: 1.5,
      playbackRate5: 2,
      subtitle: ONOFF.ON,
      qualityPc: 92,
      qualitySd: 92,
      loopPlay: ONOFF.ON,
      playlistAutoplay: ONOFF.ON,
      forbackwardBtn: ONOFF.ON,
      forbackwardBtnSecond: 10,
      backwardBtn: ONOFF.ON,
      backwardBtnSecond: 10,
      forwardBtn: ONOFF.ON,
      forwardBtnSecond: 10,
      chapter: ONOFF.ON,
      videoanalyzerCookie: ONOFF.ON,
      resumeCookie: i % 2 === 0 ? ONOFF.OFF : ONOFF.ON,
    };
  }),
};

export default PlayerProfile;
