import { EQLivePlayerBparam, EQLivePlayerSrc, ONOFF } from './Const';

export type EQLivePlayerPropsType = {
  playerRef: React.RefObject<HTMLIFrameElement>;
  customerDirectory: string;
  tagId: string;
  player: {
    /** jstream_t3.PlayerFactoryObjcreateメソッドのパラメータ */
    playerParams: {
      /** プレイヤー構成ファイルのパス */
      b: string;
      /** cid base64 ex. 'MzQ5OA==' */
      c: string;
      /** lpid, サンプルソースの場合 "0" */
      lpid: string;
      /** プレイヤー機能設定 */
      s: {
        /** 埋め込みサイズ 幅 */
        wp: number;
        /** 埋め込みサイズ 高さ */
        hp: number;
        /** レスポンスシブ 'fit' | 'off' */
        rp?: string;
        /** 再生時のデフォルト画質 */
        dq?: number;
        /** スマホ用デフォルト画質 */
        mdq?: number;
        /** 再生ボタンの有無 */
        pb?: ONOFF;
        /** 中央再生ボタン表示有無 */
        wm?: ONOFF;
        /** コントロールパネル有無 */
        cp?: ONOFF;
        /** 音声ミュート 音量調節有無 */
        vc?: ONOFF;
        /** 全画面ボタンの有無 */
        fs?: ONOFF;
        /** フッター文字 URLエンコード */
        ft?: string;
        /** フッター表示位置 */
        fa?: 'right' | 'left' | 'center';
        /** タイトル表示の有無 */
        ti?: ONOFF;
        /** 初期音声の有無 */
        is?: ONOFF;
        /** 自動再生の有無 */
        ip?: ONOFF;
        /** 外部リンク表示の有無 */
        el?: ONOFF;
        /** タグ表示の有無 */
        tg?: ONOFF;
        /** インバウンドリンクの有無 */
        il?: ONOFF;
        /** 画質選択ボタン表示の有無 */
        mb?: ONOFF;
        /** 表示するSNSボタンのリスト最大3つまで指定可 ex. f,t,l */
        sn?: string;
        /** レジューム機能の有無 */
        rs?: ONOFF;
        /** メイン、バックアップの系統選択 1:メイン系 2:バックアップ系 */
        line?: number;
        /** 視聴制限や同時接続数制限を無視しない('1')・する('0') */
        isPreview?: string;
        /** VAビーコンの送出する・しない */
        va: ONOFF;
      };
    };
  };
};

export const EQLivePlayer = (props: EQLivePlayerPropsType): void => {
  props.player.playerParams.b = EQLivePlayerBparam(props.customerDirectory);
  props.player.playerParams.s.va = ONOFF.OFF;

  const iframeDocument = props.playerRef.current?.contentDocument;

  iframeDocument?.write(
    '<!DOCTYPE html><html><head></head><body style="margin: 0;"></body></html>'
  );

  const wrapperElement = document.createElement('div');
  wrapperElement.id = `EQplayerScript_${props.tagId}`;

  // プレイヤー取得
  const scriptTag = document.createElement('script');
  scriptTag.id = `LoadScript_${props.tagId}`;
  scriptTag.type = 'text/javascript';
  /** obj.jsのpath */
  scriptTag.src = EQLivePlayerSrc(props.customerDirectory);
  scriptTag.async = true;
  // obj.js(loadScript)の読み込み完了したらjstream_t3.PlayerFactoryObj.createのscriptを埋め込み(処理実行)
  scriptTag.onload = () => {
    const scriptTag2 = document.createElement('script');
    scriptTag2.type = 'text/javascript';
    scriptTag2.id = `PlayerFactoryLiveOBJ_${props.tagId}`;
    const exec = document.createTextNode(`
      jstream_t3.PlayerFactoryLiveOBJ.create(
        ${JSON.stringify(props.player.playerParams)
          .replace(/"([^"]+)":/g, '$1:')
          .replace(/\uFFFF/g, '\\"')}
      );
    `);
    scriptTag2.appendChild(exec);
    wrapperElement?.appendChild(scriptTag2);
  };
  wrapperElement?.appendChild(scriptTag);
  iframeDocument?.body.appendChild(wrapperElement);
};

export type EQFileToLivePlayerPropsType = {
  playerRef: React.RefObject<HTMLIFrameElement>;
  customerDirectory: string;
  tagId: string;
  player: {
    /** jstream_t3.PlayerFactoryObjcreateメソッドのパラメータ */
    playerParams: {
      /** プレイヤー構成ファイルのパス */
      b: string;
      /** cid base64 ex. 'MzQ5OA==' */
      c: string;
      /** flpid, サンプルソースの場合 "0" */
      flpid: string;
      /** 動画ID */
      m: string;
      /** プレイヤー機能設定 */
      s: {
        /** 埋め込みサイズ 幅 */
        wp: number;
        /** 埋め込みサイズ 高さ */
        hp: number;
        /** レスポンスシブ 'fit' | 'off' */
        rp?: string;
        /** 再生時のデフォルト画質 */
        dq?: number;
        /** スマホ用デフォルト画質 */
        mdq?: number;
        /** 再生ボタンの有無 */
        pb?: ONOFF;
        /** 中央再生ボタン表示有無 */
        wm?: ONOFF;
        /** コントロールパネル有無 */
        cp?: ONOFF;
        /** 音声ミュート 音量調節有無 */
        vc?: ONOFF;
        /** 全画面ボタンの有無 */
        fs?: ONOFF;
        /** フッター文字 URLエンコード */
        ft?: string;
        /** フッター表示位置 */
        fa?: 'right' | 'left' | 'center';
        /** タイトル表示の有無 */
        ti?: ONOFF;
        /** 初期音声の有無 */
        is?: ONOFF;
        /** 自動再生の有無 */
        ip?: ONOFF;
        /** 外部リンク表示の有無 */
        el?: ONOFF;
        /** タグ表示の有無 */
        tg?: ONOFF;
        /** インバウンドリンクの有無 */
        il?: ONOFF;
        /** 画質選択ボタン表示の有無 */
        mb?: ONOFF;
        /** 表示するSNSボタンのリスト最大3つまで指定可 ex. f,t,l */
        sn?: string;
        /** レジューム機能の有無 */
        rs?: ONOFF;
        /** メイン、バックアップの系統選択 1:メイン系 2:バックアップ系 */
        line?: number;
        /** 視聴制限や同時接続数制限を無視しない('1')・する('0') */
        isPreview?: string;
        /** VAビーコンの送出する・しない */
        va: ONOFF;
      };
    };
  };
};

export const EQFileToLivePlayer = (
  props: EQFileToLivePlayerPropsType
): void => {
  props.player.playerParams.b = EQLivePlayerBparam(props.customerDirectory);
  props.player.playerParams.s.va = ONOFF.OFF;

  const iframeDocument = props.playerRef.current?.contentDocument;

  iframeDocument?.write(
    '<!DOCTYPE html><html><head></head><body style="margin: 0;"></body></html>'
  );

  const wrapperElement = document.createElement('div');
  wrapperElement.id = `EQplayerScript_${props.tagId}`;

  // プレイヤー取得
  const scriptTag = document.createElement('script');
  scriptTag.id = `LoadScript_${props.tagId}`;
  scriptTag.type = 'text/javascript';
  /** obj.jsのpath */
  scriptTag.src = EQLivePlayerSrc(props.customerDirectory);
  scriptTag.async = true;
  // obj.js(loadScript)の読み込み完了したらjstream_t3.PlayerFactoryObj.createのscriptを埋め込み(処理実行)
  scriptTag.onload = () => {
    const scriptTag2 = document.createElement('script');
    scriptTag2.type = 'text/javascript';
    scriptTag2.id = `PlayerFactoryLiveOBJ_${props.tagId}`;
    const exec = document.createTextNode(`
      jstream_t3.PlayerFactoryLiveOBJ.create(
        ${JSON.stringify(props.player.playerParams)
          .replace(/"([^"]+)":/g, '$1:')
          .replace(/\uFFFF/g, '\\"')}
      );
    `);
    scriptTag2.appendChild(exec);
    wrapperElement?.appendChild(scriptTag2);
  };
  wrapperElement?.appendChild(scriptTag);
  iframeDocument?.body.appendChild(wrapperElement);
};
