import React from 'react';
import { Controller } from 'react-hook-form';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormSelectBoxProps } from '../../../props/Parts';

/** ReactHookForm用セレクトボックス */
const FormSelectBox = ({
  name,
  width,
  list,
  control,
  defaultValue = '',
  disabled = false,
  validationError = false,
  validationErrorMsg = '',
  validation,
  className,
}: FormSelectBoxProps): JSX.Element => {
  return (
    <FormControl error={validationError}>
      <Controller
        name={`${name}` as const}
        control={control}
        defaultValue={defaultValue}
        rules={validation}
        render={({ field }) => (
          <Select
            variant="outlined"
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            style={{ width, height: 40, background: '#FFF' }}
            inputProps={{ 'aria-label': 'Without label' }}
            disabled={disabled}
            defaultValue={defaultValue}
            className={className}
            {...field}
          >
            {list.map((item) =>
              item.label ? (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ) : (
                <MenuItem key={item.value} value={item.value}>
                  &nbsp;
                </MenuItem>
              )
            )}
          </Select>
        )}
      />
      {validationErrorMsg && (
        <FormHelperText>{validationErrorMsg}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSelectBox;
