import React, { useEffect, useState } from 'react';
import {
  UseFormRegister,
  UseFormClearErrors,
  UseFormWatch,
  Control,
  FieldValues,
  RegisterOptions,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { InfoOutlined, Help, ThumbUp } from '@material-ui/icons';
import FormTextBox from '../parts/FormTextBox/FormTextBox';
import FormRadioButton from '../parts/FormRadioButton/FormRadioButton';
import FormSelectBox from '../parts/FormSelectBox/FormSelectBox';
import FormCheckBox from '../parts/FormCheckBox/FormCheckBox';
import {
  REQUIRED_MSG,
  getMaxValueMsg,
  getMinValueMsg,
} from '../../utils/Message';
import {
  CHAT_START_TIMING,
  CHAT_END_TIMING,
  CHAT_BLOCK_TYPE,
  CHAT_ENTER_MSG,
  CHAT_MNG_DISPLAY_NAME,
  EDITIONS,
} from '../../utils/Const';
import { FileToLiveContentProps } from '../../props/FileToLiveContent';
import Tooltip from '../parts/Tooltip/Tooltip';
import { FileToLiveRegisterProps } from '../../props/FileToLiveRegister';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& p': {
      margin: 0,
      fontSize: 13,
    },
    '& a': {
      color: '#4472C4',
    },
    '& .MuiGrid-container': {
      marginTop: 0,
      marginBottom: 10,
    },
    '& .MuiGrid-spacing-xs-8 > .MuiGrid-item': {
      padding: '16px 30px',
      paddingRight: 2,
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 30,
    marginBottom: 30,
    fontSize: 15,
  },
  rowRadioButton: {
    '& .MuiFormControlLabel-label p': {
      marginBottom: 0,
      lineHeight: '28px',
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      marginBottom: 28,
    },
    '& .MuiFormControlLabel-root:last-child .MuiIconButton-root': {
      marginBottom: 34,
    },
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '16px 22px 0',
    color: '#333333',
  },
  whiteBox: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    padding: 15,
    marginTop: 20,
  },
  discription: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 13,
    color: '#333333',
    opacity: 0.6,
  },
  inputText: {
    background: '#FFFFFF',
    height: 28,
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '4px 10px',
    },
  },
  inputOther: {
    background: '#FFFFFF',
    height: 40,
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: 10,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 10,
    },
  },
  inputOtherName: {
    background: '#FFFFFF',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: 10,
      height: 40,
      boxSizing: 'border-box',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      height: 0,
      marginBottom: 4,
    },
  },
  helpContainer: {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  help: {
    color: '#B6BEC9',
    marginLeft: 6,
    marginBottom: '-2px',
  },
  icon: {
    color: '#333333',
    marginRight: 4,
  },
  reactionButton: {
    border: '1px solid #B9B9B9',
    background: '#FFFFFF',
    color: '#333333',
    padding: '4px 10px',
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 100,
    display: 'flex',
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 4,
    },
  },
  inpuReaction: {
    background: '#FFFFFF',
    color: '#333333',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '7px 10px',
      fontSize: 12,
      borderRadius: 100,
    },
    '& .MuiOutlinedInput-notchedOutline, .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled  .MuiOutlinedInput-notchedOutline':
      {
        borderRadius: 100,
        border: '1px dashed #B9B9B9',
      },
    '&.checked .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
    },
    '&.unEmpty .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled  .MuiOutlinedInput-notchedOutline':
      {
        borderStyle: 'solid',
      },
  },
  admin: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
  },
});

/** チャット設定 */
const ChatSetting = ({
  register,
  control,
  errors,
  watch,
  clearErrors,
  setError,
  setValue,
  trigger,
  RegisterProps,
  ContentProps,
  open = false,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  errors?: Record<string, { message: string }>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  RegisterProps?: FileToLiveRegisterProps;
  ContentProps?: FileToLiveContentProps;
  open?: boolean;
}): JSX.Element => {
  const classes = useStyle();
  const edition = RegisterProps?.edition || ContentProps?.edition;
  // TODO 配信状態で切り分ける
  const disableFlg = ContentProps?.disableFlg || false;
  const contentSetting = ContentProps?.chatSetting;
  const [ChatSettingAutoResizeWidth, setChatSettingAutoResizeWidth] =
    useState<RegisterOptions>({
      required: false,
      min: { value: 300, message: getMinValueMsg(300) },
      max: { value: 1920, message: getMaxValueMsg(1920) },
      pattern: /^[0-9]+$/,
    });
  const [ChatSettingAutoResizeHeight, setChatSettingAutoResizeHeight] =
    useState<RegisterOptions>({
      required: false,
      min: { value: 320, message: getMinValueMsg(320) },
      max: { value: 1080, message: getMaxValueMsg(1080) },
      pattern: /^[0-9]+$/,
    });
  const [
    ChatSettingEndTimingAutoEndMinutes,
    setChatSettingEndTimingAutoEndMinutes,
  ] = useState<RegisterOptions>({
    required: false,
    min: { value: 1, message: getMinValueMsg(1) },
    max: { value: 60, message: getMaxValueMsg(60) },
    pattern: /^[0-9]+$/,
  });
  const [
    ChatSettingAdminDisplayNameOther,
    setChatSettingAdminDisplayNameOther,
  ] = useState<RegisterOptions>({
    required: false,
  });
  const [ChatSettingCustomBtn1Name, setChatSettingCustomBtn1Name] =
    useState<RegisterOptions>({
      required: false,
      maxLength: 10,
    });
  const [ChatSettingCustomBtn2Name, setChatSettingCustomBtn2Name] =
    useState<RegisterOptions>({
      required: false,
      maxLength: 10,
    });
  const [isOverReactionSelectLimit, setSelectedReactionLimitFlg] =
    useState(false);

  const getAdminNameFlg = (admin?: string) => {
    switch (admin) {
      case CHAT_MNG_DISPLAY_NAME.MNG:
      case CHAT_MNG_DISPLAY_NAME.OPE:
      case CHAT_MNG_DISPLAY_NAME.SEC:
        return true;
      default:
        return false;
    }
  };

  const [enterMsgDisableFlg, setEnterMsgDisableFlg] = useState(
    edition === EDITIONS.U_LIVE
  );

  useEffect(() => {
    const flg = watch('ChatSettingAutoResize') === 'true' || disableFlg;
    setChatSettingAutoResizeWidth({
      ...ChatSettingAutoResizeWidth,
      required: flg ? false : REQUIRED_MSG,
    });
    setChatSettingAutoResizeHeight({
      ...ChatSettingAutoResizeHeight,
      required: flg ? false : REQUIRED_MSG,
    });
    if (flg) {
      clearErrors('ChatSettingAutoResizeWidth');
      clearErrors('ChatSettingAutoResizeHeight');
      setValue(
        'ChatSettingAutoResizeWidth',
        contentSetting?.size?.width || 400
      );
      setValue(
        'ChatSettingAutoResizeHeight',
        contentSetting?.size?.height || 600
      );
    } else {
      trigger('ChatSettingAutoResizeWidth');
      trigger('ChatSettingAutoResizeHeight');
    }
  }, [watch('ChatSettingAutoResize')]);

  useEffect(() => {
    const flg =
      watch('ChatSettingEndTiming') === String(CHAT_END_TIMING.LIVE_AUTO) ||
      watch('ChatSettingEndTiming') === String(CHAT_END_TIMING.MANUAL) ||
      disableFlg;
    setChatSettingEndTimingAutoEndMinutes({
      ...ChatSettingEndTimingAutoEndMinutes,
      required: flg ? false : REQUIRED_MSG,
    });
    if (flg) {
      clearErrors('ChatSettingEndTimingAutoEndMinutes');
      setValue(
        'ChatSettingEndTimingAutoEndMinutes',
        contentSetting?.autoEndMinutes || ''
      );
    } else {
      // NOTE: 赤枠を付けて必須であることを強調させるため空でエラーセット
      setError('ChatSettingEndTimingAutoEndMinutes', {});
    }
  }, [watch('ChatSettingEndTiming')]);

  useEffect(() => {
    const flg = watch('ChatSettingAdminDisplayName') === 'true' || disableFlg;
    if (flg) {
      setValue(
        'ChatSettingAdminDisplayNameOther',
        getAdminNameFlg(contentSetting?.adminDisplayName)
          ? ''
          : contentSetting?.adminDisplayName
      );
    }
    const setValidation = async () => {
      await setChatSettingAdminDisplayNameOther({
        ...ChatSettingAdminDisplayNameOther,
        required: flg ? false : REQUIRED_MSG,
      });
      trigger('ChatSettingAdminDisplayNameOther');
    };
    setValidation();
  }, [watch('ChatSettingAdminDisplayName')]);

  useEffect(() => {
    const trueCount = [
      watch('ChatSettingGoodBtn'),
      watch('ChatSettingTroubleBtn'),
      watch('ChatSettingCustomBtn1Enable'),
      watch('ChatSettingCustomBtn2Enable'),
    ].filter((item) => item === true);
    setSelectedReactionLimitFlg(trueCount.length > 1);
    // カスタムボタン 入力欄の必須チェック
    const customBtn1Flg = !disableFlg && watch('ChatSettingCustomBtn1Enable');
    const customBtn2Flg = !disableFlg && watch('ChatSettingCustomBtn2Enable');
    const setValidation = async () => {
      await setChatSettingCustomBtn1Name({
        ...ChatSettingCustomBtn1Name,
        required: customBtn1Flg ? REQUIRED_MSG : false,
      });
      await setChatSettingCustomBtn2Name({
        ...ChatSettingCustomBtn2Name,
        required: customBtn2Flg ? REQUIRED_MSG : false,
      });
      trigger('ChatSettingCustomBtn1Name');
      trigger('ChatSettingCustomBtn2Name');
    };
    setValidation();
  }, [
    watch('ChatSettingGoodBtn'),
    watch('ChatSettingTroubleBtn'),
    watch('ChatSettingCustomBtn1Enable'),
    watch('ChatSettingCustomBtn2Enable'),
  ]);

  useEffect(() => {
    const maxClient = watch('BaseSettingMaxClient');
    if (maxClient < 1001 && enterMsgDisableFlg) {
      setValue('ChatSettingEnterMsg', String(CHAT_ENTER_MSG.DISPLAY));
      setEnterMsgDisableFlg(false);
    }
    if (
      maxClient > 1000 ||
      (edition === EDITIONS.U_LIVE && !enterMsgDisableFlg)
    ) {
      setValue('ChatSettingEnterMsg', String(CHAT_ENTER_MSG.NONE));
      setEnterMsgDisableFlg(true);
    }
  }, [watch('BaseSettingMaxClient')]);

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        チャットの設定を行います。チャットのデフォルトのサイズやブロックする単語などを設定します。
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          チャットの使用
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormRadioButton
              name="ChatSettingEnable"
              control={control}
              disabled={disableFlg}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                contentSetting ? contentSetting?.enabled : false
              }
              list={[
                {
                  label: '使用する',
                  value: true,
                },
                {
                  label: '使用しない',
                  value: false,
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          チャットのデフォルトサイズ
        </Grid>
        <Grid item xs={9}>
          <Box className={`${classes.greenBack} ${classes.rowRadioButton}`}>
            <FormRadioButton
              name="ChatSettingAutoResize"
              control={control}
              disabled={disableFlg}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                contentSetting ? contentSetting?.autoResize : false
              }
              list={[
                {
                  label: (
                    <>
                      チャットの自動リサイズ（レスポンシブデザイン）
                      <p className={classes.discription}>
                        <InfoOutlined
                          fontSize="small"
                          className={classes.icon}
                        />
                        埋め込み先の親要素によって指定された縦・横サイズに、自動でリサイズされます。
                      </p>
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      指定のサイズ
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 500,
                          paddingTop: 10,
                        }}
                      >
                        表示サイズ：
                        <FormTextBox
                          name="ChatSettingAutoResizeWidth"
                          width={80}
                          register={register}
                          className={classes.inputText}
                          validation={ChatSettingAutoResizeWidth}
                          numberOnly
                          disabled={
                            Boolean(
                              watch('ChatSettingAutoResize') === 'true'
                            ) || disableFlg
                          }
                          validationError={Boolean(
                            errors?.ChatSettingAutoResizeWidth
                          )}
                          defaultValue={contentSetting?.size?.width || 400}
                        />
                        &ensp;×&ensp;
                        <FormTextBox
                          name="ChatSettingAutoResizeHeight"
                          width={80}
                          register={register}
                          className={classes.inputText}
                          validation={ChatSettingAutoResizeHeight}
                          numberOnly
                          disabled={
                            Boolean(
                              watch('ChatSettingAutoResize') === 'true'
                            ) || disableFlg
                          }
                          validationError={Boolean(
                            errors?.ChatSettingAutoResizeHeight
                          )}
                          defaultValue={contentSetting?.size?.height || 600}
                        />
                      </Box>
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {errors?.ChatSettingAutoResizeWidth?.message && (
              <p style={{ color: '#C32733' }}>
                表示サイズ 幅：{errors?.ChatSettingAutoResizeWidth.message}
              </p>
            )}
            {errors?.ChatSettingAutoResizeHeight?.message && (
              <p style={{ color: '#C32733' }}>
                表示サイズ 高さ：{errors?.ChatSettingAutoResizeHeight.message}
              </p>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          チャットの開始タイミング
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormRadioButton
              name="ChatSettingStartTiming"
              control={control}
              disabled={disableFlg}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                contentSetting ? contentSetting?.startTiming : 0
              }
              list={[
                {
                  label: 'ライブ開始と同時にチャットも開始',
                  value: CHAT_START_TIMING.LIVE_AUTO,
                },
                {
                  label: (
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      手動で開始
                      <span className={classes.helpContainer}>
                        <Help fontSize="small" className={classes.help} />
                        <Tooltip message="コントロールパネルより手動で操作が可能です。" />
                      </span>
                    </Box>
                  ),
                  value: CHAT_START_TIMING.MANUAL,
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          チャットの終了タイミング
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormRadioButton
              name="ChatSettingEndTiming"
              control={control}
              disabled={disableFlg}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                contentSetting ? contentSetting?.endTiming : 0
              }
              list={[
                {
                  label: 'ライブ終了と同時にチャットも終了',
                  value: CHAT_END_TIMING.LIVE_AUTO,
                },
                {
                  label: (
                    <>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: errors?.ChatSettingEndTimingAutoEndMinutes
                            ?.message
                            ? 12
                            : 0,
                        }}
                      >
                        ライブ配信終了後、
                        <FormTextBox
                          name="ChatSettingEndTimingAutoEndMinutes"
                          width={80}
                          register={register}
                          className={classes.inputText}
                          validation={ChatSettingEndTimingAutoEndMinutes}
                          numberOnly
                          disabled={
                            Boolean(
                              watch('ChatSettingEndTiming') ===
                                String(CHAT_END_TIMING.LIVE_AUTO) ||
                                watch('ChatSettingEndTiming') ===
                                  String(CHAT_END_TIMING.MANUAL)
                            ) || disableFlg
                          }
                          validationError={Boolean(
                            errors?.ChatSettingEndTimingAutoEndMinutes
                          )}
                          defaultValue={contentSetting?.autoEndMinutes}
                        />
                        分後に自動終了
                      </Box>
                      {errors?.ChatSettingEndTimingAutoEndMinutes?.message && (
                        <p
                          style={{
                            color: '#C32733',
                            marginTop: '-4px',
                            marginLeft: 146,
                          }}
                        >
                          {errors?.ChatSettingEndTimingAutoEndMinutes.message}
                        </p>
                      )}
                    </>
                  ),
                  value: CHAT_END_TIMING.MINUTES_AUTO,
                },
                {
                  label: (
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      手動で終了
                      <span className={classes.helpContainer}>
                        <Help fontSize="small" className={classes.help} />
                        <Tooltip message="コントロールパネルより手動で操作が可能です。" />
                      </span>
                    </Box>
                  ),
                  value: CHAT_END_TIMING.MANUAL,
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          投稿されるメッセージ管理
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormRadioButton
              name="ChatSettingBlockType"
              control={control}
              disabled={disableFlg}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                contentSetting ? contentSetting?.blockType : 0
              }
              list={[
                {
                  label: 'メッセージをすべて許可する',
                  value: CHAT_BLOCK_TYPE.ALL,
                },
                {
                  label: '不適切な可能性のあるメッセージを保留して確認する',
                  value: CHAT_BLOCK_TYPE.CHECK,
                },
                {
                  label: 'すべてのメッセージを保留して確認する',
                  value: CHAT_BLOCK_TYPE.ALL_CHECK,
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          入退室メッセージの表示
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormRadioButton
              name="ChatSettingEnterMsg"
              control={control}
              disabled={disableFlg || enterMsgDisableFlg}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                contentSetting
                  ? enterMsgDisableFlg
                    ? CHAT_ENTER_MSG.NONE
                    : contentSetting?.enterMsg
                  : 0
              }
              list={[
                {
                  label: '表示する',
                  value: CHAT_ENTER_MSG.DISPLAY,
                },
                {
                  label: '入室メッセージのみ表示する',
                  value: CHAT_ENTER_MSG.CHECKIN_DISPLAY,
                },
                {
                  label: '表示しない',
                  value: CHAT_ENTER_MSG.NONE,
                },
              ]}
            />
            {edition === EDITIONS.U_LIVE && (
              <p className={classes.discription} style={{ paddingBottom: 20 }}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                Unlimited
                Liveエディションでは、入退室メッセージ設定は使用できません。
              </p>
            )}
            {edition !== EDITIONS.U_LIVE && (
              <p className={classes.discription} style={{ paddingBottom: 20 }}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                入退室メッセージの表示は、同時接続数の設定を1,000人以下にしている場合のみに利用可能となります。
              </p>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          リアクションボタンの表示
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack} style={{ paddingBottom: 20 }}>
            <p
              className={classes.discription}
              style={{
                alignItems: 'flex-start',
              }}
            >
              <InfoOutlined fontSize="small" className={classes.icon} />
              リアクションボタンは2つまで選択可能です。デフォルトリアクションボタンの「いいね！」「分かりません」以外に、自由にテキスト指定（10文字以内）が可能なカスタムリアクションボタン（アイコン無し）の作成も可能です。
            </p>
            <Box className={classes.whiteBox}>
              <FormCheckBox
                name="ChatSettingGoodBtn"
                control={control}
                disabled={
                  disableFlg ||
                  Boolean(
                    !watch('ChatSettingGoodBtn') && isOverReactionSelectLimit
                  )
                }
                label={
                  <Box className={classes.reactionButton}>
                    <ThumbUp />
                    いいね！
                  </Box>
                }
                defaultChecked={contentSetting ? contentSetting?.goodBtn : true}
              />
              <FormCheckBox
                name="ChatSettingTroubleBtn"
                control={control}
                disabled={
                  disableFlg ||
                  Boolean(
                    !watch('ChatSettingTroubleBtn') && isOverReactionSelectLimit
                  )
                }
                label={
                  <Box className={classes.reactionButton}>
                    <Help />
                    分かりません
                  </Box>
                }
                defaultChecked={
                  contentSetting ? contentSetting?.troubleBtn : true
                }
              />
              <FormCheckBox
                name="ChatSettingCustomBtn1Enable"
                control={control}
                disabled={
                  disableFlg ||
                  Boolean(
                    !watch('ChatSettingCustomBtn1Enable') &&
                      isOverReactionSelectLimit
                  )
                }
                label={
                  <FormTextBox
                    name="ChatSettingCustomBtn1Name"
                    width={150}
                    register={register}
                    className={`${classes.inpuReaction} ${
                      watch('ChatSettingCustomBtn1Name') ||
                      contentSetting?.customBtn1?.enable
                        ? 'checked'
                        : ''
                    } ${
                      watch('ChatSettingCustomBtn1Name') ||
                      contentSetting?.customBtn1?.name
                        ? 'unEmpty'
                        : ''
                    }`}
                    placeholder="例）カスタムボタン1"
                    validation={ChatSettingCustomBtn1Name}
                    validationError={Boolean(errors?.ChatSettingCustomBtn1Name)}
                    maxLength={10}
                    defaultValue={contentSetting?.customBtn1.name}
                    disabled={
                      disableFlg ||
                      Boolean(
                        !watch('ChatSettingCustomBtn1Enable') &&
                          isOverReactionSelectLimit
                      )
                    }
                  />
                }
                defaultChecked={
                  contentSetting ? contentSetting?.customBtn1.enable : false
                }
              />
              <FormCheckBox
                name="ChatSettingCustomBtn2Enable"
                control={control}
                disabled={
                  disableFlg ||
                  Boolean(
                    !watch('ChatSettingCustomBtn2Enable') &&
                      isOverReactionSelectLimit
                  )
                }
                label={
                  <FormTextBox
                    name="ChatSettingCustomBtn2Name"
                    width={150}
                    register={register}
                    className={`${classes.inpuReaction} ${
                      watch('ChatSettingCustomBtn2Name') ||
                      contentSetting?.customBtn2?.enable
                        ? 'checked'
                        : ''
                    } ${
                      watch('ChatSettingCustomBtn2Name') ||
                      contentSetting?.customBtn2?.name
                        ? 'unEmpty'
                        : ''
                    }`}
                    placeholder="例）カスタムボタン2"
                    validation={ChatSettingCustomBtn2Name}
                    validationError={Boolean(errors?.ChatSettingCustomBtn2Name)}
                    maxLength={10}
                    defaultValue={contentSetting?.customBtn2.name}
                    disabled={
                      disableFlg ||
                      Boolean(
                        !watch('ChatSettingCustomBtn2Enable') &&
                          isOverReactionSelectLimit
                      )
                    }
                  />
                }
                defaultChecked={
                  contentSetting ? contentSetting?.customBtn2.enable : false
                }
              />
              {Boolean(
                errors?.ChatSettingCustomBtn1Name ||
                  errors?.ChatSettingCustomBtn2Name
              ) && (
                <p
                  style={{
                    color: '#C32733',
                    position: 'relative',
                    height: 10,
                  }}
                >
                  {errors?.ChatSettingCustomBtn1Name?.message && (
                    <span
                      style={{
                        position: 'absolute',
                        left: 340,
                      }}
                    >
                      {errors?.ChatSettingCustomBtn1Name?.message}
                    </span>
                  )}
                  {errors?.ChatSettingCustomBtn2Name?.message && (
                    <span
                      style={{
                        position: 'absolute',
                        left: 538,
                      }}
                    >
                      {errors?.ChatSettingCustomBtn2Name?.message}
                    </span>
                  )}
                </p>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          管理者表示名
        </Grid>
        <Grid item xs={9}>
          <Box className={`${classes.greenBack} ${classes.admin}`}>
            <FormRadioButton
              name="ChatSettingAdminDisplayName"
              control={control}
              disabled={disableFlg}
              defaultValue={
                contentSetting
                  ? getAdminNameFlg(contentSetting?.adminDisplayName)
                  : true
              }
              list={[
                {
                  label: (
                    <FormSelectBox
                      name="ChatSettingAdminDisplayNameSelect"
                      control={control}
                      disabled={
                        watch('ChatSettingAdminDisplayName') === 'false' ||
                        disableFlg
                      }
                      width={150}
                      className={classes.inputOther}
                      defaultValue={
                        contentSetting?.adminDisplayName ||
                        CHAT_MNG_DISPLAY_NAME.MNG
                      }
                      list={[
                        {
                          label: CHAT_MNG_DISPLAY_NAME.MNG,
                          value: CHAT_MNG_DISPLAY_NAME.MNG,
                        },
                        {
                          label: CHAT_MNG_DISPLAY_NAME.OPE,
                          value: CHAT_MNG_DISPLAY_NAME.OPE,
                        },
                        {
                          label: CHAT_MNG_DISPLAY_NAME.SEC,
                          value: CHAT_MNG_DISPLAY_NAME.SEC,
                        },
                      ]}
                    />
                  ),
                  value: true,
                },
                {
                  label: (
                    <FormTextBox
                      name="ChatSettingAdminDisplayNameOther"
                      width={300}
                      register={register}
                      placeholder="例）配信者、その他自由指定頂けます。"
                      validation={ChatSettingAdminDisplayNameOther}
                      disabled={
                        Boolean(
                          watch('ChatSettingAdminDisplayName') === 'true'
                        ) || disableFlg
                      }
                      className={classes.inputOtherName}
                      maxLength={15}
                      validationError={Boolean(
                        errors?.ChatSettingAdminDisplayNameOther
                      )}
                      validationErrorMsg={
                        errors?.ChatSettingAdminDisplayNameOther?.message
                          ? String(
                              errors?.ChatSettingAdminDisplayNameOther?.message
                            )
                          : ''
                      }
                      defaultValue={
                        getAdminNameFlg(contentSetting?.adminDisplayName)
                          ? ''
                          : contentSetting?.adminDisplayName
                      }
                    />
                  ),
                  value: false,
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          ブロックする文字列
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="ChatSettingBlockWords"
            width="fullWidth"
            register={register}
            placeholder="例）Orange(オレンジ)，Apple(りんご)"
            rows={2}
            maxLength={1000}
            disabled={disableFlg}
            defaultValue={contentSetting?.blockWords}
          />
          {
            // TODO 履歴以外表示
            !disableFlg && (
              <p
                className={classes.discription}
                style={{ alignItems: 'flex-start', marginTop: 10 }}
              >
                <InfoOutlined fontSize="small" className={classes.icon} />
                ブロックする文字列をカンマ区切りで入力してください。
                <br />
                投稿されたメッセージに対して登録した文字列を部分一致で検索します。
                <br />
                登録された文字列が含まれるメッセージは確認のため保留されます。
              </p>
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatSetting;
