// 視聴URL → QRコードURL
const makeQRUrl = (chatenable: boolean, url: string): string => {
  if (chatenable) {
    const splitWords = url.split('/');
    splitWords.splice(-2, 0, '1');
    return splitWords.join('/');
  }
  return `${url}/1`;
};

export default makeQRUrl;
