import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CommonBox from '../parts/CommonBox/CommonBox';
import ControllerLeft from './ControllerLeft';
import ControllerRight from './ControllerRight';
import WarningDialog from '../parts/WarningDialog/WarningDialog';
import ShackBar from '../parts/SnackBar/SnackBar';
import {
  MENUBAR,
  ROUTE,
  getPath,
  UI_STATUS,
  FILE_TO_LIVE_CHAT_STATUS,
  CONTROLLER_MIN_WIDTH,
} from '../../utils/Const';
import { FileToLiveControllerProps } from '../../props/FileToLiveController';

/** スタイル */
const useStyle = makeStyles({
  root: {},
  header: {
    margin: '20px 0',
    color: '#444444',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    overflow: 'auto',
  },
  footer: {
    marginTop: 30,
    '& a': {
      color: '#333333',
    },
    '& li, .MuiTypography-root': {
      fontSize: 14,
    },
  },
});

/** コントロールパネル(UI) */
const FileToLiveControllerComponent = (
  props: FileToLiveControllerProps
): JSX.Element => {
  const classes = useStyle();
  const {
    uiStatus,
    cid,
    readAPIToken,
    userApiParams,
    chatStatus,
    aleartLiveFinishDialog,
    ChatDialog,
    snackbarInfo,
    openPreviewDialog,
    handleSnackbarClose,
  } = props;

  // 処理中
  if (uiStatus === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox
      width={CONTROLLER_MIN_WIDTH}
      className={classes.root}
      navibar={MENUBAR.FILETOLIVE}
    >
      <Box className={classes.header}>
        <h2 style={{ fontSize: 24, marginRight: 15, margin: 0 }}>
          疑似ライブコントロールパネル
        </h2>
      </Box>
      <Box className={classes.container}>
        <ControllerLeft props={props} />
        <ControllerRight props={props} />
      </Box>
      <Box className={classes.footer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {[
            <Link
              to={`${getPath(
                ROUTE.FILE_TO_LIVE_LIST
              )}?c=${cid}&t=${readAPIToken}&u=${userApiParams}`}
              key="flive-list"
            >
              疑似ライブの配信設定と開催
            </Link>,
            <Typography key="3">コントロールパネル</Typography>,
          ]}
        </Breadcrumbs>
      </Box>
      <WarningDialog
        open={openPreviewDialog.open}
        handleClose={openPreviewDialog.handleClose}
        handleOK={openPreviewDialog.handleOK}
        header="プレビュー時の注意"
        body={
          <>
            配信プレビューを行います。
            <br />
            （流量・ログはプレビューでもカウントされます）
            <br />
            よろしいですか？
          </>
        }
        cancel="キャンセル"
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={ChatDialog.open}
        handleClose={ChatDialog.handleClose}
        handleOK={ChatDialog.handleOK}
        header={`チャット${
          chatStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING ? '停止' : '開始'
        }前確認`}
        body={
          <>
            <div>{`チャットを${
              chatStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING
                ? '停止'
                : '開始'
            }しますが、よろしいですか？`}</div>
            {chatStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING && (
              <div>
                ライブ配信中にチャットの再開はできませんので、ご注意ください。
              </div>
            )}
          </>
        }
        cancel="キャンセル"
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={aleartLiveFinishDialog.open}
        handleClose={aleartLiveFinishDialog.handleClose}
        handleOK={aleartLiveFinishDialog.handleOK}
        header="ライブ配信停止前確認"
        body={
          <>
            ライブ配信{aleartLiveFinishDialog.withChat && 'とチャット'}
            を停止します。
            <br />
            よろしいですか？
          </>
        }
        cancel="キャンセル"
        ok="OK"
        buttonLeft="ok"
      />
      <ShackBar infomations={snackbarInfo} handleClose={handleSnackbarClose} />
    </CommonBox>
  );
};

export default FileToLiveControllerComponent;
