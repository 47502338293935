import React from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormRadioButtonProps } from '../../../props/Parts';

/** ReactHookForm用ラジオボタン */
const FormRadioButton = ({
  name,
  list,
  control,
  row = false,
  defaultValue = '',
  validationError = false,
  validationErrorMsg = '',
  validation,
  className = '',
  disabled = false,
}: FormRadioButtonProps): JSX.Element => {
  return (
    <FormControl error={validationError} disabled={disabled}>
      <Controller
        name={`${name}` as const}
        control={control}
        defaultValue={String(defaultValue)}
        rules={validation}
        render={({ field }) => (
          <RadioGroup {...field} row={row}>
            {list.map((item) => (
              <FormControlLabel
                value={String(item.value)}
                control={<Radio color="primary" style={{ padding: '0 9px' }} />}
                label={item.label}
                disabled={item.disabled}
                key={String(item.value)}
                className={className}
                style={{ marginBottom: 16 }}
              />
            ))}
          </RadioGroup>
        )}
      />
      {validationErrorMsg && (
        <FormHelperText>{validationErrorMsg}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormRadioButton;
