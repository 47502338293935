import React from 'react';
import { Box } from '@material-ui/core';
import {
  CHAT_STATUS_JAP,
  FILE_TO_LIVE_STATUS,
  LIVE_LOG_STATUS,
  LIVE_STATUS,
  PORTAL_STATUS_STR,
} from '../../../utils/Const';

type propType = {
  title: string;
  className?: string;
};

/** バッチ（ステータス表示など） */
const Badge = ({ title, className }: propType): JSX.Element => {
  const color = () => {
    switch (title) {
      case LIVE_STATUS.STREAMING:
      case LIVE_STATUS.ERROR:
      case CHAT_STATUS_JAP.STREAMING:
      case PORTAL_STATUS_STR.PUBLIC:
      case LIVE_LOG_STATUS.RECORDING_FINISH:
      case LIVE_LOG_STATUS.MASTER_CONVERT:
      case LIVE_LOG_STATUS.MASTER_CONVERT_FINISH:
      case LIVE_LOG_STATUS.TRANSCODE:
      case LIVE_LOG_STATUS.MASTER_CONVERT_ERROR:
      case LIVE_LOG_STATUS.VOD_UPLOAD_ERROR:
      case LIVE_LOG_STATUS.VOD_TRANSCODE_ERROR:
      case LIVE_LOG_STATUS.UNKNOWN_ERROR:
      case FILE_TO_LIVE_STATUS.ERROR:
      case FILE_TO_LIVE_STATUS.STREAMING:
        return '#CB0E04';
      case LIVE_LOG_STATUS.TRANSCODE_FINISH:
        return '#006611';
      case LIVE_STATUS.PRE:
      case FILE_TO_LIVE_STATUS.READY:
        return '#F9FB00';
      case LIVE_STATUS.STOP:
      case CHAT_STATUS_JAP.STOP:
      case PORTAL_STATUS_STR.PRIVATE:
      case FILE_TO_LIVE_STATUS.FINISHED:
        return '#4A4A4A';
      case LIVE_LOG_STATUS.NONE:
        return '#4A4A4A';
      case LIVE_STATUS.NONE:
        return '#D8D8D8';
      case LIVE_STATUS.ACTIVATING:
      case LIVE_STATUS.CREATING:
        return '#33333317';
      case FILE_TO_LIVE_STATUS.WAITING:
        return '#ffb300';
      default:
        return '#4A4A4A';
    }
  };
  if (title.length <= 0) return <></>;
  return (
    <Box
      style={{
        background: color(),
        fontWeight: 500,
        textAlign: 'center',
        minWidth: 90,
        margin: 'auto',
      }}
      color={
        color() === '#F9FB00' ||
        color() === '#D8D8D8' ||
        color() === '#33333317'
          ? '#000000DE'
          : '#FFF'
      }
      borderRadius={100}
      className={className}
      padding="0"
      height="30"
      lineHeight="30px"
      fontSize="13"
    >
      {title}
    </Box>
  );
};

export default Badge;
