import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Breadcrumbs,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { InfoOutlined, NavigateNext } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import CommonBox from '../parts/CommonBox/CommonBox';
import WarningDialog from '../parts/WarningDialog/WarningDialog';
import DefaultInfo from '../LiveRegister/DefaultInfo';
import LiveLink from '../LiveRegister/LiveLink';
import SecuritySetting from '../LiveRegister/SecuritySetting';
import ChatSetting from '../LiveRegister/ChatSetting';
import LiveEvent from '../LiveRegister/LiveEvent';
import ThumbnailSetting from './ThumbnailSetting';
import { LiveContentProps } from '../../props/LiveContent';
import { RootState } from '../../app/store';
import ProgressModal from '../parts/ProgressModal/ProgressModal';
import {
  EDITIONS,
  getPath,
  MENUBAR,
  ROUTE,
  UI_STATUS,
  LIVE_TAB_NAME,
} from '../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {},
  header: {
    fontSize: 24,
    margin: '20px 0',
    color: '#444444',
  },
  container: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 12px #1C385E0C',
    borderRadius: 8,
    padding: '40px 70px 40px',
    '& .MuiGrid-grid-xs-3': {
      maxWidth: 350,
      flexBasis: 350,
      fontWeight: 500,
      paddingRight: 48,
    },
    '& .MuiGrid-grid-xs-9': {
      maxWidth: 'calc(100% - 380px)',
      flexBasis: 'calc(100% - 380px)',
      paddingLeft: 30,
      paddingRight: 2,
    },
    '& .MuiFormControlLabel-root .MuiTypography-root': {
      fontSize: 15,
      fontWeight: 500,
    },
  },
  navigation: {
    marginBottom: 30,
    '& .MuiBottomNavigationAction-root': {
      maxWidth: 'calc(100% / 6)',
      borderBottom: '2px solid #F0F0F0',
      '&.Mui-selected': {
        borderColor: '#61B227',
        opacity: 1,
      },
    },
    '& .MuiBottomNavigationAction-label': {
      color: '#333333',
      fontSize: 16,
      fontWeight: 700,
      opacity: 0.4,
      '&.Mui-selected': {
        opacity: 1,
      },
    },
  },
  error: {
    display: 'flex',
    color: '#f44336',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    '& a, button': {
      fontSize: 17,
      borderRadius: 4,
      fontWeight: 500,
    },
    '& a': {
      background: '#D8D8D8',
      borderBottom: '1px solid #AAAAAA',
      color: '#333333',
      width: 140,
      marginRight: 15,
      textDecoration: 'none',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        background: '#F4F4F4',
      },
    },
    '& button': {
      background: '#4A8BFB',
      color: '#FFFFFF',
      width: 170,
      borderBottom: '1px solid #3C72D0',
      '&:hover': {
        backgroundColor: '#015CFA',
      },
    },
  },
  hidden: {
    display: 'none',
  },
  footer: {
    borderTop: '1px dashed #B9B9B9',
    paddingTop: 30,
    marginTop: 30,
  },
  securityAttention: {
    display: 'flex',
    background: '#C3273305',
    border: '1px solid #C32733',
    borderRadius: 4,
    color: '#C32733',
    padding: 10,
    marginBottom: 15,
  },
  icon: {
    color: '#C32733',
    marginRight: 4,
    paddingTop: 3,
  },
  breadcrumbs: {
    marginTop: 30,
    fontSize: 14,
    '& a': {
      color: '#333333',
    },
  },
});

/** ライブ詳細(UI) */
const LiveContentComponent = (props: LiveContentProps): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const user = useSelector((state: RootState) => state.user);
  const {
    edition,
    maxClientLimitDisableFlg,
    saveLiveProfile,
    dialog,
    setDialog,
  } = props;
  const [selectTab, setSelectTab] = useState<LIVE_TAB_NAME>(
    LIVE_TAB_NAME.DEFAULT_INFO
  );

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
    setError,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
  });

  const onError = (errors: FieldValues) => {
    if (Object.keys(errors).length <= 0) return;
    const errorsKey = Object.keys(errors);
    // 一致するものがあればそのタブに移動
    const base = errorsKey.find((x) => x.indexOf('BaseSetting') >= 0);
    if (base) {
      setSelectTab(LIVE_TAB_NAME.DEFAULT_INFO);
      return;
    }
    const liveLink = errorsKey.find((x) => x.indexOf('ExLinkSetting') >= 0);
    if (liveLink) {
      setSelectTab(LIVE_TAB_NAME.LIVE_LINK);
      return;
    }
    const thumbnail = errorsKey.find((x) => x.indexOf('UpdateThumbnai') >= 0);
    if (thumbnail) {
      setSelectTab(LIVE_TAB_NAME.THUMBNAIL_SETTING);
      return;
    }
    const chat = errorsKey.find((x) => x.indexOf('ChatSetting') >= 0);
    if (chat) {
      setSelectTab(LIVE_TAB_NAME.CHAT_SETTING);
    }
  };

  // 処理中
  if (status.liveContent === UI_STATUS.PROCESSING) return <LinearProgress />;

  // 保存中
  if (status.liveContent === UI_STATUS.SAVING)
    return (
      <>
        <LinearProgress />
        <ProgressModal
          open
          title="ライブ設定を保存中です。"
          info="保存には数秒かかる場合がございます。"
        />
      </>
    );

  // 更新完了(ライブの設定設定と開催画面へリダイレクト)
  if (status.liveContent === UI_STATUS.REDIRECT)
    return (
      <Redirect
        to={{
          pathname: getPath(ROUTE.LIVE_LIST),
          search: `?c=${user.contract.cid}&t=${user.contract.readAPIToken}&u=${user.user}`,
        }}
      />
    );

  return (
    <CommonBox navibar={MENUBAR.LIVELIST}>
      <h2 className={classes.header}>ライブ配信設定 詳細</h2>
      <Box className={classes.container}>
        <BottomNavigation
          showLabels
          value={selectTab}
          onChange={(event, newValue) => {
            setSelectTab(newValue);
          }}
          className={classes.navigation}
        >
          <BottomNavigationAction
            label="基本設定"
            value={LIVE_TAB_NAME.DEFAULT_INFO}
          />
          <BottomNavigationAction
            label="動画からのリンク"
            value={LIVE_TAB_NAME.LIVE_LINK}
          />
          <BottomNavigationAction
            label="セキュリティ"
            value={LIVE_TAB_NAME.SECURITY_SETTING}
          />
          <BottomNavigationAction
            label="サムネイル"
            value={LIVE_TAB_NAME.THUMBNAIL_SETTING}
          />
          <BottomNavigationAction
            label="チャット設定"
            value={LIVE_TAB_NAME.CHAT_SETTING}
          />
          {(edition === EDITIONS.BUSINESS ||
            edition === EDITIONS.EXPERT ||
            edition === EDITIONS.EXPERT_2 ||
            edition === EDITIONS.ENTERPRISE) && (
            <BottomNavigationAction
              label="ライブイベント情報"
              value={LIVE_TAB_NAME.LIVE_EVENT}
            />
          )}
        </BottomNavigation>
        <form onSubmit={handleSubmit(saveLiveProfile, onError)}>
          <DefaultInfo
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            ContentProps={props}
            open={selectTab === LIVE_TAB_NAME.DEFAULT_INFO}
          />
          <LiveLink
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            ContentProps={props}
            open={selectTab === LIVE_TAB_NAME.LIVE_LINK}
          />
          <SecuritySetting
            control={control}
            watch={watch}
            ContentProps={props}
            open={selectTab === LIVE_TAB_NAME.SECURITY_SETTING}
          />
          <ThumbnailSetting
            register={register}
            setValue={setValue}
            watch={watch}
            ContentProps={props}
            open={selectTab === LIVE_TAB_NAME.THUMBNAIL_SETTING}
            cid={user.contract.cid}
          />
          <ChatSetting
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            trigger={trigger}
            ContentProps={props}
            open={selectTab === LIVE_TAB_NAME.CHAT_SETTING}
          />
          <LiveEvent
            register={register}
            ContentProps={props}
            open={selectTab === LIVE_TAB_NAME.LIVE_EVENT}
          />
          <Box className={classes.footer}>
            {selectTab === LIVE_TAB_NAME.SECURITY_SETTING && (
              <Box className={classes.securityAttention}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                <Box>
                  当セキュリティ機能は、設定後10～30分程度で有効となります。設定直後は制限がかかりません。また、ライブ配信設定の登録直後に当設定を行われた場合、設定が有効になるまでの間は当該ライブが視聴できない状態となります。
                </Box>
              </Box>
            )}
            <Box className={classes.buttons}>
              <Link
                to={`${getPath(ROUTE.LIVE_LIST)}?c=${user.contract.cid}&t=${
                  user.contract.readAPIToken
                }&u=${user.user}`}
              >
                一覧へ戻る
              </Link>
              <Button type="submit" disabled={maxClientLimitDisableFlg}>
                保存
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
      >
        {[
          <Link
            to={`${getPath(ROUTE.LIVE_LIST)}?c=${user.contract.cid}&t=${
              user.contract.readAPIToken
            }&u=${user.user}`}
          >
            ライブの配信設定と開催
          </Link>,
          <Typography style={{ fontSize: 14 }}>詳細</Typography>,
        ]}
      </Breadcrumbs>
      <WarningDialog
        open={dialog}
        handleClose={() => setDialog(false)}
        handleOK={() => setDialog(false)}
        header="ライブ自動画質設定の内容が更新されています"
        body={
          <>
            使用しているライブ自動画質設定の修正内容を反映させる場合、再度保存を行ってください。
          </>
        }
        ok="OK"
      />
    </CommonBox>
  );
};

export default LiveContentComponent;
