import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ArrowDropUp,
  ArrowDropDown,
  OpenInNew,
  KeyboardArrowRight,
} from '@material-ui/icons';
import moment from 'moment';
import {
  FileToLiveListProps,
  TableFileToLiveProfile,
} from '../../props/FileToLiveList';
import {
  getPath,
  LIVELIST_SORT_LIVEID,
  ROUTE,
  SORT_STATUS,
  FILE_TO_LIVE_LIST_SORT_PROPERTY,
  EDITIONS,
  SORTLIST_FILE_TO_LIVESTATUS_ASCE,
  SORTLIST_FILE_TO_LIVESTATUS_DESCE,
  FILE_TO_LIVE_STATUS,
  MIN_WIDTH,
  FILE_TO_LIVE_CHAT_STATUS,
} from '../../utils/Const';
import Badge from '../parts/Badge/Badge';

const SortArrow = ({
  sort,
  active = false,
}: {
  sort?: SORT_STATUS | LIVELIST_SORT_LIVEID;
  active?: boolean;
}): JSX.Element => {
  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      style={{ cursor: 'pointer' }}
    >
      <ArrowDropUp
        fontSize="small"
        style={{
          marginBottom: '-7px',
          opacity:
            (sort === SORT_STATUS.DESCE ||
              sort === LIVELIST_SORT_LIVEID.DESCE) &&
            active
              ? '1'
              : '0.3',
        }}
      />
      <ArrowDropDown
        fontSize="small"
        style={{
          marginTop: '-7px',
          opacity:
            (sort === SORT_STATUS.ASCE || sort === LIVELIST_SORT_LIVEID.ASCE) &&
            active
              ? '1'
              : '0.3',
        }}
      />
    </Box>
  );
};

const CustomButtomTarget = ({
  title,
  href,
  disabled,
  className,
}: {
  title: string;
  href: string;
  disabled?: boolean;
  className: string;
}): JSX.Element => {
  return (
    <Button
      className={className}
      href={href}
      target="_blank"
      disabled={disabled}
    >
      {title}
      <OpenInNew
        fontSize="small"
        style={{ marginLeft: 4, width: 16, height: 16 }}
      />
    </Button>
  );
};
const CustomButtomView = ({
  title,
  className,
  onClick,
}: {
  title: string;
  className: string;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <Button className={className} onClick={onClick}>
      {title}
      <KeyboardArrowRight fontSize="small" style={{ width: 16, height: 16 }} />
    </Button>
  );
};

/** スタイル */
const useStyle = makeStyles({
  root: {
    marginTop: 20,
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '&.windows': {
      '@media (min-width: 1501px)': {
        width: `calc(100vw - (1500px - ${MIN_WIDTH}px + 20px))`,
      },
    },
  },
  header: {
    background: '#333333',
    color: '#FFFFFF',
  },
  headerCell: {
    color: '#FFFFFF',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 700,
    borderRight: '1px solid #E0E0E0',
    padding: '16px 0',
    '&:last-child': {
      borderRight: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      marginRight: 5,
    },
  },
  bodyCell: {
    fontSize: 14,
    borderRight: '1px solid #E0E0E0',
    padding: '4px 8px',
    '&:last-child': {
      borderRight: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      marginRight: 8,
    },
    '& a': {
      maxWidth: 193,
      display: 'inline-block',
      overflowWrap: 'break-word',
      color: '#4472C4',
    },
  },
  bodyRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  selectedRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  checkbox: {
    color: '#999999',
    '&.Mui-checked': {
      color: '#4A8BFB',
    },
    '& .MuiSvgIcon-root': {
      zIndex: 2,
    },
    '& .MuiIconButton-label': {
      position: 'relative',
      '&::before': {
        position: 'absolute',
        content: '""',
        background: '#FFFFFF',
        width: 16,
        height: 16,
        borderRadius: 2,
      },
    },
  },
  button: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 14,
    width: '100%',
    '& .MuiButton-label': {
      color: '#333333',
    },
    '&.Mui-disabled .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    '&:hover': {
      background: '#333333',
      '& .MuiButton-label': {
        color: '#FFFFFF',
      },
    },
  },
});

type propsType = {
  props: FileToLiveListProps;
  checkedFlpids: number[];
  flpids: number[];
  checkId: (flpid: number) => void;
  checkAllId: (flpids: number[]) => void;
  handleOpenShareDialog: (flpid: number) => void;
  handleGraph: (flpid: number) => void;
  limit: number;
  page: number;
  handleSort: () => void;
};

/** ライブの配信リスト */
const FileToLiveListTable = ({
  props,
  checkedFlpids,
  flpids,
  checkId,
  checkAllId,
  handleOpenShareDialog,
  handleGraph,
  limit,
  page,
  handleSort,
}: propsType): JSX.Element => {
  const classes = useStyle();
  const os = window.navigator.userAgent.toLowerCase();
  const { downloadClientsLogCSV, downloadChatLogCSV } = props;

  // NOTE: 明示化するために名前変更
  const sortedList = props.fileToLiveProfiles;

  // State
  /** 適用中のソートを制御 */
  const [sortProperty, setSortProperty] = useState<string>(
    FILE_TO_LIVE_LIST_SORT_PROPERTY.FLPID
  );
  /** ライブIDのソートを制御 */
  const [sortPropertyLpid, setSortPropertyLpid] =
    useState<LIVELIST_SORT_LIVEID>(LIVELIST_SORT_LIVEID.ASCE);
  /** ライブステータスのソートを制御 */
  const [sortPropertyLiveStatus, setSortPropertyLiveStatus] =
    useState<SORT_STATUS>(SORT_STATUS.NONE);
  /** 開催日時のソートを制御 */
  const [sortPropertyStartDate, setSortPropertyStartDate] =
    useState<SORT_STATUS>(SORT_STATUS.NONE);
  /** ページネーション対応リスト */
  const [pagedList, setPagedList] = useState<TableFileToLiveProfile[]>(
    sortedList.slice(0, limit)
  );

  const isChecked = (lpid: number) => {
    return Boolean(
      typeof checkedFlpids.find((x) => x === lpid) !== 'undefined'
    );
  };
  const isAllChecked = () => {
    checkedFlpids.sort((a: number, b: number) => a - b);
    // 表示範囲内のチェック可能lpidのリスト
    const pagedIds: number[] = pagedList
      .map((x) => {
        if (flpids.find((y) => y === x.flpid)) return x.flpid;
        return null;
      })
      .filter((x): x is NonNullable<typeof x> => x !== null);
    pagedIds.sort((a: number, b: number) => a - b);
    const checklpidStr = JSON.stringify(checkedFlpids);
    const lpidStr = JSON.stringify(pagedIds);
    if (pagedIds.length === 0) {
      return false;
    }
    return checklpidStr === lpidStr;
  };
  const checkAll = () => {
    // 表示範囲内のチェック可能lpidのリストを渡す
    checkAllId(
      pagedList
        .map((x) => {
          if (flpids.find((y) => y === x.flpid)) return x.flpid;
          return null;
        })
        .filter((x): x is NonNullable<typeof x> => x !== null)
    );
  };

  // ソート機能・イベント
  /** ライブIDのソート：降順、昇順 */
  const sortFlpid = (change = true) => {
    if (change) setSortProperty(FILE_TO_LIVE_LIST_SORT_PROPERTY.FLPID);
    if (
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.DESCE && change) ||
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.ASCE && !change)
    ) {
      if (change) setSortPropertyLpid(LIVELIST_SORT_LIVEID.ASCE);
      sortedList.sort((a, b) => (a.flpid < b.flpid ? 1 : -1));
    } else if (
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.ASCE && change) ||
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.DESCE && !change)
    ) {
      if (change) setSortPropertyLpid(LIVELIST_SORT_LIVEID.DESCE);
      sortedList.sort((a, b) => (a.flpid > b.flpid ? 1 : -1));
    }
    if (change) {
      handleSort();
      setPagedList(sortedList.slice(0, limit));
    }
  };

  /** ライブステータスのソート
   * 昇順：配信予約中、配信準備中、配信中、配信終了、エラー
   * 降順：エラー、配信終了、配信中、配信準備中、配信予約中
   */
  const sortLiveStatus = (change = true) => {
    if (change) setSortProperty(FILE_TO_LIVE_LIST_SORT_PROPERTY.STATUS);
    if (
      ((sortPropertyLiveStatus === SORT_STATUS.ASCE ||
        sortPropertyLiveStatus === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyLiveStatus === SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyLiveStatus(SORT_STATUS.DESCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_FILE_TO_LIVESTATUS_ASCE.indexOf(a.liveStatus) -
          SORTLIST_FILE_TO_LIVESTATUS_ASCE.indexOf(b.liveStatus)
      );
    } else if (
      (sortPropertyLiveStatus === SORT_STATUS.DESCE && change) ||
      (sortPropertyLiveStatus !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyLiveStatus(SORT_STATUS.ASCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_FILE_TO_LIVESTATUS_DESCE.indexOf(a.liveStatus) -
          SORTLIST_FILE_TO_LIVESTATUS_DESCE.indexOf(b.liveStatus)
      );
    }
    if (change) {
      handleSort();
      setPagedList(sortedList.slice(0, limit));
    }
  };

  /** 開催日時のソート：降順、昇順 */
  const sortStartDate = (change = true) => {
    if (change) setSortProperty(FILE_TO_LIVE_LIST_SORT_PROPERTY.START_DATE);
    if (
      ((sortPropertyStartDate === SORT_STATUS.ASCE ||
        sortPropertyStartDate === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyStartDate === SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyStartDate(SORT_STATUS.DESCE);
      sortedList.sort((a, b) =>
        moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1
      );
    } else if (
      (sortPropertyStartDate === SORT_STATUS.DESCE && change) ||
      (sortPropertyStartDate !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyStartDate(SORT_STATUS.ASCE);
      sortedList.sort((a, b) =>
        moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1
      );
    }
    if (change) {
      handleSort();
      setPagedList(sortedList.slice(0, limit));
    }
  };

  /** 表示範囲変更の対応 */
  useEffect(() => {
    // 一覧内容が更新された場合にもソートを適用させる
    if (sortProperty === FILE_TO_LIVE_LIST_SORT_PROPERTY.FLPID) {
      sortFlpid(false);
    }
    if (sortProperty === FILE_TO_LIVE_LIST_SORT_PROPERTY.STATUS) {
      sortLiveStatus(false);
    }
    if (sortProperty === FILE_TO_LIVE_LIST_SORT_PROPERTY.START_DATE) {
      sortStartDate(false);
    }
    // 表示範囲の適用
    setPagedList(sortedList.slice((page - 1) * limit, page * limit));
  }, [page, limit, sortedList]);

  return (
    <TableContainer
      component={Paper}
      className={`${classes.root} ${
        os.indexOf('windows nt') !== -1 ? 'windows' : ''
      }`}
    >
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell className={classes.headerCell} width="8%">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Checkbox
                  className={classes.checkbox}
                  color="default"
                  checked={isAllChecked()}
                  onClick={checkAll}
                />
                <Box display="inline-block" textAlign="center">
                  ライブ
                  <br />
                  ID
                </Box>
                <Box onClick={() => sortFlpid()}>
                  <SortArrow
                    sort={sortPropertyLpid}
                    active={
                      sortProperty === FILE_TO_LIVE_LIST_SORT_PROPERTY.FLPID
                    }
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="6.7%"
            >
              動画ID
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="16.3%"
            >
              ライブ設定名
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="5.7%"
            >
              同時接続数
              <br />
              制限
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="12.7%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">開催日時</Box>
                <Box onClick={() => sortStartDate()}>
                  <SortArrow
                    sort={sortPropertyStartDate}
                    active={
                      sortProperty ===
                      FILE_TO_LIVE_LIST_SORT_PROPERTY.START_DATE
                    }
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="8.1%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">ステータス</Box>
                <Box onClick={() => sortLiveStatus()}>
                  <SortArrow
                    sort={sortPropertyLiveStatus}
                    active={
                      sortProperty === FILE_TO_LIVE_LIST_SORT_PROPERTY.STATUS
                    }
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="7.7%"
            >
              共通タグ
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="7.7%"
            >
              コントロール
              <br />
              パネル
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="9.7%"
            >
              グラフ表示
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="8.7%"
            >
              同時接続数
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="8.7%"
            >
              チャットCSV
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...pagedList].map((flive) => (
            <TableRow
              key={flive.flpid}
              className={`${classes.bodyRow} ${
                isChecked(flive.flpid) && classes.selectedRow
              }`}
            >
              <TableCell className={classes.bodyCell}>
                <Checkbox
                  className={classes.checkbox}
                  color="default"
                  checked={isChecked(flive.flpid)}
                  onClick={() => checkId(flive.flpid)}
                  disabled={
                    flive.liveStatus !== FILE_TO_LIVE_STATUS.WAITING ||
                    flive.chatStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING
                  }
                />
                {flive.flpid}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {flive.mid}
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Link
                  to={`${getPath(ROUTE.FILE_TO_LIVE_CONTENT)}?flpid=${
                    flive.flpid
                  }&c=${props.cid}&t=${props.readAPIToken}&u=${
                    props.userApiParams
                  }`}
                >
                  {flive.liveName}
                </Link>
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {props.edition === EDITIONS.U_LIVE
                  ? '制限なし'
                  : `${flive.maxClient}人`}
              </TableCell>
              <TableCell className={classes.bodyCell}>
                {moment(flive.startDate).format('YYYY/MM/DD HH:mm')} ~ <br />
                {moment(flive.closeDate).format('YYYY/MM/DD HH:mm')}
              </TableCell>

              <TableCell align="center" className={classes.bodyCell}>
                <Badge title={flive.liveStatus} />
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {flive.liveStatus !== FILE_TO_LIVE_STATUS.FINISHED &&
                  flive.liveStatus !== FILE_TO_LIVE_STATUS.ERROR && (
                    <Box onClick={() => handleOpenShareDialog(flive.flpid)}>
                      <CustomButtomView
                        title="表示"
                        className={classes.button}
                      />
                    </Box>
                  )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {((flive.liveStatus !== FILE_TO_LIVE_STATUS.FINISHED &&
                  flive.liveStatus !== FILE_TO_LIVE_STATUS.ERROR) ||
                  (flive.chat &&
                    flive.chatStatus ===
                      FILE_TO_LIVE_CHAT_STATUS.STREAMING)) && (
                  <CustomButtomTarget
                    title="開く"
                    href={`${getPath(ROUTE.FILE_TO_LIVE_CONTROLLER)}?flpid=${
                      flive.flpid
                    }&c=${props.cid}&t=${props.readAPIToken}&u=${
                      props.userApiParams
                    }`}
                    className={classes.button}
                  />
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {flive.liveStatus === FILE_TO_LIVE_STATUS.FINISHED && (
                  <CustomButtomView
                    title="グラフ表示"
                    onClick={() => handleGraph(flive.flpid)}
                    className={classes.button}
                  />
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {flive.liveStatus === FILE_TO_LIVE_STATUS.FINISHED && (
                  <CustomButtomView
                    title="CSV出力"
                    onClick={() =>
                      downloadClientsLogCSV(
                        flive.flpid,
                        flive.startDate,
                        flive.closeDate
                      )
                    }
                    className={classes.button}
                  />
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {flive.chat &&
                  (flive.chatStatus === FILE_TO_LIVE_CHAT_STATUS.STOP ||
                    flive.chatStatus === FILE_TO_LIVE_CHAT_STATUS.DELETED) && (
                    <CustomButtomView
                      title="CSV出力"
                      onClick={() => downloadChatLogCSV(flive.flpid)}
                      className={classes.button}
                    />
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileToLiveListTable;
