import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import FileToLiveRegisterComponent from '../../components/FileToLiveRegister/FileToLiveRegisterComponent';
import { BaseSetting, InputData } from '../../props/FileToLiveRegister';
import { LIVE_SAVE } from '../../utils/Const';
import FileToLiveProfileInit from './FileToLiveProfileInit';
import FileToLiveSave from './FileToLiveSave';
import GetMediaList from './GetMediaList';

/** ライブの新規登録 */
const FileToLiveRegisterContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  // Selector
  const user = useSelector((state: RootState) => state.user);

  // 変数抽出
  const { edition, liveInfo } = user.contract;
  const { maxClient } = liveInfo;

  // エラーメッセージ
  const [message, setMessage] = useState<string>('');

  // 初期処理
  useEffect(() => {
    dispatch(FileToLiveProfileInit(LIVE_SAVE.ADD));
  }, []);

  // 保存処理
  const saveFileToLiveProfile = (data: InputData) => {
    dispatch(FileToLiveSave(data, edition, setMessage));
  };

  // 利用動画一覧取得
  const getMediaList = (mid: string) => {
    dispatch(GetMediaList(mid));
  };

  // 基本設定
  const baseSetting: BaseSetting = { maxClient };

  return (
    <FileToLiveRegisterComponent
      edition={edition}
      baseSetting={baseSetting}
      message={message}
      saveFileToLiveProfile={saveFileToLiveProfile}
      getMediaList={getMediaList}
    />
  );
};

export default FileToLiveRegisterContainer;
