import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { FileToLiveControllerProps } from '../../props/FileToLiveController';

const FORMAT_TIME = 'HH:mm';
const FORMAT_TIME_SECONDS = 'HH:mm:ss';

/** スタイル */
const useStyle = makeStyles({
  root: {
    height: 400,
    overflowX: 'auto',
    position: 'relative',
  },
  chart: {
    marginLeft: 36,
    marginBottom: 36,
  },
  viewer: {
    position: 'absolute',
    left: '-36px',
    top: 'calc(50% - 30px)',
    height: 'fit-content',
    margin: '0 auto',
    transform: 'rotate( -90deg )',
    background: '#F0F0F0',
    borderRadius: 100,
    padding: '3px 20px',
  },
  time: {
    position: 'absolute',
    left: 30,
    right: 0,
    bottom: 0,
    width: 'fit-content',
    margin: '0 auto',
    background: '#F0F0F0',
    borderRadius: 100,
    padding: '3px 20px',
  },
});

/** コントロールパネル 右(UI) */
const ChartView = ({
  controllerProps,
  className,
  width = 870,
}: {
  controllerProps?: FileToLiveControllerProps;
  className?: string;
  width?: number;
}): JSX.Element => {
  const classes = useStyle();
  const viewers = controllerProps?.liveClientsInfo.data || [];
  const [data, setData] = useState<ChartData>({} as ChartData);

  useEffect(() => {
    if (!document.getElementById('viewer-chart') || viewers.length <= 0) return;
    const canvas = document.getElementById('viewer-chart') as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d');
    const chartColor = ctx?.createLinearGradient(0, 0, 0, 300);
    chartColor?.addColorStop(0, 'rgba(97, 178, 39, 0.4)');
    chartColor?.addColorStop(1, 'rgba(97, 178, 39, 0)');
    setData({
      labels: viewers.map((x) =>
        moment(x.datetime).format(FORMAT_TIME_SECONDS)
      ),
      datasets: [
        {
          label: '時刻',
          data: viewers.map((x) => x.count),
          backgroundColor: chartColor,
          borderColor: '#61B227',
          fill: true,
          pointRadius: 5,
          pointHoverRadius: 10,
          pointBackgroundColor: '#FFFFFF',
        },
      ],
    });
  }, [document.getElementById('viewer-chart'), viewers]);

  const options: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (label, index) => {
            if (!viewers[index]) return '';
            return moment(viewers[index].datetime).format(FORMAT_TIME);
          },
        },
      },
      yAxes: {
        beginAtZero: true,
        ticks: {
          callback: (label) => {
            if (Math.floor(Number(label)) === Number(label)) {
              return label;
            }
            return null;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: () => {
            return `視聴者数`;
          },
          footer: (item) => {
            return `${item[0].raw}`;
          },
        },
        backgroundColor: '#407916',
        displayColors: false,
        yAlign: 'top',
        xAlign: 'center',
        bodyFont: {
          size: 12,
          weight: 'bold',
        },
        footerFont: {
          size: 20,
          weight: 'bold',
        },
        boxWidth: 90,
      },
    },
  };
  return (
    <Box className={`${classes.root} ${className}`} width={width + 40}>
      <span className={classes.viewer}>視聴者数</span>
      <span className={classes.time}>時刻</span>
      <Line
        height={360}
        width={width}
        data={data}
        options={options}
        id="viewer-chart"
        className={classes.chart}
      />
    </Box>
  );
};

export default ChartView;
