import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { LIVECONTROLLER_CHECK_WOWZA_FEED_MSG } from '../../utils/Message';
import {
  UI_STATUS,
  JCLOUD_SERVER_STATUS,
  LIVE_STATUS_ENG,
} from '../../utils/Const';
import { setError, setLiveControllerStatus } from '../../features/uiSlice';
import {
  setWowzaFeed,
  addEQLiveProfile,
  setLiveEventProfile,
} from '../../features/livesSlice';
import {
  GetWowzaFeed,
  GetLiveProfile,
  GetLiveEvent,
  LiveEventProfile,
} from '../../types/API';
import { FeedStateType } from '../../types/Utils';

type CheckLiveCountParams = {
  wlivesId: string;
  autoMode: boolean;
  lid: {
    cid: number;
    userApiParams: string;
    lpid: number;
    customerDir: string;
  };
  jcloudDeactivatedDialog: boolean;
  setJcloudDeactivatedDialog: React.Dispatch<React.SetStateAction<boolean>>;
  liveStatus: string | undefined;
  setNoFeedNotification: React.Dispatch<React.SetStateAction<FeedStateType>>;
  feedinCnt: FeedStateType;
  setFeedinCnt: React.Dispatch<React.SetStateAction<FeedStateType>>;
  event: LiveEventProfile;
};

/** フィードを確認し、オートモードの場合はステータス更新 */
const CheckWowzaFeed =
  (arg: CheckLiveCountParams): AppThunk =>
  async (dispatch, getState) => {
    try {
      // フィード状態チェック
      // Wowzaフィード確認API実行
      const responseFeed: AxiosResponse<GetWowzaFeed[]> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getWowzaFeed`,
        {
          wlivesIds: [arg.wlivesId],
          lids: [arg.lid],
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setWowzaFeed({ ...responseFeed.data[0] }));

      if (
        responseFeed.data[0].wlivesStatus ===
          JCLOUD_SERVER_STATUS.DEACTIVATEED &&
        !arg.jcloudDeactivatedDialog
      ) {
        // ダイアログを出す
        arg.setJcloudDeactivatedDialog(true);
      }

      // 配信中にフィードが不安定の場合通知する
      if (arg.liveStatus === LIVE_STATUS_ENG.OPEN) {
        // フィードインがあって現在フィードがない方にフィード不安定を通知する
        const mainfeedNotification =
          arg.feedinCnt.mainFeed && !responseFeed.data[0].mainFeed;
        const backfeedNotification =
          arg.feedinCnt.backFeed && !responseFeed.data[0].backFeed;

        arg.setNoFeedNotification({
          mainFeed: mainfeedNotification,
          backFeed: backfeedNotification,
        });

        // フィードインがなしで現在フィードがあるフィードを登録する
        const mainFeedinCnt =
          !arg.feedinCnt.mainFeed && responseFeed.data[0].mainFeed;
        const backFeedinCnt =
          !arg.feedinCnt.backFeed && responseFeed.data[0].backFeed;
        if (mainFeedinCnt || backFeedinCnt) {
          // ライブイベント情報更新API実行
          await axios.post(
            `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}setEvent`,
            {
              cid: arg.lid.cid,
              userApiParams: arg.lid.userApiParams,
              lpid: arg.lid.lpid,
              leid: arg.event.leid,
              main_feedin_cnt: Number(
                responseFeed.data[0].mainFeed || arg.feedinCnt.mainFeed
              ),
              sub_feedin_cnt: Number(
                responseFeed.data[0].backFeed || arg.feedinCnt.backFeed
              ),
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
          // フィードインの状態をuseStateに格納
          arg.setFeedinCnt({
            mainFeed: responseFeed.data[0].mainFeed || arg.feedinCnt.mainFeed,
            backFeed: responseFeed.data[0].backFeed || arg.feedinCnt.backFeed,
          });
        }
      }

      // オートモードの場合
      if (arg.autoMode) {
        // ライブプロファイル取得
        const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
          {
            lpid: arg.lid.lpid,
            cid: arg.lid.cid,
            userApiParams: arg.lid.userApiParams,
            pageSize: 1,
            portal: getState().user.contract.serviceFlg39 ? 1 : 0,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

        // ライブイベント情報取得API実行
        const responseEvent: AxiosResponse<GetLiveEvent> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
          {
            cid: arg.lid.cid,
            userApiParams: arg.lid.userApiParams,
            lpid: arg.lid.lpid,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(setLiveEventProfile({ ...responseEvent.data }));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: LIVECONTROLLER_CHECK_WOWZA_FEED_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default CheckWowzaFeed;
