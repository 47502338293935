import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { People, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Sensors from '../../images/Sensors.svg';
import Moving from '../../images/Moving.svg';
import ChartView from './ChartView';
import ToggleInterval from './ToggleInterval';
import { CONTROLLER_MIN_WIDTH } from '../../utils/Const';
import { FileToLiveControllerProps } from '../../props/FileToLiveController';

/** スタイル */
const useStyle = makeStyles({
  root: {
    minWidth: 1020,
    width: 'calc(100% - 560px)',
    height: 903,
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerBlock: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 12px #1C385E0C',
    borderRadius: 8,
    padding: '13px 20px',
    minWidth: 330,
    width: 'calc(100% / 3)',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
    fontWeight: 500,
    marginRight: 15,
    '&:last-child': {
      margin: 0,
    },
  },
  headerIcon: {
    width: 30,
    height: 30,
    padding: 10,
    boxShadow: '0px 3px 3px #1C385E10',
    borderRadius: 8,
    marginRight: 10,
  },
  headerNumber: {
    fontSize: 34,
    fontWeight: 700,
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  section: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 12px #1C385E0C',
    borderRadius: 8,
    marginTop: 20,
  },
  sectionHeader: {
    display: 'flex',
    padding: '20px 30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    '& h3': {
      fontSize: 16,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
    },
  },
  sectionBody: {
    height: 450,
    boxSizing: 'border-box',
  },
  toggleInterval: {
    margin: '20px 20px 15px',
    transition: 'all 400ms',
    '& .MuiToggleButtonGroup-root': {
      width: 302,
      padding: '0 4px',
    },
    '& .MuiToggleButton-root': {
      padding: '11px 6px',
      '&:hover': {
        borderRadius: 100,
      },
    },
  },
  cartView: {
    height: 370,
    margin: '0 13px 13px',
  },
  chatEmbedd: {
    height: 940,
  },
});

/** コントロールパネル 右(UI) */
const ControllerRight = ({
  props,
}: {
  props: FileToLiveControllerProps;
}): JSX.Element => {
  const classes = useStyle();
  const [viewGraph, setViewGraph] = useState(true);
  const { liveClientsInfo, chatenable, chatConsoleIframe } = props;
  const [cartWidth, setCartWidth] = useState(940);
  useEffect(() => {
    if (window.innerWidth <= CONTROLLER_MIN_WIDTH) {
      setCartWidth(940);
      return;
    }
    const innerWidth =
      window.innerWidth - 220 > CONTROLLER_MIN_WIDTH
        ? window.innerWidth - 220
        : CONTROLLER_MIN_WIDTH;
    const width = innerWidth - 640;
    setCartWidth(width);
  }, [window.innerWidth]);
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerBlock}>
          <span className={classes.alignCenter}>
            <People className={classes.headerIcon} />
            現在の視聴者数
          </span>
          <span className={classes.headerNumber}>
            {liveClientsInfo.current === null
              ? '-'
              : liveClientsInfo.current.toLocaleString()}
          </span>
        </Box>
        <Box className={classes.headerBlock}>
          <span className={classes.alignCenter}>
            <img src={Sensors} alt={Sensors} className={classes.headerIcon} />
            最高同時視聴者数
          </span>
          <span className={classes.headerNumber}>
            {liveClientsInfo.max === null
              ? '-'
              : liveClientsInfo.max.toLocaleString()}
          </span>
        </Box>
        <Box className={classes.headerBlock}>
          <span className={classes.alignCenter}>
            <img src={Sensors} alt={Sensors} className={classes.headerIcon} />
            累計視聴者数
          </span>
          <span className={classes.headerNumber}>
            {liveClientsInfo.total === null
              ? '-'
              : liveClientsInfo.total.toLocaleString()}
          </span>
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box
          className={classes.sectionHeader}
          onClick={() => setViewGraph(!viewGraph)}
        >
          <h3>
            <img
              src={Moving}
              alt="Moving"
              style={{ marginRight: 6, width: 20 }}
            />
            視聴者数推移
          </h3>
          {viewGraph ? (
            <KeyboardArrowDown fontSize="medium" />
          ) : (
            <KeyboardArrowUp fontSize="medium" />
          )}
        </Box>
        {viewGraph && (
          <Box className={classes.sectionBody}>
            <ToggleInterval
              controllerProps={props}
              style={{ margin: '20px 30px 0' }}
            />
            <ChartView
              controllerProps={props}
              className={classes.cartView}
              width={cartWidth}
            />
          </Box>
        )}
      </Box>
      {chatenable && (
        <Box className={classes.section}>
          <Box
            className={classes.chatEmbedd}
            dangerouslySetInnerHTML={{ __html: chatConsoleIframe }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ControllerRight;
