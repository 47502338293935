import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import {
  ChatSetting,
  ContentBaseSetting,
  FillerSetting,
  LiveEventInfo,
  ThumbnailSetting,
} from '../../props/FileToLiveContent';
import { InputData } from '../../props/FileToLiveRegister';
import {
  LIVE_SAVE,
  FILE_TO_LIVE_STATUS_ENG,
  FILE_TO_LIVE_CHAT_STATUS,
} from '../../utils/Const';
import { QUERY_ERR_MSG } from '../../utils/Message';
import {
  getEndTiming,
  getAutoEndMinutes,
  getPortalMetaInit,
} from '../../utils/ChangeContentData';
import { setError } from '../../features/uiSlice';
import FileToLiveProfileInit from '../FileToLiveRegister/FileToLiveProfileInit';
import FileToLiveUpdate from './FileToLiveUpdate';
import { selectEQFileToLivesById } from '../../features/fileToLivesSlice';
import GetMediaList from '../FileToLiveRegister/GetMediaList';
import GetFillerList from './GetFillerList';
import FileToLiveContentComponent from '../../components/FileToLiveContent/FileToLiveContentComponent';

/** 疑似ライブ詳細 */
const FileToLiveContentContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // クエリチェック
  const searchString = window.location.search;
  const params = new URLSearchParams(searchString);
  const flpid = Number(params.get('flpid'));
  if (!flpid) {
    dispatch(
      setError({
        msg: QUERY_ERR_MSG,
        detail: '',
      })
    );
  }

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const EQFileToLive = useAppSelector((state: RootState) =>
    selectEQFileToLivesById(state, flpid)
  );

  // 変数抽出
  const { edition, liveInfo } = user.contract;
  const { maxClient } = liveInfo;

  // エラーメッセージ
  const [message, setMessage] = useState<string>('');

  // 項目非活性フラグ設定
  const disableFlg =
    EQFileToLive?.status !== FILE_TO_LIVE_STATUS_ENG.WAITING ?? false;
  const maxClientLimitDisableFlg =
    (EQFileToLive?.status !== FILE_TO_LIVE_STATUS_ENG.WAITING ||
      EQFileToLive?.chatRoomStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING) ??
    false;

  // 初期処理
  useEffect(() => {
    dispatch(FileToLiveProfileInit(LIVE_SAVE.UPDATE));
  }, []);

  // 保存処理
  const saveFileToLiveProfile = (data: InputData) => {
    if (!EQFileToLive) return;
    dispatch(FileToLiveUpdate(data, edition, setMessage, EQFileToLive));
  };

  // 利用動画一覧取得
  const getMediaList = (mid: string) => {
    dispatch(GetMediaList(mid));
  };

  // フィラー利用動画一覧取得
  const getFillerList = (
    type: 'pre' | 'post',
    mid: string,
    searchFillerMid: string
  ) => {
    dispatch(GetFillerList(type, mid, searchFillerMid));
  };

  // 基本設定
  const baseSetting: ContentBaseSetting = {
    maxClient,
    flpid: EQFileToLive?.flpid ?? 0,
    mid: EQFileToLive?.mid ?? 0,
    startDate: new Date(EQFileToLive?.startDate ?? new Date()),
    endDate: new Date(EQFileToLive?.closeDate ?? new Date()),
    liveName: EQFileToLive?.liveName ?? '',
    liveDescription: EQFileToLive?.liveDescription ?? '',
    maxClientLimit: EQFileToLive?.maxClient ?? 0,
  };

  // チャット設定
  const chatSetting: ChatSetting = {
    enabled: Boolean(EQFileToLive?.chat.enabled),
    autoResize: Boolean(EQFileToLive?.chat.autoResizeFlg),
    size: {
      width: EQFileToLive?.chat.width ?? 0,
      height: EQFileToLive?.chat.height ?? 0,
    },
    startTiming: EQFileToLive?.chat.openTiming ?? 0,
    endTiming: getEndTiming(EQFileToLive?.chat.closeTiming ?? 0),
    autoEndMinutes: getAutoEndMinutes(EQFileToLive?.chat.closeTiming ?? 0),
    blockType: EQFileToLive?.chat.blockType ?? 0,
    enterMsg: EQFileToLive?.chat.enterMsg ?? 0,
    goodBtn: Boolean(EQFileToLive?.chat.goodBtn),
    troubleBtn: Boolean(EQFileToLive?.chat.troubleBtn),
    customBtn1: {
      enable: EQFileToLive?.chat.iconTexts
        ? Boolean(EQFileToLive?.chat.iconTexts[0]?.activeFlg)
        : false,
      name: EQFileToLive?.chat.iconTexts
        ? EQFileToLive?.chat.iconTexts[0]?.text
        : '',
    },
    customBtn2: {
      enable: EQFileToLive?.chat.iconTexts
        ? Boolean(EQFileToLive?.chat.iconTexts[1]?.activeFlg)
        : false,
      name: EQFileToLive?.chat.iconTexts
        ? EQFileToLive?.chat.iconTexts[1]?.text
        : '',
    },
    adminDisplayName: EQFileToLive?.chat.adminDisplayName ?? '',
    blockWords: EQFileToLive?.chat.blockWords.join(',') ?? '',
  };

  // フィラー映像設定
  const fillerSetting: FillerSetting = {
    prefillerMid:
      EQFileToLive?.prefillerMid && Number(EQFileToLive?.prefillerMid) !== -1
        ? EQFileToLive?.prefillerMid
        : 0,
    postfillerMid:
      EQFileToLive?.postfillerMid && Number(EQFileToLive?.postfillerMid) !== -1
        ? EQFileToLive?.postfillerMid
        : 0,
  };

  // サムネイル
  const thumbnailSetting: ThumbnailSetting = {
    feedout: {
      url: EQFileToLive?.thumbFeedout ?? '',
      insertDate: EQFileToLive?.thumbFeedoutDatetime ?? '',
    },
    feedin: {
      url: EQFileToLive?.thumbFeedin ?? '',
      insertDate: EQFileToLive?.thumbFeedinDatetime ?? '',
    },
    liveopen: {
      url: EQFileToLive?.thumbLiveopen ?? '',
      insertDate: EQFileToLive?.thumbLiveopenDatetime ?? '',
    },
  };

  // ライブイベント情報
  const liveEventInfo: LiveEventInfo =
    EQFileToLive?.portalMeta ?? getPortalMetaInit();

  return (
    <FileToLiveContentComponent
      edition={edition}
      disableFlg={disableFlg}
      maxClientLimitDisableFlg={maxClientLimitDisableFlg}
      baseSetting={baseSetting}
      chatSetting={chatSetting}
      fillerSetting={fillerSetting}
      thumbnailSetting={thumbnailSetting}
      liveEventInfo={liveEventInfo}
      message={message}
      saveFileToLiveProfile={saveFileToLiveProfile}
      getMediaList={getMediaList}
      getFillerList={getFillerList}
    />
  );
};

export default FileToLiveContentContainer;
