import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import type { RootState, AppDispatch } from './app/store';
import { init } from './features/uiSlice';
import { PATH_TOKEN, ROUTE } from './utils/Const';
import { initializeGA } from './utils/GoogleAnalytics';
import MiddleRoute from './utils/MiddleRoute';
import MockServer from './utils/MockServer';
import ErrorComponent from './components/Error/ErrorComponent';
import LiveRegisterContainer from './containers/LiveRegister/LiveRegisterContainer';
import LiveListContainer from './containers/LiveList/LiveListContainer';
import LiveControllerContainer from './containers/LiveController/LiveControllerContainer';
import LiveContentContainer from './containers/LiveContent/LiveContentContainer';
import LiveMonitorContainer from './containers/LiveMonitor/LiveMonitorContainer';
import HistoryListContainer from './containers/HistoryList/HistoryListContainer';
import HistoryContentContainer from './containers/HistoryContent/HistoryContentContainer';
import FileToLiveListContainer from './containers/FileToLiveList/FileToLiveListContainer';
import FileToLiveRegisterContainer from './containers/FileToLiveRegister/FileToLiveRegisterContainer';
import FileToLiveContentContainer from './containers/FileToLiveContent/FileToLiveContentContainer';
import FileToLiveControllerContainer from './containers/FileToLiveController/FileToLiveControllerContainer';

/** Mockモードの場合、API Mockサーバー起動 */
if (String(process.env.REACT_APP_MOCK_MODE) === 'true') {
  MockServer();
}

/** エントリーポイント */
const Main = (): JSX.Element => {
  const ui = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch<AppDispatch>();

  // 初期処理
  useEffect(() => {
    dispatch(init({}));
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_GA4_ID) {
      initializeGA();
    }
  }, [process.env.REACT_APP_GA4_ID]);

  // エラー発生
  if (ui.error.flg) return <ErrorComponent {...ui.error.data} />;

  // 初期処理実行中
  if (!ui.auth) return <LinearProgress />;

  // 上記以外は、ルーティングに従って画面表示
  return (
    <BrowserRouter>
      <Switch>
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.LIVE_LIST}`}
          component={LiveListContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.LIVE_REGISTER}`}
          component={LiveRegisterContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.LIVE_CONTENT}`}
          component={LiveContentContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.LIVE_CONTROLLER}`}
          component={LiveControllerContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.LIVE_MONITOR}`}
          component={LiveMonitorContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.HISTORY_LIST}`}
          component={HistoryListContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.HISTORY_CONTENT}`}
          component={HistoryContentContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.FILE_TO_LIVE_LIST}`}
          component={FileToLiveListContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.FILE_TO_LIVE_REGISTER}`}
          component={FileToLiveRegisterContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.FILE_TO_LIVE_CONTENT}`}
          component={FileToLiveContentContainer}
        />
        <MiddleRoute
          exact
          path={`${PATH_TOKEN}${ROUTE.FILE_TO_LIVE_CONTROLLER}`}
          component={FileToLiveControllerContainer}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
