import React from 'react';
import { changeDpiDataUrl } from 'changedpi';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  TextField,
} from '@material-ui/core';
import { InfoOutlined, Replay } from '@material-ui/icons';
import QRCode from 'qrcode.react';
import { LiveShareProps } from '../../props/LiveShare';
import { LIVESAHRE_TABS, QR_FORMAT } from '../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    boxSizing: 'border-box',
    padding: '0 20px 25px',
    '& .MuiInputBase-root.Mui-focused fieldset, .MuiInputBase-root:hover fieldset':
      {
        borderColor: '#DDDDDD',
      },
  },
  body: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigation: {
    marginBottom: 20,
    borderBottom: '2px solid #F0F0F0',
    justifyContent: 'flex-start',
    '& .MuiBottomNavigationAction-root': {
      width: 190,
      borderBottom: '2px solid #F0F0F0',
      marginBottom: '-2px',
      '&.Mui-selected': {
        borderColor: '#61B227',
        opacity: 1,
      },
    },
    '& .MuiBottomNavigationAction-label': {
      color: '#333333',
      fontSize: 15,
      fontWeight: 700,
      opacity: 0.4,
      '&.Mui-selected': {
        opacity: 1,
      },
    },
  },
  textField: {
    lineHeight: '18px',
    '& .MuiOutlinedInput-multiline': {
      width: 560,
      height: 150,
      background: '#FAFAFA',
      borderRadius: 4,
      fontSize: 13,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },
  },
  QRtextField: {
    lineHeight: '18px',
    '& .MuiOutlinedInput-multiline': {
      width: 676,
      height: 50,
      background: '#FAFAFA',
      borderRadius: 4,
      fontSize: 13,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },
  },
  bodyRight: {
    width: 560,
    color: '#333333',
    lineHeight: '20px',
    fontSize: 14,
  },
  attention: {
    display: 'flex',
    color: '#C32733',
    marginTop: 4,
  },
  button: {
    backgroundColor: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    borderRadius: 4,
    padding: '10px 34px',
    color: '#FFFFFF',
    fontSize: 17,
    fontWeight: 500,
    marginTop: 20,
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'all 400ms',
    '&:hover': {
      backgroundColor: '#015CFA',
    },
  },
  contentCopy: {
    position: 'absolute',
    minWidth: 14,
    width: 14,
    height: 14,
    top: 10,
    right: 10,
    opacity: 0.3,
    '& img': {
      width: 14,
      height: 14,
    },
  },
  QRbodyRight: {
    width: 434,
    height: 176,
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '20px 22px 0',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
  },
  QRbodyLeft: {
    width: 680,
  },
  QRcode: {
    display: 'inline-block',
    width: 105,
    height: 105,
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 4,
  },
  refreshQRCode: {
    fontSize: 13,
    fontWeight: 500,
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'all 400ms',
    '&:hover': {
      color: '#407917',
      background: 'none',
    },
  },
});

type Props = {
  props: LiveShareProps;
  selectTab: LIVESAHRE_TABS;
  setSelectTab: React.Dispatch<React.SetStateAction<LIVESAHRE_TABS>>;
};

/** 共通タグ下部 */
const ShareBottom = ({
  props,
  selectTab,
  setSelectTab,
}: Props): JSX.Element => {
  const classes = useStyle();
  const {
    chatenable,
    autoResize,
    scriptTag,
    iframeTag,
    videoUrl,
    videoShortUrl,
    chatTag,
    refreshQRCode,
    setChatTab,
    copyURL,
    copyShortURL,
    QRfileName,
    QRInfo,
    shortURLflg,
    QRUrl,
    QRShortUrl,
    createSnackbar,
  } = props;

  /** タブ選択イベント */
  const handleSelectTab = (value: LIVESAHRE_TABS): void => {
    setSelectTab(value);
    if (value === LIVESAHRE_TABS.CHAT_TAG) {
      setChatTab(true);
    } else {
      setChatTab(false);
    }
  };

  const handleCopy = (id: string) => {
    const text: HTMLInputElement = document.getElementById(
      id
    ) as HTMLInputElement;
    text.select();
    createSnackbar();
    navigator.clipboard.writeText(text.value);
  };

  // TODO: tagの文言修正はこの関数に全部寄せる
  const getText = () => {
    if (selectTab === LIVESAHRE_TABS.SCRIPT) {
      return scriptTag.tag;
    }
    if (selectTab === LIVESAHRE_TABS.IFRAME) {
      return iframeTag.tag;
    }
    if (selectTab === LIVESAHRE_TABS.QR_URL) {
      return shortURLflg ? videoShortUrl : videoUrl.tag;
    }
    if (selectTab === LIVESAHRE_TABS.CHAT_TAG) {
      if (autoResize && chatTag?.tag) {
        return chatTag.tag
          .replace(/width:(\d*)px;/, `width:100%;`)
          .replace(/height:(\d*)px;/, `height:100%;`);
      }
      return chatTag?.tag ?? '';
    }
    return '';
  };

  /** QRコードダウンロード処理 */
  const downloadQRCode = (id: string) => {
    const canvas = document.getElementById(id) as HTMLCanvasElement;

    // ダウンロードようにcanvasを作成
    const newCanvas = document.createElement('canvas');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const context = newCanvas.getContext('2d')!;

    // リサイズ
    // NOTE: canvasのデフォルトのdpiが96
    newCanvas.width = QRInfo.size;
    newCanvas.height = QRInfo.size;

    // リサイズした値で複製
    context.drawImage(canvas, 0, 0, QRInfo.size, QRInfo.size);

    const dataURL =
      QRInfo.format === QR_FORMAT.PNG
        ? newCanvas.toDataURL('image/png')
        : newCanvas.toDataURL('image/jpeg', 1.0);
    const scaleDpi = changeDpiDataUrl(dataURL, QRInfo.dpi);
    const link = document.createElement('a');
    link.href = scaleDpi;
    link.download = QRfileName;
    link.click();
  };

  return (
    <Box className={classes.root}>
      <BottomNavigation
        showLabels
        value={selectTab}
        onChange={(event, newValue) => {
          handleSelectTab(newValue);
        }}
        className={classes.navigation}
      >
        <BottomNavigationAction
          label="scriptタグ"
          value={LIVESAHRE_TABS.SCRIPT}
        />
        <BottomNavigationAction
          label="iframeタグ"
          value={LIVESAHRE_TABS.IFRAME}
        />
        <BottomNavigationAction
          label="動画URL/QRコード"
          value={LIVESAHRE_TABS.QR_URL}
        />
        {chatenable && (
          <BottomNavigationAction
            label="チャットのみ"
            value={LIVESAHRE_TABS.CHAT_TAG}
          />
        )}
      </BottomNavigation>
      {selectTab !== LIVESAHRE_TABS.QR_URL && (
        <Box className={classes.body}>
          <Box style={{ position: 'relative' }}>
            <TextField
              value={getText()}
              InputProps={{ readOnly: true }}
              className={classes.textField}
              multiline
              rows={8}
              variant="outlined"
              id="textField"
              onFocus={(e) => e.target.select()}
              onClick={(e) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
          </Box>
          <Box className={classes.bodyRight}>
            {selectTab === LIVESAHRE_TABS.SCRIPT &&
              '動的にライブタグを生成するタグになります。動画プレイヤーが直接ページに埋め込まれる見え方になります。'}
            {selectTab === LIVESAHRE_TABS.IFRAME &&
              '動画の貼られた動的HTMLページをiframeで埋め込むタグになります。'}
            {selectTab === LIVESAHRE_TABS.CHAT_TAG &&
              '動的にライブチャットタグを生成するタグになります。'}
            <Box className={classes.attention}>
              <InfoOutlined fontSize="small" style={{ marginRight: 6 }} />
              <span>
                貼付タグはそのままご利用ください。カスタマイズを行う事で、予期せぬ不具合が発生する可能性がございます。
              </span>
            </Box>
            <Button
              className={classes.button}
              onClick={() => handleCopy('textField')}
            >
              タグをコピー
            </Button>
          </Box>
        </Box>
      )}
      {selectTab === LIVESAHRE_TABS.QR_URL && (
        <Box className={classes.body}>
          <Box className={classes.QRbodyLeft}>
            <Box style={{ fontSize: 13, lineHeight: '19px', marginBottom: 12 }}>
              動画URLは、この動画を視聴するための単一URLです。HTMLを用意して埋め込みタグを貼り付けなくても、動画URLをクリックすると、動画の貼られた動的HTMLページに遷移して、動画を視聴することができます。サイト内に動画を設置せずに、メルマガから直接動画を視聴させる場合などにご利用ください。
              <span
                className={classes.attention}
                style={{ display: 'inline-block' }}
              >
                ※再生ドメイン制限を設定している場合、短縮URL化するとAndroid
                Chromeで再生できなくなる場合があります。
                <a
                  href="https://support.eq.stream.co.jp/hc/ja/articles/15170345458073"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  詳細はこちら
                </a>
              </span>
            </Box>
            <Box style={{ position: 'relative' }}>
              <TextField
                value={getText()}
                InputProps={{ readOnly: true }}
                className={classes.QRtextField}
                multiline
                rows={2}
                variant="outlined"
                id="QRtextField"
                onFocus={(e) => e.target.select()}
                onClick={(e) => {
                  const target = e.target as HTMLInputElement;
                  target.select();
                }}
              />
            </Box>
            <Box style={{ display: 'flex' }}>
              <Button
                className={classes.button}
                onClick={() => copyURL('QRtextField')}
                style={{
                  marginRight: 15,
                  marginTop: 12,
                  maxHeight: 52,
                }}
              >
                URLをコピー
              </Button>
              <Button
                className={classes.button}
                onClick={() => copyShortURL('QRtextField')}
                style={{
                  marginTop: 12,
                  maxHeight: 52,
                }}
              >
                短縮URLをコピー
              </Button>
              <Box
                style={{
                  padding: 8,
                  color: 'red',
                  width: 276,
                  fontSize: '0.7rem',
                  lineHeight: 'normal',
                }}
              >
                ＜重要なお知らせ＞
                <br />
                短縮URL機能は2025年1月28日にリニューアルしました。今後は「eqm.jp」の短縮URLをご利用ください。
                <br />
                従来の「eql.page.link」の短縮URLおよびQRコードは2025年8月25日以降リンク切れとなりますのでご注意ください。
                <br />
                <a
                  href="https://support.eq.stream.co.jp/hc/ja/articles/40843289316761"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  詳細はこちら
                </a>
              </Box>
            </Box>
          </Box>
          <Box className={classes.QRbodyRight}>
            <Box style={{ width: 105 }}>
              <QRCode
                value={shortURLflg && QRShortUrl !== '' ? QRShortUrl : QRUrl}
                size={QRInfo.size}
                level="L"
                includeMargin
                id="QRUrl"
                className={classes.QRcode}
                style={{ width: 105, height: 105 }}
              />
              <Button className={classes.refreshQRCode} onClick={refreshQRCode}>
                <Replay fontSize="small" />
                再生成
              </Button>
            </Box>
            <Box>
              <Box
                style={{
                  width: 263,
                  fontSize: 13,
                  lineHeight: '19px',
                }}
              >
                QRコードのリンク先は動画URLになります。動画の埋め込みサイズや、使用するプレイヤーの選択を変更した場合は、
                必ずQR画像の再生成を行ってください。
              </Box>
              <Button
                className={classes.button}
                onClick={() => downloadQRCode('QRUrl')}
              >
                QR画像を保存
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ShareBottom;
