import React, { useCallback, useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { FormDropzoneProps } from '../../../props/Parts';
import { DROP_ZONE_ERR_CODE } from '../../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {
    background: '#D8D8D8',
    borderRadius: 4,
    borderBottom: '1px solid #AAAAAA',
    padding: '6px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    fontWeight: 500,
    fontSize: 14,
    '&:hover': {
      background: '#333333',
      color: '#FFFFFF',
      transition: 'all 400ms',
    },
  },
});

/** ReactHookForm用画像アップロード */
const FormDropzone = ({
  name,
  register,
  setValue,
  disabled = false,
  className = '',
  accept = '',
  maxMbyte = 2,
  maxFiles = 1,
}: FormDropzoneProps): JSX.Element => {
  const classes = useStyle();
  // 画像ファイルのサイズをチェックする
  const imagesize = async (
    file: File
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const size = {
          width: img.width,
          height: img.height,
        };
        resolve(size);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const file = fileRejections[0];
      // エラーがなければ高さチェック
      if (!file?.errors) {
        // 高さが240pxない場合はエラー
        const size = await imagesize(acceptedFiles[0]);
        if (size.height < 240) {
          setValue(
            `${name}` as const,
            {
              errors: [
                {
                  code: DROP_ZONE_ERR_CODE.HEIGHT_TOO_SMALL,
                },
              ],
            },
            {
              shouldValidate: true,
            }
          );
          return;
        }
        // 正常
        setValue(`${name}` as const, acceptedFiles[0], {
          shouldValidate: true,
        });
      } else {
        setValue(`${name}` as const, file, {
          shouldValidate: true,
        });
      }
    },
    [setValue, name]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop,
    accept,
    maxSize: maxMbyte * 1000000,
    maxFiles,
  });
  useEffect(() => {
    register(`${name}` as const);
  }, [register, name]);
  return (
    <div {...getRootProps()} className={className}>
      <input type="file" name={name} accept={accept} {...getInputProps()} />
      <Button className={classes.root} onClick={open} disabled={disabled}>
        アップロード
        <KeyboardArrowRight fontSize="small" />
      </Button>
    </div>
  );
};

export default FormDropzone;
