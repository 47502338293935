import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormTextBoxProps } from '../../../props/Parts';

/** ReactHookForm用テキストボックス */
const FormTextBox = ({
  name,
  register,
  width,
  backgroundColor = '',
  label = '',
  defaultValue = '',
  placeholder = '',
  maxLength = 100,
  disabled = false,
  readOnly = false,
  numberOnly = false,
  numberStep = 1,
  rows = 1,
  validationError = false,
  validationErrorMsg = '',
  validation,
  className = '',
  onBlur,
}: FormTextBoxProps): JSX.Element => {
  const field = register(`${name}` as const, validation);
  return (
    <TextField
      size="small"
      variant="outlined"
      color="primary"
      {...field}
      style={{ width, backgroundColor }}
      fullWidth={width === 'fullWidth'}
      label={label}
      defaultValue={defaultValue}
      placeholder={placeholder}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly,
        inputProps: {
          maxLength,
          step: numberStep,
        },
      }}
      disabled={disabled}
      type="text"
      multiline={rows > 1}
      rows={rows}
      error={validationError}
      helperText={validationErrorMsg}
      className={className}
      onBlur={onBlur}
      onChange={(e) => {
        if (numberOnly) {
          const value = parseInt(e.target.value, 10);
          e.target.value = Number.isNaN(value) ? '' : value.toString();
        }
        field.onChange(e);
      }}
    />
  );
};

export default FormTextBox;
