import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Error } from '../../types/Error';
import CopyButtonTextBox from '../parts/CopyButtonTextBox/CopyButtonTextBox';

/** スタイル */
const useStyle = makeStyles({
  root: {
    height: document.body.clientHeight,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    alignItems: 'center',
    color: '#CB2016',
    marginLeft: '-6px',
  },
  titleIcon: {
    fontSize: 44,
  },
  label: {
    textAlign: 'left',
    font: 'normal normal bold 40px/18px Inter',
    letterSpacing: 0.4,
  },
  box: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 602,
    height: 198,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #E0F0F0',
    borderRadius: 6,
  },
  messageIcon: {
    fontSize: 17,
  },
  message: {
    textAlign: 'center',
    font: 'normal normal bold 18px/18px Noto Sans CJK JP',
    letterSpacing: 0.18,
    color: '#333333',
  },
});

/** エラー画面(UI) */
const ErrorComponent = ({ msg, detail }: Error): JSX.Element => {
  const classes = useStyle();
  return (
    <Box display="flex" className={classes.root}>
      <Box pb={3} display="flex" className={classes.title}>
        <Box pr={1}>
          <WarningIcon className={classes.titleIcon} />
        </Box>
        <Box className={classes.label}>Error</Box>
      </Box>
      <Box display="flex" className={classes.box}>
        <Box display="flex">
          <Box pr={1}>
            <InfoOutlinedIcon className={classes.messageIcon} />
          </Box>
          <Box className={classes.message}>{msg}</Box>
        </Box>
        {detail && (
          <Box pt={3} display="flex">
            <CopyButtonTextBox width={502} value={detail} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ErrorComponent;
