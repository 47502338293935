import React, { useEffect, useState } from 'react';
import {
  UseFormRegister,
  UseFormClearErrors,
  UseFormWatch,
  Control,
  FieldValues,
  RegisterOptions,
  UseFormSetError,
  UseFormSetValue,
  Validate,
} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, TextField } from '@material-ui/core';
import { InfoOutlined, Help } from '@material-ui/icons';
import FormTextBox from '../parts/FormTextBox/FormTextBox';
import FormRadioButton from '../parts/FormRadioButton/FormRadioButton';
import FormSelectBox from '../parts/FormSelectBox/FormSelectBox';
import FormCheckBox from '../parts/FormCheckBox/FormCheckBox';
import Tooltip from '../parts/Tooltip/Tooltip';
import {
  SUPPORTPAGE_URL_LIVEREGISTER,
  SETPAGE_URL_LIVEABR,
  SETPAGE_URL_TRANSCODE,
  SETPAGE_URL_VODGROUP,
  EDITIONS,
  STREAM_TYPE,
  APP_STREAMS,
  VOD_FLG,
} from '../../utils/Const';
import {
  REQUIRED_MSG,
  getMaxValueMsg,
  getMinValueMsg,
} from '../../utils/Message';
import { LiveRegisterProps } from '../../props/LiveRegister';
import { LiveContentProps } from '../../props/LiveContent';
import { HistoryContentProps } from '../../props/HistoryContent';
import { LiveABRProfile, Renditions } from '../../types/API';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& p': {
      margin: 0,
      fontSize: 13,
    },
    '& a': {
      color: '#4472C4',
    },
    '& .MuiGrid-item': {
      padding: '25px 32px',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 36,
    marginBottom: 30,
    fontSize: 15,
  },
  lpid: {
    background: '#F0F0F0',
    borderRadius: 4,
    width: 90,
    '& .MuiInputBase-root': {
      height: 40,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      textAlign: 'center',
    },
  },
  liveDVRProfile: {
    marginLeft: 60,
    marginTop: 14,
    '& .MuiFormControlLabel-label': {
      lineHeight: '28px',
      '& p': {
        marginBottom: 0,
      },
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      marginBottom: 24,
    },
    '&.disabled': {
      '& .MuiRadio-colorPrimary.Mui-disabled.Mui-checked, .MuiRadio-colorPrimary.Mui-disabled.Mui-checked+.MuiFormControlLabel-label.Mui-disabled':
        {
          color: 'rgba(0, 0, 0, 0.38)',
        },
    },
    '&.error .MuiFormControlLabel-root:last-child .MuiButtonBase-root.MuiIconButton-root':
      {
        marginBottom: 48,
      },
  },
  inputNumber: {
    background: '#FFFFFF',
    width: 55,
    marginRight: 4,
    '& .MuiInputBase-root': {
      height: 28,
    },
  },
  discription: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 14,
    color: '#33333399',
  },
  title: {
    borderLeft: '2px solid #61B227',
    paddingLeft: 20,
    marginLeft: 30,
    marginTop: 13,
    fontSize: 15,
    fontWeight: 500,
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '20px 22px 0',
    color: '#333333',
    '& p': {
      marginBottom: 20,
    },
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  helpContainer: {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  help: {
    color: '#B6BEC9',
    marginLeft: 5,
    marginBottom: '-2px',
    width: 16,
    height: 16,
  },
  icon: {
    color: '#33333399',
    marginRight: 4,
  },
  badge: {
    background: '#C32733',
    color: '#FFFFFF',
    padding: '2px 8px',
    fontSize: 12,
    borderRadius: 4,
    marginLeft: 15,
  },
});

/** 基本設定 */
const DefaultInfo = ({
  register,
  control,
  errors,
  watch,
  clearErrors,
  setError,
  setValue,
  RegisterProps,
  ContentProps,
  HistoryProps,
  open = false,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  errors?: Record<string, { message: string }>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  RegisterProps?: LiveRegisterProps;
  ContentProps?: LiveContentProps;
  HistoryProps?: HistoryContentProps;
  open?: boolean;
}): JSX.Element => {
  const classes = useStyle();
  const edition =
    RegisterProps?.edition || ContentProps?.edition || HistoryProps?.edition;
  const encryptEdition =
    RegisterProps?.encryptEdition ||
    ContentProps?.encryptEdition ||
    HistoryProps?.encryptEdition;
  const disableFlg = ContentProps?.disableFlg || false;
  const maxClientLimitDisableFlg =
    ContentProps?.maxClientLimitDisableFlg || false;

  const registerSetting = RegisterProps?.baseSetting;
  const contentSetting = ContentProps?.baseSetting;
  const historySetting = HistoryProps?.baseSetting;

  const maxClient =
    registerSetting?.maxClient ||
    contentSetting?.maxClient ||
    historySetting?.maxClient ||
    0;
  const nowClientCnt =
    registerSetting?.nowClientCnt ||
    contentSetting?.nowClientCnt ||
    historySetting?.nowClientCnt ||
    0;
  const transcodeProfiles =
    registerSetting?.transcodeProfiles ||
    contentSetting?.transcodeProfiles ||
    historySetting?.transcodeProfiles ||
    [];
  const groupProfiles =
    registerSetting?.groupProfiles ||
    contentSetting?.groupProfiles ||
    historySetting?.groupProfiles ||
    [];

  const [immediationValidation, setImmediationValidation] =
    useState<RegisterOptions>({
      required: false,
      min: {
        value: 3,
        message: getMinValueMsg(3),
      },
      max: {
        value: 540,
        message: getMaxValueMsg(540),
      },
      pattern: /^[0-9]+$/,
    });
  const [maxClientValidation, setMaxClientValidation] =
    useState<RegisterOptions>({});
  const [streamsChoiceFlg, setStreamsChoiceFlg] = useState(true);
  const [VodFlg, setVodFlg] = useState(true);
  const [LiveDVRProfileFlg, setLiveDVRProfileFlg] = useState(true);
  const [liveABRProfile, setLiveABRProfile] = useState<{
    list: LiveABRProfile[];
    desktopStream: boolean;
  }>({
    list:
      registerSetting?.liveABRProfiles ||
      contentSetting?.liveABRProfiles ||
      historySetting?.liveABRProfiles ||
      [],
    desktopStream: false,
  });
  const [activeQuality, setActiveQuality] = useState<string>('');
  const [originalQuality, setOriginalQuality] = useState<boolean>(false);

  const getLiveABRProfileList = (): { label: string; value: number }[] => {
    if (historySetting) {
      if (
        historySetting?.quality?.events &&
        historySetting?.quality?.events.length > 0
      ) {
        return [
          {
            label: historySetting.quality.events[0].profileName,
            value: historySetting.quality.events[0].lqpid,
          },
        ];
      }
      return [
        {
          label: '',
          value: 0,
        },
      ];
    }
    return liveABRProfile.list.map((x) => {
      return { label: x.profileName, value: x.lqpid };
    });
  };

  const getQualityString = (quality: Renditions | undefined): string => {
    const streams: string[] = [];
    if (!quality) return '';
    if (quality.original.activeFlg) streams.push('オリジナル画質');
    if (quality.fhd?.activeFlg) streams.push('フルHD画質(1080p)');
    if (quality.hd.activeFlg) streams.push('HD画質(720p)');
    if (quality.high.activeFlg) streams.push('高画質(480p)');
    if (quality.standard.activeFlg) streams.push('標準画質(360p)');
    if (quality.low.activeFlg) streams.push('低画質(240p)');
    return streams.join(' / ');
  };

  const validateABRProfiles: Validate<number> = (value: number) => {
    const list = getLiveABRProfileList();
    return Boolean(list.find((x) => x.value === Number(value)));
  };

  useEffect(() => {
    if (
      historySetting?.quality?.events &&
      historySetting?.quality?.events.length > 0
    ) {
      setValue(
        'BaseSettingLiveABRProfiles',
        historySetting?.quality.events[0].lqpid
      );
    }
  }, [historySetting?.quality?.events]);

  useEffect(() => {
    const max = maxClient - nowClientCnt;
    setMaxClientValidation({
      required: edition === EDITIONS.U_LIVE ? false : REQUIRED_MSG,
      pattern: /^[0-9]+$/,
      min: {
        value: 1,
        message: getMinValueMsg(1),
      },
      max: {
        value: max,
        message: getMaxValueMsg(max),
      },
    });
  }, [maxClient, nowClientCnt]);

  useEffect(() => {
    const flg =
      watch('BaseSettingLiveDVRProfileImmediation') === 'true' || disableFlg;
    setImmediationValidation({
      ...immediationValidation,
      required: flg ? false : REQUIRED_MSG,
    });
    if (flg) {
      clearErrors('BaseSettingLiveDVRProfileLifeTime');
      setValue(
        'BaseSettingLiveDVRProfileLifeTime',
        contentSetting?.liveDVRProfile.lifeTime ||
          historySetting?.liveDVRProfile.lifeTime ||
          ''
      );
    } else {
      // NOTE: 赤枠を付けて必須であることを強調させるため空でエラーセット
      setError('BaseSettingLiveDVRProfileLifeTime', {});
    }
  }, [watch('BaseSettingLiveDVRProfileImmediation')]);

  useEffect(() => {
    const flg = watch('BaseSettingVodFlgUse');
    setVodFlg(flg);
  }, [watch('BaseSettingVodFlgUse')]);

  useEffect(() => {
    const streamType = watch('BaseSettingStreamType');
    const flg =
      streamType === String(STREAM_TYPE.ENCODER) ||
      streamType === String(STREAM_TYPE.DESKTOP) ||
      streamType === String(STREAM_TYPE.LIVECAST);
    setStreamsChoiceFlg(flg);
    let abrProfileList =
      registerSetting?.liveABRProfiles ||
      contentSetting?.liveABRProfiles ||
      historySetting?.liveABRProfiles ||
      [];
    const abrProfileValue = historySetting?.lqpid ?? contentSetting?.lqpid ?? 0;
    let desktopStream = false;
    /**
     * NOTE: デスクトップ配信の場合、ライブ自動画質設定のリストから以下を除外する
     * ・パススルー配信の場合
     *  ・HD画質が含まれるもの(パススルー配信の場合、最上位画質の1つ上の帯域で配信するよう制御しており、フルHDはサポート外のためHDの除外が必要)
     *  ・オリジナル画質のみのもの(同様にオリジナル画質のみの場合、最上位画質の1つ上の帯域が判定できないため除外が必要)
     * ・パススルーしない配信
     *  ・フルHDが含まれるもの(パススルー配でない場合、最上位画質帯域で配信するよう制御しており、フルHDはサポート外のため除外が必要)
     */
    if (streamType === String(STREAM_TYPE.DESKTOP)) {
      abrProfileList = abrProfileList.filter((liveABRProfile) => {
        const { original, fhd, hd, high, standard, low } =
          liveABRProfile.quality;
        // パススルー配信の場合
        if (original.activeFlg) {
          return (
            !fhd?.activeFlg &&
            !hd.activeFlg &&
            !(
              original.activeFlg &&
              !high.activeFlg &&
              !standard.activeFlg &&
              !low.activeFlg
            )
          );
        }
        // パススルーしない配信の場合
        return !fhd?.activeFlg;
      });
      desktopStream = true;
    } else if (streamType === String(STREAM_TYPE.LIVECAST)) {
      // NOTE: EQライブキャストの場合、ライブ自動画質設定はパススルーしない配信（original.activateFlg=false）のものだけ
      // フルHDが入っているものはプルダウンリストに出さない
      abrProfileList = abrProfileList.filter((liveABRProfile) => {
        const { original, fhd } = liveABRProfile.quality;
        return !original?.activeFlg && !fhd?.activeFlg;
      });
    }
    // NOTE: 画質設定に設定したlqpidがない場合はエラーセットして値を消す
    if (
      (historySetting || contentSetting) &&
      !abrProfileList.find((x) => x.lqpid === abrProfileValue)
    ) {
      setError('BaseSettingLiveABRProfiles', {
        type: 'required',
        message: REQUIRED_MSG,
      });
      setValue('BaseSettingLiveABRProfiles', 0);
    }
    setLiveABRProfile({
      list: abrProfileList,
      desktopStream,
    });
  }, [watch('BaseSettingStreamType')]);

  useEffect(() => {
    const flg = watch('BaseSettingLiveDVRProfileFlg');
    setLiveDVRProfileFlg(flg);
  }, [watch('BaseSettingLiveDVRProfileFlg')]);

  useEffect(() => {
    const profile = liveABRProfile.list.find(
      (x) => x.lqpid === watch('BaseSettingLiveABRProfiles')
    );
    if (profile) {
      const quality = getQualityString(profile.quality);
      setActiveQuality(quality);
      setOriginalQuality(Boolean(profile.quality.original.activeFlg));
    }
  }, [watch('BaseSettingLiveABRProfiles')]);

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        ライブ配信設定の内容情報などを設定します。
        <a href={SUPPORTPAGE_URL_LIVEREGISTER} target="_blank" rel="noreferrer">
          カスタマーサイトで設定方法を確認する。
        </a>
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          プロファイルID
        </Grid>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            value={
              contentSetting || historySetting
                ? contentSetting?.lpid || historySetting?.lpid
                : '-'
            }
            disabled
            className={classes.lpid}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          ライブ配信設定名
          <span
            className={classes.helpContainer}
            style={{ display: 'inline-block' }}
          >
            <Help fontSize="small" className={classes.help} />
            <Tooltip message="管理画面で管理するための名称です。" />
          </span>
          {!historySetting && <span className={classes.badge}>必須</span>}
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="BaseSettingLiveName"
            width="fullWidth"
            register={register}
            validation={{
              required: disableFlg ? false : REQUIRED_MSG,
            }}
            maxLength={100}
            validationError={Boolean(errors?.BaseSettingLiveName)}
            validationErrorMsg={
              errors?.BaseSettingLiveName?.message
                ? String(errors?.BaseSettingLiveName?.message)
                : ''
            }
            defaultValue={contentSetting?.liveName || historySetting?.liveName}
            disabled={Boolean(historySetting) || disableFlg}
            className={
              !disableFlg && !watch('BaseSettingLiveName') ? 'error' : ''
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          ライブ設定説明
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="BaseSettingLiveDescription"
            width="fullWidth"
            register={register}
            rows={3}
            maxLength={500}
            defaultValue={
              contentSetting?.liveDescription || historySetting?.liveDescription
            }
            disabled={Boolean(historySetting) || disableFlg}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          同時接続数制限
          {edition !== EDITIONS.U_LIVE && (
            <span
              className={classes.helpContainer}
              style={{ display: 'inline-block' }}
            >
              <Help fontSize="small" className={classes.help} />
              <Tooltip message="入力可能な値は、1以上、契約されている同時接続数上限の値までとなります。" />
            </span>
          )}
          {!historySetting && edition !== EDITIONS.U_LIVE && (
            <span className={classes.badge}>必須</span>
          )}
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="BaseSettingMaxClient"
            width={90}
            register={register}
            validation={maxClientValidation}
            numberOnly
            placeholder={edition === EDITIONS.U_LIVE ? '制限なし' : ''}
            validationError={Boolean(errors?.BaseSettingMaxClient)}
            validationErrorMsg={
              errors?.BaseSettingMaxClient?.message
                ? String(errors?.BaseSettingMaxClient?.message)
                : ''
            }
            defaultValue={
              edition === EDITIONS.U_LIVE
                ? '制限なし'
                : contentSetting?.maxClientLimit ||
                  historySetting?.maxClientLimit
            }
            disabled={
              Boolean(historySetting || edition === EDITIONS.U_LIVE) ||
              maxClientLimitDisableFlg
            }
            className={
              !disableFlg &&
              edition !== EDITIONS.U_LIVE &&
              !watch('BaseSettingMaxClient')
                ? 'error'
                : ''
            }
          />
          <Box className={classes.discription} style={{ opacity: 1 }}>
            <InfoOutlined fontSize="small" className={classes.icon} />
            同時接続数上限：
            <span style={{ fontWeight: 700 }}>
              {edition === EDITIONS.U_LIVE ? '制限なし' : `${maxClient}人`}
            </span>
            ｜現在使用中の同時接続枠：
            {edition === EDITIONS.U_LIVE ? (
              '制限なし'
            ) : (
              <>
                <span style={{ fontWeight: 700, color: '#C32733' }}>
                  {`${nowClientCnt}人`}
                </span>
                &ensp;/&ensp;
                {`${maxClient}人`}
              </>
            )}
          </Box>
          {edition !== EDITIONS.U_LIVE && (
            <Box className={classes.discription}>
              <InfoOutlined fontSize="small" className={classes.icon} />
              同時接続枠を使用中の場合、その合算値が契約上限数を超えないように設定してください。
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          配信方法の選択
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <p className={classes.discription}>
              ライブ自動画質設定をご利用される場合は、「外部ライブエンコーダーソフト/機器を利用する」を選択してください。
            </p>
            <FormRadioButton
              name="BaseSettingStreamType"
              control={control}
              defaultValue={
                contentSetting?.streamType ||
                historySetting?.streamType ||
                STREAM_TYPE.ENCODER
              }
              disabled={Boolean(historySetting) || disableFlg}
              list={[
                {
                  label: '外部ライブエンコーダーソフト/機器を利用する',
                  value: STREAM_TYPE.ENCODER,
                },
                {
                  label: (
                    <span className={classes.alignCenter}>
                      デスクトップ配信機能を利用する
                      <span className={classes.helpContainer}>
                        <Help fontSize="small" className={classes.help} />
                        <Tooltip message="ユーザー自身のPCを使って、セルフライブ配信ができる機能" />
                      </span>
                    </span>
                  ),
                  value: STREAM_TYPE.DESKTOP,
                },
                {
                  label: (
                    <span className={classes.alignCenter}>
                      EQライブキャストを利用する
                      <span className={classes.helpContainer}>
                        <Help fontSize="small" className={classes.help} />
                        <Tooltip message="iPhone/iPadで撮影した映像をライブ配信することのできるiOSアプリ" />
                      </span>
                    </span>
                  ),
                  value: STREAM_TYPE.LIVECAST,
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
      {streamsChoiceFlg && (
        <Grid container spacing={8}>
          <Grid item xs={3}>
            ライブ自動画質設定の選択
            {!historySetting && <span className={classes.badge}>必須</span>}
          </Grid>
          <Grid item xs={9}>
            <Box className={classes.greenBack} style={{ paddingBottom: 20 }}>
              {!historySetting && (
                <p style={{ marginTop: 10 }} className={classes.discription}>
                  <a
                    href={SETPAGE_URL_LIVEABR}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ライブ自動画質設定画面
                  </a>
                  で予め設定が必要です。
                </p>
              )}
              <FormSelectBox
                name="BaseSettingLiveABRProfiles"
                control={control}
                width={300}
                disabled={Boolean(historySetting) || disableFlg}
                list={getLiveABRProfileList()}
                validation={{
                  required: streamsChoiceFlg ? REQUIRED_MSG : false,
                  min: {
                    value: 1,
                    message: REQUIRED_MSG,
                  },
                  validate: validateABRProfiles,
                }}
                validationError={Boolean(errors?.BaseSettingLiveABRProfiles)}
                validationErrorMsg={
                  errors?.BaseSettingLiveABRProfiles?.message
                    ? String(errors?.BaseSettingLiveABRProfiles?.message)
                    : ''
                }
                // NOTE: 新規登録時に空文字(=未選択)を入れる,id=0は旧から移行してきたもの
                defaultValue={contentSetting?.lqpid || 0}
                className={
                  watch('BaseSettingLiveABRProfiles')?.length <= 0
                    ? 'error'
                    : ''
                }
              />
              {liveABRProfile.desktopStream && (
                <Box className={classes.discription} style={{ fontSize: 13 }}>
                  ■パススルー配信の場合
                  <br />
                  デスクトップ配信機能をご利用される場合、フルHD画質、HD画質が選択された設定、オリジナル画質のみの設定はご利用いただけません。
                  <br />
                  ■パススルー配信しない場合
                  <br />
                  デスクトップ配信機能をご利用される場合、フルHDが選択された設定はご利用いただけません。
                  <br />
                </Box>
              )}
              {Number(watch('BaseSettingStreamType')) ===
                STREAM_TYPE.LIVECAST && (
                <Box
                  className={classes.discription}
                  style={{ fontSize: 13, lineHeight: '19px' }}
                >
                  <InfoOutlined
                    fontSize="small"
                    className={classes.icon}
                    style={{ marginTop: 1 }}
                  />
                  EQライブキャストをご利用される場合、パススルー配信の設定はご利用いただけません。
                </Box>
              )}
              {(watch('BaseSettingLiveABRProfiles') > 0 || HistoryProps) && (
                <Box
                  className={classes.discription}
                  style={{ fontSize: 13, lineHeight: '19px' }}
                >
                  <InfoOutlined
                    fontSize="small"
                    className={classes.icon}
                    style={{ marginTop: 1 }}
                  />
                  選択中の自動生成画質：
                  <span style={{ color: '#333333', fontWeight: 'bold' }}>
                    {historySetting
                      ? getQualityString(
                          // eslint-disable-next-line no-nested-ternary
                          historySetting?.quality
                            ? historySetting?.quality?.events.length > 0
                              ? historySetting?.quality?.events[0].quality
                              : undefined
                            : undefined
                        )
                      : activeQuality}
                  </span>
                  {originalQuality && (
                    <span style={{ width: '100%', marginLeft: 24 }}>
                      ※パススルー配信の設定のため、オリジナル画質が配信されます。
                    </span>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      {!streamsChoiceFlg && (
        <Grid container spacing={8}>
          <Grid item xs={3}>
            配信帯域
          </Grid>
          <Grid item xs={9}>
            <FormSelectBox
              name="BaseSettingStreams"
              control={control}
              width={300}
              disabled={Boolean(historySetting) || disableFlg}
              list={[
                {
                  label: 'HD画質',
                  value: APP_STREAMS.HD,
                },
                {
                  label: '高画質',
                  value: APP_STREAMS.HIGH,
                },
                {
                  label: '標準画質',
                  value: APP_STREAMS.STANDARD,
                },
                {
                  label: '低画質',
                  value: APP_STREAMS.LOW,
                },
              ]}
              defaultValue={
                contentSetting?.streams ||
                historySetting?.streams ||
                APP_STREAMS.STANDARD
              }
            />
          </Grid>
        </Grid>
      )}
      {encryptEdition && (
        <Grid container spacing={8}>
          <Grid item xs={3}>
            暗号配信機能の付与
          </Grid>
          <Grid item xs={9}>
            <Box className={classes.greenBack}>
              <FormCheckBox
                name="BaseSettingEncrypt"
                control={control}
                label="利用する"
                disabled={Boolean(historySetting) || disableFlg}
                defaultChecked={
                  contentSetting || historySetting
                    ? contentSetting?.encrypt || historySetting?.encrypt
                    : true
                }
              />
              <p
                className={classes.discription}
                style={{ marginLeft: 30, alignItems: 'flex-start' }}
              >
                <InfoOutlined fontSize="small" className={classes.icon} />
                「利用する」場合、標準暗号規格(AES)
                によって配信の暗号化を行います。
              </p>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={8}>
        <Grid item xs={3}>
          ライブDVR機能（ライブの巻き戻し/追いかけ視聴）の付与
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormCheckBox
              name="BaseSettingLiveDVRProfileFlg"
              control={control}
              label="利用する"
              disabled={Boolean(historySetting) || disableFlg}
              defaultChecked={
                contentSetting || historySetting
                  ? contentSetting?.liveDVRProfile?.flg ||
                    historySetting?.liveDVRProfile?.flg
                  : true
              }
            />
            <Box style={{ marginLeft: 30 }}>
              <p className={classes.discription}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                視聴者が巻き戻しできる時間は最大4時間までです。
              </p>
            </Box>
            <Box className={classes.title}>
              ライブ終了後のDVR配信有効期間設定
            </Box>
            <Box
              className={`${classes.liveDVRProfile} ${
                historySetting && !historySetting?.liveDVRProfile?.flg
                  ? 'disabled'
                  : ''
              } ${
                errors?.BaseSettingLiveDVRProfileLifeTime?.message && 'error'
              }`}
            >
              <FormRadioButton
                name="BaseSettingLiveDVRProfileImmediation"
                control={control}
                defaultValue={
                  // eslint-disable-next-line no-nested-ternary
                  contentSetting
                    ? contentSetting?.liveDVRProfile.immediation
                    : historySetting
                    ? historySetting?.liveDVRProfile.immediation
                    : true
                }
                disabled={
                  Boolean(historySetting || !LiveDVRProfileFlg) || disableFlg
                }
                list={[
                  {
                    label: (
                      <>
                        ライブ配信終了後、DVRを無効化する
                        <p className={classes.discription}>
                          <InfoOutlined
                            fontSize="small"
                            className={classes.icon}
                          />
                          ライブ配信終了後、数分以内に無効化されます。
                        </p>
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        ライブ配信終了後、
                        <FormTextBox
                          name="BaseSettingLiveDVRProfileLifeTime"
                          width={90}
                          register={register}
                          className={classes.inputNumber}
                          validation={immediationValidation}
                          numberOnly
                          disabled={
                            watch('BaseSettingLiveDVRProfileImmediation') ===
                              'true' ||
                            Boolean(historySetting) ||
                            !LiveDVRProfileFlg ||
                            disableFlg
                          }
                          validationError={Boolean(
                            errors?.BaseSettingLiveDVRProfileLifeTime
                          )}
                          defaultValue={
                            contentSetting?.liveDVRProfile.lifeTime ||
                            historySetting?.liveDVRProfile.lifeTime
                          }
                        />
                        分後にDVRを無効化する
                        {errors?.BaseSettingLiveDVRProfileLifeTime?.message && (
                          <p
                            style={{
                              color: '#C32733',
                              marginTop: '-4px',
                              marginLeft: 146,
                            }}
                          >
                            {errors?.BaseSettingLiveDVRProfileLifeTime.message}
                          </p>
                        )}
                        <p className={classes.discription}>
                          <InfoOutlined
                            fontSize="small"
                            className={classes.icon}
                          />
                          無効化は3分後から9時間（540分）まで設定ができます。
                        </p>
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          ライブの自動アーカイブ（サーバー側での録画）
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormCheckBox
              name="BaseSettingVodFlgUse"
              control={control}
              label="利用する"
              disabled={Boolean(historySetting) || disableFlg}
              defaultChecked={
                contentSetting || historySetting
                  ? !(
                      contentSetting?.vodFlg === VOD_FLG.DISABLED ||
                      historySetting?.vodFlg === VOD_FLG.DISABLED
                    )
                  : true
              }
            />
            {originalQuality || watch('BaseSettingLiveABRProfiles') === 0 ? (
              <p className={classes.discription} style={{ marginLeft: 30 }}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                録画はオリジナル画質のみとなります。
              </p>
            ) : (
              <p className={classes.discription} style={{ marginLeft: 30 }}>
                <InfoOutlined fontSize="small" className={classes.icon} />
                録画は最上位画質のみとなります。
              </p>
            )}
            <Box className={classes.title}>利用するアーカイブの種類</Box>
            <Box
              className={`${classes.liveDVRProfile} ${
                contentSetting?.vodFlg === 0 || historySetting?.vodFlg === 0
                  ? 'disabled'
                  : ''
              } ${
                errors?.BaseSettingLiveDVRProfileLifeTime?.message && 'error'
              }`}
            >
              <FormRadioButton
                name="BaseSettingVodFlgType"
                control={control}
                defaultValue={
                  // eslint-disable-next-line no-nested-ternary
                  contentSetting && contentSetting?.vodFlg !== VOD_FLG.DISABLED
                    ? contentSetting?.vodFlg
                    : historySetting &&
                      historySetting?.vodFlg !== VOD_FLG.DISABLED
                    ? historySetting?.vodFlg
                    : VOD_FLG.MAIN_OR_BACK
                }
                disabled={!VodFlg || Boolean(historySetting) || disableFlg}
                list={[
                  {
                    label: (
                      <>
                        ファイルサイズの大きい方を利用する
                        <p className={classes.discription}>
                          <InfoOutlined
                            fontSize="small"
                            className={classes.icon}
                          />
                          メイン系とバックアップ系のうち、ファイルサイズの大きい方がアーカイブとして登録されます。
                        </p>
                      </>
                    ),
                    value: VOD_FLG.MAIN_OR_BACK,
                  },
                  {
                    label: (
                      <>
                        メイン＋バックアップの両方を利用する
                        <p className={classes.discription}>
                          <InfoOutlined
                            fontSize="small"
                            className={classes.icon}
                          />
                          メイン系とバックアップ系の両方がアーカイブとして登録されます。
                        </p>
                      </>
                    ),
                    value: VOD_FLG.MAIN_AND_BACK,
                  },
                ]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {VodFlg && (
        <>
          <Grid container spacing={8}>
            <Grid item xs={3}>
              録画ファイル登録時に使うトランスコード設定
            </Grid>
            <Grid item xs={9}>
              <FormSelectBox
                name="BaseSettingMpid"
                control={control}
                width={300}
                disabled={Boolean(historySetting) || disableFlg}
                list={transcodeProfiles.map((x) => {
                  return { label: x.profileName, value: x.mpid };
                })}
                defaultValue={
                  (contentSetting?.mpid ||
                    historySetting?.mpid ||
                    registerSetting?.transcodeProfiles.find(
                      (profile) => profile.defaultFlg === '1'
                    )?.mpid) ??
                  0
                }
                validation={{
                  required: VodFlg ? REQUIRED_MSG : false,
                  min: {
                    value: 1,
                    message: REQUIRED_MSG,
                  },
                }}
                validationError={Boolean(errors?.BaseSettingMpid)}
                validationErrorMsg={
                  errors?.BaseSettingMpid?.message
                    ? String(errors?.BaseSettingMpid?.message)
                    : ''
                }
              />
              {!historySetting && (
                <p style={{ marginTop: 10 }}>
                  <a
                    href={SETPAGE_URL_TRANSCODE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    トランスコード設定画面
                  </a>
                  で予め設定が必要です。
                </p>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={3}>
              グループ設定（録画ファイルアップロード先）
            </Grid>
            <Grid item xs={9}>
              <FormSelectBox
                name="BaseSettingGroupId"
                control={control}
                width={300}
                disabled={Boolean(historySetting) || disableFlg}
                list={groupProfiles.map((x) => {
                  return {
                    label: x.groupName,
                    value: x.grid,
                  };
                })}
                defaultValue={
                  contentSetting?.groupId || historySetting?.groupId || 0
                }
              />
              {!historySetting && (
                <p style={{ marginTop: 10 }}>
                  <a
                    href={SETPAGE_URL_VODGROUP}
                    target="_blank"
                    rel="noreferrer"
                  >
                    管理グループ設定画面
                  </a>
                  で予め設定が必要です。
                </p>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default DefaultInfo;
