import crypto from 'crypto';
import { ViewLimitsProfile } from '../types/API';
import { ParentalControl } from '../types/ParentalControl';

/** アルゴリズム */
const ALGORITHM = 'aes-256-ecb';

/** キー */
const KEY = String(process.env.REACT_APP_SECURITY_CONTENT_KEY);

/** セキュリティプロファイル復号化 */
const DecryptSecurityContent = (
  viewLimitsProfile: ViewLimitsProfile | undefined
): ParentalControl => {
  if (!viewLimitsProfile?.details) return {} as ParentalControl;
  // base64デコードしてバイナリデータ取得
  const buff = Buffer.from(viewLimitsProfile.details, 'base64');
  // 復号化
  const decipher = crypto.createDecipheriv(ALGORITHM, KEY, '');
  decipher.setAutoPadding(false);
  const decryptedData = Buffer.concat([
    decipher.update(buff),
    decipher.final(),
  ]).toString('utf8');
  // JSON変換
  const data = JSON.parse(decryptedData);
  // 返却
  return {
    vlpid: Number(viewLimitsProfile?.vlpid),
    profileName: String(viewLimitsProfile?.profileName),
    profileDetail: String(viewLimitsProfile?.profileDetail),
    authMode: data?.auth_mode,
    domainlimitType: data?.domainlimit_type,
    domainlimitList: data?.domainlimit_list,
    iplimitType: data?.iplimit_type,
    iplimitList: data?.iplimit_list,
    idlimitType: data?.idlimit_type,
    idlimitList: data?.idlimit_list,
    otlimitType: data?.otlimit_type,
    ogpFlg: data?.ogp_flg,
    noindexFlg: data?.noindex_flg,
  };
};

export default DecryptSecurityContent;

// TODO: 暗号化のMockデータは一旦固定値を使用
/** セキュリティプロファイル暗号化（Mockデータ作成用） */
/*
export const encryptSecurityContent = (data: {}): string => {
  // 暗号化
  const cipher = crypto.createCipheriv(ALGORITHM, KEY, '');
  const encryptedData = Buffer.concat([
    cipher.update(JSON.stringify(data)),
    cipher.final(),
  ]);
  // base64エンコードして返却
  return Buffer.from(encryptedData).toString('base64');
};
*/
