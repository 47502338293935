import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  WlivesABRSever,
  HlsAuthSever,
  NowInfo,
  LiveConnections,
  LiveLists,
} from '../types/Live';
import {
  DeleteLiveProfile,
  SetPamalink,
  MakeShortUrl,
  GetLiveEvent,
  LiveEventProfile,
  GetWowzaFeed,
  GetEncoderSetting,
  GetStreamQuality,
} from '../types/API';
import { PamaLink } from '../types/PamaLink';
import {
  EVENT_STATUS,
  JCLOUD_SERVER_STATUS,
  LIVE_STATUS_ENG,
} from '../utils/Const';

/** entity用のアダプターを生成 */
// NOTE: デフォルトの並び順をライブIDの降順に変更し、lpidを主キーとしている
const eqliveAdapter = createEntityAdapter<LiveLists>({
  selectId: (eqlive) => eqlive.lpid,
  sortComparer: (a, b) => (a.lpid < b.lpid ? 1 : -1),
});

const wlivesAbrAdapter = createEntityAdapter<WlivesABRSever>({
  selectId: (wliveabr) => wliveabr.lpid,
  sortComparer: (a, b) => (a.lpid < b.lpid ? 1 : -1),
});

const hlsauthAdapter = createEntityAdapter<HlsAuthSever>({
  selectId: (hlsauth) => hlsauth.lpid,
  sortComparer: (a, b) => (a.lpid < b.lpid ? 1 : -1),
});

const nowinfoAdapter = createEntityAdapter<NowInfo>({
  selectId: (nowinfo) => nowinfo.lpid,
  sortComparer: (a, b) => (a.lpid < b.lpid ? 1 : -1),
});

const connectionAdapter = createEntityAdapter<LiveConnections>({
  selectId: (connection) => connection.lpid,
  sortComparer: (a, b) => (a.lpid < b.lpid ? 1 : -1),
});

type LivesEntityState = {
  /** ライブプロファイル数 */
  liveSum: number;

  /** 削除に失敗したライブID */
  faildDeleateLiveId: number[];

  /** ライブプロファイルのエンティティ */
  profiles: {
    EQProfile: EntityState<LiveLists>;
    wlivesAbrProfile: EntityState<WlivesABRSever>;
    hlsAuthProfile: EntityState<HlsAuthSever>;
    liveNowInfo: EntityState<NowInfo>;
    liveConnection: EntityState<LiveConnections>;
    liveEvent: LiveEventProfile[];
  };

  /** プレイヤー / チャットパーマリンク */
  pamalink: PamaLink;

  /** 短縮URL */
  watchUrl: {
    /** 短縮動画URL */
    shortVideo: string;

    /** QRコードURL */
    qr: string;

    /** 短縮QRコードURL */
    shortQr: string;
  };

  /** Wowzaフィード */
  wowzaFeed: GetWowzaFeed;

  /** エンコーダー設定 */
  encoderSetting: GetEncoderSetting;

  /** ライブ配信画質帯情報 */
  streamQuality: GetStreamQuality;
};

const initialEntityState: LivesEntityState = {
  liveSum: 0,
  faildDeleateLiveId: [],
  profiles: {
    EQProfile: eqliveAdapter.getInitialState(),
    wlivesAbrProfile: wlivesAbrAdapter.getInitialState(),
    hlsAuthProfile: hlsauthAdapter.getInitialState(),
    liveNowInfo: nowinfoAdapter.getInitialState(),
    liveConnection: connectionAdapter.getInitialState(),
    liveEvent: [],
  },
  pamalink: {
    errorCode: 0,
    message: '',
    pubsitesList: [],
    playersList: [],
    selectedPlayer: {
      pid: 0,
      ppsid: 0,
      width: 0,
      height: 0,
      responsive: 1,
      script: {
        tag: '',
        preview: '',
        largerTag: null,
      },
      iframe: {
        tag: '',
        preview: '',
        largerTag: '',
      },
      permalink: {
        tag: '',
        preview: '',
        largerTag: '',
      },
      chatOnly: {
        tag: '',
        preview: '',
        largerTag: null,
      },
      chatWidth: 0,
      chatHeight: 0,
      chatAutoResize: 0,
      refererPolicy: 0,
    },
  },
  watchUrl: {
    shortVideo: '',
    qr: '',
    shortQr: '',
  },
  wowzaFeed: {
    wlivesId: '',
    mainFeed: false,
    backFeed: false,
    wlivesStatus: JCLOUD_SERVER_STATUS.DEACTIVATEED,
  },
  encoderSetting: {} as GetEncoderSetting,
  streamQuality: {} as GetStreamQuality,
};

const livesSlice = createSlice({
  name: 'lives',
  initialState: initialEntityState,
  reducers: {
    /** EQのライブプロファイル件数を更新 */
    setLiveSum(state, action: PayloadAction<number>) {
      state.liveSum = action.payload;
    },
    /** EQのライブプロファイルをAPIから取得し全件追加 */
    setEQLiveProfile(
      state,
      action: PayloadAction<{ livesum: number; profile: LiveLists[] }>
    ) {
      const { livesum, profile } = action.payload;
      state.liveSum = livesum;
      const settedProfile = profile.map((prof) => {
        let liveStatus = prof.status;
        if (prof.status !== LIVE_STATUS_ENG.OPEN && prof.dvrCloseDate) {
          liveStatus = LIVE_STATUS_ENG.OPEN;
        }
        return {
          ...prof,
          status: liveStatus,
        };
      });
      eqliveAdapter.setAll(state.profiles.EQProfile, settedProfile);
    },
    /** EQライブプロファイルをAPIから取得し1つ更新 */
    addEQLiveProfile(state, action: PayloadAction<LiveLists>) {
      const profile = action.payload;
      let liveStatus = profile.status;
      if (profile.status !== LIVE_STATUS_ENG.OPEN && profile.dvrCloseDate) {
        liveStatus = LIVE_STATUS_ENG.OPEN;
      }
      eqliveAdapter.upsertOne(state.profiles.EQProfile, {
        ...profile,
        status: liveStatus,
      });
    },
    /** 複数件のEQライブプロファイルを削除 */
    removeEQliveProfile(state, action: PayloadAction<number[]>) {
      eqliveAdapter.removeMany(state.profiles.EQProfile, action.payload);
    },
    /** 削除に失敗したライブIDを更新 */
    setFaildDeleateLiveId(state, action: PayloadAction<DeleteLiveProfile>) {
      const { failed } = action.payload;
      state.faildDeleateLiveId = failed;
    },
    /** wlives, ABRのプロファイルを１つ追加 */
    addWlivesABRProfile(state, action: PayloadAction<WlivesABRSever>) {
      wlivesAbrAdapter.upsertOne(
        state.profiles.wlivesAbrProfile,
        action.payload
      );
    },
    /** hlsauthのプロファイルを１つ追加 */
    addHlsAuthProfile(state, action: PayloadAction<HlsAuthSever>) {
      hlsauthAdapter.upsertOne(state.profiles.hlsAuthProfile, action.payload);
    },
    /** ライブ中情報を１つ追加 */
    addNowInfo(state, action: PayloadAction<NowInfo>) {
      nowinfoAdapter.upsertOne(state.profiles.liveNowInfo, action.payload);
    },
    /** 同時接続数情報を１つ追加 */
    addConnections(state, action: PayloadAction<LiveConnections>) {
      connectionAdapter.upsertOne(
        state.profiles.liveConnection,
        action.payload
      );
    },
    /** プレイヤー / チャットパーマリンクを更新 */
    setPamalink(state, action: PayloadAction<SetPamalink>) {
      state.pamalink = action.payload;
      if (action.payload.pubsitesList.length === 0) {
        state.pamalink.pubsitesList = [
          {
            ppsid: 0,
            name: '',
          },
        ];
      }
    },
    /** 短縮動画URLを更新 */
    setShortVideoUrl(state, action: PayloadAction<MakeShortUrl>) {
      const { shortUrl } = action.payload;
      state.watchUrl.shortVideo = shortUrl;
    },
    /** QRURLを更新 */
    setQRUrl(state, action: PayloadAction<string>) {
      state.watchUrl.qr = action.payload;
    },
    /** 短縮QRURLを更新 */
    setShortQRUrl(state, action: PayloadAction<MakeShortUrl>) {
      const { shortUrl } = action.payload;
      state.watchUrl.shortQr = shortUrl;
    },
    /** ライブイベント情報を更新 */
    setLiveEventProfile(state, action: PayloadAction<GetLiveEvent>) {
      const { events } = action.payload;
      const settedEvent = events.map((event) => {
        let status = event.eventStatus;
        if (
          event.eventStatus !== EVENT_STATUS.RECORDING &&
          event.dvrCloseDate
        ) {
          status = EVENT_STATUS.RECORDING;
        }
        return {
          ...event,
          eventStatus: status,
        };
      });
      state.profiles.liveEvent = settedEvent;
    },
    /** Wowzaフィードを更新 */
    setWowzaFeed(state, action: PayloadAction<GetWowzaFeed>) {
      state.wowzaFeed = action.payload;
    },
    /** エンコーダー設定を更新 */
    setEncoderSetting(state, action: PayloadAction<GetEncoderSetting>) {
      state.encoderSetting = action.payload;
    },
    /** ライブ配信画質帯情報を更新 */
    setStreamQuality(state, action: PayloadAction<GetStreamQuality>) {
      state.streamQuality = action.payload;
    },
  },
});

export const {
  setLiveSum,
  setEQLiveProfile,
  addEQLiveProfile,
  removeEQliveProfile,
  setFaildDeleateLiveId,
  addWlivesABRProfile,
  addHlsAuthProfile,
  addNowInfo,
  addConnections,
  setPamalink,
  setShortVideoUrl,
  setQRUrl,
  setShortQRUrl,
  setLiveEventProfile,
  setWowzaFeed,
  setEncoderSetting,
  setStreamQuality,
} = livesSlice.actions;

export default livesSlice.reducer;

/** EQライブプロファイル取得のselector */
export const { selectAll: selectAllEQLives, selectById: selectEQLivesById } =
  eqliveAdapter.getSelectors<RootState>(
    (state) => state.lives.profiles.EQProfile
  );

/** wlives,ABRプロファイル取得のselector */
export const {
  selectAll: selectAllWlivesABR,
  selectById: selectWlivesABRById,
} = wlivesAbrAdapter.getSelectors<RootState>(
  (state) => state.lives.profiles.wlivesAbrProfile
);
/** hls-authプロファイル取得のselector */
export const selectHlsAuth = hlsauthAdapter.getSelectors<RootState>(
  (state) => state.lives.profiles.hlsAuthProfile
);
/** ライブ中情報取得のselector */
export const selectNowInfo = nowinfoAdapter.getSelectors<RootState>(
  (state) => state.lives.profiles.liveNowInfo
);
/** 同時接続数情報取得のselector */
export const selectConnection = connectionAdapter.getSelectors<RootState>(
  (state) => state.lives.profiles.liveConnection
);
