import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { AppDispatch } from '../../app/store';
import LiveControllerComponent from '../../components/LiveController/LiveControllerComponent';
import {
  selectEQLivesById,
  selectWlivesABRById,
} from '../../features/livesSlice';
import { setError } from '../../features/uiSlice';
import getCount from '../../utils/ThunkMethod/GetCount';
import {
  LIVE_STATUS_ENG,
  CHAT_STATUS,
  GraphIntervals,
  FEED_MAINBACK,
  CONTROLL_PLAYERSIZE,
  EDITIONS,
  CHAT_START_TIMING,
  CHAT_END_TIMING,
  SNACKBAR_TYPE,
  CHAT_CONSOLE_COMMOM,
  PORTAL_STATUS,
  STREAMING_GETCOUNT_DELAY,
  FormatMonitorURL,
  EVENT_STATUS,
  STREAM_TYPE,
  VOD_FLG,
} from '../../utils/Const';
import { ToLiveStatusJap } from '../../utils/ChangeStatus';
import {
  QUERY_ERR_MSG,
  LIVE_START_ERR_MSG,
  LIVE_STOP_ERR_MSG,
} from '../../utils/Message';
import { LiveEventProfile, GetLiveConnections } from '../../types/API';
import CheckLiveCount from './CheckLiveCount';
import getLiveControllerFlow from './GetLiveController';
import LiveStart from './LiveStart';
import LiveStop from './LiveStop';
import ChatManualStart from './ChatManualStart';
import ChatManualStop from './ChatManualStop';
import UpdateLiveEventName from './UpdateLiveEventName';
import ArchiveSplit from './ArchiveSplit';
import CheckWowzaFeed from './CheckWowzaFeed';
import { LoadJsPlayer, JsPlayerPropsType } from '../../utils/JsPlayer';
import { SnackBarProp, FeedStateType } from '../../types/Utils';
import formatDate from '../../utils/FormatDate';

// インターバル変換
const getInterval = (interval: GraphIntervals) => {
  switch (interval) {
    case GraphIntervals.THIRTY_SECOND:
      return 30;
    case GraphIntervals.MINUTES:
      return 60;
    case GraphIntervals.TWO_MINUTES:
      return 120;
    case GraphIntervals.THREE_MINUTES:
      return 180;
    case GraphIntervals.FIVE_MINUTES:
      return 300;
    case GraphIntervals.TEN_MINUTES:
      return 600;
    default:
      return 30;
  }
};

const liveTimeFormat = (num: number) => {
  // 秒を00:00:00 の形式で返す
  return `${Math.floor(num / 3600)
    .toString()
    .padStart(2, '00')}:${(Math.floor(num / 60) % 60)
    .toString()
    .padStart(2, '00')}:${(num % 60).toString().padStart(2, '00')}`;
};

/** コントロールパネル */
const LiveControllerContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // クエリチェック
  const query = new URLSearchParams(useLocation().search);
  const lpid = query.get('lpid') || '';
  if (lpid === '') {
    dispatch(
      setError({
        msg: QUERY_ERR_MSG,
        detail: '',
      })
    );
  }

  // Selector
  const user = useAppSelector((state) => state.user);
  const live = useAppSelector((state) => state.lives);
  const status = useAppSelector((state) => state.ui.status);
  const histories = useAppSelector((state) => state.histories);
  const EQlive = useAppSelector((state) =>
    selectEQLivesById(state, Number(lpid))
  );
  const wlive = useAppSelector((state) =>
    selectWlivesABRById(state, Number(lpid))
  );
  const eventProfile = useAppSelector(
    (state) => state.lives.profiles.liveEvent
  );

  // State
  const [portalStatus, setPortalStatus] = useState<boolean>(false);
  const [feed, setFeed] = useState<FEED_MAINBACK>(FEED_MAINBACK.MAIN);
  const [preFeed, setPreFeed] = useState<FeedStateType>({
    mainFeed: false,
    backFeed: false,
  });
  const [event, setEvent] = useState<LiveEventProfile>({} as LiveEventProfile);
  const [interval, setInterval] = useState<GraphIntervals>(
    GraphIntervals.THIRTY_SECOND
  );
  const [liveClientsInfo, setLiveClientsInfo] = useState<GetLiveConnections>({
    data: [],
    max: null,
    current: null,
    total: null,
  } as GetLiveConnections);
  const [eventName, setEventName] = useState<string>('');
  const [simultaneousLiveCountDialog, setSimultaneousLiveCountDialog] =
    useState<boolean>(false);
  const [liveStartDialog, setLiveStartDialog] = useState<boolean>(false);
  const [liveFinishDialog, setLiveFinishDialog] = useState<boolean>(false);
  const [jcloudDeactivatedDialog, setJcloudDeactivatedDialog] =
    useState<boolean>(false);
  const [liveStartNoneFeedDialog, setLiveStartNoneFeedDialog] =
    useState<boolean>(false);
  const [liveConnectionsCountErrorDialog, setLiveConnectionsCountErrorDialog] =
    useState<boolean>(false);
  const [chatDialog, setChatDialog] = useState<boolean>(false);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProp[]>([]);
  const [chatDisabled, setChatDisabled] = useState<boolean>(false);
  const [liveStartBtnDisabled, setLiveStartBtnDisabled] =
    useState<boolean>(false);
  const [liveErrorMessage, setLiveErrorMessage] = useState<string>('');
  const [dvrCloseDialog, setDvrCloseDialog] = useState<boolean>(false);
  const [noFeedNotification, setNoFeedNotification] = useState<FeedStateType>({
    mainFeed: false,
    backFeed: false,
  });
  const [feedinCnt, setFeedinCnt] = useState<FeedStateType>({
    mainFeed: false,
    backFeed: false,
  });
  const [
    simultaneousLiveCountErrorDialog,
    setSimultaneousLiveCountErrorDialog,
  ] = useState<boolean>(false);

  // 変数抽出
  const { cid, readAPIToken, edition, serviceFlg25, liveInfo, directory } =
    user.contract;
  const { maxClient, nowClientCnt } = liveInfo;

  // プレイヤー
  const playerRef = useRef<HTMLIFrameElement>(null);
  const playerProps: JsPlayerPropsType = {
    playerRef,
    player: {
      target: 'preview',
      src: '',
      width: CONTROLL_PLAYERSIZE.WIDTH,
      height: CONTROLL_PLAYERSIZE.HEIGHT,
      // NOTE: vodでテスト
      mode: 'live',
      muted: 0,
      poster: '',
    },
  };

  // ページ生成時のライブ同時開催数チェック後の後続処理
  const afterCheckedLiveCountForInit = () => {
    if (lpid === '' || !cid) return;
    dispatch(
      getLiveControllerFlow({
        lpid: Number(lpid),
        cid,
        userApiParams: user.user,
        autoMode: Boolean(serviceFlg25),
        setNoFeedNotification,
        setFeedinCnt,
      })
    );
  };

  // ライブ配信開始時のライブ同時開催数チェック後の後続処理
  const afterCheckedLiveCountForStartStream = () => {
    if (!EQlive) return;
    if (
      edition !== EDITIONS.U_LIVE &&
      maxClient < nowClientCnt + EQlive.maxClient
    ) {
      setLiveConnectionsCountErrorDialog(true);
      return;
    }
    setLiveStartDialog(true);
  };

  // ライブ数が超えていないかチェックし、ライブ同時開催数についての警告ダイアログ表示判定を行う
  useEffect(() => {
    dispatch(
      CheckLiveCount({
        cid,
        userApiParams: user.user,
        setSimultaneousLiveCountDialog,
        afterCheckedLiveCount: afterCheckedLiveCountForInit,
      })
    );
  }, []);

  // 初期処理でのライブプロファイル取得後の反映
  useEffect(() => {
    if (EQlive) {
      // ポータル公開ステータスを更新
      setPortalStatus(EQlive.portalStatus === PORTAL_STATUS.PUBLIC);
    }
  }, [EQlive?.portalStatus]);

  // 初期処理でのJCloudサーバー情報取得後の反映
  useEffect(() => {
    if (!wlive) return () => null;
    // 1分間隔でフィードの監視を設定
    const interval = window.setInterval(() => {
      dispatch(
        CheckWowzaFeed({
          wlivesId: wlive.wlives.id,
          autoMode: Boolean(serviceFlg25),
          lid: {
            cid,
            userApiParams: user.user,
            lpid: Number(lpid),
            customerDir: directory,
          },
          jcloudDeactivatedDialog,
          setJcloudDeactivatedDialog,
          liveStatus: EQlive?.status,
          setNoFeedNotification,
          feedinCnt,
          setFeedinCnt,
          event,
        })
      );
    }, 60000);
    return () => clearInterval(interval);
  }, [wlive, EQlive?.status, feedinCnt]);

  const liveEventTimerRef = useRef<number>(0);
  const timerRef = useRef<number>(0);
  const [eventTime, updateEventTime] = useState('00:00:00');

  // ライブ経過時間の更新タイマー設定処理
  const setLiveTimer = (currentEvent: LiveEventProfile) => {
    // 1秒間隔で現在時間と配信開始時間の差を計算しstate更新
    liveEventTimerRef.current = window.setInterval(() => {
      updateEventTime(
        liveTimeFormat(
          moment().diff(
            currentEvent.mainOnairTime || currentEvent.subOnairTime,
            'seconds'
          )
        )
      );
    }, 1000);
  };
  // ライブ経過時間の更新タイマークリア処理
  const clearLiveTimer = () => {
    if (!liveEventTimerRef.current) return;
    clearInterval(liveEventTimerRef.current);
    liveEventTimerRef.current = 0;
    updateEventTime('00:00:00');
  };

  // 視聴者数遷移の更新タイマー設定処理
  const setCountTimer = (
    currentEvent: LiveEventProfile,
    timeInterval: number
  ) => {
    const time = currentEvent.mainOnairTime ?? currentEvent.subOnairTime;
    if (!time) return;
    const date = formatDate(time);
    const counter = () => {
      const fromTime = Number(moment(date).format('YYYYMMDDHHmmss'));
      // NOTE: 現時刻の60秒前をtoにして同時接続数を取得することで1分半以上前の時刻を最新としてグラフに描画
      const toTime = Number(
        moment()
          .add(-STREAMING_GETCOUNT_DELAY, 'seconds')
          .format('YYYYMMDDHHmmss')
      );
      // ライブ開始から1分半以上経過後しないと同時接続数は集計できない
      if (toTime - fromTime > STREAMING_GETCOUNT_DELAY) {
        dispatch(
          getCount({
            cid: Number(cid),
            lpid: Number(lpid),
            timeInterval,
            leid: currentEvent.leid,
            from: fromTime,
            to: toTime,
          })
        );
      }
    };
    // 本番配信中のグラフ初回表示
    counter();
    // その後30秒ごとに処理
    timerRef.current = window.setInterval(() => {
      counter();
    }, 30000);
  };

  // 視聴者数遷移の更新タイマークリア処理
  const clearCountTimer = () => {
    if (!timerRef.current) return;
    clearInterval(timerRef.current);
    timerRef.current = 0;
  };

  // 初期処理 または ライブ配信開始・停止、チャット停止処理でのライブイベント取得後の反映
  useEffect(() => {
    // イベントがない（そのライブIDで初めてライブする）場合
    if (!eventProfile || eventProfile.length <= 0) {
      // チャットの利用がない場合はボタン非活性
      setChatDisabled(!EQlive?.chat.enabled);
      return;
    }

    // チャットの利用がない、または、ライブ未実施、ライブ中でかつチャット実施済のレコードがあった場合、ライブが終わるまでチャット開始を抑止するためのフラグを設定
    setChatDisabled(
      Boolean(!EQlive?.chat.enabled) ||
        eventProfile.some(
          (x) =>
            (x.eventStatus === EVENT_STATUS.NON_RECORDING ||
              x.eventStatus == null ||
              x.eventStatus === EVENT_STATUS.RECORDING) &&
            x.chatRoomStatus === EVENT_STATUS.RECORDED
        )
    );

    // ライブ実施済、かつ、チャット中のレコードがあった場合、チャットが終わるまでライブ開始を抑止するためのフラグを設定
    setLiveStartBtnDisabled(
      eventProfile.some(
        (x) =>
          x.eventStatus >= EVENT_STATUS.RECORDED &&
          x.chatRoomStatus === EVENT_STATUS.RECORDING
      )
    );
    // ライブ中のライブイベントを抽出
    const currentEvent = eventProfile.find(
      (x) => x.eventStatus === EVENT_STATUS.RECORDING
    );
    // 配信中のライブイベントがない場合
    if (!currentEvent) {
      // チャット稼働中のライブイベントを抽出
      const currentChat = eventProfile.find(
        (x) => x.chatRoomStatus === EVENT_STATUS.RECORDING
      );
      // チャットのみ稼働している場合
      // 配信中のイベント名を設定
      setEventName(currentChat ? currentChat.eventName : '');
      // チャットがない場合はイベント情報をクリア
      if (!currentChat) setEvent({} as LiveEventProfile);
      // ライブ経過時間のタイマークリア
      clearLiveTimer();
      // 視聴者数遷移の更新タイマークリア
      clearCountTimer();
      return;
    }
    // 配信中のライブイベントを設定
    setEvent(currentEvent);
    // 配信中のイベント名を設定
    setEventName(currentEvent.eventName);
    // 視聴者数遷移の更新タイマー起動
    if (!timerRef.current) setCountTimer(currentEvent, getInterval(interval));
    // ライブ経過時間の更新タイマー起動
    if (!liveEventTimerRef.current && !EQlive?.dvrCloseDate)
      setLiveTimer(currentEvent);

    // 配信中のライブイベントのフィードイン情報を設定
    setFeedinCnt({
      mainFeed: Boolean(currentEvent.mainFeedinCnt),
      backFeed: Boolean(currentEvent.subFeedinCnt),
    });
  }, [eventProfile]);

  useEffect(() => {
    if (EQlive?.status === LIVE_STATUS_ENG.STOP) {
      // ライブ経過時間のタイマークリア
      clearLiveTimer();
      // 視聴者数遷移の更新タイマークリア
      clearCountTimer();
      // フィード不安定の通知をクリア
      setNoFeedNotification({
        mainFeed: false,
        backFeed: false,
      });
      // フィードイン情報をクリア
      setFeedinCnt({
        mainFeed: false,
        backFeed: false,
      });
    } else if (
      EQlive?.status === LIVE_STATUS_ENG.OPEN &&
      EQlive?.dvrCloseDate
    ) {
      // ライブ経過時間のタイマークリア
      clearLiveTimer();
    }
  }, [EQlive?.status, EQlive?.dvrCloseDate]);

  // プレイヤー制御（プルダウン切り替え）
  useEffect(() => {
    if (!playerRef.current?.contentDocument?.documentElement) {
      if (live.wowzaFeed.mainFeed && feed === FEED_MAINBACK.MAIN) {
        // メインフィードに切り替え
        playerProps.player.src = FormatMonitorURL(
          wlive?.wlives.streams[0].monitorUrl.main ?? '',
          directory
        );
        // プレイヤー作成
        LoadJsPlayer(playerProps);
      } else if (live.wowzaFeed.backFeed && feed === FEED_MAINBACK.BACKUP) {
        // バックアップフィードに切り替え
        playerProps.player.src = FormatMonitorURL(
          wlive?.wlives.streams[0].monitorUrl.backup ?? '',
          directory
        );
        // プレイヤー作成
        LoadJsPlayer(playerProps);
      }
    }
    return () => {
      if (playerRef.current?.contentDocument?.documentElement) {
        // プレイヤー削除
        playerRef.current?.contentDocument?.removeChild(
          playerRef.current?.contentDocument?.documentElement
        );
        // プレイヤー再読み込み
        playerRef.current?.contentWindow?.location.reload();
      }
    };
  }, [feed]);

  // プレイヤー制御（フィード）
  useEffect(() => {
    // 1つ前のフィード確認でメインフィードがなく、今のフィード確認でメインフィードがあり、プルダウンもメインフィードを選択
    if (
      !preFeed.mainFeed &&
      live.wowzaFeed.mainFeed &&
      feed === FEED_MAINBACK.MAIN
    ) {
      // メインフィードに切り替え
      playerProps.player.src = FormatMonitorURL(
        wlive?.wlives.streams[0].monitorUrl.main ?? '',
        directory
      );
      // プレイヤー作成
      LoadJsPlayer(playerProps);

      // 1つ前のフィード確認でバックフィードがなく、今のフィード確認でバックフィードがあり、プルダウンもバックフィードを選択
    } else if (
      !preFeed.backFeed &&
      live.wowzaFeed.backFeed &&
      feed === FEED_MAINBACK.BACKUP
    ) {
      // バックアップフィードに切り替え
      playerProps.player.src = FormatMonitorURL(
        wlive?.wlives.streams[0].monitorUrl.backup ?? '',
        directory
      );
      // プレイヤー作成
      LoadJsPlayer(playerProps);

      // 今のフィード確認でメインフィードがなく、プルダウンもバックフィードを選択
    } else if (!live.wowzaFeed.mainFeed && feed === FEED_MAINBACK.MAIN) {
      if (playerRef.current?.contentDocument?.documentElement) {
        // プレイヤー削除
        playerRef.current?.contentDocument?.removeChild(
          playerRef.current?.contentDocument?.documentElement
        );
        // プレイヤー再読み込み
        playerRef.current?.contentWindow?.location.reload();
      }

      // 今のフィード確認でバックフィードがなく、プルダウンもバックフィードを選択
    } else if (!live.wowzaFeed.backFeed && feed === FEED_MAINBACK.BACKUP) {
      if (playerRef.current?.contentDocument?.documentElement) {
        // プレイヤー削除
        playerRef.current?.contentDocument?.removeChild(
          playerRef.current?.contentDocument?.documentElement
        );
        // プレイヤー再読み込み
        playerRef.current?.contentWindow?.location.reload();
      }
    }
    // フィードのstateを更新
    setPreFeed({
      ...preFeed,
      mainFeed: live.wowzaFeed.mainFeed,
      backFeed: live.wowzaFeed.backFeed,
    });
  }, [live.wowzaFeed]);

  // プレイヤー制御（dvr中はプレイヤー非表示）
  useEffect(() => {
    if (EQlive?.dvrCloseDate) {
      // プレイヤー削除
      playerRef.current?.contentDocument?.removeChild(
        playerRef.current?.contentDocument?.documentElement
      );
      // DVR有効期限後に表示切り替え処理
      const closeDate = formatDate(EQlive?.dvrCloseDate);
      const isStopTime = moment(closeDate).diff(moment());
      if (isStopTime) {
        setTimeout(() => {
          setDvrCloseDialog(true);
        }, isStopTime);
      }
    }
  }, [EQlive?.dvrCloseDate]);

  useEffect(() => {
    if (!histories.countLog || histories.countLog.length <= 0) return;
    setLiveClientsInfo({
      data: histories.countLog,
      ...histories.countState,
    });
  }, [histories.countLog, histories.countState]);

  // スナックバー要素削除
  const handleSnackbarClose = (index: number) => {
    const info = [...snackbarInfo];
    info.splice(index, 1);
    setSnackbarInfo(info);
  };

  // ポータル公開ダイアログクローズ処理
  const handleClosePotalDialog = () => {
    setPortalStatus(false);
  };

  // ライブ同時開催数警告ダイアログOKボタン処理
  const handleOpenSimultaneousLiveCountDialog = () => {
    setSimultaneousLiveCountDialog(false);
    // ライブ同時開催数超過について確認済みとして初期処理を実行する
    afterCheckedLiveCountForInit();
  };

  // ライブ同時開催数警告ダイアログクローズ処理
  const handleCloseSimultaneousLiveCountDialog = () => {
    setSimultaneousLiveCountDialog(false);
    window.close();
  };

  // ライブ配信開始前確認ダイアログOKボタン処理
  const handleOpenLiveStartDialog = () => {
    setLiveStartDialog(false);
    if (!EQlive || !wlive) return;
    // ライブ配信開始処理
    dispatch(
      LiveStart({
        wlivesId: wlive?.wlives.id,
        lpid: Number(lpid),
        cid,
        userApiParams: user.user,
        limit: EQlive.maxClient,
        eventName,
        customerDir: user.contract.directory,
        chat: {
          enabled: EQlive.chat.enabled,
          openTiming: EQlive.chat.openTiming,
          sendVisible: EQlive.chat.blockType,
          ngWords: EQlive.chat.blockWords,
        },
        errorCallback: () => setLiveErrorMessage(LIVE_START_ERR_MSG),
        setJcloudDeactivatedDialog,
        setNoneFeedDialog: setLiveStartNoneFeedDialog,
      })
    );
  };

  // ライブ配信開始前確認ダイアログクローズ処理
  const handleCloseLiveStartDialog = () => {
    setLiveStartDialog(false);
  };

  // チャット処理前確認ダイアログ表示
  const openChatDialog = () => {
    setChatDialog(true);
  };

  // チャット処理前確認ダイアログOKボタン処理
  const handleOpenChatDialog = () => {
    setChatDialog(false);
    if (!EQlive || !event) return;
    if (EQlive.chat.status === CHAT_STATUS.STREAMING) {
      // チャット停止処理
      dispatch(
        ChatManualStop({
          lpid: Number(lpid),
          event,
          cid,
          userApiParams: user.user,
          customerDir: user.contract.directory,
          chat: {
            closeTiming: EQlive.chat.closeTiming,
          },
        })
      );
    } else {
      // チャット開始処理
      dispatch(
        ChatManualStart({
          lpid: Number(lpid),
          cid,
          userApiParams: user.user,
          customerDir: user.contract.directory,
          chat: {
            sendVisible: EQlive.chat.blockType,
            ngWords: EQlive.chat.blockWords,
          },
        })
      );
    }
  };

  // チャット処理前確認ダイアログクローズ処理
  const handleCloseChatDialog = () => {
    setChatDialog(false);
  };

  // 同時ライブ接続数超過エラーダイアログクローズ処理
  const handleCloseLiveConnectionsCountErrorDialog = () => {
    setLiveConnectionsCountErrorDialog(false);
  };

  // ライブ配信開始時フィード未確認ダイアログクローズ処理
  const handleCloseLiveStartNoneFeedDialog = () => {
    setLiveStartNoneFeedDialog(false);
  };

  // ライブ同時開催数超過エラーダイアログクローズ処理
  const handleCloseSimultaneousLiveCountErrorDialog = () => {
    setSimultaneousLiveCountErrorDialog(false);
  };

  // ライブ配信開始前のチェック処理
  const startStream = () => {
    // ライブ同時開催数チェック
    dispatch(
      CheckLiveCount({
        cid,
        userApiParams: user.user,
        setSimultaneousLiveCountDialog: setSimultaneousLiveCountErrorDialog,
        afterCheckedLiveCount: afterCheckedLiveCountForStartStream,
      })
    );
  };

  // ライブ配信停止前のダイアログ表示
  const finishStream = () => {
    setLiveFinishDialog(true);
  };

  // ライブ配信停止前ダイアログクローズ処理
  const handleCloseLiveFinishDialog = () => {
    setLiveFinishDialog(false);
  };

  // ライブ配信停止処理
  const handleOKLiveFinishDialog = () => {
    setLiveFinishDialog(false);
    if (!EQlive || !wlive || !event) return;
    dispatch(
      LiveStop({
        wlivesId: wlive?.wlives.id,
        lpid: Number(lpid),
        cid,
        userApiParams: user.user,
        limit: EQlive.maxClient,
        dvrLifetime: wlive?.wlives.dvr.lifetime,
        vod: EQlive.vodFlg,
        automode: Boolean(serviceFlg25),
        customerDir: user.contract.directory,
        chat: {
          enabled: EQlive.chat.enabled,
          closeTiming: EQlive.chat.closeTiming,
        },
        errorCallback: () => setLiveErrorMessage(LIVE_STOP_ERR_MSG),
      })
    );
  };

  // ライブフィード受付ないステータス感知ダイアログクローズ処理
  const handleCloseJcloudDeactivatedDialog = () => {
    setJcloudDeactivatedDialog(false);
    // 画面再読み込み処理。
    window.location.reload();
  };

  // 録画ファイル分割処理
  const handleSplit = () => {
    if (!wlive) return;
    dispatch(ArchiveSplit({ wlivesId: wlive.wlives.id }));
    setSnackbarInfo([
      {
        title: '分割中です',
        type: SNACKBAR_TYPE.INFO,
        // 分割完了まで表示するため8秒追加
        time: moment().add(8, 'seconds').toString(),
      },
    ]);
    setTimeout(
      () =>
        setSnackbarInfo([
          {
            title: '分割しました',
            type: SNACKBAR_TYPE.SUCCESS,
            time: moment().toString(),
          },
        ]),
      10000
    );
  };

  // ライブ履歴名入力処理
  const inputLiveEventName = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEventName(e.target.value);
  };

  // ライブ履歴名変更処理
  const changeLiveEventName = () => {
    // 配信中のイベントがない場合、何もしない
    if (!event?.leid) return;
    dispatch(
      UpdateLiveEventName({
        cid: user.contract.cid,
        userApiParams: user.user,
        lpid: Number(lpid),
        leid: event.leid,
        eventName,
      })
    );
  };

  // 視聴者数遷移の表示間隔変更処理
  const handleInterval = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    value: GraphIntervals
  ) => {
    setInterval(value);
    // 視聴者数遷移の更新タイマー再起動
    if (timerRef.current) {
      clearCountTimer();
      setCountTimer(event, getInterval(value));
    }
  };

  // チャット管理コンソールのURL
  // ex: 'https://ssl-eq-stg.stream.co.jp/www50/chat/jmc_pub/v2/admin.html?lpid=Mw%3D%3D&userdir=eqy439mzmx'
  const chatConsoleURL = `${CHAT_CONSOLE_COMMOM}?lpid=${encodeURIComponent(
    Buffer.from(String(lpid)).toString('base64')
  )}&userdir=${user.contract.directory}`;
  // チャット管理コンソールのIframe
  const chatConsoleIframe = `<iframe src=${chatConsoleURL} width="100%" height="100%" allowFullScreen referrerPolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>`;

  return (
    <LiveControllerComponent
      handleSnackbarClose={handleSnackbarClose}
      snackbarInfo={snackbarInfo}
      uiStatus={status.liveController}
      edition={edition}
      cid={cid}
      readAPIToken={readAPIToken}
      userApiParams={user.user}
      nowClientCnt={nowClientCnt}
      maxClient={maxClient}
      maxClientLimit={Number(EQlive?.maxClient)}
      chatStatus={EQlive?.chat?.status || CHAT_STATUS.NONE}
      autoMode={Boolean(serviceFlg25)}
      streamType={
        // eslint-disable-next-line no-nested-ternary
        EQlive?.desktopEncoder
          ? STREAM_TYPE.DESKTOP
          : EQlive?.liveappFlg
          ? STREAM_TYPE.LIVECAST
          : STREAM_TYPE.ENCODER
      }
      aleartPotalHandleDialog={{
        open: portalStatus,
        handleClose: handleClosePotalDialog,
      }}
      aleartSimultaneousLiveCountDialog={{
        open: simultaneousLiveCountDialog,
        handleOK: handleOpenSimultaneousLiveCountDialog,
        handleClose: handleCloseSimultaneousLiveCountDialog,
      }}
      aleartLiveStartDialog={{
        open: liveStartDialog,
        handleOK: handleOpenLiveStartDialog,
        handleClose: handleCloseLiveStartDialog,
        withChat:
          Boolean(EQlive?.chat?.enabled) &&
          EQlive?.chat?.openTiming === CHAT_START_TIMING.LIVE_AUTO,
      }}
      aleartLiveFinishDialog={{
        open: liveFinishDialog,
        handleOK: handleOKLiveFinishDialog,
        handleClose: handleCloseLiveFinishDialog,
        withChat:
          Boolean(EQlive?.chat?.enabled) &&
          EQlive?.chat?.closeTiming === CHAT_END_TIMING.LIVE_AUTO,
      }}
      aleartJcloudDeactivatedDialog={{
        open: jcloudDeactivatedDialog,
        handleClose: handleCloseJcloudDeactivatedDialog,
      }}
      aleartLiveConnectionsCountErrorDialog={{
        open: liveConnectionsCountErrorDialog,
        handleClose: handleCloseLiveConnectionsCountErrorDialog,
      }}
      aleartLiveStartNoneFeedDialog={{
        open: liveStartNoneFeedDialog,
        handleClose: handleCloseLiveStartNoneFeedDialog,
      }}
      ChatDialog={{
        open: chatDialog,
        handleOK: handleOpenChatDialog,
        handleClose: handleCloseChatDialog,
      }}
      aleartSimultaneousLiveCountErrorDialog={{
        open: simultaneousLiveCountErrorDialog,
        handleClose: handleCloseSimultaneousLiveCountErrorDialog,
      }}
      liveErrorDialog={{
        open: liveErrorMessage.length > 0,
        handleClose: () => setLiveErrorMessage(''),
        message: liveErrorMessage,
      }}
      switchPreview={setFeed}
      inputLiveEventName={inputLiveEventName}
      changeLiveEventName={changeLiveEventName}
      startStream={() => startStream()}
      finishStream={() => finishStream()}
      openChatDialog={() => openChatDialog()}
      choiceInterval={handleInterval}
      archiveSplit={handleSplit}
      eventName={eventName}
      vodFlg={EQlive?.vodFlg || VOD_FLG.DISABLED}
      liveStatus={ToLiveStatusJap(EQlive?.status || LIVE_STATUS_ENG.NONE)}
      playerRef={playerRef}
      eventTime={eventTime}
      feed={feed}
      lpid={Number(lpid)}
      liveEventInfoName={EQlive?.liveDescription || ''}
      liveName={EQlive?.liveName || ''}
      chatStartBtnDisabled={
        EQlive?.chat?.openTiming !== CHAT_START_TIMING.MANUAL || chatDisabled
      }
      chatStopBtnDisabled={EQlive?.chat?.closeTiming !== CHAT_END_TIMING.MANUAL}
      liveStartBtnDisabled={liveStartBtnDisabled}
      liveClientsInfo={liveClientsInfo}
      graphInterval={interval}
      feedState={live.wowzaFeed}
      dvrCloseDate={formatDate(EQlive?.dvrCloseDate || '')}
      dvrCloseDialog={dvrCloseDialog}
      chatenable={!!EQlive?.chat.enabled}
      chatConsoleIframe={chatConsoleIframe}
      noFeedNotification={noFeedNotification}
    />
  );
};

export default LiveControllerContainer;
