import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { AppThunk } from '../../app/store';
import {
  LIVE_STATUS_OPEN_ERR_MSG,
  API_LIVE_ALREADY_START_ERR_MSG,
  API_LIVE_STATUS_DEACTIVATED_ERR_MSG,
} from '../../utils/Message';
import { setError, setLiveControllerStatus } from '../../features/uiSlice';
import {
  addEQLiveProfile,
  setLiveEventProfile,
  setWowzaFeed,
} from '../../features/livesSlice';
import { GetLiveEvent, GetLiveProfile, GetWowzaFeed } from '../../types/API';
import { CHAT_BLOCK_TYPE, UI_STATUS } from '../../utils/Const';

type LiveStartParams = {
  wlivesId: string;
  lpid: number;
  cid: number;
  userApiParams: string;
  limit: number;
  eventName: string;
  customerDir: string;
  chat: {
    enabled: 0 | 1;
    openTiming: number;
    sendVisible: CHAT_BLOCK_TYPE;
    ngWords: string[];
  };
  errorCallback: () => void;
  setJcloudDeactivatedDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setNoneFeedDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

/** ライブ配信開始フロー */
const LiveStart =
  (arg: LiveStartParams): AppThunk =>
  async (dispatch, getState) => {
    // 配信処理中をセット
    dispatch(setLiveControllerStatus(UI_STATUS.PROCESSING_STREAM));
    try {
      // Wowzaフィード確認API実行
      const responseWowzaFeed: AxiosResponse<GetWowzaFeed[]> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getWowzaFeed`,
        {
          wlivesIds: [arg.wlivesId],
          lids: [
            {
              cid: arg.cid,
              userApiParams: arg.userApiParams,
              lpid: arg.lpid,
              customerDir: arg.customerDir,
            },
          ],
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      // メイン、バックのどちらもフィードない場合ダイアログ表示し、抜ける
      // NOTE: jcloudのオリジンから映像が確認できなくなるまでに遅延があるため抜いてすぐに開始されるとここは抜けられてしまう
      if (
        !(
          responseWowzaFeed.data[0].mainFeed ||
          responseWowzaFeed.data[0].backFeed
        )
      ) {
        arg.setNoneFeedDialog(true);
        // ステータスも戻す
        dispatch(setWowzaFeed({ ...responseWowzaFeed.data[0] }));
        return;
      }
      // ライブ開始API実行
      await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}startLive`,
        {
          ...arg,
          // イベント名が空の場合、現在日時を設定
          eventName: arg.eventName
            ? arg.eventName
            : moment().format('YYYYMMDD HH:mm:ss'),
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
        {
          lpid: arg.lpid,
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          pageSize: 1,
          portal: getState().user.contract.serviceFlg39 ? 1 : 0,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

      // ライブイベント情報取得API実行
      const response: AxiosResponse<GetLiveEvent> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
        { cid: arg.cid, userApiParams: arg.userApiParams, lpid: arg.lpid },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setLiveEventProfile({ ...response.data }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.response?.data?.statusCode === 500 &&
        error.response?.data?.message === API_LIVE_ALREADY_START_ERR_MSG
      ) {
        // ライブ配信中だった場合はstatusを更新する
        // ライブプロファイル取得API実行
        const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
          {
            lpid: arg.lpid,
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            pageSize: 1,
            portal: getState().user.contract.serviceFlg39 ? 1 : 0,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

        // ライブイベント情報取得API実行
        const response: AxiosResponse<GetLiveEvent> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
          { cid: arg.cid, userApiParams: arg.userApiParams, lpid: arg.lpid },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(setLiveEventProfile({ ...response.data }));

        // エラーダイアログの表示
        arg.errorCallback();
      } else if (
        error.response?.data?.statusCode === 500 &&
        error.response?.data?.message === API_LIVE_STATUS_DEACTIVATED_ERR_MSG
      ) {
        // ダイアログを出す
        arg.setJcloudDeactivatedDialog(true);
      } else {
        // 異常終了
        dispatch(
          setError({
            msg: LIVE_STATUS_OPEN_ERR_MSG,
            detail: JSON.stringify(error.response?.data || error.message),
          })
        );
      }
    } finally {
      dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default LiveStart;
