import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  KeyboardArrowUp,
  Help,
  InfoOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  SpeakerNotesOff,
  Message,
  CheckCircle,
  WatchLater,
  Warning,
} from '@material-ui/icons';
import Sensors from '../../images/Sensors.svg';
import Tooltip from '../parts/Tooltip/Tooltip';
import { LiveControllerProps } from '../../props/LiveController';
import {
  CONTROLL_PLAYERSIZE,
  EDITIONS,
  FEED_MAINBACK,
  getPath,
  ROUTE,
  UI_STATUS,
  LIVE_STATUS,
  CHAT_STATUS,
  STREAM_TYPE,
} from '../../utils/Const';
import Badge from '../parts/Badge/Badge';
import ContentCut from '../../images/ContentCut.svg';

/** スタイル */
const useStyle = makeStyles({
  root: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 12px #1C385E0C',
    borderRadius: 8,
    width: 530,
    height: 'fit-content',
    marginRight: 30,
  },
  header: {
    display: 'flex',
    padding: '17px 30px',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    '& h3': {
      fontSize: 16,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
    },
  },
  body: {
    padding: '15px 20px 0',
  },
  status: {
    background: '#61B2270D',
    border: '1px solid #61B227',
    borderRadius: 4,
    fontSize: 12,
    padding: '2px 10px',
  },
  badge: {
    padding: '6px 12px',
  },
  player: {
    position: 'relative',
    background: '#FAFAFA',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    paddingBottom: 20,
    '& > iframe': {
      margin: '0 auto',
      width: CONTROLL_PLAYERSIZE.WIDTH,
      height: CONTROLL_PLAYERSIZE.HEIGHT,
      display: 'flex',
      boxSizing: 'border-box',
    },
  },
  playerHeader: {
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 10,
    padding: '8px 15px',
    fontSize: 13,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  playerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    lineHeight: '22px',
    padding: '0 15px',
    fontSize: 13,
    marginTop: 8,
  },
  linktext: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
  },
  feed: {
    opacity: 0.24,
    marginRight: 3,
    '& .MuiSvgIcon-root': {
      color: '#333333',
    },
  },
  active: {
    opacity: 1,
    '& .MuiSvgIcon-root': {
      color: '#61B227',
    },
  },
  viewBtn: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: 'translateY(50%) translateX(30%)',
    background: '#FFFFFF',
    border: '1px solid #D0D0D0',
    borderRadius: 100,
    color: 'rgba(51, 51, 51, 0.5)',
    padding: '0 10px',
    fontSize: 13,
    marginTop: 10,
  },
  splitBtn: {
    background: '#FFFFFF',
    border: '1px solid #D0D0D0',
    fontSize: 13,
    padding: '0 10px',
    marginLeft: 10,
    '& img': {
      marginRight: 6,
    },
    '& .MuiButton-label': {
      lineHeight: '13px',
    },
  },
  info: {
    margin: 0,
    padding: '10px 0 20px',
    fontSize: 13,
  },
  row: {
    display: 'flex',
    lineHeight: '28px',
  },
  title: {
    color: 'rgba(51, 51, 51, 0.5)',
    width: 145,
  },
  content: {
    width: 335,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  helpContainer: {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
    },
    '& ul': {
      'padding-left': 20,
    },
    '& li': {
      textIndent: '-0.5em',
      'line-height': '17px',
      fontSize: 12,
    },
  },
  help: {
    color: '#B6BEC9',
    marginBottom: '-4px',
  },
  inputText: {
    '& .MuiInputBase-root': {
      height: 28,
    },
  },
  attention: {
    background: '#C3273305',
    border: ' 1px dashed #C32733',
    borderRadius: 4,
    padding: '10px 16px',
    margin: '0 30px',
    color: '#C32733',
    '& h3': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      marginBottom: 6,
    },
    '& ul': {
      paddingInlineStart: 20,
      margin: 0,
    },
    '& li': {
      listStyleType: 'none',
      textIndent: '-1em',
      fontSize: 12,
    },
  },
  buttons: {
    display: 'flex',
    borderTop: '1px solid #F0F0F0',
    marginTop: 24,
    padding: 19,
    '& button': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      paddingTop: 12,
      paddingBottom: 14,
      fontSize: 14,
      fontWeight: 700,
      color: '#FFFFFF',
      '&.Mui-disabled': {
        color: '#00000038',
        background: '#D8D8D8',
        borderBottom: '1px solid #D8D8D8',
      },
    },
  },
  spaceLine: {
    border: '1px dashed #898989',
    margin: '0 12px',
    opacity: 0.3,
  },
  liveStop: {
    width: 301,
    background: '#C32733',
    '&:hover': {
      background: '#A21B25',
    },
  },
  liveStart: {
    width: 301,
    background: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    '&:hover': {
      background: '#1F68DD',
      borderBottom: '1px solid #1F68DD',
    },
  },
  chatStop: {
    width: 162,
    background: '#C32733',
    '&:hover': {
      background: '#A21B25',
    },
    '&.Mui-disabled': {
      background: '#D8D8D8',
    },
  },
  chatStart: {
    width: 162,
    background: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    '&:hover': {
      background: '#1F68DD',
      borderBottom: '1px solid #1F68DD',
    },
    '&.Mui-disabled': {
      background: '#D8D8D8',
      borderBottom: '1px solid #D8D8D8',
    },
  },
  checkNoFeed: {
    background: 'rgb(195 39 51 / 86%) 0% 0% no-repeat padding-box',
    padding: '14px 16px',
    borderRadius: 6,
    width: 332,
    height: 127,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    color: '#FFFFFF',
    boxShadow: '0px 4px 16px rgb(0 0 0 / 35%)',
    fontSize: 13,
    border: '1px solid rgb(255 255 255 / 64%)',
    boxSizing: 'border-box',
  },
  checkNoFeedHeader: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '35px 1fr',
    gridRowGap: 5,
    gridColumnGap: 14,
    borderBottom: '1px solid rgb(255 255 255 / 30%)',
    paddingBottom: 8,
  },
  checkNoFeedHeaderIcon: {
    gridRow: '1 / 3',
    gridColumn: '1 / 2',
    width: '100%',
    height: '100%',
    paddingTop: 2,
  },
  checkNoFeedHeaderTitle: {
    gridRow: '1 / 2',
    gridColumn: '2 / 3',
    margin: 0,
    fontSize: 16,
    letterSpacing: '-0.48px',
    color: '#FFFFFF',
  },
  checkNoFeedHeaderText: {
    gridRow: '2 / 3',
    gridColumn: '2 / 3',
    margin: 0,
  },
  checkNoFeedHeaderFeedType: {
    background: '#FFFFFF',
    marginRight: 8,
    display: 'inline-block',
    borderRadius: 1,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1.4,
    color: 'rgb(195 39 51 / 85%)',
    height: 16,
    width: 43,
    textAlign: 'center',
  },
  checkNoFeedBody: {
    margin: '5px 0',
    opacity: 0.95,
  },
});

/** コントロールパネル 左(UI) */
const ControllerLeft = ({
  props,
}: {
  props: LiveControllerProps;
}): JSX.Element => {
  const classes = useStyle();
  const {
    uiStatus,
    edition,
    cid,
    readAPIToken,
    userApiParams,
    chatStatus,
    autoMode,
    streamType,
    switchPreview,
    inputLiveEventName,
    changeLiveEventName,
    startStream,
    finishStream,
    openChatDialog,
    eventName,
    vodFlg,
    feed,
    lpid,
    liveEventInfoName,
    liveName,
    chatStartBtnDisabled,
    chatStopBtnDisabled,
    liveStartBtnDisabled,
    liveStatus,
    playerRef,
    eventTime,
    archiveSplit,
    feedState,
    dvrCloseDate,
    noFeedNotification,
  } = props;
  const [viewFlg, setViewFlg] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const openViewFlg = Boolean(viewFlg);
  const handleClose = (value: FEED_MAINBACK) => {
    switchPreview(value);
    setViewFlg(null);
  };
  const activating = uiStatus === UI_STATUS.ACTIVATING;
  const processingStream = uiStatus === UI_STATUS.PROCESSING_STREAM;
  const processingChat = uiStatus === UI_STATUS.PROCESSING_CHAT;
  const isLive = Boolean(liveStatus === LIVE_STATUS.STREAMING);
  const isChatActive = Boolean(chatStatus === CHAT_STATUS.STREAMING);
  const [isDisableDivideBtn, setIsDisableDivideBtn] = useState(false);

  /** ステータス名の変換 */
  const changeStatusName = (liveStatus: string) => {
    // ライブ配信中以外 かつ フィードありの場合
    if (!isLive && (feedState.mainFeed || feedState.backFeed))
      return LIVE_STATUS.PRE;
    // ライブ配信中以外 かつ アクティベート中の場合
    if (!isLive && activating) return LIVE_STATUS.ACTIVATING;
    // 上記以外の場合、ステータスそのまま
    return liveStatus;
  };

  /** 配信ボタンの非活性判定 */
  const checkDisabled = () => {
    // ライブ配信中以外 かつ フィードなしの場合
    if (!isLive && !feedState.mainFeed && !feedState.backFeed) return true;
    // ライブ配信中以外 かつ アクティベート中の場合
    if (!isLive && activating) return true;
    // DVRキャッシュありの場合
    if (dvrCloseDate) return true;
    // ライブ配信中以外 かつ オートモードの場合
    if (!isLive && autoMode) return true;
    // ライブ配信中以外 かつ 配信方法エンコーダー以外の場合
    if (!isLive && streamType !== STREAM_TYPE.ENCODER) return true;
    // 上記以外の場合
    return liveStartBtnDisabled;
  };

  /** フィード状態通知ダイアログの表示判定 */
  const checkNoFeedNotificateDialog = () => {
    // ライブ配信中以外はダイアログを表示しない
    if (!isLive) return false;
    // メインフィード使用またはバックフィード使用の場合
    if (noFeedNotification.mainFeed || noFeedNotification.backFeed) {
      return true;
    }
    // 上記以外の場合
    return false;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <h3 style={{ marginRight: 6 }}>
          <img src={Sensors} alt="Sensors" style={{ marginRight: 6 }} />
          ライブコントロール
        </h3>
        <Badge title={changeStatusName(liveStatus)} className={classes.badge} />
      </Box>
      <Box className={classes.body}>
        <Box className={classes.player}>
          <Box className={classes.playerHeader}>
            <span>
              <InfoOutlined fontSize="small" />
              フィードの状態：
              <span
                className={`${classes.feed} ${
                  feedState.mainFeed ? classes.active : ''
                }`}
              >
                <CheckCircle style={{ width: 13, height: 13 }} />
                メイン
              </span>
              <span
                className={`${classes.feed} ${
                  feedState.backFeed ? classes.active : ''
                }`}
              >
                <CheckCircle style={{ width: 13, height: 13 }} />
                バック
              </span>
            </span>
          </Box>
          <iframe
            referrerPolicy="no-referrer-when-downgrade"
            title="playerIframe-moniter"
            id="playerIframe-moniter"
            allowFullScreen
            frameBorder="0"
            ref={playerRef}
          />
          <Fade in={checkNoFeedNotificateDialog()}>
            <Box className={classes.checkNoFeed}>
              <Box className={classes.checkNoFeedHeader}>
                <Warning className={classes.checkNoFeedHeaderIcon} />
                <h4 className={classes.checkNoFeedHeaderTitle}>
                  ライブフィードの品質が不安定です
                </h4>
                <p className={classes.checkNoFeedHeaderText}>
                  対象フィード：
                  {noFeedNotification.mainFeed && (
                    <span className={classes.checkNoFeedHeaderFeedType}>
                      メイン
                    </span>
                  )}
                  {noFeedNotification.backFeed && (
                    <span className={classes.checkNoFeedHeaderFeedType}>
                      バック
                    </span>
                  )}
                </p>
              </Box>
              <Box className={classes.checkNoFeedBody}>
                ライブ映像を送信している回線状態やエンコーダ端末の状態を確認してください。
              </Box>
            </Box>
          </Fade>
          <Box className={classes.playerFooter}>
            <WatchLater fontSize="small" style={{ marginRight: 4 }} />
            経過時間：{eventTime}
            <Button
              onClick={() => {
                archiveSplit();
                setIsDisableDivideBtn(true);
                setTimeout(() => setIsDisableDivideBtn(false), 10000);
              }}
              className={classes.splitBtn}
              disabled={
                !isLive ||
                isDisableDivideBtn ||
                Boolean(dvrCloseDate) ||
                !vodFlg
              }
            >
              <img src={ContentCut} alt="cut" width={12} height={19} />
              録画ファイル分割
            </Button>
          </Box>
          {feed === FEED_MAINBACK.MAIN ? (
            <Button
              className={classes.viewBtn}
              aria-controls="view-menu"
              onClick={(e) => setViewFlg(e.currentTarget)}
              aria-haspopup="true"
              aria-expanded={viewFlg ? 'true' : undefined}
            >
              表示映像の切り替え：メイン側を視聴確認
              <KeyboardArrowUp fontSize="small" />
            </Button>
          ) : (
            <Button
              className={classes.viewBtn}
              aria-controls="view-menu"
              onClick={(e) => setViewFlg(e.currentTarget)}
              aria-haspopup="true"
              aria-expanded={viewFlg ? 'true' : undefined}
            >
              表示映像の切り替え：バック側を視聴確認
              <KeyboardArrowUp fontSize="small" />
            </Button>
          )}
          <Menu
            id="view-menu"
            open={openViewFlg}
            anchorEl={viewFlg}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 30,
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleClose(FEED_MAINBACK.MAIN)}>
              メイン側を視聴確認
            </MenuItem>
            <MenuItem onClick={() => handleClose(FEED_MAINBACK.BACKUP)}>
              バック側を視聴確認
            </MenuItem>
          </Menu>
        </Box>
        <Box className={classes.linktext}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={`${getPath(
              ROUTE.LIVE_MONITOR
            )}?lpid=${lpid}&c=${cid}&t=${readAPIToken}&u=${userApiParams}`}
            target="_blank"
          >
            配信映像モニタリング画面はこちら
          </a>
        </Box>
        <Box className={classes.info}>
          <Box className={classes.row}>
            <Box className={classes.title}>ライブID：</Box>
            <Box className={classes.content}>{lpid}</Box>
          </Box>
          {edition !== EDITIONS.U_LIVE && (
            <Box className={classes.row}>
              <Box className={classes.title}>ライブイベント情報名：</Box>
              <Box className={classes.content}>{liveEventInfoName}</Box>
            </Box>
          )}
          <Box className={classes.row}>
            <Box className={classes.title}>ライブ設定名：</Box>
            <Box className={classes.content}>{liveName}</Box>
          </Box>
          <Box className={classes.row}>
            <Box className={classes.title}>
              ライブ履歴名：
              <span
                className={classes.helpContainer}
                style={{ display: 'inline-block' }}
              >
                <Help fontSize="small" className={classes.help} />
                <Tooltip
                  message={
                    <ul>
                      <li>
                        ライブ履歴名は、ライブ配信終了後に録画ファイル名となります。
                      </li>
                      <li>
                        ライブ履歴名はライブ配信終了時点まで変更が可能です。
                      </li>
                      <li>
                        設定しない場合、ライブの開始日時がライブ履歴名となります。
                      </li>
                    </ul>
                  }
                  style={{ width: 400 }}
                />
              </span>
            </Box>
            <Box className={classes.content}>
              <TextField
                variant="outlined"
                className={classes.inputText}
                onChange={inputLiveEventName}
                onBlur={changeLiveEventName}
                value={eventName}
                fullWidth
                disabled={processingStream}
                inputProps={{ maxLength: 200 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.attention}>
        <h3 style={{ fontSize: 14, paddingTop: 2 }}>
          <InfoOutlined
            fontSize="small"
            style={{ marginRight: 4, width: 15, height: 15 }}
          />
          ご注意事項
        </h3>
        <ul>
          <li>
            ※&ensp;ライブ配信開始前に、必ず設定内容が正しいかご確認ください。
          </li>
          <li>
            ※&ensp;撮影している映像と、実際に配信される映像にはタイムラグがございますのでご注意ください。
          </li>
          <li>
            ※&ensp;アーカイブされる動画についてはライブ配信開始から10秒程度は録画されません。
          </li>
          <li>※&ensp;配信終了を行った時点で録画ファイルは分割されます。</li>
          <li>※&ensp;視聴者数は30秒間隔で更新されます。</li>
        </ul>
      </Box>
      <Box className={classes.buttons}>
        {processingStream || processingChat ? (
          <Button
            className={isLive ? classes.liveStop : classes.liveStart}
            disabled
          >
            <CircularProgress size={25} />
          </Button>
        ) : (
          <Button
            className={isLive ? classes.liveStop : classes.liveStart}
            onClick={isLive ? finishStream : startStream}
            disabled={checkDisabled()}
          >
            {isLive ? <PauseCircleFilled /> : <PlayCircleFilled />}
            {dvrCloseDate
              ? `DVR ${moment(dvrCloseDate.replace(/\//g, '-')).format(
                  'MM/DD HH:mm'
                )}迄`
              : `ライブ配信${isLive ? '停止' : '開始'}`}
          </Button>
        )}
        <span className={classes.spaceLine} />
        {processingStream || processingChat ? (
          <Button
            className={isChatActive ? classes.chatStop : classes.chatStart}
            disabled
          >
            <CircularProgress size={25} />
          </Button>
        ) : (
          <Button
            className={isChatActive ? classes.chatStop : classes.chatStart}
            onClick={openChatDialog}
            disabled={isChatActive ? chatStopBtnDisabled : chatStartBtnDisabled}
          >
            {isChatActive ? <SpeakerNotesOff /> : <Message />}
            {`チャット${
              chatStatus === CHAT_STATUS.STREAMING ? '停止' : '開始'
            }`}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ControllerLeft;
