import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  FieldValues,
} from 'react-hook-form';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { ZoomIn } from '@material-ui/icons';
import moment from 'moment';
import DisplayModal from '../parts/DisplayModal/DisplayModal';
import FormDropzone from '../parts/FormDropzone/FormDropzone';
import Badge from '../parts/Badge/Badge';
import { ToDropZoneJap } from '../../utils/ChangeStatus';
import { FILE_TO_LIVE_STATUS } from '../../utils/Const';
import { FileToLiveContentProps } from '../../props/FileToLiveContent';

/** 日付フォーマット */
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& p': {
      margin: 0,
      fontSize: 13,
    },
    '& a': {
      color: '#4472C4',
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 30,
    marginBottom: 30,
    fontSize: 15,
  },
  table: {
    fontSize: 14,
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
  },
  tableHeader: {
    background: '#333333',
    '& .MuiTableCell-head': {
      color: '#FFFFFF',
      borderRight: '1px solid #E0E0E04A',
      textAlign: 'center',
      '&:last-child': {
        textAlign: 'center',
        border: 'none',
      },
    },
  },
  tableBody: {
    '& .MuiTableCell-body': {
      borderRight: '1px solid #E0E0E0',
      fontSize: 14,
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-body': {
      borderBottom: 'none',
    },
  },
  timing: {
    padding: 5,
    paddingTop: 10,
    paddingBottom: 16,
  },
  uploadButton: {
    background: '#D8D8D8',
    borderRadius: 4,
    borderBottom: '1px solid #AAAAAA',
    padding: '6px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    fontWeight: 500,
    fontSize: 14,
  },
  image: {
    position: 'relative',
    width: 192,
    height: 108,
    margin: '0 auto',
    '& img': {
      width: 192,
      height: 108,
    },
  },
  zoomIn: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#333333',
    color: '#FFFFFF',
    bottom: 0,
    right: 0,
    padding: 4,
  },
  error: {
    color: '#f44336',
  },
});

/** サムネイル */
const ThumbnailSetting = ({
  register,
  setValue,
  watch,
  ContentProps,
  open = false,
  cid,
}: {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  ContentProps?: FileToLiveContentProps;
  open?: boolean;
  cid: number;
}): JSX.Element => {
  const classes = useStyle();
  const disableFlg = ContentProps?.disableFlg || false;
  const contentSetting = ContentProps?.thumbnailSetting;

  const [dialog, setDialog] = useState(false);
  const [feedoutImg, setFeedoutImg] = useState<string>();
  const [feedinImg, setFeedinImg] = useState<string>();
  const [liveopenImg, setLiveopenImg] = useState<string>();
  const [file, setFile] = useState<string>('');

  const onClick = (name?: string, fileUrl?: string) => {
    let image = '';
    const thumbnail = watch(`${name}` as const);
    if (thumbnail || thumbnail?.length > 0) {
      image = window.URL.createObjectURL(thumbnail);
    } else if (fileUrl) {
      image = fileUrl;
    }
    if (image.length <= 0) return;
    setFile(image);
    setDialog(true);
  };

  // デフォルトサムネイルの時はハイフン、それ以外はファイル名を返す
  const getFileName = (url: string) => {
    const split = url.split('/');
    const result = split.reverse()[0];
    return result.match(
      new RegExp(
        `^${cid}_${
          ContentProps?.baseSetting.flpid
          // eslint-disable-next-line no-useless-escape
        }_[123]\.jpg$`
      )
    )
      ? '-'
      : result;
  };

  useEffect(() => {
    const thumbnail = watch('UpdateThumbnailFeedout');
    if (!thumbnail || thumbnail.length <= 0) return;
    if (thumbnail?.errors) {
      setFeedoutImg('');
      setValue('UpdateThumbnailFeedoutDate', '');
      return;
    }
    setFeedoutImg(URL.createObjectURL(thumbnail));
    setValue('UpdateThumbnailFeedoutDate', moment().format(DATE_FORMAT));
  }, [watch('UpdateThumbnailFeedout')]);

  useEffect(() => {
    const thumbnail = watch('UpdateThumbnailFeedin');
    if (!thumbnail || thumbnail.length <= 0) return;
    if (thumbnail?.errors) {
      setFeedinImg('');
      setValue('UpdateThumbnailFeedinDate', '');
      return;
    }
    setFeedinImg(URL.createObjectURL(thumbnail));
    setValue('UpdateThumbnailFeedinDate', moment().format(DATE_FORMAT));
  }, [watch('UpdateThumbnailFeedin')]);

  useEffect(() => {
    const thumbnail = watch('UpdateThumbnailLiveopen');
    if (!thumbnail || thumbnail.length <= 0) return;
    if (thumbnail?.errors) {
      setLiveopenImg('');
      setValue('UpdateThumbnailLiveopenDate', '');
      return;
    }
    setLiveopenImg(URL.createObjectURL(thumbnail));
    setValue('UpdateThumbnailLiveopenDate', moment().format(DATE_FORMAT));
  }, [watch('UpdateThumbnailLiveopen')]);

  const formatDate = (contentDate?: string) => {
    const dateString = contentDate || '';
    if (!dateString) return '';
    const date =
      dateString.match(/[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])/) ||
      [];
    const time =
      dateString.match(/([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/) || [];
    return (
      <>
        {date[0]}
        <br />
        {time[0]}
      </>
    );
  };

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        <Box style={{ marginBottom: 20 }}>
          配信停止中の疑似ライブ映像のサムネイル画像を任意で設定することができます。
          <br />
          サムネイル画像を設定していない場合は、それぞれデフォルトのサムネイル画像が表示されます。
        </Box>
        <Box>
          ※下記の条件で画像のアップロードが可能です。
          <br />
          画像フォーマット：JPG/PNG/GIF&emsp;画像の高さ：240px以上&emsp;画像容量：2.0MB以下
          <br />
          ※疑似ライブ配信中、配信準備中はサムネイルの変更はできません。また、配信停止直後にサムネイル画像をアップロードすると、画像が反映されない場合がございます。
          <br />
          反映されない場合は再度「アップロード」ボタンよりアップロードをお試しください。
        </Box>
      </Box>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell style={{ width: 140 }}>ステータス</TableCell>
              <TableCell>プレビュー</TableCell>
              <TableCell style={{ width: 125 }}>登録日</TableCell>
              <TableCell style={{ width: 175 }}>ファイル名</TableCell>
              <TableCell style={{ width: disableFlg ? 500 : 360 }}>
                表示タイミング
              </TableCell>
              {!disableFlg && (
                <TableCell style={{ width: 170 }}>画像差し替え</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            <TableRow>
              <TableCell>
                <Badge title={FILE_TO_LIVE_STATUS.WAITING} />
              </TableCell>
              <TableCell>
                <Box className={classes.image}>
                  {!watch('UpdateThumbnailFeedout')?.errors && (
                    <>
                      <img
                        src={feedoutImg || contentSetting?.feedout?.url}
                        alt={FILE_TO_LIVE_STATUS.WAITING}
                      />
                      <ZoomIn
                        className={classes.zoomIn}
                        onClick={() =>
                          onClick(
                            'UpdateThumbnailFeedout',
                            contentSetting?.feedout?.url
                          )
                        }
                      />
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {!watch('UpdateThumbnailFeedout')?.errors &&
                  (watch('UpdateThumbnailFeedoutDate') ||
                    formatDate(contentSetting?.feedout?.insertDate))}
              </TableCell>
              <TableCell>
                {watch('UpdateThumbnailFeedout') &&
                  (watch('UpdateThumbnailFeedout')?.name ?? (
                    <div className={classes.error}>
                      {watch('UpdateThumbnailFeedout')?.errors &&
                        ToDropZoneJap(
                          watch('UpdateThumbnailFeedout')?.errors[0]?.code
                        )}
                    </div>
                  ))}
                {!watch('UpdateThumbnailFeedout') &&
                  getFileName(String(contentSetting?.feedout?.url))}
              </TableCell>
              <TableCell className={classes.timing}>
                <div>
                  疑似ライブ設定後
                  <br />
                  ～
                  <br />
                  配信開始時刻の30分前
                </div>
              </TableCell>
              {!disableFlg && (
                <TableCell>
                  <FormDropzone
                    name="UpdateThumbnailFeedout"
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                    register={register}
                    setValue={setValue}
                    disabled={disableFlg}
                  />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <Badge title={FILE_TO_LIVE_STATUS.READY} />
              </TableCell>
              <TableCell>
                <Box className={classes.image}>
                  {!watch('UpdateThumbnailFeedin')?.errors && (
                    <>
                      <img
                        src={feedinImg || contentSetting?.feedin?.url}
                        alt={FILE_TO_LIVE_STATUS.READY}
                      />
                      <ZoomIn
                        className={classes.zoomIn}
                        onClick={() =>
                          onClick(
                            'UpdateThumbnailFeedin',
                            contentSetting?.feedin?.url
                          )
                        }
                      />
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {!watch('UpdateThumbnailFeedin')?.errors &&
                  (watch('UpdateThumbnailFeedinDate') ||
                    formatDate(contentSetting?.feedin?.insertDate))}
              </TableCell>
              <TableCell>
                {watch('UpdateThumbnailFeedin') &&
                  (watch('UpdateThumbnailFeedin')?.name ?? (
                    <div className={classes.error}>
                      {watch('UpdateThumbnailFeedin')?.errors &&
                        ToDropZoneJap(
                          watch('UpdateThumbnailFeedin')?.errors[0]?.code
                        )}
                    </div>
                  ))}
                {!watch('UpdateThumbnailFeedin') &&
                  getFileName(String(contentSetting?.feedin?.url))}
              </TableCell>
              <TableCell className={classes.timing}>
                <div>
                  配信開始時刻の30分前
                  <br />
                  ～
                  <br />
                  配信開始時刻の3分前
                </div>
              </TableCell>
              {!disableFlg && (
                <TableCell>
                  <FormDropzone
                    name="UpdateThumbnailFeedin"
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                    register={register}
                    setValue={setValue}
                    disabled={disableFlg}
                  />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <Badge title={FILE_TO_LIVE_STATUS.FINISHED} />
              </TableCell>
              <TableCell>
                <Box className={classes.image}>
                  {!watch('UpdateThumbnailLiveopen')?.errors && (
                    <>
                      <img
                        src={liveopenImg || contentSetting?.liveopen?.url}
                        alt={FILE_TO_LIVE_STATUS.FINISHED}
                      />
                      <ZoomIn
                        className={classes.zoomIn}
                        onClick={() =>
                          onClick(
                            'UpdateThumbnailLiveopen',
                            contentSetting?.liveopen?.url
                          )
                        }
                      />
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {!watch('UpdateThumbnailLiveopen')?.errors &&
                  (watch('UpdateThumbnailLiveopenDate') ||
                    formatDate(contentSetting?.liveopen?.insertDate))}
              </TableCell>
              <TableCell>
                {watch('UpdateThumbnailLiveopen') &&
                  (watch('UpdateThumbnailLiveopen')?.name ?? (
                    <div className={classes.error}>
                      {watch('UpdateThumbnailLiveopen')?.errors &&
                        ToDropZoneJap(
                          watch('UpdateThumbnailLiveopen')?.errors[0]?.code
                        )}
                    </div>
                  ))}
                {!watch('UpdateThumbnailLiveopen') &&
                  getFileName(String(contentSetting?.liveopen?.url))}
              </TableCell>
              <TableCell className={classes.timing}>
                <div>終了後フィラー映像の終了後以降</div>
              </TableCell>
              {!disableFlg && (
                <TableCell>
                  <FormDropzone
                    name="UpdateThumbnailLiveopen"
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                    register={register}
                    setValue={setValue}
                    disabled={disableFlg}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DisplayModal
        open={dialog}
        handleClose={() => setDialog(false)}
        title="画像"
        imageUrl={file}
        imageAlt="画像"
      />
    </Box>
  );
};

export default ThumbnailSetting;
