import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  addEQLiveProfile,
  removeEQliveProfile,
  selectAllEQLives,
  setEQLiveProfile,
  setFaildDeleateLiveId,
  setLiveSum,
} from '../../features/livesSlice';
import { setError } from '../../features/uiSlice';
import { setContract } from '../../features/userSlice';
import {
  DeleteLiveProfile,
  EQLiveProfile,
  GetContract,
  GetLiveProfile,
  GetWowzaFeed,
} from '../../types/API';
import { LIVE_STATUS_ENG, MAX_PAGESIZE } from '../../utils/Const';
import { LIVESDELETE_FLOW_ERR_MSG } from '../../utils/Message';

export type DeleteLivesFlowParams = {
  cid: number;
  userApiParams: string;
  readAPIToken: string;
  deletelpids: number[];
  callback: React.Dispatch<React.SetStateAction<number[]>>;
};
/** ライブプロファイル削除フロー */
export const deleteLivesFlow =
  (arg: DeleteLivesFlowParams): AppThunk =>
  async (dispatch, getState) => {
    try {
      // 即時で画面から削除
      dispatch(removeEQliveProfile(arg.deletelpids));

      // 即時で画面の登録件数を更新
      const nowLiveSum = getState().lives.liveSum;
      dispatch(setLiveSum(nowLiveSum - arg.deletelpids.length));

      /** 削除に失敗したライブIDリスト */
      const falidDeleteLpids: number[] = [];

      // 削除予定のライブプロファイルが100件以下の場合
      if (arg.deletelpids.length <= MAX_PAGESIZE) {
        // ライブプロファイル削除API実行
        const responseDeleteLiveProfile: AxiosResponse<DeleteLiveProfile> =
          await axios.delete(
            `${String(
              process.env.REACT_APP_BACKEND_API_ENDPOINT
            )}deleteProfileProcess`,
            {
              params: {
                c: arg.cid,
                t: arg.readAPIToken,
                ll: arg.deletelpids.join(','),
              },
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        // ライブプロファイル削除APIで失敗したものを削除に失敗したライブIDリストに追加
        falidDeleteLpids.push(...responseDeleteLiveProfile.data.failed);
      }

      // 削除予定のライブプロファイルが100件以上ある場合を考慮する
      if (arg.deletelpids.length > MAX_PAGESIZE) {
        const deletelpids1 = arg.deletelpids.slice(0, MAX_PAGESIZE);
        const deletelpids2 = arg.deletelpids.slice(
          MAX_PAGESIZE,
          arg.deletelpids.length
        );
        // ライブプロファイル削除API実行
        const responseDeleteLiveProfile1: AxiosResponse<DeleteLiveProfile> =
          await axios.delete(
            `${String(
              process.env.REACT_APP_BACKEND_API_ENDPOINT
            )}deleteProfileProcess`,
            {
              params: {
                c: arg.cid,
                t: arg.readAPIToken,
                ll: deletelpids1.join(','),
              },
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        falidDeleteLpids.push(...responseDeleteLiveProfile1.data.failed);

        // ライブプロファイル削除API実行
        const responseDeleteLiveProfile2: AxiosResponse<DeleteLiveProfile> =
          await axios.delete(
            `${String(
              process.env.REACT_APP_BACKEND_API_ENDPOINT
            )}deleteProfileProcess`,
            {
              params: {
                c: arg.cid,
                t: arg.readAPIToken,
                ll: deletelpids2.join(','),
              },
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        falidDeleteLpids.push(...responseDeleteLiveProfile2.data.failed);
      }

      // 削除に失敗したものをstateに格納
      dispatch(setFaildDeleateLiveId({ failed: falidDeleteLpids }));

      /** ライブプロファイル削除APIで削除に失敗したライブIDを除いた削除されたライブID群 */
      const deletedLpids =
        falidDeleteLpids.length === 0
          ? arg.deletelpids.slice()
          : arg.deletelpids.filter((id) => falidDeleteLpids.indexOf(id) === -1);

      // 成功したリストを渡す
      arg.callback(deletedLpids);

      // 全件削除に成功した（失敗したものがない）場合、終了
      if (falidDeleteLpids.length === 0) {
        return true;
      }

      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setContract({ ...responseGetContract.data }));

      // ライブプロファイル取得API実行
      const responseGetLiveProfile1: AxiosResponse<GetLiveProfile> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            pageSize: MAX_PAGESIZE,
            pageNumber: 1,
            portal: getState().user.contract.serviceFlg39 ? 1 : 0,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      let liveProfilesGetted: EQLiveProfile[] =
        responseGetLiveProfile1.data.profile;

      // 100件以上のプロファイルがある場合に続きを取得
      if (responseGetLiveProfile1.data.livesum > MAX_PAGESIZE) {
        // ライブプロファイル取得API実行
        const responseGetLiveProfile2: AxiosResponse<GetLiveProfile> =
          await axios.post(
            `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
            {
              cid: arg.cid,
              userApiParams: arg.userApiParams,
              pageSize: MAX_PAGESIZE,
              pageNumber: 2,
              portal: responseGetContract.data.serviceFlg39 ? 1 : 0,
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        liveProfilesGetted = responseGetLiveProfile1.data.profile.concat(
          responseGetLiveProfile2.data.profile
        );
      }
      dispatch(
        setEQLiveProfile({
          livesum: responseGetLiveProfile1.data.livesum,
          profile: liveProfilesGetted,
        })
      );

      /** 取得したライブIDとwlivesIDとステータスの配列 */
      const lpidServerIds: {
        status: LIVE_STATUS_ENG;
        dvrCloseDate: string | null;
        lpid: number;
        serverId: string;
      }[] = liveProfilesGetted
        .map((profile) => {
          return {
            status: profile.status,
            dvrCloseDate: profile.dvrCloseDate,
            lpid: profile.lpid,
            serverId: profile.jcloudWlives.liveId,
          };
        })
        .filter(
          (x) =>
            x.status === LIVE_STATUS_ENG.STOP &&
            !x.dvrCloseDate &&
            x.lpid !== null &&
            x.serverId
        );
      // 取得したwlivesIDのリスト
      const gettedWlivesIDs = lpidServerIds.map((prof) => prof.serverId);
      if (gettedWlivesIDs.length) {
        // Wowzaフィード確認APIを実行
        const responseGetWowzaFeed: AxiosResponse<GetWowzaFeed[]> =
          await axios.post(
            `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getWowzaFeed`,
            {
              wlivesIds: gettedWlivesIDs,
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const updateEQLives = selectAllEQLives(getState());
        // フィードがあればステータス変更
        responseGetWowzaFeed.data.forEach((element) => {
          if (element.mainFeed || element.backFeed) {
            const feedOnEQlive = updateEQLives.find(
              (prof) => prof.jcloudWlives.liveId === element.wlivesId
            );
            if (feedOnEQlive === undefined) {
              // eslint-disable-next-line no-throw-literal
              throw -1;
            }
            const cloneEQlive = { ...feedOnEQlive };
            // ステータスを配信準備中に
            cloneEQlive.status = LIVE_STATUS_ENG.PRE;
            dispatch(addEQLiveProfile(cloneEQlive));
          }
        });
      }

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVESDELETE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    }
  };
