import React, { useState, useEffect } from 'react';
import {
  UseFormRegister,
  UseFormWatch,
  UseFormTrigger,
  UseFormClearErrors,
  UseFormSetError,
  Control,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import {
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import FormTextBox from '../parts/FormTextBox/FormTextBox';
import FormCheckBox from '../parts/FormCheckBox/FormCheckBox';
import SampleImage from '../../images/related_sample.png';
import { SUPPORTPAGE_URL_EXLINK } from '../../utils/Const';
import { getPattarnMsg, REQUIRED_MSG } from '../../utils/Message';
import { LiveContentProps } from '../../props/LiveContent';
import { HistoryContentProps } from '../../props/HistoryContent';

const URL_REGEXP = new RegExp(
  /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g
);

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& p': {
      margin: 0,
      fontSize: 13,
    },
    '& a': {
      color: '#4472C4',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 30,
    marginBottom: 30,
    fontSize: 15,
  },
  discription: {
    display: 'flex',
    marginTop: 10,
    opacity: 0.6,
  },
  icon: {
    color: '#333333',
    marginRight: 4,
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '20px 22px 10px',
    color: '#333333',
    '& > p': {
      marginBottom: 20,
    },
  },
  related: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    marginLeft: 30,
    marginBottom: 10,
    fontSize: 15,
    fontWeight: 500,
  },
  relatedHead: {
    padding: '15px 25px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& svg': {
      color: '#858585',
    },
  },
  relatedBody: {
    borderTop: '1px solid #F0F0F0',
    padding: '20px 25px 0',
    '& .MuiFormControl-root': {
      marginBottom: 20,
    },
  },
  sampleImage: {
    textAlign: 'center',
    color: '#333333',
    marginTop: 30,
    marginBottom: 24,
    fontSize: 13,
    fontWeight: 500,
    '& img': {
      width: 440,
      height: 244,
      borderRadius: 4,
      marginBottom: 4,
    },
  },
});

/** 動画からのリンク */
const 動画からのリンク = ({
  register,
  control,
  errors,
  watch,
  trigger,
  clearErrors,
  setError,
  ContentProps,
  HistoryProps,
  open = false,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  errors?: Record<string, { message: string }>;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  ContentProps?: LiveContentProps;
  HistoryProps?: HistoryContentProps;
  open?: boolean;
}): JSX.Element => {
  const classes = useStyle();
  const disableFlg = ContentProps?.disableFlg || false;
  const contentSetting = ContentProps?.exLinkSetting;
  const historySetting = HistoryProps?.exLinkSetting;

  const [related1, setRelated1] = useState(
    contentSetting?.settings[0] || historySetting?.settings[0] || false
  );
  const [related2, setRelated2] = useState(
    contentSetting?.settings[1] || historySetting?.settings[1] || false
  );
  const [related3, setRelated3] = useState(
    contentSetting?.settings[2] || historySetting?.settings[2] || false
  );

  const [ExLink1TextValidation, setExLink1TextValidation] =
    useState<RegisterOptions>({
      required: false,
    });
  const [ExLink1UrlValidation, setExLink1UrlValidation] =
    useState<RegisterOptions>({
      required: false,
      pattern: {
        value: URL_REGEXP,
        message: getPattarnMsg('URL'),
      },
    });

  const [ExLink2TextValidation, setExLink2TextValidation] =
    useState<RegisterOptions>({
      required: false,
    });
  const [ExLink2UrlValidation, setExLink2UrlValidation] =
    useState<RegisterOptions>({
      required: false,
      pattern: {
        value: URL_REGEXP,
        message: getPattarnMsg('URL'),
      },
    });
  const [ExLink3TextValidation, setExLink3TextValidation] =
    useState<RegisterOptions>({
      required: false,
    });
  const [ExLink3UrlValidation, setExLink3UrlValidation] =
    useState<RegisterOptions>({
      required: false,
      pattern: {
        value: URL_REGEXP,
        message: getPattarnMsg('URL'),
      },
    });

  const checkExLink2Validatin = async () => {
    await setExLink2TextValidation({
      required:
        !disableFlg &&
        watch('ExLinkSettingEnabled') &&
        watch('ExLinkSettingSetting2Url')
          ? REQUIRED_MSG
          : false,
    });
    await setExLink2UrlValidation({
      ...ExLink2UrlValidation,
      required:
        !disableFlg &&
        watch('ExLinkSettingEnabled') &&
        watch('ExLinkSettingSetting2Text')
          ? REQUIRED_MSG
          : false,
    });
    if (
      watch('ExLinkSettingSetting2Text') ||
      watch('ExLinkSettingSetting2Url')
    ) {
      trigger('ExLinkSettingSetting2Text');
      trigger('ExLinkSettingSetting2Url');
    } else {
      clearErrors('ExLinkSettingSetting2Text');
      clearErrors('ExLinkSettingSetting2Url');
    }
  };

  const checkExLink3Validatin = async () => {
    await setExLink3TextValidation({
      required:
        !disableFlg &&
        watch('ExLinkSettingEnabled') &&
        watch('ExLinkSettingSetting3Url')
          ? REQUIRED_MSG
          : false,
    });
    await setExLink3UrlValidation({
      ...ExLink3UrlValidation,
      required:
        !disableFlg &&
        watch('ExLinkSettingEnabled') &&
        watch('ExLinkSettingSetting3Text')
          ? REQUIRED_MSG
          : false,
    });
    if (
      watch('ExLinkSettingSetting3Text') ||
      watch('ExLinkSettingSetting3Url')
    ) {
      trigger('ExLinkSettingSetting3Text');
      trigger('ExLinkSettingSetting3Url');
    } else {
      clearErrors('ExLinkSettingSetting3Text');
      clearErrors('ExLinkSettingSetting3Url');
    }
  };

  useEffect(() => {
    const setValidation = async () => {
      const flg = !disableFlg && watch('ExLinkSettingEnabled');
      setRelated1(flg);
      await setExLink1TextValidation({
        required: flg ? REQUIRED_MSG : false,
      });
      await setExLink1UrlValidation({
        ...ExLink1UrlValidation,
        required: flg ? REQUIRED_MSG : false,
      });
      if (flg) {
        if (!watch('ExLinkSettingSetting1Text'))
          setError('ExLinkSettingSetting1Text', { message: REQUIRED_MSG });
        if (!watch('ExLinkSettingSetting1Url'))
          setError('ExLinkSettingSetting1Url', { message: REQUIRED_MSG });
      } else {
        clearErrors('ExLinkSettingSetting1Text');
        clearErrors('ExLinkSettingSetting1Url');
      }
    };
    setValidation();
    checkExLink2Validatin();
    checkExLink3Validatin();
  }, [watch('ExLinkSettingEnabled')]);

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        ライブ視聴完了時に、
        <a href={SUPPORTPAGE_URL_EXLINK} target="_blank" rel="noreferrer">
          関連ページへのリンク
        </a>
        を最大３つまで表示することができます。 <br />
        ※リンクの表示には、「関連リンク機能」を有効にしているプレイヤーの使用が必要です。
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          関連ページリンク（動画からのリンク）
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FormCheckBox
              name="ExLinkSettingEnabled"
              control={control}
              label="利用する"
              defaultChecked={
                // eslint-disable-next-line no-nested-ternary
                contentSetting
                  ? Boolean(contentSetting?.enabled)
                  : historySetting
                  ? Boolean(historySetting?.enabled)
                  : false
              }
              disabled={Boolean(historySetting) || disableFlg}
            />
            <p className={classes.discription} style={{ marginLeft: 30 }}>
              <InfoOutlined fontSize="small" className={classes.icon} />
              動画には、それに関連するリンクを設定しておくことをお勧めします。リンクを設定しておくと、動画から任意のページに誘導することができます。
            </p>
            <Box className={classes.sampleImage}>
              <img src={SampleImage} alt="リンク表示時のプレーヤーイメージ" />
              <Box style={{ opacity: 0.6 }}>
                リンク表示時のプレーヤーイメージ
              </Box>
            </Box>
            <Box className={classes.related}>
              <Box
                className={classes.relatedHead}
                onClick={() => setRelated1(!related1)}
              >
                関連ページリンク1
                {related1 ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </Box>
              {related1 && (
                <Box className={classes.relatedBody}>
                  <FormTextBox
                    label="リンクテキスト（関連ページの名前）"
                    name="ExLinkSettingSetting1Text"
                    width="fullWidth"
                    register={register}
                    maxLength={100}
                    defaultValue={
                      contentSetting?.settings[0]?.text ||
                      historySetting?.settings[0]?.text ||
                      ''
                    }
                    disabled={Boolean(historySetting) || disableFlg}
                    validation={ExLink1TextValidation}
                    validationError={Boolean(errors?.ExLinkSettingSetting1Text)}
                    validationErrorMsg={
                      errors?.ExLinkSettingSetting1Text?.message
                        ? String(errors?.ExLinkSettingSetting1Text?.message)
                        : ''
                    }
                    className={`${
                      errors?.ExLinkSettingSetting1Text ? 'error' : ''
                    }`}
                  />
                  <FormTextBox
                    label="リンクURL"
                    name="ExLinkSettingSetting1Url"
                    width="fullWidth"
                    register={register}
                    validation={ExLink1UrlValidation}
                    maxLength={1000}
                    validationError={Boolean(errors?.ExLinkSettingSetting1Url)}
                    validationErrorMsg={
                      errors?.ExLinkSettingSetting1Url?.message
                        ? String(errors?.ExLinkSettingSetting1Url?.message)
                        : ''
                    }
                    defaultValue={
                      contentSetting?.settings[0]?.url ||
                      historySetting?.settings[0]?.url ||
                      ''
                    }
                    disabled={Boolean(historySetting) || disableFlg}
                    className={`${
                      errors?.ExLinkSettingSetting1Url ? 'error' : ''
                    }`}
                  />
                </Box>
              )}
            </Box>
            <Box className={classes.related}>
              <Box
                className={classes.relatedHead}
                onClick={() => setRelated2(!related2)}
              >
                関連ページリンク2
                {related2 ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </Box>
              {related2 && (
                <Box className={classes.relatedBody}>
                  <FormTextBox
                    label="リンクテキスト（関連ページの名前）"
                    name="ExLinkSettingSetting2Text"
                    width="fullWidth"
                    register={register}
                    maxLength={100}
                    defaultValue={
                      contentSetting?.settings[1]?.text ||
                      historySetting?.settings[1]?.text ||
                      ''
                    }
                    disabled={Boolean(historySetting) || disableFlg}
                    validation={ExLink2TextValidation}
                    validationError={Boolean(errors?.ExLinkSettingSetting2Text)}
                    validationErrorMsg={
                      errors?.ExLinkSettingSetting2Text?.message
                        ? String(errors?.ExLinkSettingSetting2Text?.message)
                        : ''
                    }
                    className={`${
                      errors?.ExLinkSettingSetting2Text ? 'error' : ''
                    }`}
                    onBlur={checkExLink2Validatin}
                  />
                  <FormTextBox
                    label="リンクURL"
                    name="ExLinkSettingSetting2Url"
                    width="fullWidth"
                    register={register}
                    validation={ExLink2UrlValidation}
                    maxLength={1000}
                    validationError={Boolean(errors?.ExLinkSettingSetting2Url)}
                    validationErrorMsg={
                      errors?.ExLinkSettingSetting2Url?.message
                        ? String(errors?.ExLinkSettingSetting2Url?.message)
                        : ''
                    }
                    defaultValue={
                      contentSetting?.settings[1]?.url ||
                      historySetting?.settings[1]?.url ||
                      ''
                    }
                    disabled={Boolean(historySetting) || disableFlg}
                    className={`${
                      errors?.ExLinkSettingSetting2Url ? 'error' : ''
                    }`}
                    onBlur={checkExLink2Validatin}
                  />
                </Box>
              )}
            </Box>
            <Box className={classes.related}>
              <Box
                className={classes.relatedHead}
                onClick={() => setRelated3(!related3)}
              >
                関連ページリンク3
                {related3 ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </Box>
              {related3 && (
                <Box className={classes.relatedBody}>
                  <FormTextBox
                    label="リンクテキスト（関連ページの名前）"
                    name="ExLinkSettingSetting3Text"
                    width="fullWidth"
                    register={register}
                    maxLength={100}
                    defaultValue={
                      contentSetting?.settings[2]?.text ||
                      historySetting?.settings[2]?.text ||
                      ''
                    }
                    disabled={Boolean(historySetting) || disableFlg}
                    validation={ExLink3TextValidation}
                    validationError={Boolean(errors?.ExLinkSettingSetting3Text)}
                    validationErrorMsg={
                      errors?.ExLinkSettingSetting3Text?.message
                        ? String(errors?.ExLinkSettingSetting3Text?.message)
                        : ''
                    }
                    className={`${
                      errors?.ExLinkSettingSetting3Text ? 'error' : ''
                    }`}
                    onBlur={checkExLink3Validatin}
                  />
                  <FormTextBox
                    label="リンクURL"
                    name="ExLinkSettingSetting3Url"
                    width="fullWidth"
                    register={register}
                    validation={ExLink3UrlValidation}
                    maxLength={1000}
                    validationError={Boolean(errors?.ExLinkSettingSetting3Url)}
                    validationErrorMsg={
                      errors?.ExLinkSettingSetting3Url?.message
                        ? String(errors?.ExLinkSettingSetting3Url?.message)
                        : ''
                    }
                    defaultValue={
                      contentSetting?.settings[2]?.url ||
                      historySetting?.settings[2]?.url ||
                      ''
                    }
                    disabled={Boolean(historySetting) || disableFlg}
                    className={`${
                      errors?.ExLinkSettingSetting3Url ? 'error' : ''
                    }`}
                    onBlur={checkExLink3Validatin}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default 動画からのリンク;
