import { GetHistoryProfile } from '../../types/API';
import { LIVE_LOG_STATUS_ENG, CHAT_STATUS } from '../Const';

const liveStatus = Object.values(LIVE_LOG_STATUS_ENG);
const chatStatus = Object.values(CHAT_STATUS);

/** ライブ履歴取得API Mockレスポンス */
const HistoryProfile = (pageNumber: number): GetHistoryProfile => {
  return {
    livesum: 300,

    /** ライブ履歴リスト */
    events: [...Array(100)].map((x, i) => {
      const index = (pageNumber - 1) * 100 + i + 1;
      return {
        lplid: `${index}-1`,
        recMids: [3262, 2350, 2351, 2352, 2353, 2354, 2355],
        status: liveStatus[i % liveStatus.length],
        liveName: `ライブ配信設定名-${index}`,
        liveDescription: `ライブ配信設定説明-${index},ライブ配信設定説明-${index},`,
        eventName: `イベント設定名-${index}`,
        eventOnair: '2022/02/02 22:30:00',
        eventOffair:
          liveStatus[i % liveStatus.length] === 'Recording' ||
          liveStatus[i % liveStatus.length] === 'Transcoding'
            ? null
            : '2022/02/02 22:35:01',
        groupId: 1,
        mpid: 2,
        maxClient: 20,
        liveappFlg: !!(i % 2),
        bitrateFhd: 0,
        bitrateHd: 0,
        bitrateHigh: 0,
        bitrateStandard: 0,
        bitrateLow: 0,
        vodFlg: 1,
        serverNumber: 1,
        exlink: {
          enabled: 1,
          settings: [
            {
              index: 1,
              text: `関連ページリンクテキスト-${index}-1`,
              url: `関連ページリンクURL-${index}-1`,
            },
            {
              index: 2,
              text: `関連ページリンクテキスト-${index}-2`,
              url: `関連ページリンクURL-${index}-2`,
            },
            {
              index: 3,
              text: `関連ページリンクテキスト-${index}-3`,
              url: `関連ページリンクURL-${index}-3`,
            },
          ],
        },
        vlpid: 3,
        chat: {
          enabled: 1,
          status: chatStatus[i % chatStatus.length],
          autoResizeFlg: 0,
          width: 1000,
          height: 360,
          goodBtn: 1,
          troubleBtn: 0,
          enterMsg: 0,
          adminDisplayName: '事務局',
          blockType: 0,
          blockWords: ['blockeord1', 'blockword2'],
          openTiming: -1,
          closeTiming: 40,
          iconTexts: [
            {
              id: 1,
              text: 'よくわかりました',
              activeFlg: 0,
            },
            {
              id: 2,
              text: 'メモします',
              activeFlg: 0,
            },
          ],
        },
        portalMeta: {
          eventName: `ライブイベント情報名-${index}`,
          description: `ライブイベント情報説明文-${index},ライブイベント情報説明文-${index}`,
          performer: `出演者-${index}`,
        },
        desktopEncoder: !!(i % 2),
        updateDate: 'yyyy-MM-dd HH:mm:ss',
        insertDate: 'yyyy-MM-dd HH:mm:ss',
        encrypt: 1,
        dvr: 1,
        dvrCloseMinutes: 5,
        dvrCloseDate:
          liveStatus[i % liveStatus.length] ===
          LIVE_LOG_STATUS_ENG.RECORDING_FINISH
            ? 'yyyy-MM-dd HH:mm:ss'
            : null,
        lqpid: 4,

        jcloudWlives: {
          liveId: `hlsStream-id-${index}`,
          hlsauthStreamId: {
            master: `hlsStream-master-${index}`,
            original: `hlsStream-original-${index}`,
            hd: `hlsStream-hd-${index}`,
            high: '',
            standard: `hlsStream-standard-${index}`,
            low: '',
          },
          hlsauthStreamIdMain: {
            master: `hlsStream-master-${index}`,
            original: `hlsStream-original-${index}`,
            hd: `hlsStream-hd-${index}`,
            high: '',
            standard: `hlsStream-standard-${index}`,
            low: '',
          },
          hlsauthStreamIdBack: {
            master: `hlsStream-master-${index}`,
            original: `hlsStream-original-${index}`,
            hd: `hlsStream-hd-${index}`,
            high: '',
            standard: `hlsStream-standard-${index}`,
            low: '',
          },
        },
      };
    }),
  };
};

export default HistoryProfile;
