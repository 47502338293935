import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import LiveContentComponent from '../../components/LiveContent/LiveContentComponent';
import {
  ChatSetting,
  ContentBaseSetting,
  LiveEventInfo,
  ThumbnailSetting,
} from '../../props/LiveContent';
import { InputData, SecuritySetting } from '../../props/LiveRegister';
import { ExLinkSetting } from '../../types/API';
import { LIVE_SAVE, LIVE_STATUS_ENG, CHAT_STATUS } from '../../utils/Const';
import { QUERY_ERR_MSG } from '../../utils/Message';
import {
  getStreamType,
  getStreams,
  getExLinkInit,
  getEndTiming,
  getAutoEndMinutes,
  getPortalMetaInit,
} from '../../utils/ChangeContentData';
import { setError } from '../../features/uiSlice';
import { selectEQLivesById } from '../../features/livesSlice';
import LiveProfileInit from '../LiveRegister/LiveProfileInit';
import LiveUpdate from './LiveUpdate';

/** ライブ詳細 */
const LiveContentContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // クエリチェック
  const searchString = window.location.search;
  const params = new URLSearchParams(searchString);
  const lpid = Number(params.get('lpid'));
  if (!lpid) {
    dispatch(
      setError({
        msg: QUERY_ERR_MSG,
        detail: '',
      })
    );
  }

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const EQlive = useAppSelector((state: RootState) =>
    selectEQLivesById(state, lpid)
  );

  // 変数抽出
  const { edition, liveInfo } = user.contract;
  const { maxClient, nowClientCnt } = liveInfo;

  // ダイアログ
  const [dialog, setDialog] = useState<boolean>(false);

  // 項目非活性フラグ設定
  const disableFlg =
    (EQlive?.status === LIVE_STATUS_ENG.PRE ||
      EQlive?.status === LIVE_STATUS_ENG.OPEN ||
      EQlive?.chat.status === CHAT_STATUS.STREAMING) ??
    false;
  const maxClientLimitDisableFlg =
    (EQlive?.status === LIVE_STATUS_ENG.OPEN ||
      EQlive?.chat.status === CHAT_STATUS.STREAMING ||
      EQlive?.status === LIVE_STATUS_ENG.CREATING) ??
    false;
  // 初期処理
  useEffect(() => {
    dispatch(LiveProfileInit(LIVE_SAVE.UPDATE, setDialog));
  }, []);

  // 保存処理
  const saveLiveProfile = (data: InputData) => {
    if (!EQlive) return;
    dispatch(LiveUpdate(data, edition, Boolean(liveInfo.encrypt)));
  };

  // 基本設定
  const baseSetting: ContentBaseSetting = {
    liveABRProfiles: user.liveabr,
    transcodeProfiles: user.transcode,
    groupProfiles: user.group,
    nowClientCnt,
    maxClient,
    lpid: EQlive?.lpid ?? 0,
    liveName: EQlive?.liveName ?? '',
    liveDescription: EQlive?.liveDescription ?? '',
    maxClientLimit: EQlive?.maxClient ?? 0,
    streamType: getStreamType(
      EQlive?.liveappFlg ?? 0,
      EQlive?.desktopEncoder ?? 0
    ),
    lqpid: EQlive?.lqpid ?? 0,
    streams: getStreams(
      EQlive?.bitrateHd ?? 0,
      EQlive?.bitrateHigh ?? 0,
      EQlive?.bitrateStandard ?? 0,
      EQlive?.bitrateLow ?? 0
    ),
    encrypt: Boolean(EQlive?.encrypt),
    liveDVRProfile: {
      flg: Boolean(EQlive?.dvr),
      immediation:
        EQlive?.dvrCloseMinutes === 1 || EQlive?.dvrCloseMinutes === 0,
      lifeTime:
        // eslint-disable-next-line no-nested-ternary
        EQlive?.dvrCloseMinutes === undefined
          ? undefined
          : EQlive.dvrCloseMinutes >= 3
          ? EQlive.dvrCloseMinutes
          : undefined,
    },
    vodFlg: EQlive?.vodFlg ?? 0,
    mpid: EQlive?.mpid ?? 0,
    groupId: EQlive?.groupId ?? 0,
    updatedAutoQualityFlg: EQlive?.updatedAutoQualityFlg,
  };

  // 動画からのリンク
  const exLinkSetting: ExLinkSetting = EQlive?.exlink ?? getExLinkInit();

  // セキュリティ
  const securitySetting: SecuritySetting = {
    viewLimitsProfiles: user.viewLimitsProfile,
    vlpid: EQlive?.vlpid ?? 0,
  };

  // サムネイル
  const thumbnailSetting: ThumbnailSetting = {
    feedout: {
      url: EQlive?.thumbFeedout ?? '',
      insertDate: EQlive?.thumbFeedoutDatetime ?? '',
    },
    feedin: {
      url: EQlive?.thumbFeedin ?? '',
      insertDate: EQlive?.thumbFeedinDatetime ?? '',
    },
    liveopen: {
      url: EQlive?.thumbLiveopen ?? '',
      insertDate: EQlive?.thumbLiveopenDatetime ?? '',
    },
  };

  // チャット設定
  const chatSetting: ChatSetting = {
    enabled: Boolean(EQlive?.chat.enabled),
    autoResize: Boolean(EQlive?.chat.autoResizeFlg),
    size: {
      width: EQlive?.chat.width ?? 0,
      height: EQlive?.chat.height ?? 0,
    },
    startTiming: EQlive?.chat.openTiming ?? 0,
    endTiming: getEndTiming(EQlive?.chat.closeTiming ?? 0),
    autoEndMinutes: getAutoEndMinutes(EQlive?.chat.closeTiming ?? 0),
    blockType: EQlive?.chat.blockType ?? 0,
    enterMsg: EQlive?.chat.enterMsg ?? 0,
    goodBtn: Boolean(EQlive?.chat.goodBtn),
    troubleBtn: Boolean(EQlive?.chat.troubleBtn),
    customBtn1: {
      enable: EQlive?.chat.iconTexts
        ? Boolean(EQlive?.chat.iconTexts[0]?.activeFlg)
        : false,
      name: EQlive?.chat.iconTexts ? EQlive?.chat.iconTexts[0]?.text : '',
    },
    customBtn2: {
      enable: EQlive?.chat.iconTexts
        ? Boolean(EQlive?.chat.iconTexts[1]?.activeFlg)
        : false,
      name: EQlive?.chat.iconTexts ? EQlive?.chat.iconTexts[1]?.text : '',
    },
    adminDisplayName: EQlive?.chat.adminDisplayName ?? '',
    blockWords: EQlive?.chat.blockWords.join(',') ?? '',
  };

  // ライブイベント情報
  const liveEventInfo: LiveEventInfo =
    EQlive?.portalMeta ?? getPortalMetaInit();

  return (
    <LiveContentComponent
      edition={edition}
      encryptEdition={Boolean(liveInfo.encrypt)}
      disableFlg={disableFlg}
      maxClientLimitDisableFlg={maxClientLimitDisableFlg}
      baseSetting={baseSetting}
      exLinkSetting={exLinkSetting}
      securitySetting={securitySetting}
      thumbnailSetting={thumbnailSetting}
      chatSetting={chatSetting}
      liveEventInfo={liveEventInfo}
      saveLiveProfile={saveLiveProfile}
      dialog={dialog}
      setDialog={setDialog}
    />
  );
};

export default LiveContentContainer;
