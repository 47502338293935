import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { FormSeachTextBoxProps } from '../../../props/Parts';

/** ReactHookForm用テキストボックス */
const FormSearchTextBox = ({
  name,
  register,
  setValue,
  width,
  backgroundColor = '',
  label = '',
  defaultValue = '',
  placeholder = '',
  maxLength = 100,
  disabled = false,
  readOnly = false,
  numberOnly = false,
  numberStep = 1,
  rows = 1,
  validationError = false,
  validationErrorMsg = '',
  validation,
  className = '',
  autoComplete = 'off',
}: FormSeachTextBoxProps): JSX.Element => {
  const handleClear = () => {
    if (disabled) return;
    setValue(`${name}` as const, '');
  };
  return (
    <TextField
      size="small"
      variant="standard"
      color="primary"
      {...register(`${name}` as const, validation)}
      style={{ width, backgroundColor }}
      fullWidth={width === 'fullWidth'}
      label={label}
      defaultValue={defaultValue}
      placeholder={placeholder}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly,
        inputProps: {
          maxLength,
          step: numberStep,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <ClearIcon onClick={handleClear} />
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      type={numberOnly ? 'number' : 'text'}
      multiline={rows > 1}
      rows={rows}
      error={validationError}
      helperText={validationErrorMsg}
      className={className}
      autoComplete={autoComplete}
    />
  );
};

export default FormSearchTextBox;
