import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Box, Button } from '@material-ui/core';

/** スタイル */
const useStyle = makeStyles({
  root: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px #0000001F',
    border: '1px solid #F0F0F0',
    width: 670,
    height: 'auto',
    borderRadius: 8,
    '&:focus-visible': {
      outline: 'none',
    },
  },
  title: {
    padding: '40px 35px 20px',
    fontSize: 15,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0 35px 25px',
    '& button': {
      fontSize: 14,
      fontWeight: 500,
      borderRadius: 4,
      padding: '8px 16px',
    },
  },
  cansel: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    marginRight: 10,
    color: '#333333',
    '&.Mui-disabled': {
      color: '#00000038',
      borderBottom: '1px solid #D8D8D8',
    },
  },
  ok: {
    background: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    color: '#FFFFFF',
    '&:hover': {
      borderBottom: '1px solid #F4F4F4',
    },
    '&.Mui-disabled': {
      color: '#00000038',
      background: '#D8D8D8',
      borderBottom: '1px solid #D8D8D8',
    },
  },
});

type propType = {
  open: boolean;
  handleClose: () => void;
  handleOK?: () => void;
  title: React.ReactNode;
  cansel?: string | null;
  ok?: string;
  buttonDisabled?: boolean;
};

/** 確認ダイアログ(UI) */
const Dialog = ({
  open,
  handleClose,
  handleOK,
  title,
  cansel = '',
  ok,
  buttonDisabled = false,
}: propType): JSX.Element => {
  const classes = useStyle();
  return (
    <Modal
      className={classes.root}
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.buttons}>
          {cansel && (
            <Button
              className={classes.cansel}
              onClick={handleClose}
              disabled={buttonDisabled}
            >
              {cansel || 'キャンセル'}
            </Button>
          )}
          {ok && (
            <Button
              className={classes.ok}
              onClick={() => {
                if (handleOK) handleOK();
                handleClose();
              }}
              disabled={buttonDisabled}
            >
              {ok || 'OK'}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default Dialog;
