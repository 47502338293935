import { SetPamalink } from '../../types/API';
/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/** プレイヤー／チャットパーマリンク作成API Mockレスポンス */
const PlayerTag = ({
  width,
  height,
  chat_width,
  chat_height,
  chat_autoresize_flg,
}: {
  width: number;
  height: number;
  chat_width: number;
  chat_height: number;
  chat_autoresize_flg?: boolean;
}): SetPamalink => {
  return {
    errorCode: 0,
    message: 'OK',
    playersList: [...Array(10)].map((x, i) => {
      return {
        pid: i,
        name: `プレイヤー名-${i}`,
      };
    }),
    pubsitesList: [...Array(10)].map((x, i) => {
      return {
        ppsid: i,
        name: `掲載サイト別管理-${i}`,
      };
    }),
    selectedPlayer: {
      pid: 1,
      ppsid: 2,
      width,
      height,
      responsive: 1,
      // NOTE: 本来はひとつづきの文字列でエスケープ文字'\n'で改行が入っている
      script: {
        tag: `<script type=\"text/javascript\" charset=\"UTF-8\" src=\"https://ssl-eq-test.stream.co.jp/www50/eqc111test/jmc_pub/jmc_swf/liveplayer/t3/obj.js\"></script>
              <script type=\"text/javascript\">
              \tjstream_t3.PlayerFactoryLiveOBJ.create({
              \tb:\"eqc111test.eq-test.webcdn.stream.ne.jp/www50/eqc111test/jmc_pub/jmc_swf/liveplayer/\",
              \tc:\"Mjk5MA==\",
              \tlpid:\"NTc3\",
              \ts:{
              \t\tskb:\"90\",
              \t\tcc:\"off\",
              \t\tcp:\"off\",
              \t\tdq:\"92\",
              \t\tel:\"off\",
              \t\tfs:\"off\",
              \t\tfskb:\"90\",
              \t\thp:${height},
              \t\tis:\"off\",
              \t\tmb:\"off\",
              \t\tmdq:\"90\",
              \t\tpb:\"off\",
              \t\tpr:\"\",
              \t\tprl:\"off\",
              \t\trs:\"on\",
              \t\tsb:\"off\",
              \t\tsn:\"t\",
              \t\tvc:\"off\",
              \t\twm:\"off\",
              \t\twp:${width}
              \t}
              });
              </script>
              <noscript>
              <div>このコンテンツを見るためにはJavaScriptを有効にしてください。Please enable JavaScript to watch this content.</div>
              </noscript>\n`,
        preview: `https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169%230%232E04480FC000%23OzkwOzkw%23`,
        largerTag: null,
      },
      iframe: {
        tag: `<iframe src=\"https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169%230%232E04488FC000%23OzkwOzkw%23/%230%232E04488FC000%23OzkwOzkw%23/chat/190%23258%230%231%231%230\" width=\"1041\" height=\"600\"  frameborder=\"0\" allowfullscreen></iframe>`,
        preview: `https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169%230%232E04480FC000%23OzkwOzkw%23`,
        largerTag: `<iframe src=\"https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169\" width=\"1041\" height=\"600\"  frameborder=\"0\" allowfullscreen></iframe>`,
      },
      permalink: {
        tag: `https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169%230%232E04480FC000%23OzkwOzkw%23/chat/190%23258%230%231%231%230`,
        preview: `https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169%230%232E04480FC000%23OzkwOzkw%23`,
        largerTag: `<iframe src=\"https://api99-platform.stream.co.jp/apiservice/lplt3/Mjk5MA%3d%3d%23NTc3%23281%23169%230%232E04488FC000%23OzkwOzkw%23/chat/190%23258%230%231%231%230\" width=\"1041\" height=\"600\"  frameborder=\"0\" allowfullscreen></iframe>`,
      },
      chatOnly: {
        tag: chat_autoresize_flg
          ? `<iframe src=\"https://ssl-eq-test.stream.co.jp/www50/chat/jmc_pub/client.html?cid=Mjk5MA%3D%3D&lpid=NTc3&b=http%3A%2F%2Feqy005test.eq-test.webcdn.stream.ne.jp%2Fwww50%2Feqy005test%2Fjmc_pub%2Fjmc_swf%2Fliveplayer%2F&em=both&icon1=1&icon2=1\" \r\n style=\"width:100%;height:100%;border: none;\"></iframe>`
          : `<iframe src=\"https://ssl-eq-test.stream.co.jp/www50/chat/jmc_pub/client.html?cid=Mjk5MA%3D%3D&lpid=NTc3&b=http%3A%2F%2Feqy005test.eq-test.webcdn.stream.ne.jp%2Fwww50%2Feqy005test%2Fjmc_pub%2Fjmc_swf%2Fliveplayer%2F&em=both&icon1=1&icon2=1\" \r\n style=\"width:${chat_width}px;height:${chat_height}px;border: none;\"></iframe>`,
        preview: `https://ssl-eq-test.stream.co.jp/www50/chat/jmc_pub/client.html?cid=Mjk5MA%3D%3D&lpid=NTc3&b=http%3A%2F%2Feqy005test.eq-test.webcdn.stream.ne.jp%2Fwww50%2Feqy005test%2Fjmc_pub%2Fjmc_swf%2Fliveplayer%2F&em=both&icon1=1&icon2=1`,
        largerTag: null,
      },
      chatWidth: 480,
      chatHeight: 360,
      chatAutoResize: 1,
      refererPolicy: 1,
    },
  };
};

export default PlayerTag;
