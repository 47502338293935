/** 解像度別m3u8作成API Mockレスポンス */
const MakeM3U8 = {
  streamId: {
    master: 'master',
    low: 'low',
    standard: 'standard',
    high: 'high',
    hd: 'hd',
    fhd: 'fhd',
    original: 'original',
  },
  streamIdMain: {
    master: 'master',
    low: 'low',
    standard: 'standard',
    high: 'high',
    hd: 'hd',
    fhd: 'fhd',
    original: 'original',
  },
  streamIdBack: {
    master: 'master',
    low: 'low',
    standard: 'standard',
    high: 'high',
    hd: 'hd',
    fhd: 'fhd',
    original: 'original',
  },
};

export default MakeM3U8;
