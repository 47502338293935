import moment from 'moment';

const eventStatus = [
  0, // 録画待機中
  100, // 録画中
  180, // 分割処理中
  190, // 停止API実行中
  200, // 録画終了
  210, // マスター変換中
  220, // マスター変換完了
  300, // トランスコード中
  900, // トランスコード完了
  970, // マスター変換エラー
];

/** ライブイベント情報取得API Mockレスポンス */
const LiveEvent = {
  errorCode: 0,
  message: '',
  cid: 1,
  eventsum: 10,
  lpid: 1,

  /** イベント情報リスト */
  events: [...Array(10)].map((x, i) => {
    const onairTime = moment()
      .add(-3, 'hours')
      .format('YYYY-MM-DDTHH:mm:ss.SS');
    const feedoutTime = moment()
      .add(1, 'hours')
      .format('YYYY-MM-DDTHH:mm:ss.SS');
    const dvrCloseDate = moment()
      .add(2, 'hours')
      .format('YYYY-MM-DDTHH:mm:ss.SS');
    return {
      leid: i,
      eventName: `イベント名-${i}`,
      eventStatus: eventStatus[i],
      mode: 1,
      mainStatus: 0,
      mainInquiryFlg: null,
      mainFeedflgFhd: 0,
      mainFeedflgHd: 0,
      mainFeedflgHigh: 0,
      mainFeedflgMid: 0,
      mainFeedflgLow: 0,
      mainFeedinCnt: 1,
      mainFeedinTime: onairTime,
      mainOnairTime: onairTime,
      mainOffairTime: onairTime,
      mainFeedoutTime: feedoutTime,
      mainMasterconvertTime: onairTime,
      mainRecfileSize: 0,
      mainRecFeed: 0,
      subStatus: 0,
      subInquiryFlg: null,
      subFeedflgFhd: 0,
      subFeedflgHd: 0,
      subFeedflgHigh: 0,
      subFeedflgMid: 0,
      subFeedflgLow: 0,
      subFeedinCnt: 1,
      subFeedinTime: onairTime,
      subOnairTime: onairTime,
      subOffairTime: onairTime,
      subFeedoutTime: onairTime,
      subMasterconvertTime: onairTime,
      subRecfileSize: 0,
      subRecFeed: 0,
      connectmax: 100,
      currentConnectmax: 100,
      maxOnairMinutes: 240,
      vodFlg: 0,
      masterconvertTime: onairTime,
      transcodeTime: onairTime,
      mid: '363,364',
      serverIp: '192.168.0.1',
      uid: 1,
      autostartFlg: 1,
      pvId: 1,
      splitCnt: 1,
      splitMaxCnt: 9,
      insertDate: onairTime,
      deleteDate: null,
      updateDate: onairTime,
      deleteFlg: 1,
      chatId: 1234,
      chatUrl:
        'https://chat-svc-03acc784-b3e0-4c83-bdd6-45d3791e246a.stg-chat.cloud.stream.co.jp',
      cumulativeMax: 0,
      dvrCloseDate,
      chatRoomId: '',
      chatRoomAdminId: 0,
      chatRoomStatus: 0,
    };
  }),
};

export default LiveEvent;
