import { GetFileToLiveMovie, QualityOfMovie } from '../../types/API';

/** 疑似ライブ利用動画取得API Mockレスポンス */
const FileToLiveMovie = ({
  mid,
  qualityFlg,
}: {
  mid: string;
  qualityFlg?: 0 | 1;
}): GetFileToLiveMovie => {
  const pcQuality: QualityOfMovie = {
    fhd: { activeFlg: 1 },
    hd: { activeFlg: 1 },
    high: { activeFlg: 0 },
    standard: { activeFlg: 0 },
    low: { activeFlg: 1 },
  };
  const spQuality: QualityOfMovie = {
    fhd: { activeFlg: 1 },
    hd: { activeFlg: 1 },
    high: { activeFlg: 0 },
    standard: { activeFlg: 0 },
    low: { activeFlg: 1 },
  };
  const movies = [...Array(100)].map((x, i) => {
    return {
      mid: i + 1,
      title: `疑似ライブ 動画-${i + 1}`,
      duration: 60 + i * 60,
      pcQuality: qualityFlg ? pcQuality : undefined,
      spQuality: qualityFlg ? spQuality : undefined,
    };
  });
  return {
    sum: mid
      ? movies.filter((movie) => String(movie.mid) === String(mid)).length
      : 100,
    /** 利用可能動画リスト */
    movies: mid
      ? movies.filter((movie) => String(movie.mid) === String(mid))
      : movies,
  };
};

export default FileToLiveMovie;
