import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useTracking } from './GoogleAnalytics';

const MiddleRoute = ({ path, component, ...rest }: RouteProps): JSX.Element => {
  useTracking(process.env.REACT_APP_GA4_ID);
  return <Route {...rest} path={path} component={component} />;
};

export default MiddleRoute;
