import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  CONNECTION_GRAPH_ERR_MSG,
  GET_COUNT_API_ERR_MSG,
  NOT_PARAMETER_ERR_MSG,
} from '../Message';
import { setError } from '../../features/uiSlice';
import { setHistoryCount } from '../../features/historiesSlice';
import { NOT_FOUND } from '../Const';
import { GetLiveConnections } from '../../types/API';

type getCountType = {
  cid: number;
  lpid: number;
  leid: number;
  timeInterval?: number;
  from?: number;
  to?: number;
};
/** ライブ同時接続取得 */
const getCount =
  (arg: getCountType): AppThunk =>
  async (dispatch) => {
    try {
      // パラメータに不足がある場合はエラー
      if (!arg.from || !arg.to) {
        const noparam: string[] = [];
        if (!arg.from) {
          noparam.push('ライブ開始時刻');
        }
        if (!arg.to) {
          noparam.push('ライブ停止時刻');
        }
        dispatch(
          setError({
            msg: CONNECTION_GRAPH_ERR_MSG,
            detail: `${NOT_PARAMETER_ERR_MSG}: ${noparam.join()}`,
          })
        );
        return;
      }
      // ライブ同時接続取得API実行
      const response: AxiosResponse<GetLiveConnections> = await axios.get(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getCount`,
        {
          params: {
            cid: arg.cid,
            lpid: arg.lpid,
            leid: arg.leid,
            timeInterval: arg.timeInterval || 30,
            from: arg.from,
            to: arg.to,
          },
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // 正常終了
      dispatch(setHistoryCount(response.data));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response?.data?.statusCode === NOT_FOUND) return;
      // 異常終了
      dispatch(
        setError({
          msg: GET_COUNT_API_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    }
  };

export default getCount;
