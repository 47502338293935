import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ArrowDropUp,
  ArrowDropDown,
  KeyboardArrowRight,
} from '@material-ui/icons';
import moment from 'moment';
import {
  HistoryListProps,
  TableLiveProfileArch,
} from '../../props/HistoryList';
import {
  ROUTE,
  getPath,
  SORT_STATUS,
  HISTORY_LIST_SORT_PROPERTY,
  SORTLIST_LIVELOGSTATUS_ASCE,
  SORTLIST_LIVELOGSTATUS_DESCE,
  MIN_WIDTH,
  LIVE_LOG_STATUS,
} from '../../utils/Const';
import Badge from '../parts/Badge/Badge';
import Tooltip from '../parts/Tooltip/Tooltip';

const SortArrow = ({
  sort,
  active = false,
}: {
  sort?: SORT_STATUS;
  active?: boolean;
}): JSX.Element => {
  return (
    <Box display="inline-flex" flexDirection="column">
      <ArrowDropUp
        fontSize="small"
        style={{
          marginBottom: '-6px',
          opacity: sort === SORT_STATUS.DESCE && active ? '1' : '0.3',
        }}
      />
      <ArrowDropDown
        fontSize="small"
        style={{
          marginTop: '-7px',
          opacity: sort === SORT_STATUS.ASCE && active ? '1' : '0.3',
        }}
      />
    </Box>
  );
};

const CustomButtomView = ({
  title,
  style,
  onClick,
  className,
}: {
  title: string;
  style?: object;
  onClick?: () => void;
  className?: string;
}): JSX.Element => {
  return (
    <Button style={{ ...style }} className={className} onClick={onClick}>
      {title}
      <KeyboardArrowRight
        fontSize="small"
        style={{ marginLeft: 4, width: 16, height: 16 }}
      />
    </Button>
  );
};

/** スタイル */
const useStyle = makeStyles({
  root: {
    marginTop: 20,
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
    width: MIN_WIDTH,
    boxSizing: 'border-box',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '@media (min-width: 1501px)': {
      width: `calc(100vw - (1500px - ${MIN_WIDTH}px))`,
    },
    '&.windows': {
      '@media (min-width: 1501px)': {
        width: `calc(100vw - (1500px - ${MIN_WIDTH}px + 20px))`,
      },
    },
  },
  header: {
    background: '#333333',
    color: '#FFFFFF',
  },
  headerCell: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 700,
    borderRight: '1px solid #E0E0E04A',
    padding: '16px 4px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  bodyCell: {
    fontSize: 14,
    borderRight: '1px solid #E0E0E0',
    padding: '10px 8px',
    '&:last-child': {
      borderRight: 'none',
    },
    '& a': {
      color: '#4472C4',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
  },
  checkbox: {
    color: '#999999',
    '&.Mui-checked': {
      color: '#4A8BFB',
    },
  },
  sortArrow: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  recMids: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: 4,
    fontSize: 12,
    '& .tooltip': {
      bottom: '-40px',
      left: 0,
      '& span::before': {
        top: '-35px',
        left: '50%',
        marginLeft: '10px',
        border: 'none',
        borderRight: '8px solid transparent',
        borderLeft: '8px solid transparent',
        borderBottom: '10px solid #333333',
      },
    },
    '&:hover .tooltip': {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateY(0)',
      marginLeft: 'auto',
    },
  },
  button: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 14,
    color: '#333333',
    transition: 'all 400ms',
    '&:hover': {
      background: '#333333',
      '& .MuiButton-label': {
        color: '#FFFFFF',
      },
    },
  },
});

type propsType = {
  props: HistoryListProps;
  handleGraph: (
    lplid: string,
    liveLogStatus: LIVE_LOG_STATUS,
    dvrCloseDate: string | null
  ) => void;
  limit: number;
  page: number;
  handleSort: () => void;
};

/** ライブイベント履歴リスト */
const HistoryListTable = ({
  props,
  handleGraph,
  limit,
  page,
  handleSort,
}: propsType): JSX.Element => {
  const classes = useStyle();
  const os = window.navigator.userAgent.toLowerCase();
  const { downloadClientsLogCSV, downloadChatLogCSV } = props;

  // NOTE: 明示化するために名前変更
  const sortedList = props.liveProfileArch;

  // State
  /** 適用中のソートを制御 */
  const [sortProperty, setSortProperty] = useState<HISTORY_LIST_SORT_PROPERTY>(
    HISTORY_LIST_SORT_PROPERTY.EVENT_DATE
  );
  /** ライブログIDのソートを制御 */
  const [sortPropertyLplid, setSortPropertyLplid] = useState<SORT_STATUS>(
    SORT_STATUS.NONE
  );
  /** ライブログステータスのソートを制御 */
  const [sortPropertyLiveLogStatus, setSortPropertyLiveLogStatus] =
    useState<SORT_STATUS>(SORT_STATUS.NONE);
  /** ライブ開催日のソートを制御 */
  const [sortPropertyDate, setSortPropertyDate] = useState<SORT_STATUS>(
    SORT_STATUS.ASCE
  );
  /** ページネーション対応リスト */
  const [pagedList, setPagedList] = useState<TableLiveProfileArch[]>(
    sortedList.slice(0, limit)
  );

  // ソート機能・イベント
  /** ライブログIDのソート：降順、昇順 */
  const sortLplid = () => {
    setSortProperty(HISTORY_LIST_SORT_PROPERTY.LPLID);
    if (
      sortPropertyLplid === SORT_STATUS.DESCE ||
      sortPropertyLplid === SORT_STATUS.NONE
    ) {
      setSortPropertyLplid(SORT_STATUS.ASCE);
      sortedList.sort((a, b) => {
        const AId = a.lplid.split('-').map((x) => Number(x));
        const BId = b.lplid.split('-').map((x) => Number(x));
        if (AId[0] === BId[0]) return AId[1] < BId[1] ? 1 : -1;
        return AId[0] < BId[0] ? 1 : -1;
      });
    } else if (sortPropertyLplid === SORT_STATUS.ASCE) {
      setSortPropertyLplid(SORT_STATUS.DESCE);
      sortedList.sort((a, b) => {
        const AId = a.lplid.split('-').map((x) => Number(x));
        const BId = b.lplid.split('-').map((x) => Number(x));
        if (AId[0] === BId[0]) return AId[1] > BId[1] ? 1 : -1;
        return AId[0] > BId[0] ? 1 : -1;
      });
    }
    handleSort();
    setPagedList(sortedList.slice(0, limit));
  };

  /** ライブログステータスのソート
   * 昇順：トランスコード完了, VODトランスコードエラー, トランスコード中, マスター変換完了, マスター変換エラー,
   *      マスター変換中, VODアップロードエラー, 録画終了, 録画停止処理中, 録画延長処理中, 録画中,
   *      録画待機中, 不明なエラー
   * 降順：不明なエラー, 録画待機中, 録画中, 録画延長処理中, 録画停止処理中, 録画終了, VODアップロードエラー,
   *      マスター変換中, マスター変換エラー, マスター変換完了, トランスコード中, VODトランスコードエラー,
   *      トランスコード完了
   * */
  const sortLiveLogStatus = () => {
    setSortProperty(HISTORY_LIST_SORT_PROPERTY.STATUS);
    if (
      sortPropertyLiveLogStatus === SORT_STATUS.DESCE ||
      sortPropertyLiveLogStatus === SORT_STATUS.NONE
    ) {
      setSortPropertyLiveLogStatus(SORT_STATUS.ASCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_LIVELOGSTATUS_ASCE.indexOf(a.liveLogStatus) -
          SORTLIST_LIVELOGSTATUS_ASCE.indexOf(b.liveLogStatus)
      );
    } else if (sortPropertyLiveLogStatus === SORT_STATUS.ASCE) {
      setSortPropertyLiveLogStatus(SORT_STATUS.DESCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_LIVELOGSTATUS_DESCE.indexOf(a.liveLogStatus) -
          SORTLIST_LIVELOGSTATUS_DESCE.indexOf(b.liveLogStatus)
      );
    }
    handleSort();
    setPagedList(sortedList.slice(0, limit));
  };

  /** ライブ開催日付のソート：降順、昇順 */
  const sortEventDate = () => {
    setSortProperty(HISTORY_LIST_SORT_PROPERTY.EVENT_DATE);
    if (sortPropertyDate === SORT_STATUS.DESCE) {
      setSortPropertyDate(SORT_STATUS.ASCE);
      sortedList.sort((a, b) =>
        moment(
          // eslint-disable-next-line no-nested-ternary
          a.eventOnair
            ? a.eventOnair
            : a.eventOffair
            ? a.eventOffair
            : '1997/05/29 00:00:00'
        ).isBefore(
          moment(
            // eslint-disable-next-line no-nested-ternary
            b.eventOnair
              ? b.eventOnair
              : b.eventOffair
              ? b.eventOffair
              : '1997/05/29 00:00:00'
          )
        )
          ? 1
          : -1
      );
    } else if (
      sortPropertyDate === SORT_STATUS.ASCE ||
      sortPropertyDate === SORT_STATUS.NONE
    ) {
      setSortPropertyDate(SORT_STATUS.DESCE);
      sortedList.sort((a, b) =>
        moment(
          // eslint-disable-next-line no-nested-ternary
          a.eventOnair
            ? a.eventOnair
            : a.eventOffair
            ? a.eventOffair
            : '1997/05/29 00:00:00'
        ).isAfter(
          moment(
            // eslint-disable-next-line no-nested-ternary
            b.eventOnair
              ? b.eventOnair
              : b.eventOffair
              ? b.eventOffair
              : '1997/05/29 00:00:00'
          )
        )
          ? 1
          : -1
      );
    }
    handleSort();
    setPagedList(sortedList.slice(0, limit));
  };

  /** 表示範囲変更の対応 */
  useEffect(() => {
    setPagedList(sortedList.slice((page - 1) * limit, page * limit));
  }, [page, limit]);

  return (
    <TableContainer
      component={Paper}
      className={`${classes.root} ${
        os.indexOf('windows nt') !== -1 ? 'windows' : ''
      }`}
    >
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell className={classes.headerCell} width="7%">
              <Box display="flex" alignItems="center" justifyContent="center">
                ライブ
                <br />
                ログID
                <Box onClick={sortLplid} className={classes.sortArrow}>
                  <SortArrow
                    sort={sortPropertyLplid}
                    active={sortProperty === HISTORY_LIST_SORT_PROPERTY.LPLID}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="15.2%"
            >
              ステータス
              <Box onClick={sortLiveLogStatus} className={classes.sortArrow}>
                <SortArrow
                  sort={sortPropertyLiveLogStatus}
                  active={sortProperty === HISTORY_LIST_SORT_PROPERTY.STATUS}
                />
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="32.9%"
            >
              ライブ履歴名
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="12.5%"
            >
              開催日時
              <Box onClick={sortEventDate} className={classes.sortArrow}>
                <SortArrow
                  sort={sortPropertyDate}
                  active={
                    sortProperty === HISTORY_LIST_SORT_PROPERTY.EVENT_DATE
                  }
                />
              </Box>
            </TableCell>
            <TableCell align="center" className={classes.headerCell} width="6%">
              配信時間
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="17.7%"
            >
              同時接続数
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="8.7%"
            >
              チャット投稿
              <br />
              一覧
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props &&
            [...pagedList].map((live) => (
              <TableRow key={live.lplid}>
                <TableCell align="center" className={classes.bodyCell}>
                  {live.lplid}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <Badge title={live.liveLogStatus} />
                  {live.recMids?.length > 0 && (
                    <span className={classes.recMids}>
                      {live.recMids?.slice(0, 5).join(',')}
                      {live.recMids?.length > 5 && '...'}
                      {live.recMids?.length > 5 && (
                        <Tooltip message={live.recMids.join(',')} />
                      )}
                    </span>
                  )}
                </TableCell>
                <TableCell
                  className={classes.bodyCell}
                  style={{ maxWidth: '0.28vw' }}
                >
                  <Link
                    to={`${getPath(ROUTE.HISTORY_CONTENT)}?lplid=${
                      live.lplid
                    }&c=${props.cid}&t=${props.readAPIToken}&u=${
                      props.userApiParams
                    }`}
                    style={{ color: '#4472C4' }}
                  >
                    {live.eventName ? live.eventName : live.eventOnair ?? ''}
                  </Link>
                </TableCell>
                <TableCell align="center" className={classes.bodyCell}>
                  {live.eventOnair}
                  <br />
                  {live.eventOffair}
                </TableCell>
                <TableCell align="center" className={classes.bodyCell}>
                  {live.eventTime}
                </TableCell>
                <TableCell align="center" className={classes.bodyCell}>
                  <CustomButtomView
                    title="グラフ表示"
                    style={{ marginRight: 8 }}
                    onClick={() =>
                      handleGraph(
                        live.lplid,
                        live.liveLogStatus,
                        live.dvrCloseDate
                      )
                    }
                    className={classes.button}
                  />
                  <CustomButtomView
                    title="CSV出力"
                    style={{ width: 94 }}
                    onClick={() =>
                      downloadClientsLogCSV(
                        live.lplid,
                        live.liveLogStatus,
                        live.dvrCloseDate,
                        live.eventOnair,
                        live.eventOffair
                      )
                    }
                    className={classes.button}
                  />
                </TableCell>
                <TableCell align="center" className={classes.bodyCell}>
                  {live.chat && (
                    <CustomButtomView
                      title="CSV出力"
                      onClick={() => downloadChatLogCSV(live.lplid)}
                      className={classes.button}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoryListTable;
