// アプリ全体で使用するメッセージ

/** リファラーチェック: エラーメッセージ */
export const REFERRER_ERR_MSG = '不正な遷移です';

/** パスチェック: エラーメッセージ */
export const PATH_ERR_MSG = '不正なパスです';

/** クエリチェック: エラーメッセージ */
export const QUERY_ERR_MSG = 'URLに必須パラメータがありません';

/** 日付チェック: エラーメッセージ */
export const DATE_ERR_MSG = '有効な日付を入力してください';

/** 認証API: エラーメッセージ */
export const AUTH_API_ERR_MSG = '認証APIの実行に失敗しました';

/** 契約会社情報取得API: エラーメッセージ */
export const CONTRACTGET_API_ERR_MSG =
  '契約会社情報取得APIの実行に失敗しました';

/** JCloudサーバー: エラーメッセージ */
export const JCLOUDSERVER_ERR_MSG = 'Jcloudサーバーエラー';

/** JCloudサーバー設定(wlives)取得API: エラーメッセージ */
export const JCLOUDSERVERWLIVESGET_API_ERR_MSG =
  'JCloudサーバー設定(wlives)取得APIの実行に失敗しました';

/** JCloudサーバー設定(hls-auth)取得API: エラーメッセージ */
export const JCLOUDSERVERHLSAUTHGET_API_ERR_MSG =
  'JCloudサーバー設定(hls-auth)取得APIの実行に失敗しました';

/** 画面初期表示: エラーメッセージ */
export const INIT_FLOW_ERR_MSG = '画面初期表示の処理に失敗しました';

/** ライブ新規登録画面表示フロー:  エラーメッセージ */
export const LIVE_ADD_INIT_FLOW_ERR_MSG =
  'ライブ新規登録画面表示処理に失敗しました';

/** ライブ新規登録画面保存処理フロー:  エラーメッセージ */
export const LIVE_ADD_SAVE_FLOW_ERR_MSG = 'ライブ新規登録処理に失敗しました';

/** ライブ詳細画面表示フロー:  エラーメッセージ */
export const LIVE_UPDATE_INIT_FLOW_ERR_MSG =
  'ライブ詳細画面表示処理に失敗しました';

/** ライブ詳細画面保存処理フロー:  エラーメッセージ */
export const LIVE_UPDATE_SAVE_FLOW_ERR_MSG = 'ライブ更新処理に失敗しました';

/** ライブ一覧取得フロー:  エラーメッセージ */
export const LIVELIST_FLOW_ERR_MSG = 'ライブ一覧取得処理に失敗しました';

/** ライブプロファイル削除フロー:  エラーメッセージ */
export const LIVESDELETE_FLOW_ERR_MSG =
  'ライブプロファイル削除処理に失敗しました';

/** ライブエンコーダー設定画面表示フロー:  エラーメッセージ */
export const LIVEENCODER_FLOW_ERR_MSG =
  'エンコーダー設定画面表示処理に失敗しました';

/** ライブ共通タグ画面表示フロー:  エラーメッセージ */
export const LIVESHARE_FLOW_ERR_MSG = '共通タグ画面表示処理に失敗しました';

/** ライブ共通タグ画面更新フロー: エラーメッセージ */
export const LIVESHAREUPDATE_FLOW_ERR_MSG =
  '共通タグ画面更新処理に失敗しました';

/** コントロールパネル画面表示フロー(超過費用チェック):  エラーメッセージ */
export const LIVECONTROLLER_CHECK_LIVE_ERR_MSG =
  '超過費用チェックに失敗しました';

/** コントロールパネル画面表示フロー(フィードの監視):  エラーメッセージ */
export const LIVECONTROLLER_CHECK_WOWZA_FEED_MSG =
  'フィードチェックに失敗しました';

/** コントロールパネル画面表示フロー:  エラーメッセージ */
export const LIVECONTROLLER_FLOW_ERR_MSG =
  'コントロールパネル画面表示処理に失敗しました';

/** ライブ配信設定履歴画面表示フロー:  エラーメッセージ */
export const LIVE_HISTORY_INIT_FLOW_ERR_MSG =
  'ライブ配信設定履歴表示処理に失敗しました';

/** ライブ同時接続数グラフ表示:  エラーメッセージ */
export const CONNECTION_GRAPH_ERR_MSG =
  'ライブ同時接続数グラフ表示処理に失敗しました';

/** ライブ配信開始フロー:  エラーメッセージ */
export const LIVE_STATUS_OPEN_ERR_MSG = 'ライブ配信開始に失敗しました';

/** ライブ配信終了フロー:  エラーメッセージ */
export const LIVE_STATUS_CLOSE_ERR_MSG = 'ライブ配信終了に失敗しました';

/** チャット手動開始フロー:  エラーメッセージ */
export const CHAT_MANUAL_OPEN_ERR_MSG = 'チャット手動開始に失敗しました';

/** チャット手動停止フロー:  エラーメッセージ */
export const CHAT_MANUAL_CLOSE_ERR_MSG = 'チャット手動停止に失敗しました';

/** ジョブ発行API:  エラーメッセージ */
export const JOBDISPATCH_API_ERR_MSG = 'ジョブ発行APIの実行に失敗しました';

/** 解像度別m3u8作成API: エラーメッセージ */
export const RESOLUION_URL_API_ERR_MSG =
  '解像度別m3u8作成APIの実行に失敗しました';

/** ライブチャット投稿履歴取得API: エラーメッセージ */
export const GET_LIVE_CHAT_MSG_API_ERR_MSG =
  'ライブチャット投稿履歴取得APIの実行に失敗しました';

/** モニタリング画面表示フロー: エラーメッセージ */
export const LIVEMONITER_FLOW_ERR_MSG =
  'モニタリング画面表示処理に失敗しました';

/** ライブチャット投稿履歴CSV出力フロー:  エラーメッセージ */
export const DOWNLOAD_CHAT_LOG_CSV_FLOW_ERR_MSG =
  'ライブチャット投稿履歴のCVS出力処理に失敗しました';

/** ライブ同時接続取CSV出力フロー:  エラーメッセージ */
export const DOWNLOAD_CLIENTS_LOG_CSV_FLOW_ERR_MSG =
  'ライブ同時接続取のCVS出力処理に失敗しました';

/** ライブ分割API: エラーメッセージ */
export const ARCHIVE_SPLIT_API_ERR_MSG = 'ライブ分割APIの実行に失敗しました';

/** ライブイベント情報取得API: エラーメッセージ */
export const GET_LIVE_EVENT_API_ERR_MSG =
  'ライブイベント情報取得APIの実行に失敗しました';

/** ライブイベント情報更新API: エラーメッセージ */
export const SET_LIVE_EVENT_API_ERR_MSG =
  'ライブイベント情報更新APIの実行に失敗しました';

/** Wowzaフィード確認API: エラーメッセージ */
export const GET_WOWZA_FEED_API_ERR_MSG =
  'Wowzaフィード確認APIの実行に失敗しました';

/** ライブイベント履歴画面表示フロー: エラーメッセージ */
export const HISTORYLIST_FLOW_ERR_MSG =
  'ライブイベント履歴画面表示処理に失敗しました';

/** ライブ履歴取得API: エラーメッセージ */
export const GET_HISTORY_LIST_API_ERR_MSG =
  'ライブ履歴取得APIの実行に失敗しました';

/** ライブ同時接続取得API: エラーメッセージ */
export const GET_COUNT_API_ERR_MSG =
  'ライブ同時接続取得APIの実行に失敗しました';

/** パラメータ不足: エラーメッセージ */
export const NOT_PARAMETER_ERR_MSG = '必要なパラメータが不足しています';

/** wlivesId = null エラーメッセージ */
export const WLIVES_ID_IS_NULL_MSG = 'wlivesIdがnullです';

/** ライブプロファイルが見つからない エラーメッセージ */
export const LIVEPROFILE_NOT_FIND_ERR_MSG =
  'ライブプロファイルが見つかりません';

/** JCLoudがアクティベートではない エラーメッセージ */
export const NOT_ACTIVATE_ERR_MSG = '配信可能なステータスでは有りません';

/** 疑似ライブ一覧取得フロー:  エラーメッセージ */
export const FILETOLIVELIST_FLOW_ERR_MSG =
  '疑似ライブ一覧取得処理に失敗しました';

/** 疑似ライブプロファイル削除フロー:  エラーメッセージ */
export const FILETOLIVESDELETE_FLOW_ERR_MSG =
  '疑似ライブプロファイル削除処理に失敗しました';

/** 疑似ライブプロファイル取得:  エラーメッセージ */
export const FILETOLIVES_GET_ERR_MSG =
  '疑似ライブプロファイル取得処理に失敗しました';

/** 疑似ライブ同時接続取得API: エラーメッセージ */
export const GET_FILE_TO_LIVE_COUNT_API_ERR_MSG =
  '疑似ライブ同時接続取得APIの実行に失敗しました';

/** 疑似ライブ共通タグ画面表示フロー:  エラーメッセージ */
export const FILETOLIVESHARE_FLOW_ERR_MSG =
  '共通タグ画面表示処理に失敗しました';

/** 疑似ライブ共通タグ画面更新フロー: エラーメッセージ */
export const FILETOLIVESHAREUPDATE_FLOW_ERR_MSG =
  '共通タグ画面更新処理に失敗しました';

/** 疑似ライブ同時接続数グラフ表示:  エラーメッセージ */
export const FILE_TO_LIVE_CONNECTION_GRAPH_ERR_MSG =
  '疑似ライブ同時接続数グラフ表示処理に失敗しました';

/** 疑似ライブ利用可能動画一覧取得フロー:  エラーメッセージ */
export const FILE_TO_LIVE_GETMOVIE_FLOW_ERR_MSG =
  '利用可能動画一覧取得処理に失敗しました';

/** 疑似ライブ新規登録: 動画未選択エラーメッセージ */
export const FILE_TO_LIVE_UNSELECTED_MID_MSG =
  '動画IDをリストから選択してください';

export const FILE_SEARCH_MID_TEXT_STRING_MSG =
  '動画IDは半角数字でご入力ください';

/** 疑似ライブ新規登録: 開始日時エラーメッセージ */
export const FILE_TO_LIVE_START_DATETIME_INVALID_MSG =
  '開始日時は現在の日時から30分以降で設定してください';

/** 疑似ライブ新規登録画面表示フロー:  エラーメッセージ */
export const FILE_TO_LIVE_ADD_INIT_FLOW_ERR_MSG =
  '疑似ライブ新規登録画面表示処理に失敗しました';

/** 疑似ライブ詳細画面表示フロー:  エラーメッセージ */
export const FILE_TO_LIVE_UPDATE_INIT_FLOW_ERR_MSG =
  '疑似ライブ詳細画面表示処理に失敗しました';

/** 疑似ライブ詳細画面保存処理フロー:  エラーメッセージ */
export const FILE_TO_LIVE_UPDATE_SAVE_FLOW_ERR_MSG =
  '疑似ライブ更新処理に失敗しました';

/** バリデーション: 必須エラーメッセージ */
export const REQUIRED_MSG = '必須項目です';

/** バリデーション: 最大値エラーメッセージ */
export const getMaxValueMsg = (value: number): string => {
  return `最大値は${value}です`;
};

/** バリデーション: 最小値エラーメッセージ */
export const getMinValueMsg = (value: number): string => {
  return `最小値は${value}です`;
};

/** バリデーション: パターンエラーメッセージ */
export const getPattarnMsg = (title: string): string => {
  return `${title}で入力してください`;
};

/** react-dropzoneエラーメッセージ */
export enum DROP_ZONE_ERR_MSG {
  NONE = '',
  TOO_MANY_FILES = 'ファイルが複数アップロードされています',
  FILE_INVALID_TYPE = '許可されていないファイルがアップロードされました',
  FILE_TOO_LARGE = 'ファイルサイズが大き過ぎます',
  FILE_TOO_SMALL = 'ファイルサイズが小さ過ぎます',
  HEIGHT_TOO_SMALL = '画像の高さが小さ過ぎます',
}

/** ライブプロファイル作成・更新APIエラーメッセージ */
export enum LIVE_PROFILE_ERR_MSG {
  NONE = '',
  MSG_7000 = '一部のみ更新（ライブ配信準備中時、一部設定登録不可）',
  MSG_9021 = 'パラメータエラー',
  MSG_9022 = '未対応サムネイル',
  MSG_9502 = 'サムネイルアップロード中エラー',
  MSG_9503 = '削除済みまたは、登録されていない',
  MSG_9512 = 'ライブ中またはライブ準備中更新NGエラー',
  MSG_9516 = 'ライブ設定上限超過エラー',
  MSG_9519 = '有効なグループIDなし',
  MSG_9520 = '有効なトランスコードIDなし',
  MSG_9521 = 'ライブ同時接続数上限超過エラー',
  MSG_9522 = '有効なセキュリティプロファイルIDなし',
  MSG_9525 = 'ライブ自動画質設定プロファイルIDなし',
  MSG_99999 = 'その他システムエラー',
}

/** 疑似ライブプロファイル作成・更新APIエラーメッセージ */
export enum FILE_TO_LIVE_PROFILE_ERR_MSG {
  NONE = '',
  MSG_9021 = 'パラメータエラー',
  MSG_9503 = '削除済みまたは、登録されていない',
  MSG_9512 = 'ライブ中またはライブ準備中更新NGエラー',
  MSG_9516 = 'ライブ設定上限超過エラー',
  MSG_9521 = 'ライブ同時接続数上限超過エラー',
  MSG_9527 = '登録できないフィラー映像',
  MSG_99999 = 'その他システムエラー',
}

export const API_LIVE_ALREADY_START_ERR_MSG = '既にライブ開始しています';

export const API_LIVE_STATUS_DEACTIVATED_ERR_MSG =
  'ステータスがdeactivatedです';

export const API_STOP_ERR_MDG = '既にライブ停止中です';

export const LIVE_START_ERR_MSG =
  'このライブ設定は現在ライブ配信中のため、ご利用いただけません。<br/>「ライブステータス」が「配信停止中」になりましたらご利用いただけますので、<br/>「ライブステータス」をご確認の上、配信を行ってください。';

export const LIVE_STOP_ERR_MSG =
  'このライブ設定はすでにライブ配信が終了しているため、「配信停止中」の状態です。<br/>ページをリロードいただくか、ライブ設定一覧画面の「ライブステータス」をご確認ください。';
