import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Box,
  Select,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
import { Close, Help, InfoOutlined, ZoomIn } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { LiveShareProps } from '../../props/LiveShare';
import ShackBar from '../parts/SnackBar/SnackBar';
import EmbeddedSize from './EmbeddedSize';
import ChatEmbeddedSize from './ChatEmbeddedSize';
import OtherSetting from './OtherSetting';
import ShareBottom from './ShareBottom';
import {
  LIVESAHRE_TABS,
  LIVESHARE_PLAYERSIZE,
  UI_STATUS,
  MIN_WIDTH,
} from '../../utils/Const';
import { RootState } from '../../app/store';
import LargerViewModal from './LargerView';
import Tooltip from '../parts/Tooltip/Tooltip';

/** スタイル */
const useStyle = makeStyles({
  root: {
    width: MIN_WIDTH,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    background: '#FFFFFF',
    borderRadius: 8,
    boxShadow: '0px 4px 16px #0000001F',
    lineHeight: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  progress: {
    width: MIN_WIDTH,
    height: '80vh',
    background: '#FFFFFF',
    borderRadius: 8,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    fontSize: 20,
    '& h3': {
      margin: '20px 0',
    },
  },
  body: {
    padding: '10px 40px 0',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    '& h4': {
      fontSize: 15,
      fontWeight: 500,
      borderLeft: '2px solid #61B227',
      paddingLeft: 20,
      margin: '15px 0 15px',
      lineHeight: '27px',
      color: '#333333',
    },
  },
  input: {
    background: '#FFFFFF',
    marginRight: 4,
    '& .MuiOutlinedInput-input': {
      padding: '6px 10px',
      lineHeight: '28px',
      fontSize: 14,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 'calc(100% - 35px)',
    },
  },
  icon: {
    color: '#C32733',
    marginRight: 4,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: '#C32733',
  },
  bodyLeft: {
    width: 650,
    height: 'calc(100vh - 520px)',
    overflowY: 'scroll',
    paddingRight: 20,
    paddingBottom: 20,
    boxSizing: 'border-box',
    msOverflowStyle: 'none',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
    '& .MuiRadio-root': {
      padding: '8px 4px',
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  attention: {
    display: 'flex',
    color: '#C32733',
    marginTop: 6,
  },
  bodyRight: {
    position: 'relative',
    width: 520,
    height: 'calc(100vh - 518px)',
    maxHeight: 364,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '20px 20px 10px',
    marginBottom: '-30px',
    boxSizing: 'border-box',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      content: '""',
      border: '1px solid #F0F0F0',
      borderRadius: 6,
      width: 518,
      height: 362,
    },
  },
  bodyBottom: {
    background: '#FAFAFA',
    borderRadius: '0px 0px 8px 8px',
    minHeight: 300,
    padding: '25px 39px',
    margin: '0 -1px',
    boxSizing: 'border-box',
  },
  selectPpsid: {
    background: '#FFFFFF',
    marginRight: 4,
    minWidth: 277,
    zIndex: 99,
    '& .MuiOutlinedInput-input': {
      padding: '4px 10px',
      lineHeight: '17px',
      fontSize: 12,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 'calc(100% - 35px)',
    },
  },
  helpContainer: {
    position: 'relative',
    zIndex: 99,
    '& .tooltip': {
      transform: 'translateY(-3px)',
    },
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      top: 32,
      marginBottom: '-110px',
      transform: 'translateY(0px)',
    },
    '&:hover .balloon': {
      top: '-72px',
      marginLeft: '-3px',
      '&::before': {
        borderTop: 'none',
        borderBottom: '15px solid #333333',
      },
    },
  },
  help: {
    color: '#B6BEC9',
    marginLeft: 2,
    marginBottom: '-2px',
  },
  zoomIn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    cursor: 'pointer',
    zIndex: 99,
  },
  preview: {
    width: LIVESHARE_PLAYERSIZE.WIDTH,
    height: LIVESHARE_PLAYERSIZE.HEIGHT,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 'auto',
    justifyContent: 'center',
    boxShadow: '0px 4px 12px #1C385E0C',
  },
  chatPreview: {
    display: 'flex',
    alignItems: 'center',
  },
  playerIframe: {
    display: 'inline',
    position: 'relative',
    transformOrigin: '0 0',
  },
  saving: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: '100%',
    background: '#ffffff80',
  },
  completed: {
    display: 'none',
  },
});

const CalcScalePreview = (width: number, height: number) => {
  const scaleWidth = LIVESHARE_PLAYERSIZE.WIDTH / width;
  const scaleHeight = LIVESHARE_PLAYERSIZE.HEIGHT / height;
  if (Math.abs(1 - scaleHeight) >= Math.abs(1 - scaleWidth)) {
    return scaleHeight;
  }
  return scaleWidth;
};

type propsType = {
  open: boolean;
  props: LiveShareProps;
  onClose: () => void;
};

/** 共通タグ(UI) */
const LiveShareComponent = ({
  open,
  props,
  onClose,
}: propsType): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const {
    choicePlayerProfile,
    setOpenPreview,
    choicePubSiteProfile,
    playerProfiles,
    pubSitesProfiles,
    pid,
    seekbar,
    ppsid,
    selectTab,
    setSelectTab,
    skipBtn,
    chatenable,
    previewPlayerRender,
    previewPlayerRef,
    previewChat,
    previewChatWidth,
    previewChatHeight,
    largePreviewChatWidth,
    largePreviewChatHeight,
    largerPreviewChat,
    openPreview,
    previewPlayerWidth,
    previewPlayerHeight,
    largerPlayerRef,
    snackbarInfo,
    handleSnackbarClose,
  } = props;
  const merginWidth =
    (LIVESHARE_PLAYERSIZE.WIDTH -
      previewPlayerWidth *
        CalcScalePreview(previewPlayerWidth, previewPlayerHeight)) /
    2;
  const merginHeight =
    (LIVESHARE_PLAYERSIZE.HEIGHT -
      previewPlayerHeight *
        CalcScalePreview(previewPlayerWidth, previewPlayerHeight)) /
    2;
  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      {status.liveShare === UI_STATUS.PROCESSING ? (
        <>
          <Box className={classes.progress}>
            <LinearProgress />
          </Box>
        </>
      ) : (
        <Box className={classes.container}>
          <Box
            className={`${classes.progress} ${
              status.liveShare === UI_STATUS.SAVING
                ? classes.saving
                : classes.completed
            }`}
          >
            <LinearProgress />
          </Box>
          <Box className={classes.header}>
            <h3>ライブ共通タグ取得</h3>
            <Close onClick={onClose} style={{ cursor: 'pointer' }} />
          </Box>
          <Box className={classes.body}>
            <Box className={classes.bodyLeft}>
              <h4>使用するプレイヤーまたは掲載サイト別管理設定を選択</h4>
              <Select
                value={pid}
                onChange={choicePlayerProfile}
                variant="outlined"
                className={classes.input}
                style={{ minWidth: 300 }}
                autoWidth
              >
                {playerProfiles.map((player) => (
                  <MenuItem key={player.pid} value={player.pid}>
                    {player.profileName}
                  </MenuItem>
                ))}
              </Select>
              {!seekbar && !skipBtn && (
                <Box className={classes.attention}>
                  <InfoOutlined fontSize="small" style={{ marginRight: 6 }} />
                  <span style={{ marginTop: 4 }}>
                    シークバー非表示のプレイヤーのためDVR操作ができません。
                  </span>
                </Box>
              )}
              {selectTab !== LIVESAHRE_TABS.CHAT_TAG && (
                <EmbeddedSize props={props} />
              )}
              {selectTab !== LIVESAHRE_TABS.SCRIPT && chatenable && (
                <ChatEmbeddedSize props={props} selectTab={selectTab} />
              )}
              <OtherSetting props={props} />
            </Box>
            <Box className={classes.bodyRight}>
              <Box
                style={{
                  borderBottom: '1px solid #EFEFEF',
                  paddingBottom: 15,
                }}
              >
                選択中のプレイヤープレビュー
                <span style={{ opacity: 0.6, fontSize: 13 }}>
                  （実際の表示サイズは反映されません）
                </span>
              </Box>
              {selectTab !== LIVESAHRE_TABS.CHAT_TAG && (
                <>
                  {previewPlayerRender && (
                    <Box className={classes.preview}>
                      <iframe
                        referrerPolicy="no-referrer-when-downgrade"
                        title="playerIframe"
                        id="playerIframe"
                        allowFullScreen
                        frameBorder="0"
                        className={classes.playerIframe}
                        ref={previewPlayerRef}
                        style={{
                          width: previewPlayerWidth,
                          height: previewPlayerHeight,
                          transform: `scale(${CalcScalePreview(
                            previewPlayerWidth,
                            previewPlayerHeight
                          )})`,
                          margin: `${merginHeight}px ${merginWidth}px`,
                          zIndex: 99,
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
              {selectTab === LIVESAHRE_TABS.CHAT_TAG && (
                <Box className={`${classes.preview} ${classes.chatPreview}`}>
                  <Box
                    dangerouslySetInnerHTML={{ __html: previewChat ?? '' }}
                    style={{
                      width: previewChatWidth,
                      height: previewChatHeight,
                      transform: `scale(${CalcScalePreview(
                        previewChatWidth,
                        previewChatHeight
                      )})`,
                    }}
                  />
                </Box>
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 4px',
                }}
              >
                {pid === -1 && (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                      value={ppsid}
                      onChange={choicePubSiteProfile}
                      variant="outlined"
                      className={classes.selectPpsid}
                      autoWidth
                    >
                      {pubSitesProfiles.map((pubSite) => (
                        <MenuItem key={pubSite.ppsid} value={pubSite.ppsid}>
                          {pubSite.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <span className={classes.helpContainer}>
                      <Help fontSize="small" className={classes.help} />
                      <Tooltip
                        message={
                          <>
                            プレビューしたい掲載サイトを選択して下さい。
                            <br />
                            実際のプレイヤーは掲載サイトURLで指定された設定が反映されます。
                          </>
                        }
                      />
                    </span>
                  </Box>
                )}
                {chatenable && selectTab !== LIVESAHRE_TABS.CHAT_TAG && (
                  <Box
                    onClick={() => setOpenPreview(true)}
                    className={classes.zoomIn}
                  >
                    <ZoomIn />
                    拡大する
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.bodyBottom}>
            <ShareBottom
              props={props}
              selectTab={selectTab}
              setSelectTab={setSelectTab}
            />
          </Box>
          <LargerViewModal
            open={openPreview}
            handleClose={() => setOpenPreview(false)}
            title="拡大プレビュープレイヤー"
            playerRef={largerPlayerRef}
            selectTabFlg={selectTab === LIVESAHRE_TABS.SCRIPT}
            videoWidth={previewPlayerWidth}
            videoHeight={previewPlayerHeight}
            chatWidth={largePreviewChatWidth}
            chatHeight={largePreviewChatHeight}
            chatTag={largerPreviewChat}
          />
          <ShackBar
            infomations={snackbarInfo}
            handleClose={handleSnackbarClose}
          />
        </Box>
      )}
    </Modal>
  );
};

export default LiveShareComponent;
