import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

/** スタイル */
const useStyle = makeStyles({
  root: {
    position: 'absolute',
    background: '#333333',
    color: '#FFFFFF',
    padding: '10px 7px',
    marginLeft: '-110px',
    width: 240,
    bottom: 30,
    borderRadius: 4,
    fontSize: 12,
    visibility: 'hidden',
    opacity: 0,
    transform: 'translateY(3px)',
    transition: 'transform 400ms ease',
  },
  message: {},
  balloon: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '103px',
      border: '15px solid transparent',
      borderTop: '15px solid #333333',
    },
  },
});

type propType = {
  message: string | JSX.Element;
  style?: React.CSSProperties;
};

/** ツールチップ */
const Tooltip = ({ message, style }: propType): JSX.Element => {
  const classes = useStyle();
  return (
    <Box className={`tooltip ${classes.root}`} style={style}>
      <Box className={classes.message}>{message}</Box>
      <span className={`balloon ${classes.balloon}`} />
    </Box>
  );
};

export default Tooltip;
