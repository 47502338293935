import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  LIVE_STATUS_CLOSE_ERR_MSG,
  API_STOP_ERR_MDG,
} from '../../utils/Message';
import { setError, setLiveControllerStatus } from '../../features/uiSlice';
import { UI_STATUS } from '../../utils/Const';
import { GetLiveEvent, GetLiveProfile } from '../../types/API';
import {
  addEQLiveProfile,
  setLiveEventProfile,
} from '../../features/livesSlice';

type LiveStopParams = {
  wlivesId: string;
  lpid: number;
  cid: number;
  userApiParams: string;
  limit: number;
  dvrLifetime: number;
  vod: number;
  automode: boolean;
  customerDir: string;
  chat: {
    enabled: 0 | 1;
    closeTiming: number;
  };
  errorCallback: () => void;
};

/** ライブ配信停止フロー */
const LiveStop =
  (arg: LiveStopParams): AppThunk =>
  async (dispatch, getState) => {
    // 配信処理中をセット
    dispatch(setLiveControllerStatus(UI_STATUS.PROCESSING_STREAM));
    try {
      // ライブ停止API実行
      await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}stopLive`,
        arg,
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
        {
          lpid: arg.lpid,
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          pageSize: 1,
          portal: getState().user.contract.serviceFlg39 ? 1 : 0,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

      // ライブイベント情報取得API実行
      const response: AxiosResponse<GetLiveEvent> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
        { cid: arg.cid, userApiParams: arg.userApiParams, lpid: arg.lpid },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setLiveEventProfile({ ...response.data }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.response?.data?.statusCode === 500 &&
        error.response?.data?.message === API_STOP_ERR_MDG
      ) {
        // ライブ停止中だった場合はstatusを更新する
        // ライブプロファイル取得API実行
        const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
          {
            lpid: arg.lpid,
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            pageSize: 1,
            portal: getState().user.contract.serviceFlg39 ? 1 : 0,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

        // ライブイベント情報取得API実行
        const response: AxiosResponse<GetLiveEvent> = await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
          { cid: arg.cid, userApiParams: arg.userApiParams, lpid: arg.lpid },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        dispatch(setLiveEventProfile({ ...response.data }));

        // エラーダイアログの表示
        arg.errorCallback();
      } else {
        // 異常終了
        dispatch(
          setError({
            msg: LIVE_STATUS_CLOSE_ERR_MSG,
            detail: JSON.stringify(error.response?.data || error.message),
          })
        );
      }
    } finally {
      dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default LiveStop;
