import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../app/store';
import HistoryContentComponent from '../../components/HistoryContent/HistoryContentComponent';
import {
  ContentBaseSetting,
  ChatSetting,
  LiveEventInfo,
} from '../../props/LiveContent';
import { SecuritySetting } from '../../props/LiveRegister';
import { ExLinkSetting } from '../../types/API';
import { QUERY_ERR_MSG } from '../../utils/Message';
import {
  getStreamType,
  getStreams,
  getExLinkInit,
  getEndTiming,
  getAutoEndMinutes,
  getPortalMetaInit,
} from '../../utils/ChangeContentData';
import { setError } from '../../features/uiSlice';
import { selectHistoryLivesById } from '../../features/historiesSlice';
import HistoryContentInit from './HistoryContentInit';

/** ライブ配信設定履歴 */
const HistoryContentContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // クエリチェック
  const searchString = window.location.search;
  const params = new URLSearchParams(searchString);
  const lplid = String(params.get('lplid'));
  if (!lplid) {
    dispatch(
      setError({
        msg: QUERY_ERR_MSG,
        detail: '',
      })
    );
  }

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const event = useSelector((state: RootState) =>
    selectHistoryLivesById(state, lplid)
  );
  const { qualityHistory } = useSelector((state: RootState) => state.histories);

  // 変数抽出
  const { edition, liveInfo } = user.contract;
  const { maxClient, nowClientCnt } = liveInfo;

  // 初期処理
  useEffect(() => {
    dispatch(HistoryContentInit(lplid));
  }, []);

  // 基本設定
  const baseSetting: ContentBaseSetting = {
    liveABRProfiles: user.liveabr,
    transcodeProfiles: user.transcode,
    groupProfiles: user.group,
    nowClientCnt,
    maxClient,
    lpid: Number(event?.lplid?.split('-')[0]) ?? 0,
    liveName: event?.liveName ?? '',
    liveDescription: event?.liveDescription ?? '',
    maxClientLimit: event?.maxClient ?? 0,
    streamType: getStreamType(
      event?.liveappFlg ? 1 : 0,
      event?.desktopEncoder ? 1 : 0,
      event?.lqpid ?? 0
    ),
    lqpid: event?.lqpid ?? 0,
    streams: getStreams(
      event?.bitrateHd ?? 0,
      event?.bitrateHigh ?? 0,
      event?.bitrateStandard ?? 0,
      event?.bitrateLow ?? 0
    ),
    encrypt: Boolean(event?.encrypt) ?? false,
    liveDVRProfile: {
      flg: Boolean(event?.dvr),
      immediation: event?.dvrCloseMinutes === 1 || event?.dvrCloseMinutes === 0,
      lifeTime:
        // eslint-disable-next-line no-nested-ternary
        event?.dvrCloseMinutes === undefined
          ? undefined
          : event?.dvrCloseMinutes >= 3
          ? event?.dvrCloseMinutes
          : undefined,
    },
    vodFlg: event?.vodFlg ?? 0,
    mpid: event?.mpid ?? 0,
    groupId: event?.groupId ?? 0,
    quality: qualityHistory || null,
  };

  // 動画からのリンク
  const exLinkSetting: ExLinkSetting = event?.exlink ?? getExLinkInit();

  // セキュリティ
  const securitySetting: SecuritySetting = {
    viewLimitsProfiles: user.viewLimitsProfile,
    vlpid: event?.vlpid ?? 0,
  };

  // チャット設定
  const chatSetting: ChatSetting = {
    enabled: Boolean(event?.chat?.enabled),
    autoResize: Boolean(event?.chat?.autoResizeFlg),
    size: {
      width: event?.chat?.width ?? 0,
      height: event?.chat?.height ?? 0,
    },
    startTiming: event?.chat?.openTiming ?? 0,
    endTiming: getEndTiming(event?.chat?.closeTiming ?? 0),
    autoEndMinutes: getAutoEndMinutes(event?.chat?.closeTiming ?? 0),
    blockType: event?.chat?.blockType ?? 0,
    enterMsg: event?.chat?.enterMsg ?? 0,
    goodBtn: Boolean(event?.chat?.goodBtn),
    troubleBtn: Boolean(event?.chat?.troubleBtn),
    customBtn1: {
      enable: event?.chat.iconTexts
        ? Boolean(event?.chat.iconTexts[0]?.activeFlg)
        : false,
      name: event?.chat.iconTexts ? event?.chat.iconTexts[0]?.text : '',
    },
    customBtn2: {
      enable: event?.chat.iconTexts
        ? Boolean(event?.chat.iconTexts[1]?.activeFlg)
        : false,
      name: event?.chat.iconTexts ? event?.chat.iconTexts[1]?.text : '',
    },
    adminDisplayName: event?.chat?.adminDisplayName ?? '',
    blockWords: event?.chat?.blockWords.join(',') ?? '',
  };

  // ライブイベント情報
  const liveEventInfo: LiveEventInfo = event?.portalMeta ?? getPortalMetaInit();

  return (
    <HistoryContentComponent
      edition={edition}
      encryptEdition={Boolean(liveInfo.encrypt)}
      baseSetting={baseSetting}
      exLinkSetting={exLinkSetting}
      securitySetting={securitySetting}
      chatSetting={chatSetting}
      liveEventInfo={liveEventInfo}
    />
  );
};

export default HistoryContentContainer;
