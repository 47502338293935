import React, { useState } from 'react';
import {
  FormControl,
  Input,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import CopyToClipBoard from 'react-copy-to-clipboard';
import FileCopyIcon from '../../../images/ContentCopy.svg';
import { CopyButtonTextBoxProps } from '../../../props/Parts';

/** コピーボタン付きテキストボックス */
const CopyButtonTextBox = ({
  width,
  value,
  rows = 1,
}: CopyButtonTextBoxProps): JSX.Element => {
  const [openTip, setOpenTip] = useState<boolean>(false);

  const handleCloseTip = (): void => {
    setOpenTip(false);
  };

  const handleClickButton = (): void => {
    setOpenTip(true);
  };

  return (
    <FormControl variant="outlined">
      <Input
        type="text"
        style={{
          width,
          color: '#333333',
          background: '#FAFAFA 0% 0% no-repeat padding-box',
          borderRadius: 4,
          font: 'normal normal normal 13px/18px Roboto Mono',
          letterSpacing: 0,
          paddingLeft: '8px 20px',
          paddingRight: 10,
        }}
        value={value}
        multiline={rows > 1}
        rows={rows}
        disabled
        disableUnderline
        endAdornment={
          <InputAdornment position="end">
            <Tooltip
              arrow
              open={openTip}
              onClose={handleCloseTip}
              disableHoverListener
              placement="top"
              title="コピーしました"
            >
              <CopyToClipBoard text={value}>
                <IconButton onClick={handleClickButton}>
                  <img
                    src={FileCopyIcon}
                    style={{ width: 12, height: 14, opacity: 0.3 }}
                    alt="copy"
                  />
                </IconButton>
              </CopyToClipBoard>
            </Tooltip>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default CopyButtonTextBox;
