import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { Close, Warning, Error } from '@material-ui/icons';
import moment from 'moment';
import { SNACKBAR_TYPE } from '../../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {
    position: 'fixed',
    right: 10,
    top: 10,
    zIndex: 10,
  },
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 10px #1C385E17',
    border: '1px solid #D1D9CA',
    borderRadius: 6,
    width: 360,
    marginBottom: 10,
    padding: '22px 20px',
    position: 'relative',
    '&.first': {
      animation: `$fadeIn ease 400ms 1 normal backwards`,
    },
    '&.close': {
      animation: `$fadeOut ease 350ms 1 normal forwards`,
    },
  },
  '@keyframes fadeIn': {
    from: {
      transform: 'translateX(360px)',
    },
    to: {
      transform: 'translateX(0px)',
    },
  },
  '@keyframes fadeOut': {
    from: {
      transform: 'translateX(0px)',
    },
    to: {
      transform: 'translateX(500px)',
    },
  },
  title: {
    fontSize: 14,
    display: 'flex',
    fontWeight: 700,
    '& svg': {
      marginRight: 15,
    },
    '&.info': {
      color: '#5390FB',
    },
    '&.error': {
      color: '#CB2016',
    },
    '&.warning': {
      color: '#B46F00',
    },
    '&.success': {
      color: '#407917',
    },
  },
  info: {
    fontSize: 12,
    color: '#333333B3',
    marginLeft: 30,
    marginTop: 5,
    width: 260,
  },
  button: {
    position: 'absolute',
    top: 22,
    right: 15,
    padding: 0,
    minWidth: 12,
    color: '#D1D9CA',
    '&.first': {
      color: '#333333',
    },
  },
});

type infoType = {
  title: string | React.ReactNode;
  info?: string | React.ReactNode;
  type: SNACKBAR_TYPE;
  time?: string;
};

type propType = {
  infomations: infoType[];
  handleClose: (index: number) => void;
};

/** スナックバー(UI) */
const SnackBar = ({ infomations, handleClose }: propType): JSX.Element => {
  const classes = useStyle();
  /** 表示中のスナックバー */
  const [displayInformation, setDisplayInformation] = useState<infoType | null>(
    null
  );
  /** アニメーションフラグ */
  const [closeAnimation, setCloseAnimation] = useState<boolean>(false);
  /** スナックバー表示処理中フラグ */
  const openProcessing = useRef(false);
  /** スナックバー削除処理中フラグ */
  const closeProcessing = useRef(false);

  // スナックバー表示処理
  const openSnackBar = (info: infoType) => {
    openProcessing.current = true;
    const add = () => {
      handleClose(0);
      setDisplayInformation(info);
      openProcessing.current = false;
    };
    window.setTimeout(add, 500);
  };

  // スナックバー削除処理
  const closeSnackBar = () => {
    if (closeProcessing.current) return;
    closeProcessing.current = true;
    setCloseAnimation(true);

    const close = () => {
      if (!openProcessing.current) {
        setDisplayInformation(null);
      }
      setCloseAnimation(false);

      if (infomations.length) {
        const info = infomations[0];
        // 削除処理の時間分、表示時間を延長
        openSnackBar({
          ...info,
          time: moment(info.time).add(500, 'milliseconds').toString(),
        });
      }
      closeProcessing.current = false;
    };
    window.setTimeout(close, 500);
  };

  useEffect(() => {
    if (infomations.length) {
      if (displayInformation) {
        // 表示中のスナックバー削除処理
        if (!closeProcessing.current) {
          if (displayInformation.title !== infomations[0].title) {
            closeSnackBar();
          }
        } else {
          // 削除処理が完了するまで待つ。削除処理の時間分、表示時間を延長。
          window.setTimeout(() => {
            const info = infomations[0];
            openSnackBar({
              ...info,
              time: moment(info.time).add(500, 'milliseconds').toString(),
            });
          }, 500);
        }
      } else {
        // スナックバー追加処理
        openSnackBar(infomations[0]);
      }
    }
  }, [infomations]);

  useEffect(() => {
    if (!displayInformation) return () => null;
    // 生成の3秒後に自動削除
    const interval = setInterval(() => {
      if (
        moment(displayInformation.time)
          .add(3, 'seconds')
          .isBefore(moment(), 'seconds')
      ) {
        closeSnackBar();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [displayInformation]);

  if (displayInformation)
    return (
      <Box className={classes.root}>
        {displayInformation && (
          <Box
            className={`${classes.container} first ${
              closeAnimation ? 'close' : ''
            }`}
          >
            <Box
              className={`${
                classes.title
              } ${displayInformation.type.toString()}`}
            >
              {displayInformation.type !== SNACKBAR_TYPE.WARNING && (
                <Error fontSize="small" />
              )}
              {displayInformation.type === SNACKBAR_TYPE.WARNING && (
                <Warning fontSize="small" />
              )}
              {displayInformation.title}
            </Box>
            <Button className={`${classes.button} first`}>
              <Close onClick={() => closeSnackBar()} fontSize="small" />
            </Button>
            {displayInformation.info && (
              <Box className={classes.info}>{displayInformation.info}</Box>
            )}
          </Box>
        )}
      </Box>
    );
  return <></>;
};

export default SnackBar;
