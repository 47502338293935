import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import type { RootState, AppDispatch } from '../../app/store';
import {
  selectAllEQFileToLives,
  setFaildDeleateFileToLiveId,
} from '../../features/fileToLivesSlice';
import {
  setFileToLiveContentStatus,
  setFileToLiveRegisterStatus,
} from '../../features/uiSlice';
import { getPath, ROUTE, UI_STATUS } from '../../utils/Const';
import FileToLiveListComponent from '../../components/FlieToLiveList/FileToLiveListComponent';
import { useAppSelector } from '../../app/hooks';
import { ToFileToLiveStatusJap } from '../../utils/ChangeStatus';
import { deleteFileToLivesFlow } from './DeleteLives';
import { getFileToLiveListFlow } from './GetFileToLiveList';
import {
  downloadFileToLiveChatLogCSVFlow,
  downloadFileToLiveClientsLogCSVFlow,
} from './CsvDownload';
import { TableFileToLiveProfile } from '../../props/FileToLiveList';

/** ライブの配信設定と開催 */
const FileToLiveListContainer = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const flivesbase = useSelector((state: RootState) => state.fileToLives);
  const EQFileToLives = useAppSelector(selectAllEQFileToLives);

  // 変数抽出
  const { edition, cid, readAPIToken, liveInfo } = user.contract;
  const {
    cntLive,
    maxClient,
    filetoLiveNowCnt,
    filetoliveCnt,
    filetoLiveNowClientCnt,
    filetoliveAddCnt,
  } = liveInfo;
  const { liveSum, faildDeleateFileToLiveId } = flivesbase;

  // state
  /** チェックされたライブID */
  const [checkedFlpids, setCheckedFlpids] = useState<number[]>([]);
  /** 削除されたライブID */
  const [succeedDeleteFlpids, setSucceedDeleteFlpids] = useState<number[]>([]);
  /** テーブルで使用するプロファイルデータ */
  const [tableFileToLiveProfile, setTableFileToLiveProfile] = useState<
    TableFileToLiveProfile[]
  >([]);
  /** チャットCSV404ダイアログ表示有無 */
  const [notFoundChatlogDialog, setNotFoundChatlogDialog] =
    useState<boolean>(false);

  // イベント
  /** 疑似ライブの新規登録へ遷移 */
  const goFileToLiveLiveRegister = () => {
    history.replace({
      pathname: getPath(ROUTE.FILE_TO_LIVE_REGISTER),
      search: `?c=${user.contract.cid}&t=${user.contract.readAPIToken}&u=${user.user}`,
    });
  };

  /** 削除イベント */
  const deleteFileToLiveProfileEvent = (ids: number[]) => {
    // 全チェックを外す
    setCheckedFlpids([]);

    // 削除フロー実行
    dispatch(
      deleteFileToLivesFlow({
        cid,
        userApiParams: user.user,
        readAPIToken,
        deleteflpids: ids,
        callback: setSucceedDeleteFlpids,
      })
    );
  };

  /** 削除失敗ダイアログクローズイベント */
  const closeFaildDellFpidDialog = () => {
    setSucceedDeleteFlpids([]);
    dispatch(setFaildDeleateFileToLiveId({ failed: [] }));
  };

  // 初期処理
  useEffect(() => {
    // 新規登録と詳細のステータスを初期化
    dispatch(setFileToLiveRegisterStatus(UI_STATUS.PROCESSING));
    dispatch(setFileToLiveContentStatus(UI_STATUS.PROCESSING));
    dispatch(getFileToLiveListFlow({ cid, userApiParams: user.user }));
  }, []);

  // Redux Stateの疑似ライブプロファイルの更新で駆動
  useEffect(() => {
    // ComponentへのPropsの生成
    const fileToLiveProfiles = EQFileToLives.map((profile) => ({
      flpid: profile.flpid,
      mid: profile.mid,
      liveName: profile.liveName,
      maxClient: profile.maxClient,
      liveStatus: ToFileToLiveStatusJap(profile.status),
      startDate: profile.startDate,
      closeDate: profile.closeDate,
      chat: Boolean(profile.chat.enabled),
      chatStatus: profile.chatRoomStatus,
    }));
    setTableFileToLiveProfile(fileToLiveProfiles);
  }, [EQFileToLives]);

  // 同時接続数のCSV出力
  const downloadClientsLogCSV = (flpid: number, from: string, to: string) => {
    dispatch(
      downloadFileToLiveClientsLogCSVFlow({
        cid,
        flpid,
        timeInterval: 30,
        from: Number(moment(from).format('YYYYMMDDHHmmss')),
        to: Number(moment(to).format('YYYYMMDDHHmmss')),
      })
    );
  };

  // チャットログのCSV出力
  const downloadChatLogCSV = (flpid: number) => {
    dispatch(
      downloadFileToLiveChatLogCSVFlow({
        readAPIToken,
        flpid,
        setNotFoundChatlogDialog,
      })
    );
  };

  /** 疑似ライブ予約上限数 */
  const filetoliveCntSum = filetoliveCnt + filetoliveAddCnt;

  return (
    <FileToLiveListComponent
      msg="通知用メッセージ"
      deleteFileToLiveProfileEvent={deleteFileToLiveProfileEvent}
      closeFaildDellFlpidDialog={closeFaildDellFpidDialog}
      goFileToLiveLiveRegister={goFileToLiveLiveRegister}
      checkedFlpids={checkedFlpids}
      setCheckedFlpids={setCheckedFlpids}
      faildDeleateFileToLiveId={faildDeleateFileToLiveId}
      succeedDeleateFileToLiveId={succeedDeleteFlpids}
      edition={edition}
      cid={cid}
      readAPIToken={readAPIToken}
      userApiParams={user.user}
      maxLive={cntLive}
      maxContractClient={maxClient}
      filetoLiveNowClientCnt={filetoLiveNowClientCnt}
      filetoLiveNowCnt={filetoLiveNowCnt}
      filetoliveCntSum={filetoliveCntSum}
      liveSum={liveSum}
      notFoundChatlogDialog={notFoundChatlogDialog}
      setNotFoundChatlogDialog={setNotFoundChatlogDialog}
      fileToLiveProfiles={tableFileToLiveProfile}
      downloadClientsLogCSV={downloadClientsLogCSV}
      downloadChatLogCSV={downloadChatLogCSV}
    />
  );
};

export default FileToLiveListContainer;
