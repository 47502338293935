import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  EQFileToLiveProfile,
  GetContract,
  GetFileToLiveProfile,
} from '../../types/API';

import { setError, setFileToLiveListStatus } from '../../features/uiSlice';
import { setContract } from '../../features/userSlice';
import { setEQFileToLiveProfile } from '../../features/fileToLivesSlice';
import { FILETOLIVELIST_FLOW_ERR_MSG } from '../../utils/Message';
import { MAX_PAGESIZE, UI_STATUS } from '../../utils/Const';

export type GetFileToLiveListFlowParams = {
  cid: number;
  userApiParams: string;
};
/** 疑似ライブプロファイル取得フロー */
export const getFileToLiveListFlow =
  (arg: GetFileToLiveListFlowParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFileToLiveListStatus(UI_STATUS.PROCESSING));

      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setContract({ ...responseGetContract.data }));

      // 疑似ライブプロファイル取得API実行
      const response: AxiosResponse<GetFileToLiveProfile> = await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getProfile`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          pageSize: MAX_PAGESIZE,
          pageNumber: 1,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      let liveProfilesGetted: EQFileToLiveProfile[] = response.data.profile;

      // 100件以上のプロファイルがある場合に全件取得
      const loopTimes = [
        ...Array(
          response.data.livesum
            ? Math.ceil(response.data.livesum / MAX_PAGESIZE) - 1
            : 0
        ),
      ].map((x, i) => i + 2);
      await Promise.all(
        loopTimes.map(async (pageNumber) => {
          // 疑似ライブプロファイル取得API実行
          const response: AxiosResponse<GetFileToLiveProfile> =
            await axios.post(
              `${String(
                process.env.REACT_APP_BACKEND_API_ENDPOINT
              )}filetolive/getProfile`,
              {
                cid: arg.cid,
                userApiParams: arg.userApiParams,
                pageSize: MAX_PAGESIZE,
                pageNumber,
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
          liveProfilesGetted = liveProfilesGetted.concat(response.data.profile);
        })
      );

      dispatch(
        setEQFileToLiveProfile({
          livesum: response.data.livesum,
          profile: liveProfilesGetted,
        })
      );

      // 画面描画開始
      dispatch(setFileToLiveListStatus(UI_STATUS.COMPLETED));

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: FILETOLIVELIST_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setFileToLiveListStatus(UI_STATUS.COMPLETED));
    }
  };
