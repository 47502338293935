export type JsPlayerPropsType = {
  playerRef: React.RefObject<HTMLIFrameElement>;
  player: {
    /** プレイヤー生成要素 タグID */
    target: string;
    /** 配信URL */
    src: string;
    /** 幅 */
    width?: number;
    /** 高さ */
    height?: number;
    /** 映像種別 */
    mode: 'vod' | 'live' | 'dvr';
    /** ミュート */
    muted: 0 | 1;
    /** ポスター画像URL */
    poster: string;
  };
};

export const LoadJsPlayer = (props: JsPlayerPropsType): void => {
  const iframeDocument = props.playerRef.current?.contentDocument;
  iframeDocument?.open();
  iframeDocument?.write(
    '<!DOCTYPE html><html><head></head><body style="margin: 0;"></body></html>'
  );
  iframeDocument?.close();

  // divタグ宣言（ここにプレイヤーを描画）
  const divElement = document.createElement('div');
  divElement.id = props.player.target;
  // divタグをbodyに追加
  iframeDocument?.body.appendChild(divElement);

  const scriptTag = document.createElement('script');
  /** jsplayer.jsのpath */
  // scriptTag.src = `${String(process.env.PUBLIC_URL)}js/jsplayer.js`;
  scriptTag.src = `${window.location.origin}/js/custom_jsplayer.js`;

  scriptTag.type = 'text/javascript';
  scriptTag.async = true;
  iframeDocument?.body.appendChild(scriptTag);
  // NOTE: js/jsplayer.jsの読み込み後に以下の処理（同期処理）
  scriptTag.onload = () => {
    // scriptタグの宣言
    const scriptTag2 = document.createElement('script');
    scriptTag2.type = 'text/javascript';
    // プレイヤー取得
    const exec = document.createTextNode(`
      player = new JsPlayer(
        ${JSON.stringify(props.player)
          .replace(/"([^"]+)":/g, '$1:')
          .replace(/\uFFFF/g, '\\"')}
      );
    `);
    // プレイヤーをscriptタグに追加
    scriptTag2?.appendChild(exec);
    // scriptタグをbodyに追加
    iframeDocument?.body.appendChild(scriptTag2);
  };
  // scriptタグをheadに追加
  iframeDocument?.head.appendChild(scriptTag);
};
