import { QR_FORMAT } from '../Const';

/** QRコード生成情報取得API Mockレスポンス */
const QRProfile = {
  size: 500,
  dpi: 350,
  format: QR_FORMAT.PNG,
};

export default QRProfile;
