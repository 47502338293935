import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { setLiveEvent } from '../../features/historiesSlice';
import { setError, setHistoryListStatus } from '../../features/uiSlice';
import { setContract } from '../../features/userSlice';
import { GetContract, GetHistoryProfile, LiveEvent } from '../../types/API';
import { UI_STATUS, MAX_PAGESIZE } from '../../utils/Const';
import { HISTORYLIST_FLOW_ERR_MSG } from '../../utils/Message';

export type GetHistoryListFlowParams = {
  cid: number;
  userApiParams: string;
  readAPIToken: string;
  from: string;
  to: string;
};
/** ライブイベント履歴取得フロー */
export const getHistoryListFlow =
  (arg: GetHistoryListFlowParams): AppThunk =>
  async (dispatch) => {
    try {
      // 処理中をセット
      dispatch(setHistoryListStatus(UI_STATUS.PROCESSING));

      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setContract({ ...responseGetContract.data }));

      // ライブ履歴取得API実行
      const responseGetHistoryProfile1: AxiosResponse<GetHistoryProfile> =
        await axios.get(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}getLiveProfileArch`,
          {
            params: {
              t: arg.readAPIToken,
              event_date_from: arg.from,
              event_date_to: arg.to,
              pageSize: MAX_PAGESIZE,
              pageNumber: 1,
            },
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      let liveProfilesGetted: LiveEvent[] =
        responseGetHistoryProfile1.data.events;

      // 100件以上のプロファイルがある場合に続きを取得
      if (responseGetHistoryProfile1.data.livesum > MAX_PAGESIZE) {
        const loopTimes = [
          ...Array(
            Math.ceil(responseGetHistoryProfile1.data.livesum / 100) - 1
          ),
        ].map((x, i) => i + 2);
        await Promise.all(
          loopTimes.map(async (pageNumber) => {
            // ライブプロファイル取得API実行
            const responseGetHistoryProfile2: AxiosResponse<GetHistoryProfile> =
              await axios.get(
                `${String(
                  process.env.REACT_APP_BACKEND_API_ENDPOINT
                )}getLiveProfileArch`,
                {
                  params: {
                    t: arg.readAPIToken,
                    event_date_from: arg.from,
                    event_date_to: arg.to,
                    pageSize: MAX_PAGESIZE,
                    pageNumber,
                  },
                  headers: {
                    accept: 'application/json',
                    'Content-type': 'application/json',
                  },
                }
              );
            liveProfilesGetted = [
              ...liveProfilesGetted,
              ...responseGetHistoryProfile2.data.events,
            ];
          })
        );
      }

      dispatch(
        setLiveEvent({
          livesum: responseGetHistoryProfile1.data.livesum,
          events: liveProfilesGetted,
        })
      );

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: HISTORYLIST_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      // 完了をセット
      dispatch(setHistoryListStatus(UI_STATUS.COMPLETED));
    }
  };
