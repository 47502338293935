import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { RootState, AppDispatch } from '../../app/store';
import LiveMonitorComponent from '../../components/LiveMonitor/LiveMonitorComponent';
import { setError } from '../../features/uiSlice';
import { STREMAS_ENG, MONITORING_PLAYERSIZE, ONOFF } from '../../utils/Const';
import { QUERY_ERR_MSG } from '../../utils/Message';
import { EQLivePlayer, EQLivePlayerPropsType } from '../../utils/EQPlayer';
import { getLiveMoniterFlow } from './GetLiveMoniter';

/** 配信モニタリング */
const LiveMonitorContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const query = new URLSearchParams(useLocation().search);
  const lpid = query.get('lpid') || '';
  if (lpid === '') {
    dispatch(
      setError({
        msg: QUERY_ERR_MSG,
        detail: '',
      })
    );
  }

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const streamQuality = useSelector(
    (state: RootState) => state.lives.streamQuality
  );

  // State
  const [activeSection, setActiveSection] = useState<STREMAS_ENG[]>([]);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  // 変数抽出
  const { cid, readAPIToken } = user.contract;

  // プレイヤー
  const mainPlayerRef: {
    [key: string]: React.RefObject<HTMLIFrameElement>;
  } = {
    original: useRef<HTMLIFrameElement>(null),
    fhd: useRef<HTMLIFrameElement>(null),
    hd: useRef<HTMLIFrameElement>(null),
    high: useRef<HTMLIFrameElement>(null),
    standard: useRef<HTMLIFrameElement>(null),
    low: useRef<HTMLIFrameElement>(null),
  };
  const backPlayerRef: {
    [key: string]: React.RefObject<HTMLIFrameElement>;
  } = {
    original: useRef<HTMLIFrameElement>(null),
    fhd: useRef<HTMLIFrameElement>(null),
    hd: useRef<HTMLIFrameElement>(null),
    high: useRef<HTMLIFrameElement>(null),
    standard: useRef<HTMLIFrameElement>(null),
    low: useRef<HTMLIFrameElement>(null),
  };

  const mainPlayerParams = {
    playerParams: {
      b: '',
      c: Buffer.from(String(user.contract.cid)).toString('base64'),
      lpid: String(lpid),
      s: {
        hp: MONITORING_PLAYERSIZE.HEIGHT,
        wp: MONITORING_PLAYERSIZE.WIDTH,
        rp: 'off',
        dq: 9,
        mdq: 9,
        pb: ONOFF.ON,
        wm: ONOFF.ON,
        cp: ONOFF.ON,
        vc: ONOFF.ON,
        fs: ONOFF.ON,
        ft: '',
        ti: ONOFF.ON,
        is: ONOFF.OFF,
        ip: ONOFF.OFF,
        el: ONOFF.OFF,
        tg: ONOFF.OFF,
        il: ONOFF.OFF,
        mb: ONOFF.OFF, // NOTE: ONで画質確認
        sn: '',
        rs: ONOFF.OFF,
        line: 1,
        va: ONOFF.OFF,
      },
    },
  };

  const backPlayerParams = {
    playerParams: {
      b: '',
      c: Buffer.from(String(user.contract.cid)).toString('base64'),
      lpid: String(lpid),
      s: {
        hp: MONITORING_PLAYERSIZE.HEIGHT,
        wp: MONITORING_PLAYERSIZE.WIDTH,
        rp: 'fit',
        dq: 9,
        mdq: 9,
        pb: ONOFF.ON,
        wm: ONOFF.ON,
        cp: ONOFF.ON,
        vc: ONOFF.ON,
        fs: ONOFF.ON,
        ft: '',
        ti: ONOFF.ON,
        is: ONOFF.OFF,
        ip: ONOFF.OFF,
        el: ONOFF.OFF,
        tg: ONOFF.OFF,
        il: ONOFF.OFF,
        mb: ONOFF.OFF, // NOTE: ONで画質確認
        sn: '',
        rs: ONOFF.OFF,
        line: 2,
        va: ONOFF.OFF,
      },
    },
  };

  const mainPlayerProps: {
    original: EQLivePlayerPropsType;
    fhd: EQLivePlayerPropsType;
    hd: EQLivePlayerPropsType;
    high: EQLivePlayerPropsType;
    standard: EQLivePlayerPropsType;
    low: EQLivePlayerPropsType;
  } = {
    original: {
      customerDirectory: user.contract.directory,
      playerRef: mainPlayerRef.original,
      tagId: 'main-original',
      player: { ...mainPlayerParams },
    },
    fhd: {
      customerDirectory: user.contract.directory,
      playerRef: mainPlayerRef.fhd,
      tagId: 'main-fhd',
      player: { ...mainPlayerParams },
    },
    hd: {
      customerDirectory: user.contract.directory,
      playerRef: mainPlayerRef.hd,
      tagId: 'main-hd',
      player: { ...mainPlayerParams },
    },
    high: {
      customerDirectory: user.contract.directory,
      playerRef: mainPlayerRef.high,
      tagId: 'main-high',
      player: { ...mainPlayerParams },
    },
    standard: {
      customerDirectory: user.contract.directory,
      playerRef: mainPlayerRef.standard,
      tagId: 'main-standard',
      player: { ...mainPlayerParams },
    },
    low: {
      customerDirectory: user.contract.directory,
      playerRef: mainPlayerRef.low,
      tagId: 'main-low',
      player: { ...mainPlayerParams },
    },
  };

  const backPlayerProps: {
    original: EQLivePlayerPropsType;
    fhd: EQLivePlayerPropsType;
    hd: EQLivePlayerPropsType;
    high: EQLivePlayerPropsType;
    standard: EQLivePlayerPropsType;
    low: EQLivePlayerPropsType;
  } = {
    original: {
      customerDirectory: user.contract.directory,
      playerRef: backPlayerRef.original,
      tagId: 'back-original',
      player: { ...backPlayerParams },
    },
    fhd: {
      customerDirectory: user.contract.directory,
      playerRef: backPlayerRef.fhd,
      tagId: 'back-fhd',
      player: { ...backPlayerParams },
    },
    hd: {
      customerDirectory: user.contract.directory,
      playerRef: backPlayerRef.hd,
      tagId: 'back-hd',
      player: { ...backPlayerParams },
    },
    high: {
      customerDirectory: user.contract.directory,
      playerRef: backPlayerRef.high,
      tagId: 'back-high',
      player: { ...backPlayerParams },
    },
    standard: {
      customerDirectory: user.contract.directory,
      playerRef: backPlayerRef.standard,
      tagId: 'back-standard',
      player: { ...backPlayerParams },
    },
    low: {
      customerDirectory: user.contract.directory,
      playerRef: backPlayerRef.low,
      tagId: 'back-low',
      player: { ...backPlayerParams },
    },
  };

  // アコーディオンの切り替わり
  const openRef = (value: STREMAS_ENG) => {
    switch (value) {
      case STREMAS_ENG.ORIGIN:
        // 最高画質選択
        mainPlayerProps.original.player.playerParams.s.dq = 9;
        mainPlayerProps.original.player.playerParams.s.mdq = 9;
        backPlayerProps.original.player.playerParams.s.dq = 9;
        backPlayerProps.original.player.playerParams.s.mdq = 9;
        // メイン、バックプレイヤー作成
        EQLivePlayer(mainPlayerProps.original);
        EQLivePlayer(backPlayerProps.original);
        break;
      case STREMAS_ENG.FULLHD:
        // フルHD画質選択
        mainPlayerProps.hd.player.playerParams.s.dq = 5;
        mainPlayerProps.hd.player.playerParams.s.mdq = 5;
        backPlayerProps.hd.player.playerParams.s.dq = 5;
        backPlayerProps.hd.player.playerParams.s.mdq = 5;
        // メイン、バックプレイヤー作成
        EQLivePlayer(mainPlayerProps.fhd);
        EQLivePlayer(backPlayerProps.fhd);
        break;
      case STREMAS_ENG.HD:
        // HD画質選択
        mainPlayerProps.hd.player.playerParams.s.dq = 4;
        mainPlayerProps.hd.player.playerParams.s.mdq = 4;
        backPlayerProps.hd.player.playerParams.s.dq = 4;
        backPlayerProps.hd.player.playerParams.s.mdq = 4;
        // メイン、バックプレイヤー作成
        EQLivePlayer(mainPlayerProps.hd);
        EQLivePlayer(backPlayerProps.hd);
        break;
      case STREMAS_ENG.HIGH:
        // 高画質選択
        mainPlayerProps.high.player.playerParams.s.dq = 3;
        mainPlayerProps.high.player.playerParams.s.mdq = 3;
        backPlayerProps.high.player.playerParams.s.dq = 3;
        backPlayerProps.high.player.playerParams.s.mdq = 3;
        // メイン、バックプレイヤー作成
        EQLivePlayer(mainPlayerProps.high);
        EQLivePlayer(backPlayerProps.high);
        break;
      case STREMAS_ENG.STANDARD:
        // 標準画質選択
        mainPlayerProps.standard.player.playerParams.s.dq = 2;
        mainPlayerProps.standard.player.playerParams.s.mdq = 2;
        backPlayerProps.standard.player.playerParams.s.dq = 2;
        backPlayerProps.standard.player.playerParams.s.mdq = 2;
        // メイン、バックプレイヤー作成
        EQLivePlayer(mainPlayerProps.standard);
        EQLivePlayer(backPlayerProps.standard);
        break;
      case STREMAS_ENG.LOW:
        // 低画質選択
        mainPlayerProps.low.player.playerParams.s.dq = 1;
        mainPlayerProps.low.player.playerParams.s.mdq = 1;
        backPlayerProps.low.player.playerParams.s.dq = 1;
        backPlayerProps.low.player.playerParams.s.mdq = 1;
        // メイン、バックプレイヤー作成
        EQLivePlayer(mainPlayerProps.low);
        EQLivePlayer(backPlayerProps.low);
        break;
      default:
        break;
    }
  };

  const handleAccordion = async (value: STREMAS_ENG) => {
    if (activeSection.find((x) => x === value)) {
      await setActiveSection([...activeSection].filter((x) => x !== value));
      // プレイヤー削除
      mainPlayerRef[value].current?.contentDocument?.documentElement.remove();
      backPlayerRef[value].current?.contentDocument?.documentElement.remove();
    } else {
      await setActiveSection([...activeSection, value]);
      openRef(value);
    }
  };

  // 初期処理
  useEffect(() => {
    const initial = async () => {
      await dispatch(
        getLiveMoniterFlow({
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
        })
      );
      Object.keys(mainPlayerRef).map(async (key) => {
        if (mainPlayerRef[key].current?.contentDocument?.documentElement) {
          // プレイヤー削除
          mainPlayerRef[key].current?.contentDocument?.documentElement.remove();
          backPlayerRef[key].current?.contentDocument?.documentElement.remove();
        }
      });
      setFirstLoad(true);
    };
    initial();
  }, []);

  useEffect(() => {
    if (firstLoad) {
      // オリジナル、もしくは最上位画質のアコーディオンをデフォで開く
      if (streamQuality?.original.activateFlg) {
        handleAccordion(STREMAS_ENG.ORIGIN);
      } else if (streamQuality?.fhd.activateFlg) {
        handleAccordion(STREMAS_ENG.FULLHD);
      } else if (streamQuality?.hd.activateFlg) {
        handleAccordion(STREMAS_ENG.HD);
      } else if (streamQuality?.high.activateFlg) {
        handleAccordion(STREMAS_ENG.HIGH);
      } else if (streamQuality?.standard.activateFlg) {
        handleAccordion(STREMAS_ENG.STANDARD);
      } else if (streamQuality?.low.activateFlg) {
        handleAccordion(STREMAS_ENG.LOW);
      }
      setFirstLoad(false);
    }
  }, [firstLoad]);

  return (
    <LiveMonitorComponent
      msg="通知用メッセージ"
      cid={cid}
      readAPIToken={readAPIToken}
      userApiParams={user.user}
      handleAccordion={handleAccordion}
      activeSection={activeSection}
      mainPlayerRef={mainPlayerRef}
      backPlayerRef={backPlayerRef}
      originalUrlActivateFlg={Boolean(
        streamQuality?.original?.activateFlg ?? 0
      )}
      fullHDlUrlActivateFlg={Boolean(streamQuality?.fhd?.activateFlg ?? 0)}
      HDlUrlActivateFlg={Boolean(streamQuality?.hd?.activateFlg ?? 0)}
      highlUrlActivateFlg={Boolean(streamQuality?.high?.activateFlg ?? 0)}
      standardlUrlActivateFlg={Boolean(
        streamQuality?.standard?.activateFlg ?? 0
      )}
      lowUrlActivateFlg={Boolean(streamQuality?.low?.activateFlg ?? 0)}
    />
  );
};

export default LiveMonitorContainer;
