import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  LinearProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { InfoOutlined, PlaylistAdd, DeleteForever } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import CommonBox from '../parts/CommonBox/CommonBox';
import Dialog from '../parts/Dialog/Dialog';
import LiveListTable from './LiveListTable';
import { LiveListProps } from '../../props/LiveList';
import {
  EDITIONS,
  MENUBAR,
  UI_STATUS,
  MIN_WIDTH,
  desktopStreamUrl,
  ROUTE,
  getPath,
  DISPLAY_COUNT,
  LIVE_STATUS,
  CHAT_STATUS,
} from '../../utils/Const';
import LiveEncoderContainer from '../../containers/LiveEncoder/LiveEncoderContainer';
import { RootState } from '../../app/store';
import LiveShareContainer from '../../containers/LiveShare/LiveShareContainer';
import WarningDialog from '../parts/WarningDialog/WarningDialog';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '&::before': {
      content: '""',
      width: '100vw',
      height: 319,
      backgroundSize: '100% 319px',
      background:
        'transparent linear-gradient(180deg, #CFEAB3 0%, #FBFBFB 100%) 0% 0% no-repeat padding-box',
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 3,
    },
    '& .common-header, .common-navigation': {
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 3,
    },
    '& .common-navigation': {
      top: 80,
    },
    '& .MuiButton-label': {
      fontFamily: '"Noto Sans CJK JP", "Noto Sans JP", "Inter", "Roboto Mono"',
      fontWeight: 500,
    },
  },
  fixed: {
    position: 'fixed',
    width: MIN_WIDTH,
    minWidth: MIN_WIDTH,
    zIndex: 3,
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
  header: {
    fontSize: 24,
    color: '#444444',
    top: 120,
    margin: '20px auto',
  },
  infoBox: {
    background: '#FBFFF8 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 12px #61B22712',
    border: '1px solid #B4D69B',
    borderRadius: 4,
    display: 'flex',
    fontSize: 14,
    padding: '7px 10px',
    marginBottom: 20,
    top: 195,
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    '& em': {
      fontStyle: 'normal',
      fontWeight: 700,
    },
    '& strong': {
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#C32733',
      marginRight: 4,
    },
  },
  tool: {
    display: 'flex',
    justifyContent: 'space-between',
    top: 264,
    margin: '0 auto',
  },
  state: {
    background: '#61B227 0% 0% no-repeat padding-box',
    borderRadius: 100,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    paddingRight: 10,
    marginRight: 10,
  },
  addButton: {
    color: '#FFF',
    background: '#4A8BFB',
    fontSize: 17,
    fontWeight: 500,
    borderBottom: '1px solid #3C72D0',
    borderRadius: 4,
    marginRight: 10,
    height: 40,
    width: 160,
    '&:hover': {
      backgroundColor: '#015CFA',
    },
  },
  deleteButton: {
    color: '#C32733',
    border: '1px solid #C32733',
    fontSize: 17,
    fontWeight: 500,
    borderRadius: 4,
    padding: '4px 10px',
    paddingRight: 0,
    height: 40,
    minWidth: 132,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#C32733',
    },
    '& svg': {
      marginRight: 3,
    },
  },
  table: {
    marginTop: 326,
  },
  pagenation: {
    margin: '50px auto 0',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'rgba(97, 178, 39, 0.08)',
    },
  },
  viewLimit: {
    width: 80,
    height: 40,
    background: '#FFFFFF',
    marginLeft: 10,
  },
  deleteNumber: {
    background: '#F7F7F7',
    marginTop: 10,
    padding: '10px 10px',
    borderRadius: 5,
  },
});

/** ライブの配信設定と開催(UI) */
const LiveListComponent = (props: LiveListProps): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const {
    cid,
    readAPIToken,
    userApiParams,
    edition,
    maxLive,
    nowClientCnt,
    automode,
    nowCnt,
    maxContractClient,
    liveSum,
    faildDellpid,
    succeedDellpid,
    checkedLpids,
    liveProfiles,
    deleteLiveProfile,
    closeFaildDellpidDialog,
    goLiveRegister,
    setCheckedLpids,
  } = props;
  /** 削除確認ダイアログ開閉 */
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  /** 削除時ライブIDチェックされていないダイアログ開閉 */
  const [noneDeleteDialog, setNoneDeleteDialog] = useState<boolean>(false);
  /** エンコーダー設定ダイアログ開閉 */
  const [encoderDialog, setEncoderDialog] = useState<boolean>(false);
  /** エンコーダー設定ダイアログ 押されたライブID */
  const [openEncoderLpid, setOpenEncoderLpid] = useState<number | null>(null);
  /** 共通タグダイアログ開閉 */
  const [shareDialog, setShareDialog] = useState<boolean>(false);
  /** 共通タグダイアログ 押されたライブID */
  const [openShareLpid, setOpenShareLpid] = useState<number | null>(null);
  // 旧ライブプロファイル確認ダイアログ開閉
  const [oldProfileDialog, setOldProfileDialog] = useState<boolean>(false);
  /** ページネーション関連 */
  const [limit, setLimit] = useState<number>(DISPLAY_COUNT[0]);
  const [page, setPage] = useState<number>(1);

  // 旧ライブプロファイル確認ダイアログクローズ処理
  const handleCloseOldProfileDialog = () => {
    setOldProfileDialog(false);
  };
  /** 選択可能な全lpidのリスト */
  const lpids: number[] = liveProfiles
    .filter(
      (live) =>
        live.liveStatus === LIVE_STATUS.STOP &&
        live.chatStatus !== CHAT_STATUS.STREAMING
    )
    .map((live) => live.lpid);

  /** 各行、チェックされているか判定 */
  const checkId = (lpid: number) => {
    const isChecked = Boolean(
      typeof checkedLpids.find((x) => x === lpid) !== 'undefined'
    );
    if (isChecked) {
      setCheckedLpids([...checkedLpids.filter((x) => x !== lpid)]);
    } else {
      setCheckedLpids([...checkedLpids, lpid]);
    }
  };
  /** 全行、チェックされているか判定 */
  const checkAllId = (ids: number[]) => {
    checkedLpids.sort((a: number, b: number) => a - b);
    ids.sort((a: number, b: number) => a - b);
    const checklpidStr = JSON.stringify(checkedLpids);
    const lpidStr = JSON.stringify(ids);
    if (checklpidStr === lpidStr) {
      setCheckedLpids([]);
    } else {
      // チェック済みで被っているもの
      const currentIds = checkedLpids.filter(
        (x) => ids.filter((y) => x === y).length > 0
      );
      if (currentIds.length > 0) {
        // 被っているものを排除
        setCheckedLpids(
          checkedLpids.filter((x) => ids.filter((y) => x === y).length <= 0)
        );
      } else {
        setCheckedLpids([...checkedLpids, ...ids]);
      }
    }
  };

  /** 削除ボタン押下時イベント */
  const onClickDleteBtn = () => {
    if (checkedLpids.length === 0) {
      setNoneDeleteDialog(true);
    } else {
      setConfirmDialog(true);
    }
  };

  /** 削除実行 */
  const handleDelete = () => {
    deleteLiveProfile(checkedLpids);
    setPage(1);
  };

  /** エンコーダー設定オープンイベント */
  const handleOpenEncoderDialog = (lpid: number, haveWlivesId: boolean) => {
    if (haveWlivesId) {
      setOpenEncoderLpid(lpid);
      setEncoderDialog(true);
    } else {
      setOldProfileDialog(true);
    }
  };

  /** エンコーダー設定クローズイベント */
  const handleCloseEncoderDialog = () => {
    setEncoderDialog(false);
    setOpenEncoderLpid(null);
  };

  /** 共通タグオープンイベント */
  const handleOpenShareDialog = (lpid: number, haveWlivesId: boolean) => {
    if (haveWlivesId) {
      setOpenShareLpid(lpid);
      setShareDialog(true);
    } else {
      setOldProfileDialog(true);
    }
  };

  /** 共通タグクローズイベント */
  const handleCloseShareDialog = () => {
    setShareDialog(false);
    setOpenShareLpid(null);
  };

  /** デスクトップ配信者画面遷移ボタン押下イベント */
  const handleTransitionDesktopStream = (
    lpid: number,
    haveWlivesId: boolean,
    userApiParams: string
  ) => {
    if (haveWlivesId) {
      // 新規タブでデスクトップ配信画面への遷移
      window.open(
        desktopStreamUrl(
          String(cid),
          String(lpid),
          readAPIToken,
          userApiParams
        ),
        '_blank'
      );
    } else {
      setOldProfileDialog(true);
    }
  };

  /** ライブコントロールパネル画面遷移ボタン押下イベント */
  const handleTransitionController = (lpid: number, haveWlivesId: boolean) => {
    if (haveWlivesId) {
      // 新規タブでライブコントロールパネル画面への遷移
      window.open(
        `${getPath(
          ROUTE.LIVE_CONTROLLER
        )}?lpid=${lpid}&c=${cid}&t=${readAPIToken}&u=${userApiParams}`,
        '_blank'
      );
    } else {
      setOldProfileDialog(true);
    }
  };

  /** ライブモニタリング画面遷移ボタン押下イベント */
  const handleTransitionMonitor = (lpid: number, haveWlivesId: boolean) => {
    if (haveWlivesId) {
      // 新規タブでモニタリング画面への遷移
      window.open(
        `${getPath(
          ROUTE.LIVE_MONITOR
        )}?lpid=${lpid}&c=${cid}&t=${readAPIToken}&u=${userApiParams}`,
        '_blank'
      );
    } else {
      setOldProfileDialog(true);
    }
  };

  /** 表示件数の変更 */
  const handleLimit = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setLimit(Number(event.target.value));
    setPage(1);
  };

  /** 表示範囲の変更 */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  /** ソート対応 */
  const handleSort = () => {
    setPage(1);
  };

  // 処理中
  if (status.liveList === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox className={classes.root} navibar={MENUBAR.LIVELIST}>
      <h2 className={`${classes.header} ${classes.fixed}`}>
        ライブの配信設定と開催
      </h2>
      <Box className={`${classes.infoBox} ${classes.fixed}`}>
        <Box className={classes.info}>
          <Box className={classes.state}>
            <InfoOutlined />
            契約情報
          </Box>
          同時ライブ開催数上限：
          <em>{maxLive}件</em>
          ｜同時接続数上限：
          <em>
            {edition === EDITIONS.U_LIVE
              ? '制限なし'
              : `${maxContractClient}人`}
          </em>
          ｜オートモード：
          <em>{automode ? 'ON' : 'OFF'}</em>
        </Box>
        <Box className={classes.info}>
          <Box className={classes.state}>
            <InfoOutlined />
            ライブ状況
          </Box>
          現在開催中のライブ数：
          <strong>{nowCnt}件</strong>/ {maxLive}
          件｜現在使用中の同時接続枠：
          <strong>
            {edition === EDITIONS.U_LIVE ? '制限なし' : `${nowClientCnt}人`}
          </strong>
          {edition === EDITIONS.U_LIVE
            ? '/ 制限なし'
            : `/ ${maxContractClient}人`}
        </Box>
      </Box>
      <Box className={`${classes.tool} ${classes.fixed}`}>
        <Box>
          <Button className={classes.addButton} onClick={goLiveRegister}>
            <PlaylistAdd />
            新規登録
          </Button>
          <Button className={classes.deleteButton} onClick={onClickDleteBtn}>
            <DeleteForever />
            削除
            <span style={{ marginLeft: '-6px' }}>
              （{checkedLpids.length}件）
            </span>
          </Button>
        </Box>
        <Box
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            marginRight: 10,
          }}
        >
          登録件数：<b style={{ marginRight: 10 }}>{liveSum}件</b>|
          <span style={{ marginLeft: 10 }}>表示件数</span>
          <Select
            value={limit}
            onChange={handleLimit}
            variant="outlined"
            className={classes.viewLimit}
          >
            {DISPLAY_COUNT.map((item) => (
              <MenuItem key={item} value={item}>
                {item}件
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.table}>
        <LiveListTable
          props={props}
          checkedLpids={checkedLpids}
          lpids={lpids}
          checkId={checkId}
          checkAllId={checkAllId}
          handleOpenEncoderDialog={handleOpenEncoderDialog}
          handleOpenShareDialog={handleOpenShareDialog}
          handleTransitionDesktopStream={handleTransitionDesktopStream}
          handleTransitionController={handleTransitionController}
          handleTransitionMonitor={handleTransitionMonitor}
          page={page}
          limit={limit}
          uiStatus={status.liveList}
          handleSort={handleSort}
        />
        <Pagination
          className={classes.pagenation}
          count={Math.ceil(liveProfiles?.length / limit)}
          onChange={handlePage}
          page={page}
        />
      </Box>
      <Dialog
        open={noneDeleteDialog}
        handleClose={() => setNoneDeleteDialog(false)}
        handleOK={() => true}
        title={<>削除対象が１つもチェックされていません。</>}
      />
      <Dialog
        open={confirmDialog}
        handleClose={() => setConfirmDialog(false)}
        handleOK={handleDelete}
        title={
          <>
            チェックした以下のライブIDのライブ配信設定情報を削除します。
            <br />
            よろしいですか？
            <div className={classes.deleteNumber}>
              {checkedLpids.join(', ')}
            </div>
          </>
        }
        cansel="キャンセル"
        ok="OK"
      />
      {(succeedDellpid || faildDellpid) && (
        <Dialog
          open={succeedDellpid.length !== 0 || faildDellpid.length !== 0}
          handleClose={closeFaildDellpidDialog}
          handleOK={() => true}
          title={
            <>
              {succeedDellpid.length > 0 && (
                <>
                  以下のライブIDのライブ配信設定情報を削除しました。
                  <div className={classes.deleteNumber}>
                    {succeedDellpid.join(', ')}
                  </div>
                </>
              )}
              {faildDellpid.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  以下のライブIDのライブ配信設定情報の削除に失敗しました。
                  <div className={classes.deleteNumber}>
                    {faildDellpid.join(', ')}
                  </div>
                </div>
              )}
            </>
          }
        />
      )}
      {openEncoderLpid && (
        <LiveEncoderContainer
          open={encoderDialog}
          lpid={openEncoderLpid}
          onClose={() => handleCloseEncoderDialog()}
        />
      )}
      {openShareLpid && (
        <LiveShareContainer
          open={shareDialog}
          lpid={openShareLpid}
          onClose={() => handleCloseShareDialog()}
        />
      )}
      <WarningDialog
        open={oldProfileDialog}
        handleClose={handleCloseOldProfileDialog}
        header="新EQライブ移行による確認"
        body={
          <>
            こちらのライブプロファイルは旧EQライブで作成されたものです。
            <br />
            新EQライブで使用される場合は、ライブプロファイル設定の更新が必要です。
            <br />
            「ライブ設定名」を押下し、設定内容をご確認の上、「保存」ボタンで更新を行ってください。
          </>
        }
        cancel="OK"
        ok=""
      />
    </CommonBox>
  );
};

export default LiveListComponent;
