import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import type { RootState, AppDispatch } from '../../app/store';
import HistoryListComponent from '../../components/HistoryList/HistoryListComponent';
import { selectAllHistoryLives } from '../../features/historiesSlice';
import { ToLiveLogStatusJap } from '../../utils/ChangeStatus';
import { useAppSelector } from '../../app/hooks';
import {
  downloadClientsLogCSVFlow,
  downloadChatLogCSVFlow,
} from './CsvDownload';
import { getHistoryListFlow } from './GetHistoryList';
import { TableLiveProfileArch } from '../../props/HistoryList';
import { LIVE_LOG_STATUS } from '../../utils/Const';

/** 時間フォーマット */
const TIME_FORMAT = 'HH:mm:ss';
const DATE_FORMAT = 'YYYY/MM/DD';
const URL_FORMAT = 'YYYYMMDDHHmmss';
/** 経過時間取得 */
const getDiffTime = (from: string, to: string | null) => {
  const diff = moment(to).diff(from);
  const reset = new Date('2020/01/01 00:00:00').getTime();
  if (!from || !to) return '';
  return moment(reset + diff).format(TIME_FORMAT);
};

/** ライブイベント履歴 */
const HistoryListContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const histories = useAppSelector(selectAllHistoryLives);

  // 変数抽出
  const { cid, readAPIToken } = user.contract;

  // State
  const [tableLiveProfilesArch, setTableLiveProfilesArch] = useState<
    TableLiveProfileArch[]
  >([]);
  const [fromDate, setFromDate] = useState<string>(
    moment().add(-7, 'days').format(DATE_FORMAT)
  );
  const [toDate, setToDate] = useState<string>(moment().format(DATE_FORMAT));
  const [notFoundChatlogDialog, setNotFoundChatlogDialog] =
    useState<boolean>(false);

  // 初期処理
  useEffect(() => {
    dispatch(
      getHistoryListFlow({
        cid,
        userApiParams: user.user,
        readAPIToken,
        from: fromDate,
        to: toDate,
      })
    );
  }, []);

  // イベント
  // 開催日付の変更：From
  const handleFrom = (value?: string) => {
    if (value) {
      setFromDate(value);
      dispatch(
        getHistoryListFlow({
          cid,
          userApiParams: user.user,
          readAPIToken,
          from: moment(value).format(DATE_FORMAT),
          to: moment(toDate).format(DATE_FORMAT),
        })
      );
    }
  };

  // 開催日付の変更：To
  const handleTo = (value?: string) => {
    if (value) {
      setToDate(value);
      dispatch(
        getHistoryListFlow({
          cid,
          userApiParams: user.user,
          readAPIToken,
          from: moment(fromDate).format(DATE_FORMAT),
          to: moment(value).format(DATE_FORMAT),
        })
      );
    }
  };

  // 同時接続数のCSV出力
  const downloadClientsLogCSV = (
    lplid: string,
    liveLogStatus: LIVE_LOG_STATUS,
    dvrCloseDate: string | null,
    from: string,
    to: string | null
  ) => {
    const spritLplid = lplid.split('-').map((x) => Number(x));
    dispatch(
      downloadClientsLogCSVFlow({
        cid,
        lpid: spritLplid[0],
        leid: spritLplid[1],
        timeInterval: 30,
        from: !from ? undefined : Number(moment(from).format(URL_FORMAT)),
        to:
          // eslint-disable-next-line no-nested-ternary
          liveLogStatus === LIVE_LOG_STATUS.RECORDING || dvrCloseDate
            ? Number(moment().format(URL_FORMAT))
            : !to
            ? undefined
            : Number(moment(to).format(URL_FORMAT)),
      })
    );
  };

  // チャットログのCSV出力
  const downloadChatLogCSV = (lplid: string) => {
    dispatch(
      downloadChatLogCSVFlow({
        readAPIToken,
        lplid,
        setNotFoundChatlogDialog,
      })
    );
  };
  useEffect(() => {
    // ComponentへのPropsの生成
    const liveProfilesArch = histories
      .map((event) => ({
        lplid: event.lplid,
        liveLogStatus: ToLiveLogStatusJap(event.status),
        dvrCloseDate: event.dvrCloseDate,
        eventName: event.eventName,
        eventOnair: event.eventOnair,
        eventOffair: event.eventOffair,
        eventTime: String(getDiffTime(event.eventOnair, event.eventOffair)),
        chat: Boolean(event.chat.enabled),
        recMids: event.recMids,
      }))
      .sort((a, b) =>
        moment(a.eventOnair || a.eventOffair).isBefore(
          moment(b.eventOnair || b.eventOffair)
        )
          ? 1
          : -1
      );
    setTableLiveProfilesArch(liveProfilesArch);
  }, [histories]);

  return (
    <HistoryListComponent
      msg="通知用メッセージ"
      inputFromDate={handleFrom}
      inputToDate={handleTo}
      downloadClientsLogCSV={downloadClientsLogCSV}
      downloadChatLogCSV={downloadChatLogCSV}
      cid={cid}
      readAPIToken={readAPIToken}
      userApiParams={user.user}
      notFoundChatlogDialog={notFoundChatlogDialog}
      setNotFoundChatlogDialog={setNotFoundChatlogDialog}
      fromDate={fromDate}
      toDate={toDate}
      liveProfileArch={tableLiveProfilesArch}
    />
  );
};

export default HistoryListContainer;
