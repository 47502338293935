// ライブプロファイル保存処理用の便利クラス
import { InputData } from '../props/LiveRegister';
import { CHAT_END_TIMING, EDITIONS, STREAM_TYPE } from './Const';

/** 画面入力項目のパラメータ設定 */
const setInputParams = (
  data: InputData,
  edition: EDITIONS,
  encryptEdition: boolean
): FormData => {
  const formData = new FormData();
  // ライブ名
  formData.append('name', data.BaseSettingLiveName);
  // ライブ設定説明
  formData.append('description', data.BaseSettingLiveDescription);
  // グループ設定（録画ファイルアップロード先）
  if (data.BaseSettingVodFlgUse)
    formData.append('group_id', String(data.BaseSettingGroupId));
  // トランスコードID
  if (data.BaseSettingVodFlgUse)
    formData.append('transcode_id', String(data.BaseSettingMpid));
  // 同時接続数制限
  formData.append(
    'max_client',
    edition === EDITIONS.U_LIVE ? '0' : String(data.BaseSettingMaxClient)
  );
  // EQライブキャスト利用
  formData.append(
    'liveapp_flg',
    Number(data.BaseSettingStreamType) === STREAM_TYPE.LIVECAST ? '1' : '0'
  );
  // VODの使用可否
  if (data.BaseSettingVodFlgUse) {
    formData.append('vod_flg', String(data.BaseSettingVodFlgType));
  } else {
    formData.append('vod_flg', '0');
  }
  // ライブ自動画質設定ID
  formData.append('lqpid', String(data.BaseSettingLiveABRProfiles));
  // 暗号配信機能の付与
  if (encryptEdition)
    formData.append('encrypt', data.BaseSettingEncrypt ? '1' : '0');
  // ライブDVR機能
  formData.append('dvr', data.BaseSettingLiveDVRProfileFlg ? '1' : '0');
  // ライブ終了後のDVR配信有効期間（分）
  if (data.BaseSettingLiveDVRProfileFlg) {
    formData.append(
      'dvr_close_minutes',
      data.BaseSettingLiveDVRProfileImmediation === 'true'
        ? '1'
        : String(data.BaseSettingLiveDVRProfileLifeTime)
    );
  } else {
    formData.append('dvr_close_minutes', '0');
  }
  // 動画からのリンク
  formData.append('exlink_flg', data.ExLinkSettingEnabled ? '1' : '0');
  if (data.ExLinkSettingEnabled) {
    // 関連ページリンク(1) リンクテキスト
    formData.append(
      'exlink1_txt',
      data.ExLinkSettingSetting1Text && data.ExLinkSettingSetting1Url
        ? data.ExLinkSettingSetting1Text
        : ''
    );
    // 関連ページリンク(1) リンクURL
    formData.append(
      'exlink1_url',
      data.ExLinkSettingSetting1Text && data.ExLinkSettingSetting1Url
        ? data.ExLinkSettingSetting1Url
        : ''
    );
    // 関連ページリンク(2) リンクテキスト
    formData.append(
      'exlink2_txt',
      data.ExLinkSettingSetting2Text && data.ExLinkSettingSetting2Url
        ? data.ExLinkSettingSetting2Text
        : ''
    );
    // 関連ページリンク(2) リンクURL
    formData.append(
      'exlink2_url',
      data.ExLinkSettingSetting2Text && data.ExLinkSettingSetting2Url
        ? data.ExLinkSettingSetting2Url
        : ''
    );
    // 関連ページリンク(3) リンクテキスト
    formData.append(
      'exlink3_txt',
      data.ExLinkSettingSetting3Text && data.ExLinkSettingSetting3Url
        ? data.ExLinkSettingSetting3Text
        : ''
    );
    // 関連ページリンク(3) リンクURL
    formData.append(
      'exlink3_url',
      data.ExLinkSettingSetting3Text && data.ExLinkSettingSetting3Url
        ? data.ExLinkSettingSetting3Url
        : ''
    );
  }
  // セキュリティプロファイルID
  formData.append(
    'vlp_id',
    String(data.SecuritySettingParentalControlProfiles)
  );
  // 配信停止サムネイル
  if (data.UpdateThumbnailFeedout && !data.UpdateThumbnailFeedout?.errors)
    formData.append('thumb_feedout', data.UpdateThumbnailFeedout);
  // 配信準備サムネイル
  if (data.UpdateThumbnailFeedin && !data.UpdateThumbnailFeedin?.errors)
    formData.append('thumb_feedin', data.UpdateThumbnailFeedin);
  // 配信終了サムネイル
  if (data.UpdateThumbnailLiveopen && !data.UpdateThumbnailLiveopen?.errors)
    formData.append('thumb_liveopen', data.UpdateThumbnailLiveopen);
  // チャット設定利用可否
  formData.append('chat_flg', data.ChatSettingEnable === 'true' ? '1' : '0');
  // チャットの自動リサイズ
  formData.append(
    'chat_auto_resize',
    data.ChatSettingAutoResize === 'true' ? '1' : '0'
  );
  // チャットサイズ
  if (data.ChatSettingAutoResize === 'false') {
    // チャットwidth
    formData.append('chat_width', String(data.ChatSettingAutoResizeWidth));
    // チャットheight
    formData.append('chat_height', String(data.ChatSettingAutoResizeHeight));
  }
  // チャット 開始タイミング（分）
  formData.append('chat_open_timing', String(data.ChatSettingStartTiming));
  // チャット 終了タイミング（分）
  if (Number(data.ChatSettingEndTiming) === CHAT_END_TIMING.MINUTES_AUTO) {
    formData.append(
      'chat_close_timing',
      String(data.ChatSettingEndTimingAutoEndMinutes)
    );
  } else {
    formData.append('chat_close_timing', String(data.ChatSettingEndTiming));
  }
  // 投稿されるメッセージ管理
  formData.append('chat_block_type', String(data.ChatSettingBlockType));
  // 入退室メッセージの表示
  formData.append('chat_enter_msg', String(data.ChatSettingEnterMsg));
  // いいねボタン表示
  formData.append('chat_good_btn', data.ChatSettingGoodBtn ? '1' : '0');
  // わかりませんボタン表示
  formData.append('chat_trouble_btn', data.ChatSettingTroubleBtn ? '1' : '0');
  // チャット任意のリアクションボタンテキストの利用有無
  formData.append(
    'chat_icon_text_flg[]',
    data.ChatSettingCustomBtn1Enable ? '1' : '0'
  );
  formData.append(
    'chat_icon_text_flg[]',
    data.ChatSettingCustomBtn2Enable ? '1' : '0'
  );
  // チャット 任意のリアクションボタンテキスト
  formData.append('chat_icon_text[]', data.ChatSettingCustomBtn1Name);
  formData.append('chat_icon_text[]', data.ChatSettingCustomBtn2Name);
  // 管理者表示名
  formData.append(
    'chat_display_name',
    data.ChatSettingAdminDisplayName === 'true'
      ? data.ChatSettingAdminDisplayNameSelect
      : data.ChatSettingAdminDisplayNameOther
  );
  // ブロックする文字列（カンマ区切り）
  formData.append('chat_block_words', data.ChatSettingBlockWords);
  // デスクトップ配信利用
  formData.append(
    'desktop_encoder',
    Number(data.BaseSettingStreamType) === STREAM_TYPE.DESKTOP ? '1' : '0'
  );
  // ライブイベント情報 - イベント名
  formData.append('potal_meta_event_name', data.LiveEventInfoName);
  // ライブイベント情報 - イベント説明
  formData.append(
    'potal_meta_event_description',
    data.LiveEventInfoDescription
  );
  // ライブイベント情報 - 出演者
  formData.append('potal_meta_performer', data.LiveEventInfoPerformer);

  return formData;
};

export default setInputParams;
