import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  setError,
  setLiveListStatus,
  setFileToLiveRegisterStatus,
} from '../../features/uiSlice';
import { InputData } from '../../props/FileToLiveRegister';
import { SetLiveProfile } from '../../types/API';
import { ToFileToLiveSetProfileStatusJap } from '../../utils/ChangeStatus';
import { EDITIONS, UI_STATUS } from '../../utils/Const';
import { LIVE_ADD_SAVE_FLOW_ERR_MSG } from '../../utils/Message';
import setInputParams from '../../utils/FileToLiveSaveUtil';

/** ライブプロファイル新規登録処理フロー */
const FileToLiveSave =
  (
    data: InputData,
    edition: EDITIONS,
    setMessage: React.Dispatch<React.SetStateAction<string>>
  ): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    // 処理中をセット
    dispatch(setFileToLiveRegisterStatus(UI_STATUS.PROCESSING));
    try {
      // 疑似ライブプロファイル新規登録
      const formData = setInputParams(data, edition);
      formData.append('cid', String(user.contract.cid));
      formData.append('userApiParams', user.user);
      const responseSetLiveProfile: AxiosResponse<SetLiveProfile> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/setProfile`,
          formData,
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'multipart/form-data',
            },
          }
        );
      if (responseSetLiveProfile.data.errorCode !== 0) {
        throw new Error(
          `${ToFileToLiveSetProfileStatusJap(
            responseSetLiveProfile.data.errorCode
          )}: ${responseSetLiveProfile.data.message}`
        );
      }

      // 正常終了
      dispatch(setFileToLiveRegisterStatus(UI_STATUS.REDIRECT));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response) {
        // 異常終了
        dispatch(
          setError({
            msg: LIVE_ADD_SAVE_FLOW_ERR_MSG,
            detail: JSON.stringify(error.response.data),
          })
        );
      } else {
        // エラーメッセージ設定
        setMessage(error?.message);
      }
      dispatch(setFileToLiveRegisterStatus(UI_STATUS.COMPLETED));
    } finally {
      dispatch(setLiveListStatus(UI_STATUS.PROCESSING));
    }
  };

export default FileToLiveSave;
