import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import jaLocale from 'date-fns/locale/ja';
import moment from 'moment';
import CommonBox from '../parts/CommonBox/CommonBox';
import Dialog from '../parts/Dialog/Dialog';
import HistoryListTable from './HistoryListTable';
import { HistoryListProps } from '../../props/HistoryList';
import HistoryConnectionGraphContainer from '../../containers/HistoryConnectionGraphContainer';
import {
  MENUBAR,
  UI_STATUS,
  MIN_WIDTH,
  LIVE_LOG_STATUS,
  DISPLAY_COUNT,
} from '../../utils/Const';
import { DATE_ERR_MSG } from '../../utils/Message';
import { RootState } from '../../app/store';

/** スタイル */
const useStyle = makeStyles({
  root: {
    background: 'none',
    '&::before': {
      content: '""',
      width: '100vw',
      height: 236,
      backgroundSize: '100% 236px',
      background:
        'transparent linear-gradient(180deg, #CFEAB3 0%, #FBFBFB 100%) 0% 0% no-repeat padding-box',
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 3,
    },
    '& .common-header, .common-navigation': {
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 3,
    },
    '& .common-navigation': {
      top: 80,
    },
  },
  fixed: {
    position: 'fixed',
    width: MIN_WIDTH,
    minWidth: MIN_WIDTH,
    zIndex: 3,
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
  header: {
    fontSize: 24,
    color: '#444444',
    top: 120,
    margin: '20px auto 0',
  },
  tool: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    top: 175,
    margin: '0 auto',
    fontSize: 15,
  },
  container: {
    marginTop: 236,
  },
  pagenation: {
    margin: '50px auto 0',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'rgba(97, 178, 39, 0.08)',
    },
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
  },
  inputDate: {
    background: '#FFFFFF',
    margin: '0 10px',
    '& .MuiOutlinedInput-root': {
      height: 40,
      width: 160,
      fontSize: 15,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100vw',
  },
  viewLimit: {
    width: 80,
    height: 40,
    background: '#FFFFFF',
    marginLeft: 10,
  },
});

/** ライブイベント履歴(UI) */
const HistoryListComponent = (props: HistoryListProps): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const {
    inputFromDate,
    inputToDate,
    notFoundChatlogDialog,
    setNotFoundChatlogDialog,
    fromDate,
    toDate,
    liveProfileArch,
  } = props;
  const [graphDialog, setGraphDialog] = useState<boolean>(false);
  const [lplid, setLplid] = useState<string>('');
  const [liveLogStatus, setLiveLogStatus] = useState<LIVE_LOG_STATUS>(
    LIVE_LOG_STATUS.NONE
  );
  const [dvrCloseDate, setDvrCloseDate] = useState<string | null>(null);
  /** 26ヶ月以上指定できないダイアログ開閉 */
  const [tooManyDialog, setTooManyDialog] = useState<boolean>(false);
  /** ページネーション関連 */
  const [limit, setLimit] = useState<number>(DISPLAY_COUNT[0]);
  const [page, setPage] = useState<number>(1);

  const handleGraph = (
    lplid: string,
    logStatus: LIVE_LOG_STATUS,
    dvrCloseDate: string | null
  ) => {
    setGraphDialog(true);
    setLplid(lplid);
    setLiveLogStatus(logStatus);
    setDvrCloseDate(dvrCloseDate);
  };
  const handleDate = (name: string, value: MaterialUiPickersDate) => {
    if (!value?.getTime()) return;
    // 26ヶ月以前だったらエラー
    const limitDate = moment().add(-25, 'month');
    if (moment(value).isBefore(limitDate)) {
      setTooManyDialog(true);
      return;
    }
    if (name === 'from') {
      if (value && value.getTime() <= new Date(toDate).getTime()) {
        inputFromDate(value?.toString());
        setPage(1);
        return;
      }
    }
    if (name === 'to') {
      if (value && value.getTime() >= new Date(fromDate).getTime()) {
        inputToDate(value?.toString());
        setPage(1);
        return;
      }
    }
    alert(DATE_ERR_MSG);
  };
  const blurDate = (name: string, value: string) => {
    const date = new Date(value);
    if (!date.getTime()) {
      alert(DATE_ERR_MSG);
      return;
    }
    // 26ヶ月以前だったらエラー
    const limitDate = moment().add(-25, 'month');
    if (moment(value).isBefore(limitDate)) {
      setTooManyDialog(true);
      return;
    }
    if (name === 'from') {
      if (date && date.getTime() <= new Date(toDate).getTime()) {
        inputFromDate(date?.toString());
        setPage(1);
        return;
      }
    }
    if (name === 'to') {
      if (date && date.getTime() >= new Date(fromDate).getTime()) {
        inputToDate(date?.toString());
        setPage(1);
        return;
      }
    }
    alert(DATE_ERR_MSG);
  };

  /** 表示件数の変更 */
  const handleLimit = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setLimit(Number(event.target.value));
    setPage(1);
  };

  // 処理中
  if (status.historyList === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox className={classes.root} navibar={MENUBAR.HISTORYLIST}>
      {status.outputCSV === UI_STATUS.PROCESSING && !graphDialog && (
        <LinearProgress className={classes.linearProgress} />
      )}
      <h2 className={`${classes.header} ${classes.fixed}`}>
        ライブイベント履歴
      </h2>
      <Box className={`${classes.tool} ${classes.fixed}`}>
        <Box>
          過去に開催したライブのログ（同時接続数）をCSV形式でダウンロードできます。
        </Box>
        <Box className={classes.datePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
            <>
              開催日時
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="yyyy/MM/dd"
                value={new Date(fromDate)}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => handleDate('from', date)}
                className={classes.inputDate}
                onBlur={(e) => blurDate('from', e.target.value)}
                disableToolbar
              />
              〜
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="yyyy/MM/dd"
                value={new Date(toDate)}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => handleDate('to', date)}
                className={classes.inputDate}
                onBlur={(e) => blurDate('to', e.target.value)}
                disableToolbar
                style={{ marginRight: 0 }}
              />
            </>
          </MuiPickersUtilsProvider>
          <span style={{ marginLeft: 20 }}>表示件数</span>
          <Select
            value={limit}
            onChange={handleLimit}
            variant="outlined"
            className={classes.viewLimit}
          >
            {DISPLAY_COUNT.map((item) => (
              <MenuItem key={item} value={item}>
                {item}件
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.container}>
        <HistoryListTable
          props={props}
          handleGraph={handleGraph}
          page={page}
          limit={limit}
          handleSort={() => setPage(1)}
        />
        <Pagination
          className={classes.pagenation}
          count={Math.ceil(liveProfileArch?.length / limit)}
          onChange={(event, value) => setPage(value)}
          page={page}
        />
      </Box>
      <HistoryConnectionGraphContainer
        open={graphDialog}
        lplid={lplid}
        liveLogStatus={liveLogStatus}
        dvrCloseDate={dvrCloseDate}
        onClose={() => setGraphDialog(false)}
      />
      <Dialog
        open={tooManyDialog}
        handleClose={() => setTooManyDialog(false)}
        handleOK={() => true}
        title={<>26ヶ月以前のデータは抽出できません</>}
      />
      <Dialog
        open={notFoundChatlogDialog}
        handleClose={() => setNotFoundChatlogDialog(false)}
        title={
          <>
            データを準備中です。しばらくお待ちの上、再度お試しください。
            <br />
            チャットが終了していない場合は、チャット終了後にダウンロードが可能となります。
          </>
        }
        cansel=""
        ok="OK"
      />
    </CommonBox>
  );
};

export default HistoryListComponent;
