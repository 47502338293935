const ChatLog = (type: string): string => {
  if (type === 'file') {
    return '';
  }
  const csvbody = [...Array(10)]
    .map((x, i) => {
      return `${i},${!!(i % 2)},2019/06/17 15:${
        10 + i
      }:54,master,システム${i},【○○】さんが入室しました\n`;
    })
    .join('');
  return `number,delete,time,soket_id,name,message\n${csvbody}`;
};

export default ChatLog;
