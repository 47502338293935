import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { LiveShareProps } from '../../props/LiveShare';
import { PLAYER_ASPECTFIX } from '../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  input: {
    background: '#FFFFFF',
    marginRight: 4,
    '& .MuiOutlinedInput-input': {
      padding: '6px 10px',
      lineHeight: '28px',
      width: 60,
    },
  },
  attention: {
    display: 'flex',
    color: '#C32733',
  },
  root: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '16px 22px 10px',
    color: '#333333',
    '& .MuiFormControlLabel-label': {
      lineHeight: '32px',
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
      marginRight: 14,
    },
  },
  icon: {
    color: '#C32733',
    marginRight: 4,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: '#C32733',
  },
  aspectRadio: {
    '& .MuiRadio-root': {
      width: 26,
      height: 34,
      color: '#61B227',
    },
  },
});

/** 埋め込みサイズ */
const EmbeddedSize = ({ props }: { props: LiveShareProps }): JSX.Element => {
  const classes = useStyle();
  const {
    responsive,
    choiceVideoDefaultSize,
    videoDefaultSizeFlg,
    videoWidth,
    videoHeight,
    inputVideoSize,
    blurVideoSize,
    aspectRatio,
    choiceAspectRatio,
    inputVideoSizeRequireError,
    setInputVideoSizeRequireError,
    inputVideoSizeError,
  } = props;

  const onChangeVideoDefaultSize = (value: string) => {
    if (value === 'false') {
      setInputVideoSizeRequireError({
        width: !videoWidth,
        height: !videoHeight,
      });
    }
    choiceVideoDefaultSize(value);
  };
  const onBlurVideoSize = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(event.target.value);
    if (event.target.name === 'width') {
      setInputVideoSizeRequireError({
        ...inputVideoSizeRequireError,
        width: !value,
      });
      blurVideoSize.width(value);
    } else if (event.target.name === 'height') {
      setInputVideoSizeRequireError({
        ...inputVideoSizeRequireError,
        height: !value,
      });
      blurVideoSize.height(value);
    }
  };

  return (
    <>
      <h4>埋め込みサイズ</h4>
      {responsive && (
        <Box className={classes.attention}>
          <InfoOutlined fontSize="small" style={{ marginRight: 6 }} />
          <span style={{ marginTop: 4 }}>
            プレイヤーの自動リサイズ（レスポンシブデザイン）が有効です。
          </span>
        </Box>
      )}
      <Box className={classes.root}>
        <RadioGroup
          value={videoDefaultSizeFlg}
          onChange={(e, value) => onChangeVideoDefaultSize(value)}
        >
          <FormControlLabel
            value
            control={<Radio color="primary" />}
            label="選択したプレイヤーのデフォルトサイズにする"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={
              <>
                ライブ設定の標準画質の動画サイズにする
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                >
                  動画表示サイズ：
                  <TextField
                    variant="outlined"
                    value={videoWidth}
                    className={classes.input}
                    onBlur={onBlurVideoSize}
                    onChange={(event) =>
                      inputVideoSize.width(parseInt(event.target.value, 10))
                    }
                    name="width"
                    error={
                      inputVideoSizeError.width ||
                      inputVideoSizeRequireError.width
                    }
                    InputProps={{ inputProps: { maxLength: 4 } }}
                  />
                  &ensp;×&ensp;
                  <TextField
                    variant="outlined"
                    value={videoHeight}
                    className={classes.input}
                    onBlur={onBlurVideoSize}
                    onChange={(event) =>
                      inputVideoSize.height(parseInt(event.target.value, 10))
                    }
                    name="height"
                    error={
                      inputVideoSizeError.height ||
                      inputVideoSizeRequireError.height
                    }
                    InputProps={{ inputProps: { maxLength: 4 } }}
                  />
                </Box>
                {inputVideoSizeError.width && (
                  <Box className={classes.error}>
                    <InfoOutlined fontSize="small" className={classes.icon} />
                    動画表示サイズの幅は150から1920までの値を入力してください
                  </Box>
                )}
                {inputVideoSizeError.height && (
                  <Box className={classes.error}>
                    <InfoOutlined fontSize="small" className={classes.icon} />
                    動画表示サイズの高さは136から1080までの値を入力してください
                  </Box>
                )}
                <Box
                  style={{ display: 'flex' }}
                  className={classes.aspectRadio}
                >
                  アスペクト比（縦横比）の固定：
                  <RadioGroup
                    value={aspectRatio}
                    onChange={(e, value) => choiceAspectRatio(value)}
                    style={{ flexDirection: 'row', marginLeft: 6 }}
                  >
                    <FormControlLabel
                      value={PLAYER_ASPECTFIX.NONE}
                      control={<Radio color="primary" style={{ padding: 0 }} />}
                      label="固定しない"
                    />
                    <FormControlLabel
                      value={PLAYER_ASPECTFIX.XVIIVFIX}
                      control={<Radio color="primary" style={{ padding: 0 }} />}
                      label="16:9に固定"
                    />
                    <FormControlLabel
                      value={PLAYER_ASPECTFIX.IVIIIFIX}
                      control={<Radio color="primary" style={{ padding: 0 }} />}
                      label="4:3に固定"
                    />
                  </RadioGroup>
                </Box>
              </>
            }
          />
        </RadioGroup>
      </Box>
    </>
  );
};

export default EmbeddedSize;
