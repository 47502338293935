import { GetFileToLiveFiller } from '../../types/API';

/** 疑似ライブフィラー利用動画取得API Mockレスポンス */
const FileToLiveFiller = (mid: string): GetFileToLiveFiller => {
  const fillers = [...Array(100)].map((x, i) => {
    return {
      mid: i + 1,
      title: `疑似ライブ 動画-${i + 1}`,
      duration: 60 + i * 60,
    };
  });
  return {
    sum: mid
      ? fillers.filter((filler) => String(filler.mid) === String(mid)).length
      : 100,
    /** 利用可能動画リスト */
    movies: mid
      ? fillers.filter((filler) => String(filler.mid) === String(mid))
      : fillers,
  };
};

export default FileToLiveFiller;
