import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { addEQFileToLiveProfile } from '../../features/fileToLivesSlice';
import { GetFileToLiveProfile } from '../../types/API';
import { setError } from '../../features/uiSlice';
import { FILETOLIVES_GET_ERR_MSG } from '../../utils/Message';

type getProfileType = {
  cid: number;
  flpid: number;
  userApiParams: string;
};
/** 疑似ライブプロファイル取得 */
export const getProfile =
  (arg: getProfileType): AppThunk =>
  async (dispatch) => {
    try {
      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetFileToLiveProfile> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/getProfile`,
          {
            cid: arg.cid,
            flpid: arg.flpid,
            userApiParams: arg.userApiParams,
            pageSize: 1,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(
        addEQFileToLiveProfile({
          ...responseProfile.data.profile[0],
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: FILETOLIVES_GET_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    }
  };

export default getProfile;
