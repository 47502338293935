import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { addEQLiveProfile, selectEQLivesById } from '../../features/livesSlice';
import {
  setError,
  setLiveContentStatus,
  setLiveRegisterStatus,
} from '../../features/uiSlice';
import { setContract, setLiveSetUpDataList } from '../../features/userSlice';
import {
  GetContract,
  GetLiveProfile,
  GetLiveSetUpDataList,
  GetWowzaFeed,
} from '../../types/API';
import {
  LIVE_SAVE,
  LIVE_STATUS_ENG,
  PORTAL_STATUS,
  UI_STATUS,
} from '../../utils/Const';
import {
  LIVE_ADD_INIT_FLOW_ERR_MSG,
  LIVE_UPDATE_INIT_FLOW_ERR_MSG,
} from '../../utils/Message';
import formatDate from '../../utils/FormatDate';

/** ライブプロファイル初期表示処理フロー */
const LiveProfileInit =
  (
    save: LIVE_SAVE,
    setDialog?: React.Dispatch<React.SetStateAction<boolean>>
  ): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    const searchString = window.location.search;
    const params = new URLSearchParams(searchString);
    const userApiParams = encodeURI(String(params.get('u'))).replace(
      /%20/g,
      '+'
    );
    // 処理中をセット
    dispatch(
      save === LIVE_SAVE.ADD
        ? setLiveRegisterStatus(UI_STATUS.PROCESSING)
        : setLiveContentStatus(UI_STATUS.PROCESSING)
    );
    try {
      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: user.contract.cid,
          userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setContract({ ...responseGetContract.data }));

      // ライブ新規登録プルダウンリスト取得API実行
      const responseGetLiveSetUpDataList: AxiosResponse<GetLiveSetUpDataList> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}getLiveSetUpDataList`,
          {
            cid: user.contract.cid,
            userApiParams,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(setLiveSetUpDataList({ ...responseGetLiveSetUpDataList.data }));
      // 更新の場合のみ
      if (save === LIVE_SAVE.UPDATE) {
        // ライブプロファイル取得API実行
        const responseGetLiveProfile: AxiosResponse<GetLiveProfile> =
          await axios.post(
            `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
            {
              lpid: Number(params.get('lpid')),
              cid: user.contract.cid,
              userApiParams,
              pageSize: 1,
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        // NOTE: ポータル公開ステータスは使わないのでデフォルト値を代入
        dispatch(
          addEQLiveProfile({
            ...responseGetLiveProfile.data.profile[0],
            portalStatus: PORTAL_STATUS.NONE,
          })
        );

        // 配信停止中の時だけステータスを配信準備中に書き換える必要がある
        if (
          responseGetLiveProfile.data.profile[0]?.status ===
            LIVE_STATUS_ENG.STOP &&
          responseGetLiveProfile.data.profile[0]?.jcloudWlives.liveId
        ) {
          // Wowzaフィード確認API実行
          const responseGetWowzaFeed: AxiosResponse<GetWowzaFeed[]> =
            await axios.post(
              `${String(
                process.env.REACT_APP_BACKEND_API_ENDPOINT
              )}getWowzaFeed`,
              {
                wlivesIds: [
                  responseGetLiveProfile.data.profile[0]?.jcloudWlives.liveId,
                ],
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
          if (
            responseGetWowzaFeed.data[0].mainFeed ||
            responseGetWowzaFeed.data[0].backFeed
          ) {
            dispatch(
              addEQLiveProfile({
                ...responseGetLiveProfile.data.profile[0],
                status: LIVE_STATUS_ENG.PRE,
                portalStatus: PORTAL_STATUS.NONE,
              })
            );
          }
        }
        // 画面描写は完了にしておく
        dispatch(setLiveContentStatus(UI_STATUS.COMPLETED));

        const EQLive = selectEQLivesById(
          getState(),
          Number(params.get('lpid'))
        );
        // 「ライブプロファイルの更新日時」が「紐づく自動画質設定の更新日時」よりも前かの判断をフラグへ格納
        responseGetLiveSetUpDataList.data.autoQuolity.profiles.forEach(
          (AutoQuality) => {
            // 旧ライブから移行されたlqpid=0のライブプロファイルはfalse
            if (EQLive?.lqpid === 0) {
              const updatedAutoQualityFlg = false;
              const cloneEQlive = {
                ...EQLive,
                updatedAutoQualityFlg,
              };
              dispatch(addEQLiveProfile(cloneEQlive));

              // ダイアログ表示処理
              if (setDialog !== undefined) {
                setDialog(cloneEQlive.updatedAutoQualityFlg);
              }
            }
            if (EQLive?.lqpid === AutoQuality.lqpid) {
              const liveProfileUpdateDate = formatDate(EQLive.updateDate);
              const autoQualityUpdateDate = formatDate(AutoQuality.updateDate);
              if (
                liveProfileUpdateDate === null ||
                autoQualityUpdateDate === null
              ) {
                return;
              }
              const updatedAutoQualityFlg =
                liveProfileUpdateDate < autoQualityUpdateDate;
              const cloneEQlive = {
                ...EQLive,
                updatedAutoQualityFlg,
              };
              dispatch(addEQLiveProfile(cloneEQlive));

              // ダイアログ表示処理
              if (setDialog !== undefined) {
                setDialog(cloneEQlive.updatedAutoQualityFlg);
              }
            }
          }
        );
      }
      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg:
            save === LIVE_SAVE.ADD
              ? LIVE_ADD_INIT_FLOW_ERR_MSG
              : LIVE_UPDATE_INIT_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(
        save === LIVE_SAVE.ADD
          ? setLiveRegisterStatus(UI_STATUS.COMPLETED)
          : setLiveContentStatus(UI_STATUS.COMPLETED)
      );
    }
  };

export default LiveProfileInit;
