// APIで取得した値をライブ詳細とライブ配信設定履歴の画面へ設定するための変換処理
import { ExLinkSetting, LiveEventInfo } from '../types/API';
import { APP_STREAMS, CHAT_END_TIMING, STREAM_TYPE } from './Const';

/** 配信方法 */
export const getStreamType = (
  liveAppFlg: 0 | 1,
  desktopEncoder: 0 | 1,
  lqpid?: number
): STREAM_TYPE => {
  if (lqpid === 0 || liveAppFlg) {
    return STREAM_TYPE.LIVECAST;
  }
  if (desktopEncoder) {
    return STREAM_TYPE.DESKTOP;
  }
  return STREAM_TYPE.ENCODER;
};

/** 配信帯域 */
export const getStreams = (
  bitrateHD: number,
  bitrateHigh: number,
  bitrateStandard: number,
  bitrateLow: number
): APP_STREAMS => {
  if (bitrateHD) {
    return APP_STREAMS.HD;
  }
  if (bitrateHigh) {
    return APP_STREAMS.HIGH;
  }
  if (bitrateStandard) {
    return APP_STREAMS.STANDARD;
  }
  if (bitrateLow) {
    return APP_STREAMS.LOW;
  }
  return APP_STREAMS.NONE;
};

/** 動画からのリンク(初期値) */
export const getExLinkInit = (): ExLinkSetting => {
  return {
    enabled: 1,
    settings: [
      {
        index: 1,
        text: '',
        url: '',
      },
      {
        index: 2,
        text: '',
        url: '',
      },
      {
        index: 3,
        text: '',
        url: '',
      },
    ],
  };
};

/** チャットの終了タイミング */
export const getEndTiming = (closeTiming: number): number => {
  // 時間指定されている場合、時間指定で自動終了を返す
  if (closeTiming > 0) return CHAT_END_TIMING.MINUTES_AUTO;
  return closeTiming;
};

/** チャットの終了タイミング(指定時間) */
export const getAutoEndMinutes = (closeTiming: number): number => {
  // 時間指定されている場合、指定時間を返す
  if (closeTiming > 0) return closeTiming;
  return 0;
};

/** ライブイベント情報(初期値) */
export const getPortalMetaInit = (): LiveEventInfo => {
  return {
    eventName: '',
    description: '',
    performer: '',
  };
};
