/** ライブ自動画質設定履歴取得API Mockレスポンス */
const AutoQualityHistory = {
  cid: 2984,
  lpid: 691,
  events: [],
  // events: [
  //   {
  //     leid: 4,
  //     lqpid: 7,
  //     profileName: 'ライブ自動画質設定履歴',
  //     framerate: 30,
  //     quality: {
  //       fhd: { activeFlg: 0, videoBitrate: 0 },
  //       hd: { activeFlg: 1, videoBitrate: 0 },
  //       high: { activeFlg: 1, videoBitrate: 0 },
  //       standard: { activeFlg: 0, videoBitrate: 0 },
  //       low: { activeFlg: 0, videoBitrate: 0 },
  //       original: { activeFlg: 1, videoBitrate: 0 },
  //     },
  //   },
  // ],
};

export default AutoQualityHistory;
