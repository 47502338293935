import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { CHAT_MANUAL_OPEN_ERR_MSG } from '../../utils/Message';
import {
  setError,
  setFileToLiveControllerStatus,
} from '../../features/uiSlice';
import { GetFileToLiveProfile } from '../../types/API';
import { UI_STATUS } from '../../utils/Const';
import { addEQFileToLiveProfile } from '../../features/fileToLivesSlice';

type ChatManualStartParams = {
  flpid: number;
  cid: number;
  userApiParams: string;
  customerDir: string;
};

/** チャット手動開始フロー */
const ChatManualStart =
  (arg: ChatManualStartParams): AppThunk =>
  async (dispatch) => {
    // チャット処理中をセット
    dispatch(setFileToLiveControllerStatus(UI_STATUS.PROCESSING_CHAT));
    try {
      // チャット開始API実行
      await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/startChat`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          flpid: arg.flpid,
          customerDir: arg.customerDir,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetFileToLiveProfile> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/getProfile`,
          arg,
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(
        addEQFileToLiveProfile({
          ...responseProfile.data.profile[0],
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: CHAT_MANUAL_OPEN_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setFileToLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default ChatManualStart;
