import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { setEncoderSetting } from '../../features/livesSlice';
import { setError, setLiveEncoderStatus } from '../../features/uiSlice';
import { GetEncoderSetting } from '../../types/API';
import { UI_STATUS } from '../../utils/Const';
import { LIVEENCODER_FLOW_ERR_MSG } from '../../utils/Message';

type getJCloudSeverWlivesParams = {
  cid: number;
  userApiParams: string;
  lpid: number;
};
/** エンコーダー設定情報取得 */
const getLiveEncoder =
  (arg: getJCloudSeverWlivesParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLiveEncoderStatus(UI_STATUS.PROCESSING));

      // エンコーダー設定取得API実行
      const responseGetEncoder: AxiosResponse<GetEncoderSetting> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEncoder`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            lpid: arg.lpid,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      dispatch(setEncoderSetting(responseGetEncoder.data));

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVEENCODER_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveEncoderStatus(UI_STATUS.COMPLETED));
    }
  };

export default getLiveEncoder;
