import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Box, Button, LinearProgress } from '@material-ui/core';
import { Close, KeyboardArrowRight } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import CommonTable from './CommonTable';
import { LiveEncoderProps } from '../../props/LiveEncoder';
import { RootState } from '../../app/store';
import { STREAMS, UI_STATUS } from '../../utils/Const';

const CustomButtomView = ({
  title,
  onClick,
  className,
}: {
  title: string;
  className: string;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <Button className={className} onClick={onClick}>
      {title}
      <KeyboardArrowRight
        fontSize="small"
        style={{ marginLeft: 4, width: 16, height: 16 }}
      />
    </Button>
  );
};

/** スタイル */
const useStyle = makeStyles({
  root: {
    width: 980,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#333333',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  container: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    background: '#FFFFFF',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: '0px 4px 16px #0000001F',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    fontSize: 20,
  },
  body: {
    padding: '30px 40px',
    fontSize: 15,
    height: 'calc(100% - 140px)',
    overflow: 'auto',
    '& h4': {
      fontSize: 15,
      fontWeight: 500,
      borderLeft: '2px solid #61B227',
      paddingLeft: 20,
      marginTop: 31,
    },
  },
  button: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 14,
    color: '#333333',
    width: 154,
    '&:hover': {
      background: '#333333',
      color: '#FFFFFF',
    },
  },
});

type propsType = {
  open: boolean;
  props: LiveEncoderProps;
  onClose: () => void;
};

/** ライブエンコーダー設定(UI) */
const LiveEncoderComponent = ({
  open,
  props,
  onClose,
}: propsType): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const {
    lpid,
    liveName,
    imageQuality,
    bitrate,
    downloadLiveShellProfile,
    downloadWireCastProfile,
    downloadLiveUSoloProfile,
  } = props;

  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      {status.liveEncoder === UI_STATUS.PROCESSING ? (
        <Box className={classes.container}>
          <LinearProgress />
        </Box>
      ) : (
        <Box className={classes.container}>
          <Box className={classes.header}>
            <h3>ライブエンコーダー設定取得</h3>
            <Close onClick={onClose} style={{ cursor: 'pointer' }} />
          </Box>
          <Box className={classes.body}>
            <Box>
              ライブエンコーダーに設定する設定情報を取得することができます。
            </Box>
            <h4>ライブエンコーダー設定内容</h4>
            <CommonTable
              header={[
                { name: 'item', title: '項目', width: 280 },
                { name: 'settingValue', title: '設定値', width: 620 },
              ]}
              body={[
                [
                  { name: 'item', content: 'プロファイルID' },
                  { name: 'settingValue', content: lpid },
                ],
                [
                  { name: 'item', content: 'ライブ設定名' },
                  { name: 'settingValue', content: liveName },
                ],
                [
                  { name: 'item', content: '配信帯域' },
                  {
                    name: 'settingValue',
                    content:
                      imageQuality === STREAMS.ORIGIN
                        ? 'オリジナル画質のみ'
                        : `${imageQuality}(${bitrate}kbps)`,
                  },
                ],
              ]}
            />
            <h4 style={{ marginTop: 28 }}>設定ダウンロード</h4>
            <p style={{ marginBottom: 22 }}>
              ご使用のライブエンコーダーの設定情報を、下記よりダウンロードしてご使用ください。
            </p>
            <CommonTable
              header={[
                {
                  name: 'liveEncoder',
                  title: 'ライブエンコーダー',
                  width: 750,
                },
                {
                  name: 'download',
                  title: (
                    <Box style={{ textAlign: 'center' }}>
                      設定情報のダウンロード
                    </Box>
                  ),
                  width: 150,
                },
              ]}
              body={[
                [
                  { name: 'liveEncoder', content: 'LiveShell X' },
                  {
                    name: 'download',
                    content: (
                      <CustomButtomView
                        title="ダウンロード"
                        onClick={downloadLiveShellProfile}
                        className={classes.button}
                      />
                    ),
                  },
                ],
                [
                  { name: 'liveEncoder', content: 'Wirecast' },
                  {
                    name: 'download',
                    content: (
                      <CustomButtomView
                        title="ダウンロード"
                        onClick={downloadWireCastProfile}
                        className={classes.button}
                      />
                    ),
                  },
                ],
                [
                  { name: 'liveEncoder', content: 'LiveU Solo' },
                  {
                    name: 'download',
                    content: (
                      <CustomButtomView
                        title="ダウンロード"
                        onClick={downloadLiveUSoloProfile}
                        className={classes.button}
                      />
                    ),
                  },
                ],
              ]}
            />
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default LiveEncoderComponent;
