import { GetEncoderSetting } from '../../types/API';

/** ライブエンコーダー設定取得API Mockレスポンス */
const EncoderSetting: GetEncoderSetting = {
  original: { activateFlg: 1 },
  fhd: { activateFlg: 0, bitrate: 0 },
  hd: { activateFlg: 1, bitrate: 2000 },
  high: { activateFlg: 1, bitrate: 1000 },
  standard: { activateFlg: 0, bitrate: 0 },
  low: { activateFlg: 0, bitrate: 0 },
  encoderProfile: {
    liveshell:
      '【配信するサーバ情報(RTMP)】\\n■配信先URL\\n\t－メインライブエンコーダーの場合：rtmp://n18jklpjrdrlxy9o-org1.wlive.cloud.stream.co.jp:1935/n18jklpjrdrlxy9o?key=0c756a04\\n\t－バックアップライブエンコーダーの場合：rtmp://n18jklpjrdrlxy9o-org2.wlive.cloud.stream.co.jp:1935/n18jklpjrdrlxy9o?key=0c756a04\\n\n\\n■ストリーム名\\n\t－jfZnvKBFDildGTpy6aNzqgH4\\n\\n\\n【エンコーダー設定の推奨値】\\n下記はエンコーダーに設定する推奨値になります。\\n推奨値が複数表示されている場合は、配信状況に応じていずれか1つの画質をエンコーダーで設定してください。\\n\\n\\n【CUSTOM】\\n■AUDIO QUALITY\\n\t－サンプリングレート：44.1KHz or 48KHz\\n\t－チャンネル：ステレオ\\n\t－ビットレート\\n\t\t－高画質用ライブエンコーダーの場合：128kbps\\n\\n\\n■VIDEO QUALITY\\n\tー平均ビットレート\\n\t\t－高画質用ライブエンコーダーの場合：1600\\n\t－iフレーム挿入間隔：30frame\\n\t－フレームレート：30\\n\t－プロファイル：Baseline\\n\t－縦解像度\\n\t\t－高画質用ライブエンコーダーの場合：480',
    liveusolo:
      'LiveU Soloポータルサイトより設定\\nhttps://solo.liveu.tv/login\\n\\n\\n新しい配信先を選択 → Jstreamを選択\\n\\n\\n【配信先を編集する】\\n－配信先名：任意の名前をご設定ください\\n\\n\\n－プロファイル\\n1280x720 Widescreen 1sec GOP\\n\\n\\n－ストリームURL\\nrtmp://n18jklpjrdrlxy9o-org1.wlive.cloud.stream.co.jp:1935/n18jklpjrdrlxy9o?key=0c756a04\\n－バックアップサーバーURL\\nrtmp://n18jklpjrdrlxy9o-org2.wlive.cloud.stream.co.jp:1935/n18jklpjrdrlxy9o?key=0c756a04\\n\\n\\nーストリームキー\\njfZnvKBFDildGTpy6aNzqgH4\\n\\n\\n【エンコーダー設定の推奨値】\\n下記はエンコーダーに設定する推奨値になります。\\n推奨値が複数表示されている場合は、配信状況に応じていずれか1つの画質をエンコーダーで設定してください。\\n\\n\\n■プロファイルの設定（高度）\\n－解像度の設定を上書き　チェックあり\\n    －高画質用ライブエンコーダーの場合：854x480\\n\\n\\n－ビットレート(kbps)　チェックあり\\n※選択したプロファイルの解像度に従い、3000kbpsを推奨致します\\n    －高画質用ライブエンコーダーの場合：1600\\n\\n\\n－フレームレート　チェックあり\\n30',
    wirecast:
      '【配信設定】\\n■出力先（配信先）\\n\tRTMPサーバー\\n\\n\\n■アドレス\\n\t－メインライブエンコーダーの場合：rtmp://n18jklpjrdrlxy9o-org1.wlive.cloud.stream.co.jp:1935/n18jklpjrdrlxy9o?key=0c756a04\\n\t－バックアップライブエンコーダーの場合：rtmp://n18jklpjrdrlxy9o-org2.wlive.cloud.stream.co.jp:1935/n18jklpjrdrlxy9o?key=0c756a04\\n■ストリーム\\n\t－jfZnvKBFDildGTpy6aNzqgH4\\n\\n\\n\\n\\n■User Agent\\n\tWirecast/FM 1.0\\n\\n\\n■ストリームの遅延\\n\t0秒\\n\\n\\n【エンコーダー設定の推奨値】\\n下記はエンコーダーに設定する推奨値になります。\\n推奨値が複数表示されている場合は、配信状況に応じていずれか1つの画質をエンコーダーで設定してください。\\n\\n\\n■高画質用ライブエンコーダーの場合：\\n・Video Encoding\\n\t－エンコーダー：x264\\n\t－幅：854\\n\t－高さ：480\\n\t－1秒あたりのフレーム数：30\\n\t－平均ビットレート：1600\\n\t－画質：3 － (非常に高速なエンコード)\\n\t－x264コマンドラインオプション：--no-scenecutを記入\\n\t－プロファイル：ベースライン\\n\t－キーフレーム：30\\n\t－次のフレーム数ごとにタイムコード：(チェック不要、記載不要)\\n\t－厳密に一定のビットレートを維持：(チェック不要)\\n\t－キーフレームを位置合わせ：ONにチェック\\n\\n\\n・Audio Encoding(AAC)\\n\t－チャンネル：Stereo\\n\t－目標のビットレート：128\\n\t－サンプルレート：44.1 or 48\\n\\n',
  },
};

export default EncoderSetting;
