import {
  LIVE_STATUS,
  LIVE_STATUS_ENG,
  LIVE_LOG_STATUS,
  LIVE_LOG_STATUS_ENG,
  STREAMS,
  STREMAS_ENG,
  STREMAS_NUM,
  DROP_ZONE_ERR_CODE,
  LIVE_PROFILE_ERR_CODE,
  FILE_TO_LIVE_PROFILE_ERR_CODE,
  FILE_TO_LIVE_STATUS_ENG,
  FILE_TO_LIVE_STATUS,
} from './Const';
import {
  DROP_ZONE_ERR_MSG,
  FILE_TO_LIVE_PROFILE_ERR_MSG,
  LIVE_PROFILE_ERR_MSG,
} from './Message';

/** ライブステータスの日本変換 */
export const ToLiveStatusJap = (status: LIVE_STATUS_ENG): LIVE_STATUS => {
  switch (status) {
    case LIVE_STATUS_ENG.PRE:
      return LIVE_STATUS.PRE;

    case LIVE_STATUS_ENG.OPEN:
      return LIVE_STATUS.STREAMING;

    case LIVE_STATUS_ENG.STOP:
      return LIVE_STATUS.STOP;

    case LIVE_STATUS_ENG.CREATING:
      return LIVE_STATUS.CREATING;

    case LIVE_STATUS_ENG.ERROR:
      return LIVE_STATUS.ERROR;

    default:
      return LIVE_STATUS.NONE;
  }
};

/** ライブステータスの英語変換 */
export const ToLiveStatusEng = (status: LIVE_STATUS): LIVE_STATUS_ENG => {
  switch (status) {
    case LIVE_STATUS.PRE:
      return LIVE_STATUS_ENG.PRE;

    case LIVE_STATUS.STREAMING:
      return LIVE_STATUS_ENG.OPEN;

    case LIVE_STATUS.STOP:
      return LIVE_STATUS_ENG.STOP;

    case LIVE_STATUS.CREATING:
      return LIVE_STATUS_ENG.CREATING;

    case LIVE_STATUS.ERROR:
      return LIVE_STATUS_ENG.ERROR;

    default:
      return LIVE_STATUS_ENG.NONE;
  }
};

/** ライブ履歴ステータスの日本変換 */
export const ToLiveLogStatusJap = (
  status: LIVE_LOG_STATUS_ENG
): LIVE_LOG_STATUS => {
  switch (status) {
    case LIVE_LOG_STATUS_ENG.WAIT:
      return LIVE_LOG_STATUS.WAIT;

    case LIVE_LOG_STATUS_ENG.RECORDING:
      return LIVE_LOG_STATUS.RECORDING;

    case LIVE_LOG_STATUS_ENG.RECORDING_EXTENSION:
      return LIVE_LOG_STATUS.RECORDING_EXTENSION;

    case LIVE_LOG_STATUS_ENG.RECORDING_STOPING:
      return LIVE_LOG_STATUS.RECORDING_STOPING;

    case LIVE_LOG_STATUS_ENG.RECORDING_FINISH:
      return LIVE_LOG_STATUS.RECORDING_FINISH;

    case LIVE_LOG_STATUS_ENG.MASTER_CONVERT:
      return LIVE_LOG_STATUS.MASTER_CONVERT;

    case LIVE_LOG_STATUS_ENG.MASTER_CONVERT_FINISH:
      return LIVE_LOG_STATUS.MASTER_CONVERT_FINISH;

    case LIVE_LOG_STATUS_ENG.TRANSCODE:
      return LIVE_LOG_STATUS.TRANSCODE;

    case LIVE_LOG_STATUS_ENG.TRANSCODE_FINISH:
      return LIVE_LOG_STATUS.TRANSCODE_FINISH;

    case LIVE_LOG_STATUS_ENG.MASTER_CONVERT_ERROR:
      return LIVE_LOG_STATUS.MASTER_CONVERT_ERROR;

    case LIVE_LOG_STATUS_ENG.VOD_UPLOAD_ERROR:
      return LIVE_LOG_STATUS.VOD_UPLOAD_ERROR;

    case LIVE_LOG_STATUS_ENG.VOD_TRANSCODE_ERROR:
      return LIVE_LOG_STATUS.VOD_TRANSCODE_ERROR;

    case LIVE_LOG_STATUS_ENG.UNKNOWN_ERROR:
      return LIVE_LOG_STATUS.UNKNOWN_ERROR;

    default:
      return LIVE_LOG_STATUS.NONE;
  }
};

// 帯域名の日本語変換
// NOTE: objectのkeyから日本語に変換することがあるため、引数に明示的にstringも許容している
export const ToStreamsJap = (stream: STREMAS_ENG | string): STREAMS => {
  switch (stream) {
    case STREMAS_ENG.ORIGIN:
      return STREAMS.ORIGIN;

    case STREMAS_ENG.FULLHD:
      return STREAMS.FULLHD;

    case STREMAS_ENG.HD:
      return STREAMS.HD;

    case STREMAS_ENG.HIGH:
      return STREAMS.HIGH;

    case STREMAS_ENG.STANDARD:
      return STREAMS.STANDARD;

    case STREMAS_ENG.LOW:
      return STREAMS.LOW;

    default:
      return STREAMS.NONE;
  }
};

// 帯域名の数字変換
// NOTE: objectのkeyから変換することがあるため、引数に明示的にstringも許容している
export const ToStreamsNum = (stream: STREMAS_ENG | string): STREMAS_NUM => {
  switch (stream) {
    case STREMAS_ENG.ORIGIN:
      return STREMAS_NUM.ORIGIN;

    case STREMAS_ENG.FULLHD:
      return STREMAS_NUM.FULLHD;

    case STREMAS_ENG.HD:
      return STREMAS_NUM.HD;

    case STREMAS_ENG.HIGH:
      return STREMAS_NUM.HIGH;

    case STREMAS_ENG.STANDARD:
      return STREMAS_NUM.STANDARD;

    case STREMAS_ENG.LOW:
      return STREMAS_NUM.LOW;

    default:
      return STREMAS_NUM.AUTO;
  }
};

/** react-dropzoneエラーメッセージの日本語変換 */
export const ToDropZoneJap = (code: DROP_ZONE_ERR_CODE): DROP_ZONE_ERR_MSG => {
  switch (code) {
    case DROP_ZONE_ERR_CODE.TOO_MANY_FILES:
      return DROP_ZONE_ERR_MSG.TOO_MANY_FILES;

    case DROP_ZONE_ERR_CODE.FILE_INVALID_TYPE:
      return DROP_ZONE_ERR_MSG.FILE_INVALID_TYPE;

    case DROP_ZONE_ERR_CODE.FILE_TOO_LARGE:
      return DROP_ZONE_ERR_MSG.FILE_TOO_LARGE;

    case DROP_ZONE_ERR_CODE.FILE_TOO_SMALL:
      return DROP_ZONE_ERR_MSG.FILE_TOO_SMALL;

    case DROP_ZONE_ERR_CODE.HEIGHT_TOO_SMALL:
      return DROP_ZONE_ERR_MSG.HEIGHT_TOO_SMALL;

    default:
      return DROP_ZONE_ERR_MSG.NONE;
  }
};

/** ライブプロファイル作成・更新APIのステータスとメッセージ変換 */
export const ToSetProfileStatusJap = (
  code: LIVE_PROFILE_ERR_CODE
): LIVE_PROFILE_ERR_MSG => {
  switch (code) {
    case LIVE_PROFILE_ERR_CODE.CODE_7000:
      return LIVE_PROFILE_ERR_MSG.MSG_7000;

    case LIVE_PROFILE_ERR_CODE.CODE_9021:
      return LIVE_PROFILE_ERR_MSG.MSG_9021;

    case LIVE_PROFILE_ERR_CODE.CODE_9022:
      return LIVE_PROFILE_ERR_MSG.MSG_9022;

    case LIVE_PROFILE_ERR_CODE.CODE_9502:
      return LIVE_PROFILE_ERR_MSG.MSG_9502;

    case LIVE_PROFILE_ERR_CODE.CODE_9503:
      return LIVE_PROFILE_ERR_MSG.MSG_9503;

    case LIVE_PROFILE_ERR_CODE.CODE_9512:
      return LIVE_PROFILE_ERR_MSG.MSG_9512;

    case LIVE_PROFILE_ERR_CODE.CODE_9516:
      return LIVE_PROFILE_ERR_MSG.MSG_9516;

    case LIVE_PROFILE_ERR_CODE.CODE_9519:
      return LIVE_PROFILE_ERR_MSG.MSG_9519;

    case LIVE_PROFILE_ERR_CODE.CODE_9520:
      return LIVE_PROFILE_ERR_MSG.MSG_9520;

    case LIVE_PROFILE_ERR_CODE.CODE_9521:
      return LIVE_PROFILE_ERR_MSG.MSG_9521;

    case LIVE_PROFILE_ERR_CODE.CODE_9522:
      return LIVE_PROFILE_ERR_MSG.MSG_9522;

    case LIVE_PROFILE_ERR_CODE.CODE_9525:
      return LIVE_PROFILE_ERR_MSG.MSG_9525;

    case LIVE_PROFILE_ERR_CODE.CODE_99999:
      return LIVE_PROFILE_ERR_MSG.MSG_99999;

    default:
      return LIVE_PROFILE_ERR_MSG.NONE;
  }
};

/** 疑似ライブプロファイル作成・更新APIのステータスとメッセージ変換 */
export const ToFileToLiveSetProfileStatusJap = (
  code: FILE_TO_LIVE_PROFILE_ERR_CODE
): FILE_TO_LIVE_PROFILE_ERR_MSG => {
  switch (code) {
    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_9021:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_9021;

    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_9503:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_9503;

    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_9512:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_9512;

    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_9516:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_9516;

    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_9521:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_9521;

    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_9527:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_9527;

    case FILE_TO_LIVE_PROFILE_ERR_CODE.CODE_99999:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.MSG_99999;

    default:
      return FILE_TO_LIVE_PROFILE_ERR_MSG.NONE;
  }
};

/** ライブステータスの日本変換 */
export const ToFileToLiveStatusJap = (
  status: FILE_TO_LIVE_STATUS_ENG
): FILE_TO_LIVE_STATUS => {
  switch (status) {
    case FILE_TO_LIVE_STATUS_ENG.WAITING:
      return FILE_TO_LIVE_STATUS.WAITING;

    case FILE_TO_LIVE_STATUS_ENG.READY:
      return FILE_TO_LIVE_STATUS.READY;

    case FILE_TO_LIVE_STATUS_ENG.STREAMING:
      return FILE_TO_LIVE_STATUS.STREAMING;

    case FILE_TO_LIVE_STATUS_ENG.FINISHED:
      return FILE_TO_LIVE_STATUS.FINISHED;

    case FILE_TO_LIVE_STATUS_ENG.ERROR:
      return FILE_TO_LIVE_STATUS.ERROR;

    default:
      return FILE_TO_LIVE_STATUS.NONE;
  }
};

/** ライブステータスの英語変換 */
export const ToFileToLiveStatusEng = (
  status: FILE_TO_LIVE_STATUS
): FILE_TO_LIVE_STATUS_ENG => {
  switch (status) {
    case FILE_TO_LIVE_STATUS.WAITING:
      return FILE_TO_LIVE_STATUS_ENG.WAITING;

    case FILE_TO_LIVE_STATUS.READY:
      return FILE_TO_LIVE_STATUS_ENG.READY;

    case FILE_TO_LIVE_STATUS.STREAMING:
      return FILE_TO_LIVE_STATUS_ENG.STREAMING;

    case FILE_TO_LIVE_STATUS.FINISHED:
      return FILE_TO_LIVE_STATUS_ENG.FINISHED;

    case FILE_TO_LIVE_STATUS.ERROR:
      return FILE_TO_LIVE_STATUS_ENG.ERROR;

    default:
      return FILE_TO_LIVE_STATUS_ENG.NONE;
  }
};
