import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormCheckBoxProps } from '../../../props/Parts';

/** ReactHookForm用チェックボックス */
const FormCheckBox = ({
  name,
  control,
  label = '',
  defaultChecked = false,
  disabled = false,
  className = '',
}: FormCheckBoxProps): JSX.Element => {
  return (
    <Controller
      name={`${name}` as const}
      control={control}
      defaultValue={defaultChecked}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          style={{ marginBottom: 4 }}
          control={
            <Checkbox
              color="primary"
              defaultChecked={defaultChecked}
              disabled={disabled}
              className={className}
              style={{ padding: '0 9px' }}
            />
          }
          {...field}
        />
      )}
    />
  );
};

export default FormCheckBox;
