import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  EQLiveProfile,
  GetContract,
  GetLiveProfile,
  GetAutoQuality,
  GetWowzaFeed,
} from '../../types/API';

import { setError, setLiveListStatus } from '../../features/uiSlice';
import { setContract, setAutoQuality } from '../../features/userSlice';
import {
  addEQLiveProfile,
  selectAllEQLives,
  setEQLiveProfile,
} from '../../features/livesSlice';
import { LIVELIST_FLOW_ERR_MSG } from '../../utils/Message';
import { LIVE_STATUS_ENG, MAX_PAGESIZE, UI_STATUS } from '../../utils/Const';
import formatDate from '../../utils/FormatDate';
import { LiveLists } from '../../types/Live';

export type GetLiveListFlowParams = {
  cid: number;
  userApiParams: string;
};
/** ライブプロファイル取得フロー */
export const getLiveListFlow =
  (arg: GetLiveListFlowParams): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setLiveListStatus(UI_STATUS.PROCESSING));

      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setContract({ ...responseGetContract.data }));

      // ライブプロファイル取得API実行
      const responseGetLiveProfile1: AxiosResponse<GetLiveProfile> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            pageSize: MAX_PAGESIZE,
            pageNumber: 1,
            portal: responseGetContract.data.serviceFlg39 ? 1 : 0,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      let liveProfilesGetted: EQLiveProfile[] =
        responseGetLiveProfile1.data.profile;

      // 100件以上のプロファイルがある場合に続きを取得
      if (responseGetLiveProfile1.data.livesum > MAX_PAGESIZE) {
        // ライブプロファイル取得API実行
        const responseGetLiveProfile2: AxiosResponse<GetLiveProfile> =
          await axios.post(
            `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
            {
              cid: arg.cid,
              userApiParams: arg.userApiParams,
              pageSize: MAX_PAGESIZE,
              pageNumber: 2,
              portal: responseGetContract.data.serviceFlg39 ? 1 : 0,
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        liveProfilesGetted = responseGetLiveProfile1.data.profile.concat(
          responseGetLiveProfile2.data.profile
        );
      }
      dispatch(
        setEQLiveProfile({
          livesum: responseGetLiveProfile1.data.livesum,
          profile: liveProfilesGetted,
        })
      );

      // 画面描画開始
      dispatch(setLiveListStatus(UI_STATUS.PROCESSING_QUALITY));

      /** 取得したライブIDとwlivesIDとステータスの配列 */
      const lpidServerIds: {
        status: LIVE_STATUS_ENG;
        dvrCloseDate: string | null;
        lpid: number;
        serverId: string;
      }[] = liveProfilesGetted
        .map((profile) => {
          return {
            status: profile.status,
            dvrCloseDate: profile.dvrCloseDate,
            lpid: profile.lpid,
            serverId: profile.jcloudWlives.liveId,
          };
        })
        .filter(
          (x) =>
            x.status === LIVE_STATUS_ENG.STOP &&
            !x.dvrCloseDate &&
            x.lpid !== null &&
            x.serverId
        );
      // 取得したwlivesIDのリスト
      const gettedWlivesIDs = lpidServerIds.map((prof) => prof.serverId);
      if (gettedWlivesIDs.length) {
        // Wowzaフィード確認APIを実行
        const responseGetWowzaFeed: AxiosResponse<GetWowzaFeed[]> =
          await axios.post(
            `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getWowzaFeed`,
            {
              wlivesIds: gettedWlivesIDs,
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const EQLives = selectAllEQLives(getState());
        // フィードがあればステータス変更
        responseGetWowzaFeed.data.forEach((element) => {
          if (element.mainFeed || element.backFeed) {
            const feedOnEQlive = EQLives.find(
              (prof) => prof.jcloudWlives.liveId === element.wlivesId
            );
            if (feedOnEQlive === undefined) {
              // eslint-disable-next-line no-throw-literal
              throw -1;
            }
            const cloneEQlive = { ...feedOnEQlive };
            // ステータスを配信準備中に
            cloneEQlive.status = LIVE_STATUS_ENG.PRE;
            dispatch(addEQLiveProfile(cloneEQlive));
          }
        });
      }

      // ライブ自動画質設定取得API実行
      const responseGetAutoQuality: AxiosResponse<GetAutoQuality> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getAutoQuality`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(setAutoQuality({ ...responseGetAutoQuality.data }));

      const EQLives = selectAllEQLives(getState());
      const updatedAutoQualityFlgProfiles: LiveLists[] = [];
      // 「ライブプロファイルの更新日時」が「紐づく自動画質設定の更新日時」よりも前かの判断をフラグへ格納
      EQLives.forEach((prof) => {
        responseGetAutoQuality.data.autoQuolity.profiles.forEach(
          (AutoQuality) => {
            // 旧ライブから移行されたlqpid=0のライブプロファイルはfalse
            if (prof.lqpid === 0) {
              const updatedAutoQualityFlg = false;
              updatedAutoQualityFlgProfiles.push({
                ...prof,
                updatedAutoQualityFlg,
              });
            }
            if (prof.lqpid === AutoQuality.lqpid) {
              const liveProfileUpdateDate = formatDate(prof.updateDate);
              const autoQualityUpdateDate = formatDate(AutoQuality.updateDate);
              if (
                liveProfileUpdateDate === null ||
                autoQualityUpdateDate === null
              ) {
                return;
              }
              const updatedAutoQualityFlg =
                liveProfileUpdateDate < autoQualityUpdateDate;
              updatedAutoQualityFlgProfiles.push({
                ...prof,
                updatedAutoQualityFlg,
              });
            }
          }
        );
      });
      dispatch(
        setEQLiveProfile({
          livesum: responseGetLiveProfile1.data.livesum,
          profile: updatedAutoQualityFlgProfiles,
        })
      );

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVELIST_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveListStatus(UI_STATUS.COMPLETED));
    }
  };
