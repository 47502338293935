import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Paper, PaperProps } from '@material-ui/core';
import Draggable from 'react-draggable';
import { Close } from '@material-ui/icons';

/** スタイル */
const useStyle = makeStyles({
  root: {},
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px #0000001F',
    border: '1px solid #F0F0F0',
    margin: 'auto',
    borderRadius: 8,
    overflowX: 'auto',
  },
  header: {
    padding: '20px 30px',
    borderBottom: '1px solid #F0F0F0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': {
      margin: 0,
      fontSize: 20,
    },
    cursor: 'move',
  },
  body: {
    padding: '30px 40px',
    overflow: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  bodyContain: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
  },
  player: {
    border: '1px dashed #70707080',
  },
  chat: {
    width: '100%',
  },
});

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

type propType = {
  open: boolean;
  handleClose: () => void;
  title: string;
  playerRef: React.RefObject<HTMLIFrameElement>;
  selectTabFlg: boolean;
  videoWidth: number;
  videoHeight: number;
  chatWidth?: number;
  chatHeight?: number;
  chatTag?: string;
};

/** 拡大プレビューモーダル */
const LargerViewModal = ({
  open,
  handleClose,
  title,
  playerRef,
  selectTabFlg,
  videoWidth,
  videoHeight,
  chatWidth,
  chatHeight,
  chatTag,
}: propType): JSX.Element => {
  const classes = useStyle();
  const commonHeight =
    videoHeight >= (chatHeight ?? 0) ? videoHeight : chatHeight;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={classes.root}
      style={{ display: open ? 'block' : 'none' }}
      maxWidth="xl"
    >
      <Box
        className={classes.container}
        style={{ width: videoWidth + (selectTabFlg ? 0 : chatWidth ?? 0) }}
      >
        <Box className={classes.header} id="draggable-dialog-title">
          <h3 style={{ fontSize: 20 }}>{title}</h3>
          <Close onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Box>
        <Box className={classes.body}>
          <Box className={classes.bodyContain}>
            <iframe
              referrerPolicy="no-referrer-when-downgrade"
              title="playerIframe"
              id="playerIframe"
              allowFullScreen
              frameBorder="0"
              className={classes.player}
              ref={playerRef}
              width={videoWidth}
              height={selectTabFlg ? videoHeight : commonHeight}
            />
            {!selectTabFlg && (
              <Box
                dangerouslySetInnerHTML={{ __html: chatTag ?? '' }}
                style={{
                  width: chatWidth,
                  height: commonHeight,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LargerViewModal;
