import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Breadcrumbs,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import {
  UI_STATUS,
  EDITIONS,
  ROUTE,
  getPath,
  MENUBAR,
  LIVE_TAB_NAME,
} from '../../utils/Const';
import { RootState } from '../../app/store';
import CommonBox from '../parts/CommonBox/CommonBox';
import DefaultInfo from '../LiveRegister/DefaultInfo';
import LiveLink from '../LiveRegister/LiveLink';
import SecuritySetting from '../LiveRegister/SecuritySetting';
import ChatSetting from '../LiveRegister/ChatSetting';
import LiveEvent from '../LiveRegister/LiveEvent';
import { HistoryContentProps } from '../../props/HistoryContent';

/** スタイル */
const useStyle = makeStyles({
  root: {},
  header: {
    fontSize: 24,
    margin: '20px 0',
    color: '#444444',
  },
  container: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 12px #1C385E0C',
    borderRadius: 8,
    padding: '40px 70px 40px',
    '& .MuiGrid-grid-xs-3': {
      maxWidth: 350,
      flexBasis: 350,
      fontWeight: 500,
      paddingRight: 48,
    },
    '& .MuiGrid-grid-xs-9': {
      maxWidth: 'calc(100% - 380px)',
      flexBasis: 'calc(100% - 380px)',
      paddingLeft: 30,
      paddingRight: 2,
    },
    '& .MuiInputBase-root.Mui-disabled, .MuiSelect-outlined.MuiSelect-outlined':
      {
        background: '#F0F0F0',
        color: '#333333',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiSelect-icon.Mui-disabled': {
          display: 'none',
        },
      },
    '& .MuiCheckbox-root:not(.Mui-checked)+.MuiFormControlLabel-label': {
      opacity: 0.2,
    },
    '& .MuiCheckbox-root.Mui-checked+.MuiFormControlLabel-label > .MuiBox-root':
      {
        background: '#F0F0F0',
        border: 'none',
      },
    '& .MuiCheckbox-root.Mui-checked+.MuiFormControlLabel-label .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled':
      {
        background: '#F0F0F0',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    '& .MuiCheckbox-root+.MuiFormControlLabel-label .MuiInputBase-root.Mui-disabled':
      {
        background: 'none',
        borderRadius: 100,
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #B9B9B9',
        },
      },
    '& .MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-disabled:not(.Mui-checked)':
      {
        color: '#B9B9B9',
      },
    '& label+.MuiInputBase-root.Mui-disabled': {
      background: '#FFFFFF',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(0, 0, 0, 0.26)',
      },
    },
    '& .MuiRadio-colorPrimary.Mui-disabled.Mui-checked, .MuiCheckbox-colorPrimary.Mui-disabled':
      {
        color: '#61B227',
        '&+.MuiFormControlLabel-label.Mui-disabled': {
          color: '#333333',
        },
      },
    '& .MuiFormControlLabel-root .MuiTypography-root': {
      fontSize: 15,
      fontWeight: 500,
    },
  },
  navigation: {
    marginBottom: 30,
    '& .MuiBottomNavigationAction-root': {
      maxWidth: 'calc(100% / 5)',
      borderBottom: '2px solid #F0F0F0',
      '&.Mui-selected': {
        borderColor: '#61B227',
        opacity: 1,
      },
    },
    '& .MuiBottomNavigationAction-label': {
      color: '#333333',
      fontSize: 16,
      fontWeight: 700,
      opacity: 0.4,
      '&.Mui-selected': {
        opacity: 1,
      },
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    '& a': {
      fontSize: 17,
      fontWeight: 500,
      borderRadius: 4,
      background: '#D8D8D8',
      color: '#333333',
      width: 140,
      height: 40,
      marginRight: 15,
      textDecoration: 'none',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        background: '#F4F4F4',
      },
    },
  },
  hidden: {
    display: 'none',
  },
  footer: {
    borderTop: '1px dashed #B9B9B9',
    paddingTop: 30,
    marginTop: 30,
  },
  breadcrumbs: {
    marginTop: 30,
    fontSize: 14,
    '& a': {
      color: '#333333',
    },
  },
});

/** ライブ配信設定履歴(UI) */
const HistoryContentComponent = (props: HistoryContentProps): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const user = useSelector((state: RootState) => state.user);
  const { edition, baseSetting } = props;
  const [selectTab, setSelectTab] = useState(LIVE_TAB_NAME.DEFAULT_INFO);
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
    trigger,
  } = useForm({
    criteriaMode: 'all',
    shouldFocusError: false,
  });

  // 処理中
  if (status.historyContent === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox navibar={MENUBAR.HISTORYLIST}>
      <h2 className={classes.header}>
        ライブイベント履歴 ｜ {baseSetting.liveName}
      </h2>
      <Box className={classes.container}>
        <BottomNavigation
          showLabels
          value={selectTab}
          onChange={(event, newValue) => {
            setSelectTab(newValue);
          }}
          className={classes.navigation}
        >
          <BottomNavigationAction
            label="基本設定"
            value={LIVE_TAB_NAME.DEFAULT_INFO}
          />
          <BottomNavigationAction
            label="動画からのリンク"
            value={LIVE_TAB_NAME.LIVE_LINK}
          />
          <BottomNavigationAction
            label="セキュリティ"
            value={LIVE_TAB_NAME.SECURITY_SETTING}
          />
          <BottomNavigationAction
            label="チャット設定"
            value={LIVE_TAB_NAME.CHAT_SETTING}
          />
          {(edition === EDITIONS.BUSINESS ||
            edition === EDITIONS.EXPERT ||
            edition === EDITIONS.EXPERT_2 ||
            edition === EDITIONS.ENTERPRISE) && (
            <BottomNavigationAction
              label="ライブイベント情報"
              value={LIVE_TAB_NAME.LIVE_EVENT}
            />
          )}
        </BottomNavigation>
        <>
          <DefaultInfo
            control={control}
            register={register}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            HistoryProps={props}
            open={selectTab === LIVE_TAB_NAME.DEFAULT_INFO}
          />
          <LiveLink
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            open={selectTab === LIVE_TAB_NAME.LIVE_LINK}
            HistoryProps={props}
          />
          <SecuritySetting
            control={control}
            watch={watch}
            HistoryProps={props}
            open={selectTab === LIVE_TAB_NAME.SECURITY_SETTING}
          />
          <ChatSetting
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            trigger={trigger}
            open={selectTab === LIVE_TAB_NAME.CHAT_SETTING}
            HistoryProps={props}
          />
          <LiveEvent
            register={register}
            HistoryProps={props}
            open={selectTab === LIVE_TAB_NAME.LIVE_EVENT}
          />
        </>
        <Box className={classes.footer}>
          <Box className={classes.buttons}>
            <Link
              to={`${getPath(ROUTE.HISTORY_LIST)}?c=${user.contract.cid}&t=${
                user.contract.readAPIToken
              }&u=${user.user}`}
            >
              一覧へ戻る
            </Link>
          </Box>
        </Box>
      </Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.breadcrumbs}
      >
        {[
          <Link
            to={`${getPath(ROUTE.HISTORY_LIST)}?c=${user.contract.cid}&t=${
              user.contract.readAPIToken
            }&u=${user.user}`}
          >
            ライブイベント履歴
          </Link>,
          <Typography style={{ fontSize: 14 }}>詳細</Typography>,
        ]}
      </Breadcrumbs>
    </CommonBox>
  );
};

export default HistoryContentComponent;
