import { GetWowzaFeed } from '../../types/API';
import { JCLOUD_SERVER_STATUS } from '../Const';

/** Wowzaフィード確認API Mockレスポンス */
const WowzaFeed = (wlivesIds: string[]): GetWowzaFeed[] => {
  return [...Array(wlivesIds.length)].map((_, i) => {
    const wlivesId: string[] = wlivesIds[i].split('-');
    return {
      wlivesId: wlivesIds[i],
      mainFeed: Number(wlivesId[1]) > 50,
      backFeed: false,
      wlivesStatus:
        Number(wlivesId[1]) > 50
          ? JCLOUD_SERVER_STATUS.CLOSED
          : JCLOUD_SERVER_STATUS.DEACTIVATEED,
    };
  });
};

export default WowzaFeed;
