import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  LiveEvent,
  GetHistoryProfile,
  GetLiveConnections,
  GetAutoQualityHistory,
} from '../types/API';
import { ViewerLog } from '../types/Utils';

/** entity用のアダプターを生成 */
// NOTE: デフォルトの並び順をライブIDの降順に変更し、lpidを主キーとしている
const historyliveAdapter = createEntityAdapter<LiveEvent>({
  selectId: (eqlive) => eqlive.lplid,
  sortComparer: (a, b) => (a.lplid < b.lplid ? 1 : -1),
});

type HistoriesEntityState = {
  /** ライブプロファイル数 */
  liveSum: number;

  /** ライブ履歴のエンティティ */
  events: EntityState<LiveEvent>;

  /** ライブ画質設定履歴 */
  qualityHistory: GetAutoQualityHistory;

  /** 同時接続数ログ */
  countLog?: ViewerLog[];
  countState: {
    max: number | null;
    current: number | null;
    total: number | null;
  };
};

const initialEntityState: HistoriesEntityState = {
  liveSum: 0,
  events: historyliveAdapter.getInitialState(),
  qualityHistory: {} as GetAutoQualityHistory,
  countLog: [],
  countState: { max: null, current: null, total: null },
};

const historiesSlice = createSlice({
  name: 'historyies',
  // 型以外に設定したいものを定義
  // NOTE: statusをもたせて、APIのレスポンス待ちですをUIに描画可能（ローディング中を表現できる）
  initialState: initialEntityState,
  reducers: {
    /** ライブ履歴プロファイルを更新 */
    setLiveEvent(state, action: PayloadAction<GetHistoryProfile>) {
      const { livesum, events } = action.payload;
      state.liveSum = livesum;
      historyliveAdapter.setAll(state.events, events ?? []);
    },
    /** ライブ履歴イベントを更新 */
    addLiveEvent(
      state,
      action: PayloadAction<{
        historyProfile: GetHistoryProfile;
        lplid: string;
      }>
    ) {
      const { historyProfile, lplid } = action.payload;
      const event = historyProfile.events.find(
        (event) => event.lplid === lplid
      );
      historyliveAdapter.upsertOne(state.events, event ?? ({} as LiveEvent));
    },
    /** 同時接続数を更新 */
    setHistoryCount(state, action: PayloadAction<GetLiveConnections>) {
      const { data, max, current, total } = action.payload;
      state.countLog = data;
      state.countState = { max, current, total };
    },
    /** 画質設定履歴を更新 */
    setQualityHistory(state, action: PayloadAction<GetAutoQualityHistory>) {
      state.qualityHistory = action.payload;
    },
  },
});

export const {
  setLiveEvent,
  addLiveEvent,
  setHistoryCount,
  setQualityHistory,
} = historiesSlice.actions;

export default historiesSlice.reducer;

/** EQライブプロファイル取得のselector */
export const {
  selectAll: selectAllHistoryLives,
  selectById: selectHistoryLivesById,
} = historyliveAdapter.getSelectors<RootState>(
  (state) => state.histories.events
);
