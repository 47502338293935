import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CommonBoxProps } from '../../../props/Parts';
import Logo from '../../../images/logo.png';
import {
  getPath,
  MENUBAR,
  ROUTE,
  SUPPORTPAGE_URL_FAQ,
  MIN_WIDTH,
} from '../../../utils/Const';
import { RootState } from '../../../app/store';

/** スタイル */
const useStyle = makeStyles({
  root: {
    color: '#333333',
    width: '100vw',
    backgroundSize: '100% 319px',
    background:
      'transparent linear-gradient(180deg, #CFEAB3 0%, #FBFBFB 100%) 0% 0% no-repeat padding-box',
    overflow: 'auto',
    '& input[type="number"]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
    '& .MuiInputBase-root:hover fieldset': {
      borderColor: '#C0C0C0',
    },
    '& .MuiInputBase-root.Mui-focused:hover fieldset': {
      borderColor: '#61B227',
    },
    '& .MuiInputBase-root.Mui-error fieldset': {
      borderColor: '#C32733',
    },
    '& .error .MuiInputBase-root fieldset, .error .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#C32733',
      },
    '& h2': {
      fontWeight: 500,
    },
  },
  container: {
    width: MIN_WIDTH,
    margin: '0 auto',
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
  header: {
    width: MIN_WIDTH,
    height: 46,
    margin: '16px auto 18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 13,
    },
  },
  state: {
    display: 'flex',
    background: '#FBFFF87B',
    borderRadius: 4,
    padding: 6,
    paddingRight: 10,
    alignItems: 'center',
    fontSize: 15,
  },
  badge: {
    background: '#FFFFFF',
    padding: '3px 7px',
    borderRadius: 2,
    marginRight: 8,
    fontSize: 13,
  },
  nav: {
    width: MIN_WIDTH,
    margin: '0 auto',
    display: 'flex',
    borderBottom: '2px solid rgba(97, 178, 39, 0.27)',
    borderRadius: 2,
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
  selector: {
    fontSize: 15,
    fontWeight: 500,
    paddingBottom: 18,
    marginBottom: -2,
    marginRight: 30,
    cursor: 'pointer',
    '&.active': {
      borderBottom: '2px solid rgba(97, 178, 39, 1)',
    },
    '&:hover': {
      color: '#61B227',
      transition: 'all 400ms ease',
    },
  },
  footer: {
    width: MIN_WIDTH,
    borderTop: '1px solid #E0E0E0',
    padding: '26px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: 50,
    '& a': {
      textDecoration: 'none',
      fontSize: 13,
      color: '#333333',
      display: 'flex',
      marginBottom: 18,
    },
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
});

/** 共通画面(UI) */
const CommonBox = ({
  children,
  className,
  navibar = MENUBAR.LIVELIST,
  width = MIN_WIDTH,
}: CommonBoxProps): JSX.Element => {
  const classes = useStyle();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  /** ライブの配信設定と開催へ遷移 */
  const goLiveList = () => {
    history.replace({
      pathname: getPath(ROUTE.LIVE_LIST),
      search: `?c=${user.contract.cid}&t=${user.contract.readAPIToken}&u=${user.user}`,
    });
  };

  /** ライブの履歴へ遷移 */
  const goLiveHistory = () => {
    history.replace({
      pathname: getPath(ROUTE.HISTORY_LIST),
      search: `?c=${user.contract.cid}&t=${user.contract.readAPIToken}&u=${user.user}`,
    });
  };

  /** 疑似ライブへ遷移 */
  const goFileToLive = () => {
    history.replace({
      pathname: getPath(ROUTE.FILE_TO_LIVE_LIST),
      search: `?c=${user.contract.cid}&t=${user.contract.readAPIToken}&u=${user.user}`,
    });
  };
  return (
    <Box className={`${classes.root} ${className}`}>
      <Box
        className={`common-header ${classes.header}`}
        style={{ minWidth: width }}
      >
        <Box
          fontSize="20px"
          fontWeight="700"
          color="#5F626E"
          className={classes.logo}
        >
          <img src={Logo} alt="logo" />
          EQ Management Console
        </Box>
        <Box className={classes.state}>
          <Box className={classes.badge}>契約会社</Box>
          <Box>
            {user.contract.cid}：{user.contract.companyName}
          </Box>
        </Box>
      </Box>
      <Box
        className={`common-navigation ${classes.nav}`}
        style={{ minWidth: width }}
      >
        <Box
          className={`${classes.selector} ${
            navibar === MENUBAR.LIVELIST ? 'active' : ''
          }`}
          onClick={goLiveList}
        >
          ライブの配信設定と開催
        </Box>
        <Box
          className={`${classes.selector} ${
            navibar === MENUBAR.HISTORYLIST ? 'active' : ''
          }`}
          onClick={goLiveHistory}
        >
          ライブイベント履歴
        </Box>
        <Box
          className={`${classes.selector} ${
            navibar === MENUBAR.FILETOLIVE ? 'active' : ''
          }`}
          onClick={goFileToLive}
        >
          疑似ライブ管理
        </Box>
      </Box>
      <Box className={classes.container} style={{ minWidth: width }}>
        {children}
      </Box>
      <div id="help-script" />
      <Box className={classes.footer} style={{ minWidth: width }}>
        <a href={SUPPORTPAGE_URL_FAQ} target="_blank" rel="noreferrer">
          <Help fontSize="small" style={{ marginRight: 4, opacity: 0.6 }} />
          よくあるご質問・お問い合わせはこちらから
        </a>
        <span style={{ color: '#3333336E', fontSize: 12 }}>
          株式会社Ｊストリーム Copyright (C) J-Stream Inc. All Rights Reserved.
        </span>
      </Box>
    </Box>
  );
};

export default CommonBox;
