import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Breadcrumbs,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  NavigateNext,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import CommonBox from '../parts/CommonBox/CommonBox';
import WarningDialog from '../parts/WarningDialog/WarningDialog';
import { LiveMonitorProps } from '../../props/LiveMonitor';
import {
  STREMAS_ENG,
  MENUBAR,
  MONITORING_PLAYERSIZE,
  UI_STATUS,
  getPath,
  ROUTE,
} from '../../utils/Const';
import { RootState } from '../../app/store';

/** スタイル */
const useStyle = makeStyles({
  root: {},
  header: {
    fontSize: 24,
    margin: '20px 0',
    color: '#444444',
  },
  body: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 8,
    padding: '50px 70px',
  },
  status: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    padding: '16px 20px',
    fontSize: 14,
  },
  title: {
    borderLeft: '2px solid #61B227',
    paddingLeft: 20,
    marginTop: 30,
    marginBottom: 30,
    fontSize: 15,
    fontWeight: 500,
  },
  badge: {
    border: '1px solid #61B227',
    borderRadius: 4,
    padding: '2px 0',
    textAlign: 'center',
    width: 80,
    boxSizing: 'border-box',
    marginLeft: 15,
  },
  good: {
    background: '#61B2270D',
    borderColor: '#61B227',
  },
  bad: {
    background: '#C327330D',
    borderColor: '#C32733',
  },
  none: {
    background: '#4A4A4A0D',
    borderColor: '#4A4A4A',
  },
  section: {
    background: '#FFFFFF',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    marginBottom: 10,
    fontSize: 15,
    fontWeight: 500,
    boxShadow: '0px 4px 12px #1C385E0C',
  },
  sectionHead: {
    padding: '15px 25px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
  },
  sectionBody: {
    borderTop: '1px solid #F0F0F0',
    padding: '20px 25px 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 20,
  },
  player: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 25,
    padding: 13,
    border: '1px solid #B9B9B9',
    borderRadius: 4,
    width: MONITORING_PLAYERSIZE.WIDTH,
    height: MONITORING_PLAYERSIZE.HEIGHT,
  },
  playerTitle: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    padding: '0 2px',
    backgroundColor: '#FFFFFF',
    transform: 'translateY(-50%) translateX(10px)',
    color: '#33333399',
  },
  playerIframe: {
    width: '100%',
    height: '100%',
    display: 'inline',
  },
  footer: {
    marginTop: 30,
    '& a': {
      color: '#333333',
    },
  },
});

/** 配信モニタリング(UI) */
const LiveMonitorComponent = (props: LiveMonitorProps): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const [dialog, setDialog] = useState<boolean>(true);
  const {
    handleAccordion,
    activeSection,
    mainPlayerRef,
    backPlayerRef,
    originalUrlActivateFlg,
    fullHDlUrlActivateFlg,
    HDlUrlActivateFlg,
    highlUrlActivateFlg,
    standardlUrlActivateFlg,
    lowUrlActivateFlg,
    cid,
    readAPIToken,
    userApiParams,
  } = props;
  // 処理中
  if (status.liveMonitor === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox className={classes.root} navibar={MENUBAR.LIVELIST}>
      <h2 className={classes.header}>配信映像モニタリング</h2>
      <Box className={classes.body}>
        <p
          style={{
            borderBottom: '1px dashed #B9B9B9',
            paddingBottom: 30,
            fontSize: 15,
          }}
        >
          選択しているライブ自動画質設定の画質レンディションについて、モニタリングが可能です。
        </p>
        <Box className={classes.title}>モニタリング画質チェック</Box>
        {originalUrlActivateFlg && (
          <Box className={classes.section}>
            <Box
              className={classes.sectionHead}
              onClick={() => handleAccordion(STREMAS_ENG.ORIGIN)}
            >
              オリジナル画質
              {activeSection.find((x) => x === STREMAS_ENG.ORIGIN) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Box>
            {activeSection.find((x) => x === STREMAS_ENG.ORIGIN) && (
              <Box className={classes.sectionBody}>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>メイン映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-origin-main"
                    id="playerIframe-origin-main"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={mainPlayerRef.original}
                    scrolling="no"
                  />
                </Box>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>バックアップ映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-origin-back"
                    id="playerIframe-origin-back"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={backPlayerRef.original}
                    scrolling="no"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {fullHDlUrlActivateFlg && (
          <Box className={classes.section}>
            <Box
              className={classes.sectionHead}
              onClick={() => handleAccordion(STREMAS_ENG.FULLHD)}
            >
              フルHD画質
              {activeSection.find((x) => x === STREMAS_ENG.FULLHD) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Box>
            {activeSection.find((x) => x === STREMAS_ENG.FULLHD) && (
              <Box className={classes.sectionBody}>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>メイン映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-FHD-main"
                    id="playerIframe-FHD-main"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={mainPlayerRef.fhd}
                    scrolling="no"
                  />
                </Box>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>バックアップ映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-FHD-back"
                    id="playerIframe-FHD-back"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={backPlayerRef.fhd}
                    scrolling="no"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {HDlUrlActivateFlg && (
          <Box className={classes.section}>
            <Box
              className={classes.sectionHead}
              onClick={() => handleAccordion(STREMAS_ENG.HD)}
            >
              HD画質
              {activeSection.find((x) => x === STREMAS_ENG.HD) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Box>
            {activeSection.find((x) => x === STREMAS_ENG.HD) && (
              <Box className={classes.sectionBody}>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>メイン映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-HD-main"
                    id="playerIframe-HD-main"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={mainPlayerRef.hd}
                    scrolling="no"
                  />
                </Box>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>バックアップ映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-HD-back"
                    id="playerIframe-HD-back"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={backPlayerRef.hd}
                    scrolling="no"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {highlUrlActivateFlg && (
          <Box className={classes.section}>
            <Box
              className={classes.sectionHead}
              onClick={() => handleAccordion(STREMAS_ENG.HIGH)}
            >
              高画質
              {activeSection.find((x) => x === STREMAS_ENG.HIGH) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Box>
            {activeSection.find((x) => x === STREMAS_ENG.HIGH) && (
              <Box className={classes.sectionBody}>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>メイン映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-high-main"
                    id="playerIframe-high-main"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={mainPlayerRef.high}
                    scrolling="no"
                  />
                </Box>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>バックアップ映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-high-back"
                    id="playerIframe-high-back"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={backPlayerRef.high}
                    scrolling="no"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {standardlUrlActivateFlg && (
          <Box className={classes.section}>
            <Box
              className={classes.sectionHead}
              onClick={() => handleAccordion(STREMAS_ENG.STANDARD)}
            >
              標準画質
              {activeSection.find((x) => x === STREMAS_ENG.STANDARD) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Box>
            {activeSection.find((x) => x === STREMAS_ENG.STANDARD) && (
              <Box className={classes.sectionBody}>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>メイン映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-standard-main"
                    id="playerIframe-standard-main"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={mainPlayerRef.standard}
                    scrolling="no"
                  />
                </Box>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>バックアップ映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-stadard-back"
                    id="playerIframe-standard-back"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={backPlayerRef.standard}
                    scrolling="no"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {lowUrlActivateFlg && (
          <Box className={classes.section}>
            <Box
              className={classes.sectionHead}
              onClick={() => handleAccordion(STREMAS_ENG.LOW)}
            >
              低画質
              {activeSection.find((x) => x === STREMAS_ENG.LOW) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Box>
            {activeSection.find((x) => x === STREMAS_ENG.LOW) && (
              <Box className={classes.sectionBody}>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>メイン映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-low-main"
                    id="playerIframe-low-main"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={mainPlayerRef.low}
                    scrolling="no"
                  />
                </Box>
                <Box className={classes.player}>
                  <span className={classes.playerTitle}>バックアップ映像</span>
                  <iframe
                    referrerPolicy="no-referrer-when-downgrade"
                    title="playerIframe-low-back"
                    id="playerIframe-low-back"
                    allowFullScreen
                    frameBorder="0"
                    className={classes.playerIframe}
                    ref={backPlayerRef.low}
                    scrolling="no"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box className={classes.footer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {[
            <Link
              to={`${getPath(
                ROUTE.LIVE_LIST
              )}?c=${cid}&t=${readAPIToken}&u=${userApiParams}`}
            >
              ライブの配信設定と開催
            </Link>,
            <Typography key="3">配信映像モニタリング</Typography>,
          ]}
        </Breadcrumbs>
      </Box>
      <WarningDialog
        open={dialog}
        handleClose={() => setDialog(false)}
        handleOK={() => setDialog(false)}
        header="【ご注意】モニタリング負荷について"
        body={
          <>
            ここのモニタリング画面は、モニタリングする画質レンディション数によっては、ご使用のパソコンに高負荷が掛かる場合がございますので、必ずライブコントロールパネル操作中のパソコンとは別のパソコンでご利用ください。
            <br />
            （流量・ログもカウントされます）
          </>
        }
        ok="OK"
      />
    </CommonBox>
  );
};

export default LiveMonitorComponent;
