import axios from 'axios';
import { AppThunk } from '../../app/store';
import { setError } from '../../features/uiSlice';
import { SET_LIVE_EVENT_API_ERR_MSG } from '../../utils/Message';

type Arg = {
  cid: number;
  userApiParams: string;
  lpid: number;
  leid: number;
  eventName: string;
};

/** ライブイベント名変更フロー */
const UpdateLiveEventName =
  (arg: Arg): AppThunk =>
  async (dispatch) => {
    try {
      // ライブイベント情報更新API実行
      await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}setEvent`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          lpid: arg.lpid,
          leid: arg.leid,
          event_name: arg.eventName,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: SET_LIVE_EVENT_API_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    }
  };

export default UpdateLiveEventName;
