import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Box, Button } from '@material-ui/core';
import { Error } from '@material-ui/icons';

/** スタイル */
const useStyle = makeStyles({
  root: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px #0000001F',
    width: 670,
    height: 'auto',
    border: 'none',
    borderRadius: 8,
    '&:focus-visible': {
      outline: 'none',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: '#C32733',
    color: '#FFFFFF',
    padding: '20px 35px',
    borderRadius: '8px 8px 0px 0px',
    fontSize: 20,
    fontWeight: 500,
  },
  body: {
    padding: '20px 35px',
    fontSize: 15,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0 35px 25px',
    '& button': {
      fontSize: 14,
      fontWeight: 500,
      borderRadius: 4,
      width: 100,
    },
  },
  buttonLeft: {
    background: '#4A8BFB',
    borderBottom: '1px solid #3C72D0',
    color: '#FFFFFF',
    marginRight: 10,
    '&:hover': {
      background: '#015CFA',
    },
  },
  buttonRight: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    color: '#333333',
    '&:hover': {
      background: '#F4F4F4',
    },
  },
});

type propType = {
  open: boolean;
  handleClose: () => void;
  handleOK?: () => void;
  header: string | React.ReactNode;
  body: string | React.ReactNode;
  cancel?: string | null;
  ok?: string;
  buttonLeft?: 'ok' | 'cancel';
};

/** 警告ダイアログ(UI) */
const WarningDialog = ({
  open,
  handleClose,
  handleOK,
  header,
  body,
  cancel = '',
  ok,
  buttonLeft = 'cancel',
}: propType): JSX.Element => {
  const classes = useStyle();
  /** ボタン描画 */
  const renderButton = () => {
    if (buttonLeft === 'ok') {
      return (
        <>
          {ok && (
            <Button
              className={classes.buttonLeft}
              onClick={() => {
                if (handleOK) handleOK();
              }}
            >
              {ok || 'OK'}
            </Button>
          )}
          {cancel && (
            <Button className={classes.buttonRight} onClick={handleClose}>
              {cancel || 'キャンセル'}
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        {cancel && (
          <Button className={classes.buttonLeft} onClick={handleClose}>
            {cancel || 'キャンセル'}
          </Button>
        )}
        {ok && (
          <Button
            className={classes.buttonRight}
            onClick={() => {
              if (handleOK) handleOK();
            }}
          >
            {ok || 'OK'}
          </Button>
        )}
      </>
    );
  };
  return (
    <Modal
      className={classes.root}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Error style={{ marginRight: 10 }} />
          {header}
        </Box>
        <Box className={classes.body}>{body}</Box>
        <Box className={classes.buttons}>{renderButton()}</Box>
      </Box>
    </Modal>
  );
};

export default WarningDialog;
