import React from 'react';
import { Modal, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/** スタイル */
const useStyle = makeStyles({
  root: {
    display: 'flex',
    fontSize: 13,
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: 20,
  },
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px #0000001F',
    border: '1px solid #F0F0F0',
    height: 'auto',
    margin: 'auto',
    marginTop: 'calc(100vh / 2 - 110px)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: '45px 35px',
  },
  text: {
    color: '#333333',
    fontSize: 15,
    marginLeft: 20,
  },
});

/** プログレスモーダル */
const ProgressModal = ({
  open,
  title,
  info,
}: {
  open: boolean;
  title: string;
  info?: string;
}): JSX.Element => {
  const classes = useStyle();
  return (
    <Modal
      className={classes.root}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      <Box className={classes.container}>
        <CircularProgress />
        <Box className={classes.text}>
          <h3 style={{ margin: 0, fontSize: 20, fontWeight: 500 }}>{title}</h3>
          {info && <span style={{ opacity: 0.5 }}>{info}</span>}
        </Box>
      </Box>
    </Modal>
  );
};

export default ProgressModal;
