// ライブプロファイル保存処理用の便利クラス
import moment from 'moment';
import { InputData } from '../props/FileToLiveRegister';
import { CHAT_END_TIMING, EDITIONS, FILE_TO_LIVE_FILLER_ID } from './Const';

/** 画面入力項目のパラメータ設定 */
const setInputParams = (data: InputData, edition: EDITIONS): FormData => {
  const formData = new FormData();
  // 本編の動画ID
  formData.append('mid', String(data.BaseSettingMid));
  // 疑似ライブ名
  formData.append('name', data.BaseSettingLiveName);
  // 公開期間 - 開始
  formData.append(
    'start_date',
    moment(`${data.BaseSettingStartDate} ${data.BaseSettingStartTime}`).format(
      'YYYY-MM-DD HH:mm:ss'
    )
  );
  // 疑似ライブ設定説明
  formData.append('description', data.BaseSettingLiveDescription);
  // 同時接続数制限
  formData.append(
    'max_client',
    edition === EDITIONS.U_LIVE ? '0' : String(data.BaseSettingMaxClient)
  );

  // チャット設定利用可否
  formData.append('chat_flg', data.ChatSettingEnable === 'true' ? '1' : '0');
  // チャットの自動リサイズ
  formData.append(
    'chat_auto_resize',
    data.ChatSettingAutoResize === 'true' ? '1' : '0'
  );
  // チャットサイズ
  if (data.ChatSettingAutoResize === 'false') {
    // チャットwidth
    formData.append('chat_width', String(data.ChatSettingAutoResizeWidth));
    // チャットheight
    formData.append('chat_height', String(data.ChatSettingAutoResizeHeight));
  }
  // チャット 開始タイミング（分）
  formData.append('chat_open_timing', String(data.ChatSettingStartTiming));
  // チャット 終了タイミング（分）
  if (Number(data.ChatSettingEndTiming) === CHAT_END_TIMING.MINUTES_AUTO) {
    formData.append(
      'chat_close_timing',
      String(data.ChatSettingEndTimingAutoEndMinutes)
    );
  } else {
    formData.append('chat_close_timing', String(data.ChatSettingEndTiming));
  }
  // 投稿されるメッセージ管理
  formData.append('chat_block_type', String(data.ChatSettingBlockType));
  // 入退室メッセージの表示
  formData.append('chat_enter_msg', String(data.ChatSettingEnterMsg));
  // いいねボタン表示
  formData.append('chat_good_btn', data.ChatSettingGoodBtn ? '1' : '0');
  // わかりませんボタン表示
  formData.append('chat_trouble_btn', data.ChatSettingTroubleBtn ? '1' : '0');
  // チャット任意のリアクションボタンテキストの利用有無
  formData.append(
    'chat_icon_text_flg[]',
    data.ChatSettingCustomBtn1Enable ? '1' : '0'
  );
  formData.append(
    'chat_icon_text_flg[]',
    data.ChatSettingCustomBtn2Enable ? '1' : '0'
  );
  // チャット 任意のリアクションボタンテキスト
  formData.append('chat_icon_text[]', data.ChatSettingCustomBtn1Name);
  formData.append('chat_icon_text[]', data.ChatSettingCustomBtn2Name);
  // 管理者表示名
  formData.append(
    'chat_display_name',
    data.ChatSettingAdminDisplayName === 'true'
      ? data.ChatSettingAdminDisplayNameSelect
      : data.ChatSettingAdminDisplayNameOther
  );
  // ブロックする文字列（カンマ区切り）
  formData.append('chat_block_words', data.ChatSettingBlockWords);

  // 配信停止サムネイル
  if (data.UpdateThumbnailFeedout && !data.UpdateThumbnailFeedout?.errors)
    formData.append('thumb_feedout', data.UpdateThumbnailFeedout);
  // 配信準備サムネイル
  if (data.UpdateThumbnailFeedin && !data.UpdateThumbnailFeedin?.errors)
    formData.append('thumb_feedin', data.UpdateThumbnailFeedin);
  // 配信終了サムネイル
  if (data.UpdateThumbnailLiveopen && !data.UpdateThumbnailLiveopen?.errors)
    formData.append('thumb_liveopen', data.UpdateThumbnailLiveopen);
  // サムネイル-配信停止登録日時
  if (data.UpdateThumbnailFeedoutDate)
    formData.append('thumb_feedout_datetime', data.UpdateThumbnailFeedoutDate);
  // サムネイル-配信準備登録日時
  if (data.UpdateThumbnailFeedinDate)
    formData.append('thumb_feedin_datetime', data.UpdateThumbnailFeedinDate);
  // サムネイル-配信終了登録日時
  if (data.UpdateThumbnailLiveopenDate)
    formData.append(
      'thumb_liveopen_datetime',
      data.UpdateThumbnailLiveopenDate
    );

  // 開始前フィラー映像にする動画ID
  if (data.FillerSettingPrefillerUseDefault) {
    formData.append(
      'prefiller_mid',
      Number(data.FillerSettingPrefillerUseDefault) ===
        FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT
        ? String(FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT)
        : String(data.FillerSettingSelectPrefillerMid)
    );
  } else {
    formData.append('prefiller_mid', '0');
  }
  // 終了後フィラー映像にする動画ID
  if (data.FillerSettingPostfillerUseDefault) {
    formData.append(
      'postfiller_mid',
      Number(data.FillerSettingPostfillerUseDefault) ===
        FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT
        ? String(FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT)
        : String(data.FillerSettingSelectPostfillerMid)
    );
  } else {
    formData.append('postfiller_mid', '0');
  }

  // ライブイベント情報 - イベント名
  formData.append('potal_meta_event_name', data.LiveEventInfoName);
  // ライブイベント情報 - イベント説明
  formData.append(
    'potal_meta_event_description',
    data.LiveEventInfoDescription
  );
  // ライブイベント情報 - 出演者
  formData.append('potal_meta_performer', data.LiveEventInfoPerformer);

  return formData;
};

export default setInputParams;
