import React, { useEffect, useState } from 'react';
import {
  UseFormRegister,
  UseFormClearErrors,
  UseFormWatch,
  FieldValues,
  RegisterOptions,
  UseFormSetError,
  UseFormSetValue,
  Controller,
  Control,
} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import { InfoOutlined, Help } from '@material-ui/icons';
import moment from 'moment';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FormTextBox from '../parts/FormTextBox/FormTextBox';
import Tooltip from '../parts/Tooltip/Tooltip';
import {
  EDITIONS,
  SUPPORTPAGE_URL_FILETOLIVEREGISTER,
  UI_STATUS,
} from '../../utils/Const';
import {
  REQUIRED_MSG,
  getMinValueMsg,
  FILE_TO_LIVE_UNSELECTED_MID_MSG,
  FILE_SEARCH_MID_TEXT_STRING_MSG,
  FILE_TO_LIVE_START_DATETIME_INVALID_MSG,
} from '../../utils/Message';
import { FileToLiveRegisterProps } from '../../props/FileToLiveRegister';
import FormSearchTextBox from '../parts/FormSearchTextBox/FormSearchTextBox';
import { EQFileToLiveMovie } from '../../types/API';
import { FileToLiveContentProps } from '../../props/FileToLiveContent';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& p': {
      margin: 0,
      fontSize: 13,
    },
    '& a': {
      color: '#4472C4',
    },
    '& .MuiGrid-item': {
      padding: '25px 32px',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 36,
    marginBottom: 30,
    fontSize: 15,
  },
  lpid: {
    background: '#F0F0F0',
    borderRadius: 4,
    width: 90,
    '& .MuiInputBase-root': {
      height: 40,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      textAlign: 'center',
    },
  },
  discription: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    opacity: 0.6,
    fontSize: 14,
  },
  title: {
    borderLeft: '2px solid #61B227',
    paddingLeft: 20,
    marginLeft: 30,
    marginTop: 13,
    fontSize: 15,
    fontWeight: 500,
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '20px 22px',
    color: '#333333',
    '& p': {
      marginBottom: 20,
    },
  },
  helpContainer: {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  tooltipSmall: {
    '&:hover .tooltip': {
      width: 100,
      marginLeft: '-50px',
      '& .balloon:before': {
        marginLeft: 40,
      },
    },
  },
  help: {
    color: '#B6BEC9',
    marginLeft: 5,
    marginBottom: '-2px',
    width: 16,
    height: 16,
  },
  icon: {
    color: '#333333',
    marginRight: 4,
  },
  badge: {
    background: '#C32733',
    color: '#FFFFFF',
    padding: '2px 8px',
    fontSize: 12,
    borderRadius: 4,
    marginLeft: 15,
  },
  whiteInnerTop: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    marginTop: 14,
    padding: '20px 10px',
  },
  whiteInner: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    marginTop: 14,
    padding: '16px 22px',
    '& p': {
      marginBottom: 20,
    },
  },
  midAttention: {
    background: '#C3273305',
    border: '1px solid #C32733',
    borderRadius: 4,
    color: '#C32733',
    padding: 10,
    marginBottom: 15,
  },
  midDiscription: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
  },
  redIcon: {
    color: '#C32733',
    marginRight: 4,
    paddingTop: 3,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: '#C32733',
    fontSize: 13,
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    fontSize: 15,
  },
  inputDate: {
    background: '#FFFFFF',
    margin: '0 10px',
    '& .MuiOutlinedInput-root': {
      height: 40,
      width: 160,
      fontSize: 15,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  inputTime: {
    background: '#FFFFFF',
    margin: '0 10px',
    '& .MuiOutlinedInput-root': {
      height: 40,
      width: 123,
      fontSize: 15,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
});

/** 基本設定 */
const DefaultInfo = ({
  register,
  control,
  errors,
  watch,
  clearErrors,
  setError,
  setValue,
  RegisterProps,
  ContentProps,
  open = false,
  getMediaList,
  movies,
  moviesStatus,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  errors?: Record<string, { message: string }>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  RegisterProps?: FileToLiveRegisterProps;
  // TODO 詳細＆履歴
  ContentProps?: FileToLiveContentProps;
  open?: boolean;
  getMediaList: (mid: string) => void;
  movies: EQFileToLiveMovie[];
  moviesStatus: UI_STATUS;
}): JSX.Element => {
  const classes = useStyle();
  const edition = RegisterProps?.edition || ContentProps?.edition;
  // TODO 配信予約中以外は無効にする
  const disableFlg = ContentProps?.disableFlg || false;
  const maxClientLimitDisableFlg =
    ContentProps?.maxClientLimitDisableFlg || false;

  const registerSetting = RegisterProps?.baseSetting;
  const contentSetting = ContentProps?.baseSetting;

  const maxClient =
    registerSetting?.maxClient || contentSetting?.maxClient || 0;

  const maxClientValidation: RegisterOptions = {
    required: edition === EDITIONS.U_LIVE ? false : REQUIRED_MSG,
    pattern: /^[0-9]+$/,
    min: {
      value: 1,
      message: getMinValueMsg(1),
    },
  };
  const DATE_FORMAT = 'YYYY/MM/DD';
  const TIME_FORMAT = 'HH:mm';
  const [startDate, setStartDate] = useState(
    contentSetting?.startDate || moment().add(1, 'hour').toDate()
  );
  const [startTime, setStartTime] = useState(
    contentSetting?.startDate || moment().add(1, 'hour').toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    contentSetting?.endDate || null
  );
  const [endTime, setEndTime] = useState<Date | null>(
    contentSetting?.endDate || null
  );

  const handleStartDate = (value: MaterialUiPickersDate | string) => {
    const date = moment(value);
    setStartDate(date.toDate());
    setValue('BaseSettingStartDate', date.format(DATE_FORMAT), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const handleStartTime = (value: MaterialUiPickersDate | string) => {
    const date = moment(value, TIME_FORMAT);
    setStartTime(date.toDate());
    setValue('BaseSettingStartTime', date.format(TIME_FORMAT), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const startEndDateTimeValidation = {
    validate: {
      startDateTime: () => {
        clearErrors('BaseSettingStartDateTime');
        const watchStartDate = watch('BaseSettingStartDate');
        const watchStartTime = watch('BaseSettingStartTime');
        if (
          !moment(watchStartDate, DATE_FORMAT).isValid() ||
          !moment(watchStartTime, TIME_FORMAT).isValid()
        ) {
          setError('BaseSettingStartDateTime', { message: REQUIRED_MSG });
          return false;
        }
        const startDateTime = moment(
          watchStartDate + watchStartTime,
          DATE_FORMAT + TIME_FORMAT
        );
        if (startDateTime.diff(moment(), 'minutes') < 30) {
          setError('BaseSettingStartDateTime', {
            message: FILE_TO_LIVE_START_DATETIME_INVALID_MSG,
          });
          return false;
        }
        return true;
      },
    },
  };
  const calculateEndDateTime = () => {
    const watchStartDate = watch('BaseSettingStartDate');
    const watchStartTime = watch('BaseSettingStartTime');
    const DATETIME_FORMAT = DATE_FORMAT + TIME_FORMAT;
    const date = moment(watchStartDate + watchStartTime, DATETIME_FORMAT);
    if (
      moment(startDate).format(DATETIME_FORMAT) !== date.format(DATETIME_FORMAT)
    ) {
      setStartDate(date.toDate());
      setStartTime(date.toDate());
    }
    const mid = watch('BaseSettingMid');
    const row = movies.find((val) => val.mid === mid);
    if (
      !row ||
      !moment(watchStartDate, DATE_FORMAT).isValid() ||
      !moment(watchStartTime, TIME_FORMAT).isValid()
    ) {
      setEndDate(null);
      setEndTime(null);
      return;
    }
    const endDateTime = date.add(row.duration, 's');
    setEndDate(endDateTime.toDate());
    setEndTime(endDateTime.toDate());
  };
  const handleMidClick = (event: React.MouseEvent<unknown>, mid: number) => {
    const row = movies.find((val) => val.mid === mid);
    if (!row) {
      setValue('SearchMidName', '');
      return;
    }
    setValue('SearchMidName', `${row.mid}：${row.title}`);
  };

  useEffect(() => {
    if (contentSetting?.mid) {
      const row = movies.find((val) => val.mid === contentSetting?.mid);
      if (row) {
        setValue('SearchMidName', `${row.mid}：${row.title}`);
      }
      return;
    }
    if (contentSetting) setValue('SearchMidName', '');
  }, [contentSetting?.mid]);

  useEffect(() => {
    const name = watch('SearchMidName');
    const row = movies.find((val) => `${val.mid}：${val.title}` === name);
    if (!row) {
      setValue('BaseSettingMid', null);
      const result = /\s+/.test(name);
      const resultNum = /\d+/.test(name);
      if (name === '' || (!result && resultNum)) {
        getMediaList(name);
      }
    }
    if (row) {
      setValue('BaseSettingMid', row.mid);
      clearErrors('SearchMidName');
    }
    calculateEndDateTime();
  }, [watch('SearchMidName')]);

  useEffect(() => {
    calculateEndDateTime();
  }, [watch('BaseSettingStartDate'), watch('BaseSettingStartTime')]);

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        疑似ライブ配信設定の内容情報などを設定します。
        <a
          href={SUPPORTPAGE_URL_FILETOLIVEREGISTER}
          target="_blank"
          rel="noreferrer"
        >
          カスタマーサイトで設定方法を確認する。
        </a>
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          プロファイルID
        </Grid>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            value={contentSetting ? contentSetting?.flpid : '-'}
            disabled
            className={classes.lpid}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          動画ID
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.whiteInnerTop}>
            <p className={classes.discription}>
              リストには選択可能な最新100件の動画のみ表示されます。リスト以外の動画を表示する場合は動画ID検索をご利用ください。
            </p>
            <Box className={classes.whiteInner}>
              <FormSearchTextBox
                name="SearchMidName"
                width="fullWidth"
                register={register}
                setValue={setValue}
                validation={{
                  validate: {
                    selected: (data) => {
                      if (!watch('BaseSettingMid') && /\s+/.test(data)) {
                        return FILE_SEARCH_MID_TEXT_STRING_MSG;
                      }
                      if (!watch('BaseSettingMid') && !/\d+/.test(data)) {
                        return FILE_SEARCH_MID_TEXT_STRING_MSG;
                      }
                      if (!watch('BaseSettingMid')) {
                        return FILE_TO_LIVE_UNSELECTED_MID_MSG;
                      }
                      return true;
                    },
                  },
                }}
                maxLength={100}
                validationError={Boolean(errors?.SearchMidName)}
                validationErrorMsg={
                  errors?.SearchMidName?.message
                    ? String(errors?.SearchMidName?.message)
                    : ''
                }
                disabled={disableFlg || Boolean(contentSetting)}
                className={
                  !disableFlg && !watch('BaseSettingMid') ? 'error' : ''
                }
              />
              {moviesStatus === UI_STATUS.COMPLETED && movies.length === 0 && (
                <Box className={classes.error}>
                  利用可能な動画が存在しません。
                </Box>
              )}
              {moviesStatus === UI_STATUS.COMPLETED &&
                !watch('BaseSettingMid') && (
                  <TableContainer component={Paper} style={{ maxHeight: 312 }}>
                    <Table>
                      <TableBody>
                        {movies.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.mid}
                            >
                              <TableCell
                                key={row.mid}
                                align="left"
                                onClick={(event) =>
                                  handleMidClick(event, row.mid)
                                }
                              >
                                {row.mid}：{row.title}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              &emsp;
              <Box className={classes.midAttention}>
                <Box className={classes.midDiscription}>
                  <InfoOutlined fontSize="small" className={classes.redIcon} />
                  疑似ライブ本編映像として利用可能な動画は、以下の3つの条件を「すべて」満たすものに限られます。
                </Box>
                <Box className={classes.midDiscription}>
                  ・ストリーミング配信用ファイルが生成されている動画
                </Box>
                <Box className={classes.midDiscription}>
                  ・かつ、動画尺が「1分以上－8時間未満」の長さの動画
                </Box>
                <Box className={classes.midDiscription}>
                  ・かつ、エラーなどが発生していない状態の動画
                </Box>
                <Box className={classes.midDiscription}>
                  上記条件を満たさない動画は選択肢に表示されてきませんのでご注意ください。
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          疑似ライブ配信設定名
          <span
            className={classes.helpContainer}
            style={{ display: 'inline-block' }}
          >
            <Help fontSize="small" className={classes.help} />
            <Tooltip message="管理画面で管理するための名称です。" />
          </span>
          {!disableFlg && <span className={classes.badge}>必須</span>}
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="BaseSettingLiveName"
            width="fullWidth"
            register={register}
            validation={{
              required: disableFlg ? false : REQUIRED_MSG,
            }}
            maxLength={100}
            validationError={Boolean(errors?.BaseSettingLiveName)}
            validationErrorMsg={
              errors?.BaseSettingLiveName?.message
                ? String(errors?.BaseSettingLiveName?.message)
                : ''
            }
            defaultValue={contentSetting?.liveName}
            disabled={disableFlg}
            className={
              !disableFlg && !watch('BaseSettingLiveName') ? 'error' : ''
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          疑似ライブ設定説明
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="BaseSettingLiveDescription"
            width="fullWidth"
            register={register}
            rows={3}
            maxLength={500}
            defaultValue={contentSetting?.liveDescription}
            disabled={disableFlg}
          />
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          同時接続数制限
          <span
            className={classes.helpContainer}
            style={{ display: 'inline-block' }}
          >
            <Help fontSize="small" className={classes.help} />
            {edition !== EDITIONS.U_LIVE && (
              <Tooltip message="入力可能な値は、1以上、契約されている同時接続数上限の値までとなります。" />
            )}
          </span>
          {edition !== EDITIONS.U_LIVE && (
            <span className={classes.badge}>必須</span>
          )}
        </Grid>
        <Grid item xs={9}>
          <FormTextBox
            name="BaseSettingMaxClient"
            width={90}
            register={register}
            validation={maxClientValidation}
            numberOnly
            placeholder={edition === EDITIONS.U_LIVE ? '制限なし' : ''}
            validationError={Boolean(errors?.BaseSettingMaxClient)}
            validationErrorMsg={
              errors?.BaseSettingMaxClient?.message
                ? String(errors?.BaseSettingMaxClient?.message)
                : ''
            }
            defaultValue={contentSetting?.maxClientLimit}
            disabled={
              Boolean(edition === EDITIONS.U_LIVE) || maxClientLimitDisableFlg
            }
            className={
              !disableFlg && !watch('BaseSettingMaxClient') ? 'error' : ''
            }
          />
          <Box className={classes.discription} style={{ opacity: 1 }}>
            <InfoOutlined fontSize="small" className={classes.icon} />
            同時接続数上限：
            <span style={{ fontWeight: 700 }}>
              {edition === EDITIONS.U_LIVE ? '制限なし' : `${maxClient}人`}
            </span>
            ｜
            <span style={{ color: '#B6BEC9' }}>
              現在使用中の同時接続枠：
              {edition === EDITIONS.U_LIVE ? (
                '制限なし'
              ) : (
                <>
                  <span
                    style={{
                      fontWeight: 700,
                      color: '#B6BEC9',
                      marginLeft: 10,
                    }}
                  >
                    --人
                  </span>
                  &ensp;/&ensp; --人
                </>
              )}
            </span>
            <span
              className={`${classes.helpContainer} ${classes.tooltipSmall}`}
              style={{ display: 'inline-block' }}
            >
              <Help fontSize="small" className={classes.help} />
              <Tooltip message="現在調整中です" />
            </span>
          </Box>
          {edition !== EDITIONS.U_LIVE && (
            <Box className={classes.discription}>
              <InfoOutlined fontSize="small" className={classes.icon} />
              同時接続枠を使用中の場合、その合算値が契約上限数を超えないように設定してください。
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          公開期間
          {!disableFlg && <span className={classes.badge}>必須</span>}
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <p className={classes.discription}>
              開始日時は現在の日時から30分以降で設定が可能です。
            </p>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
              <>
                <Box className={classes.datePicker}>
                  開始日時：
                  <Controller
                    name="BaseSettingStartDate"
                    control={control}
                    defaultValue={moment(startDate).format(DATE_FORMAT)}
                    rules={startEndDateTimeValidation}
                    render={() => (
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy/MM/dd"
                        value={startDate}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={(date) => handleStartDate(date)}
                        className={classes.inputDate}
                        onBlur={(e) => handleStartDate(e.target.value)}
                        disableToolbar
                        error={Boolean(errors?.BaseSettingStartDateTime)}
                        disabled={disableFlg}
                      />
                    )}
                  />
                  <Controller
                    name="BaseSettingStartTime"
                    control={control}
                    defaultValue={moment(startTime).format(TIME_FORMAT)}
                    rules={startEndDateTimeValidation}
                    render={() => (
                      <KeyboardTimePicker
                        autoOk
                        ampm={false}
                        value={startTime}
                        onChange={(date) => handleStartTime(date)}
                        format="HH:mm"
                        variant="inline"
                        inputVariant="outlined"
                        className={classes.inputTime}
                        onBlur={(e) => {
                          handleStartTime(e.target.value);
                        }}
                        disableToolbar
                        keyboardIcon={<AccessTimeIcon />}
                        error={Boolean(errors?.BaseSettingStartDateTime)}
                        disabled={disableFlg}
                      />
                    )}
                  />
                  ～
                </Box>
                {errors?.BaseSettingStartDateTime?.message && (
                  <Box className={classes.error}>
                    <InfoOutlined
                      fontSize="small"
                      className={classes.redIcon}
                    />
                    {errors?.BaseSettingStartDateTime?.message}
                  </Box>
                )}
                <Box className={classes.datePicker}>
                  終了日時：
                  <Controller
                    name="BaseSettingEndDate"
                    control={control}
                    defaultValue={moment(endDate).format(DATE_FORMAT)}
                    render={() => (
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy/MM/dd"
                        value={endDate}
                        onChange={() => undefined}
                        InputAdornmentProps={{ position: 'end' }}
                        className={classes.inputDate}
                        disableToolbar
                        readOnly
                        disabled
                      />
                    )}
                  />
                  <Controller
                    name="BaseSettingEndTime"
                    control={control}
                    defaultValue={moment(endTime).format(TIME_FORMAT)}
                    render={() => (
                      <KeyboardTimePicker
                        autoOk
                        ampm={false}
                        value={endTime}
                        onChange={() => undefined}
                        format="HH:mm"
                        variant="inline"
                        inputVariant="outlined"
                        className={classes.inputTime}
                        disableToolbar
                        keyboardIcon={<AccessTimeIcon />}
                        readOnly
                        disabled
                      />
                    )}
                  />
                </Box>
              </>
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DefaultInfo;
