// アプリ全体で使用する定数値

/** HTTPステータスコード 404 */
export const NOT_FOUND = 404;

/** DVR用のデモソース指定ライブID */
export const DVR_DEMO_LPID = 2147483647;

/** 画面の最小幅 */
export const MIN_WIDTH = 1280;

/** 画面の最小幅 */
export const CONTROLLER_MIN_WIDTH = 1580;

/** 最大ページ数 */
export const MAX_PAGE_NUM = 10;

/** 最大プロファイル取得数 */
export const MAX_PAGESIZE = 100;

/** ライブ中の同時接続数取得遅延（秒） */
export const STREAMING_GETCOUNT_DELAY = 60;

/** エンコーダの設定 音声サンプリングレート推奨値 */
export const RECOMMEND_AUDIO_SAMPLINGRATE = [44.1, 48];

/** エンコーダの設定 音声チャンネル推奨値 */
export const RECOMMEND_AUDIO_CHANEL = 'ステレオ';

/** エンコーダの設定 音声チャンネル推奨値（英語） */
export const RECOMMEND_AUDIO_CHANEL_ENG = 'Stereo';

/** エンコーダの設定 音声ビットレート推奨値（フルHD、HD、高画質） */
export const RECOMMEND_HIGH_AUDIO_BITRATE = 128;

/** エンコーダの設定 音声ビットレート推奨値（標準画質、低画質） */
export const RECOMMEND_LOW_AUDIO_BITRATE = 96;

/** エンコーダの設定 プロファイル推奨値 */
export const RECOMMEND_PROFILE = 'ベースライン';

/** エンコーダの設定 プロファイル推奨値（英語） */
export const RECOMMEND_PROFILE_ENG = 'Baseline';

/** 同時接続数グラフ表示間隔 */
export const GRAPH_INTERVALS = ['30秒', '1分', '2分', '3分', '5分', '10分'];

/** ライブ表示件数 */
export const LIVE_VIEW_LIMIT = [20, 30, 50];

/** メニューバーの選択肢 */
export enum MENUBAR {
  /** ライブの配信設定と開催 */
  LIVELIST,
  /** ライブイベント履歴 */
  HISTORYLIST,
  /** 疑似ライブ */
  FILETOLIVE,
}

/** エディション */
export enum EDITIONS {
  NONE = 0,
  ENTRY = 7,
  STARTUP = 8,
  C_ENTRY = 14,
  C_STARTUP = 15,
  BUSINESS = 9,
  EXPERT = 10,
  EXPERT_2 = 22,
  ENTERPRISE = 17,
  U_LIVE = 23,
}

/** 視聴制限プロファイル - 認証モード */
export enum AUTHMODE {
  AND = 'and',
  OR = 'or',
}

/** 視聴制限プロファイル - 再生ドメイン制限設定 タイプ */
export enum DOMAINLIMIT_TYPE {
  /** 再生ドメイン制限なし */
  NONE,
  /** 以下のドメインのみ再生可能 */
  WHITELIST,
}

/** IPアドレスによる視聴制限タイプ */
export enum IPLIMIT_TYPE {
  /** IP視聴制限なし */
  NONE,
  /** 以下のIP上のみ再生可能（ホワイトリスト） */
  WHITELIST,
  /** 以下のIP上では再生ブロック（ブラックリスト） */
  BLACKLIST,
}

/** ポータル公開ステータス */
export enum PORTAL_STATUS {
  /** なし */
  NONE,
  /** 非公開 */
  PRIVATE,
  /** 公開 */
  PUBLIC,
}

/** ポータル公開ステータス 文字列 */
export enum PORTAL_STATUS_STR {
  /** なし */
  NONE = '-',
  /** 非公開 */
  PRIVATE = '非公開',
  /** 公開 */
  PUBLIC = '公開中',
}

/** チャットステータス */
export enum CHAT_STATUS {
  /** 配信中 */
  STREAMING = 'streaming',
  /** 停止中 */
  STOP = 'stop',
  /** 設定なし */
  NONE = 'no_setting',
}

/** 擬似ライブチャットステータス */
export enum FILE_TO_LIVE_CHAT_STATUS {
  /** 配信中 */
  STREAMING = 100,
  /** 停止中 */
  STOP = 200,
  /** 削除済み */
  DELETED = 300,
  /** 設定なし */
  NONE = 0,
}

/** チャットステータス 日本語 */
export enum CHAT_STATUS_JAP {
  /** 配信中 */
  STREAMING = '公開中',
  /** 停止中 */
  STOP = '停止中',
  /** 設定なし */
  NONE = '-',
}

/** 全画質帯 */
export enum STREAMS {
  ORIGIN = 'オリジナル画質',
  FULLHD = 'フルHD画質',
  HD = 'HD画質',
  HIGH = '高画質',
  STANDARD = '標準画質',
  LOW = '低画質',
  AUTO = '自動',
  NONE = '',
}

/** 全画質帯 */
export enum STREMAS_ENG {
  ORIGIN = 'original',
  FULLHD = 'fhd',
  HD = 'hd',
  HIGH = 'high',
  STANDARD = 'standard',
  LOW = 'low',
  AUTO = 'auto',
  NONE = '',
}

/** 全画質帯 */
export enum STREMAS_NUM {
  ORIGIN = 9,
  FULLHD = 5,
  HD = 4,
  HIGH = 3,
  STANDARD = 2,
  LOW = 1,
  AUTO = 0,
  UNSELECTED = 9999,
}

/** 推奨エンコーダ一覧 */
export enum ENCODERS {
  LIVESHELL = 'liveshell',
  WIRECAST = 'wirecast',
  LIVEUSOLO = 'LiveUSolo',
}

/** 視聴者数無制限 */
export const UNLIMITED_CLIENTS = -1;

/** JCloudサーバーステータス */
export enum JCLOUD_SERVER_STATUS {
  OPENING = 'opening',
  OPENED = 'opened',
  CLOSING = 'closing',
  CLOSED = 'closed',
  DEACTIVATEED = 'deactivated',
}

/** 配信方法 */
export enum STREAM_TYPE {
  /** 外部エンコーダー */
  ENCODER,
  /** デスクトップ配信 */
  DESKTOP,
  /** EQライブキャスト */
  LIVECAST,
}

/** ライブキャスト、デスクトップ配信帯域 */
export enum APP_STREAMS {
  NONE = '',
  HD = 'HD画質',
  HIGH = '高画質',
  STANDARD = '標準画質',
  LOW = '低画質',
}

/** チャットの開始のタイミング設定 */
export enum CHAT_START_TIMING {
  /** 手動で開始 */
  MANUAL = -1,
  /** ライブ開始と同時にチャットも開始 */
  LIVE_AUTO = 0,
}

/** チャットの終了のタイミング設定 */
export enum CHAT_END_TIMING {
  /** 手動で終了 */
  MANUAL = -1,
  /** ライブ終了と同時にチャットも終了 */
  LIVE_AUTO = 0,
  /** ライブ終了後○分で自動終了 */
  MINUTES_AUTO = 1,
}

/** 投稿されるメッセージの管理 */
export enum CHAT_BLOCK_TYPE {
  /** メッセージを全て許可する */
  ALL,
  /** 不適切な可能性のあるメッセージを保留して確認する */
  CHECK,
  /** すべてのメッセージを保留して確認する */
  ALL_CHECK,
}

/** 入退室メッセージの表示 */
export enum CHAT_ENTER_MSG {
  /** 表示する */
  DISPLAY,
  /** 入室メッセージのみ表示する */
  CHECKIN_DISPLAY,
  /** 表示しない */
  NONE,
}

/** 管理者表示名 */
export enum CHAT_MNG_DISPLAY_NAME {
  MNG = '管理者',
  OPE = '運営',
  SEC = '事務局',
}

/** ジョブ発行APIパラメータ type */
export enum JOBDISPATCH_API_PRAM_TYPE {
  DEACTIVATE = 'deactivate',
  CLEANUP = 'cleanup',
  ENDCHAT = 'endChat',
  UPDATECHAT = 'updateChatCSV',
  DELETE = 'delete',
  DELETE_FLIVE = 'deleteflive',
}

/** ライブの配信設定と開催 ソート項目 */
export enum LIVE_LIST_SORT_PROPERTY {
  LPID = 'lpid',
  STATUS = 'status',
  CHAT_STATUS = 'chatStatus',
  PORTAL_STATUS = 'portalStatus',
  LIVE_APP = 'liveApp',
  DESKTOP = 'desktop',
}

/** 汎用ソート候補 */
export enum SORT_STATUS {
  DESCE,
  ASCE,
  NONE,
}

/** ライブIDソート候補 */
export enum LIVELIST_SORT_LIVEID {
  DESCE,
  ASCE,
}

/** ライブ配信設定と開催、コントロールパネルで見られるステータス */
export enum LIVE_STATUS {
  NONE = '',
  ACTIVATING = '起動中…',
  PRE = '配信準備中',
  STREAMING = '本番配信中',
  STOP = '配信停止中',
  CREATING = '作成中',
  ERROR = 'エラー',
}

/** ライブ配信設定と開催で見られるステータス(英名) */
export enum LIVE_STATUS_ENG {
  NONE = '',
  PRE = 'prepare',
  OPEN = 'open',
  STOP = 'stop',
  CREATING = 'creating',
  ERROR = 'error',
}

/** ライブステータス：DVR残 */
export const LIVE_STATUS_DVR_REMAIN = 'DVR残';

/** ライブステータスソート順 - 昇順 */
export const SORTLIST_LIVESTATUS_ASCE = [
  LIVE_STATUS.STREAMING,
  LIVE_STATUS.PRE,
  LIVE_STATUS_DVR_REMAIN,
  LIVE_STATUS.STOP,
  LIVE_STATUS.NONE,
];

/** ライブステータスソート順 - 降順 */
export const SORTLIST_LIVESTATUS_DESCE = [
  LIVE_STATUS.STOP,
  LIVE_STATUS_DVR_REMAIN,
  LIVE_STATUS.PRE,
  LIVE_STATUS.STREAMING,
  LIVE_STATUS.NONE,
];

/** チャットステータスソート順 - 昇順 */
export const SORTLIST_CHATSTATUS_ASCE = [
  CHAT_STATUS.STREAMING,
  CHAT_STATUS.STOP,
  CHAT_STATUS.NONE,
];

/** チャットステータスソート順 - 降順 */
export const SORTLIST_CHATSTATUS_DESCE = [
  CHAT_STATUS.NONE,
  CHAT_STATUS.STOP,
  CHAT_STATUS.STREAMING,
];

/** ポータルステータスソート順 - 昇順 */
export const SORTLIST_PORTALSTATUS_ASCE = [
  PORTAL_STATUS.PUBLIC,
  PORTAL_STATUS.PRIVATE,
  PORTAL_STATUS.NONE,
];

/** ポータルステータスソート順 - 降順 */
export const SORTLIST_PORTALSTATUS_DESCE = [
  PORTAL_STATUS.NONE,
  PORTAL_STATUS.PRIVATE,
  PORTAL_STATUS.PUBLIC,
];

/** ライブログステータス(英字) */
export enum LIVE_LOG_STATUS_ENG {
  NONE = '',
  WAIT = 'Waiting',
  RECORDING = 'Recording',
  RECORDING_EXTENSION = 'RecordExtending',
  RECORDING_STOPING = 'RecordStoping',
  RECORDING_FINISH = 'RecordComp',
  MASTER_CONVERT = 'MasterConverting',
  MASTER_CONVERT_FINISH = 'MasterConvertComp',
  TRANSCODE = 'Transcoding',
  TRANSCODE_FINISH = 'TranscodeComp',
  MASTER_CONVERT_ERROR = 'MasterConvertError',
  VOD_UPLOAD_ERROR = 'VODUploadError',
  VOD_TRANSCODE_ERROR = 'VODTranscodeError',
  UNKNOWN_ERROR = 'Error',
}

/** ライブログステータス */
export enum LIVE_LOG_STATUS {
  NONE = '',
  WAIT = '録画待機中',
  RECORDING = '録画中',
  RECORDING_EXTENSION = '録画延長処理中',
  RECORDING_STOPING = '録画停止処理中',
  RECORDING_FINISH = '録画終了',
  MASTER_CONVERT = 'マスター変換中',
  MASTER_CONVERT_FINISH = 'マスター変換完了',
  TRANSCODE = 'トランスコード中',
  TRANSCODE_FINISH = 'トランスコード完了',
  MASTER_CONVERT_ERROR = 'マスター変換エラー',
  VOD_UPLOAD_ERROR = 'VODアップロードエラー',
  VOD_TRANSCODE_ERROR = 'VODトランスコードエラー',
  UNKNOWN_ERROR = '不明なエラー',
}

/** イベントステータス */
export enum EVENT_STATUS {
  /** 未実施 */
  NON_RECORDING = 0,
  /** 実施中 */
  RECORDING = 100,
  /** 実施済 */
  RECORDED = 200,
}

/** ライブの配信設定と開催 ソート項目 */
export enum HISTORY_LIST_SORT_PROPERTY {
  LPLID = 'lplid',
  STATUS = 'status',
  EVENT_DATE = 'eventDate',
}

/** ライブログステータスソート順 - 昇順 */
export const SORTLIST_LIVELOGSTATUS_ASCE = [
  LIVE_LOG_STATUS.TRANSCODE_FINISH,
  LIVE_LOG_STATUS.VOD_TRANSCODE_ERROR,
  LIVE_LOG_STATUS.TRANSCODE,
  LIVE_LOG_STATUS.MASTER_CONVERT_FINISH,
  LIVE_LOG_STATUS.MASTER_CONVERT_ERROR,
  LIVE_LOG_STATUS.MASTER_CONVERT,
  LIVE_LOG_STATUS.VOD_UPLOAD_ERROR,
  LIVE_LOG_STATUS.RECORDING_FINISH,
  LIVE_LOG_STATUS.RECORDING_STOPING,
  LIVE_LOG_STATUS.RECORDING_EXTENSION,
  LIVE_LOG_STATUS.RECORDING,
  LIVE_LOG_STATUS.WAIT,
  LIVE_LOG_STATUS.UNKNOWN_ERROR,
  LIVE_LOG_STATUS.NONE,
];

/** ライブログステータスソート順 - 降順 */
export const SORTLIST_LIVELOGSTATUS_DESCE = [
  LIVE_LOG_STATUS.UNKNOWN_ERROR,
  LIVE_LOG_STATUS.WAIT,
  LIVE_LOG_STATUS.RECORDING,
  LIVE_LOG_STATUS.RECORDING_EXTENSION,
  LIVE_LOG_STATUS.RECORDING_STOPING,
  LIVE_LOG_STATUS.RECORDING_FINISH,
  LIVE_LOG_STATUS.VOD_UPLOAD_ERROR,
  LIVE_LOG_STATUS.MASTER_CONVERT,
  LIVE_LOG_STATUS.MASTER_CONVERT_ERROR,
  LIVE_LOG_STATUS.MASTER_CONVERT_FINISH,
  LIVE_LOG_STATUS.TRANSCODE,
  LIVE_LOG_STATUS.VOD_TRANSCODE_ERROR,
  LIVE_LOG_STATUS.TRANSCODE_FINISH,
  LIVE_LOG_STATUS.NONE,
];

/** 同時接続数グラフ表示間隔 */
// CHECK: GRAPH_INTERVALSと被ってるならこっち消してもいい？
export enum GraphIntervals {
  NONE = '',
  THIRTY_SECOND = '30秒',
  MINUTES = '1分',
  TWO_MINUTES = '2分',
  THREE_MINUTES = '3分',
  FIVE_MINUTES = '5分',
  TEN_MINUTES = '10分',
}

/** 画面制御用ステータス */
export enum UI_STATUS {
  PROCESSING,
  COMPLETED,
  REDIRECT,
  ACTIVATING,
  PROCESSING_STREAM,
  PROCESSING_CHAT,
  PROCESSING_QUALITY,
  SAVING,
}

/** プレイヤーアスペクト比固定 */
export enum PLAYER_ASPECTFIX {
  NONE,
  XVIIVFIX,
  IVIIIFIX,
}

/** 共通タグ - 掲載サイト別管理プルダウンを名 */
export const PUBSITES_PULLDOWN_NAME = '掲載サイト別管理の設定を使用する';

/** 共通タグ - 動画表示幅 入力 上限下限 */
export enum VIDEOWIDTH_MAXMIN {
  MIN = 150,
  MAX = 1920,
}

/** 共通タグ - 動画表示高さ 入力 上限下限 */
export enum VIDEOHEIGHT_MAXMIN {
  MIN = 136,
  MAX = 1080,
}

/** 共通タグ - チャットサイズ幅 入力 上限下限 */
export enum CHATWIDTH_MAXMIN {
  MIN = 300,
  MAX = 1920,
}

/** 共通タグ - チャットサイズ高さ 入力 上限下限 */
export enum CHATHEIGHT_MAXMIN {
  MIN = 320,
  MAX = 1080,
}

/** 共通タグ - タブ名 */
export enum LIVESAHRE_TABS {
  SCRIPT,
  IFRAME,
  QR_URL,
  CHAT_TAG,
}

/** QRコード拡張子 */
export enum QR_FORMAT {
  NONE = '',
  PNG = 'png',
  JPG = 'jpg',
}

/** 共通タグで使用するチャットの埋め込みデフォルトサイズ */
export enum DEFAULT_CHATSIZE {
  WIDTH = 400,
  HEIGHT = 600,
}

/** 共通タグ - プレビューサイズ */
export enum LIVESHARE_PLAYERSIZE {
  WIDTH = 475,
  HEIGHT = 267,
}

/** モニタリング画面 - プレイヤーサイズ */
export enum MONITORING_PLAYERSIZE {
  WIDTH = 505, // 505,
  HEIGHT = 284, // 248,
}

/** コントロールパネル画面 - プレイヤーサイズ */
export enum CONTROLL_PLAYERSIZE {
  WIDTH = 460,
  HEIGHT = 258,
}

/** コントロールパネル画面（疑似ライブ） - プレイヤーサイズ */
export enum FILETOLIVE_CONTROLL_PLAYERSIZE {
  WIDTH = 488,
  HEIGHT = 274,
}

/** フィード選択肢 */
export enum FEED_MAINBACK {
  MAIN,
  BACKUP,
}

/** ライブプロファイル保存処理種別 */
export enum LIVE_SAVE {
  ADD = 'add',
  UPDATE = 'update',
}

/** 可能かどうか=boolean */
export enum IS_ENABLE {
  ENABLE = 'enabled',
  DISABLED = 'disabled',
}

/** on/off=boolean */
export enum ONOFF {
  ON = 'on',
  OFF = 'off',
}

/** react-dropzoneエラーコード */
export enum DROP_ZONE_ERR_CODE {
  TOO_MANY_FILES = 'too-many-files',
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
  FILE_TOO_SMALL = 'file-too-small',
  HEIGHT_TOO_SMALL = 'height-too-small',
}

/** ライブプロファイル作成・更新APIエラーコード */
export enum LIVE_PROFILE_ERR_CODE {
  CODE_7000 = 7000,
  CODE_9021 = 9021,
  CODE_9022 = 9022,
  CODE_9502 = 9502,
  CODE_9503 = 9503,
  CODE_9512 = 9512,
  CODE_9516 = 9516,
  CODE_9519 = 9519,
  CODE_9520 = 9520,
  CODE_9521 = 9521,
  CODE_9522 = 9522,
  CODE_9525 = 9525,
  CODE_99999 = 99999,
}

/** ライブ情報作成後に使用されてなかった場合、Deactivateが実行される時間(分) */
export const DEACTIVATE_TIME = 60;

/** サポートページリンク - FAQ */
export const SUPPORTPAGE_URL_FAQ =
  'https://support.eq.stream.co.jp/hc/ja/articles/115009413108-FAQ';

/** サポートページリンク - ライブ配信設定の新規登録 */
export const SUPPORTPAGE_URL_LIVEREGISTER =
  'https://support.eq.stream.co.jp/hc/ja/articles/4419508351513';

/** サポートページリンク - 関連ページへのリンク */
export const SUPPORTPAGE_URL_EXLINK =
  'https://support.eq.stream.co.jp/hc/ja/articles/115010535568-%E5%8B%95%E7%94%BB%E3%81%AE%E6%9C%AB%E5%B0%BE%E3%81%AB%E9%96%A2%E9%80%A3%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%B8%E3%81%AE%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B';

/** サポートページリンク - 疑似ライブ配信設定の新規登録 */
export const SUPPORTPAGE_URL_FILETOLIVEREGISTER =
  'https://support.eq.stream.co.jp/hc/ja/articles/5973328778265';

/** サポートページリンク - Cookie/WebStorageについて */
export const SUPPORTPAGE_URL_COOKIE =
  'https://support.eq.stream.co.jp/hc/ja/articles/4943454744857#%E2%96%A0EQ%E3%83%97%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC';

/** ライブ自動画質設定画面リンク */
export const SETPAGE_URL_LIVEABR = `${String(
  process.env.REACT_APP_EQ_MNG_URL
)}manager/settings/live_quality_profile_list.aspx`;

/** トランスコード設定画面リンク */
export const SETPAGE_URL_TRANSCODE = `${String(
  process.env.REACT_APP_EQ_MNG_URL
)}manager/settings/transcode_profile_user_list.aspx`;

/** アーカイブグループ設定画面リンク */
export const SETPAGE_URL_VODGROUP = `${String(
  process.env.REACT_APP_EQ_MNG_URL
)}manager/settings/group_list.aspx`;

/** セキュリティ設定画面へのリンク */
export const SETPAGE_URL_SECURITY = `${String(
  process.env.REACT_APP_EQ_MNG_URL
)}manager/settings/viewlimit_profile_list.aspx`;

/** デスクトップ配信画面リンク */
export const desktopStreamUrl = (
  c: string,
  l: string,
  t: string,
  u: string
): string =>
  `${String(
    process.env.REACT_APP_DESKTOP_STREAM_URL
  )}?c=${c}&l=${l}&t=${t}&u=${u}`;

/** JCloudのオリジンURL(monitor_url)EQホスト用に変換 */
export const FormatMonitorURL = (url: string, directory: string): string => {
  const index = url.indexOf('/monitor-live/') ?? 0;
  const src = url.substring(index);
  return `https://eqlive-${directory}-live.${process.env.REACT_APP_MONITOR_URL}${src}`;
};

/** 管理コンソール固定部分 */
export const CHAT_CONSOLE_COMMOM = `${String(
  process.env.REACT_APP_CHAT_MNG_URL
)}www50/chat/jmc_pub/v2/admin.html`;

/** EQライブプレイヤーソースURL */
export const EQLivePlayerSrc = (dir: string): string =>
  `${process.env.REACT_APP_PLAYER_SRC_URL}www50/${dir}/jmc_pub/jmc_swf/liveplayer/t3/obj.js`;

/** EQライブプレイヤーbパラメータ */
export const EQLivePlayerBparam = (dir: string): string =>
  `${dir}.${process.env.REACT_APP_PLAYER_B_URL}www50/${dir}/jmc_pub/jmc_swf/liveplayer/`;

/** EQVODプレイヤーソースURL */
export const EQVodPlayerSrc = (dir: string): string =>
  `${process.env.REACT_APP_PLAYER_SRC_URL}www50/${dir}/jmc_pub/jmc_swf/player/t3/if.js`;

/** EQVODプレイヤーbパラメータ */
export const EQVodPlayerBparam = (dir: string): string =>
  `${dir}.${process.env.REACT_APP_PLAYER_B_URL}www50/${dir}/jmc_pub/jmc_swf/player/`;

/** パストークン正規表現 */
export const PATH_TOKEN = '/([a-z0-9]+)';

/** ルーティング */
export const ROUTE = {
  /** ライブの配信設定と開催 */
  LIVE_LIST: '/manager/live/live_profile_list',

  /** ライブの新規登録 */
  LIVE_REGISTER: '/manager/live/new_profile',

  /** ライブ詳細 */
  LIVE_CONTENT: '/manager/live/profile',

  /** コントロールパネル */
  LIVE_CONTROLLER: '/manager/live/console',

  /** 配信モニタリング */
  LIVE_MONITOR: '/manager/live/monitoring',

  /** ライブイベント履歴 */
  HISTORY_LIST: '/manager/live/live_event_list',

  /** ライブ配信設定履歴 */
  HISTORY_CONTENT: '/manager/live/report',

  /** 疑似ライブの配信設定と開催 */
  FILE_TO_LIVE_LIST: '/manager/flive/flive_profile_list',

  /** 疑似ライブの新規登録 */
  FILE_TO_LIVE_REGISTER: '/manager/flive/new_profile',

  /** 疑似ライブ詳細 */
  FILE_TO_LIVE_CONTENT: '/manager/flive/profile',

  /** 疑似コントロールパネル */
  FILE_TO_LIVE_CONTROLLER: '/manager/flive/console',
};

/** パスに含まれるトークンと遷移先パスを結合して返却 */
export const getPath = (route: string): string => {
  const { pathname } = window.location;
  const token = pathname.split('/');
  return `/${token[1]}${route}/`;
};

/** SnackBar Type */
export enum SNACKBAR_TYPE {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

/** 疑似ライブの配信設定と開催 ソート項目 */
export enum FILE_TO_LIVE_LIST_SORT_PROPERTY {
  FLPID = 'flpid',
  STATUS = 'status',
  START_DATE = 'start_date',
}

/** 疑似ライブの開始前、終了後にフィラー映像を利用する */
export enum FILE_TO_LIVE_FILLER_ID {
  /** システムデフォルトのフィラー映像を利用する場合のID */
  SYSTEM_DEFAULT = 0,
  /** 任意の動画を指定する */
  SELECT_MID = -1,
}

/** 疑似ライブプロファイル作成・更新APIエラーコード */
export enum FILE_TO_LIVE_PROFILE_ERR_CODE {
  CODE_9021 = 9021,
  CODE_9503 = 9503,
  CODE_9512 = 9512,
  CODE_9516 = 9516,
  CODE_9521 = 9521,
  CODE_9527 = 9527,
  CODE_99999 = 99999,
}

/** 疑似ライブ配信設定と開催、コントロールパネルで見られるステータス */
export enum FILE_TO_LIVE_STATUS {
  NONE = '',
  WAITING = '配信予約中',
  READY = '配信準備中',
  STREAMING = '配信中',
  FINISHED = '配信終了',
  ERROR = 'エラー',
}

/** 疑似ライブ配信設定と開催で見られるステータス(英名) */
export enum FILE_TO_LIVE_STATUS_ENG {
  NONE = '',
  WAITING = 'Waiting',
  READY = 'Ready',
  STREAMING = 'Streaming',
  FINISHED = 'Finished',
  ERROR = 'Error',
}

/** ライブステータスソート順 - 昇順 */
export const SORTLIST_FILE_TO_LIVESTATUS_ASCE = [
  FILE_TO_LIVE_STATUS.WAITING,
  FILE_TO_LIVE_STATUS.READY,
  FILE_TO_LIVE_STATUS.STREAMING,
  FILE_TO_LIVE_STATUS.FINISHED,
  FILE_TO_LIVE_STATUS.ERROR,
  FILE_TO_LIVE_STATUS.NONE,
];

/** ライブステータスソート順 - 降順 */
export const SORTLIST_FILE_TO_LIVESTATUS_DESCE = [
  FILE_TO_LIVE_STATUS.FINISHED,
  FILE_TO_LIVE_STATUS.STREAMING,
  FILE_TO_LIVE_STATUS.READY,
  FILE_TO_LIVE_STATUS.WAITING,
  FILE_TO_LIVE_STATUS.ERROR,
  FILE_TO_LIVE_STATUS.NONE,
];

/** ライブ情報タブ名 */
export enum LIVE_TAB_NAME {
  DEFAULT_INFO = 'default',
  LIVE_LINK = 'link',
  SECURITY_SETTING = 'security',
  CHAT_SETTING = 'chat',
  THUMBNAIL_SETTING = 'thumbnail',
  LIVE_EVENT = 'event',
  FILLER_SETTING = 'filler',
}

/** 表示件数 */
export const DISPLAY_COUNT = [20, 30, 50];

/** ライブの自動アーカイブ */
export enum VOD_FLG {
  DISABLED = 0,
  MAIN_OR_BACK = 1,
  MAIN_AND_BACK = 2,
}
