import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { LIVECONTROLLER_FLOW_ERR_MSG } from '../../utils/Message';
import { UI_STATUS } from '../../utils/Const';
import { setContract } from '../../features/userSlice';
import {
  setError,
  setFileToLiveControllerStatus,
} from '../../features/uiSlice';
import { GetContract, GetFileToLiveProfile } from '../../types/API';
import { addEQFileToLiveProfile } from '../../features/fileToLivesSlice';

type GetLiveControllerFlowParams = {
  cid: number;
  flpid: number;
  userApiParams: string;
};

/** コントロールパネル表示フロー */
const getLiveControllerFlow =
  (arg: GetLiveControllerFlowParams): AppThunk =>
  async (dispatch) => {
    try {
      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setContract({ ...responseGetContract.data }));

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetFileToLiveProfile> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/getProfile`,
          {
            flpid: arg.flpid,
            cid: arg.cid,
            userApiParams: arg.userApiParams,
            pageSize: 1,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(
        addEQFileToLiveProfile({
          ...responseProfile.data.profile[0],
        })
      );

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVECONTROLLER_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setFileToLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default getLiveControllerFlow;
