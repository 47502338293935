import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  DeleteLiveProfile,
  SetPamalink,
  MakeShortUrl,
  EQFileToLiveProfile,
  EQFileToLiveMovie,
  GetLiveConnections,
} from '../types/API';
import { PamaLink } from '../types/PamaLink';
import { FileToLiveLists } from '../types/FileToLive';
import { ViewerLog } from '../types/Utils';

/** entity用のアダプターを生成 */
// NOTE: デフォルトの並び順をライブIDの降順に変更し、lpidを主キーとしている
const eqFileToliveAdapter = createEntityAdapter<FileToLiveLists>({
  selectId: (eqlive) => eqlive.flpid,
  sortComparer: (a, b) => (a.flpid < b.flpid ? 1 : -1),
});

type FileToLivesEntityState = {
  /** 疑似ライブプロファイル数 */
  liveSum: number;

  /** 削除に失敗した疑似ライブID */
  faildDeleateFileToLiveId: number[];

  /** ライブプロファイルのエンティティ */
  profiles: {
    EQFileToLiveProfile: EntityState<FileToLiveLists>;
  };

  /** プレイヤー / チャットパーマリンク */
  pamalink: PamaLink;

  /** 短縮URL */
  watchUrl: {
    /** 短縮動画URL */
    shortVideo: string;

    /** QRコードURL */
    qr: string;

    /** 短縮QRコードURL */
    shortQr: string;
  };

  /** 利用可能動画 */
  movies: EQFileToLiveMovie[];

  /** 開始前フィラー利用可能動画 */
  prefillerMovies: EQFileToLiveMovie[];

  /** 終了時フィラー利用可能動画 */
  postfillerMovies: EQFileToLiveMovie[];

  /** 同時接続数ログ */
  countLog?: ViewerLog[];
  countState: {
    max: number | null;
    current: number | null;
    total: number | null;
  };
};

const initialEntityState: FileToLivesEntityState = {
  liveSum: 0,
  faildDeleateFileToLiveId: [],
  profiles: {
    EQFileToLiveProfile: eqFileToliveAdapter.getInitialState(),
  },
  pamalink: {
    errorCode: 0,
    message: '',
    playersList: [],
    pubsitesList: [],
    selectedPlayer: {
      pid: 0,
      ppsid: 0,
      width: 0,
      height: 0,
      responsive: 1,
      script: {
        tag: '',
        preview: '',
        largerTag: null,
      },
      iframe: {
        tag: '',
        preview: '',
        largerTag: '',
      },
      permalink: {
        tag: '',
        preview: '',
        largerTag: '',
      },
      chatOnly: {
        tag: '',
        preview: '',
        largerTag: null,
      },
      chatWidth: 0,
      chatHeight: 0,
      chatAutoResize: 0,
      refererPolicy: 0,
    },
  },
  watchUrl: {
    shortVideo: '',
    qr: '',
    shortQr: '',
  },
  movies: [],
  prefillerMovies: [],
  postfillerMovies: [],
  countLog: [],
  countState: { max: null, current: null, total: null },
};

const fileToLivesSlice = createSlice({
  name: 'fileToLives',
  initialState: initialEntityState,
  reducers: {
    /** EQの疑似ライブプロファイル件数を更新 */
    setFliveSum(state, action: PayloadAction<number>) {
      state.liveSum = action.payload;
    },
    /** EQの疑似ライブプロファイルをAPIから取得し全件追加 */
    setEQFileToLiveProfile(
      state,
      action: PayloadAction<{ livesum: number; profile: EQFileToLiveProfile[] }>
    ) {
      const { livesum, profile } = action.payload;
      state.liveSum = livesum;
      eqFileToliveAdapter.setAll(state.profiles.EQFileToLiveProfile, profile);
    },
    /** EQ疑似ライブプロファイルをAPIから取得し1つ更新 */
    addEQFileToLiveProfile(state, action: PayloadAction<FileToLiveLists>) {
      eqFileToliveAdapter.upsertOne(
        state.profiles.EQFileToLiveProfile,
        action.payload
      );
    },
    /** 複数件のEQ疑似ライブプロファイルを削除 */
    removeEQFileToLiveProfile(state, action: PayloadAction<number[]>) {
      eqFileToliveAdapter.removeMany(
        state.profiles.EQFileToLiveProfile,
        action.payload
      );
    },
    /** 削除に失敗した疑似ライブIDを更新 */
    setFaildDeleateFileToLiveId(
      state,
      action: PayloadAction<DeleteLiveProfile>
    ) {
      const { failed } = action.payload;
      state.faildDeleateFileToLiveId = failed;
    },
    /** プレイヤー / チャットパーマリンクを更新 */
    setPamalink(state, action: PayloadAction<SetPamalink>) {
      state.pamalink = action.payload;
      if (action.payload.pubsitesList.length === 0) {
        state.pamalink.pubsitesList = [
          {
            ppsid: 0,
            name: '',
          },
        ];
      }
    },
    /** 短縮動画URLを更新 */
    setShortVideoUrl(state, action: PayloadAction<MakeShortUrl>) {
      const { shortUrl } = action.payload;
      state.watchUrl.shortVideo = shortUrl;
    },
    /** QRURLを更新 */
    setQRUrl(state, action: PayloadAction<string>) {
      state.watchUrl.qr = action.payload;
    },
    /** 短縮QRURLを更新 */
    setShortQRUrl(state, action: PayloadAction<MakeShortUrl>) {
      const { shortUrl } = action.payload;
      state.watchUrl.shortQr = shortUrl;
    },
    /** EQの疑似ライブ利用可能動画をAPIから取得し全件追加 */
    setEQFileToLiveMovie(
      state,
      action: PayloadAction<{ movies: EQFileToLiveMovie[] }>
    ) {
      state.movies = action.payload.movies;
    },
    /** EQの疑似ライブフィラー利用可能動画をAPIから取得し全件追加 */
    setEQFileToLiveFiller(
      state,
      action: PayloadAction<{
        type: 'pre' | 'post';
        fillers: EQFileToLiveMovie[];
      }>
    ) {
      if (action.payload.type === 'pre') {
        state.prefillerMovies = action.payload.fillers;
      }
      if (action.payload.type === 'post') {
        state.postfillerMovies = action.payload.fillers;
      }
    },
    /** 同時接続数を更新 */
    setFileToLiveCount(state, action: PayloadAction<GetLiveConnections>) {
      const { data, max, current, total } = action.payload;
      state.countLog = data;
      state.countState = { max, current, total };
    },
  },
});

export const {
  setFliveSum,
  setEQFileToLiveProfile,
  addEQFileToLiveProfile,
  removeEQFileToLiveProfile,
  setFaildDeleateFileToLiveId,
  setPamalink,
  setShortVideoUrl,
  setQRUrl,
  setShortQRUrl,
  setEQFileToLiveMovie,
  setEQFileToLiveFiller,
  setFileToLiveCount,
} = fileToLivesSlice.actions;

export default fileToLivesSlice.reducer;

/** EQ疑似ライブプロファイル取得のselector */
export const {
  selectAll: selectAllEQFileToLives,
  selectById: selectEQFileToLivesById,
} = eqFileToliveAdapter.getSelectors<RootState>(
  (state) => state.fileToLives.profiles.EQFileToLiveProfile
);
