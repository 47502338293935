import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { GraphIntervals } from '../../utils/Const';
import { FileToLiveControllerProps } from '../../props/FileToLiveController';

/** スタイル */
const useStyle = makeStyles({
  root: {
    display: 'flex',
    fontSize: 13,
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: 20,
    transition: 'all 400ms',
    '& .MuiToggleButtonGroup-root': {
      width: 302,
      padding: '0 4px',
    },
    '& .MuiToggleButton-root': {
      padding: '11px 6px',
      '&:hover': {
        borderRadius: 100,
      },
    },
  },
  toggle: {
    width: 360,
    height: 34,
    backgroundColor: '#F2F3F4',
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 60,
    height: 24,
    fontSize: 14,
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: '#FFFFFF',
      borderRadius: 100,
      color: '#333333',
    },
  },
  title: {
    color: '#333333',
    opacity: 0.5,
  },
});

/** コントロールパネル 右(UI) */
const ToggleInterval = ({
  controllerProps,
  style,
}: {
  controllerProps?: FileToLiveControllerProps;
  style?: object;
}): JSX.Element => {
  const classes = useStyle();
  const handleChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: GraphIntervals
  ) => {
    if (controllerProps) {
      controllerProps?.choiceInterval(event, value);
    }
  };
  return (
    <Box className={classes.root} style={{ ...style }}>
      <span className={classes.title}>表示間隔：</span>
      <ToggleButtonGroup
        value={controllerProps?.graphInterval}
        exclusive
        onChange={handleChange}
        className={classes.toggle}
      >
        {Object.values(GraphIntervals)
          .filter((x) => x.length > 0)
          .map((x: GraphIntervals) => (
            <ToggleButton
              key={x}
              value={x}
              aria-label={x}
              className={classes.button}
            >
              {x}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToggleInterval;
