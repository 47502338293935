import axios from 'axios';
import { AppThunk } from '../../app/store';
import {
  setError,
  setFileToLiveContentStatus,
  setFileToLiveListStatus,
} from '../../features/uiSlice';
import { InputData } from '../../props/FileToLiveRegister';
import { EQFileToLiveProfile } from '../../types/API';
import { ToFileToLiveSetProfileStatusJap } from '../../utils/ChangeStatus';
import { EDITIONS, UI_STATUS } from '../../utils/Const';
import { FILE_TO_LIVE_UPDATE_SAVE_FLOW_ERR_MSG } from '../../utils/Message';
import setInputParams from '../../utils/FileToLiveSaveUtil';

/** ライブプロファイル更新処理フロー */
const FileToLiveUpdate =
  (
    data: InputData,
    edition: EDITIONS,
    setMessage: React.Dispatch<React.SetStateAction<string>>,
    EQFileTolive: EQFileToLiveProfile
  ): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    // 処理中をセット
    dispatch(setFileToLiveContentStatus(UI_STATUS.SAVING));
    try {
      // 画面入力項目のパラメータ設定
      const formData = setInputParams(data, edition);
      formData.append('flpid', String(EQFileTolive.flpid));
      formData.append('cid', String(user.contract.cid));
      formData.append('userApiParams', user.user);

      // ライブプロファイル更新
      const responseSetLiveProfile = await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/setProfile`,
        formData,
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'multipart/form-data',
          },
        }
      );

      if (responseSetLiveProfile.data.errorCode !== 0) {
        throw new Error(
          `${ToFileToLiveSetProfileStatusJap(
            responseSetLiveProfile.data.errorCode
          )}: ${responseSetLiveProfile.data.message}`
        );
      }

      // 正常終了
      dispatch(setFileToLiveContentStatus(UI_STATUS.REDIRECT));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response) {
        // 異常終了
        dispatch(
          setError({
            msg: FILE_TO_LIVE_UPDATE_SAVE_FLOW_ERR_MSG,
            detail: JSON.stringify(error.response.data),
          })
        );
      } else {
        // エラーメッセージ設定
        setMessage(error?.message);
      }
      dispatch(setFileToLiveContentStatus(UI_STATUS.COMPLETED));
    } finally {
      dispatch(setFileToLiveListStatus(UI_STATUS.PROCESSING));
    }
  };

export default FileToLiveUpdate;
