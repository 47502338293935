import { EDITIONS } from '../Const';

const LiveInfo = {
  nowCnt: 1,
  nowClientCnt: 20,
  cntLive: 7,
  maxClient: 2000,
  encrypt: 1,
  bitrateFhd: 4000,
  bitrateHd: 2000,
  bitrate1: 1056,
  bitrate2: 532,
  bitrate3: 282,
  widthFhd: 1920,
  widthhd: 1280,
  width1: 854,
  width2: 640,
  width3: 426,
  heightFhd: 1080,
  heightHd: 720,
  height1: 480,
  height2: 360,
  height3: 240,
  filetoLiveNowCnt: 2,
  filetoliveCnt: 200,
  filetoLiveNowClientCnt: 300,
  filetoliveAddCnt: 8,
};

/** 契約会社情報API Mockレスポンス */
const Contract = {
  companyName: 'Mockテスト',
  directory: 'eqc111test',
  edition: EDITIONS.EXPERT,
  uploadSizeLimit: 4294967296,
  serviceFlg25: 0,
  serviceFlg37: 0,
  serviceFlg39: 1,
  liveInfo: LiveInfo,
};

export default Contract;
