import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import LiveRegisterComponent from '../../components/LiveRegister/LiveRegisterComponent';
import {
  BaseSetting,
  InputData,
  SecuritySetting,
} from '../../props/LiveRegister';
import { LIVE_SAVE } from '../../utils/Const';
import LiveProfileInit from './LiveProfileInit';
import LiveSave from './LiveSave';

/** ライブの新規登録 */
const LiveRegisterContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  // Selector
  const user = useSelector((state: RootState) => state.user);

  // 変数抽出
  const { edition, liveInfo } = user.contract;
  const { maxClient, nowClientCnt } = liveInfo;

  // 初期処理
  useEffect(() => {
    dispatch(LiveProfileInit(LIVE_SAVE.ADD));
  }, []);

  // 保存処理
  const saveLiveProfile = (data: InputData) => {
    dispatch(LiveSave(data, edition, Boolean(liveInfo.encrypt)));
  };

  // 基本設定
  const baseSetting: BaseSetting = {
    liveABRProfiles: user.liveabr,
    transcodeProfiles: user.transcode,
    groupProfiles: user.group,
    nowClientCnt,
    maxClient,
  };

  // セキュリティ
  const securitySetting: SecuritySetting = {
    viewLimitsProfiles: user.viewLimitsProfile,
    vlpid: user.viewLimitsProfile[0]?.vlpid,
  };

  return (
    <LiveRegisterComponent
      edition={edition}
      encryptEdition={Boolean(liveInfo.encrypt)}
      baseSetting={baseSetting}
      securitySetting={securitySetting}
      saveLiveProfile={saveLiveProfile}
    />
  );
};

export default LiveRegisterContainer;
