import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  setError,
  setLiveListStatus,
  setLiveRegisterStatus,
} from '../../features/uiSlice';
import { InputData } from '../../props/LiveRegister';
import { SetLiveProfile } from '../../types/API';
import { ToSetProfileStatusJap } from '../../utils/ChangeStatus';
import { EDITIONS, UI_STATUS } from '../../utils/Const';
import { LIVE_ADD_SAVE_FLOW_ERR_MSG } from '../../utils/Message';
import setInputParams from '../../utils/LiveSaveUtil';

/** ライブプロファイル新規登録処理フロー */
const LiveSave =
  (data: InputData, edition: EDITIONS, encryptEdition: boolean): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    // 処理中をセット
    dispatch(setLiveRegisterStatus(UI_STATUS.SAVING));
    try {
      // ライブプロファイル新規登録
      const formData = setInputParams(data, edition, encryptEdition);
      // 契約会社ID
      formData.append('cid', String(user.contract.cid));
      // userApiParams
      formData.append('userApiParams', user.user);
      const responseSetLiveProfileTmp: AxiosResponse<SetLiveProfile> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}setProfileProcess`,
          formData,
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'multipart/form-data',
            },
          }
        );
      if (responseSetLiveProfileTmp.data.errorCode !== 0) {
        // 異常終了
        // NOTE: 400系のクライアントサイドのエラーだが、エラー画面に遷移させないとチェックボックスの不具合が生じるため、例外的にエラー画面に遷移
        throw new Error(
          `${ToSetProfileStatusJap(
            responseSetLiveProfileTmp.data.errorCode
          )}: ${responseSetLiveProfileTmp.data.message}`
        );
      }

      // 正常終了
      dispatch(setLiveRegisterStatus(UI_STATUS.REDIRECT));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: LIVE_ADD_SAVE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error?.message),
        })
      );
    } finally {
      dispatch(setLiveListStatus(UI_STATUS.PROCESSING));
    }
  };

export default LiveSave;
