import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { setError, setFileToLiveMoviesStatus } from '../../features/uiSlice';
import { GetFileToLiveMovie } from '../../types/API';
import { FILE_TO_LIVE_GETMOVIE_FLOW_ERR_MSG } from '../../utils/Message';
import { setEQFileToLiveMovie } from '../../features/fileToLivesSlice';
import { UI_STATUS } from '../../utils/Const';

/** 疑似ライブ利用動画取得API実行 */
const GetMediaList =
  (mid?: string): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    try {
      dispatch(setFileToLiveMoviesStatus(UI_STATUS.PROCESSING));
      // 疑似ライブ利用動画取得API実行
      const response: AxiosResponse<GetFileToLiveMovie> = await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getMediaList`,
        {
          cid: user.contract.cid,
          userApiParams: user.user,
          pageSize: 100,
          pageNumber: 1,
          mid: mid || null,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      const { movies } = response.data;
      dispatch(setEQFileToLiveMovie({ movies: movies || [] }));

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: FILE_TO_LIVE_GETMOVIE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setFileToLiveMoviesStatus(UI_STATUS.COMPLETED));
    }
  };

export default GetMediaList;
