import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ArrowDropUp,
  ArrowDropDown,
  OpenInNew,
  KeyboardArrowRight,
  Check,
  Warning,
} from '@material-ui/icons';
import { LiveListProps, TableLiveProfiles } from '../../props/LiveList';
import {
  getPath,
  MIN_WIDTH,
  LIVELIST_SORT_LIVEID,
  LIVE_STATUS,
  PORTAL_STATUS,
  PORTAL_STATUS_STR,
  ROUTE,
  SORT_STATUS,
  LIVE_LIST_SORT_PROPERTY,
  EDITIONS,
  SORTLIST_LIVESTATUS_ASCE,
  SORTLIST_LIVESTATUS_DESCE,
  SORTLIST_PORTALSTATUS_ASCE,
  SORTLIST_PORTALSTATUS_DESCE,
  LIVE_STATUS_DVR_REMAIN,
  CHAT_STATUS,
  CHAT_STATUS_JAP,
  SORTLIST_CHATSTATUS_ASCE,
  SORTLIST_CHATSTATUS_DESCE,
  UI_STATUS,
} from '../../utils/Const';
import Badge from '../parts/Badge/Badge';

const SortArrow = ({
  sort,
  active = false,
}: {
  sort?: SORT_STATUS | LIVELIST_SORT_LIVEID;
  active?: boolean;
}): JSX.Element => {
  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      style={{ cursor: 'pointer' }}
    >
      <ArrowDropUp
        fontSize="small"
        style={{
          marginBottom: '-7px',
          opacity:
            (sort === SORT_STATUS.DESCE ||
              sort === LIVELIST_SORT_LIVEID.DESCE) &&
            active
              ? '1'
              : '0.3',
        }}
      />
      <ArrowDropDown
        fontSize="small"
        style={{
          marginTop: '-7px',
          opacity:
            (sort === SORT_STATUS.ASCE || sort === LIVELIST_SORT_LIVEID.ASCE) &&
            active
              ? '1'
              : '0.3',
        }}
      />
    </Box>
  );
};

const CustomButtomTarget = ({
  title,
  href,
  disabled,
  className,
}: {
  title: string;
  href?: string;
  disabled?: boolean;
  className: string;
}): JSX.Element => {
  return (
    <Button
      className={className}
      href={href ?? ''}
      target={href?.length ? '_blank' : ''}
      disabled={disabled}
    >
      {title}
      <OpenInNew
        fontSize="small"
        style={{ marginLeft: 4, width: 16, height: 16 }}
      />
    </Button>
  );
};
const CustomButtomView = ({
  title,
  className,
}: {
  title: string;
  className: string;
}): JSX.Element => {
  return (
    <Button className={className}>
      {title}
      <KeyboardArrowRight fontSize="small" style={{ width: 16, height: 16 }} />
    </Button>
  );
};

const PortalStatusBadge = ({
  status,
}: {
  status: PORTAL_STATUS;
}): JSX.Element => {
  if (status === PORTAL_STATUS.PRIVATE) {
    return <Badge title={PORTAL_STATUS_STR.PRIVATE} />;
  }
  if (status === PORTAL_STATUS.PUBLIC) {
    return <Badge title={PORTAL_STATUS_STR.PUBLIC} />;
  }
  return <p>-</p>;
};

const ChatStatusBadge = ({ status }: { status: CHAT_STATUS }): JSX.Element => {
  if (status === CHAT_STATUS.STREAMING) {
    return <Badge title={CHAT_STATUS_JAP.STREAMING} />;
  }
  if (status === CHAT_STATUS.STOP) {
    return <Badge title={CHAT_STATUS_JAP.STOP} />;
  }
  return <p>-</p>;
};

/** スタイル */
const useStyle = makeStyles({
  root: {
    marginTop: 20,
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
    width: MIN_WIDTH,
    boxSizing: 'border-box',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '@media (min-width: 1501px)': {
      width: `calc(100vw - (1500px - ${MIN_WIDTH}px))`,
    },
    '&.windows': {
      '@media (min-width: 1501px)': {
        width: `calc(100vw - (1500px - ${MIN_WIDTH}px + 20px))`,
      },
    },
  },
  header: {
    background: '#333333',
    color: '#FFFFFF',
  },
  headerCell: {
    color: '#FFFFFF',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 700,
    borderRight: '1px solid #E0E0E0',
    padding: '16px 0',
    '&:last-child': {
      borderRight: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      marginRight: 5,
    },
  },
  bodyCell: {
    fontSize: 14,
    borderRight: '1px solid #E0E0E0',
    padding: '4px 8px',
    '&:last-child': {
      borderRight: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      marginRight: 8,
    },
    '& a': {
      maxWidth: 193,
      display: 'inline-block',
      overflowWrap: 'break-word',
      color: '#4472C4',
    },
  },
  bodyRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  selectedRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  checkbox: {
    color: '#999999',
    '&.Mui-checked': {
      color: '#4A8BFB',
    },
    '& .MuiSvgIcon-root': {
      zIndex: 2,
    },
    '& .MuiIconButton-label': {
      position: 'relative',
      '&::before': {
        position: 'absolute',
        content: '""',
        background: '#FFFFFF',
        width: 16,
        height: 16,
        borderRadius: 2,
      },
    },
  },
  button: {
    background: '#D8D8D8',
    borderBottom: '1px solid #AAAAAA',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 14,
    width: '100%',
    '& .MuiButton-label': {
      color: '#333333',
    },
    '&.Mui-disabled .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    '&:hover': {
      background: '#333333',
      '& .MuiButton-label': {
        color: '#FFFFFF',
      },
    },
  },
  warningContainer: {
    position: 'relative',
  },
  tooltipArrow: {
    backgroundColor: '#333333',
  },
  arrow: {
    color: '#333333',
  },
  toolipHidden: {
    display: 'none',
  },
});

type propsType = {
  props: LiveListProps;
  checkedLpids: number[];
  lpids: number[];
  checkId: (lpid: number) => void;
  checkAllId: (lpids: number[]) => void;
  handleOpenEncoderDialog: (lpid: number, haveWlivesId: boolean) => void;
  handleOpenShareDialog: (lpid: number, haveWlivesId: boolean) => void;
  handleTransitionDesktopStream: (
    lpid: number,
    haveWlivesId: boolean,
    handleTransitionDesktopStream: string
  ) => void;
  handleTransitionController: (lpid: number, haveWlivesId: boolean) => void;
  handleTransitionMonitor: (lpid: number, haveWlivesId: boolean) => void;
  limit: number;
  page: number;
  uiStatus: UI_STATUS;
  handleSort: () => void;
};

/** ライブの配信リスト */
const LiveListTable = ({
  props,
  checkedLpids,
  lpids,
  checkId,
  checkAllId,
  handleOpenEncoderDialog,
  handleOpenShareDialog,
  handleTransitionDesktopStream,
  handleTransitionController,
  handleTransitionMonitor,
  limit,
  page,
  uiStatus,
  handleSort,
}: propsType): JSX.Element => {
  const classes = useStyle();
  const os = window.navigator.userAgent.toLowerCase();

  // NOTE: 明示化するために名前変更
  const sortedList = props.liveProfiles;

  // State
  /** 適用中のソートを制御 */
  const [sortProperty, setSortProperty] = useState<LIVE_LIST_SORT_PROPERTY>(
    LIVE_LIST_SORT_PROPERTY.LPID
  );
  /** ライブIDのソートを制御 */
  const [sortPropertyLpid, setSortPropertyLpid] =
    useState<LIVELIST_SORT_LIVEID>(LIVELIST_SORT_LIVEID.ASCE);
  /** ライブステータスのソートを制御 */
  const [sortPropertyLiveStatus, setSortPropertyLiveStatus] =
    useState<SORT_STATUS>(SORT_STATUS.NONE);
  /** チャットステータスのソートを制御 */
  const [sortPropertyChatStatus, setSortPropertyChatStatus] =
    useState<SORT_STATUS>(SORT_STATUS.NONE);
  /** ポータルステータスのソートを制御 */
  const [sortPropertyPortalStatus, setSortPropertyPortalStatus] =
    useState<SORT_STATUS>(SORT_STATUS.NONE);
  /** ライブキャストのソートを制御 */
  const [sortPropertyLiveApp, setSortPropertyLiveApp] = useState<SORT_STATUS>(
    SORT_STATUS.NONE
  );
  /** デスクトップ配信のソートを制御 */
  const [sortPropertyDesktop, setSortPropertyDesktop] = useState<SORT_STATUS>(
    SORT_STATUS.NONE
  );
  /** ページネーション対応リスト */
  const [pagedList, setPagedList] = useState<TableLiveProfiles[]>(
    sortedList.slice(0, limit)
  );

  const isTransitionable = (status: LIVE_STATUS) => {
    return status !== LIVE_STATUS.CREATING && status !== LIVE_STATUS.ERROR;
  };

  const isChecked = (lpid: number) => {
    return Boolean(typeof checkedLpids.find((x) => x === lpid) !== 'undefined');
  };
  const isAllChecked = () => {
    checkedLpids.sort((a: number, b: number) => a - b);
    // 表示範囲内のチェック可能lpidのリスト
    const pagedIds: number[] = pagedList
      .map((x) => {
        if (lpids.find((y) => y === x.lpid)) return x.lpid;
        return null;
      })
      .filter((x): x is NonNullable<typeof x> => x !== null);
    pagedIds.sort((a: number, b: number) => a - b);
    const checklpidStr = JSON.stringify(checkedLpids);
    const lpidStr = JSON.stringify(pagedIds);
    if (pagedIds.length === 0) {
      return false;
    }
    return checklpidStr === lpidStr;
  };
  const checkAll = () => {
    // 表示範囲内のチェック可能lpidのリストを渡す
    checkAllId(
      pagedList
        .map((x) => {
          if (lpids.find((y) => y === x.lpid)) return x.lpid;
          return null;
        })
        .filter((x): x is NonNullable<typeof x> => x !== null)
    );
  };

  /**
   * 自動画質設定更新フラグとUI_STATUSをもとにボタンの非活性等を実施するか判定
   */
  const isAutoQuqlityUpdate = (
    uiStatus: UI_STATUS,
    updatedAutoQualityFlg?: boolean
  ) => {
    return updatedAutoQualityFlg || uiStatus !== UI_STATUS.COMPLETED;
  };

  // ソート機能・イベント
  /** ライブIDのソート：降順、昇順 */
  const sortLpid = (change = true) => {
    if (change) setSortProperty(LIVE_LIST_SORT_PROPERTY.LPID);
    if (
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.DESCE && change) ||
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.ASCE && !change)
    ) {
      if (change) setSortPropertyLpid(LIVELIST_SORT_LIVEID.ASCE);
      sortedList.sort((a, b) => (a.lpid < b.lpid ? 1 : -1));
    } else if (
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.DESCE && !change) ||
      (sortPropertyLpid === LIVELIST_SORT_LIVEID.ASCE && change)
    ) {
      if (change) setSortPropertyLpid(LIVELIST_SORT_LIVEID.DESCE);
      sortedList.sort((a, b) => (a.lpid > b.lpid ? 1 : -1));
    }
    if (change) setPagedList(sortedList.slice(0, limit));
  };

  /** ライブステータスのソート
   * 昇順：本番配信中、配信準備中、DVR残、配信停止中
   * 降順：配信停止中、DVR残、配信準備中、本番配信中
   */
  const sortLiveStatus = (change = true) => {
    if (change) setSortProperty(LIVE_LIST_SORT_PROPERTY.STATUS);
    if (
      ((sortPropertyLiveStatus === SORT_STATUS.ASCE ||
        sortPropertyLiveStatus === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyLiveStatus === SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyLiveStatus(SORT_STATUS.DESCE);
      sortedList.sort((a, b) => {
        const livestatusA =
          // eslint-disable-next-line no-nested-ternary
          a.liveStatus !== LIVE_STATUS.STREAMING
            ? a.liveStatus
            : a.dvrCloseDate
            ? LIVE_STATUS_DVR_REMAIN
            : LIVE_STATUS.STREAMING;

        const livestatusB =
          // eslint-disable-next-line no-nested-ternary
          b.liveStatus !== LIVE_STATUS.STREAMING
            ? b.liveStatus
            : b.dvrCloseDate
            ? LIVE_STATUS_DVR_REMAIN
            : LIVE_STATUS.STREAMING;
        return (
          SORTLIST_LIVESTATUS_ASCE.indexOf(livestatusA) -
          SORTLIST_LIVESTATUS_ASCE.indexOf(livestatusB)
        );
      });
    } else if (
      (sortPropertyLiveStatus === SORT_STATUS.DESCE && change) ||
      (sortPropertyLiveStatus !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyLiveStatus(SORT_STATUS.ASCE);
      sortedList.sort((a, b) => {
        const livestatusA =
          // eslint-disable-next-line no-nested-ternary
          a.liveStatus !== LIVE_STATUS.STREAMING
            ? a.liveStatus
            : a.dvrCloseDate
            ? LIVE_STATUS_DVR_REMAIN
            : LIVE_STATUS.STREAMING;

        const livestatusB =
          // eslint-disable-next-line no-nested-ternary
          b.liveStatus !== LIVE_STATUS.STREAMING
            ? b.liveStatus
            : b.dvrCloseDate
            ? LIVE_STATUS_DVR_REMAIN
            : LIVE_STATUS.STREAMING;
        return (
          SORTLIST_LIVESTATUS_DESCE.indexOf(livestatusA) -
          SORTLIST_LIVESTATUS_DESCE.indexOf(livestatusB)
        );
      });
    }
    if (change) setPagedList(sortedList.slice(0, limit));
  };

  /** チャットステータスのソート
   * 昇順： 公開中, 停止中, -
   * 降順： -, 停止中, 公開中
   */
  const sortChatStatus = (change = true) => {
    if (change) setSortProperty(LIVE_LIST_SORT_PROPERTY.CHAT_STATUS);
    if (
      ((sortPropertyChatStatus === SORT_STATUS.ASCE ||
        sortPropertyChatStatus === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyChatStatus === SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyChatStatus(SORT_STATUS.DESCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_CHATSTATUS_ASCE.indexOf(a.chatStatus) -
          SORTLIST_CHATSTATUS_ASCE.indexOf(b.chatStatus)
      );
    } else if (
      (sortPropertyChatStatus === SORT_STATUS.DESCE && change) ||
      (sortPropertyChatStatus !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyChatStatus(SORT_STATUS.ASCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_CHATSTATUS_DESCE.indexOf(a.chatStatus) -
          SORTLIST_CHATSTATUS_DESCE.indexOf(b.chatStatus)
      );
    }
    if (change) setPagedList(sortedList.slice(0, limit));
  };

  /** ポータルステータスのソート
   * 昇順： 公開中, 非公開, -
   * 降順： -, 非公開, 公開中
   */
  const sortPortalStatus = (change = true) => {
    if (change) setSortProperty(LIVE_LIST_SORT_PROPERTY.PORTAL_STATUS);
    if (
      ((sortPropertyPortalStatus === SORT_STATUS.ASCE ||
        sortPropertyPortalStatus === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyPortalStatus === SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyPortalStatus(SORT_STATUS.DESCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_PORTALSTATUS_ASCE.indexOf(a.portalStatus) -
          SORTLIST_PORTALSTATUS_ASCE.indexOf(b.portalStatus)
      );
    } else if (
      (sortPropertyPortalStatus === SORT_STATUS.DESCE && change) ||
      (sortPropertyPortalStatus !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyPortalStatus(SORT_STATUS.ASCE);
      sortedList.sort(
        (a, b) =>
          SORTLIST_PORTALSTATUS_DESCE.indexOf(a.portalStatus) -
          SORTLIST_PORTALSTATUS_DESCE.indexOf(b.portalStatus)
      );
    }
    if (change) setPagedList(sortedList.slice(0, limit));
  };

  /** ライブキャストのソート：使用 → 未使用 */
  const sortLiveApp = (change = true) => {
    if (change) setSortProperty(LIVE_LIST_SORT_PROPERTY.LIVE_APP);
    if (
      ((sortPropertyLiveApp === SORT_STATUS.ASCE ||
        sortPropertyLiveApp === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyLiveApp !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyLiveApp(SORT_STATUS.DESCE);
      sortedList.sort((a, b) => (a.liveAppFlg < b.liveAppFlg ? 1 : -1));
    } else if (
      (sortPropertyLiveApp === SORT_STATUS.DESCE && change) ||
      (sortPropertyLiveApp !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyLiveApp(SORT_STATUS.ASCE);
      sortedList.sort((a, b) => (a.liveAppFlg > b.liveAppFlg ? 1 : -1));
    }
    if (change) setPagedList(sortedList.slice(0, limit));
  };

  /** デスクトップ配信のソート：使用 → 未使用 */
  const sortDesktop = (change = true) => {
    if (change) setSortProperty(LIVE_LIST_SORT_PROPERTY.DESKTOP);
    if (
      ((sortPropertyDesktop === SORT_STATUS.ASCE ||
        sortPropertyDesktop === SORT_STATUS.NONE) &&
        change) ||
      (sortPropertyDesktop === SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyDesktop(SORT_STATUS.DESCE);
      sortedList.sort((a, b) => (a.desktopEncoder < b.desktopEncoder ? 1 : -1));
    } else if (
      (sortPropertyDesktop === SORT_STATUS.DESCE && change) ||
      (sortPropertyDesktop !== SORT_STATUS.DESCE && !change)
    ) {
      if (change) setSortPropertyDesktop(SORT_STATUS.ASCE);
      sortedList.sort((a, b) => (a.desktopEncoder > b.desktopEncoder ? 1 : -1));
    }
    if (change) setPagedList(sortedList.slice(0, limit));
  };

  /** 表示範囲変更の対応 */
  useEffect(() => {
    // 一覧内容が更新された場合にもソートを適用させる
    if (sortProperty === LIVE_LIST_SORT_PROPERTY.LPID) {
      sortLpid(false);
    }
    if (sortProperty === LIVE_LIST_SORT_PROPERTY.STATUS) {
      sortLiveStatus(false);
    }
    if (sortProperty === LIVE_LIST_SORT_PROPERTY.CHAT_STATUS) {
      sortChatStatus(false);
    }
    if (sortProperty === LIVE_LIST_SORT_PROPERTY.PORTAL_STATUS) {
      sortPortalStatus(false);
    }
    if (sortProperty === LIVE_LIST_SORT_PROPERTY.LIVE_APP) {
      sortLiveApp(false);
    }
    if (sortProperty === LIVE_LIST_SORT_PROPERTY.DESKTOP) {
      sortDesktop(false);
    }
    // 表示範囲の適用
    setPagedList(sortedList.slice((page - 1) * limit, page * limit));
  }, [page, limit, sortedList]);

  /** ソートによる表示範囲変更の対応 */
  useEffect(() => {
    handleSort();
  }, [sortProperty]);

  return (
    <TableContainer
      component={Paper}
      className={`${classes.root} ${
        os.indexOf('windows nt') !== -1 ? 'windows' : ''
      }`}
    >
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell className={classes.headerCell} width="7.4%">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Checkbox
                  className={classes.checkbox}
                  color="default"
                  checked={isAllChecked()}
                  onClick={checkAll}
                />
                <Box display="inline-block" textAlign="center">
                  ライブ
                  <br />
                  ID
                </Box>
                <Box onClick={() => sortLpid()}>
                  <SortArrow
                    sort={sortPropertyLpid}
                    active={sortProperty === LIVE_LIST_SORT_PROPERTY.LPID}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="15.7%"
            >
              ライブ設定名
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="6.1%"
            >
              同時接続数
              <br />
              制限
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="7.5%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">
                  ライブ
                  <br />
                  ステータス
                </Box>
                <Box onClick={() => sortLiveStatus()}>
                  <SortArrow
                    sort={sortPropertyLiveStatus}
                    active={sortProperty === LIVE_LIST_SORT_PROPERTY.STATUS}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="6.6%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">
                  チャット
                  <br />
                  ステータス
                </Box>
                <Box onClick={() => sortChatStatus()}>
                  <SortArrow
                    sort={sortPropertyChatStatus}
                    active={
                      sortProperty === LIVE_LIST_SORT_PROPERTY.CHAT_STATUS
                    }
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="8.3%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">
                  ポータル公開
                  <br />
                  ステータス
                </Box>
                <Box onClick={() => sortPortalStatus()}>
                  <SortArrow
                    sort={sortPropertyPortalStatus}
                    active={
                      sortProperty === LIVE_LIST_SORT_PROPERTY.PORTAL_STATUS
                    }
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="5.4%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">
                  ライブ
                  <br />
                  キャスト
                </Box>
                <Box onClick={() => sortLiveApp()}>
                  <SortArrow
                    sort={sortPropertyLiveApp}
                    active={sortProperty === LIVE_LIST_SORT_PROPERTY.LIVE_APP}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="9.6%"
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="inline-block">
                  デスクトップ
                  <br />
                  配信
                </Box>
                <Box onClick={() => sortDesktop()}>
                  <SortArrow
                    sort={sortPropertyDesktop}
                    active={sortProperty === LIVE_LIST_SORT_PROPERTY.DESKTOP}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="9.6%"
            >
              ライブ
              <br />
              エンコーダー設定
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="7.1%"
            >
              共通タグ
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="7.1%"
            >
              コントロール
              <br />
              パネル
            </TableCell>
            <TableCell
              align="center"
              className={classes.headerCell}
              width="7.1%"
            >
              モニタリング
              <br />
              画面
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...pagedList].map((live) => (
            <TableRow
              key={live.lpid}
              className={`${classes.bodyRow} ${
                isChecked(live.lpid) && classes.selectedRow
              }`}
            >
              <TableCell className={classes.bodyCell}>
                <Checkbox
                  className={classes.checkbox}
                  color="default"
                  checked={isChecked(live.lpid)}
                  onClick={() => checkId(live.lpid)}
                  disabled={
                    live.liveStatus !== LIVE_STATUS.STOP ||
                    live.chatStatus === CHAT_STATUS.STREAMING
                  }
                />
                {live.lpid}
              </TableCell>
              <TableCell className={classes.bodyCell}>
                {live.updatedAutoQualityFlg ? (
                  <span
                    className={classes.warningContainer}
                    style={{ display: 'inline-block' }}
                  >
                    <Tooltip
                      classes={{
                        tooltipArrow: classes.tooltipArrow,
                        arrow: classes.arrow,
                      }}
                      disableFocusListener
                      arrow
                      placement="bottom"
                      title="ライブ自動画質設定ファイルが更新されています"
                    >
                      <Warning
                        style={{
                          color: '#CB0E04',
                          verticalAlign: 'top',
                          marginRight: '4px',
                        }}
                      />
                    </Tooltip>
                  </span>
                ) : (
                  ''
                )}
                <Link
                  to={`${getPath(ROUTE.LIVE_CONTENT)}?lpid=${live.lpid}&c=${
                    props.cid
                  }&t=${props.readAPIToken}&u=${props.userApiParams}`}
                >
                  {live.liveName}
                </Link>
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) &&
                  (props.edition === EDITIONS.U_LIVE
                    ? '制限なし'
                    : `${live.maxClient}人`)}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                <Badge title={live.liveStatus} />
                {isTransitionable(live.liveStatus) &&
                  live.dvrCloseDate &&
                  `DVR ${moment(live.dvrCloseDate.replace(/\//g, '-')).format(
                    'MM/DD HH:mm'
                  )}迄`}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && (
                  <ChatStatusBadge status={live.chatStatus} />
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && (
                  <PortalStatusBadge status={Number(live.portalStatus)} />
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell} width="7%">
                {isTransitionable(live.liveStatus) && live.liveAppFlg && (
                  <Check fontSize="small" />
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && live.desktopEncoder && (
                  <Box
                    onClick={() =>
                      handleTransitionDesktopStream(
                        live.lpid,
                        live.haveWlivesId,
                        props.userApiParams
                      )
                    }
                  >
                    <CustomButtomTarget
                      title="配信者画面"
                      className={classes.button}
                    />
                  </Box>
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && live.liveShareFlg && (
                  <Box
                    onClick={() =>
                      handleOpenEncoderDialog(live.lpid, live.haveWlivesId)
                    }
                  >
                    <CustomButtomView title="取得" className={classes.button} />
                  </Box>
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && (
                  <Box
                    onClick={() =>
                      handleOpenShareDialog(live.lpid, live.haveWlivesId)
                    }
                  >
                    <CustomButtomView title="表示" className={classes.button} />
                  </Box>
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && (
                  <Box
                    // 自動画質設定更新フラグの処理判定中は画面遷移を無効化
                    onClick={
                      isAutoQuqlityUpdate(uiStatus, live.updatedAutoQualityFlg)
                        ? () => {
                            // 画面遷移を無効化
                          }
                        : () =>
                            handleTransitionController(
                              live.lpid,
                              live.haveWlivesId
                            )
                    }
                  >
                    <Tooltip
                      // 自動画質更新フラグによってツールチップの表示を切り替え
                      classes={{
                        tooltipArrow: isAutoQuqlityUpdate(
                          uiStatus,
                          live.updatedAutoQualityFlg
                        )
                          ? classes.tooltipArrow
                          : classes.toolipHidden,
                        arrow: isAutoQuqlityUpdate(
                          uiStatus,
                          live.updatedAutoQualityFlg
                        )
                          ? classes.arrow
                          : classes.toolipHidden,
                      }}
                      arrow
                      placement="bottom"
                      title="ライブ自動画質設定ファイルが更新されています"
                    >
                      <span>
                        <CustomButtomTarget
                          title="開く"
                          className={classes.button}
                          // 自動画質設定更新フラグの処理判定中はボタンを無効化
                          disabled={isAutoQuqlityUpdate(
                            uiStatus,
                            live.updatedAutoQualityFlg
                          )}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                )}
              </TableCell>
              <TableCell align="center" className={classes.bodyCell}>
                {isTransitionable(live.liveStatus) && (
                  <Box
                    onClick={() =>
                      handleTransitionMonitor(live.lpid, live.haveWlivesId)
                    }
                  >
                    <CustomButtomTarget
                      title="開く"
                      className={classes.button}
                    />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LiveListTable;
