import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Box } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MIN_WIDTH } from '../../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px #0000001F',
    border: '1px solid #F0F0F0',
    width: 'fit-content',
    maxWidth: MIN_WIDTH,
    height: 'fit-content',
    borderRadius: 8,
  },
  header: {
    padding: '20px 30px',
    borderBottom: '1px solid #F0F0F0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': {
      margin: 0,
      fontSize: 20,
    },
  },
  body: {
    padding: '30px 40px',
    height: 'fit-content',
    overflow: 'auto',
  },
  image: {
    border: '1px dashed #70707080',
    width: 640,
    height: 360,
  },
});

type propType = {
  open: boolean;
  handleClose: () => void;
  title: React.ReactNode;
  imageUrl: string;
  imageAlt: string;
};

/** 汎用モーダル(UI) */
const DisplayModal = ({
  open,
  handleClose,
  title,
  imageUrl,
  imageAlt,
}: propType): JSX.Element => {
  const classes = useStyle();
  return (
    <Modal
      className={classes.root}
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: '#00000033',
        },
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.header}>
          <h3>{title}</h3>
          <Close onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Box>
        <Box className={classes.body}>
          <img className={classes.image} src={imageUrl} alt={imageAlt} />
        </Box>
      </Box>
    </Modal>
  );
};

export default DisplayModal;
