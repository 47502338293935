import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract } from '../types/Contract';
import {
  GetContract,
  GetLiveSetUpDataList,
  GetAutoQuality,
  GetPlayerList,
  GetQRProfile,
  ViewLimitsProfile,
} from '../types/API';
import { Group } from '../types/Group';
import { LiveABR } from '../types/LiveABR';
import { Transcode } from '../types/Transcode';
import { Player } from '../types/Player';
import {
  EDITIONS,
  ONOFF,
  PLAYER_ASPECTFIX,
  PUBSITES_PULLDOWN_NAME,
  QR_FORMAT,
} from '../utils/Const';
import { QR } from '../types/QR';

/** ページ遷移元から得る情報 */
type FromPreviousPage = {
  /** EQユーザーのIDとパスワード */
  user: string;

  /** 契約会社ID */
  cid: number;

  /** ReadAPIトークン */
  readAPIToken: string;
};

type State = {
  /** EQユーザーのIDとパスワード */
  user: string;

  /** 契約会社情報 */
  contract: Contract;

  /** ライブ自動画質設定一覧 */
  liveabr: LiveABR[];

  /** トランスコード設定一覧 */
  transcode: Transcode[];

  /** アーカイブ管理グループ一覧 */
  group: Group[];

  /** 視聴制限プロファイル一覧 */
  viewLimitsProfile: ViewLimitsProfile[];

  /** プレイヤー一覧 */
  player: Player;

  /** QRコード生成情報 */
  qr: QR;
};

const initialState: State = {
  user: '',
  contract: {
    cid: 0,
    companyName: '',
    directory: '',
    edition: EDITIONS.NONE,
    uploadSizeLimit: 0,
    readAPIToken: '',
    serviceFlg25: 0,
    serviceFlg37: 0,
    serviceFlg39: 0,
    liveInfo: {
      nowCnt: 0,
      nowClientCnt: 0,
      cntLive: 0,
      maxClient: 0,
      encrypt: 0,
      bitrateFhd: 0,
      bitrateHd: 0,
      bitrate1: 0,
      bitrate2: 0,
      bitrate3: 0,
      widthFhd: 0,
      widthHd: 0,
      width1: 0,
      width2: 0,
      width3: 0,
      heightFhd: 0,
      heightHd: 0,
      height1: 0,
      height2: 0,
      height3: 0,
      filetoLiveNowClientCnt: 0,
      filetoLiveNowCnt: 0,
      filetoliveCnt: 0,
      filetoliveAddCnt: 0,
    },
  },
  liveabr: [],
  transcode: [],
  group: [],
  viewLimitsProfile: [],
  player: {
    errorCode: 0,
    message: '',
    players: [],
  },
  qr: {
    size: 0,
    dpi: 0,
    format: QR_FORMAT.NONE,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // 遷移元urlからのユーザー、契約会社情報
    setFromPreviousPage(state: State, action: PayloadAction<FromPreviousPage>) {
      const { user, cid, readAPIToken } = action.payload;
      state.user = user;
      state.contract.cid = cid;
      state.contract.readAPIToken = readAPIToken;
    },
    // APIによる契約会社情報更新
    setContract(state: State, action: PayloadAction<GetContract>) {
      const {
        companyName,
        directory,
        edition,
        uploadSizeLimit,
        serviceFlg25,
        serviceFlg37,
        serviceFlg39,
        liveInfo,
      } = action.payload;
      state.contract.companyName = companyName;
      state.contract.directory = directory;
      state.contract.edition = edition;
      state.contract.uploadSizeLimit = uploadSizeLimit;
      state.contract.serviceFlg25 = serviceFlg25;
      state.contract.serviceFlg37 = serviceFlg37;
      state.contract.serviceFlg39 = serviceFlg39;
      state.contract.liveInfo = liveInfo;
    },
    // APIによるライブ新規登録プルダウンリスト更新
    setLiveSetUpDataList(
      state: State,
      action: PayloadAction<GetLiveSetUpDataList>
    ) {
      const { autoQuolity, masters, groups, viewLimits } = action.payload;
      state.liveabr = autoQuolity.profiles;
      state.transcode = masters.profile;
      state.group = groups.group;
      state.viewLimitsProfile = viewLimits.profile;
      if (!state.viewLimitsProfile) {
        state.viewLimitsProfile = [
          {
            vlpid: 0,
            profileName: '制限なし',
            profileDetail: '',
            details: '',
          },
        ];
      } else {
        state.viewLimitsProfile.unshift({
          vlpid: 0,
          profileName: '制限なし',
          profileDetail: '',
          details: '',
        });
      }
    },
    // ライブ自動画質設定更新
    setAutoQuality(state: State, action: PayloadAction<GetAutoQuality>) {
      const { autoQuolity } = action.payload;
      state.liveabr = autoQuolity.profiles;
    },
    // APIによるプレイヤー一覧更新
    setPlayers(state: State, action: PayloadAction<GetPlayerList>) {
      state.player = action.payload;
      state.player.players.push({
        pid: -1,
        name: PUBSITES_PULLDOWN_NAME,
        defaultFlg: 0,
        width: 0,
        height: 0,
        lightwidth: 0,
        lightheight: 0,
        aspectratio: PLAYER_ASPECTFIX.NONE,
        protocol: '',
        playertype: 11,
        sound: ONOFF.OFF,
        control: ONOFF.OFF,
        volume: ONOFF.OFF,
        playpause: ONOFF.OFF,
        seekbar: ONOFF.OFF,
        hidescreen: ONOFF.OFF,
        autostart: ONOFF.OFF,
        autoresize: ONOFF.OFF,
        fullscreen: ONOFF.OFF,
        useendimage: ONOFF.OFF,
        footer: '',
        footeralign: null,
        title: ONOFF.OFF,
        multibitrate: ONOFF.OFF,
        exlink: ONOFF.OFF,
        tag: ONOFF.OFF,
        sns: ONOFF.OFF,
        facebook: ONOFF.OFF,
        twitter: ONOFF.OFF,
        mixi: ONOFF.OFF,
        google: ONOFF.OFF,
        line: ONOFF.OFF,
        replay: ONOFF.OFF,
        inboundlink: ONOFF.OFF,
        resume: ONOFF.OFF,
        watermark: ONOFF.OFF,
        watermarkDesign: 1,
        playbackRate1: 0,
        playbackRate2: 0,
        playbackRate3: 0,
        playbackRate4: 0,
        playbackRate5: 0,
        subtitle: ONOFF.OFF,
        qualityPc: 0,
        qualitySd: 0,
        loopPlay: ONOFF.OFF,
        playlistAutoplay: ONOFF.OFF,
        forbackwardBtn: ONOFF.OFF,
        forbackwardBtnSecond: 0,
        backwardBtn: ONOFF.OFF,
        backwardBtnSecond: 0,
        forwardBtn: ONOFF.OFF,
        forwardBtnSecond: 0,
        chapter: ONOFF.OFF,
        videoanalyzerCookie: ONOFF.OFF,
        resumeCookie: ONOFF.OFF,
      });
    },
    // APIによるQR生成情報更新
    setQR(state: State, action: PayloadAction<GetQRProfile>) {
      state.qr = action.payload;
    },
  },
});

export const {
  setFromPreviousPage,
  setContract,
  setLiveSetUpDataList,
  setAutoQuality,
  setPlayers,
  setQR,
} = userSlice.actions;

export default userSlice.reducer;
