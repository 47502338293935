import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { LIVE_STATUS_CLOSE_ERR_MSG } from '../../utils/Message';
import {
  setError,
  setFileToLiveControllerStatus,
} from '../../features/uiSlice';
import { UI_STATUS } from '../../utils/Const';
import { GetFileToLiveProfile } from '../../types/API';
import { addEQFileToLiveProfile } from '../../features/fileToLivesSlice';

type LiveStopParams = {
  flpid: number;
  cid: number;
  userApiParams: string;
};

/** ライブ配信停止フロー */
const LiveStop =
  (arg: LiveStopParams): AppThunk =>
  async (dispatch) => {
    // 配信処理中をセット
    dispatch(setFileToLiveControllerStatus(UI_STATUS.PROCESSING_STREAM));
    try {
      // ライブ停止API実行
      await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/stopLive`,
        arg,
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetFileToLiveProfile> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/getProfile`,
          arg,
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(
        addEQFileToLiveProfile({
          ...responseProfile.data.profile[0],
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: LIVE_STATUS_CLOSE_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setFileToLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default LiveStop;
