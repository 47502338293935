import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { addLiveEvent, setQualityHistory } from '../../features/historiesSlice';
import { setError, setHistoryContentStatus } from '../../features/uiSlice';
import { setContract, setLiveSetUpDataList } from '../../features/userSlice';
import {
  GetContract,
  GetHistoryProfile,
  GetLiveSetUpDataList,
  GetAutoQualityHistory,
} from '../../types/API';
import { UI_STATUS } from '../../utils/Const';
import { LIVE_HISTORY_INIT_FLOW_ERR_MSG } from '../../utils/Message';

/** ライブ配信設定履歴 初期表示処理フロー */
const HistoryContentInit =
  (lplid: string): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    // 処理中をセット
    dispatch(setHistoryContentStatus(UI_STATUS.PROCESSING));
    try {
      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: user.contract.cid,
          userApiParams: user.user,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setContract({ ...responseGetContract.data }));

      // ライブ新規登録プルダウンリスト取得API実行
      const responseGetLiveSetUpDataList: AxiosResponse<GetLiveSetUpDataList> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}getLiveSetUpDataList`,
          {
            cid: user.contract.cid,
            userApiParams: user.user,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      dispatch(setLiveSetUpDataList({ ...responseGetLiveSetUpDataList.data }));

      // ライブ履歴取得API実行
      const response: AxiosResponse<GetHistoryProfile> = await axios.get(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getLiveProfileArch`,
        {
          params: {
            t: user.contract.readAPIToken,
            lpid: Number(lplid.split('-')[0]),
          },
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(addLiveEvent({ historyProfile: response.data, lplid }));

      // ライブ自動画質設定履歴取得API実行
      const responseQuality: AxiosResponse<GetAutoQualityHistory> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}getAutoQualityHistory`,
          {
            cid: user.contract.cid,
            userApiParams: user.user,
            lpid: Number(lplid.split('-')[0]),
            leid: Number(lplid.split('-')[1]),
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      dispatch(setQualityHistory(responseQuality.data));

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVE_HISTORY_INIT_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setHistoryContentStatus(UI_STATUS.COMPLETED));
    }
  };

export default HistoryContentInit;
