import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  addEQFileToLiveProfile,
  selectEQFileToLivesById,
  setEQFileToLiveFiller,
  setEQFileToLiveMovie,
} from '../../features/fileToLivesSlice';
import {
  setError,
  setFileToLiveRegisterStatus,
  setFileToLiveContentStatus,
} from '../../features/uiSlice';
import { setContract } from '../../features/userSlice';
import {
  GetContract,
  GetFileToLiveFiller,
  GetFileToLiveMovie,
  GetFileToLiveProfile,
} from '../../types/API';
import { LIVE_SAVE, UI_STATUS } from '../../utils/Const';
import {
  FILE_TO_LIVE_ADD_INIT_FLOW_ERR_MSG,
  FILE_TO_LIVE_UPDATE_INIT_FLOW_ERR_MSG,
} from '../../utils/Message';

/** ライブプロファイル初期表示処理フロー */
const FileToLiveProfileInit =
  (save: LIVE_SAVE): AppThunk =>
  async (dispatch, getState) => {
    const { user } = getState();
    const searchString = window.location.search;
    const params = new URLSearchParams(searchString);
    const userApiParams = encodeURI(String(params.get('u'))).replace(
      /%20/g,
      '+'
    );
    // 処理中をセット
    dispatch(
      save === LIVE_SAVE.ADD
        ? setFileToLiveRegisterStatus(UI_STATUS.PROCESSING)
        : setFileToLiveContentStatus(UI_STATUS.PROCESSING)
    );
    try {
      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: user.contract.cid,
          userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setContract({ ...responseGetContract.data }));

      let mid = null;

      // 更新の場合のみ
      if (save === LIVE_SAVE.UPDATE) {
        // ライブプロファイル取得API実行
        const responseGetLiveProfile: AxiosResponse<GetFileToLiveProfile> =
          await axios.post(
            `${String(
              process.env.REACT_APP_BACKEND_API_ENDPOINT
            )}filetolive/getProfile`,
            {
              flpid: Number(params.get('flpid')),
              cid: user.contract.cid,
              userApiParams,
            },
            {
              headers: {
                accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        dispatch(
          addEQFileToLiveProfile({
            ...responseGetLiveProfile.data.profile[0],
          })
        );

        const EQFileTolive = selectEQFileToLivesById(
          getState(),
          Number(params.get('flpid'))
        );
        mid = EQFileTolive?.mid;

        const prefillerMid = EQFileTolive?.prefillerMid || 0;
        if (prefillerMid > 0) {
          // 疑似ライブ開始前フィラー利用取得API実行
          const responseGetPreFileToLiveFiller: AxiosResponse<GetFileToLiveFiller> =
            await axios.post(
              `${String(
                process.env.REACT_APP_BACKEND_API_ENDPOINT
              )}filetolive/getFillerList`,
              {
                mid,
                cid: user.contract.cid,
                userApiParams: user.user,
                pageSize: 100,
                pageNumber: 1,
                searchFillerMid: prefillerMid,
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
          const { movies: preFillers } = responseGetPreFileToLiveFiller.data;
          dispatch(
            setEQFileToLiveFiller({ type: 'pre', fillers: preFillers || [] })
          );
        }

        const postfillerMid = EQFileTolive?.postfillerMid || 0;
        if (postfillerMid > 0) {
          // 疑似ライブ終了後フィラー利用取得API実行
          const responseGetPostFileToLiveFiller: AxiosResponse<GetFileToLiveFiller> =
            await axios.post(
              `${String(
                process.env.REACT_APP_BACKEND_API_ENDPOINT
              )}filetolive/getFillerList`,
              {
                mid,
                cid: user.contract.cid,
                userApiParams: user.user,
                pageSize: 100,
                pageNumber: 1,
                searchFillerMid: postfillerMid,
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
          const { movies: postFillers } = responseGetPostFileToLiveFiller.data;
          dispatch(
            setEQFileToLiveFiller({ type: 'post', fillers: postFillers || [] })
          );
        }
      }

      // 疑似ライブ利用動画取得API実行
      const responseGetFileToLiveMovie: AxiosResponse<GetFileToLiveMovie> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/getMediaList`,
          {
            cid: user.contract.cid,
            userApiParams: user.user,
            pageSize: 100,
            pageNumber: 1,
            mid,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      const { movies } = responseGetFileToLiveMovie.data;
      dispatch(setEQFileToLiveMovie({ movies: movies || [] }));

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg:
            save === LIVE_SAVE.ADD
              ? FILE_TO_LIVE_ADD_INIT_FLOW_ERR_MSG
              : FILE_TO_LIVE_UPDATE_INIT_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(
        save === LIVE_SAVE.ADD
          ? setFileToLiveRegisterStatus(UI_STATUS.COMPLETED)
          : setFileToLiveContentStatus(UI_STATUS.COMPLETED)
      );
    }
  };

export default FileToLiveProfileInit;
