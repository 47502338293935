import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { AppDispatch, RootState } from '../../app/store';
import { setError } from '../../features/uiSlice';
import { selectEQFileToLivesById } from '../../features/fileToLivesSlice';
import { getCount } from '../../utils/ThunkMethod/FileToLivesGetCount';
import {
  GraphIntervals,
  CHAT_START_TIMING,
  CHAT_END_TIMING,
  CHAT_CONSOLE_COMMOM,
  FILE_TO_LIVE_STATUS_ENG,
  STREAMING_GETCOUNT_DELAY,
  FILE_TO_LIVE_CHAT_STATUS,
  ONOFF,
  // UI_STATUS,
  FILETOLIVE_CONTROLL_PLAYERSIZE,
} from '../../utils/Const';
import { ToFileToLiveStatusJap } from '../../utils/ChangeStatus';
import { QUERY_ERR_MSG } from '../../utils/Message';
import { GetLiveConnections } from '../../types/API';
import getLiveControllerFlow from './GetLiveController';
import LiveStop from './LiveStop';
import ChatManualStart from './ChatManualStart';
import ChatManualStop from './ChatManualStop';
import { SnackBarProp } from '../../types/Utils';
import FileToLiveControllerComponent from '../../components/FileToLiveController/FileToLiveControllerComponent';
import {
  EQFileToLivePlayer,
  EQFileToLivePlayerPropsType,
} from '../../utils/EQPlayer';
import getProfile from './GetProfile';

// インターバル変換
const getInterval = (interval: GraphIntervals) => {
  switch (interval) {
    case GraphIntervals.THIRTY_SECOND:
      return 30;
    case GraphIntervals.MINUTES:
      return 60;
    case GraphIntervals.TWO_MINUTES:
      return 120;
    case GraphIntervals.THREE_MINUTES:
      return 180;
    case GraphIntervals.FIVE_MINUTES:
      return 300;
    case GraphIntervals.TEN_MINUTES:
      return 600;
    default:
      return 30;
  }
};

/** コントロールパネル */
const FileToLiveControllerContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // クエリチェック
  const query = new URLSearchParams(useLocation().search);
  const flpid = query.get('flpid') || '';
  if (flpid === '') {
    dispatch(
      setError({
        msg: QUERY_ERR_MSG,
        detail: '',
      })
    );
  }

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const fileToLives = useSelector((state: RootState) => state.fileToLives);
  const status = useSelector((state: RootState) => state.ui.status);
  const EQFileToLive = useAppSelector((state: RootState) =>
    selectEQFileToLivesById(state, flpid)
  );

  // State
  const [interval, setInterval] = useState<GraphIntervals>(
    GraphIntervals.THIRTY_SECOND
  );
  const [fileToLiveClientsInfo, setFileToLiveClientsInfo] =
    useState<GetLiveConnections>({
      data: [],
      max: null,
      current: null,
      total: null,
    } as GetLiveConnections);
  const [liveFinishDialog, setLiveFinishDialog] = useState<boolean>(false);
  const [chatDialog, setChatDialog] = useState<boolean>(false);
  const [previewDialog, setPreviewDialog] = useState<boolean>(false);
  const [previewLid, setPreviewLid] = useState<boolean>(true);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProp[]>([]);
  const [chatDisabled, setChatDisabled] = useState<boolean>(false);

  // 変数抽出
  const { cid, readAPIToken, edition } = user.contract;

  // プレイヤー
  const playerRef = useRef<HTMLIFrameElement>(null);
  const playerProps = {
    playerParams: {
      b: '',
      c: Buffer.from(String(user.contract.cid)).toString('base64'),
      flpid: String(flpid),
      m: Buffer.from(String(EQFileToLive?.mid)).toString('base64'),
      s: {
        hp: FILETOLIVE_CONTROLL_PLAYERSIZE.HEIGHT,
        wp: FILETOLIVE_CONTROLL_PLAYERSIZE.WIDTH,
        rp: 'fit',
        dq: 0,
        mdq: 0,
        pb: ONOFF.ON,
        wm: ONOFF.ON,
        cp: ONOFF.ON,
        vc: ONOFF.ON,
        fs: ONOFF.ON,
        ft: '',
        ti: ONOFF.ON,
        is: ONOFF.OFF,
        ip: ONOFF.OFF,
        el: ONOFF.OFF,
        tg: ONOFF.OFF,
        il: ONOFF.OFF,
        mb: ONOFF.ON,
        sn: '',
        rs: ONOFF.OFF,
        va: ONOFF.OFF,
      },
    },
  };
  const previewProps: EQFileToLivePlayerPropsType = {
    customerDirectory: user.contract.directory,
    playerRef,
    tagId: 'flive-live-controller',
    player: playerProps,
  };

  // 初期処理
  useEffect(() => {
    if (flpid || cid) {
      dispatch(
        getLiveControllerFlow({
          flpid: Number(flpid),
          cid,
          userApiParams: user.user,
        })
      );
    }
    return () => {
      if (playerRef.current?.contentDocument?.documentElement) {
        // プレイヤー削除
        playerRef.current?.contentDocument?.removeChild(
          playerRef.current?.contentDocument?.documentElement
        );
        // プレイヤー再読み込み
        playerRef.current?.contentWindow?.location.reload();
      }
    };
  }, []);

  // NOTE: 画面描画後にplayerRefの中身をセットしてあげる必要がある
  useEffect(() => {
    if (!previewLid) {
      // プレイヤー作成
      EQFileToLivePlayer(previewProps);
    }
  }, [previewLid]);

  const timerRef = useRef<number>();
  // 視聴者数遷移の更新タイマー設定処理
  const setCountTimer = (timeInterval: number) => {
    // 配信中と配信終了以外は、視聴者数の取得をしない
    if (
      !(
        EQFileToLive?.status === FILE_TO_LIVE_STATUS_ENG.STREAMING ||
        EQFileToLive?.status === FILE_TO_LIVE_STATUS_ENG.FINISHED
      )
    )
      return;

    const counter = () => {
      const fromTime = Number(
        moment(EQFileToLive?.startDate).format('YYYYMMDDHHmmss')
      );
      // NOTE: 現時刻の60秒前をtoにして同時接続数を取得することで1分半以上前の時刻を最新としてグラフに描画
      const toTime = Number(
        moment()
          .add(-STREAMING_GETCOUNT_DELAY, 'seconds')
          .format('YYYYMMDDHHmmss')
      );
      // ライブ開始から1分半以上経過後しないと同時接続数は集計できない
      if (toTime - fromTime > STREAMING_GETCOUNT_DELAY) {
        dispatch(
          getCount({
            cid: Number(cid),
            flpid: Number(flpid),
            timeInterval,
            from: fromTime,
            to: toTime,
          })
        );
      }
    };
    // 本番配信中のグラフ初回表示
    counter();
    // その後30秒ごとに処理
    if (EQFileToLive?.status === FILE_TO_LIVE_STATUS_ENG.STREAMING) {
      timerRef.current = window.setInterval(() => {
        counter();
      }, 30000);
    }
  };

  // 視聴者数遷移の更新タイマークリア処理
  const clearCountTimer = () => {
    if (!timerRef.current) return;
    clearInterval(timerRef.current);
  };

  useEffect(() => {
    if (EQFileToLive && !timerRef.current) {
      setCountTimer(getInterval(interval));
    }
  }, [EQFileToLive]);

  // 配信予約中 配信準備中(30分前) 配信中 配信終了のステータスが違う時は、データ更新する
  const statusTimerRef = useRef<number>();
  const setStatusTimer = (timeInterval: number) => {
    clearInterval(statusTimerRef.current);
    statusTimerRef.current = window.setInterval(() => {
      dispatch(
        getProfile({
          flpid: Number(flpid),
          cid,
          userApiParams: user.user,
        })
      );
      setStatusTimer(60000);
    }, timeInterval);
  };
  useEffect(() => {
    clearInterval(statusTimerRef.current);
    let interval = 0;
    // 配信予約中の時は、開始25分前から毎分ステータス再取得
    if (EQFileToLive?.status === FILE_TO_LIVE_STATUS_ENG.WAITING) {
      interval = moment(EQFileToLive?.startDate)
        .add('-25', 'minutes')
        .diff(moment());
    }
    // 配信準備中の時は、開始5分前からステータス再取得
    if (EQFileToLive?.status === FILE_TO_LIVE_STATUS_ENG.READY) {
      interval = moment(EQFileToLive?.startDate)
        .add('-5', 'minutes')
        .diff(moment());
    }
    // 配信中の時は、配信終了からステータス再取得
    if (EQFileToLive?.status === FILE_TO_LIVE_STATUS_ENG.STREAMING) {
      interval = moment(EQFileToLive?.closeDate).diff(moment());
    }
    if (interval < 0) {
      interval = 60000;
    }
    if (interval > 0) {
      setStatusTimer(interval);
    }
  }, [EQFileToLive?.status]);

  useEffect(() => {
    if (!fileToLives.countLog || fileToLives.countLog.length <= 0) return;
    setFileToLiveClientsInfo({
      data: fileToLives.countLog,
      ...fileToLives.countState,
    });
  }, [fileToLives.countLog, fileToLives.countState]);

  // チャット開始制御
  useEffect(() => {
    // チャットの利用がない場合、擬似ライブ配信中以外、一度チャットを終了した場合はボタン非活性
    if (
      !EQFileToLive?.chat.enabled ||
      EQFileToLive?.chatRoomStatus !== FILE_TO_LIVE_CHAT_STATUS.NONE
    ) {
      setChatDisabled(true);
      return;
    }
    setChatDisabled(false);
  }, [
    EQFileToLive?.status,
    EQFileToLive?.chatRoomStatus,
    EQFileToLive?.chat.enabled,
  ]);

  // スナックバー要素削除
  const handleSnackbarClose = (index: number) => {
    const info = [...snackbarInfo];
    info.splice(index, 1);
    setSnackbarInfo(info);
  };

  // チャット処理前確認ダイアログ表示
  const openChatDialog = () => {
    setChatDialog(true);
  };

  // チャット処理前確認ダイアログOKボタン処理
  const handleOpenChatDialog = () => {
    setChatDialog(false);
    if (!EQFileToLive) return;
    if (EQFileToLive.chatRoomStatus === FILE_TO_LIVE_CHAT_STATUS.STREAMING) {
      // チャット停止処理
      dispatch(
        ChatManualStop({
          flpid: Number(flpid),
          cid,
          userApiParams: user.user,
          customerDir: user.contract.directory,
        })
      );
    } else {
      // チャット開始処理
      dispatch(
        ChatManualStart({
          flpid: Number(flpid),
          cid,
          userApiParams: user.user,
          customerDir: user.contract.directory,
        })
      );
    }
  };

  // チャット処理前確認ダイアログクローズ処理
  const handleCloseChatDialog = () => {
    setChatDialog(false);
  };

  // ライブ配信停止前のダイアログ表示
  const finishStream = () => {
    setLiveFinishDialog(true);
  };

  // ライブ配信停止前ダイアログクローズ処理
  const handleCloseLiveFinishDialog = () => {
    setLiveFinishDialog(false);
  };

  // ライブ配信停止処理
  const handleOKLiveFinishDialog = () => {
    setLiveFinishDialog(false);
    if (!EQFileToLive) return;
    dispatch(
      LiveStop({
        flpid: Number(flpid),
        cid,
        userApiParams: user.user,
      })
    );
  };

  // プレビューオープン処理
  const handleOKOpenPreview = async () => {
    setPreviewLid(false);
    setPreviewDialog(false);
  };

  // プレビューオープンダイアログオープン処理
  const handleOpenOpenPreview = () => {
    setPreviewDialog(true);
  };

  // プレビューオープンダイアログクローズ処理
  const handleCloseOpenPreview = () => {
    setPreviewDialog(false);
  };

  // 視聴者数遷移の表示間隔変更処理
  const handleInterval = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    value: GraphIntervals
  ) => {
    setInterval(value);
    // 視聴者数遷移の更新タイマー再起動
    if (timerRef.current) {
      clearCountTimer();
      setCountTimer(getInterval(value));
    }
  };

  // チャット管理コンソールのURL
  // ex: 'https://ssl-eq-stg.stream.co.jp/www50/chat/jmc_pub/v2/admin.html?flpid=Mw%3D%3D&userdir=eqy439mzmx'
  const chatConsoleURL = `${CHAT_CONSOLE_COMMOM}?flpid=${encodeURIComponent(
    Buffer.from(String(flpid)).toString('base64')
  )}&userdir=${user.contract.directory}`;
  // チャット管理コンソールのIframe
  const chatConsoleIframe = `<iframe src=${chatConsoleURL} width="100%" height="100%" allowFullScreen referrerPolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>`;

  return (
    <FileToLiveControllerComponent
      handleSnackbarClose={handleSnackbarClose}
      snackbarInfo={snackbarInfo}
      uiStatus={status.fileToLiveController}
      edition={edition}
      cid={cid}
      readAPIToken={readAPIToken}
      userApiParams={user.user}
      chatStatus={EQFileToLive?.chatRoomStatus || FILE_TO_LIVE_CHAT_STATUS.NONE}
      aleartLiveFinishDialog={{
        open: liveFinishDialog,
        handleOK: handleOKLiveFinishDialog,
        handleClose: handleCloseLiveFinishDialog,
        withChat:
          Boolean(EQFileToLive?.chat?.enabled) &&
          EQFileToLive?.chat?.closeTiming === CHAT_END_TIMING.LIVE_AUTO,
      }}
      ChatDialog={{
        open: chatDialog,
        handleOK: handleOpenChatDialog,
        handleClose: handleCloseChatDialog,
      }}
      previewLid={previewLid}
      openPreviewDialog={{
        open: previewDialog,
        handleOK: handleOKOpenPreview,
        handleClose: handleCloseOpenPreview,
        handleOpen: handleOpenOpenPreview,
      }}
      finishStream={() => finishStream()}
      openChatDialog={() => openChatDialog()}
      choiceInterval={handleInterval}
      liveStatus={ToFileToLiveStatusJap(
        EQFileToLive?.status || FILE_TO_LIVE_STATUS_ENG.NONE
      )}
      flpid={Number(flpid)}
      playerRef={playerRef}
      liveEventInfoName={EQFileToLive?.liveDescription || ''}
      liveName={EQFileToLive?.liveName || ''}
      chatStartBtnDisabled={
        chatDisabled ||
        EQFileToLive?.chat?.openTiming !== CHAT_START_TIMING.MANUAL
      }
      chatStopBtnDisabled={
        EQFileToLive?.chat?.closeTiming !== CHAT_END_TIMING.MANUAL
      }
      liveClientsInfo={fileToLiveClientsInfo}
      graphInterval={interval}
      chatenable={!!EQFileToLive?.chat.enabled}
      chatConsoleIframe={chatConsoleIframe}
    />
  );
};

export default FileToLiveControllerContainer;
