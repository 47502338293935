import { createTheme } from '@material-ui/core/styles';

/** Material-UI DefaultTheme */
const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans CJK JP',
      'Noto Sans JP',
      'Inter',
      'Roboto Mono',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#61B227',
      dark: '#407916',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        overflow: 'hidden',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        top: '-4px',
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: '#C32733',
        },
      },
    },
    MuiButton: {
      root: {
        color: '#333333',
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
    MuiTableCell: {
      body: {
        color: '#333333',
      },
    },
  },
});

export default theme;
