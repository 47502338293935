import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAppSelector } from '../app/hooks';
import { RootState, AppDispatch } from '../app/store';
import HistoryConnectionGraphComponent from '../components/HistoryConnectionGraph/HistoryConnectionGraphComponent';
import { HistoryConnectionGraphProps } from '../props/HistoryConnectionGraph';
import { GraphIntervals } from '../utils/Const';
import { ViewerLog } from '../types/Utils';
import { selectEQFileToLivesById } from '../features/fileToLivesSlice';
import { getCount } from '../utils/ThunkMethod/FileToLivesGetCount';
import { downloadFileToLiveClientsLogCSVFlow } from './FileToLiveList/CsvDownload';

/** 時間フォーマット */
const FORMAT_DATE = 'YYYY/MM/DD';
const FORMAT_TIME = 'HH:mm:ss';
const URL_FORMAT = 'YYYYMMDDHHmmss';

const getInterval = (interval: GraphIntervals) => {
  switch (interval) {
    case GraphIntervals.THIRTY_SECOND:
      return 30;
    case GraphIntervals.MINUTES:
      return 60;
    case GraphIntervals.TWO_MINUTES:
      return 120;
    case GraphIntervals.THREE_MINUTES:
      return 180;
    case GraphIntervals.FIVE_MINUTES:
      return 300;
    case GraphIntervals.TEN_MINUTES:
      return 600;
    default:
      return 30;
  }
};

type propType = {
  open: boolean;
  flpid: number;
  onClose: () => void;
};

/** グラフ表示 */
const FileToLiveHistoryConnectionGraphContainer = ({
  open,
  flpid,
  onClose,
}: propType): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const fileToLiveCountLog = useSelector(
    (state: RootState) => state.fileToLives.countLog
  );
  const EQFileToLive = useAppSelector((state: RootState) =>
    selectEQFileToLivesById(state, flpid)
  );
  // 変数抽出
  const { cid } = user.contract;

  // State
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [interval, setInterval] = useState<GraphIntervals>(
    GraphIntervals.THIRTY_SECOND
  );
  const [clientsLog, setClientLog] = useState<ViewerLog[]>([]);

  // 日付適用
  const handleDate = (
    from?: MaterialUiPickersDate,
    to?: MaterialUiPickersDate
  ) => {
    // 日時だけ更新
    setFromDate(moment(from).format());
    setToDate(moment(to).format());
    // 同時接続数を取得
    dispatch(
      getCount({
        cid: Number(cid),
        flpid,
        timeInterval: getInterval(interval),
        from: Number(moment(from).format(URL_FORMAT)),
        to: Number(moment(to).format(URL_FORMAT)),
      })
    );
  };

  // 間隔適用
  const handleInterval = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: GraphIntervals
  ) => {
    // 表示間隔だけ更新
    setInterval(value);

    // 同時接続数を取得
    dispatch(
      getCount({
        cid: Number(cid),
        flpid,
        timeInterval: getInterval(value),
        from: Number(moment(fromDate).format(URL_FORMAT)),
        to: Number(moment(toDate).format(URL_FORMAT)),
      })
    );
  };

  // 同時接続数をCSV出力
  const downloadClientsLogCSV = () => {
    dispatch(
      downloadFileToLiveClientsLogCSVFlow({
        cid,
        flpid,
        timeInterval: getInterval(interval),
        from: Number(moment(fromDate).format(URL_FORMAT)),
        to: Number(moment(toDate).format(URL_FORMAT)),
      })
    );
  };

  // グラフモーダル開閉時処理
  useEffect(() => {
    if (open) {
      setFromDate(EQFileToLive?.startDate || '');
      setToDate(EQFileToLive?.closeDate || '');
      // NOTE: 初回描画時は表示間隔は30秒固定
      dispatch(
        getCount({
          cid: Number(cid),
          flpid,
          timeInterval: 30,
          from: !EQFileToLive?.startDate
            ? undefined
            : Number(moment(EQFileToLive?.startDate).format(URL_FORMAT)),
          to: !EQFileToLive?.closeDate
            ? undefined
            : Number(moment(EQFileToLive?.closeDate).format(URL_FORMAT)),
        })
      );
    } else {
      // モーダル閉じると値をリセットする
      setClientLog([]);
      setFromDate('');
      setToDate('');
      setInterval(GraphIntervals.THIRTY_SECOND);
    }
  }, [open]);

  // getCountのコールでグラフを更新
  useEffect(() => {
    if (!fileToLiveCountLog || fileToLiveCountLog.length <= 0) {
      setClientLog([]);
      return;
    }
    setClientLog(fileToLiveCountLog);
  }, [fileToLiveCountLog]);

  const props: HistoryConnectionGraphProps = {
    msg: '通知メッセージ',
    updateSpan: handleDate,
    downloadClientsLogCSV,
    choiceInterval: handleInterval,
    fromDate: {
      date: moment(fromDate).format(FORMAT_DATE),
      time: moment(fromDate).format(FORMAT_TIME),
    },
    toDate: {
      date: moment(toDate).format(FORMAT_DATE),
      time: moment(toDate).format(FORMAT_TIME),
    },
    graphInterval: interval,
    clientsLog,
  };

  return (
    <HistoryConnectionGraphComponent
      open={open}
      onClose={onClose}
      props={props}
    />
  );
};

export default FileToLiveHistoryConnectionGraphContainer;
