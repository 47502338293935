import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { GetStreamQuality, GetContract } from '../../types/API';
import { setError, setLiveMonitorStatus } from '../../features/uiSlice';
import { setStreamQuality } from '../../features/livesSlice';
import { setContract } from '../../features/userSlice';
import { LIVEMONITER_FLOW_ERR_MSG } from '../../utils/Message';
import { UI_STATUS } from '../../utils/Const';

export type GetLiveMoniterFlowParams = {
  cid: number;
  userApiParams: string;
  lpid: string;
};
/** ライブモニタリング画面表示フロー */
export const getLiveMoniterFlow =
  (arg: GetLiveMoniterFlowParams): AppThunk =>
  async (dispatch) => {
    try {
      // 画面表示中
      dispatch(setLiveMonitorStatus(UI_STATUS.PROCESSING));

      // 契約会社情報取得API実行
      const responseGetContract: AxiosResponse<GetContract> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setContract({ ...responseGetContract.data }));

      // ライブ配信画質帯情報取得API実行
      const responseStreamQuality: AxiosResponse<GetStreamQuality> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}getStreamQuality`,
          {
            cid: arg.cid,
            lpid: arg.lpid,
            userApiParams: arg.userApiParams,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      dispatch(setStreamQuality({ ...responseStreamQuality.data }));

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVEMONITER_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveMonitorStatus(UI_STATUS.COMPLETED));
    }
  };
