import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  removeEQFileToLiveProfile,
  setEQFileToLiveProfile,
  setFaildDeleateFileToLiveId,
  setFliveSum,
} from '../../features/fileToLivesSlice';

import { setError } from '../../features/uiSlice';
import {
  DeleteFileToLiveProfile,
  EQFileToLiveProfile,
  GetFileToLiveProfile,
} from '../../types/API';
import { MAX_PAGESIZE } from '../../utils/Const';
import { FILETOLIVESDELETE_FLOW_ERR_MSG } from '../../utils/Message';

export type DeleteLivesFlowParams = {
  cid: number;
  userApiParams: string;
  readAPIToken: string;
  deleteflpids: number[];
  callback: React.Dispatch<React.SetStateAction<number[]>>;
};
/** 疑似ライブプロファイル削除フロー */
export const deleteFileToLivesFlow =
  (arg: DeleteLivesFlowParams): AppThunk =>
  async (dispatch, getState) => {
    try {
      // 即時で画面から削除
      dispatch(removeEQFileToLiveProfile(arg.deleteflpids));

      // 即時で画面の登録件数を更新
      const nowLiveSum = getState().fileToLives.liveSum;
      dispatch(setFliveSum(nowLiveSum - arg.deleteflpids.length));

      /** 削除に失敗したライブIDリスト */
      const falidDeleteLpids: number[] = [];

      // 疑似ライブプロファイル削除API実行
      const responseDeleteFileToLiveProfile: AxiosResponse<DeleteFileToLiveProfile> =
        await axios.delete(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/delProfile`,
          {
            params: {
              c: arg.cid,
              t: arg.readAPIToken,
              fll: arg.deleteflpids.join(','),
            },
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      // 疑似ライブプロファイル削除APIで失敗したものを削除に失敗したライブIDリストに追加
      falidDeleteLpids.push(...responseDeleteFileToLiveProfile.data.failed);

      /** 疑似ライブプロファイル削除APIで削除に失敗したライブIDを除いた削除されたライブID群 */
      const deletedLpids =
        falidDeleteLpids.length === 0
          ? arg.deleteflpids.slice()
          : arg.deleteflpids.filter(
              (id) => falidDeleteLpids.indexOf(id) === -1
            );

      // 削除に失敗したものをstateに格納
      dispatch(setFaildDeleateFileToLiveId({ failed: falidDeleteLpids }));
      // 成功したリストを渡す
      arg.callback(deletedLpids);

      // 全件削除に成功した（失敗したものがない）場合、終了
      if (falidDeleteLpids.length === 0) {
        return true;
      }

      // 疑似ライブプロファイル取得API実行
      const response: AxiosResponse<GetFileToLiveProfile> = await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getProfile`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          pageSize: MAX_PAGESIZE,
          pageNumber: 1,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      let liveProfilesGetted: EQFileToLiveProfile[] = response.data.profile;
      // 100件以上のプロファイルがある場合に全件取得
      const loopTimes = [
        ...Array(Math.ceil(response.data.livesum / MAX_PAGESIZE) - 1),
      ].map((x, i) => i + 2);
      await Promise.all(
        loopTimes.map(async (pageNumber) => {
          // 疑似ライブプロファイル取得API実行
          const response: AxiosResponse<GetFileToLiveProfile> =
            await axios.post(
              `${String(
                process.env.REACT_APP_BACKEND_API_ENDPOINT
              )}filetolive/getProfile`,
              {
                cid: arg.cid,
                userApiParams: arg.userApiParams,
                pageSize: MAX_PAGESIZE,
                pageNumber,
              },
              {
                headers: {
                  accept: 'application/json',
                  'Content-type': 'application/json',
                },
              }
            );
          liveProfilesGetted = liveProfilesGetted.concat(response.data.profile);
        })
      );

      dispatch(
        setEQFileToLiveProfile({
          livesum: liveProfilesGetted.length,
          profile: liveProfilesGetted,
        })
      );

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: FILETOLIVESDELETE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    }
  };
