import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../app/store';
import {
  selectAllEQLives,
  setFaildDeleateLiveId,
} from '../../features/livesSlice';
import {
  setLiveContentStatus,
  setLiveRegisterStatus,
} from '../../features/uiSlice';
import { getPath, PORTAL_STATUS, ROUTE, UI_STATUS } from '../../utils/Const';
import LiveListComponent from '../../components/LiveList/LiveListComponent';
import { useAppSelector } from '../../app/hooks';
import { ToLiveStatusJap } from '../../utils/ChangeStatus';
import { deleteLivesFlow } from './DeleteLives';
import { getLiveListFlow } from './GetLiveList';
import { TableLiveProfiles } from '../../props/LiveList';
import formatDate from '../../utils/FormatDate';

/** ライブの配信設定と開催 */
const LiveListContainer = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  // Selector
  const user = useSelector((state: RootState) => state.user);
  const livesbase = useSelector((state: RootState) => state.lives);
  const EQLives = useAppSelector(selectAllEQLives);

  // 変数抽出
  const { edition, cid, readAPIToken, serviceFlg25, liveInfo } = user.contract;
  const { cntLive, maxClient, nowCnt, nowClientCnt } = liveInfo;
  const { liveSum, faildDeleateLiveId } = livesbase;

  // state
  /** チェックされたライブID */
  const [checkedLpids, setCheckedLpids] = useState<number[]>([]);
  /** 削除されたライブID */
  const [succeedDeleteLpids, setSucceedDeleteLpids] = useState<number[]>([]);
  /** テーブルで使用するプロファイルデータ */
  const [tableLiveProfile, setTableLiveProfile] = useState<TableLiveProfiles[]>(
    []
  );

  // イベント
  /** ライブの新規登録へ遷移 */
  const goLiveRegister = () => {
    history.replace({
      pathname: getPath(ROUTE.LIVE_REGISTER),
      search: `?c=${user.contract.cid}&t=${user.contract.readAPIToken}&u=${user.user}`,
    });
  };

  /** 削除イベント */
  const deleteLiveProfileEvent = (ids: number[]) => {
    // 全チェックを外す
    setCheckedLpids([]);

    // 削除フロー実行
    dispatch(
      deleteLivesFlow({
        cid,
        userApiParams: user.user,
        readAPIToken,
        deletelpids: ids,
        callback: setSucceedDeleteLpids,
      })
    );
  };

  /** 削除失敗ダイアログクローズイベント */
  const closeFaildDellpidDialog = () => {
    setSucceedDeleteLpids([]);
    dispatch(setFaildDeleateLiveId({ failed: [] }));
  };

  // 初期処理
  useEffect(() => {
    // 新規登録と詳細のステータスを初期化
    dispatch(setLiveRegisterStatus(UI_STATUS.PROCESSING));
    dispatch(setLiveContentStatus(UI_STATUS.PROCESSING));
    dispatch(getLiveListFlow({ cid, userApiParams: user.user }));
  }, []);

  // Redux StateのEQライブプロファイルの更新で駆動
  useEffect(() => {
    // ComponentへのPropsの生成
    const liveProfiles = EQLives.map((profile) => ({
      lpid: profile.lpid,
      haveWlivesId: Boolean(profile.jcloudWlives.liveId),
      liveName: profile.liveName,
      maxClient: profile.maxClient,
      liveStatus: ToLiveStatusJap(profile.status),
      dvrCloseDate: formatDate(profile.dvrCloseDate) || '',
      chatStatus: profile.chat.status,
      portalStatus: profile.portalStatus ?? PORTAL_STATUS.NONE,
      liveAppFlg: Boolean(profile.liveappFlg),
      desktopEncoder: Boolean(profile.desktopEncoder),
      liveShareFlg: !(profile.liveappFlg || profile.desktopEncoder),
      updatedAutoQualityFlg: profile.updatedAutoQualityFlg,
    }));
    setTableLiveProfile(liveProfiles);
  }, [EQLives]);

  return (
    <LiveListComponent
      msg="通知用メッセージ"
      deleteLiveProfile={deleteLiveProfileEvent}
      closeFaildDellpidDialog={closeFaildDellpidDialog}
      goLiveRegister={goLiveRegister}
      checkedLpids={checkedLpids}
      setCheckedLpids={setCheckedLpids}
      faildDellpid={faildDeleateLiveId}
      succeedDellpid={succeedDeleteLpids}
      edition={edition}
      cid={cid}
      readAPIToken={readAPIToken}
      userApiParams={user.user}
      maxLive={cntLive}
      maxContractClient={maxClient}
      automode={Boolean(serviceFlg25)}
      nowCnt={nowCnt}
      nowClientCnt={nowClientCnt}
      liveSum={liveSum}
      liveProfiles={tableLiveProfile}
    />
  );
};

export default LiveListContainer;
