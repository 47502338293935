import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { LiveShareProps } from '../../props/LiveShare';
import {
  DEFAULT_CHATSIZE,
  LIVESAHRE_TABS,
  CHATWIDTH_MAXMIN,
  CHATHEIGHT_MAXMIN,
} from '../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  input: {
    background: '#FFFFFF',
    marginRight: 4,
    '& .MuiOutlinedInput-input': {
      padding: '6px 10px',
      lineHeight: '28px',
      width: 60,
    },
  },
  attention: {
    display: 'flex',
    color: '#C32733',
  },
  root: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '12px 22px 16px',
    color: '#333333',
    '& .MuiFormControlLabel-label': {
      lineHeight: '32px',
      fontSize: 14,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
    },
  },
  icon: {
    color: '#C32733',
    marginRight: 4,
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: '#C32733',
  },
});

/** チャット埋め込みサイズ */
const ChatEmbeddedSize = ({
  props,
  selectTab,
}: {
  props: LiveShareProps;
  selectTab: LIVESAHRE_TABS;
}): JSX.Element => {
  const classes = useStyle();
  const {
    autoResize,
    chatMatchPlayer,
    chatWidth,
    chatHeight,
    choiceChatMatchPlayer,
    inputChatSize,
    blurChatSize,
    inputChatSizeRequireError,
    setInputChatSizeRequireError,
    inputChatSizeError,
    setInputChatSizeError,
  } = props;
  const onChangeChatDefaultSize = (value: string) => {
    if (value === 'false') {
      setInputChatSizeRequireError({
        width: !DEFAULT_CHATSIZE.WIDTH,
        height: !DEFAULT_CHATSIZE.HEIGHT,
      });
    }
    choiceChatMatchPlayer(value);
  };
  const onBlurChatSize = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(event.target.value);
    if (event.target.name === 'width') {
      setInputChatSizeError({
        ...inputChatSizeError,
        width: Boolean(
          value < CHATWIDTH_MAXMIN.MIN || value > CHATWIDTH_MAXMIN.MAX
        ),
      });
      setInputChatSizeRequireError({
        ...inputChatSizeRequireError,
        width: !value,
      });
      blurChatSize.width(value);
    } else if (event.target.name === 'height') {
      setInputChatSizeError({
        ...inputChatSizeError,
        height: Boolean(
          value < CHATHEIGHT_MAXMIN.MIN || value > CHATHEIGHT_MAXMIN.MAX
        ),
      });
      setInputChatSizeRequireError({
        ...inputChatSizeRequireError,
        height: !value,
      });
      blurChatSize.height(value);
    }
  };
  return (
    <>
      <h4>チャット埋め込みサイズ</h4>
      {autoResize && selectTab === LIVESAHRE_TABS.CHAT_TAG && (
        <Box className={classes.attention}>
          <InfoOutlined fontSize="small" style={{ marginRight: 6 }} />
          <span style={{ marginTop: 4 }}>
            チャットの自動リサイズ（レスポンシブデザイン）が有効です。
          </span>
        </Box>
      )}
      <Box className={classes.root}>
        <RadioGroup
          value={chatMatchPlayer}
          onChange={(e, value) => onChangeChatDefaultSize(value)}
        >
          {selectTab !== LIVESAHRE_TABS.CHAT_TAG && (
            <FormControlLabel
              value
              control={<Radio color="primary" />}
              label="プレイヤーに合わせる"
            />
          )}
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={
              <>
                指定のサイズ&emsp;表示サイズ：
                <TextField
                  variant="outlined"
                  value={chatWidth}
                  className={classes.input}
                  onBlur={onBlurChatSize}
                  onChange={(event) =>
                    inputChatSize.width(parseInt(event.target.value, 10))
                  }
                  name="width"
                  error={
                    inputChatSizeError.width || inputChatSizeRequireError.width
                  }
                  InputProps={{ inputProps: { maxLength: 4 } }}
                />
                &ensp;×&ensp;
                <TextField
                  variant="outlined"
                  value={chatHeight}
                  className={classes.input}
                  onBlur={onBlurChatSize}
                  onChange={(event) =>
                    inputChatSize.height(parseInt(event.target.value, 10))
                  }
                  name="height"
                  error={
                    inputChatSizeError.height ||
                    inputChatSizeRequireError.height
                  }
                  InputProps={{ inputProps: { maxLength: 4 } }}
                />
                {inputChatSizeError.width && (
                  <Box className={classes.error}>
                    <InfoOutlined fontSize="small" className={classes.icon} />
                    チャット表示サイズの幅は300から1920までの値を入力してください
                  </Box>
                )}
                {inputChatSizeError.height && (
                  <Box className={classes.error}>
                    <InfoOutlined fontSize="small" className={classes.icon} />
                    チャット表示サイズの高さは320から1080までの値を入力してください
                  </Box>
                )}
              </>
            }
          />
        </RadioGroup>
      </Box>
    </>
  );
};

export default ChatEmbeddedSize;
