import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { AppDispatch, RootState } from '../../app/store';
import LiveEncoderComponent from '../../components/LiveEncoder/LiveEncoderComponent';
import { LiveEncoderProps } from '../../props/LiveEncoder';
import { selectEQLivesById } from '../../features/livesSlice';
import { ToStreamsJap } from '../../utils/ChangeStatus';
import { ENCODERS, STREMAS_ENG } from '../../utils/Const';
import getLiveEncoder from './GetLiveEncoder';

type propType = {
  open: boolean;
  lpid: number;
  onClose: () => void;
};

const downloadProfile = (
  cid: number,
  lpid: number,
  encoder: ENCODERS,
  text: string
) => {
  const element = document.createElement('a');
  const file = new Blob([text.replace(/\\n/g, '\r\n')], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${cid}_${lpid}_${encoder}.txt`;
  document.body.appendChild(element);
  element.click();
};

/** ライブエンコーダー設定 */
const LiveEncoderContainer = ({
  open,
  lpid,
  onClose,
}: propType): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);
  const EQlive = useAppSelector((state: RootState) =>
    selectEQLivesById(state, lpid)
  );
  const encoderSetting = useAppSelector(
    (state: RootState) => state.lives.encoderSetting
  );

  // ABR設定されている最上位の画質帯情報
  const imageQuality = Object.entries(encoderSetting ?? {})
    .filter((quality) => !(quality[0] === STREMAS_ENG.ORIGIN))
    .find(([, value]) => 'activateFlg' in value && value.activateFlg) ?? [
    STREMAS_ENG.ORIGIN,
    { ...encoderSetting.original, bitrate: null },
  ];

  // 初期処理
  useEffect(() => {
    dispatch(
      getLiveEncoder({
        cid: user.contract.cid,
        userApiParams: user.user,
        lpid,
      })
    );
  }, [EQlive?.jcloudWlives]);

  // ボタン押下で各種ダウンロードされるtxtの内容を整形し、ダウンロード実行
  const downloadLiveShellProfile = () => {
    downloadProfile(
      user.contract.cid,
      lpid,
      ENCODERS.LIVESHELL,
      encoderSetting.encoderProfile?.liveshell
    );
  };
  const downloadWireCastProfile = () => {
    downloadProfile(
      user.contract.cid,
      lpid,
      ENCODERS.WIRECAST,
      encoderSetting.encoderProfile?.wirecast
    );
  };
  const downloadLiveUSoloProfile = () => {
    downloadProfile(
      user.contract.cid,
      lpid,
      ENCODERS.LIVEUSOLO,
      encoderSetting.encoderProfile?.liveusolo
    );
  };

  // propsの整形
  const props: LiveEncoderProps = {
    msg: '通知メッセージ',
    downloadLiveShellProfile,
    downloadWireCastProfile,
    downloadLiveUSoloProfile,
    lpid,
    liveName: EQlive?.liveName ?? '',
    imageQuality: ToStreamsJap(imageQuality[0]),
    bitrate: 'bitrate' in imageQuality[1] ? imageQuality[1].bitrate ?? 0 : 0,
  };

  return <LiveEncoderComponent open={open} props={props} onClose={onClose} />;
};

export default LiveEncoderContainer;
