import React, { useEffect } from 'react';
import {
  UseFormRegister,
  FieldValues,
  Control,
  UseFormWatch,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import FormRadioButton from '../parts/FormRadioButton/FormRadioButton';
import { FileToLiveContentProps } from '../../props/FileToLiveContent';
import { FILE_TO_LIVE_FILLER_ID, UI_STATUS } from '../../utils/Const';
import FormSearchTextBox from '../parts/FormSearchTextBox/FormSearchTextBox';
import {
  FILE_TO_LIVE_UNSELECTED_MID_MSG,
  FILE_SEARCH_MID_TEXT_STRING_MSG,
} from '../../utils/Message';
import { EQFileToLiveMovie } from '../../types/API';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& .MuiGrid-container': {
      marginTop: 0,
      marginBottom: '-16px',
    },
    '& .MuiGrid-spacing-xs-8 > .MuiGrid-item': {
      padding: '16px 30px',
      paddingRight: 2,
    },
    '& .MuiGrid-grid-xs-3': {
      lineHeight: '40px',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 30,
    marginBottom: 14,
    fontSize: 15,
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '20px 22px',
    color: '#333333',
  },
  whiteInner: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    marginTop: 14,
    padding: '16px 22px',
    '& p': {
      marginBottom: 20,
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    color: '#C32733',
    fontSize: 13,
  },
  midAttention: {
    background: '#C3273305',
    border: '1px solid #C32733',
    borderRadius: 4,
    color: '#C32733',
    padding: 10,
    marginBottom: 15,
  },
  midDiscription: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
  },
  redIcon: {
    color: '#C32733',
    marginRight: 4,
    paddingTop: 3,
  },
});

/** フィラー選択フォーム */
const FillerForm = ({
  register,
  control,
  errors,
  watch,
  clearErrors,
  setValue,
  ContentProps,
  getFillerList,
  mid,
  type,
  movies,
  status,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  errors?: Record<string, { message: string }>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  ContentProps?: FileToLiveContentProps;
  getFillerList: (
    type: 'pre' | 'post',
    mid: string,
    searchFillerMid: string
  ) => void;
  mid: string;
  type: 'pre' | 'post';
  movies: EQFileToLiveMovie[];
  status: UI_STATUS;
}): JSX.Element => {
  const classes = useStyle();
  const disableFlg = ContentProps?.disableFlg || false;
  const contentSetting = ContentProps?.fillerSetting;
  const isPre = type === 'pre';
  const serchFormName = isPre ? 'SearchPrefillerName' : 'SearchPostfillerName';
  const fillerSettingFillerUseDefault = isPre
    ? 'FillerSettingPrefillerUseDefault'
    : 'FillerSettingPostfillerUseDefault';
  const fillerSettingSelectFillerMid = isPre
    ? 'FillerSettingSelectPrefillerMid'
    : 'FillerSettingSelectPostfillerMid';
  const useSystemDefault =
    watch(fillerSettingFillerUseDefault) ===
    String(FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT);

  /**
   * 任意動画の初期Midを取得
   * フォームの値(エラー時の選択値)>DBの値>未選択の優先順位
   * @returns [string] mid
   */
  const getDefaultMid = () => {
    // フォーム入力値がある場合は、フォームの値を優先する
    if (watch(fillerSettingSelectFillerMid) !== undefined) {
      return watch(fillerSettingSelectFillerMid);
    }
    return isPre
      ? contentSetting?.prefillerMid || FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT
      : contentSetting?.postfillerMid || FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT;
  };
  const selectedMid = getDefaultMid();

  const handleMidClick = (
    event: React.MouseEvent<unknown>,
    clickedMid: number
  ) => {
    const row = movies.find((val) => val.mid === clickedMid);
    if (!row) {
      setValue(serchFormName, '');
      return;
    }
    setValue(serchFormName, `${row.mid}：${row.title}`);
  };
  const setFillerList = () => {
    const name = watch(serchFormName);
    const row = movies.find((val) => `${val.mid}：${val.title}` === name);
    if (!row) {
      setValue(fillerSettingSelectFillerMid, null);
      const result = /\s+/.test(name);
      const resultNum = /\d+/.test(name);
      if ((name === '' || (!result && resultNum)) && mid) {
        getFillerList(type, mid, name);
      }
    }
    if (row) {
      setValue(fillerSettingSelectFillerMid, row.mid);
      clearErrors(serchFormName);
    }
  };
  useEffect(() => {
    if (selectedMid) {
      const row = movies.find((val) => val.mid === selectedMid);
      if (row) {
        setValue(serchFormName, `${row.mid}：${row.title}`);
      }
      return;
    }
    setValue(serchFormName, '');
  }, [selectedMid]);
  useEffect(() => {
    if (useSystemDefault) return;
    setFillerList();
  }, [useSystemDefault, watch(serchFormName)]);
  useEffect(() => {
    if (
      useSystemDefault ||
      selectedMid !== FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT
    )
      return;
    setValue(
      fillerSettingFillerUseDefault,
      String(FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT)
    );
    setValue(serchFormName, '');
    setValue(fillerSettingSelectFillerMid, '');
  }, [mid]);

  return (
    <>
      <FormRadioButton
        name={fillerSettingFillerUseDefault}
        control={control}
        disabled={disableFlg}
        defaultValue={
          selectedMid === FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT
            ? FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT
            : FILE_TO_LIVE_FILLER_ID.SELECT_MID
        }
        list={[
          {
            label: 'システムデフォルトのフィラー映像を利用する',
            value: FILE_TO_LIVE_FILLER_ID.SYSTEM_DEFAULT,
          },
          {
            label: '任意の動画を指定する',
            value: FILE_TO_LIVE_FILLER_ID.SELECT_MID,
          },
        ]}
      />
      <Box className={classes.whiteInner}>
        <FormSearchTextBox
          name={serchFormName}
          width="fullWidth"
          register={register}
          setValue={setValue}
          validation={{
            validate: {
              selected: (data) => {
                if (
                  !(
                    !disableFlg &&
                    (useSystemDefault || watch(fillerSettingSelectFillerMid))
                  ) &&
                  /\s+/.test(data)
                ) {
                  return FILE_SEARCH_MID_TEXT_STRING_MSG;
                }
                if (
                  !(
                    !disableFlg &&
                    (useSystemDefault || watch(fillerSettingSelectFillerMid))
                  ) &&
                  !/\d+/.test(data)
                ) {
                  return FILE_SEARCH_MID_TEXT_STRING_MSG;
                }
                if (
                  !(
                    !disableFlg &&
                    (useSystemDefault || watch(fillerSettingSelectFillerMid))
                  )
                ) {
                  return FILE_TO_LIVE_UNSELECTED_MID_MSG;
                }
                return true;
              },
            },
          }}
          maxLength={100}
          validationError={
            !disableFlg && !useSystemDefault && Boolean(errors?.[serchFormName])
          }
          validationErrorMsg={
            !disableFlg && !useSystemDefault && errors?.[serchFormName]?.message
              ? String(errors?.[serchFormName]?.message)
              : ''
          }
          disabled={
            useSystemDefault || disableFlg || status !== UI_STATUS.COMPLETED
          }
          className={
            !disableFlg && !watch(fillerSettingSelectFillerMid) ? 'error' : ''
          }
        />
        {!disableFlg &&
          !useSystemDefault &&
          status === UI_STATUS.COMPLETED &&
          movies.length === 0 &&
          (mid ? (
            <Box className={classes.error}>利用可能な動画が存在しません。</Box>
          ) : (
            <Box className={classes.error}>
              基本設定で動画を選択してください。
            </Box>
          ))}
        {!useSystemDefault &&
          status === UI_STATUS.COMPLETED &&
          !watch(fillerSettingSelectFillerMid) && (
            <TableContainer component={Paper} style={{ maxHeight: 312 }}>
              <Table>
                <TableBody>
                  {movies.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.mid}
                      >
                        <TableCell
                          key={row.mid}
                          align="left"
                          onClick={(event) => handleMidClick(event, row.mid)}
                        >
                          {row.mid}：{row.title}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </Box>
    </>
  );
};

/** フィラー映像設定 */
const FillerSetting = ({
  register,
  control,
  errors,
  watch,
  clearErrors,
  setValue,
  ContentProps,
  open = false,
  getFillerList,
  mid,
  prefillerMovies,
  prefillerStatus,
  postfillerMovies,
  postfillerStatus,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  errors?: Record<string, { message: string }>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  ContentProps?: FileToLiveContentProps;
  open?: boolean;
  getFillerList: (
    type: 'pre' | 'post',
    mid: string,
    searchFillerMid: string
  ) => void;
  mid: string;
  prefillerMovies: EQFileToLiveMovie[];
  prefillerStatus: UI_STATUS;
  postfillerMovies: EQFileToLiveMovie[];
  postfillerStatus: UI_STATUS;
}): JSX.Element => {
  const classes = useStyle();

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>フィラー映像の設定を行います。</Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          開始前のフィラー映像
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FillerForm
              control={control}
              register={register}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              setValue={setValue}
              ContentProps={ContentProps}
              getFillerList={getFillerList}
              mid={mid}
              type="pre"
              movies={prefillerMovies}
              status={prefillerStatus}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          終了後のフィラー映像
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.greenBack}>
            <FillerForm
              control={control}
              register={register}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              setValue={setValue}
              ContentProps={ContentProps}
              getFillerList={getFillerList}
              mid={mid}
              type="post"
              movies={postfillerMovies}
              status={postfillerStatus}
            />
          </Box>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <Box className={classes.midAttention}>
            <Box className={classes.midDiscription}>
              <InfoOutlined fontSize="small" className={classes.redIcon} />
              フィラー映像として利用可能な動画は、以下の3つの条件を「すべて」満たすものに限られます。
            </Box>
            <Box className={classes.midDiscription}>
              ・本編動画と同じトランスコード設定で生成され、同じ種類の画質がある動画
            </Box>
            <Box className={classes.midDiscription}>
              ・かつ、動画尺が「4分以上－5分以下」の長さの動画
            </Box>
            <Box className={classes.midDiscription}>
              ・かつ、エラーなどが発生していない状態の動画
            </Box>
            <Box className={classes.midDiscription}>
              上記条件を満たさない動画は選択肢に表示されてきませんのでご注意ください。
            </Box>
            <br />
            <Box className={classes.midDiscription}>
              <InfoOutlined fontSize="small" className={classes.redIcon} />
              フィラー映像の注意点
            </Box>
            <Box className={classes.midDiscription}>
              ・開始前フィラー映像の冒頭部分は、ユーザが視聴できない部分が発生します。あらかじめご了承ください。
            </Box>
            <Box className={classes.midDiscription}>
              ・開始前フィラー映像は、動画の終わりから3分程度しかユーザは視聴できません。
            </Box>
            <Box className={classes.midDiscription}>
              ・終了後フィラー映像の終了部分は、ユーザが視聴できない部分が発生します。あらかじめご了承ください。
            </Box>
            <Box className={classes.midDiscription}>
              ・終了後フィラー映像は、動画の始めから3分程度しかユーザは視聴できません。
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FillerSetting;
