/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  GetPlayerList,
  GetQRProfile,
  MakeShortUrl,
  SetPamalink,
  GetFileToLiveMovie,
} from '../../types/API';
import { setPlayers, setQR } from '../../features/userSlice';
import { FILETOLIVESHARE_FLOW_ERR_MSG } from '../../utils/Message';
import { setError, setLiveShareStatus } from '../../features/uiSlice';
import {
  setPamalink,
  setQRUrl,
  setShortQRUrl,
  setShortVideoUrl,
  setEQFileToLiveMovie,
} from '../../features/fileToLivesSlice';
import { UI_STATUS, ONOFF, STREMAS_NUM } from '../../utils/Const';
import makeQRUrl from '../../utils/MakeQRUrl';

type GetFileToLiveShareFlowParams = {
  cid: number;
  flpid: number;
  mid?: number;
  userApiParams: string;
  chatenable: boolean;
  chatAutoresizeFlg: 0 | 1;
  setPid: React.Dispatch<React.SetStateAction<number>>;
  setPpsid: React.Dispatch<React.SetStateAction<number>>;
  setVideoanalyzerCookie: React.Dispatch<React.SetStateAction<boolean>>;
  setResumeCookie: React.Dispatch<React.SetStateAction<boolean>>;
  defaultQualityPC?: number;
  defaultQualitySP?: number;
};

type getPlayerTagRequestParams = {
  cid: string;
  userApiParams: string;
  flpid: string;
  pid?: number;
  mid?: number;
  width?: number;
  height?: number;
  chat_autoresize_flg?: number;
  videoanalyzerCookie?: 0 | 1;
  resumeCookie?: 0 | 1;
  defaultQualityPc?: STREMAS_NUM;
  defaultQualitySp?: STREMAS_NUM;
};

/** ライブ共通タグ表示フロー */
const getFileToLiveShareFlow =
  (arg: GetFileToLiveShareFlowParams): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setLiveShareStatus(UI_STATUS.PROCESSING));

      const { user } = getState();

      // プレイヤー一覧取得API実行
      const responseGetPlayerList: AxiosResponse<GetPlayerList> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getPlayer`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
      if (responseGetPlayerList.data.errorCode !== 0) {
        // 異常終了
        // NOTE: 400系のクライアントサイドのエラーだが、エラー画面に遷移させないとチェックボックスの不具合が生じるため、例外的にエラー画面に遷移
        throw new Error(
          `${responseGetPlayerList.data.errorCode}: ${responseGetPlayerList.data.message}`
        );
      }

      dispatch(setPlayers({ ...responseGetPlayerList.data }));

      // 選択されているプレイヤーの情報を抽出
      const nowPlayer =
        responseGetPlayerList.data.players.find((profile) => {
          return profile.defaultFlg === 1;
        }) ??
        responseGetPlayerList.data.players[
          responseGetPlayerList.data.players.length - 2
        ];

      // QRコード生成情報取得API実行
      const responseGetQRProfile: AxiosResponse<GetQRProfile> =
        await axios.post(
          `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getQrcode`,
          {
            cid: arg.cid,
            userApiParams: arg.userApiParams,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      dispatch(setQR({ ...responseGetQRProfile.data }));

      // 疑似ライブ利用動画取得API実行
      const responseGetFileToLiveMovie: AxiosResponse<GetFileToLiveMovie> =
        await axios.post(
          `${String(
            process.env.REACT_APP_BACKEND_API_ENDPOINT
          )}filetolive/getMediaList`,
          {
            cid: user.contract.cid,
            userApiParams: user.user,
            mid: arg.mid,
            pageSize: 100,
            pageNumber: 1,
            qualityFlg: 1,
          },
          {
            headers: {
              accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

      const { movies } = responseGetFileToLiveMovie.data;
      dispatch(setEQFileToLiveMovie({ movies: movies || [] }));

      const updatePlayerTagParams: getPlayerTagRequestParams = {
        cid: String(arg.cid),
        flpid: String(arg.flpid),
        pid: nowPlayer?.pid,
        mid: arg.mid,
        userApiParams: arg.userApiParams,
        // NOTE: 画面初期表示がイブ設定の標準画質の動画サイズにするので標準画質の解像度に。
        width: user.contract.liveInfo.width2,
        height: user.contract.liveInfo.height2,
        chat_autoresize_flg: arg.chatAutoresizeFlg,
      };
      updatePlayerTagParams.videoanalyzerCookie =
        nowPlayer.videoanalyzerCookie === ONOFF.OFF ? 0 : 1;
      updatePlayerTagParams.resumeCookie =
        nowPlayer.resumeCookie === ONOFF.OFF ? 0 : 1;
      if (arg.defaultQualityPC !== STREMAS_NUM.UNSELECTED)
        updatePlayerTagParams.defaultQualityPc = arg.defaultQualityPC;
      if (arg.defaultQualitySP !== STREMAS_NUM.UNSELECTED)
        updatePlayerTagParams.defaultQualitySp = arg.defaultQualitySP;

      // プレイヤー／チャットパーマリンク作成API実行
      // NOTE: pid指定しない場合デフォルト使用のプレイヤーが返ってくる
      const responseSetPamalink: AxiosResponse<SetPamalink> = await axios.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getPlayerTag`,
        updatePlayerTagParams,
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      if (responseSetPamalink.data.errorCode !== 0) {
        // 異常終了
        // NOTE: 400系のクライアントサイドのエラーだが、エラー画面に遷移させないとチェックボックスの不具合が生じるため、例外的にエラー画面に遷移
        throw new Error(
          `${responseSetPamalink.data.errorCode}: ${responseSetPamalink.data.message}`
        );
      }

      dispatch(setPamalink({ ...responseSetPamalink.data }));
      const tagParams = {
        cid: String(arg.cid),
        flpid: String(arg.flpid),
        mid: String(arg.mid),
        type: 'new_file_to_live',
      };

      // 短縮動画URL生成API実行
      const responseShortVideo: AxiosResponse<MakeShortUrl> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}makeShortUrl`,
        {
          url: responseSetPamalink.data.selectedPlayer.permalink.tag,
          userApiParams: arg.userApiParams,
          tags: tagParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setShortVideoUrl({ ...responseShortVideo.data }));

      // 非短縮QRコードURL生成
      const QRUrl = makeQRUrl(
        arg.chatenable,
        responseSetPamalink.data.selectedPlayer.permalink.tag
      );

      dispatch(setQRUrl(QRUrl));

      // 短縮QRURL生成API実行
      const responseShortQR: AxiosResponse<MakeShortUrl> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}makeShortUrl`,
        {
          url: QRUrl,
          userApiParams: arg.userApiParams,
          tags: tagParams,
          qr: true,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setShortQRUrl({ ...responseShortQR.data }));

      // コンポーネントのstateにセット
      arg.setPid(responseSetPamalink.data.selectedPlayer.pid);
      arg.setVideoanalyzerCookie(
        nowPlayer?.videoanalyzerCookie === ONOFF.OFF ?? false
      );
      arg.setResumeCookie(nowPlayer?.resumeCookie === ONOFF.OFF ?? false);

      // 掲載サイト別管理の最後尾の設定を初期値に
      const bottomPpsid = responseSetPamalink.data.pubsitesList.length
        ? responseSetPamalink.data.pubsitesList.slice(-1)[0].ppsid
        : 0;
      arg.setPpsid(bottomPpsid);

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: FILETOLIVESHARE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveShareStatus(UI_STATUS.COMPLETED));
    }
  };

export default getFileToLiveShareFlow;
