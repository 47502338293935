import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const tableStyle = makeStyles({
  root: {
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableHeader: {
    background: '#333333',
    color: '#FFFFFF',
  },
  headerCell: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 700,
    borderRight: '1px solid #E0E0E04A',
    padding: '22px 25px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  bodyRow: {
    '&:nth-child(odd)': {
      background: '#0000000A',
    },
  },
  bodyCell: {
    fontSize: 14,
    borderRight: '1px solid #E0E0E0',
    padding: '22px 25px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

type tableProps = {
  header: { name: string; title: string | React.ReactNode; width?: number }[];
  body: { name: string; content: string | React.ReactNode }[][];
};

/** 白黒テーブル */
const CommonTable = ({ header, body }: tableProps): JSX.Element => {
  const classes = tableStyle();
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {header.map((cell) => (
              <TableCell
                key={cell.name}
                className={classes.headerCell}
                width={cell.width}
              >
                {cell.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map((row, index) => (
            <TableRow
              className={classes.bodyRow}
              key={`row-${row[index]?.name || index}`}
            >
              {row.map((cell) => (
                <TableCell className={classes.bodyCell} key={cell.name}>
                  {cell.content}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonTable;
