import { createServer, Response } from 'miragejs';
import Success from './MockResponses/Success';
import Contract from './MockResponses/Contract';
import { GetStreamQuality } from '../types/API';
import {
  LiveProfile,
  LiveProfileOne,
  SetLiveProfile,
} from './MockResponses/LiveProfile';
import {
  WlivesServer,
  HlsAuthSever,
  CreateSever,
} from './MockResponses/JcloudServer';
import LiveSetUpDataList from './MockResponses/LiveSetUpDataList';
import AutoQuality from './MockResponses/AutoQuality';
import MakeM3U8 from './MockResponses/MakeM3U8';
import PlayerProfile from './MockResponses/PlayerProfile';
import PlayerTag from './MockResponses/PlayerTag';
import QRProfile from './MockResponses/QRProfile';
import ShortUrl from './MockResponses/ShortUrl';
import HistoryProfile from './MockResponses/HistoryProfile';
import Count from './MockResponses/Count';
import ChatLog from './MockResponses/ChatLog';
import WowzaFeed from './MockResponses/WowzaFeed';
import LiveEvent from './MockResponses/LiveEvent';
import CountUpCntLive from './MockResponses/CountUpCntLive';
import AutoQualityHistory from './MockResponses/AutoQualityHistory';
import EncoderSetting from './MockResponses/EncoderSetting';
import {
  FileToLiveProfile,
  FileToLiveProfileOne,
  FileToLiveProfileSome,
  SetFileToLiveProfile,
} from './MockResponses/FileToLiveProfile';
import FileToLiveMovie from './MockResponses/FileToLiveMovie';
import FileToLiveFiller from './MockResponses/FileToLiveFiller';

/** API Mockサーバー */
const MockServer = () => {
  const server = createServer({
    routes() {
      // 認証API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}checkToken`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // 契約会社情報取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getContract`,
        () => {
          return new Response(200, {}, Contract);
        }
      );
      // ライブプロファイル取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          const { pageNumber, lpid, portal } = params;
          // リクエストにlpidを指定した場合
          if (lpid) {
            return new Response(
              200,
              {},
              LiveProfileOne(Number(lpid), Number(portal))
            );
          }
          // リクエストに何もない場合
          return new Response(
            200,
            {},
            LiveProfile(Number(pageNumber), Number(portal))
          );
        }
      );

      // JCloudサーバー設定情報取得API
      this.get(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getJcloudServer/:id`,
        (schema, request) => {
          // NOTE: request.paramsで動的パスidを取得可能
          const { type } = request.queryParams;
          if (type === 'wlives') {
            return new Response(200, {}, WlivesServer);
          }
          if (type === 'hls-auth') {
            return new Response(200, {}, HlsAuthSever);
          }
          return new Response(400, {}, {});
        }
      );
      // ライブ新規登録プルダウンリスト取得API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getLiveSetUpDataList`,
        () => {
          return new Response(200, {}, LiveSetUpDataList);
        }
      );
      // ライブ自動画質設定取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getAutoQuality`,
        () => {
          return new Response(200, {}, AutoQuality);
        }
      );
      // Wowzaフィード確認API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getWowzaFeed`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          return new Response(200, {}, WowzaFeed(params.wlivesIds));
        }
      );
      // ライブプロファイル削除API
      this.del(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}deleteProfile`,
        (schema, request) => {
          const { ll } = request.queryParams;
          return new Response(
            200,
            {},
            // クエリパラメータをそのまま変形して返す
            // NOTE: 削除が全件失敗するためJCloudのdeactivate deleteのジョブは実行されないパターン
            // { failed: ll.split(',').map((str) => parseInt(str, 10)) }
            // NOTE: 2件以上削除した場合に1つ失敗するパターン
            {
              failed: ll
                .split(',')
                .map((str) => parseInt(str, 10))
                .slice(0, ll.split(',').length - 1),
            }
          );
        }
      );
      // // ライブプロファイル削除API
      // this.del(
      //   `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}deleteProfile`,
      //   () => {
      //     return new Response(
      //       200,
      //       {},
      //       {
      //         failed: [],
      //       }
      //     );
      //   }
      // );
      // ジョブ発行API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}jobDispatch`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // JCloudサーバー作成API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}createJcloudServer`,
        () => {
          return new Response(200, {}, CreateSever);
        }
      );
      // 解像度別m3u8作成API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}makeM3U8`,
        () => {
          return new Response(200, {}, MakeM3U8);
        }
      );
      // ライブプロファイル作成・更新API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}setProfile`,
        () => {
          return new Response(200, {}, SetLiveProfile);
        }
      );
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}setProfileProcess`,
        () => {
          return new Response(200, {}, SetLiveProfile);
        }
      );
      // ライブ情報JSON更新API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}updateLiveInfoJSON`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブフタ情報JSON更新API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}updateLiveLidJSON`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // チャットJSONP更新API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}updateChatJSONP`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // プレイヤー一覧取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getPlayer`,
        () => {
          return new Response(200, {}, PlayerProfile);
        }
      );
      // プレイヤー／チャットパーマリンク作成API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getPlayerTag`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          // NOTE: コールごとにtagのレスポンスを変更して、画面に反映されているのを確認できる
          return new Response(
            200,
            {},
            PlayerTag({
              width: params.width === undefined ? 641 : Number(params.width),
              height: params.height === undefined ? 361 : Number(params.height),
              chat_width:
                params.chat_width === undefined
                  ? 400
                  : Number(params.chat_width),
              chat_height:
                params.chat_height === undefined
                  ? 600
                  : Number(params.chat_height),
              chat_autoresize_flg: Boolean(params.chat_autoresize_flg) ?? false,
            })
          );
        }
      );
      // QRコード生成情報取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getQrcode`,
        () => {
          return new Response(200, {}, QRProfile);
        }
      );
      // 短縮URL生成API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}makeShortUrl`,
        () => {
          // NOTE: コールごとにtagのレスポンスを変更して、画面に反映されているのを確認できる
          return new Response(200, {}, ShortUrl(String(Math.random())));
        }
      );
      // ライブステータス更新API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}updateLiveStatus`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // JCloudサーバー設定変更API
      this.patch(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}updateJcloudServer`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブ履歴取得API
      this.get(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getLiveProfileArch`,
        (schema, request) => {
          const { pageNumber } = request.queryParams;
          return new Response(200, {}, HistoryProfile(Number(pageNumber)));
        }
      );
      // ライブ同時接続取得API
      this.get(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getCount`,
        (schema, request) => {
          const { from, to } = request.queryParams;
          return new Response(200, {}, Count(from, to));
        }
      );
      // ライブチャット投稿履歴取得API
      this.get(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getLiveChatMsgArch`,
        (schema, request) => {
          const { type } = request.queryParams;
          return new Response(200, {}, ChatLog(type));
        }
      );
      // ライブ分割API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}splitRecording`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブイベント情報取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
        () => {
          return new Response(200, {}, LiveEvent);
        }
      );
      // ライブイベント情報更新API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}setEvent`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブ契約数カウントアップAPI
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}countUpCntLive`,
        () => {
          return new Response(200, {}, CountUpCntLive);
        }
      );
      // チャット開始API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}startChat`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブ開始API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}startLive`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブ停止API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}stopLive`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // チャット停止API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}endChat`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // ライブ自動画質履歴取得API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getAutoQualityHistory`,
        () => {
          return new Response(200, {}, AutoQualityHistory);
        }
      );
      // ライブ自動画質履歴取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEncoder`,
        () => {
          return new Response(200, {}, EncoderSetting);
        }
      );
      // ライブ自動画質履歴取得API
      this.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getStreamQuality`,
        () => {
          const streamQuality: GetStreamQuality = EncoderSetting;
          return new Response(200, {}, streamQuality);
        }
      );
      // 疑似ライブプロファイル作成更新API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/setProfile`,
        () => {
          return new Response(200, {}, SetFileToLiveProfile);
        }
      );
      // 疑似ライブプロファイル取得API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getProfile`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          // リクエストにflpidを指定した場合
          if (params.flpid) {
            if (
              typeof params.flpid === 'number' ||
              params.flpid.indexOf(',') !== false
            ) {
              return new Response(200, {}, FileToLiveProfileOne(params.flpid));
            }
            const flpids: number[] = params.flpid
              .split(',')
              .map((item: string) => {
                return parseInt(item, 10);
              });
            return new Response(200, {}, FileToLiveProfileSome(flpids));
          }
          // リクエストに何もない場合
          return new Response(
            200,
            {},
            FileToLiveProfile(Number(params.pageNumber))
          );
        }
      );
      // 疑似ライブプロファイル削除API
      this.del(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/delProfile`,
        (schema, request) => {
          const { flpids } = request.queryParams;
          return new Response(
            200,
            {},
            // クエリパラメータをそのまま変形して返す
            // NOTE: 削除が全件失敗するためJCloudのdeactivate deleteのジョブは実行されないパターン
            // { failed: flpids.split(',').map((str) => parseInt(str, 10)) }
            // NOTE: 2件以上削除した場合に1つ失敗するパターン
            {
              failed: flpids
                .split(',')
                .map((str) => parseInt(str, 10))
                .slice(0, flpids.split(',').length - 1),
            }
          );
        }
      );
      // 疑似ライブ プレイヤー／チャットパーマリンク作成API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getPlayerTag`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          // NOTE: コールごとにtagのレスポンスを変更して、画面に反映されているのを確認できる
          return new Response(
            200,
            {},
            PlayerTag({
              width: params.width === undefined ? 641 : Number(params.width),
              height: params.height === undefined ? 361 : Number(params.height),
              chat_width:
                params.chat_width === undefined
                  ? 400
                  : Number(params.chat_width),
              chat_height:
                params.chat_height === undefined
                  ? 600
                  : Number(params.chat_height),
              chat_autoresize_flg: Boolean(params.chat_autoresize_flg) ?? false,
            })
          );
        }
      );
      // 疑似ライブ 利用可能動画一覧取得API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getMediaList`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          // NOTE: コールごとにtagのレスポンスを変更して、画面に反映されているのを確認できる
          return new Response(
            200,
            {},
            FileToLiveMovie({
              mid: params.mid,
              qualityFlg: params.qualityFlg,
            })
          );
        }
      );
      // 疑似ライブ フィラー利用可能動画一覧取得API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getFillerList`,
        (schema, request) => {
          const params = JSON.parse(request.requestBody);
          // NOTE: コールごとにtagのレスポンスを変更して、画面に反映されているのを確認できる
          return new Response(
            200,
            {},
            FileToLiveFiller(params.searchFillerMid)
          );
        }
      );
      // 疑似ライブチャット投稿履歴取得API
      this.get(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/getFileToLiveChatMsgArch`,
        (schema, request) => {
          const { type } = request.queryParams;
          return new Response(200, {}, ChatLog(type));
        }
      );
      // 疑似ライブ停止API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/stopLive`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // 疑似ライブチャット開始API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/startChat`,
        () => {
          return new Response(200, {}, Success);
        }
      );
      // 疑似ライブチャット停止API
      this.post(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}filetolive/stopChat`,
        () => {
          return new Response(200, {}, Success);
        }
      );
    },
  });
  return server;
};

export default MockServer;
