import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  GET_LIVE_CHAT_MSG_API_ERR_MSG,
  DOWNLOAD_CHAT_LOG_CSV_FLOW_ERR_MSG,
  DOWNLOAD_CLIENTS_LOG_CSV_FLOW_ERR_MSG,
  NOT_PARAMETER_ERR_MSG,
  GET_COUNT_API_ERR_MSG,
} from '../../utils/Message';
import { setError, setOutputCSVStatus } from '../../features/uiSlice';
import { UI_STATUS } from '../../utils/Const';
import { GetLiveConnectionArch } from '../../types/API';

type getCountType = {
  cid: number;
  lpid: number;
  leid: number;
  timeInterval?: number;
  from?: number;
  to?: number;
};

/** ライブ同時接続取得 */
export const downloadClientsLogCSVFlow =
  (arg: getCountType): AppThunk =>
  async (dispatch) => {
    // 処理中をセット
    dispatch(setOutputCSVStatus(UI_STATUS.PROCESSING));
    try {
      // パラメータに不足がある場合はエラー
      if (!arg.from || !arg.to) {
        const noparam: string[] = [];
        if (!arg.from) {
          noparam.push('ライブ開始時刻');
        }
        if (!arg.to) {
          noparam.push('ライブ停止時刻');
        }
        dispatch(
          setError({
            msg: GET_COUNT_API_ERR_MSG,
            detail: `${NOT_PARAMETER_ERR_MSG}: ${noparam.join()}`,
          })
        );
        return true;
      }
      // ライブ同時接続取得API実行
      const response: AxiosResponse<GetLiveConnectionArch> = await axios.get(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getCount`,
        {
          params: {
            cid: arg.cid,
            lpid: arg.lpid,
            leid: arg.leid,
            timeInterval: arg.timeInterval || 30,
            from: arg.from,
            to: arg.to,
          },
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      // 正常終了
      const header = `${Object.keys(response.data.data[0]).join(',')}\r\n`;
      const data = `${response.data.data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((record: any) => Object.values(record).join(','))
        .join('\r\n')}\r\n\r\n`;
      const footer = `${['累計視聴者数', response.data.total ?? 0].join(
        ','
      )}\r\n`;
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const url = URL.createObjectURL(
        new Blob([bom, header, data, footer], { type: 'text/csv' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${arg.cid}_${arg.lpid}-${arg.leid}_${arg.from}_${arg.to}.csv`
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 404エラーは空のCSVファイルを出力させる
      if (error.response?.data?.statusCode === 404) {
        const url = URL.createObjectURL(new Blob([], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${arg.cid}_${arg.lpid}-${arg.leid}_${arg.from}_${arg.to}.csv`
        );
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
        return true;
      }
      // 異常終了
      return dispatch(
        setError({
          msg: error.response
            ? GET_COUNT_API_ERR_MSG
            : DOWNLOAD_CLIENTS_LOG_CSV_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      // 完了をセット
      dispatch(setOutputCSVStatus(UI_STATUS.COMPLETED));
    }
  };

type getChatType = {
  readAPIToken: string;
  lplid: string;
  setNotFoundChatlogDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

/** ライブチャット投稿履歴取得 */
export const downloadChatLogCSVFlow =
  (arg: getChatType): AppThunk =>
  async (dispatch) => {
    // 処理中をセット
    dispatch(setOutputCSVStatus(UI_STATUS.PROCESSING));
    try {
      // ライブチャット投稿履歴取得API実行
      const response: AxiosResponse<string> = await axios.get(
        `${String(
          process.env.REACT_APP_BACKEND_API_ENDPOINT
        )}getLiveChatMsgArch`,
        {
          params: {
            t: arg.readAPIToken,
            lplid: arg.lplid,
            type: 'text',
          },
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      // 正常終了
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const url = URL.createObjectURL(
        new Blob([bom, response.data], { type: 'text/csv' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `chatlog_${arg.lplid}.csv`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
      return true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 404エラー時はダイアログを表示
      if (error.response?.data?.statusCode === 404) {
        arg.setNotFoundChatlogDialog(true);
        return true;
      }
      // 異常終了
      return dispatch(
        setError({
          msg: error.response
            ? GET_LIVE_CHAT_MSG_API_ERR_MSG
            : DOWNLOAD_CHAT_LOG_CSV_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      // 完了をセット
      dispatch(setOutputCSVStatus(UI_STATUS.COMPLETED));
    }
  };
