import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import uiReducer from '../features/uiSlice';
import userReducer from '../features/userSlice';
import livesReducer from '../features/livesSlice';
import historiesReducer from '../features/historiesSlice';
import fileToLivesReducer from '../features/fileToLivesSlice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
    lives: livesReducer,
    histories: historiesReducer,
    fileToLives: fileToLivesReducer,
  },
  devTools: process.env.REACT_APP_REDUX_DEVTOOLS === 'true',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
