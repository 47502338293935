/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import {
  selectEQLivesById,
  setPamalink,
  setQRUrl,
  setShortQRUrl,
  setShortVideoUrl,
} from '../../features/livesSlice';
import { setError, setLiveShareStatus } from '../../features/uiSlice';
import { MakeShortUrl, SetPamalink } from '../../types/API';
import {
  DEFAULT_CHATSIZE,
  PLAYER_ASPECTFIX,
  STREMAS_NUM,
  ONOFF,
  UI_STATUS,
} from '../../utils/Const';
import makeQRUrl from '../../utils/MakeQRUrl';
import { LIVESHAREUPDATE_FLOW_ERR_MSG } from '../../utils/Message';

type UpdateLiveShareParams = {
  canPlayerload: React.MutableRefObject<boolean>;
  canPlayerdel: React.MutableRefObject<boolean>;
  setPreviewPlayerRender: (value: React.SetStateAction<boolean>) => void;
  cid: number;
  userApiParams: string;
  lpid: number;
  setPpsid: React.Dispatch<React.SetStateAction<number>>;
  pid?: number;
  ppsid?: number;
  width: number;
  height: number;
  chatWidth?: number;
  chatHeight?: number;
  chatAutoresizeFlg?: 0 | 1;
  refererPolicy?: 0 | 1;
  defaultQualityPC?: STREMAS_NUM;
  defaultQualitySP?: STREMAS_NUM;
  setVideoWidth: React.Dispatch<React.SetStateAction<number>>;
  setVideoHeight: React.Dispatch<React.SetStateAction<number>>;
  setAspectRatio: React.Dispatch<React.SetStateAction<PLAYER_ASPECTFIX>>;
  videoDefaultSizeFlg?: boolean;
  videoanalyzerCookie?: boolean;
  resumeCookie?: boolean;
  setVideoanalyzerCookie: React.Dispatch<React.SetStateAction<boolean>>;
  setResumeCookie: React.Dispatch<React.SetStateAction<boolean>>;
};

type getPlayerTagRequestParams = {
  cid: string;
  userApiParams: string;
  lpid: string;
  pid?: number;
  ppsid?: number;
  width?: number;
  height?: number;
  chat_width?: number;
  chat_height?: number;
  chat_autoresize_flg?: number;
  referer_policy?: number;
  defaultQualityPc?: number;
  defaultQualitySp?: number;
  videoanalyzerCookie?: 0 | 1;
  resumeCookie?: 0 | 1;
};

/** ライブ共通タグ画面更新フロー */
const updateLiveShare =
  (arg: UpdateLiveShareParams): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setLiveShareStatus(UI_STATUS.SAVING));
      arg.canPlayerdel.current = true;
      arg.setPreviewPlayerRender(false);

      // プレイヤー／チャットパーマリンク作成API のリクエストパラメータ
      const updatePlayerTagParams: getPlayerTagRequestParams = {
        cid: String(arg.cid),
        userApiParams: arg.userApiParams,
        lpid: String(arg.lpid),
        pid: arg.pid,
        ppsid: arg.ppsid === 0 ? undefined : arg.ppsid,
        width: arg.width,
        height: arg.height,
        chat_width: arg.chatWidth ?? DEFAULT_CHATSIZE.WIDTH,
        chat_height: arg.chatHeight ?? DEFAULT_CHATSIZE.HEIGHT,
        chat_autoresize_flg: arg.chatAutoresizeFlg ?? 0,
        referer_policy: arg.refererPolicy ?? 1,
        videoanalyzerCookie: arg.videoanalyzerCookie ? 0 : 1,
        resumeCookie: arg.resumeCookie ? 0 : 1,
      };
      if (arg.defaultQualityPC !== STREMAS_NUM.UNSELECTED)
        updatePlayerTagParams.defaultQualityPc = arg.defaultQualityPC;
      if (arg.defaultQualitySP !== STREMAS_NUM.UNSELECTED)
        updatePlayerTagParams.defaultQualitySp = arg.defaultQualitySP;

      // プレイヤー／チャットパーマリンク作成API実行
      const responseSetPamalink: AxiosResponse<SetPamalink> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getPlayerTag`,
        updatePlayerTagParams,
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      if (responseSetPamalink.data.errorCode !== 0) {
        // 異常終了
        // NOTE: 400系のクライアントサイドのエラーだが、エラー画面に遷移させないとチェックボックスの不具合が生じるため、例外的にエラー画面に遷移
        throw new Error(
          `${responseSetPamalink.data.errorCode}: ${responseSetPamalink.data.message}`
        );
      }

      dispatch(setPamalink({ ...responseSetPamalink.data }));
      const tagParams = {
        cid: String(arg.cid),
        lpid: String(arg.lpid),
        type: 'new_live',
      };

      // 短縮動画URL生成API実行
      const responseShortVideo: AxiosResponse<MakeShortUrl> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}makeShortUrl`,
        {
          url: responseSetPamalink.data.selectedPlayer.permalink.tag,
          userApiParams: arg.userApiParams,
          tags: tagParams,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setShortVideoUrl({ ...responseShortVideo.data }));
      arg.setPreviewPlayerRender(true);
      arg.canPlayerload.current = true;
      const chatenable = Boolean(
        selectEQLivesById(getState(), arg.lpid)?.chat.enabled
      );

      // 非短縮QRコードURL生成
      const QRUrl = makeQRUrl(
        chatenable,
        responseSetPamalink.data.selectedPlayer.permalink.tag
      );

      dispatch(setQRUrl(QRUrl));

      // 短縮QRURL生成API実行
      const responseShortQR: AxiosResponse<MakeShortUrl> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}makeShortUrl`,
        {
          url: QRUrl,
          userApiParams: arg.userApiParams,
          tags: tagParams,
          qr: true,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      dispatch(setShortQRUrl({ ...responseShortQR.data }));

      // 掲載サイト別管理選択のプルダウンで選択したとき
      if (arg.pid === -1 && arg.videoDefaultSizeFlg) {
        const nowPlayer = getState().user.player.players.find((profile) => {
          return profile.pid === responseSetPamalink.data.selectedPlayer.pid;
        });
        arg.setVideoWidth(nowPlayer?.width ?? arg.width);
        arg.setVideoHeight(nowPlayer?.height ?? arg.height);
        arg.setAspectRatio(nowPlayer?.aspectratio ?? PLAYER_ASPECTFIX.XVIIVFIX);
        arg.setVideoanalyzerCookie(
          nowPlayer?.videoanalyzerCookie === ONOFF.OFF ?? false
        );
        arg.setResumeCookie(nowPlayer?.resumeCookie === ONOFF.OFF ?? false);
      }

      if (!arg.ppsid) {
        // 掲載サイト別管理の最後尾の設定を初期値に
        const bottomPpsid = responseSetPamalink.data.pubsitesList.length
          ? responseSetPamalink.data.pubsitesList.slice(-1)[0].ppsid
          : 0;
        arg.setPpsid(bottomPpsid);
      }

      // 正常終了
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      return dispatch(
        setError({
          msg: LIVESHAREUPDATE_FLOW_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveShareStatus(UI_STATUS.COMPLETED));
    }
  };

export default updateLiveShare;
