import moment from 'moment';
import { GetLiveConnections } from '../../types/API';

const Count = (from?: string, to?: string): GetLiveConnections => {
  if (!from || !to) {
    return {
      data: [
        { datetime: '2019-11-07 17:55:30', count: 0 },
        { datetime: '2019-11-07 17:56:00', count: 0 },
        { datetime: '2019-11-07 17:56:30', count: 255 },
        { datetime: '2019-11-07 17:57:00', count: 444 },
        { datetime: '2019-11-07 17:57:30', count: 514 },
        { datetime: '2019-11-07 17:58:00', count: 603 },
        { datetime: '2019-11-07 17:58:30', count: 680 },
        { datetime: '2019-11-07 17:59:00', count: 777 },
        { datetime: '2019-11-07 17:59:30', count: 905 },
        { datetime: '2019-11-07 18:00:00', count: 1017 },
      ],
      max: 1017,
      current: 1017,
      total: 5195,
    };
  }
  // YYYYMMDDhhmmss => YYYY/MM/DD HH:mm:ss 変換
  const dateFormat = (date: string) => {
    return moment(
      `${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6, 8)} ${date.slice(
        8,
        10
      )}:${date.slice(10, 12)}:${date.slice(12, 14)}`
    );
  };
  const fromDate = dateFormat(from);
  const toDate = dateFormat(to);
  const diff = Math.ceil(toDate.diff(fromDate, 'seconds') / 30 + 1);
  return {
    data: [...Array(diff)].map((x, i) => {
      return {
        datetime: fromDate.add(30, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
        count: 200 + i * 50,
      };
    }),
    max: 1017,
    current: 1017,
    total: 5195,
  };
};

export default Count;
