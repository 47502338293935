import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Error } from '../types/Error';
import { APISuccess } from '../types/API';
import {
  REFERRER_ERR_MSG,
  PATH_ERR_MSG,
  QUERY_ERR_MSG,
  INIT_FLOW_ERR_MSG,
  AUTH_API_ERR_MSG,
} from '../utils/Message';
import { setFromPreviousPage } from './userSlice';
import { UI_STATUS } from '../utils/Const';

type State = {
  /** 認証フラグ */
  auth: boolean;

  /** 画面制御用フラグ */
  status: {
    liveList: UI_STATUS;
    liveRegister: UI_STATUS;
    liveContent: UI_STATUS;
    liveEncoder: UI_STATUS;
    liveShare: UI_STATUS;
    liveController: UI_STATUS;
    liveMonitor: UI_STATUS;
    historyContent: UI_STATUS;
    historyList: UI_STATUS;
    clientsLog: UI_STATUS;
    outputCSV: UI_STATUS;
    fileToLiveList: UI_STATUS;
    fileToLiveRegister: UI_STATUS;
    fileToLiveContent: UI_STATUS;
    fileToLiveShare: UI_STATUS;
    fileToLiveController: UI_STATUS;
    fileToLiveMovies: UI_STATUS;
    fileToLivePrefillers: UI_STATUS;
    fileToLivePostfillers: UI_STATUS;
  };

  /** Error情報 */
  error: {
    flg: boolean;
    data: Error;
  };
};

const initialState: State = {
  auth: false,
  status: {
    liveList: UI_STATUS.PROCESSING,
    liveRegister: UI_STATUS.PROCESSING,
    liveContent: UI_STATUS.PROCESSING,
    liveEncoder: UI_STATUS.PROCESSING,
    liveShare: UI_STATUS.PROCESSING,
    liveController: UI_STATUS.PROCESSING,
    liveMonitor: UI_STATUS.PROCESSING,
    historyContent: UI_STATUS.PROCESSING,
    historyList: UI_STATUS.PROCESSING,
    clientsLog: UI_STATUS.COMPLETED,
    outputCSV: UI_STATUS.COMPLETED,
    fileToLiveList: UI_STATUS.PROCESSING,
    fileToLiveRegister: UI_STATUS.PROCESSING,
    fileToLiveContent: UI_STATUS.PROCESSING,
    fileToLiveShare: UI_STATUS.PROCESSING,
    fileToLiveController: UI_STATUS.PROCESSING,
    fileToLiveMovies: UI_STATUS.COMPLETED,
    fileToLivePrefillers: UI_STATUS.COMPLETED,
    fileToLivePostfillers: UI_STATUS.COMPLETED,
  },
  error: {
    flg: false,
    data: {
      msg: '',
      detail: '',
    },
  },
};

/** 初期処理 */
export const init = createAsyncThunk<APISuccess, {}, { rejectValue: Error }>(
  'ui/init',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      // リファラーチェック
      if (String(process.env.REACT_APP_EQ_REFERRER_CHECK) === 'true') {
        const urls = String(process.env.REACT_APP_WHITELIST_DOMAIN).split(',');
        if (!urls.find((url) => document.referrer.indexOf(url) !== -1)) {
          return rejectWithValue({
            msg: REFERRER_ERR_MSG,
            detail: '',
          });
        }
      }

      // パスチェック
      const { pathname } = window.location;
      if (pathname === '') {
        return rejectWithValue({
          msg: PATH_ERR_MSG,
          detail: '',
        });
      }

      // 1つめのパスをbase64エンコードしてトークンとして取得
      const token = Buffer.from(pathname.split('/')[1]).toString('base64');

      // クエリチェック
      const searchString = window.location.search;
      const params = new URLSearchParams(searchString);
      const cid = Number(params.get('c'));
      const readAPIToken = params.get('t');
      const user = encodeURI(String(params.get('u'))).replace(/%20/g, '+');
      if (!cid || !readAPIToken || !user) {
        return rejectWithValue({
          msg: QUERY_ERR_MSG,
          detail: '',
        });
      }

      // 認証API実行
      const response = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}checkToken`,
        { token },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // userSliceにEQユーザー情報、cid、ReadAPIトークンを追加
      dispatch(setFromPreviousPage({ user, cid, readAPIToken }));

      // 正常終了
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue({
        msg: error.response ? AUTH_API_ERR_MSG : INIT_FLOW_ERR_MSG,
        detail: JSON.stringify(error.response?.data || error.message),
      });
    }
  }
);

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // Error情報セット
    setError(state: State, action: PayloadAction<Error>) {
      state.error.flg = true;
      state.error.data = action.payload;
    },
    // ライブの一覧ステータスセット
    setLiveListStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveList = action.payload;
    },
    // ライブの新規登録ステータスセット
    setLiveRegisterStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveRegister = action.payload;
    },
    // ライブ詳細ステータスセット
    setLiveContentStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveContent = action.payload;
    },
    // ライブエンコーダー設定ステータスセット
    setLiveEncoderStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveEncoder = action.payload;
    },
    // ライブ共通タグステータスセット
    setLiveShareStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveShare = action.payload;
    },
    // ライブコンパネステータスセット
    setLiveControllerStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveController = action.payload;
    },
    // ライブモニタリングステータスセット
    setLiveMonitorStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.liveMonitor = action.payload;
    },
    // ライブ履歴ステータスセット
    setHistoryContentStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.historyContent = action.payload;
    },
    // ライブ履歴一覧セット
    setHistoryListStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.historyList = action.payload;
    },
    // 同時接続数ログセット
    setClientsLogStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.clientsLog = action.payload;
    },
    // CSV出力セット
    setOutputCSVStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.outputCSV = action.payload;
    },
    // 疑似ライブの一覧ステータスセット
    setFileToLiveListStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.fileToLiveList = action.payload;
    },
    // 疑似ライブの新規登録ステータスセット
    setFileToLiveRegisterStatus(
      state: State,
      action: PayloadAction<UI_STATUS>
    ) {
      state.status.fileToLiveRegister = action.payload;
    },
    // 疑似ライブ詳細ステータスセット
    setFileToLiveContentStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.fileToLiveContent = action.payload;
    },
    // 疑似ライブ共通タグステータスセット
    setFileToLiveShareStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.fileToLiveShare = action.payload;
    },
    // 疑似ライブコンパネステータスセット
    setFileToLiveControllerStatus(
      state: State,
      action: PayloadAction<UI_STATUS>
    ) {
      state.status.fileToLiveController = action.payload;
    },
    // 疑似ライブの利用動画一覧ステータスセット
    setFileToLiveMoviesStatus(state: State, action: PayloadAction<UI_STATUS>) {
      state.status.fileToLiveMovies = action.payload;
    },
    // 疑似ライブの開始前フィラー利用動画一覧ステータスセット
    setFileToLivePrefillersStatus(
      state: State,
      action: PayloadAction<UI_STATUS>
    ) {
      state.status.fileToLivePrefillers = action.payload;
    },
    // 疑似ライブの終了フィラー利用動画一覧ステータスセット
    setFileToLivePostfillersStatus(
      state: State,
      action: PayloadAction<UI_STATUS>
    ) {
      state.status.fileToLivePostfillers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(init.fulfilled, (state) => {
      state.auth = true;
    });
    builder.addCase(init.rejected, (state, action) => {
      state.error.flg = true;
      if (action.payload) state.error.data = action.payload;
    });
  },
});

export const {
  setError,
  setLiveListStatus,
  setLiveRegisterStatus,
  setLiveContentStatus,
  setLiveEncoderStatus,
  setLiveShareStatus,
  setLiveControllerStatus,
  setLiveMonitorStatus,
  setHistoryContentStatus,
  setHistoryListStatus,
  setClientsLogStatus,
  setOutputCSVStatus,
  setFileToLiveListStatus,
  setFileToLiveRegisterStatus,
  setFileToLiveContentStatus,
  setFileToLiveShareStatus,
  setFileToLiveControllerStatus,
  setFileToLiveMoviesStatus,
  setFileToLivePrefillersStatus,
  setFileToLivePostfillersStatus,
} = uiSlice.actions;

export default uiSlice.reducer;
