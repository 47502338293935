import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { NavigateNext, Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CommonBox from '../parts/CommonBox/CommonBox';
import ControllerLeft from './ControllerLeft';
import ControllerRight from './ControllerRight';
import Dialog from '../parts/Dialog/Dialog';
import WarningDialog from '../parts/WarningDialog/WarningDialog';
import { LiveControllerProps } from '../../props/LiveController';
import ShackBar from '../parts/SnackBar/SnackBar';
import {
  MENUBAR,
  ROUTE,
  getPath,
  UI_STATUS,
  EDITIONS,
  CHAT_STATUS,
  CONTROLLER_MIN_WIDTH,
} from '../../utils/Const';

/** スタイル */
const useStyle = makeStyles({
  root: {},
  header: {
    margin: '20px 0',
    color: '#444444',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    overflow: 'auto',
  },
  footer: {
    marginTop: 30,
    '& a': {
      color: '#333333',
    },
    '& li, .MuiTypography-root': {
      fontSize: 14,
    },
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#4A8BFB80',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#4A8BFB',
    },
  },
});

/** コントロールパネル(UI) */
const LiveControllerComponent = (props: LiveControllerProps): JSX.Element => {
  const classes = useStyle();
  const {
    uiStatus,
    edition,
    cid,
    readAPIToken,
    userApiParams,
    nowClientCnt,
    maxClient,
    maxClientLimit,
    chatStatus,
    aleartPotalHandleDialog,
    aleartLiveStartNoneFeedDialog,
    aleartSimultaneousLiveCountDialog,
    aleartLiveStartDialog,
    aleartLiveConnectionsCountErrorDialog,
    aleartLiveFinishDialog,
    aleartJcloudDeactivatedDialog,
    aleartSimultaneousLiveCountErrorDialog,
    liveErrorDialog,
    ChatDialog,
    snackbarInfo,
    handleSnackbarClose,
    vodFlg,
    dvrCloseDialog,
  } = props;
  const [finishDialogOpen, setFinishDialogOpen] = useState<boolean>(false);
  const [finishProgress, setFinishProgress] = useState<number>(0);

  // ライブ停止プログレスバー処理
  const handleOKfinish = () => {
    aleartLiveFinishDialog.handleOK();
    if (vodFlg) {
      setFinishDialogOpen(true);
      setFinishProgress(0);
      setInterval(() => {
        setFinishProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval();
            return 100;
          }
          return prevProgress + 100 / 40;
        });
      }, 1000);
    }
  };

  // 処理中
  if (uiStatus === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox
      width={CONTROLLER_MIN_WIDTH}
      className={classes.root}
      navibar={MENUBAR.LIVELIST}
    >
      <Box className={classes.header}>
        <h2 style={{ fontSize: 24, marginRight: 15, margin: 0 }}>
          コントロールパネル
        </h2>
      </Box>
      <Box className={classes.container}>
        <ControllerLeft props={props} />
        <ControllerRight props={props} />
      </Box>
      <Box className={classes.footer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {[
            <Link
              to={`${getPath(
                ROUTE.LIVE_LIST
              )}?c=${cid}&t=${readAPIToken}&u=${userApiParams}`}
            >
              ライブの配信設定と開催
            </Link>,
            <Typography key="3">コントロールパネル</Typography>,
          ]}
        </Breadcrumbs>
      </Box>
      <Dialog
        open={dvrCloseDialog}
        handleClose={() => {
          window.location.reload();
        }}
        title={
          <Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 20,
                marginBottom: 15,
              }}
            >
              <Warning style={{ marginRight: 10 }} />
              ライブDVR無効化のお知らせ
            </Box>
            <Box>
              ライブのDVRが無効化されました。
              <br />
              再度配信する場合はコントロールパネルをリロードするか、一度コントロールパネルを閉じ、開き直してから配信をしてください。
            </Box>
          </Box>
        }
        cansel={null}
        ok="OK"
      />
      <Dialog
        open={aleartPotalHandleDialog.open}
        handleClose={aleartPotalHandleDialog.handleClose}
        title={
          <Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 20,
                marginBottom: 15,
              }}
            >
              <Warning style={{ marginRight: 10 }} />
              ご注意ください
            </Box>
            <Box>
              このライブ配信設定は、現在ポータル側で有効なライブ配信ページを持っている状態です。
              <br />
              このままライブ配信を行うと、ポータル上でライブ配信が行われます。
              <br />
              ポータル上でのライブ配信を避けたい場合、新たなライブ配信設定をして配信するか、別のライブ配信設定よりライブ配信を行ってください。
            </Box>
          </Box>
        }
        cansel={null}
        ok="OK"
      />
      <WarningDialog
        open={aleartSimultaneousLiveCountDialog.open}
        handleClose={aleartSimultaneousLiveCountDialog.handleClose}
        handleOK={aleartSimultaneousLiveCountDialog.handleOK}
        header="同時ライブ開催数についての警告"
        body={
          <>
            契約している同時ライブ開催数を超過しているため、ご利用頂けません。
            <br />
            <br />
            ライブコントロールパネルを開きますが、よろしいですか？
          </>
        }
        cancel="キャンセル"
        ok="OK"
      />
      <WarningDialog
        open={aleartLiveStartDialog.open}
        handleClose={aleartLiveStartDialog.handleClose}
        handleOK={aleartLiveStartDialog.handleOK}
        header="ライブ配信開始前確認"
        body={
          <>
            {edition !== EDITIONS.U_LIVE && (
              <>
                {`同時接続枠を${nowClientCnt}人/${maxClient}人使用中です。同時接続枠を${maxClientLimit}人使用します。`}
                <br />
              </>
            )}
            <span style={{ color: '#C32733', display: 'block' }}>
              アーカイブされる動画について、「OK」ボタン押下後から開始10秒程度録画されないためご注意ください。
            </span>
            ライブ配信{aleartLiveStartDialog.withChat && 'とチャット'}
            を開始しますが、よろしいですか？
          </>
        }
        cancel="キャンセル"
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={aleartSimultaneousLiveCountErrorDialog.open}
        handleClose={aleartSimultaneousLiveCountErrorDialog.handleClose}
        handleOK={aleartSimultaneousLiveCountErrorDialog.handleClose}
        header="同時ライブ開催数についての警告"
        body={
          <>
            契約している同時ライブ開催数を超過しているため、ご利用頂けません。
            <br />
            設定しているライブ開催数を超過してご利用いただく場合、追加費用が発生いたします。
            <br />
            担当営業までお問い合わせください。
          </>
        }
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={aleartLiveConnectionsCountErrorDialog.open}
        handleClose={aleartLiveConnectionsCountErrorDialog.handleClose}
        handleOK={aleartLiveConnectionsCountErrorDialog.handleClose}
        header="ライブ配信エラー"
        body={
          <>
            {`同時接続枠を${nowClientCnt}人/${maxClient}人使用中です。同時接続枠がオーバーしています。`}
            <br />
            変更してください。
          </>
        }
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={ChatDialog.open}
        handleClose={ChatDialog.handleClose}
        handleOK={ChatDialog.handleOK}
        header={`チャット${
          chatStatus === CHAT_STATUS.STREAMING ? '停止' : '開始'
        }前確認`}
        body={
          <>
            <div>{`チャットを${
              chatStatus === CHAT_STATUS.STREAMING ? '停止' : '開始'
            }しますが、よろしいですか？`}</div>
            {chatStatus === CHAT_STATUS.STREAMING && (
              <>
                <br />
                <div>ライブ配信は開始されていますか？</div>
                <div>
                  チャットを終了する場合、必ずライブ配信を開始した後に終了してください。
                  <br />
                  ライブ配信開始前にチャットを終了すると、ライブ配信終了までチャットがご利用いただけなくなります。
                </div>
              </>
            )}
          </>
        }
        cancel="キャンセル"
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={aleartLiveFinishDialog.open}
        handleClose={aleartLiveFinishDialog.handleClose}
        handleOK={handleOKfinish}
        header="ライブ配信停止前確認"
        body={
          <>
            ライブ配信{aleartLiveFinishDialog.withChat && 'とチャット'}
            を停止します。
            <br />
            ライブ履歴名は現在入力されている内容で確定します。
            <br />
            よろしいですか？
            <br />
            <br />
            ライブ配信停止後、ライブサーバステータスエラーのダイアログが表示された場合はコントロールパネルをリロードするか、一度コントロールパネルを閉じ、開きなおしてから配信をしてください。
            <br />
            <br />
            ※&ensp;視聴者との遅延があるため、終了間際の映像が視聴者に配信されない場合がござ
            <br />
            &emsp;&ensp;います。
            <br />
            &emsp;&ensp;プレゼンテーション終了後、配信を40秒程度維持した後、停止してください。
          </>
        }
        cancel="キャンセル"
        ok="OK"
        buttonLeft="ok"
      />
      <Dialog
        open={finishDialogOpen}
        handleClose={() => {
          if (finishProgress < 100) return;
          setFinishDialogOpen(false);
        }}
        title={
          <Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 20,
                marginBottom: 15,
              }}
            >
              <Warning style={{ marginRight: 10 }} />
              <span style={{ color: '#C32733' }}>終了処理中です・・・</span>
            </Box>
            <Box style={{ color: '#C32733' }}>
              アーカイブされる動画について、「OK」ボタン押下後から録画が完全に停止する
              <br />
              まで数十秒は動画内に記録されますのでご注意ください。
              <Box className={classes.progress}>
                <LinearProgress
                  variant="determinate"
                  value={finishProgress}
                  style={{ width: '100%', marginRight: 5 }}
                />
                <span style={{ width: 40, color: '#000' }}>
                  {Math.ceil(finishProgress)}%
                </span>
              </Box>
            </Box>
          </Box>
        }
        cansel={null}
        ok="OK"
        buttonDisabled={finishProgress < 100}
      />
      <WarningDialog
        open={aleartJcloudDeactivatedDialog.open}
        handleClose={aleartJcloudDeactivatedDialog.handleClose}
        handleOK={aleartJcloudDeactivatedDialog.handleClose}
        header="ライブサーバーステータスエラー"
        body={
          <>
            ライブサーバーがライブフィードを受け付けないステータスになっています。
            <br />
            コントロールパネルをリロードします。
          </>
        }
        ok="OK"
        buttonLeft="ok"
      />
      <WarningDialog
        open={aleartLiveStartNoneFeedDialog.open}
        handleClose={aleartLiveStartNoneFeedDialog.handleClose}
        header="ライブ開始時フィード未確認"
        body={
          <>
            エンコーダーからのフィードが確認できないため、 <br />
            ライブ配信ができませんでした。 <br />
            エンコーダーからフィードを再接続いただき、 <br />
            コントロールパネルを再読み込みした上で、ライブ配信を開始してください。
          </>
        }
        cancel="OK"
        ok=""
      />
      <WarningDialog
        open={liveErrorDialog.open}
        handleClose={liveErrorDialog.handleClose}
        handleOK={liveErrorDialog.handleClose}
        header="配信エラー"
        body={
          <div dangerouslySetInnerHTML={{ __html: liveErrorDialog.message }} />
        }
        ok="OK"
        buttonLeft="ok"
      />
      <ShackBar infomations={snackbarInfo} handleClose={handleSnackbarClose} />
    </CommonBox>
  );
};

export default LiveControllerComponent;
