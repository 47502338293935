import axios, { AxiosResponse } from 'axios';
import { AppThunk } from '../../app/store';
import { CHAT_MANUAL_CLOSE_ERR_MSG } from '../../utils/Message';
import { setError, setLiveControllerStatus } from '../../features/uiSlice';
import { UI_STATUS } from '../../utils/Const';
import {
  GetLiveEvent,
  GetLiveProfile,
  LiveEventProfile,
} from '../../types/API';
import {
  addEQLiveProfile,
  setLiveEventProfile,
} from '../../features/livesSlice';

type ChatManualStopParams = {
  lpid: number;
  event: LiveEventProfile;
  cid: number;
  userApiParams: string;
  customerDir: string;
  chat: {
    closeTiming: number;
  };
};

/** チャット手動停止フロー */
const ChatManualStop =
  (arg: ChatManualStopParams): AppThunk =>
  async (dispatch, getState) => {
    // チャット処理中をセット
    dispatch(setLiveControllerStatus(UI_STATUS.PROCESSING_CHAT));
    try {
      // チャット停止API実行
      await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}endChat`,
        {
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          lpid: arg.lpid,
          customerDir: arg.customerDir,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );

      // ライブプロファイル取得API実行
      const responseProfile: AxiosResponse<GetLiveProfile> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getProfile`,
        {
          lpid: arg.lpid,
          cid: arg.cid,
          userApiParams: arg.userApiParams,
          pageSize: 1,
          portal: getState().user.contract.serviceFlg39 ? 1 : 0,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(addEQLiveProfile(responseProfile.data.profile[0]));

      // ライブイベント情報取得API実行
      const response: AxiosResponse<GetLiveEvent> = await axios.post(
        `${String(process.env.REACT_APP_BACKEND_API_ENDPOINT)}getEvent`,
        { cid: arg.cid, userApiParams: arg.userApiParams, lpid: arg.lpid },
        {
          headers: {
            accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      );
      dispatch(setLiveEventProfile({ ...response.data }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 異常終了
      dispatch(
        setError({
          msg: CHAT_MANUAL_CLOSE_ERR_MSG,
          detail: JSON.stringify(error.response?.data || error.message),
        })
      );
    } finally {
      dispatch(setLiveControllerStatus(UI_STATUS.COMPLETED));
    }
  };

export default ChatManualStop;
