/** APIレスポンスの日付をYYYY/MM/DD HH:mm:ssで返す */
const checkDate = (time: string | null): string | null => {
  if (!time || time.length <= 0) return null;
  let date = time.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/);
  if (!date) {
    date = time.match(/(\d{4})\/(\d{2})\/(\d{2})T(\d{2}):(\d{2}):(\d{2})/);
    if (!date) return time;
  }
  return `${date[1]}/${date[2]}/${date[3]} ${date[4]}:${date[5]}:${date[6]}`;
};

export default checkDate;
