import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  LinearProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  InfoOutlined,
  PlaylistAdd,
  DeleteForever,
  Help,
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import CommonBox from '../parts/CommonBox/CommonBox';
import Tooltip from '../parts/Tooltip/Tooltip';
import Dialog from '../parts/Dialog/Dialog';
import FileToLiveListTable from './FileToLiveListTable';
import { FileToLiveListProps } from '../../props/FileToLiveList';
import {
  EDITIONS,
  MENUBAR,
  UI_STATUS,
  MIN_WIDTH,
  FILE_TO_LIVE_STATUS,
  DISPLAY_COUNT,
  FILE_TO_LIVE_CHAT_STATUS,
} from '../../utils/Const';
import { RootState } from '../../app/store';
import FileToLiveShareContainer from '../../containers/FileToLiveShare/FileToLiveShareContainer';
import FileToLiveHistoryConnectionGraphContainer from '../../containers/FileToLiveHistoryConnectionGraphContainer';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '&::before': {
      content: '""',
      width: '100vw',
      height: 319,
      backgroundSize: '100% 319px',
      background:
        'transparent linear-gradient(180deg, #CFEAB3 0%, #FBFBFB 100%) 0% 0% no-repeat padding-box',
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 3,
    },
    '& .common-header, .common-navigation': {
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 3,
    },
    '& .common-navigation': {
      top: 80,
    },
    '& .MuiButton-label': {
      fontFamily: '"Noto Sans CJK JP", "Noto Sans JP", "Inter", "Roboto Mono"',
      fontWeight: 500,
    },
  },
  fixed: {
    position: 'fixed',
    left: 0,
    right: 0,
    width: MIN_WIDTH,
    minWidth: MIN_WIDTH,
    zIndex: 3,
    '@media (min-width: 1501px)': {
      width: `calc(100% - (1500px - ${MIN_WIDTH}px))`,
    },
  },
  header: {
    fontSize: 24,
    color: '#444444',
    top: 120,
    margin: '20px auto',
  },
  infoBox: {
    background: '#FBFFF8 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 12px #61B22712',
    border: '1px solid #B4D69B',
    borderRadius: 4,
    display: 'flex',
    fontSize: 14,
    padding: '7px 10px',
    marginBottom: 20,
    top: 195,
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    '& em': {
      fontStyle: 'normal',
      fontWeight: 700,
    },
    '& strong': {
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#C32733',
      marginRight: 4,
    },
  },
  tool: {
    display: 'flex',
    justifyContent: 'space-between',
    top: 264,
    margin: '0 auto',
  },
  state: {
    background: '#61B227 0% 0% no-repeat padding-box',
    borderRadius: 100,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    paddingRight: 10,
    marginRight: 10,
  },

  helpContainer: {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
      width: 100,
      marginLeft: '-50px',
      '& .balloon:before': {
        marginLeft: 40,
      },
    },
  },
  help: {
    color: '#B6BEC9',
    marginLeft: 5,
    marginBottom: '-2px',
    width: 16,
    height: 16,
  },
  addButton: {
    color: '#FFF',
    background: '#4A8BFB',
    fontSize: 17,
    fontWeight: 500,
    borderBottom: '1px solid #3C72D0',
    borderRadius: 4,
    marginRight: 10,
    height: 40,
    width: 160,
    '&:hover': {
      backgroundColor: '#015CFA',
    },
  },
  deleteButton: {
    color: '#C32733',
    border: '1px solid #C32733',
    fontSize: 17,
    fontWeight: 500,
    borderRadius: 4,
    padding: '4px 10px',
    paddingRight: 0,
    height: 40,
    minWidth: 132,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#C32733',
    },
    '& svg': {
      marginRight: 3,
    },
  },
  table: {
    marginTop: 326,
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100vw',
  },
  pagenation: {
    margin: '50px auto 0',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'rgba(97, 178, 39, 0.08)',
    },
  },
  viewLimit: {
    width: 80,
    height: 40,
    background: '#FFFFFF',
    marginLeft: 10,
  },
  deleteNumber: {
    background: '#F7F7F7',
    marginTop: 10,
    padding: '10px 10px',
    borderRadius: 5,
  },
});

/** ライブの配信設定と開催(UI) */
const FileToLiveListComponent = (props: FileToLiveListProps): JSX.Element => {
  const classes = useStyle();
  const status = useSelector((state: RootState) => state.ui.status);
  const {
    edition,
    maxLive,
    maxContractClient,
    filetoLiveNowCnt,
    filetoliveCntSum,
    liveSum,
    faildDeleateFileToLiveId,
    succeedDeleateFileToLiveId,
    checkedFlpids,
    fileToLiveProfiles,
    notFoundChatlogDialog,
    setNotFoundChatlogDialog,
    deleteFileToLiveProfileEvent,
    closeFaildDellFlpidDialog,
    goFileToLiveLiveRegister,
    setCheckedFlpids,
  } = props;
  /** 削除確認ダイアログ開閉 */
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  /** 削除時ライブIDチェックされていないダイアログ開閉 */
  const [noneDeleteDialog, setNoneDeleteDialog] = useState<boolean>(false);
  /** 共通タグダイアログ開閉 */
  const [shareDialog, setShareDialog] = useState<boolean>(false);
  /** 共通タグダイアログ 押されたライブID */
  const [openShareFlpid, setOpenShareFlpid] = useState<number | null>(null);
  /** ページネーション関連 */
  const [limit, setLimit] = useState<number>(DISPLAY_COUNT[0]);
  const [page, setPage] = useState<number>(1);

  /** 選択可能な全lpidのリスト */
  const flpids: number[] = fileToLiveProfiles
    .filter(
      (flive) =>
        flive.liveStatus === FILE_TO_LIVE_STATUS.WAITING &&
        flive.chatStatus !== FILE_TO_LIVE_CHAT_STATUS.STREAMING
    )
    .map((flive) => flive.flpid);
  /** 各行、チェックされているか判定 */
  const checkId = (lpid: number) => {
    const isChecked = Boolean(
      typeof checkedFlpids.find((x) => x === lpid) !== 'undefined'
    );
    if (isChecked) {
      setCheckedFlpids([...checkedFlpids.filter((x) => x !== lpid)]);
    } else {
      setCheckedFlpids([...checkedFlpids, lpid]);
    }
  };
  /** 全行、チェックされているか判定 */
  const checkAllId = (ids: number[]) => {
    checkedFlpids.sort((a: number, b: number) => a - b);
    ids.sort((a: number, b: number) => a - b);
    const checklpidStr = JSON.stringify(checkedFlpids);
    const lpidStr = JSON.stringify(ids);
    if (checklpidStr === lpidStr) {
      setCheckedFlpids([]);
    } else {
      // チェック済みで被っているもの
      const currentIds = checkedFlpids.filter(
        (x) => ids.filter((y) => x === y).length > 0
      );
      if (currentIds.length > 0) {
        // 被っているものを排除
        setCheckedFlpids(
          checkedFlpids.filter((x) => ids.filter((y) => x === y).length <= 0)
        );
      } else {
        setCheckedFlpids([...checkedFlpids, ...ids]);
      }
    }
  };

  /** 削除ボタン押下時イベント */
  const onClickDleteBtn = () => {
    if (checkedFlpids.length === 0) {
      setNoneDeleteDialog(true);
    } else {
      setConfirmDialog(true);
    }
  };

  /** 削除実行 */
  const handleDelete = () => {
    deleteFileToLiveProfileEvent(checkedFlpids);
    setPage(1);
  };

  /** 共通タグオープンイベント */
  const handleOpenShareDialog = (flpid: number) => {
    setOpenShareFlpid(flpid);
    setShareDialog(true);
  };

  /** 共通タグクローズイベント */
  const handleCloseShareDialog = () => {
    setShareDialog(false);
    setOpenShareFlpid(null);
  };

  /** グラフ表示 */
  const [graphDialog, setGraphDialog] = useState<boolean>(false);
  const [flpid, setFlpid] = useState<number>(0);
  const handleGraph = (flpid: number) => {
    setGraphDialog(true);
    setFlpid(flpid);
  };

  /** 表示件数の変更 */
  const handleLimit = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setLimit(Number(event.target.value));
    setPage(1);
  };

  /** 表示範囲の変更 */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  /** ソート対応 */
  const handleSort = () => {
    setPage(1);
  };

  // 処理中
  if (status.fileToLiveList === UI_STATUS.PROCESSING) return <LinearProgress />;

  return (
    <CommonBox className={classes.root} navibar={MENUBAR.FILETOLIVE}>
      {status.outputCSV === UI_STATUS.PROCESSING && !graphDialog && (
        <LinearProgress className={classes.linearProgress} />
      )}
      <h2 className={`${classes.header} ${classes.fixed}`}>
        疑似ライブの配信設定と開催
      </h2>
      <Box className={`${classes.infoBox} ${classes.fixed}`}>
        <Box className={classes.info}>
          <Box className={classes.state}>
            <InfoOutlined />
            契約情報
          </Box>
          疑似同時ライブ開催数上限：
          <em>{maxLive}件</em>
          ｜同時接続数上限：
          <em>
            {edition === EDITIONS.U_LIVE
              ? '制限なし'
              : `${maxContractClient}人`}
          </em>
        </Box>
        <Box className={classes.info}>
          <Box className={classes.state}>
            <InfoOutlined />
            ライブ状況
          </Box>
          現在予約中の疑似ライブ数：
          <strong>{filetoLiveNowCnt}件</strong>/ {filetoliveCntSum}件 ｜
          <span style={{ color: '#B6BEC9' }}>
            現在使用中の同時接続枠数：
            <strong style={{ color: '#B6BEC9' }}>
              {edition === EDITIONS.U_LIVE ? (
                '制限なし'
              ) : (
                <span style={{ fontWeight: 700, marginLeft: 10 }}>--人</span>
              )}
            </strong>
            /&nbsp;
            {edition === EDITIONS.U_LIVE ? '制限なし' : `--人`}
          </span>
          <span
            className={classes.helpContainer}
            style={{ display: 'inline-block' }}
          >
            <Help fontSize="small" className={classes.help} />
            <Tooltip message="現在調整中です" />
          </span>
        </Box>
      </Box>
      <Box className={`${classes.tool} ${classes.fixed}`}>
        <Box>
          <Button
            className={classes.addButton}
            onClick={goFileToLiveLiveRegister}
          >
            <PlaylistAdd />
            新規登録
          </Button>
          <Button className={classes.deleteButton} onClick={onClickDleteBtn}>
            <DeleteForever />
            削除
            <span style={{ marginLeft: '-6px' }}>
              （{checkedFlpids.length}件）
            </span>
          </Button>
        </Box>
        <Box fontSize="15" display="flex" alignItems="center">
          登録件数：<b style={{ marginRight: 10 }}>{liveSum}件</b>|
          <span style={{ marginLeft: 10 }}>表示件数</span>
          <Select
            value={limit}
            onChange={handleLimit}
            variant="outlined"
            className={classes.viewLimit}
          >
            {DISPLAY_COUNT.map((item) => (
              <MenuItem key={item} value={item}>
                {item}件
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.table}>
        <FileToLiveListTable
          props={props}
          checkedFlpids={checkedFlpids}
          flpids={flpids}
          checkId={checkId}
          checkAllId={checkAllId}
          handleOpenShareDialog={handleOpenShareDialog}
          handleGraph={handleGraph}
          page={page}
          limit={limit}
          handleSort={handleSort}
        />
        <Pagination
          className={classes.pagenation}
          count={Math.ceil(fileToLiveProfiles?.length / limit)}
          onChange={handlePage}
          page={page}
        />
      </Box>
      <Dialog
        open={noneDeleteDialog}
        handleClose={() => setNoneDeleteDialog(false)}
        handleOK={() => true}
        title={<>削除対象が１つもチェックされていません。</>}
      />
      <Dialog
        open={confirmDialog}
        handleClose={() => setConfirmDialog(false)}
        handleOK={handleDelete}
        title={
          <>
            チェックした疑似ライブ配信設定情報を削除します。
            <br />
            よろしいですか？
            <div className={classes.deleteNumber}>
              {checkedFlpids.join(', ')}
            </div>
          </>
        }
        cansel="キャンセル"
        ok="OK"
      />
      {(succeedDeleateFileToLiveId || faildDeleateFileToLiveId) && (
        <Dialog
          open={
            succeedDeleateFileToLiveId.length !== 0 ||
            faildDeleateFileToLiveId.length !== 0
          }
          handleClose={closeFaildDellFlpidDialog}
          handleOK={() => true}
          title={
            <>
              {succeedDeleateFileToLiveId.length > 0 && (
                <>
                  疑似ライブ配信設定情報の削除に成功しました。
                  <div className={classes.deleteNumber}>
                    {succeedDeleateFileToLiveId.join(', ')}
                  </div>
                </>
              )}
              {faildDeleateFileToLiveId.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  疑似ライブID: {faildDeleateFileToLiveId.join(',')}
                  の削除に失敗しました
                </div>
              )}
            </>
          }
        />
      )}
      {openShareFlpid && (
        <FileToLiveShareContainer
          open={shareDialog}
          flpid={openShareFlpid}
          onClose={() => handleCloseShareDialog()}
        />
      )}
      <FileToLiveHistoryConnectionGraphContainer
        open={graphDialog}
        flpid={flpid}
        onClose={() => setGraphDialog(false)}
      />
      <Dialog
        open={notFoundChatlogDialog}
        handleClose={() => setNotFoundChatlogDialog(false)}
        title={
          <>
            データを準備中です。しばらくお待ちの上、再度お試しください。
            <br />
            チャットが終了していない場合は、チャット終了後にダウンロードが可能となります。
          </>
        }
        cansel=""
        ok="OK"
      />
    </CommonBox>
  );
};

export default FileToLiveListComponent;
