import React, { useEffect, useState } from 'react';
import { Control, FieldValues, UseFormWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import FormSelectBox from '../parts/FormSelectBox/FormSelectBox';
import { LiveRegisterProps } from '../../props/LiveRegister';
import { ParentalControl } from '../../types/ParentalControl';
import { LiveContentProps } from '../../props/LiveContent';
import { HistoryContentProps } from '../../props/HistoryContent';
import {
  AUTHMODE,
  EDITIONS,
  IPLIMIT_TYPE,
  SETPAGE_URL_SECURITY,
} from '../../utils/Const';
import DecryptSecurityContent from '../../utils/DecryptSecurityContent';

/** スタイル */
const useStyle = makeStyles({
  root: {
    '& p': {
      margin: 0,
      fontSize: 13,
    },
    '& a': {
      color: '#4472C4',
    },
    '& .hidden.MuiIconButton-root': {
      display: 'none',
    },
    '& .hidden .MuiIconButton-root': {
      display: 'none',
    },
  },
  header: {
    borderBottom: '1px dashed #B9B9B9',
    paddingBottom: 30,
    marginBottom: 30,
    fontSize: 15,
  },
  discription: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  title: {
    borderLeft: '2px solid #61B227',
    paddingLeft: 20,
    marginTop: 20,
    fontSize: 15,
    fontWeight: 500,
  },
  greenBack: {
    background: '#FBFFF8',
    border: '1px dashed #B4D69B',
    borderRadius: 4,
    padding: '10px 30px 30px',
    color: '#333333',
    marginTop: 20,
    '& p': {
      marginBottom: 20,
    },
  },
  greenInner: {
    marginLeft: 20,
    marginTop: 14,
    fontSize: 14,
  },
  icon: {
    color: '#333333',
    marginRight: 4,
  },
  permit: {
    background: '#61B2270D',
    border: '1px solid #61B227',
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 4,
    padding: '4px 16px',
    marginLeft: 14,
    marginRight: 20,
  },
  disallow: {
    background: '#C327330D',
    border: '1px solid #C32733',
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 4,
    padding: '4px 10px',
    marginLeft: 14,
    marginRight: 20,
  },
  whiteInner: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px #1C385E0C',
    border: '1px solid #F0F0F0',
    borderRadius: 6,
    marginTop: 14,
    fontSize: 14,
    padding: '30px 30px 20px',
  },
  whiteInnerTitle: {
    fontSize: 15,
    fontWeight: 500,
    display: 'flex',
  },
  borderBox: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 25,
    padding: 22,
    border: '1px dashed #B9B9B9',
    borderRadius: 4,
    '& ul': {
      margin: 0,
      lineHeight: '20px',
      paddingInlineStart: 20,
    },
  },
  borderBoxTitle: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    paddingLeft: 6,
    backgroundColor: '#FFFFFF',
    transform: 'translateY(-50%) translateX(10px)',
  },
  greenBorder: {
    borderLeft: '2px solid #61B227',
    paddingLeft: 20,
    marginLeft: 10,
    marginTop: 20,
  },
  andFlg: {
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& .active': {
      background: '#61B2271A',
      borderColor: '#61B227',
      opacity: 1,
      '& span': {
        color: '#FFFFFF',
        background: '#61B227',
        border: 'none',
      },
    },
  },
  andFlgButton: {
    display: 'flex',
    background: '#FFFFFF',
    border: '1px solid #333333',
    borderRadius: 4,
    padding: 10,
    width: 'calc(50% - 30px)',
    opacity: 0.2,
    '& span': {
      width: 50,
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      background: '#FFFFFF',
      color: '#333333',
      border: '1px solid #333333',
      fontWeight: 700,
      marginRight: 15,
    },
    '& div': {
      fontSize: 13,
      fontWeight: 500,
      color: '#21410A',
    },
  },
  nolist: {
    listStyle: 'none',
    padding: '0 !important',
  },
});

/** セキュリティ */
const SecuritySetting = ({
  control,
  watch,
  RegisterProps,
  ContentProps,
  HistoryProps,
  open = false,
}: {
  control: Control<FieldValues, object>;
  watch: UseFormWatch<FieldValues>;
  RegisterProps?: LiveRegisterProps;
  ContentProps?: LiveContentProps;
  HistoryProps?: HistoryContentProps;
  open?: boolean;
}): JSX.Element => {
  const classes = useStyle();
  const edition =
    RegisterProps?.edition || ContentProps?.edition || HistoryProps?.edition;
  const disableFlg = ContentProps?.disableFlg || false;
  const securitySetting =
    RegisterProps?.securitySetting ||
    ContentProps?.securitySetting ||
    HistoryProps?.securitySetting;

  // 初期のセキュリティプロファイル内容を取得
  const [securityContents, setSecurityContents] = useState<ParentalControl>(
    () => {
      return DecryptSecurityContent(
        securitySetting?.viewLimitsProfiles.find(
          (profile) => profile.vlpid === securitySetting?.vlpid
        )
      );
    }
  );

  // セキュリティプロファイル内容を更新
  useEffect(() => {
    if (!securitySetting) return;
    const prof = watch('SecuritySettingParentalControlProfiles');
    setSecurityContents(
      DecryptSecurityContent(
        securitySetting.viewLimitsProfiles.find(
          (profile) => profile.vlpid === prof
        )
      )
    );
  }, [watch('SecuritySettingParentalControlProfiles')]);

  return (
    <Box className={classes.root} style={{ display: open ? 'block' : 'none' }}>
      <Box className={classes.header}>
        このライブ配信設定で行われるライブ動画に対して再生を許可するドメインを設定したり、アクセスをIPで制限する設定が出来ます。
        <br />
        <span style={{ color: '#C32733' }}>
          {edition !== EDITIONS.U_LIVE && '「ワンタイム制限設定」'}
          「ID/PW認証設定」をご利用いただくには、プレイヤーのバージョンを最新にしていただく必要がございます。
        </span>
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          セキュリティプロファイル選択
        </Grid>
        <Grid item xs={9}>
          {securitySetting && (
            <>
              <FormSelectBox
                name="SecuritySettingParentalControlProfiles"
                control={control}
                width={300}
                disabled={Boolean(HistoryProps) || disableFlg}
                list={securitySetting.viewLimitsProfiles.map((x) => {
                  return { label: x.profileName, value: x.vlpid };
                })}
                defaultValue={securitySetting?.vlpid}
              />
              <p style={{ marginTop: 10 }}>
                <a href={SETPAGE_URL_SECURITY} target="_blank" rel="noreferrer">
                  セキュリティ設定画面
                </a>
                で予め設定が必要です。
              </p>
            </>
          )}
          <Box className={classes.greenBack}>
            <Box className={classes.title}>セキュリティプロファイル概要</Box>
            <Box className={classes.greenInner}>
              {securityContents.profileDetail}
            </Box>
            <Box className={classes.title}>動画URLページセキュリティ</Box>
            <Box className={classes.greenInner}>
              ■ SNS共有時の埋め込み再生：
              {securityContents.ogpFlg !== 0 ? (
                <span className={classes.permit}>許可する</span>
              ) : (
                <span className={classes.disallow}>許可しない</span>
              )}
              ■ 検索エンジンインデックス：
              {securityContents.noindexFlg !== 0 ? (
                <span className={classes.permit}>許可する</span>
              ) : (
                <span className={classes.disallow}>許可しない</span>
              )}
            </Box>
            <Box className={classes.title}>制限の詳細</Box>
            <Box className={classes.whiteInner}>
              <Box className={classes.whiteInnerTitle}>
                <Lock fontSize="small" style={{ marginRight: 10 }} />
                視聴環境制限
              </Box>
              <Box className={classes.greenBorder}>
                <Box className={classes.borderBox}>
                  <Box className={classes.borderBoxTitle}>
                    再生ドメイン制限設定（以下のドメイン上のみ再生可能）
                  </Box>
                  {securityContents.domainlimitList?.length > 0 ? (
                    <ul>
                      {securityContents.domainlimitList.map((x) => (
                        <li key={x}>{x}</li>
                      ))}
                    </ul>
                  ) : (
                    'なし'
                  )}
                </Box>
                <Box className={classes.borderBox}>
                  <Box className={classes.borderBoxTitle}>
                    {securityContents.iplimitType === IPLIMIT_TYPE.WHITELIST
                      ? 'IP視聴制限設定（以下のIP上のみ再生可能）'
                      : 'IP視聴制限設定（以下のIP上では再生ブロック）'}
                  </Box>
                  {securityContents.iplimitList?.length > 0 ? (
                    <ul className={classes.nolist}>
                      {securityContents.iplimitList.map((x) => (
                        <li key={x}>{x}</li>
                      ))}
                    </ul>
                  ) : (
                    'なし'
                  )}
                </Box>
                {edition !== EDITIONS.U_LIVE && (
                  <Box className={classes.borderBox}>
                    <Box className={classes.borderBoxTitle}>
                      ワンタイム制限設定
                    </Box>
                    {securityContents.otlimitType ? 'あり' : 'なし'}
                  </Box>
                )}
              </Box>
              <Box className={classes.whiteInnerTitle}>
                <Lock fontSize="small" style={{ marginRight: 10 }} />
                ID/PW認証
              </Box>
              <Box className={classes.greenBorder}>
                <Box className={classes.borderBox}>
                  <Box className={classes.borderBoxTitle}>ID/PW認証設定</Box>
                  {securityContents.idlimitList?.length > 0 ? (
                    <ul className={classes.nolist}>
                      {securityContents.idlimitList.map((x) => (
                        <li key={x.id}>
                          {x.id}:{x.pw}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    'なし'
                  )}
                </Box>
              </Box>
              <Box className={classes.andFlg}>
                <Box
                  className={`${classes.andFlgButton} ${
                    securityContents.authMode === AUTHMODE.AND ? 'active' : ''
                  }`}
                >
                  <span>AND</span>
                  <Box>
                    【視聴環境制限】【ID/PW認証】
                    <br /> 両方の判定で適合すれば視聴可能
                  </Box>
                </Box>
                <Box
                  className={`${classes.andFlgButton} ${
                    securityContents.authMode === AUTHMODE.OR ? 'active' : ''
                  }`}
                  style={{ marginLeft: '-15px' }}
                >
                  <span>OR</span>
                  <Box>
                    【視聴環境制限】【ID/PW認証】
                    <br /> どちらかの判定で適合すれば視聴可能
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SecuritySetting;
