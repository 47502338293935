import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AppDispatch, RootState } from '../../app/store';
import LiveShareComponent from '../../components/LiveShare/LiveShareComponent';
import { selectEQLivesById } from '../../features/livesSlice';
import { LiveShareProps } from '../../props/LiveShare';
import { SnackBarProp } from '../../types/Utils';
import {
  CHATHEIGHT_MAXMIN,
  CHATWIDTH_MAXMIN,
  DEFAULT_CHATSIZE,
  ONOFF,
  PLAYER_ASPECTFIX,
  VIDEOHEIGHT_MAXMIN,
  VIDEOWIDTH_MAXMIN,
  SNACKBAR_TYPE,
  LIVESAHRE_TABS,
  DVR_DEMO_LPID,
  STREMAS_NUM,
} from '../../utils/Const';
import { EQLivePlayer, EQLivePlayerPropsType } from '../../utils/EQPlayer';
import getLiveShareFlow from './GetLiveShare';
import updateLiveShare from './UpdateLiveShare';
import { ToStreamsNum } from '../../utils/ChangeStatus';

type propType = {
  open: boolean;
  lpid: number;
  onClose: () => void;
};

/** 共通タグ */
const LiveShareContainer = ({ open, lpid, onClose }: propType): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  // Selector
  const user = useSelector((state: RootState) => state.user);
  const { pamalink, watchUrl, streamQuality } = useSelector(
    (state: RootState) => state.lives
  );
  const EQLive = useSelector((state: RootState) =>
    selectEQLivesById(state, lpid)
  );
  const chatenable = EQLive?.chat.enabled ?? 0;
  const autoResize = EQLive?.chat.autoResizeFlg ?? 0;
  const chatBaseWidth = EQLive?.chat.width ?? DEFAULT_CHATSIZE.WIDTH;
  const chatBaseHeight = EQLive?.chat.height ?? DEFAULT_CHATSIZE.HEIGHT;

  // 変数抽出
  const { width2, height2 } = user.contract.liveInfo;
  const { shortVideo, qr, shortQr } = watchUrl;

  // State
  // 動画サイズ未入力エラーフラグ
  const [inputVideoSizeRequireError, setInputVideoSizeRequireError] = useState({
    width: false,
    height: false,
  });
  // 動画サイズバリデーションエラーフラグ
  const [inputVideoSizeError, setInputVideoSizeError] = useState({
    width: false,
    height: false,
  });
  // チャットサイズ未入力エラーフラグ
  const [inputChatSizeRequireError, setInputChatSizeRequireError] = useState({
    width: false,
    height: false,
  });
  // チャットサイズバリデーションエラーフラグ
  const [inputChatSizeError, setInputChatSizeError] = useState({
    width: false,
    height: false,
  });
  // プレイヤーID
  // NOTE: 描画のAPIコール処理でデフォルトプレイヤーIDをsetしている
  const [pid, setPid] = useState<number>(0);
  // 埋め込みサイズ、ラジオボタン選択値
  const [videoDefaultSizeFlg, setVideoDefaultSizeFlg] =
    useState<boolean>(false);
  // 動画表示サイズ
  const [videoWidth, setVideoWidth] = useState<number>(width2);
  const [videoHeight, setVideoHeight] = useState<number>(height2);
  // アスペクト比
  const [aspectRatio, setAspectRatio] = useState<PLAYER_ASPECTFIX>(
    PLAYER_ASPECTFIX.XVIIVFIX
  );
  // チャット埋め込みサイズ、ラジオボタン選択値
  const [chatMatchPlayer, setChatMatchPlayer] = useState<0 | 1>(autoResize);
  // チャットサイズ
  const [chatWidth, setChatWidth] = useState<number>(
    autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth
  );
  const [chatHeight, setChatHeight] = useState<number>(
    autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight
  );
  // リファラーポリシー
  const [referrerPolicy, setReferrerPolicy] = useState<0 | 1>(1);
  // デフォルト画質設定 PC
  const [defaultQualityPC, setDefaultQualityPC] = useState<STREMAS_NUM>(
    STREMAS_NUM.UNSELECTED
  );
  // デフォルト画質設定 SP
  const [defaultQualitySP, setDefaultQualitySP] = useState<STREMAS_NUM>(
    STREMAS_NUM.UNSELECTED
  );
  // 掲載サイト別管理ID
  const [ppsid, setPpsid] = useState<number>(0);
  // 短縮URL表示中フラグ
  const [shortURLflg, setShortURLflg] = useState<boolean>(false);
  // 過去Blur時の各サイズ
  const [presize, setPresize] = useState({
    videoWidth: width2,
    videoHeight: height2,
    chatWidth: autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth,
    chatHeight: autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight,
  });
  // チャットタブ開閉
  const [chatTab, setChatTab] = useState<boolean>(false);
  // 拡大プレビューの制御
  const [openPreview, setOpenPreview] = useState(false);
  // スナックバー表示
  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProp[]>([]);
  // 動画視聴解析系cookie true = OFF, false = ON
  const [videoanalyzerCookie, setVideoanalyzerCookie] = useState(false);
  // プレイヤーレジューム機能系cookie true = OFF, false = ON
  const [resumeCookie, setResumeCookie] = useState(false);

  // 選択されているプレイヤーの情報を抽出
  const choicePlayer = user.player.players.find((profile) => {
    return profile.pid === pamalink.selectedPlayer.pid;
  });

  // 選択されているプレイヤーのシークバー情報
  const seekbarFlg: ONOFF = choicePlayer?.seekbar ?? ONOFF.OFF;

  // 選択されているプレイヤーのスキップボタン情報 どちらかがONであればtrue
  const skipBtnFlg: boolean =
    (choicePlayer?.backwardBtn === ONOFF.ON ||
      choicePlayer?.forwardBtn === ONOFF.ON) ??
    false;
  // 選択されているプレイヤーのレスポンシブ情報
  const responsiveFlg: ONOFF = choicePlayer?.autoresize ?? ONOFF.OFF;
  // タブの制御
  const [selectTab, setSelectTab] = useState<LIVESAHRE_TABS>(
    LIVESAHRE_TABS.SCRIPT
  );

  // プレイヤー埋め込み
  const [previewPlayerRender, setPreviewPlayerRender] = useState<boolean>(true);
  const previewPlayerRef = useRef<HTMLIFrameElement>(null);

  // プレビューチャットサイズ 幅
  const [previewChatWidth, setPreviewChatWidth] = useState<number>(
    autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth
  );
  // プレビューチャットサイズ 高さ
  const [previewChatHeight, setPreviewChatHeight] = useState<number>(
    autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight
  );
  // チャット埋め込み
  const [previewChat, setPreviewChat] = useState<string>(
    pamalink.selectedPlayer.chatOnly?.tag ?? ''
  );
  // 拡大プレビューチャットサイズ 幅
  const [largePreviewChatWidth, setLargePreviewChatWidth] = useState<number>(
    autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth
  );
  // 拡大プレビューチャットサイズ 高さ
  const [largePreviewChatHeight, setLargePreviewChatHeight] = useState<number>(
    autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight
  );
  // 拡大チャット
  const [largerPreviewChat, setLargerPreviewChat] = useState<string>(
    pamalink.selectedPlayer.chatOnly?.tag ?? ''
  );

  const tagHeight = pamalink.selectedPlayer.script.tag.match(/hp:(\d*)/) || [];
  const tagWidth = pamalink.selectedPlayer.script.tag.match(/wp:(\d*)/) || [];
  const sn: string[] = [];
  if (choicePlayer?.facebook === ONOFF.ON) {
    sn.push('f');
  }
  if (choicePlayer?.twitter === ONOFF.ON) {
    sn.push('t');
  }
  if (choicePlayer?.line === ONOFF.ON) {
    sn.push('l');
  }
  const playerProps = {
    playerParams: {
      b: '',
      c: Buffer.from(String(user.contract.cid)).toString('base64'),
      lpid: EQLive?.dvr ? String(DVR_DEMO_LPID) : '0',
      s: {
        hp: Number(tagHeight[1]),
        wp: Number(tagWidth[1]),
        rp: choicePlayer?.autoresize === ONOFF.ON ? 'on' : 'off',
        lu: choicePlayer?.loopPlay,
        plac: choicePlayer?.playlistAutoplay,
        dq: 0,
        mdq: 0,
        pb: choicePlayer?.playpause,
        wm: choicePlayer?.watermark,
        cp: choicePlayer?.control,
        vc: choicePlayer?.volume,
        fs: choicePlayer?.fullscreen,
        ft: choicePlayer?.footer
          ? encodeURIComponent(choicePlayer?.footer)
          : undefined,
        fa: choicePlayer?.footeralign ?? undefined,
        ti: choicePlayer?.title,
        is: choicePlayer?.sound,
        ip: choicePlayer?.autostart,
        el: choicePlayer?.exlink,
        tg: choicePlayer?.tag,
        il: choicePlayer?.inboundlink,
        mb: choicePlayer?.multibitrate,
        sn: sn.join(),
        rs: choicePlayer?.resume,
        isPreview: '1',
        va: ONOFF.OFF,
        fc: resumeCookie ? ONOFF.OFF : ONOFF.ON,
      },
    },
  };

  const previewProps: EQLivePlayerPropsType = {
    customerDirectory: user.contract.directory,
    playerRef: previewPlayerRef,
    tagId: 'live-share',
    player: playerProps,
  };

  // 拡大プレビュー
  const largerPlayerRef = useRef<HTMLIFrameElement>(null);

  // 初回レンダー制御
  const isFirstRender = useRef({
    updatePamalink: false,
    chatTab: false,
    largerView: false,
  });
  // プレイヤーロード可不可
  const canPlayerload = useRef<boolean>(false);
  // プレイヤー削除可不可
  const canPlayerdel = useRef<boolean>(false);
  // デフォルト画質選択可不可
  const canSetDefaultQuality = useRef<boolean>(true);

  // スナックバー生成
  const createSnackbar = () => {
    const addInfo = () => {
      setSnackbarInfo([
        {
          title: 'クリップボードにコピーしました。',
          type: SNACKBAR_TYPE.INFO,
          time: moment().toString(),
        },
      ]);
    };
    if (snackbarInfo.length > 0) {
      // スナックバーがあれば一旦削除する
      setSnackbarInfo([]);
      // 削除描画が終わるまで待つ
      window.setTimeout(addInfo, 100);
    } else {
      addInfo();
    }
  };
  // スナックバー要素削除
  const handleSnackbarClose = (index: number) => {
    const info = [...snackbarInfo];
    info.splice(index, 1);
    setSnackbarInfo(info);
  };

  // 動画表示サイズバリデーションエラーの初期化
  const initVideoError = () => {
    setInputVideoSizeRequireError({
      ...inputVideoSizeRequireError,
      width: false,
      height: false,
    });
    setInputVideoSizeError({
      ...inputVideoSizeError,
      width: false,
      height: false,
    });
  };
  // 全バリデーションエラーの初期化
  const initError = () => {
    initVideoError();
    setInputChatSizeRequireError({
      ...inputChatSizeRequireError,
      width: false,
      height: false,
    });
    setInputChatSizeError({
      ...inputChatSizeError,
      width: false,
      height: false,
    });
  };

  // stateの初期化
  const initState = () => {
    setPid(0);
    setVideoDefaultSizeFlg(false);
    setVideoWidth(width2);
    setVideoHeight(height2);
    setAspectRatio(PLAYER_ASPECTFIX.XVIIVFIX);
    setChatMatchPlayer(0);
    setChatWidth(autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth);
    setChatHeight(autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight);
    setReferrerPolicy(1);
    setPpsid(0);
    setShortURLflg(false);
    initError();
    setChatTab(false);
    setPresize({
      ...presize,
      videoWidth: width2,
      videoHeight: height2,
      chatWidth: autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth,
      chatHeight: autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight,
    });
    setVideoanalyzerCookie(false);
    setResumeCookie(false);
  };

  // バリデーションエラー判定（動画サイズ）
  const videoValidationError =
    inputVideoSizeRequireError.width ||
    inputVideoSizeRequireError.height ||
    inputVideoSizeError.width ||
    inputVideoSizeError.height;

  // バリデーションエラー判定（チャットサイズ）
  // NOTE: プレイヤーに合わせる場合、ここはエラーでもいいため動画サイズのバリデーションとは分離
  const chatValidationError =
    inputChatSizeRequireError.width ||
    inputChatSizeRequireError.height ||
    inputChatSizeError.width ||
    inputChatSizeError.height;

  /** バリデーションかけたチャットパーマリンク作成APIコール */
  const validationDispatch = ({
    calcuWidth,
    calcuHeight,
    forced = false,
  }: {
    calcuWidth?: number;
    calcuHeight?: number;
    forced?: boolean;
  }) => {
    // NOTE: useStateの適用タイミングがこの関数の後なのでforcedでエラーハンドリングを取る（もっといい方法があるはず）
    if (!previewPlayerRender) return;
    if (!(videoValidationError || chatValidationError) || forced) {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid,
          ppsid,
          width: calcuWidth || videoWidth,
          height: calcuHeight || videoHeight,
          chatWidth: chatMatchPlayer ? calcuWidth || videoWidth : chatWidth,
          chatHeight: chatMatchPlayer ? calcuHeight || videoHeight : chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC,
          defaultQualitySP,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
      // NOTE: チャットサイズはバリデーションエラーだけどプレイヤーサイズに合わせるならばOK
    } else if (!videoValidationError && chatMatchPlayer) {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid,
          ppsid,
          width: calcuWidth || videoWidth,
          height: calcuHeight || videoHeight,
          chatWidth: chatMatchPlayer ? calcuWidth || videoWidth : chatWidth,
          chatHeight: chatMatchPlayer ? calcuHeight || videoHeight : chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC,
          defaultQualitySP,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
    }
  };

  /** 最大最低判定 */
  const aryMax = (a: number, b: number) => {
    return Math.max(a, b);
  };

  const aryMin = (a: number, b: number) => {
    return Math.min(a, b);
  };

  const setDefaultPcQuality = (player: any) => {
    let defaultPcQuality;
    defaultPcQuality = STREMAS_NUM.UNSELECTED;
    if (streamQuality) {
      const activeQuality = Object.entries(streamQuality).filter(
        (quality) => quality[1].activateFlg
      );
      const list = activeQuality.map(([key]) => ToStreamsNum(key));
      // オリジナルのみではない場合、自動画質判定を行う
      if (
        activeQuality.length > 1 ||
        streamQuality.original?.activateFlg === 0
      ) {
        if (player?.qualityPc === 0) {
          defaultPcQuality = STREMAS_NUM.AUTO;
        }
      }
      if (player?.qualityPc === 90) {
        defaultPcQuality = list.reduce(aryMin);
      }
      if (player?.qualityPc === 92) {
        defaultPcQuality = list.reduce(aryMax);
      }
    }
    return defaultPcQuality;
  };

  const setDefaultSpQuality = (player: any) => {
    let defaultSpQuality;
    defaultSpQuality = STREMAS_NUM.UNSELECTED;
    if (streamQuality) {
      const activeQuality = Object.entries(streamQuality).filter(
        (quality) => quality[1].activateFlg
      );
      const list = activeQuality.map(([key]) => ToStreamsNum(key));
      // オリジナルのみではない場合、自動画質判定を行う
      if (
        activeQuality.length > 1 ||
        streamQuality.original?.activateFlg === 0
      ) {
        if (player?.qualitySd === 0) {
          defaultSpQuality = STREMAS_NUM.AUTO;
        }
      }
      if (player?.qualitySd === 90) {
        defaultSpQuality = list.reduce(aryMin);
      }
      if (player?.qualitySd === 92) {
        defaultSpQuality = list.reduce(aryMax);
      }
    }
    return defaultSpQuality;
  };

  /** プレイヤー一覧選択イベント */
  const choicePlayerProfile = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const nowPid = event.target.value as number;
    setPid(nowPid);
    // 選択されているプレイヤーの情報を抽出
    const nowPlayer = user.player.players.find((profile) => {
      return profile.pid === nowPid;
    });
    const defaultPcQuality = setDefaultPcQuality(nowPlayer);
    const defaultSpQuality = setDefaultSpQuality(nowPlayer);
    canSetDefaultQuality.current = true;
    if (videoDefaultSizeFlg) {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid: nowPid,
          ppsid,
          width: nowPlayer?.width ?? width2,
          height: nowPlayer?.height ?? height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC: defaultPcQuality,
          defaultQualitySP: defaultSpQuality,
          videoanalyzerCookie:
            nowPlayer?.videoanalyzerCookie === ONOFF.OFF ?? false,
          resumeCookie: nowPlayer?.resumeCookie === ONOFF.OFF ?? false,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
      setVideoWidth(nowPlayer?.width ?? width2);
      setVideoHeight(nowPlayer?.height ?? height2);
      setAspectRatio(nowPlayer?.aspectratio ?? PLAYER_ASPECTFIX.XVIIVFIX);
    } else {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid: nowPid,
          ppsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC: defaultPcQuality,
          defaultQualitySP: defaultSpQuality,
          videoanalyzerCookie:
            nowPlayer?.videoanalyzerCookie === ONOFF.OFF ?? false,
          resumeCookie: nowPlayer?.resumeCookie === ONOFF.OFF ?? false,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
      setVideoWidth(width2);
      setVideoHeight(height2);
      setAspectRatio(PLAYER_ASPECTFIX.XVIIVFIX);
    }
    setChatMatchPlayer(0);
    setChatWidth(autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth);
    setChatHeight(autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight);
    setShortURLflg(false);
    setVideoanalyzerCookie(
      nowPlayer?.videoanalyzerCookie === ONOFF.OFF ?? false
    );
    setResumeCookie(nowPlayer?.resumeCookie === ONOFF.OFF ?? false);
    initError();
  };

  /** 埋め込みサイズ選択イベント */
  const choiceVideoDefaultSize = (value: string) => {
    if (value === 'true') {
      // 選択されてるプレイヤーのデフォルトのvideoSizeでvideowidth, heightをセット
      setVideoWidth(choicePlayer?.width ?? 0);
      setVideoHeight(choicePlayer?.height ?? 0);
      setVideoDefaultSizeFlg(true);
      initVideoError();
    } else if (value === 'false') {
      // 契約会社情報の標準画質でvideowidth, heightをセット
      setVideoWidth(width2);
      setVideoHeight(height2);
      setVideoDefaultSizeFlg(false);
      initVideoError();
    }
    setAspectRatio(PLAYER_ASPECTFIX.XVIIVFIX);
  };

  /** 動画サイズ入力イベント */
  const inputVideoSize = {
    width: (value: number) => {
      if (Number.isNaN(value)) {
        setVideoWidth(0);
      } else {
        setVideoWidth(value);
      }
    },
    height: (value: number) => {
      if (Number.isNaN(value)) {
        setVideoHeight(0);
      } else {
        setVideoHeight(value);
      }
    },
  };

  /** 動画サイズブラーイベント */
  const blurVideoSize = {
    /** 幅 */
    width: (value: number) => {
      // アスペクト比計算
      let height = 0;
      if (aspectRatio === PLAYER_ASPECTFIX.XVIIVFIX) {
        height = Math.round(value * (9 / 16));
      } else if (aspectRatio === PLAYER_ASPECTFIX.IVIIIFIX) {
        height = Math.round(value * (3 / 4));
      } else {
        height = videoHeight;
      }
      setVideoHeight(height);
      setInputVideoSizeError({
        ...inputVideoSizeError,
        width: Boolean(
          value < VIDEOWIDTH_MAXMIN.MIN || value > VIDEOWIDTH_MAXMIN.MAX
        ),
        height: Boolean(
          height < VIDEOHEIGHT_MAXMIN.MIN || height > VIDEOHEIGHT_MAXMIN.MAX
        ),
      });
      if (value !== presize.videoWidth) {
        // NOTE: validationDispatch内が非同期的に処理されるのでここで弾く
        if (!(value < VIDEOWIDTH_MAXMIN.MIN || value > VIDEOWIDTH_MAXMIN.MAX)) {
          validationDispatch({
            calcuWidth: value,
            calcuHeight: height,
            forced: true,
          });
        }
        setPresize({
          ...presize,
          videoWidth: value,
          videoHeight,
        });
      }
    },
    /** 高さ */
    height: (value: number) => {
      // アスペクト比計算
      let width = 0;
      if (aspectRatio === PLAYER_ASPECTFIX.XVIIVFIX) {
        width = Math.round(value * (16 / 9));
      } else if (aspectRatio === PLAYER_ASPECTFIX.IVIIIFIX) {
        width = Math.round(value * (4 / 3));
      } else {
        width = videoWidth;
      }
      setVideoWidth(width);
      setInputVideoSizeError({
        ...inputVideoSizeError,
        width: Boolean(
          width < VIDEOWIDTH_MAXMIN.MIN || width > VIDEOWIDTH_MAXMIN.MAX
        ),
        height: Boolean(
          value < VIDEOHEIGHT_MAXMIN.MIN || value > VIDEOHEIGHT_MAXMIN.MAX
        ),
      });
      if (value !== presize.videoHeight) {
        // NOTE: validationDispatch内が非同期的に処理されるのでここで弾く
        if (
          !(value < VIDEOHEIGHT_MAXMIN.MIN || value > VIDEOHEIGHT_MAXMIN.MAX)
        ) {
          validationDispatch({
            calcuWidth: width,
            calcuHeight: value,
            forced: true,
          });
        }
        setPresize({
          ...presize,
          videoWidth,
          videoHeight: value,
        });
      }
    },
  };

  /** アスペクト比変更イベント */
  const choiceAspectRatio = (value: string) => {
    setAspectRatio(Number(value));
    // 選択されているアス比で動画表示サイズを計算（幅に合わせる）
    let height = 0;
    if (Number(value) === PLAYER_ASPECTFIX.XVIIVFIX) {
      height = Math.round(videoWidth * (9 / 16));
    } else if (Number(value) === PLAYER_ASPECTFIX.IVIIIFIX) {
      height = Math.round(videoWidth * (3 / 4));
    } else {
      height = videoHeight;
    }
    setVideoHeight(height);

    setInputVideoSizeError({
      ...inputVideoSizeError,
      height: Boolean(
        height < VIDEOHEIGHT_MAXMIN.MIN || height > VIDEOHEIGHT_MAXMIN.MAX
      ),
    });
  };

  /** チャット埋め込みサイズ選択イベント */
  const choiceChatMatchPlayer = (value: string) => {
    if (value === 'true') {
      setChatMatchPlayer(1);
    } else if (value === 'false') {
      setChatMatchPlayer(0);
    }
  };

  /** チャットサイズ入力イベント */
  const inputChatSize = {
    width: (value: number) => {
      if (Number.isNaN(value)) {
        setChatWidth(0);
      } else {
        setChatWidth(value);
      }
    },
    height: (value: number) => {
      if (Number.isNaN(value)) {
        setChatHeight(0);
      } else {
        setChatHeight(value);
      }
    },
  };

  /** チャットプレビュー反映イベント */
  const changeChatPreview = () => {
    const chatTag = pamalink.selectedPlayer.chatOnly?.tag ?? '';

    if (!chatenable || chatTag.length <= 0) return;

    // チャットサイズをプレイヤーに合わせる場合
    if (chatMatchPlayer) {
      // レスポンシブ設定なので、プレイヤーサイズにする
      const previewTag = chatTag
        .replace('client.html', 'client_preview.html')
        .replace(`width:100%;`, `width:${Number(tagWidth[1])}px;`)
        .replace(`height:100%;`, `height:${Number(tagHeight[1])}px;`);

      setPreviewChatWidth(Number(tagWidth[1]));
      setPreviewChatHeight(Number(tagHeight[1]));
      setPreviewChat(previewTag);
    } else {
      // プレビューのサイズはタグから取得
      const tagChatWidth =
        pamalink.selectedPlayer.chatOnly?.tag.match(/width:(\d*)px;/) ?? [];
      const tagChatHeight =
        pamalink.selectedPlayer.chatOnly?.tag.match(/height:(\d*)px;/) ?? [];
      const previewTag = chatTag.replace('client.html', 'client_preview.html');
      setPreviewChatWidth(Number(tagChatWidth[1]));
      setPreviewChatHeight(Number(tagChatHeight[1]));
      setPreviewChat(previewTag);
    }
  };

  /** 拡大チャットプレビュー反映イベント */
  const changeLargerChatPreview = () => {
    const chatTag = pamalink.selectedPlayer.chatOnly?.tag ?? '';

    if (!chatenable || chatTag.length <= 0) return;

    // チャットサイズをプレイヤーに合わせる場合
    if (chatMatchPlayer) {
      // レスポンシブ設定なので、プレイヤーサイズにする
      const largeTag = chatTag
        .replace('client.html', 'client_preview.html')
        .replace(`width:100%;`, `width:${Number(tagWidth[1])}px;`)
        .replace(`height:100%;`, `height:${Number(tagHeight[1])}px;`);
      setLargePreviewChatWidth(Number(tagWidth[1]));
      setLargePreviewChatHeight(Number(tagHeight[1]));
      setLargerPreviewChat(largeTag);
    } else {
      // プレビューのサイズはタグから取得
      const tagChatWidth =
        pamalink.selectedPlayer.chatOnly?.tag.match(/width:(\d*)px;/) ?? [];
      const tagChatHeight =
        pamalink.selectedPlayer.chatOnly?.tag.match(/height:(\d*)px;/) ?? [];
      // 拡大プレビューの高さはプレイヤーとチャットの最大値
      const commonHeight =
        Number(tagHeight[1]) >= Number(tagChatHeight[1])
          ? Number(tagHeight[1])
          : Number(tagChatHeight[1]);
      const largeTag = chatTag
        .replace('client.html', 'client_preview.html')
        .replace(`height:${tagChatHeight[1]}px;`, `height:${commonHeight}px;`);
      setLargePreviewChatWidth(Number(tagChatWidth[1]));
      setLargePreviewChatHeight(commonHeight);
      setLargerPreviewChat(largeTag);
    }
  };

  /** チャットサイズブラーイベント */
  const blurChatSize = {
    /** 幅 */
    width: (value: number) => {
      if (value !== presize.chatWidth) {
        // NOTE: validationDispatch内が非同期的に処理されるのでここで弾く
        if (!(value < CHATWIDTH_MAXMIN.MIN || value > CHATWIDTH_MAXMIN.MAX)) {
          validationDispatch({ forced: true });
        }
        setPresize({
          ...presize,
          chatWidth: value,
          chatHeight,
        });
      }
    },
    /** 高さ */
    height: (value: number) => {
      if (value !== presize.chatHeight) {
        // NOTE: validationDispatch内が非同期的に処理されるのでここで弾く
        if (!(value < CHATHEIGHT_MAXMIN.MIN || value > CHATHEIGHT_MAXMIN.MAX)) {
          validationDispatch({ forced: true });
        }
        setPresize({
          ...presize,
          chatWidth,
          chatHeight: value,
        });
      }
    },
  };

  /** リファラーポリシー選択イベント */
  const choiceReferrerPolicy = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value as boolean) {
      setReferrerPolicy(1);
    } else {
      setReferrerPolicy(0);
    }
  };

  /** デフォルト画質設定 PC 選択イベント */
  const choiceDefaultQualityPC = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let setQuality;
    if ((event.target.value as STREMAS_NUM) || event.target.value === 0) {
      setQuality = event.target.value as STREMAS_NUM;
    } else {
      setQuality = STREMAS_NUM.UNSELECTED;
    }
    setDefaultQualityPC(setQuality);
    if (videoDefaultSizeFlg) {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid,
          ppsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          videoDefaultSizeFlg,
          defaultQualityPC: setQuality,
          defaultQualitySP,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
    } else {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid,
          ppsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC: setQuality,
          defaultQualitySP,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
      setVideoWidth(width2);
      setVideoHeight(height2);
      setAspectRatio(PLAYER_ASPECTFIX.XVIIVFIX);
    }
  };

  /** デフォルト画質設定 SP 選択イベント */
  const choiceDefaultQualitySP = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let setQuality;
    if ((event.target.value as STREMAS_NUM) || event.target.value === 0) {
      setQuality = event.target.value as STREMAS_NUM;
    } else {
      setQuality = STREMAS_NUM.UNSELECTED;
    }
    setDefaultQualitySP(setQuality);
    if (videoDefaultSizeFlg) {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid,
          ppsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          videoDefaultSizeFlg,
          defaultQualityPC,
          defaultQualitySP: setQuality,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
    } else {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid,
          ppsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC,
          defaultQualitySP: setQuality,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
      setVideoWidth(width2);
      setVideoHeight(height2);
      setAspectRatio(PLAYER_ASPECTFIX.XVIIVFIX);
    }
  };

  /** 掲載サイト別管理ID選択イベント */
  const choicePubSiteProfile = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const nowPpsid = event.target.value as number;
    setPpsid(nowPpsid);
    if (videoDefaultSizeFlg) {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid: -1,
          ppsid: nowPpsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          videoDefaultSizeFlg,
          defaultQualityPC,
          defaultQualitySP,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
    } else {
      dispatch(
        updateLiveShare({
          canPlayerload,
          canPlayerdel,
          setPreviewPlayerRender,
          cid: user.contract.cid,
          userApiParams: user.user,
          lpid,
          setPpsid,
          pid: -1,
          ppsid: nowPpsid,
          width: width2,
          height: height2,
          chatWidth,
          chatHeight,
          chatAutoresizeFlg: chatMatchPlayer,
          refererPolicy: referrerPolicy,
          setVideoWidth,
          setVideoHeight,
          setAspectRatio,
          defaultQualityPC,
          defaultQualitySP,
          videoanalyzerCookie,
          resumeCookie,
          setVideoanalyzerCookie,
          setResumeCookie,
        })
      );
      setVideoWidth(width2);
      setVideoHeight(height2);
      setAspectRatio(PLAYER_ASPECTFIX.XVIIVFIX);
    }
    setChatMatchPlayer(0);
    setChatWidth(autoResize ? DEFAULT_CHATSIZE.WIDTH : chatBaseWidth);
    setChatHeight(autoResize ? DEFAULT_CHATSIZE.HEIGHT : chatBaseHeight);
    setShortURLflg(false);
    initError();
  };

  const setCookieHandle = (videoanalyzer?: boolean, resume?: boolean) => {
    setVideoanalyzerCookie(videoanalyzer ?? videoanalyzerCookie);
    setResumeCookie(resume ?? resumeCookie);
    dispatch(
      updateLiveShare({
        canPlayerload,
        canPlayerdel,
        setPreviewPlayerRender,
        cid: user.contract.cid,
        userApiParams: user.user,
        lpid,
        setPpsid,
        pid,
        ppsid,
        width: width2,
        height: height2,
        chatWidth,
        chatHeight,
        chatAutoresizeFlg: chatMatchPlayer,
        refererPolicy: referrerPolicy,
        setVideoWidth,
        setVideoHeight,
        setAspectRatio,
        videoDefaultSizeFlg: videoDefaultSizeFlg ?? undefined,
        defaultQualityPC,
        defaultQualitySP,
        videoanalyzerCookie: videoanalyzer,
        resumeCookie: resume,
        setVideoanalyzerCookie,
        setResumeCookie,
      })
    );
  };

  /** 通常URLコピーイベント */
  const copyURL = (id: string) => {
    const text: HTMLInputElement = document.getElementById(
      id
    ) as HTMLInputElement;
    // NOTE: コピー箇所をハイライト
    text.select();
    createSnackbar();
    navigator.clipboard.writeText(pamalink.selectedPlayer.permalink.tag);
    setShortURLflg(false);
  };

  /** 短縮URLコピーイベント */
  const copyShortURL = (id: string) => {
    const text: HTMLInputElement = document.getElementById(
      id
    ) as HTMLInputElement;
    // NOTE: コピー箇所をハイライト
    text.select();
    createSnackbar();
    navigator.clipboard.writeText(shortVideo);
    setShortURLflg(true);
  };

  /** 再生成ボタン押下イベント */
  const refreshQRCode = () => {
    validationDispatch({});
  };

  // 初期処理
  useEffect(() => {
    const defaultPcQuality = setDefaultPcQuality(choicePlayer);
    const defaultSpQuality = setDefaultSpQuality(choicePlayer);
    canSetDefaultQuality.current = true;
    dispatch(
      getLiveShareFlow({
        cid: user.contract.cid,
        lpid,
        userApiParams: user.user,
        chatenable: Boolean(chatenable),
        chatAutoresizeFlg: chatMatchPlayer,
        setPid,
        setPpsid,
        setVideoanalyzerCookie,
        setResumeCookie,
        canPlayerload,
        defaultQualityPC: defaultPcQuality,
        defaultQualitySP: defaultSpQuality,
      })
    );
    return () => {
      initState();
      // プレイヤー削除
      if (previewPlayerRef.current?.contentDocument?.documentElement) {
        previewPlayerRef.current?.contentDocument?.removeChild(
          previewPlayerRef.current?.contentDocument?.documentElement
        );
      }
    };
  }, []);

  // 入力更新時
  useEffect(() => {
    if (isFirstRender.current.updatePamalink) {
      validationDispatch({});
    } else {
      isFirstRender.current.updatePamalink = true;
    }
  }, [videoDefaultSizeFlg, aspectRatio, chatMatchPlayer, referrerPolicy]);

  // プレイヤー更新時
  useEffect(() => {
    if (
      (canPlayerload.current || !previewProps.playerRef) &&
      previewProps.playerRef.current
    ) {
      // プレイヤー作成
      EQLivePlayer(previewProps);
      canPlayerload.current = false;
      canSetDefaultQuality.current = false;
    } else {
      if (
        canPlayerdel.current &&
        previewPlayerRef.current?.contentDocument?.documentElement
      ) {
        // プレイヤー削除
        previewPlayerRef.current?.contentDocument?.removeChild(
          previewPlayerRef.current?.contentDocument?.documentElement
        );
      }
      canPlayerdel.current = false;
    }
    if (canSetDefaultQuality.current) {
      const defaultPcQuality = setDefaultPcQuality(choicePlayer);
      const defaultSpQuality = setDefaultSpQuality(choicePlayer);
      setDefaultQualityPC(defaultPcQuality as STREMAS_NUM);
      setDefaultQualitySP(defaultSpQuality as STREMAS_NUM);
    }
  }, [playerProps.playerParams.s]);

  // プレイヤー更新時
  // NOTE: チャットタブの切り替わりだけ制御必要
  useEffect(() => {
    if (isFirstRender.current.chatTab) {
      if (
        chatTab &&
        previewPlayerRef.current?.contentDocument?.documentElement
      ) {
        // プレイヤー削除
        previewPlayerRef.current?.contentDocument?.removeChild(
          previewPlayerRef.current?.contentDocument?.documentElement
        );
      } else {
        EQLivePlayer(previewProps);
      }
      // チャット用の設定に変更
      setChatMatchPlayer(0);
    } else {
      isFirstRender.current.chatTab = true;
    }
  }, [chatTab]);

  // 拡大プレビュープレイヤー更新時
  useEffect(() => {
    if (openPreview) {
      // チャット使用かつScriptタグ以外で拡大プレビューを使用
      if (chatenable && selectTab !== LIVESAHRE_TABS.SCRIPT) {
        /** チャットプレビューサイズ 高さ */
        const tagChatHeightExp =
          pamalink.selectedPlayer.chatOnly?.tag.match(/height:(\d*)px;/) ?? [];
        const tagChatHeight = chatMatchPlayer
          ? largePreviewChatHeight
          : Number(tagChatHeightExp[1]);
        previewProps.player.playerParams.s.hp =
          Number(tagHeight[1]) >= tagChatHeight
            ? Number(tagHeight[1])
            : tagChatHeight;
        changeLargerChatPreview();
      }
      previewProps.playerRef = largerPlayerRef;
      EQLivePlayer(previewProps);
    } else {
      // プレイヤー削除
      largerPlayerRef.current?.contentDocument?.removeChild(
        largerPlayerRef.current?.contentDocument?.documentElement
      );
      largerPlayerRef.current?.contentWindow?.location.reload();
      previewProps.playerRef = previewPlayerRef;
      previewProps.player.playerParams.s.hp = Number(tagHeight[1]);
    }
  }, [openPreview]);

  useEffect(() => {
    changeChatPreview();
  }, [pamalink.selectedPlayer.chatOnly]);

  // propsの整形
  const props: LiveShareProps = {
    msg: '通知メッセージ',
    snackbarInfo,
    createSnackbar,
    handleSnackbarClose,
    // イベント
    choicePlayerProfile,
    choiceVideoDefaultSize,
    inputVideoSize,
    blurVideoSize,
    choiceAspectRatio,
    choiceChatMatchPlayer,
    inputChatSize,
    blurChatSize,
    choiceReferrerPolicy,
    choicePubSiteProfile,
    setChatTab,
    refreshQRCode,
    copyURL,
    copyShortURL,
    setOpenPreview,
    // プルダウン
    playerProfiles: user.player.players.map((profile) => ({
      pid: profile.pid,
      profileName: profile.name,
      defaultFlg: Boolean(profile.defaultFlg),
    })),
    pubSitesProfiles: pamalink.pubsitesList,
    // ユーザー入力・選択
    pid,
    seekbar: seekbarFlg === ONOFF.ON,
    skipBtn: skipBtnFlg,
    responsive: responsiveFlg === ONOFF.ON,
    videoDefaultSizeFlg,
    videoWidth,
    videoHeight,
    aspectRatio,
    chatMatchPlayer: Boolean(chatMatchPlayer),
    chatWidth,
    chatHeight,
    referrerPolicy,
    openPreview,
    ppsid,
    selectTab,
    setSelectTab,
    // 画面描画
    chatenable: Boolean(chatenable),
    autoResize: Boolean(autoResize),
    scriptTag: pamalink.selectedPlayer.script,
    iframeTag: pamalink.selectedPlayer.iframe,
    videoUrl: pamalink.selectedPlayer.permalink,
    videoShortUrl: shortVideo,
    QRUrl: qr,
    QRShortUrl: shortQr,
    shortURLflg,
    QRfileName: `live_${user.contract.cid}_${lpid}_qrcode.${user.qr.format}`,
    QRInfo: {
      size: user.qr.size,
      dpi: user.qr.dpi,
      format: user.qr.format,
    },
    chatTag: pamalink.selectedPlayer.chatOnly,
    previewChat,
    previewChatWidth,
    previewChatHeight,
    largePreviewChatWidth,
    largePreviewChatHeight,
    largerPreviewChat,
    previewPlayerRender,
    previewPlayerRef,
    previewPlayerWidth: Number(tagWidth[1]),
    previewPlayerHeight: Number(tagHeight[1]),
    largerPlayerRef,
    // エラー系
    inputVideoSizeRequireError,
    setInputVideoSizeRequireError,
    inputVideoSizeError,
    inputChatSizeRequireError,
    setInputChatSizeRequireError,
    inputChatSizeError,
    setInputChatSizeError,
    // デフォルト画質設定
    streamQuality,
    defaultQualityPC,
    defaultQualitySP,
    choiceDefaultQualityPC,
    choiceDefaultQualitySP,
    videoanalyzerCookie,
    resumeCookie,
    setCookieHandle,
  };

  return <LiveShareComponent open={open} props={props} onClose={onClose} />;
};

export default LiveShareContainer;
